import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { FormErrorMessage } from '../../../components';
import { PaymentCycleType, SubscriptionType } from '../../../configs';
import { ApiSchemaCompanyContractSubscription, Option, Subscription } from '../../../models';
import { useFunctionalityCompanyContract } from '../../../pages/hooks';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFunctionalityInputChecker } from '../../../pages/hooks/functionalityInputChecker';

type Props = {
  id: string,
  show: boolean,
  handleShow: (value: boolean) => void,
  subscriptionType: SubscriptionType,
  subscriptionInfo?: Subscription,
  onUpdateSuccess?: any,
  setOnUpdateSuccess?: any,
  isReseller?: boolean
}

export function ModalSubscriptionUpdate(props: Props) {
  const { id, show, handleShow, subscriptionType, subscriptionInfo, onUpdateSuccess, setOnUpdateSuccess, isReseller } = props;
  const { register, setValue, handleSubmit, formState: { errors }, control, } = useForm<ApiSchemaCompanyContractSubscription>();
  const { onUpdateSubscription } = useFunctionalityCompanyContract();
  const { handleSpecialCharacterWhiteSpaces, numberFieldValidation, handleCopyPasteInvalidValue, handleLimitDecimalPlaces } = useFunctionalityInputChecker()

  const getDate = (date: Date) => {
    const str = moment(date).format("YYYY-MM-DD")
    return str
  }
  const defaultDate = getDate(new Date(subscriptionInfo?.startDate ?? ""))

  const paymentCycleList = () => {
    const paymentCycleArray: Option[] = [];

    for (let i = 1; i <= Object.keys(PaymentCycleType).length / 2; i++) {
      paymentCycleArray.push({
        label: PaymentCycleType[i],
        value: i
      })
    }
    return paymentCycleArray;
  }

  const updateHandler = ((form: ApiSchemaCompanyContractSubscription) => {
    onUpdateSubscription(id, {
      ...form,
      type: subscriptionType,
      paymentCycle: subscriptionInfo?.paymentCycle ?? 0,
      creditLimit: form?.creditLimit ? parseFloat(form.creditLimit.toString()) : 0,
      subscriptionRate: parseFloat(form.subscriptionRate.toString()),
      startDate: moment(form.startDate).format("YYYY-MM-DD")

    })
      .then(() => {
        handleShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
      })
      .catch();
  })

  return (
    <Modal
      show={show}
      onHide={() => handleShow(false)}
      centered
    >
      <form onSubmit={handleSubmit(updateHandler)}>
        {/*@ts-ignore */}
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">Start Date</label>
                    <Controller
                    //@ts-ignore
                      defaultValue={
                        moment(defaultDate).toDate() ?? ""
                      }
                      name="startDate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <DatePicker
                          readOnly={true}
                          minDate={moment().toDate()}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Start date"
                          className="form-control"
                          selected={field.value ? new Date(field.value): null}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    {errors.startDate && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="col">
                    <label htmlFor="" className="position-relative required">Payment Cycle</label>
                    <input
                      className="d-none"
                      name="paymentCycle"
                    // defaultValue={}
                    // ref={register({ required: true })}
                    />
                    <Select
                      className={` ${errors.paymentCycle?.type == "required" ? 'field-required' : ''} `}
                      isDisabled={true}
                      placeholder={(subscriptionInfo?.paymentCycle ? PaymentCycleType[subscriptionInfo?.paymentCycle] : "Select payment cycle")}
                      // defaultValue={paymentCycleList()[0]}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={paymentCycleList()}
                      onChange={(value) => {
                        setValue(
                          "paymentCycle",
                          value?.value,
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                    />
                    {errors.paymentCycle && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                </div>

              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">Subscription Rate</label>
                <div className="input-group ">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="flat-rate" >$</span>
                  </div>
                  <input
                    type="float"
                    className="form-control"
                    {...register(
                      'subscriptionRate',
                      { required: true, validate: handleCopyPasteInvalidValue },
                    )}
                    defaultValue={subscriptionInfo?.subscriptionRate}
                    // onChange={(e) => {
                    //   setValue("subscriptionRate", Number(e.target.value), {
                    //     shouldValidate: true,
                    //   });
                    // }}
                    onKeyPress={(event) => {
                      if (!/[0-9]|\./.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyDown={(evt) => numberFieldValidation(evt)}
                    onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                  {errors.subscriptionRate && errors.subscriptionRate.type === "required" && (
                    <FormErrorMessage message="required" />
                  )}
                  {errors.subscriptionRate && errors.subscriptionRate.type === "validate" && (
                    <FormErrorMessage message="Please enter a valid value" />
                  )}
                </div>
              </div>
              {
                !isReseller && <div className="form-group">
                  <label htmlFor="" className="position-relative required">Credit Limit</label>
                  <div className="input-group ">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="flat-rate" >$</span>
                    </div>
                    <input
                      type="float"
                      className="form-control"
                      {...register('creditLimit', { required: true, validate: handleCopyPasteInvalidValue })}
                      defaultValue={subscriptionInfo?.creditLimit}
                      // onChange={(e) => {
                      //   setValue("creditLimit", Number(e.target.value), {
                      //     shouldValidate: true,
                      //   });
                      // }}
                      onKeyPress={(event) => {
                        if (!/[0-9]|\./.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onKeyDown={(evt) => numberFieldValidation(evt)}
                      onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                    {errors.creditLimit && errors.creditLimit.type === "required" && (
                      <FormErrorMessage message="required" />
                    )}
                    {errors.creditLimit && errors.creditLimit.type === "validate" && (
                      <FormErrorMessage message="Please enter a valid value" />
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary update-from-submit-btn text-uppercase"
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
