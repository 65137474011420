import { WithPagination } from "./../../models/api/common";
import React, { useState } from "react";
import useSWR from "swr";
import { PlatformApi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { RecentCallResponseModel } from "../../models/api/Call";
import { AppConstants, topLoaderProgress } from "../../configs";

type Props = {
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
};

export function useFunctionalityRecentCall(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;
  const pagedRecentCallsUrl = PlatformApi.Call.GetRecentCalls(
    pageSize,
    pageNo,
    searchText
  );
  const [pagedCallListCount, setPagedCallListCount] = useState<number>(0);

  const LoadRecentCall = async () => {
    const res = await AxiosAuth.get<
      ApiResponse<WithPagination<RecentCallResponseModel[]>>
    >(pagedRecentCallsUrl);
    if ((res.status = AppConstants.Api.StatusOK)) {
      return Promise.resolve(res.data);
    }
  };
  return {
    LoadRecentCall,
  };
}
