import React from "react";
import {UtilsJQuery} from "../../utils";
import {AxiosAuth} from "../../core";
import {AppConstants, VriApi} from "../../configs";


export function useFunctionalityMakeSchedule() {
  const onCreateConference = React.useCallback(async (body: FormData) => {
    const btnLoading = UtilsJQuery.Ladda(".make-schedule-submit-btn");
    btnLoading.start?.();

    const conferenceUrl = VriApi.Conference.CreateConference.Root();
    try {
      const response = await AxiosAuth.post(conferenceUrl, body);

      if (response.status === AppConstants.Api.StatusOK) {
        console.log("==** Response inside Make Schedule: ", response);
        return Promise.resolve(response);
      }
    } catch (e) {
      return Promise.reject();
    }
    btnLoading.stop?.();
  }, []);

  return {
    onCreateConference
  };
}