import { useCallback } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { PlatformApi } from "../../configs";
import { AppConstants } from "../../configs/appConstants";
import { AppRouteUi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core";
import {
  APIEnabledIvrCustomFields,
  APISchemaUpdateCustomField,
  APISchemaUpdateDialKeyMapping,
  APISchemaUpdateMessage,
} from "../../models";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityCompanyIvr() {
  const companyId = AppRouteUi.Company.Profile.useParam();
  const companyIvrDetailsUrl = PlatformApi.Company.GetIvrById(companyId ?? '');
  const onUpdateDialKeyMapping = useCallback(
    (requestBodyData: APISchemaUpdateDialKeyMapping, ivrId: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.post(
        PlatformApi.CompanyIvr.UpdateDialKeyMapping(ivrId),
        requestBodyData
      )
        .then((res) => {
          if (res?.status === AppConstants.Api.StatusOK) {
            toast.success(res?.data?.message);
            // mutate(companyIvrDetailsUrl);

          }
          btnLoading.stop?.();
          return Promise.resolve();
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
          btnLoading.stop?.();
          return Promise.reject();
        });

      btnLoading.stop?.();
    },
    []
  );
  const onUpdateMessage = useCallback(async (ivrId: string, form: FormData) => {
    const btnLoading = UtilsJQuery.Ladda(".btn-message-submit");
    btnLoading.start?.();
    try {
      var res = await AxiosAuth.post<FormData>(
        PlatformApi.CompanyIvr.UpdateMessage(ivrId),
        form
      );
      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Successfully updated");
        mutate(PlatformApi.Company.GetById(companyId));
      }

      return
    } catch (e: any) {
      toast.error(e.Message);
      console.log(e);
      toast.success("failed to update");
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);
  const onUpdateCustomField = useCallback(async (ivrId: string, form: APIEnabledIvrCustomFields) => {
    const btnLoading = UtilsJQuery.Ladda(".btn-message-submit");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post(
        PlatformApi.CompanyIvr.UpdateCustomFields(ivrId),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(PlatformApi.Company.GetById(companyId));
        toast.success("Updated Successfully");
      }
    } catch (e) {
      toast.error("Update Failed");
      console.log(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onUpdateThreeWayCall = useCallback((isEnabled: boolean, ivrId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".btn-message-submit");
    btnLoading.start?.();
    const body = new FormData();

    body.append("isEnabled", isEnabled ? "true" : "false");
    return AxiosAuth.post(PlatformApi.CompanyIvr.UpdateThreeWayCall(ivrId), body)
      .then((res) => {
        if (res?.status === AppConstants.Api.StatusOK) {
          toast.success(res?.data?.message);
          mutate(PlatformApi.Company.GetById(companyId));
        }
        btnLoading.stop?.();
        return Promise.resolve();
      })
      .catch((e) => {
        toast.error(e);
        console.log(e);
        btnLoading.stop?.();
        return Promise.reject();
      });
  },
    []
  );

  return {
    onUpdateDialKeyMapping,
    onUpdateMessage,
    onUpdateCustomField,
    onUpdateThreeWayCall,
  };
}
