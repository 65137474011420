import React, { useEffect, useState } from "react";
import { ConferenceCallSettingCode, sortedOptions, topLoaderProgress } from "../../../configs/appConstants";
import { ApiSchemaChangeCustomFiledName, ApiSchemaCompanyByIdData, CallSetting, CallSettingsConferenceCallToggleHide, CallSettingsDisableToggle, CompanySettings, CustomSetting } from "../../../models";
import { PageJobSettings } from "../../../pages";
import { useFunctionalityCompanySettings } from "../../../pages/hooks/functionalityCompanySettings";
import { PageConferenceSettings } from "../../../pages/public/ConferenceSettingsPage.";
import ModalInputElement from "../../modals/common/singleInputModal";
import { useFunctionalityCompanySettingsDetails, useFunctionalityDepartment, useFunctionalityLocation } from "../../../pages/hooks";
import { AppRouteUi } from "../../../configs";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import { NoDataComponent } from "../../admin/noDataComponent";
import { AssetSvg } from "../../../assets";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
};

export function SettingsTab() {
  const [loaderProgress, setLoaderProgress] = useState(0);

  const { onCompanySettingsDetails } = useFunctionalityCompanySettingsDetails({
    companyId: AppRouteUi.Company.Profile.useParam(),
    setLoaderProgress: setLoaderProgress
  });

  const [companySettingsDetails, setCompanySettingsDetails] = useState<ApiSchemaCompanyByIdData>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const { optionMappedLocationList } = useFunctionalityLocation({ companyId: AppRouteUi.Company.Profile.useParam() || "", shouldLocationGetAllInvoke: true });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({ companyId: AppRouteUi.Company.Profile.useParam() || "", shouldDepartmentGetAllInvoke: true });

  let locationFound = true;
  let departmentFound = true;

  useEffect(() => {
    onCompanySettingsDetails()?.then((res) => {
      setCompanySettingsDetails(res?.data);
      setLoaderProgress(topLoaderProgress.complete);
    });
  }, [onUpdateSuccess]);

  const companySettings: CompanySettings = companySettingsDetails?.companySettings || {};

  useEffect(() => {
  }, [onUpdateSuccess]);

  const {
    onChangeCallSettingStatus,
    onChangeCustomSettingStatus,
    onChangeAccountStatus,
    onChangeCustomFieldName,
  } = useFunctionalityCompanySettings();
  const [customFieldShow, setCustomFieldModalShow] = React.useState(false);
  const [customField, setCustomFiled] = useState<CustomSetting>();
  const [inputValue, setInputValue] = useState("");

  const [accStatus, setAccStatus] = useState(companySettingsDetails?.isActive);

  const onCallSettingToggleHandler = (callSetting: CallSetting) => {
    callSetting = { ...callSetting, isEnabled: !callSetting.isEnabled };
    onChangeCallSettingStatus(callSetting, setLoaderProgress)?.then(() => {
      setLoaderProgress(topLoaderProgress.complete);
      setOnUpdateSuccess(prev => !prev);
    });
  };
  const onCustomSettingToggleHandler = (customSetting: CustomSetting) => {
    if (customSetting?.code === "Location") {
      if (optionMappedLocationList?.length === 0) {
        toast.error("Please add Location first to enable");
        setTimeout(() => {
          $('button[name=Location]').removeClass('active')
        }, 300);
        return;
      }
    }
    if (customSetting?.code === "Department") {
      if (optionMappedDepartmentList?.length === 0) {
        toast.error("Please add Department first to enable");
        setTimeout(() => {
          $('button[name=Department]').removeClass('active')
        }, 300);
        return;
      }
    }

    customSetting = { ...customSetting, isEnabled: !customSetting.isEnabled };
    onChangeCustomSettingStatus(customSetting, setLoaderProgress, locationFound, departmentFound)?.then(() => {
      setLoaderProgress(topLoaderProgress.complete);
      setOnUpdateSuccess(prev => !prev);
    });
  };
  const onAccountStatusToggleHandler = (accountStatus: boolean | undefined) => {
    const accountStatusModel = {
      companyId: companySettingsDetails?.id || "",
      isActive: !accountStatus,
    };
    onChangeAccountStatus(accountStatusModel, setAccStatus, setLoaderProgress)?.then(() => {
      setLoaderProgress(topLoaderProgress.complete);
      setOnUpdateSuccess(prev => !prev);
    });
  };
  const onEditCustomField = (customField: CustomSetting) => {
    setInputValue(customField.name);
    setCustomFiled(customField);
    setCustomFieldModalShow(true);
  };
  const onCustomFieldChangeHandler = (name: string) => {
    const model: ApiSchemaChangeCustomFiledName = {
      customSettingId: customField?.id || "",
      name: name,
    };
    onChangeCustomFieldName(model, onUpdateSuccess, setOnUpdateSuccess);
  };
  const onChangeCustomField = (event: any) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setAccStatus(companySettingsDetails?.isActive)
  }, [companySettingsDetails])

  const callSettingsToggleIsDisable = (companySettingItem: CallSetting) => {
    return companySettingItem?.code === CallSettingsDisableToggle.language && companySettingItem?.isEnabled;
  }
  const customFieldDescription = (customField: CustomSetting) => {
    if (customField?.name === "Location") {
      return <p className="settings-small-text">Let the user provide the Location of the user</p>
    }
    else if (customField.name === "Department") {
      return <p className="settings-small-text">Let the user provide the Department of the user</p>
    }
    else {
      return <p className="settings-small-text">Let the user provide Custom user identification</p>
    }
  }

  let callSettings: CallSetting[] = []
  const [callSetting, setCallSetting] = useState<CallSetting[]>([])
  useEffect(() => {
    if (companySettings.callSettings) {
      for (let i = 0; i < sortedOptions.length; i++) {
        const itemIndex = companySettings?.callSettings?.findIndex((option) => option?.code === sortedOptions[i]);
        callSettings.push(companySettings.callSettings[itemIndex])
      }
      setCallSetting(callSettings)
    }
  }, [companySettings.callSettings])
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {
        companySettingsDetails ?
          <div className="pt-3 pb-3 pb-sm-0 ">
            <div className="component-card mb-3">
              <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
                <div>Account Status</div>
              </div>
              <div className="component-card-body border">
                <div className="row flex-wrap px-3">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-0 col-min-width">
                    <button
                      type="button"
                      className={
                        accStatus ? "btn btn-toggle active"
                          : "btn btn-toggle"
                      }
                      onClick={() => {
                        onAccountStatusToggleHandler(
                          accStatus
                        );
                      }}
                    >
                      <div className="handle"></div>
                    </button>

                    <span className="toggle-button-label">
                      {accStatus ? "Active" : "Inactive"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="component-card mb-3">
              <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
                <div>Call Settings</div>
              </div>
              <div className="component-card-body border">

                <div className="row flex-wrap px-3">
                  {callSetting?.map(
                    (companySettingItem, index) => <>
                      {
                        companySettingItem?.code !== CallSettingsConferenceCallToggleHide.conferenceCall &&
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 px-0 col-min-width">
                          <button
                            type="button"
                            disabled={callSettingsToggleIsDisable(companySettingItem)}
                            className={
                              companySettingItem?.isEnabled
                                ? "btn btn-toggle active"
                                : "btn btn-toggle"
                            }
                            onClick={() => {
                              onCallSettingToggleHandler(companySettingItem);
                            }}
                          >
                            <div className="handle"></div>
                          </button>

                          <span className="toggle-button-label">
                            {companySettingItem?.code}
                          </span>
                          <p className="settings-small-text">
                            {companySettingItem?.description}
                          </p>
                        </div>
                      }
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="component-card mb-3">
              <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
                <div>Custom Fields</div>
              </div>
              <div className="component-card-body border">
                <div className="row flex-wrap px-3">
                  {companySettings?.customFields?.map(
                    (customSettingItem, index) => (
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 px-0 col-min-width">
                        <button
                          type="button"
                          name={customSettingItem.code}
                          className={
                            customSettingItem?.isEnabled
                              ? "btn btn-toggle active"
                              : "btn btn-toggle"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            onCustomSettingToggleHandler(customSettingItem);
                          }}
                        >
                          <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label">
                          {customSettingItem?.name}{" "}
                          <button className="btn svg-color py-0 font-sz-12 mb-1" onClick={() => onEditCustomField(customSettingItem)}>
                            <span >
                              <AssetSvg.Edit />
                            </span>
                          </button>

                        </span>
                        {
                          customFieldDescription(customSettingItem)
                        }
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="component-card mb-3">
              <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
                <div>Booking Custom Fields</div>
              </div>
              <div className="component-card-body border">
                <div className="row flex-wrap px-3">
                  {companySettings?.bookingCustomFields?.map(
                    (customSettingItem, index) => (
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-0 col-min-width">
                        <button
                          type="button"
                          className={
                            customSettingItem?.isEnabled
                              ? "btn btn-toggle active"
                              : "btn btn-toggle"
                          }
                          onClick={() => {
                            onCustomSettingToggleHandler(customSettingItem);
                          }}
                        >
                          <div className="handle"></div>
                        </button>

                        <span className="toggle-button-label">
                          {customSettingItem?.name}{" "}
                          <button className="btn svg-color py-0 font-sz-12 mb-1" onClick={() => onEditCustomField(customSettingItem)}>
                            <span >
                              <AssetSvg.Edit />
                            </span>
                          </button>
                        </span>
                        {
                          customFieldDescription(customSettingItem)
                        }
                        {/* <p className="settings-small-text">
                          {customSettingItem?.description ? customSettingItem?.description : customSettingItem?.code}
                        </p> */}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <PageJobSettings
              bookingPolicy={companySettingsDetails?.bookingPolicySettings}
              companyName={companySettingsDetails?.name ?? ""}
              onUpdateSuccess={onUpdateSuccess}
              setOnUpdateSuccess={setOnUpdateSuccess}
            />{" "}
            {companySettingsDetails?.companySettings?.callSettings?.find(
              (x: any) => x.code === ConferenceCallSettingCode
            )?.isEnabled === true ? (
              <PageConferenceSettings
                companyProfileData={companySettingsDetails}
                companyName={companySettingsDetails?.name ?? ""}
              />
            ) : (
              <></>
            )}
          </div>
          : <NoDataComponent title="Loading..." />
      }
      {
        customFieldShow && <ModalInputElement
          show={customFieldShow}
          handleShow={setCustomFieldModalShow}
          header="Enter input"
          inputValue={inputValue}
          onSaveHandler={onCustomFieldChangeHandler}
          onChangeHandler={onChangeCustomField}
        />
      }
    </>
  );
}
