import { Device } from 'twilio-client';
import { logger } from '../utils';

export const device = new Device();

device.on('ready', () => {
  logger({
    message: `Twilio device is now ready for connection`,
  });
});

device.on('error', (error) => {
  logger({
    message: `Twilio device Error: `,
    data: error?.message
  });
});

device.on('offline', () => {
  logger({
    message: `Twilio device is now offline`,
  });
});

device.on('busy', () => {
  logger({
    message: `Twilio device is now busy`,
  });
});
