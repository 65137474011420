import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AppRouteUi, PlatformType, topLoaderProgress } from "../../../configs";
import { ApiSchemaCompanyByIdData, ApiSchemaLanguagePackData, LanguagePack, } from "../../../models";
import { useFunctionalityChooseLanguagePack, useFunctionalityCompanyLanguagePack, useFunctionalityCompanyPackageDetails, } from "../../../pages/hooks";
import { OptionMapper } from "../../../utils/optionMapper";
import { LanguagePackComponent } from "../../Common/Components/LanguagePackComponent";
import { ModalSingleDropdown } from "../../modals";
import { useAppSelector } from "../../../core";
import { NoDataComponent } from "../../admin/noDataComponent";
import { AssetSvg } from "../../../assets";
import { ModalLanguagePackV2 } from "../../modals/languagePackModalV2";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
  setLoaderProgress?: any;
  copyable?: boolean;
};

export function PackageTab({ editable, copyable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const isContractFeesUpdate = useAppSelector((state) => state.companyContract.isUpdateContractFees);

  const { onCompanyPackageDetails } = useFunctionalityCompanyPackageDetails({
    companyId: AppRouteUi.Company.Profile.useParam(),
    setLoaderProgress: setLoaderProgress
  });

  const [companyPackageDetails, setCompanyPackageDetails] = useState<ApiSchemaCompanyByIdData>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const [topLoadingBar, setTopLoadingBar] = useState(0);
  const [isCopyLanguagePack, setIsCopyLanguagePack] = useState(false);

  useEffect(() => {
    onCompanyPackageDetails()
      ?.then((res) => {
        setCompanyPackageDetails(res?.data);
        setLoaderProgress(topLoaderProgress.complete);
      }
      )
  }, [onUpdateSuccess, isContractFeesUpdate]);

  const [show, setShow] = useState(false);
  const [showSelectPackModal, setShowSelectPackModal] = useState(false);
  const [selectedCompanyLanguageId, setSelectedCompanyLanguageId] = useState("");
  const [selectedLanguagePackId, setSelectedLanguagePackId] = useState("");
  const [dataToEdit, setDataToEdit] = React.useState<ApiSchemaLanguagePackData>(Object);
  const [chooseAnotherPackageList, setChooseAnotherPackageList] = useState<any[]>([]);
  const languagePacks: LanguagePack[] = companyPackageDetails?.languagePacks || [];
  const [selectedPlatformType, setSelectedPlatformType] = useState<number>(0)
  const [packNameAsProp, setPackNameAsProp] = useState("")

  const { onChooseAnotherPackage } = useFunctionalityChooseLanguagePack({
    companyId: companyPackageDetails?.id ?? "",
    setLoaderProgress: setLoaderProgress,
  });

  const { onEditPack } = useFunctionalityCompanyLanguagePack({
    companyId: companyPackageDetails?.id ?? "",
    setLoaderProgress: setLoaderProgress
  }
  );

  const onEditHandler = (pack: ApiSchemaLanguagePackData, platFormType: number) => {
    setDataToEdit(pack);
    setIsCopyLanguagePack(false);
    setSelectedPlatformType(platFormType)
    setShow(true);
  };

  const onCopyHandler = (pack: ApiSchemaLanguagePackData, platFormType: number) => {
    const copyLanguagePack = { ...pack, name: 'Copy of '.concat(pack.name) };

    setDataToEdit(copyLanguagePack);
    setIsCopyLanguagePack(true);
    setSelectedPlatformType(platFormType)
    setShow(true);
  };

  const onChangeHandler = (languagePackId: string) => {
    setSelectedLanguagePackId(languagePackId ?? "");
  };

  const onCopyLanguagePackHandler = (copyLanguagePackId?: string, languagePackEditId?: string) => {
    let selectedCompanyLanguagePackId = "";

    if (selectedPlatformType > 0 && companyPackageDetails?.languagePacks) {
      const languagePackToBeEdit = companyPackageDetails?.languagePacks?.filter((languagePackIndex: LanguagePack) => languagePackIndex.languagePack?.id === languagePackEditId);
      selectedCompanyLanguagePackId = languagePackToBeEdit[0]?.id ?? "";
    }

    onEditPack(selectedCompanyLanguagePackId ?? "", {
      languagePackId: copyLanguagePackId ?? "",
      companyId: "",
      platform: "",
    })
      .then(() => {
        setLoaderProgress(topLoaderProgress.complete);
        setShowSelectPackModal(false);
        setOnUpdateSuccess(!onUpdateSuccess)
      })
      .catch(() => {
        setShowSelectPackModal(false);
      });
  }

  const onSaveHandler = () => {
    onEditPack(selectedCompanyLanguageId, {
      languagePackId: selectedLanguagePackId ?? "",
      companyId: "",
      platform: "",
    })
      .then(() => {
        setLoaderProgress(topLoaderProgress.complete);
        setShowSelectPackModal(false);
        setOnUpdateSuccess(!onUpdateSuccess)
      })
      .catch(() => {
        setShowSelectPackModal(false);
      });
  };

  const onChooseAnother = (plaformtype: PlatformType, id?: string) => {
    // setTopLoadingBar(topLoaderProgress.start);
    setSelectedCompanyLanguageId(id ?? "");

    onChooseAnotherPackage(companyPackageDetails?.id ?? "", plaformtype).then((d) => {
      setLoaderProgress(topLoaderProgress.complete);
      var optionList = OptionMapper.mapperFunc(d);

      if (optionList) {
        setChooseAnotherPackageList(optionList);
        setShowSelectPackModal(true);
        setTopLoadingBar(topLoaderProgress.complete)
      }
    }
    );
  };

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {companyPackageDetails ?
        languagePacks.map((language, index) => (
          <div className="row flex-wrap pt-3 mb-3">
            <div className="col col-package-width">
              <LanguagePackComponent
                editable={editable}
                editCallBack={
                  editable
                    ? () => { onEditHandler(language.languagePack, language.platformType) } : undefined}
                copyable={copyable}
                copyCallBack={
                  copyable
                    ? () => { onCopyHandler(language.languagePack, language.platformType) } : undefined
                }
                languagePack={language.languagePack ?? undefined}
                languagePackName={`${language.languagePack.name}-${PlatformType[language.platformType]
                  }`}
              />
            </div>
            {editable && (
              <div className="col-package-btn pl-lg-0 pl-md-0 pl-sm-3 pl-3 ">
                <button
                  className="btn btn-primary svg-color btn-md-block "
                  type="button"
                  onClick={() => {
                    setPackNameAsProp(companyPackageDetails?.languagePacks ? companyPackageDetails?.languagePacks[index].languagePack.name : "")
                    onChooseAnother(
                      companyPackageDetails?.languagePacks
                        ? companyPackageDetails.languagePacks[index].platformType
                        : 0,
                      companyPackageDetails?.languagePacks
                        ? companyPackageDetails.languagePacks[index].id
                        : ""
                    );

                  }}
                >
                  <span >
                    <AssetSvg.Edit />
                  </span>
                  <span className="ml-2">
                    Choose Another
                  </span>
                </button>
              </div>
            )}
          </div>
        ))
        :
        <NoDataComponent title="Loading..." />
      }

      <ModalLanguagePackV2
        key={Date.now() + ''}
        show={show}
        handleShow={setShow}
        dataToEdit={dataToEdit}
        companyId={companyPackageDetails?.id ?? ""}
        onUpdateSuccess={onUpdateSuccess}
        setOnUpdateSuccess={setOnUpdateSuccess}
        //setDataToEdit={setDataToEdit}
        selectedPlatformType={selectedPlatformType}
        isCopyLanguagePack={isCopyLanguagePack}
        onCopyLanguagePackHandler={onCopyLanguagePackHandler}
      />
      <ModalSingleDropdown
        show={showSelectPackModal}
        handleShow={setShowSelectPackModal}
        header="Choose package"
        dropDownList={chooseAnotherPackageList}
        onChangeHandler={onChangeHandler}
        onSaveHandler={onSaveHandler}
        packNameAsProp={packNameAsProp}
      />
    </>
  );
}
