import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { ApiSchemaUpdatePassword } from "../../models";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  onSaveHandler: any;
};

export function ModalUserResetPassword(props: Props): JSX.Element {
  const { register, handleSubmit, formState: { errors }, setValue, setError } =
    useForm<ApiSchemaUpdatePassword>();
  const onChangePasswordHandler = (form: ApiSchemaUpdatePassword) => {
    props.onSaveHandler(form);
  };
  const [inputReadOnly, setInputReadyOnly] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInputReadyOnly(false);
    }, 500);
  }, []);
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <form onSubmit={handleSubmit(onChangePasswordHandler)}>
        {/*@ts-ignore */}
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {" "}
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  New Password
                </label>
                <input
                  type="password"
                  {...register('password', { required: true, minLength: 6 })}
                  className="form-control"
                  placeholder="New Password"
                  readOnly={inputReadOnly} />

                {errors.password && errors.password.type === "required" && (
                  <FormErrorMessage message="required" />
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <FormErrorMessage message="Please enter at least 6 characters." />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  {...register('retypePassword', { required: true, minLength: 6 })}
                  className="form-control"
                  placeholder="Confirm New Password"
                  readOnly={inputReadOnly} />

                {errors.retypePassword &&
                  errors.retypePassword.type === "required" && (
                    <FormErrorMessage message="required" />
                  )}
                {errors.retypePassword &&
                  errors.retypePassword.type === "minLength" && (
                    <FormErrorMessage message="Please enter at least 6 characters." />
                  )}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            onClick={() => props.handleShow(false)}
            className="btn btn-link text-uppercase text-site"
          >
            Cancel
          </div>
          <button
            type="submit"
            className="btn btn-primary text-uppercase user-from-submit-btn"
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
