import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { device } from '../../configs';
import { logger } from '../../utils';

type TypeNumberPadProps = {
  setDialedInput: Dispatch<SetStateAction<string>>;
};

export function NumberPad({ setDialedInput }: TypeNumberPadProps) {
  const numberClickHandler = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLButtonElement;
    device.activeConnection()?.sendDigits(value);
    logger({ message: 'Pressed Pin: ', data: value });
    setDialedInput((previous) => (previous += value));
  };

  return (
    <div className="key-row-wrapper">
      <div className="key-row">
        <button
          value="1"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          1
        </button>
        <button
          value="2"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          2
        </button>
        <button
          value="3"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          3
        </button>
      </div>
      <div className="key-row">
        <button
          value="4"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          4
        </button>
        <button
          value="5"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          5
        </button>
        <button
          value="6"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          6
        </button>
      </div>
      <div className="key-row">
        <button
          value="7"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          7
        </button>
        <button
          value="8"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          8
        </button>
        <button
          value="9"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          9
        </button>
      </div>
      <div className="key-row">
        <button
          value="*"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          *
        </button>
        <button
          value="0"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          0
        </button>
        <button
          value="#"
          className="btn btn-light shadow-sm ripple"
          onClick={numberClickHandler}
        >
          #
        </button>
      </div>
    </div>
  );
}
