import React, { useState } from "react";
import { Tab } from "../../../configs";

type Props = {
  tabList: Tab[];
  activeTabName: string;
  tabComponentProps?: any;
  loaderProgress?: number;
  setLoaderProgress?: any;
};
export function NavTab(props: Props) {
  const {
    tabList,
    activeTabName,
    tabComponentProps,
    loaderProgress,
    setLoaderProgress,
  } = props;

  const [handleTabId] = useState<any>();

  const [selectedTab, setSelectedTab] = useState<string>(activeTabName);


  return (
    <>
      <div className="my-acc-tab">
        <nav>
          <div className="nav nav-pills" id="profile-nav-tab" role="tablist">
            {tabList.map((tab) => (
              <a
                key={tab.tabId}
                className={
                  tab.name === activeTabName
                    ? "call-search-nav-link nav-link active"
                    : "nav-link call-search-nav-link"
                }
                id={tab.tabId}
                data-toggle="tab"
                href={`#${tab.referenceId}`}
                role="tab"
                aria-controls={tab.referenceId}
                onClick={() => setSelectedTab(tab.name)}
              >
                {tab.name}
              </a>
            ))}
          </div>
        </nav>

        <div
          className="tab-content profile-tab-content-container"
          id="nav-tabContent"
        >
          {tabList.map((tab) => (
            <div>
              {tab.name === selectedTab && (
                <div
                  className={
                    tab.name === activeTabName
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                  id={tab.referenceId}
                  role="tabpanel"
                  aria-labelledby={tab.tabId}
                >
                  <div>
                    {tab.component(
                      tabComponentProps,
                      loaderProgress,
                      setLoaderProgress
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
