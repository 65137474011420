import { Modal } from 'react-bootstrap'
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { ApiSchemaLateFee } from '../../../models/api/companyContract';
import { FormErrorMessage } from '../../../components';
import { useFunctionalityCompanyContract } from '../../../pages/hooks/functionalityCompanyContract';
import { Subscription } from "../../../models";
import { useFunctionalityInputChecker } from '../../../pages/hooks/functionalityInputChecker';
import { useEffect, useState } from "react";

type Props = {
	id: string,
	show: boolean,
	handleShow: (value: boolean) => void,
	data?: Subscription,
	onUpdateSuccess?: any,
	setOnUpdateSuccess?: any,
}

const months = [
	{
		label: '1',
		value: 1
	},
	{
		label: '2',
		value: 2
	},
	{
		label: '3',
		value: 3
	}
]

export function ModalLateFee(props: Props) {
	const { id, show, handleShow, data, onUpdateSuccess, setOnUpdateSuccess } = props;
	const { register, setValue, formState: { errors }, handleSubmit } = useForm<ApiSchemaLateFee>();
	const { onUpdateLateFee } = useFunctionalityCompanyContract();
	const { handleSpecialCharacterWhiteSpaces, numberFieldValidation, handleCopyPasteInvalidValue, handleLimitDecimalPlaces } = useFunctionalityInputChecker();
	const [lateFeeRateError, setLateFeeRateError] = useState<string>('');

	const LateFeeUpdateHandler = (form: ApiSchemaLateFee) => {
		onUpdateLateFee(id, {
			lateFeeRate: form.lateFeeRate ? parseFloat(form.lateFeeRate.toString()) : data?.lateFeeRate ?? 0,
			lateTimeMonthLimit: form.lateTimeMonthLimit ? parseInt(form.lateTimeMonthLimit.toString()) : data?.lateTimeMonthLimit ?? 0
		}).then(() => {
			handleShow(false);
			setOnUpdateSuccess(!onUpdateSuccess);
		}).catch(e => console.error(e));
	}

	const handleLateFeeRateValidation = (e: any) => {
		const rate = Number(e.target.value);
		if (rate > 100) {
			setLateFeeRateError('Late fee rate is invalid');
		} else {
			setLateFeeRateError('');
		}
	}

	const initializeErrorState = () => {
		setLateFeeRateError('');
	}

	return (
        <Modal
			show={show}
			onHide={() => {
				initializeErrorState();
				handleShow(false);
			}}
			centered
		>
			<form onSubmit={handleSubmit(LateFeeUpdateHandler)}>
				{/*@ts-ignore */}
				        {/*@ts-ignore */}
        <Modal.Header closeButton>
					<Modal.Title>Update Late Fee</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="" className="position-relative required">Select the number of months after which the late fee will be added</label>
								<input
                                    className="d-none"
                                    {...register(
                                        'lateTimeMonthLimit',
                                        { required: data?.lateTimeMonthLimit ? false : true },
                                    )} />
								<Select
									className={` ${errors.lateTimeMonthLimit?.type == "required" ? 'field-required' : ''} `}

									placeholder={data?.lateTimeMonthLimit ? data?.lateTimeMonthLimit : "Select period"}
									// placeholder="Select period"
									components={{
										IndicatorSeparator: () => null,
									}}
									options={months}
									// defaultValue={}
									onChange={(val) => setValue('lateTimeMonthLimit', val?.value ? val?.value : (data?.lateTimeMonthLimit ? data?.lateTimeMonthLimit : 0 ), {
										shouldValidate: true,
									})}
								/>
								{
									errors.lateTimeMonthLimit && (
										<FormErrorMessage message="required" />
									)
								}
							</div>
							<div className="form-group">
								<label htmlFor="" className="position-relative required">Amount that will be charged on the total bill as late fee</label>
								<div className="input-group ">
									<div className="input-group-prepend">
										<span
											className="input-group-text"
											id="flat-rate"
										>
											%
										</span>
									</div>
									<input
                                        type="float"
                                        className="form-control"
                                        {...register('lateFeeRate', { required: true, validate: handleCopyPasteInvalidValue })}
                                        defaultValue={data?.lateFeeRate}
                                        onChange={(evt: any) => {
											// 	setValue("lateFeeRate", Number(evt.target.value), {
											// 		shouldValidate: true,
											// 	});
											handleLateFeeRateValidation(evt)
										}}
                                        onKeyPress={(event) => {
											if (!/[0-9]|\./.test(event.key)) {
												event.preventDefault();
											}
										}}
                                        onKeyDown={(evt) => numberFieldValidation(evt)}
                                        onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
									{
										errors.lateFeeRate && errors.lateFeeRate.type === "required" && (
											<FormErrorMessage message="required" />
										)
									}

									{errors.lateFeeRate && errors.lateFeeRate.type === "validate" && (
										<FormErrorMessage message="Please enter a valid value" />
									)}
									{
										lateFeeRateError && <FormErrorMessage message={lateFeeRateError} />
									}
								</div>
							</div>
						</div>
					</div>

				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						className="btn btn-link text-site text-uppercase"
						onClick={() => {
							initializeErrorState();
							handleShow(false);
						}}
					>
						Cancel
					</button>
					<button
						type="submit"
						className="btn btn-primary text-uppercase update-from-submit-btn"
						disabled={!!lateFeeRateError}
					>
						Save
					</button>
				</Modal.Footer>
			</form>
		</Modal>
    );
}
