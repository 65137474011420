import React from "react";
import useSWR, { mutate } from "swr";
import {AppConstants, PlatformApi, topLoaderProgress} from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  ApiSchemaCreateJob,
  ApiSchemaJobList,
  CompanyLanguagePackUpdateModel,
} from "../../models";
import { ApiSchemaEditJob } from "../../models/api/jobs";
import { UtilsJQuery } from "../../utils";
import { toast } from "react-toastify";
import { platform } from "os";

type Props = {
  companyId?: string | "";
  setLoaderProgress?: any;
};
export function useFunctionalityCompanyLanguagePack(props: Props) {
  const {companyId, setLoaderProgress} = props;

  const companyUrl=PlatformApi.Company.GetById(companyId??"");
  const [infoBoxData, setInfoBoxData] = React.useState<[string, string][]>();
  const [errMsg, setErrMsg] = React.useState<string>();

  const onEditPack = React.useCallback(async (id:string,form: CompanyLanguagePackUpdateModel) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    setLoaderProgress(topLoaderProgress.start);
    try {
      const res = await AxiosAuth.put(
        PlatformApi.Company.UpdateCompanyPack(id ?? ""),
        form
      );
      if (res.status === AppConstants.Api.StatusOK) {
        setLoaderProgress(topLoaderProgress.complete);
        mutate(companyUrl);
        toast.success("Package changed successfully");
      }
    } catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      setErrMsg((e as Error).message);
      toast.error("Package changed failed");
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    infoBoxData,
    onEditPack,
    errMsg,
  };
}
