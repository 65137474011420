import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { AssetSvg } from "../../../assets";
import { ChatType } from "../../../configs";
import { ChannelContext } from "../../../core";
import { AllContactList } from "./allContactList";
import { GroupList } from "./groups";
import { RecentConversationList } from "./recentConversationList";

export function ChatHome(): JSX.Element {
  const [searchValue, setSearchValue] = React.useState<string>("");
  // const [chatType, setChatType] = React.useState<ChatType>(1)
  const { selectedChannel, setSelectedChannel, setCurrentConvo, setConvoType, chatType, setChatType } =
  React.useContext(ChannelContext);
  const handleChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const chatTypeHandle = (type: ChatType) => {
    setChatType(type)
  }

  const clickHandler = () => {
    if (!selectedChannel) {
      setChatType(0)
      setCurrentConvo(undefined)
      setConvoType(0)
      setSelectedChannel(undefined)
    }
    else {
      setChatType(1)
      setCurrentConvo(undefined)
      setConvoType(0)
      setSelectedChannel(undefined)
    }
  }


  return (
    <>
      {/* search bar */}
      <div className="col-12 py-2">
      <div className="row align-items-center" >
            <button className="btn btn-link" onClick={(e) => {
              e.preventDefault()
              clickHandler()
            }}>
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-muted"
              />
            </button>
            <div className="input-group col pl-0">
          <input
            type="text"
            className="search-input-control form-control"
            aria-label="search-input"
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button
              className="btn general-search-button border-grey py-0"
              type="button"
            >
              <AssetSvg.SearchIcon />
            </button>
          </div>
        </div>
          </div>
      
      </div>
      {/* search bar */}

      <div className="col-12">
        <ul className="nav justify-content-between site-chat-nav">

          <li className="nav-item py-1 col pl-1 pr-1">
            <a
              className="nav-link active text-center px-0"
              id="recent-chat-tab"
              data-toggle="tab"
              href="#recentChatTab"
              role="tab"
              // aria-controls="recentChatTab"
              // aria-selected="true"
              onClick={() => { chatTypeHandle(ChatType.Recent) }}
            >
              <AssetSvg.Conversations />
              <div className="font-sz-13 font-500 mt-2 text-nowrap">
                Recent
              </div>
            </a>
          </li>
          <li className="nav-item py-1 col pl-1 pr-1">
            <a
              className="nav-link text-center px-0"
              id="chat-contacts-tab"
              data-toggle="tab"
              href="#chatContactsTab"
              role="tab"
              // aria-controls="chatContactsTab"
              aria-selected="false"
              onClick={() => { chatTypeHandle(ChatType.AllContact) }}
            >
              <AssetSvg.Contacts />
              <div className="font-sz-13 font-500 mt-2 text-nowrap">
                All Contacts
              </div>
            </a>

          </li>
          <li className="nav-item py-1 col pl-1 pr-1">
            <a
              className="nav-link text-center px-0"
              id="chat-groups-tab"
              data-toggle="tab"
              href="#ChatGroupsTab"
              role="tab"
              // aria-controls="ChatGroupsTab"
              aria-selected="false"
              onClick={() => { chatTypeHandle(ChatType.Group) }}
            >
              <AssetSvg.Groups />
              <div className="font-sz-13 font-500  mt-2 text-nowrap">
                Groups
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="border-bottom"></div>

      <div className="tab-content">
        {chatType == ChatType.Recent && <div
        // className="tab-pane active"
        // id="recentChatTab"
        // role="tabpanel"
        // aria-labelledby="recent-chat-tab"
        >
          <RecentConversationList searchValue={searchValue} setType={setChatType} />
        </div>}
        {chatType == ChatType.AllContact && <div
          // className="tab-pane"
          // id="chatContactsTab"
          role="tabpanel"
        // aria-labelledby="chat-contacts-tab"
        >
          <AllContactList searchValue={searchValue} />
        </div>}
        {chatType == ChatType.Group && <div
          // className="tab-pane"
          // id="ChatGroupsTab"
          role="tabpanel"
        // aria-labelledby="chat-groups-tab"
        >
          <GroupList searchValue={searchValue} />
        </div>}
      </div>
    </>
  );
}
