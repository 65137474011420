import { Modals,   TwilioNumber } from "../../configs";
import { BootstrapUtils } from "../../utils/bootstrap";

export function ModalLobbyAudioCallData(props: any): JSX.Element {
  return (
    <div
      className="modal fade "
      tabIndex={-1}
      role="dialog"
      aria-labelledby="lobbyAudioCallData"
      aria-hidden="true"
      id={Modals.LobbyAudioCallData}
    >
      <div className="modal-dialog " role="document">
        <div className="modal-content">
          <div className="modal-header">
            Join and use a phone for audio
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="" aria-hidden="true">
                {" "}
                &times;{" "}
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label htmlFor="" className="text-muted font-sz-12">
                  On your phone, dial
                </label>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="" className="text-muted font-sz-12">
                  {TwilioNumber.ivrNumberForVideoConference}
                </label>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="" className="text-muted font-sz-12">
                  Enter this pin, when prompted
                </label>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="" className="text-muted font-sz-12">
                  {props?.conferenceTin}
                </label>
              </div>
            </div>

            <div
              className="form-row 
                justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
                flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
                mx-0"
            >
              <button
                className="  btn btn-outline-dark border-grey 
                  d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                  flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                  justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
                onClick={() => {
                  BootstrapUtils.ModalHideById(Modals.LobbyAudioCallData);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
