import { Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  deleteHandler: any;
  msg?: string;
};

export function ModalConfirmDelete(props: Props): JSX.Element {
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <form action="">
        <Modal.Body>
          Are you sure you want to {props.msg ?? " delete"}?
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="delete-button btn btn-primary text-uppercase
						user-from-submit-btn"
            onClick={props.deleteHandler}
          >
            {props.msg ?? " Delete"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
