import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaLocationData, WithPagination } from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  companyId: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
};

let pagedLocationUrl: string;

export function useFunctionalityPagedLocation(props: Props) {

  const { companyId, pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedLocationListCount, setPagedLocationListCount] = useState<number>(0);

  pagedLocationUrl = PlatformApi.Location.PagedGetAll(companyId, pageSize, pageNo, searchText);

  const { data: apiPagedLocationList } = useSWR<ApiSchemaLocationData[]>(
    pagedLocationUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLocationData[]>>>(
        pagedLocationUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedLocationListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedLocationList = React.useMemo(() => {
    const data = Array.isArray(apiPagedLocationList) ? apiPagedLocationList : [];
    return data;
  }, [apiPagedLocationList]);
  return {
    pagedLocationList,
    pagedLocationListCount,
  };
}
type UserProps = {
  companyId: string;
  shouldLocationGetAllInvoke?: boolean;
  setSelectedLocation?: Function;
};
export function useFunctionalityLocation(props: UserProps) {
  const { companyId, shouldLocationGetAllInvoke = false, setSelectedLocation } = props;
  const locationUrl = PlatformApi.Location.GetAll(companyId);
  const locationCreateUrl = PlatformApi.Location.Create();
  //const locationDeleteUrl = PlatformApi.Location.Delete("");

  const { data: apiLocationList } = useSWR<ApiSchemaLocationData[]>(shouldLocationGetAllInvoke ? locationUrl : null, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLocationData[]>>>(locationUrl)
      .then((r) => r.data.data?.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const optionMappedLocationList = React.useMemo(() => {
    const data = Array.isArray(apiLocationList)
      ? OptionMapper.mapperFunc(apiLocationList)
      : [];
    return data;
  }, [apiLocationList]);

  const onAddLocation = React.useCallback(async (form: ApiSchemaLocationData) => {
    const btnLoading = UtilsJQuery.Ladda(".location-form-submit-btn");
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.post(locationCreateUrl, (form = { ...form, companyId: companyId || "" }));

      if (response.status === AppConstants.Api.StatusOK) {
        setSelectedLocation?.(response.data.data)
        toast.success("Location added successfully");
        mutate(locationUrl);
        mutate(pagedLocationUrl);
      }
    } catch (e) {
      toast.error("Failed to add location.");
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const onEditLocation = React.useCallback(
    (editForm: ApiSchemaLocationData, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(".location-form-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(PlatformApi.Location.Update(id), editForm)
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            toast.success("Location edited successfully");
            mutate(locationUrl);
            mutate(pagedLocationUrl);
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    }, []);

  const onDeleteLocation = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        PlatformApi.Location.Delete(id || "")
      );


      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Location deleted successfully");
        mutate(pagedLocationUrl);
      }
    } catch (e) {
      toast.error("Failed to delete Location.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    optionMappedLocationList,
    onAddLocation,
    onEditLocation,
    onDeleteLocation,
  };
}

type locationProps = {
  locationId: string;
};
export function useFunctionalityLocationDetails(props: locationProps) {
  const { locationId } = props;
  const locationDetailsUrl = PlatformApi.Location.GetDetailsById(locationId);

  const { data: apiLocationDetails } = useSWR<ApiSchemaLocationData>(locationId ? locationDetailsUrl : null, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLocationData>>>(locationDetailsUrl)
      .then((r) => {
        return r.data?.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const locationDetails = React.useMemo(() => {
    const data = typeof apiLocationDetails === 'object' ? apiLocationDetails : null;
    return data;
  }, [apiLocationDetails]);

  return {
    locationDetails
  };
}
