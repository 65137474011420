import { useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { ResellerTabs } from '../../configs';
import { AppRouteUi } from '../../configs/appRoute';
import { NavTab } from '../../features/Common/Components';
import { CompanyProfileInfo, CompanyProfileTopBar } from '../../features/CompanyProfile';
import { ApiSchemaCompanyByIdData } from '../../models';
import { useFunctionalityReseller } from '../hooks/functionalityReseller';

export function PageResellerProfile(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.Reseller.Profile.useParam();
  const { companyByIdData } = useFunctionalityReseller({ setLoaderProgress: setLoaderProgress, parentId: companyId, shouldVendorGetAllInvoke: true });
  const companyProfileData: ApiSchemaCompanyByIdData = companyByIdData();

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <CompanyProfileTopBar companyName={companyProfileData?.name} heading="Resellers" />
      <div className="row mt-3">
        <div className="col-profile-left-width">
          <CompanyProfileInfo companyProfileData={companyProfileData} />
        </div>
        <div className="col-profile-right-width">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <NavTab
                  tabList={ResellerTabs}
                  activeTabName={ResellerTabs[0].name}
                  tabComponentProps={companyProfileData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

