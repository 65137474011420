import { Modal, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { AgentBills, MiscellaneousDetails, } from "../../models";
import { useFunctionalityBookingAgentBillsDetails } from "../../pages/hooks";
import { FileBucketURL, PlatformType } from "../../configs";
import { handleNumberToTwoDecimalPoint } from "../../utils";
import { AssetSvg } from "../../assets";
type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  spiltDuration: (duration: any) => number[];
  bookingId?: string;
  bookingAgentBills?: AgentBills;
  jobPlatformType?: PlatformType
};

export function ModalViewAgentBills(props: Props): JSX.Element {
  const { bookingId, bookingAgentBills, jobPlatformType } = props;
  const [agentBillsDetails, setAgentBillsDetails] = useState<any>({});

  const { onCompanyBookingAgentBillsDetails } =
    useFunctionalityBookingAgentBillsDetails({
      bookingId: bookingId ?? "",
      operatorUserId: bookingAgentBills?.operatorUserId ?? "",
    });

  useEffect(() => {
    onCompanyBookingAgentBillsDetails()?.then((res) => {
      setAgentBillsDetails(res?.data);
    });
  }, []);

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleShow(false);
        }}
        size="lg"
        centered
      >
        <div>
                  {/*@ts-ignore */}
        <Modal.Header closeButton>
            <Modal.Title className="test-modal">
              Agent Bills Details-{agentBillsDetails?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Duration: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div>
                        {
                          props.spiltDuration(
                            agentBillsDetails?.duration
                          )[0]
                        }
                        h &nbsp;
                        {
                          props.spiltDuration(
                            agentBillsDetails?.duration
                          )[1]
                        }
                        m &nbsp;
                        {
                          props.spiltDuration(
                            agentBillsDetails?.duration
                          )[2]
                        }
                        s
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Agent Rate: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div><span className={agentBillsDetails?.agentRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.agentRate ? agentBillsDetails?.agentRate : 0)}</span></div>
                    </div>
                  </div>
                </div>
                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>  {agentBillsDetails?.mileage! < 0 ?
                          <span className="text-danger">(${Math.abs(agentBillsDetails?.mileage ? agentBillsDetails?.mileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>
                          :
                          <span>{(agentBillsDetails?.mileage ? agentBillsDetails?.mileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        } miles</div>
                      </div>
                    </div>
                  </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Maximum Free Mileage: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>  {agentBillsDetails?.maximumFreeMileage! < 0 ?
                          <span className="text-danger">(${Math.abs(agentBillsDetails?.maximumFreeMileage ? agentBillsDetails?.maximumFreeMileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>
                          :
                          <span>{(agentBillsDetails?.maximumFreeMileage ? agentBillsDetails?.maximumFreeMileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        }miles</div>
                      </div>
                    </div>
                  </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage Rate: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div><span className={agentBillsDetails?.mileageRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.mileageRate ? agentBillsDetails?.mileageRate : 0)}</span></div>
                      </div>
                    </div>
                  </div>
                }

              </div>

              <div className="col-md-12 col-lg-6">
                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Bill: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div><span className={agentBillsDetails?.bill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.bill ? agentBillsDetails?.bill : 0)}</span></div>
                    </div>
                  </div>
                </div>
                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage Bill: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div><span className={agentBillsDetails?.mileageBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.mileageBill ? agentBillsDetails?.mileageBill : 0)}</span></div>
                      </div>
                    </div>
                  </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Total Miscellaneous Bill: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div><span className={agentBillsDetails?.totalMiscellaneousBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.totalMiscellaneousBill ? agentBillsDetails?.totalMiscellaneousBill : 0)}</span></div>
                      </div>
                    </div>
                  </div>
                }


                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Total Bill: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <p>
                        <strong className={agentBillsDetails?.totalBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(agentBillsDetails?.totalBill ? agentBillsDetails?.totalBill : 0)}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {agentBillsDetails?.miscellaneousBills?.map(
              (miscellaneousData: MiscellaneousDetails) => (
                <>
                  <div className="component-card mb-3">
                    <div className="component-card-header border-top border-right border-left">
                      Miscellaneous
                    </div>
                    <div className="component-card-body border border-top-0  p-0">
                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th scope="col">Description</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Documents</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">
                                {miscellaneousData?.description ?? "-"}
                              </td>
                              <td scope="row">{miscellaneousData?.amount}</td>
                              <td scope="row">
                                <button
                                  className="btn btn-link text-site px-0"
                                  onClick={() =>
                                    window.open(
                                      `${FileBucketURL?.Root}${miscellaneousData?.fileKey}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <div
                                    className="btn svg-color"
                                    title="miscellaneousDocumentView"
                                  >
                                    <span >
                                      <AssetSvg.ViewProfile />
                                    </span>
                                  </div>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
      </Modal >
    </>
  );
}
