import React, { useState } from "react";
import { CallInitiateParam, CallInitiateTabs, PlatformType } from "../../configs/appConstants";
import { AudioCallInitiateParameter, videoCallInitiateParameter, } from "../../models";
import { ApiSchemaFilterOperatorRequest } from "../../models/api/Operator";
import { useFunctionalityDepartment, useFunctionalityLanguage, useFunctionalityLocation, useFunctionalitySkillNameByCompanyId } from "../../pages/hooks";
import { SearchInterpreterForm } from "./searchInterpreterForm";
import { useFunctionalityGender } from "../../pages/hooks/functionalityOperatorCriteria";
import { ActionVideoCall, useAppDispatch, useAppSelector } from "../../core";
import { useForm } from "react-hook-form";
import CallInitiateButtonSearchInterpreter from "../Common/Components/CommonCall/callInitiateButtonSearchInterpreter";
import { UserMediaUtils,UtilsJQuery } from "../../utils";

interface PropsType {
  pagedOperatorCriteriaList: any
}

export function SearchInterpreterVri({ pagedOperatorCriteriaList }: PropsType): JSX.Element {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.auth.profile?.companyId || "");
  const userId = useAppSelector((state) => state.auth.profile?.id);
  const companyAccountStatus = useAppSelector((state) => state.auth?.menuSettings?.accountStatus);

  const { languageList } = useFunctionalityLanguage({ plaformType: PlatformType.VRI, shouldLanguageGetAllInvoke: true });
  const { getAllSkillNameList } = useFunctionalitySkillNameByCompanyId(userId);
  const { optionMappedGenderList } = useFunctionalityGender();
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: companyId, shouldLocationGetAllInvoke: true
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: companyId, shouldDepartmentGetAllInvoke: true
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<CallInitiateParam>();

  const [language, setLanguage] = React.useState("");
  const [languageName, setLanguageName] = React.useState("");
  const [skill, setSkill] = React.useState<any>(null);
  const [gender, setGender] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [location, setLocation] = React.useState<any>(null);
  const [customFields, setCustomFields] = React.useState<any>([]);
  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
  const [matchedRequiredFieldCount, setMatchedRequiredFieldCount] = React.useState(0);

  const getFilterOperatorParam = () => {
    const requestModel: ApiSchemaFilterOperatorRequest = {
      languageId: language,
      languageName: languageName,
      skillId: skill,
      gender: Number(gender) || 0,
      customFields: customFields
    };
    return requestModel;
  };

  const btnLoading = UtilsJQuery.Ladda(".vri-call-btn-submit");
  const [isMediaAllowed, setIsMediaAllowed] = useState<boolean>(false)
  const checkPermission = async () => {
    const tracks = await UserMediaUtils.startMedia();
    tracks && dispatch(ActionVideoCall.setTracks(tracks));
    if (tracks) {
      setIsMediaAllowed(true)
    } else {
      setIsMediaAllowed(false)
    }
  }

  const getVideoCallInitiatorParam = () => {
    const model: videoCallInitiateParameter = {
      LanguageName: languageName,
    };
    return model;
  };
  const getAudioCallInitiateParam = () => {
    const model: AudioCallInitiateParameter = {
      operatorLanguageName: languageName,
    };
    return model;
  };

  const optionSkillUpdatedList = [
    {
      value: "",
      label: 'Any'
    },
    ...getAllSkillNameList,
  ];

  const submitHandler = async (form: CallInitiateParam) => {
    setIsFormSubmitSuccess(false);
    setLanguage(form?.Language);
    setSkill(form?.Skill ? form?.Skill : null);
    setGender(form?.Gender);
    setDepartment(form?.Department);
    setLocation(form?.Location);

    btnLoading.start?.();
    await checkPermission()
    setIsFormSubmitSuccess(true);
    btnLoading.stop?.();
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <SearchInterpreterForm
        languageList={languageList}
        language={language}
        setLanguage={setLanguage}
        setLanguageName={setLanguageName}
        optionMappedSkillList={optionSkillUpdatedList}
        optionSkillListForDefaultValue={optionSkillUpdatedList}
        optionMappedGenderList={optionMappedGenderList}
        optionMappedDepartmentList={optionMappedDepartmentList}
        optionMappedLocationList={optionMappedLocationList}
        skill={skill}
        setSkill={setSkill}
        gender={gender}
        setGender={setGender}
        department={department}
        setDepartment={setDepartment}
        location={location}
        setLocation={setLocation}
        customFields={customFields}
        setCustomFields={setCustomFields}
        matchedRequiredFieldCount={matchedRequiredFieldCount}
        setMatchedRequiredFieldCount={setMatchedRequiredFieldCount}
        register={register}
        errors={errors}
        setValue={setValue}
        pagedOperatorCriteriaList={pagedOperatorCriteriaList}
      />
      <CallInitiateButtonSearchInterpreter
        operatorFilterParam={getFilterOperatorParam()}
        callInitiateTab={CallInitiateTabs.searchInterpreter}
        videoCallInitiateParam={getVideoCallInitiatorParam()}
        audioCallInitiateParam={getAudioCallInitiateParam()}
        isFormSubmitSuccess={isFormSubmitSuccess}
        initiateTab={PlatformType.VRI}
        companyAccountStatus={companyAccountStatus}
        isMediaAllowed={isMediaAllowed}
      />
    </form>
  );
}
