import { useCallback, useEffect, useState } from "react";
import { mutate } from "swr";
import { ExportOptions } from "..";
import { AssetSvg } from "../../assets";
import { ModalColumnSelection } from "../modals/columnSelectionModal";
import { ColumnSelectionsModel } from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import { debounce } from "../../utils";

type Props = {
  showFilter?: boolean;
  showExportOptions?: boolean;
  filterPanel?: string;
  searchText?: string;
  handleSearch?: any;
  columnSelectionModalShow?: boolean;
  setColumnSelectionModalShow?: (value: boolean) => void;
  mutateUrl?: string;
  columnSelection?: ColumnSelectionsModel[];
  setColumnSelection?: any;
  showAutoRefresh?: boolean;
  tableType?: number;
  filterData?: ApiSchemaFilter;
  downloadedFileName?: string;
  companyId?: string;
  callCenterId?: string;
  setLoaderProgress?: any;
  setIsShowCommonFilter?: any
};
export function Searchbar(props: Props): JSX.Element {
  const {
    showFilter,
    showExportOptions,
    filterPanel,
    searchText,
    handleSearch,
    columnSelectionModalShow,
    setColumnSelectionModalShow,
    mutateUrl,
    columnSelection,
    setColumnSelection,
    showAutoRefresh,
    tableType,
    filterData,
    downloadedFileName,
    companyId,
    callCenterId,
    setLoaderProgress,
    setIsShowCommonFilter
  } = props;
  const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(true);
  const [input, setInput] = useState('');

  const debounceSearch = useCallback(
    debounce((event: Event) => {
      handleSearch(event);
    }, 1000),
    []
  );

  useEffect(() => {
    let intervalId: any;
    if (isAutoRefresh) {
      intervalId = setInterval(() => {
        mutate(mutateUrl!);
      }, 60000);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isAutoRefresh, mutateUrl]);

  return (
    <div className="input-group">
      <input
        minLength={1}
        type="text"
        className="form-control"
        aria-label="search-input"
        placeholder="Search"
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        onKeyUp={(event) => {
          debounceSearch(event);
        }}
      />

      <div className="input-group-append main-site-search-buttons">
        <button
          className="btn border-grey search-input-btn svg-color"
          type="button"
          // onClick={handleSearch}
        >
          <AssetSvg.SearchIcon />
        </button>
        {showFilter ? (
          <button
            className="btn border-grey svg-color search-filter-btn"
            type="button"
            data-toggle="collapse"
            data-target={`#${filterPanel}`}
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => {
              setIsShowCommonFilter((prev: boolean) => !prev);
            }}
          >
            <AssetSvg.Filter />
          </button>
        ) : null}

        {showAutoRefresh ? (
          <button
            className={
              isAutoRefresh
                ? 'btn  btn-refresh-on'
                : 'btn  svg-color btn-refresh-off'
            }
            onClick={() => {
              setIsAutoRefresh((prev) => !prev);
            }}
          >
            <span>
              <AssetSvg.Refresh />
            </span>
          </button>
        ) : null}

        {showExportOptions ? (
          <>
            <button
              className="btn dropdown-toggle border-grey export-btn"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <ExportOptions
              columnSelection={columnSelection}
              tableType={tableType}
              filterData={filterData}
              downloadedFileName={downloadedFileName}
              companyId={companyId}
              callCenterId={callCenterId}
              searchText={searchText}
              columnSelectionModalShow={columnSelectionModalShow}
              setColumnSelectionModalShow={setColumnSelectionModalShow}
              setLoaderProgress={setLoaderProgress}
            />
          </>
        ) : null}
      </div>
      <ModalColumnSelection
        show={columnSelectionModalShow}
        handleShow={setColumnSelectionModalShow}
        columns={columnSelection}
        setColumns={setColumnSelection}
        modalTitle={'Column selection'}
      />
    </div>
  );
}
