import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { StorageUserCalendar } from '../../../core';
import { StorageAuth } from '../../../core/LocalStorage/StorageAuth';
import { useReduxHelper } from '../../../pages/hooks';

function GoogleLogoutCom(): JSX.Element {
  const { resetAllReduxData } = useReduxHelper();

  const onLogout = React.useCallback(() => {
    StorageAuth.loginStatus = 'false';
    StorageAuth.Clear();
    StorageUserCalendar.Clear();
    resetAllReduxData();
  }, [resetAllReduxData]);

  return (
    <button className="btn w-100" onClick={onLogout}>
      <span className="m-2">
        <FontAwesomeIcon icon={faGoogle} />
      </span>
      Log out
    </button>
  );
}

export default GoogleLogoutCom;
