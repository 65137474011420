import { Address, PlatformType } from ".";
import {
    BookingCustomFieldType,
    BookingStatusType,
    GenderType,
} from "../../configs";

export interface ApiSchemaJobData {
    address?: string;
    assignedTo?: string;
    city?: string;
    company?: string;
    country?: string;
    countryName?: string;
    date?: string;
    durationHours?: string;
    durationMinutes?: string;
    extraInformation?: string;
    gender?: string;
    jobId: string;
    language?: string;
    languageName?: string;
    noOfInterpreter?: string;
    preferredInterpreter?: string;
    rate: string;
    requestType?: string;
    requestTypeName?: string;
    requestedBy?: string;
    reseller?: string;
    service?: string;
    serviceType?: string;
    siteContact?: string;
    startDate?: string;
    startTime?: string;
    state?: string;
    stateName?: string;
    status?: string;
    timeZone?: string;
    zipCode?: string;
}

export interface ApiSchemaStatusCountList {
    Open?: number;
    Offered?: number;
    Assigned?: number;
    Confirmed?: number;
    Billed?: number;
    Paid?: number;
    Closed?: number;
    Cancelled?: number;
    Unfulfilled?: number;
    NonAttendance?: number;
}

export interface ApiSchemaGetAllJobData {
    pagedBookingList: ApiSchemaLPagedJob;
    statusCountList: [string, string][];
}

export interface ApiSchemaCreateJob {
    preferredInterpreterId?: string;
    skillId?: string;
    gender?: GenderType;
    commonNote?: string;
    consumerType: string;
    consumer: string;
    modalityId?: string;
    appointmentCategoryId?: string;
    appointmentDescriptionId?: string;
    location?: string | null;
    address?: Address;
    bookingCustomFields: BookingCustomFieldRequestModel[];
    requestedBy: string;
    serviceType: number;
    noOfInterpreter: number;
    siteContact?: string;
    language: string;
    // requestType?: string | null;
    startDate: string;
    startTime: string;
    durationHours: string;
    durationMinutes: string;
    timeZone: string;
    extraInformation?: string;
    rate?: string;
    companyId?: string;
}

export interface ApiSchemaJobList {
    id?: string;
    serialNumber?: string;
    date?: string;
    requestedBy?: string;
    reseller?: string;
    company?: string;
    serviceType?: PlatformType;
    language?: string;
    // requestTypeId?: string;
    requestType?: string;
    gender?: GenderType;
    // assignedToId?: string;
    assignedToNames?: string[];
    status?: BookingStatusType;
    isUrgent?: boolean;
    bookingCustomField1?: string;
    bookingCustomField2?: string;
    bookingCustomField3?: string;
    bookingCustomField4?: string;
}

export interface ApiSchemaLPagedJob {
    pageIndex: string;
    pageSize: string;
    count: string;
    data: ApiSchemaJobList[];
}

export interface ApiSchemaEditJob {
    jobId: string;
    startDate?: string;
    startTime?: string;
    durationHours?: string;
    durationMinutes?: string;
    timeZone?: string;
}

export interface ITypeMinimumTimesByPlatform {
    platform: number;
    minimumTime: any;
}

export interface ApiSchemaJobFormFields {
    isSkillEnabled: boolean;
    isGenderEnabled: boolean;
    isVideoEnabled: boolean;
    isAudioEnabled: boolean;
    isFaceToFaceEnabled: boolean;
    bookingCustomFieldModels: BookingCustomFieldRequestModel[];
    minimumTimesByPlatform: ITypeMinimumTimesByPlatform[];
}

export interface ApiSchemaJobDataById {
    id: string;
    serialNo: string;
    requestedBy: string;
    requestedById: string;
    companyName: string;
    companyId: string;
    email: string;
    start: string;
    date: string;
    startTime: string;
    endTime: string;
    end?: string;
    durationHours: string;
    durationMinutes: string;
    languageName: string;
    requestType: string;
    consumerType: string;
    consumer: string;
    customerNote: string;
    operatorNote: string;
    adminNote: string;
    modality: string;
    appointmentCategory: string;
    appointmentDescription: string;
    genderType: GenderType;
    extraInformation: string;
    location: string;
    address: Address;
    status: number;
    actualStartTime: string;
    actualEndTime: string;
    isAlreadyAccepted?: boolean;
    platformType?: number;
    assignedOperatorId?: string;
    distance: string;
    videoConferenceRoomId: string;
    clientBookingInvoiceId: string;
}

export interface ApiSchemaJobDetailsById {
    id: string;
    serialNo: string;
    companyName: string;
    siteContact: string;
    serviceType: PlatformType;
    date: string;
    startTime: string;
    durationHours: string;
    durationMinutes: string;
    languageName: string;
    requestType: string;
    rate: number;
    noteForFinance: string;
    customerNote: string;
    operatorNote: string;
    adminNote: string;
    appointedOperators: AppointedOperator[];
    noOfOperator: number;
    genderType: GenderType;
    extraInformation: string;
    status: number;
    location: string;
    address: Address;
    bookingCustomFields: BookingCustomFieldRequestModel[];
    bookingHistories: BookingCustomHistoryModel[];
    timeZone?: any;
}

export interface AppointedOperator {
    id: string;
    name: string;
}

export interface ApiSchemaJobVosFormById {
    actualStart: string;
    actualEnd: string;
    mileage?: any;
    vosFormFile?: any;
}

/**************Request Model *********** */

export interface CreateBookingRequestModel {
    noOfInterpreter: number | null;
    siteContact: string;
    startDate: string;
    startTime: string;
    durationHours: string;
    durationMinutes: string;
    timeZone: string;
    requestedBy: string;
    serviceType: PlatformType;
    language: string;
    preferredInterpreterId: string;
    skillId: string;
    gender: GenderType;
    commonNote: string;
    consumerType: string;
    consumer: string;
    modalityId: string;
    appointmentCategoryId: string;
    appointmentDescriptionId: string;
    address: Address;
    bookingCustomFields: BookingCustomFieldRequestModel[];
}
export interface BookingCustomFieldRequestModel {
    name: string;
    value: string;
    type: BookingCustomFieldType;
}

export interface BookingCustomHistoryModel {
    name: string;
    activity: string;
    date: string;
    time: string;
    actedUpon?: string;
    createdAt?: string;
}

export interface AppointedInterpreter {
    operatorUserId: string;
    name: string;
    phoneNumber: string;
    rate: number;
    distance: number;
}

export interface SelectedAppointedInterpreter {
    operatorUserIds: string[];
}

export interface SelectedAppointedInterpreterWithName {
    id: string;
    name: string;
}

export interface ChangeJobStatus {
    statusType: BookingStatusType | undefined;
    cancellationReasonId?: string;
    cancellationExtraInfo?: string;
}

export interface BookingCancellationReasons {
    id: string;
    reason: string;
}

export interface CloseJobModal {
    actualStart: any;
    actualEnd: any;
    mileage: any;
    vosFormFile: any;
    closeJobNote: any;
    operatorUserId: string;
    documentDescriptions: any[];
    documentAmounts: any[];
    documentFiles: any[];
}

export interface MiscellaneousInfo {
    id: any;
    description: any;
    amount: any;
    file: any;
}

export interface BookingActualTimeModel {
    time: string;
    type: BookingActualTimeType;
}

export enum BookingActualTimeType {
    StartTime = 1,
    EndTime,
}

export interface BookingBillsDetails {
    isUrgentJob: boolean;
    isClosedByAllOperators: boolean;
    cumulativeDuration: string;
    clientRate: number;
    clientBill: number;
    totalClientBill: number;
    bookingFee: number;
    technologyFeeRate: number;
    technologyFeeBill: number;
    clientMileage: number;
    travelTimeRate: number;
    travelTimeBill: number;
    mileageRate: number;
    mileageBill: number;
    agentBills: AgentBills[];
    profit: number;
}

export interface AgentBills {
    operatorUserId: string;
    name: string;
    duration: string;
    bill: number;
    vosFormFileKey: string;
    bookingOperatorInvoiceId: string;
}

export interface AgentBillsDetails {
    operatorUserId: string;
    name: string;
    duration: string;
    agentRate: number;
    mileage: number;
    maximumFreeMileage: number;
    mileageRate: number;
    mileageBill: number;
    bill: number;
    totalBill: number;
    vosFormFileKey: string;
    miscellaneousBills: MiscellaneousDetails[];
}

export interface MiscellaneousDetails {
    description: string;
    amount: number;
    fileKey: string;
}

export interface MiscellaneousDocumentDescriptions {
    documentId: number;
    documentDescription: string;
}

export interface MiscellaneousDocumentAmounts {
    documentId: number;
    documentAmount: any;
}

export interface MiscellaneousDocumentFileKeys {
    documentId: number;
    documentFileKey: string;
}

export interface MiscellaneousDocumentFileNames {
    documentId: number;
    documentFileName: string;
}

export interface CloseJobInfo {
    startDate: string;
    endDate: string;
    mileage: number;
    vosFormFileKey: string;
    jobCloseNote: string;
    miscellaneousBills: MiscellaneousBillsDetails[];
}

export interface MiscellaneousBillsDetails {
    miscellaneousBillSerialNo: number;
    description: string;
    amount: any;
    fileKey: string;
}

export interface GetAllForCalenderData {
    bookingId: string;
    startTime: string;
    endTime: string;
    serialNo: string;
    company: string;
    bookingStatusType: number;
}
