import { CallLogTableDataWeb, CommonFilter, ModalCallSummary, Searchbar, } from "../../features";
import { useFunctionalityCallLog, useFunctionalityCallSummary, useFunctionalityColumnSelections } from "../hooks";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { CallLogPagedResponseModel, CallSummaryResponseModel, } from "../../models/api/Call";
import {
  CallLogType,
  ColumnSelectionTableType,
  ExportTableType,
  PaginationSettings,
  topLoaderProgress,
} from "../../configs";
import LoadingBar from "react-top-loading-bar";
import { useAppSelector } from "../../core";
import { useFunctionalityCallLogColumns } from "../hooks/functionalityCallLogColumns";
import { CallLogInfoBox } from "../../features/Common/Components/CallLogInfoBox";
import { ColumnSelectionsModel } from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import ReactDOM from "react-dom";


export function PageInflowCallLog(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [callSummaryData, setCallSummaryData] = React.useState<CallSummaryResponseModel>();
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [callLogData, setCallLogData] = useState<CallLogPagedResponseModel>(Object);
  const [pagedCallListCount, setPagedCallListCount] = useState(0);
  const location = useLocation<{ callId: string }>();
  const callId = location.state?.callId;
  const { LoadById } = useFunctionalityCallSummary();
  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>([])
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);

  let initialFilterData: ApiSchemaFilter = {
    filterQueries: [],
    sortQuery: {}
  };
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(initialFilterData);

  const { LoadCallLog } = useFunctionalityCallLog({
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    callLogType: CallLogType.InflowCall,
    setLoaderProgress,
    filterData,
  });
  useEffect(() => {
    if (callId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadById(callId).then((data) => {
        setLoaderProgress(topLoaderProgress.complete);
        setCallSummaryData(data);
        setShow(true);
      });
    }
    setLoaderProgress(topLoaderProgress.start);
    LoadCallLog().then((r) => {
      setLoaderProgress(topLoaderProgress.complete);

      if (r?.data) setCallLogData(r?.data);
      if (r?.data) setPagedCallListCount(r?.data.totalCount);
    });
  }, [pageSize, pageIndex, filterData, searchText]);
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const currentSession = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole,
  }));

  const { LoadColumnSelections } = useFunctionalityColumnSelections({ type: ColumnSelectionTableType.InflowCall });

  useEffect(() => {
    LoadColumnSelections().then((r) => {
      if (r?.data) setColumnSelection(r?.data);
    });
  }, [])

  const { callLogColumn } = useFunctionalityCallLogColumns({
    userRole: currentSession.userRole ?? "",
    setShow: setShow,
    setCallSummaryData: setCallSummaryData,
    setLoaderProgress: setLoaderProgress,
    callLogType: CallLogType.InflowCall,
    columnSelection: columnSelection
  });
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="mt-4 mt-sm-4 mt-md-1 mt-lg-1 mt-xl-1 mb-4 font-weight-light">
        In Flow Call Log
      </h4>
      <div className="call-log-page ">
        <div className="row">
          <div className="col-12">
            <Searchbar
              showFilter={true}
              showExportOptions={true}
              filterPanel={"commonFilterPanel"}
              searchText={searchText}
              handleSearch={handleSearch}
              columnSelectionModalShow={columnSelectionModalShow}
              setColumnSelectionModalShow={setColumnSelectionModalShow}
              columnSelection={columnSelection}
              setColumnSelection={setColumnSelection}
              showAutoRefresh={true}
              tableType={ExportTableType.InflowCall}
              downloadedFileName={'CallLog'}
              filterData={filterData}
              setLoaderProgress={setLoaderProgress}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
          {
            isShowCommonFilter && <CommonFilter
              setFilterData={setFilterData}
              setLoaderProgress={setLoaderProgress}
              pageName='callLogPage'
              setPageIndex={setPageIndex}
            />
          }
          <CallLogInfoBox
            callLogData={callLogData}
            userRole={currentSession.userRole ?? ""}
          />
          <CallLogTableDataWeb
            loaderProgress={loaderProgress}
            pageHeader="Call Log"
            isPageEnabled={true}
            tableData={callLogData.callLogs}
            tableHeaders={callLogColumn}
            pageIndex={pageIndex}
            pageSize={pageSize}
            count={pagedCallListCount}
            onChangePageIndex={onChangePageIndex}
            onChangePageSize={onChangePageSize}
            setCallSummaryData={setCallSummaryData}
            setLoaderProgress={setLoaderProgress}
            setShow={setShow}
          />
          <ModalCallSummary
            summary={callSummaryData}
            show={show}
            handleShow={setShow}
          />
        </div>
      </div>
    </>
  );
}
