import React, { useState } from "react";
import { ApiSchemaCompanyByIdData, CallSetting, CompanySettings, CustomSetting, } from "../../../models/api/company";
import { useFunctionalityCompanySettings } from "../../../pages/hooks/functionalityCompanySettings";
import { PhoneNumber } from "../PhoneNumber";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
};

export function ProductsTab({ companyProfileData }: Props) {
  const companySettings: CompanySettings = companyProfileData?.companySettings || {};
  const { onChangCallCenterSetting } = useFunctionalityCompanySettings();
  const onCallCenterSettingToggleHandler = (callSetting: CallSetting) => {
    callSetting = { ...callSetting, isEnabled: !callSetting.isEnabled };
    onChangCallCenterSetting(callSetting);
  };

  return (
    <>
      <div className="pt-3 pb-3 pb-sm-0 ">
        <div className="component-card mb-3">
          <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
            <div>Call Center Settings</div>
          </div>
          <div className="component-card-body border">
            <div className="row flex-wrap px-3">
              {companySettings?.callCenterSettings?.map(
                (companySettingItem, index) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-0 col-min-width">
                    <button
                      type="button"
                      className={
                        companySettingItem?.isEnabled
                          ? "btn btn-toggle active"
                          : "btn btn-toggle"
                      }
                      onClick={() => {
                        onCallCenterSettingToggleHandler(companySettingItem);
                      }}
                    >
                      <div className="handle"></div>
                    </button>

                    <span className="toggle-button-label">
                      {companySettingItem?.code}
                    </span>
                    <p className="settings-small-text">
                      {companySettingItem?.description}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <PhoneNumber companyId={companyProfileData?.id ?? ""} />
      </div>
    </>
  );
}
