import React from 'react';
import {JobCustomFields} from "./JobCustomFields";

type Props = {
    register: any,
    setValue: any,
    errors: any,
    customFields?: any;
    customFieldValues?: any;
    setCustomFieldValues?: any;
}

export function JobCustomFieldsForm(props: Props): JSX.Element {
    const { register, setValue, errors, customFields, customFieldValues, setCustomFieldValues} = props;

    return (
        <>
            <div className="" >
                <div className="component-card rounded mb-3">
                    <div className="component-card-header d-flex align-items-center justify-content-between  border rounded-top ">
                        <div className="text-capitalize">Custom Fields</div>
                    </div>
                    <div className="component-card-body rounded-bottom  border-left border-right border-bottom">
                        {
                            customFields?.map((customField: any) => <JobCustomFields key={customField?.type}
                                                                                     register={register}
                                                                                     setValue={setValue}
                                                                                     errors={errors}
                                                                                     customField={customField}
                                                                                     customFieldValues={customFieldValues}
                                                                                     setCustomFieldValues={setCustomFieldValues} />)
                        }
                    </div>
                </div>
            </div >
        </>
    );
}
