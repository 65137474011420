import { Modal } from "react-bootstrap";
import { ApiSchemaLanguagePackData } from "../../../models/api";
import { LanguagePack } from "../../Common/Components/LanguagePack";
import { LanguagePackComponent } from "../../Common/Components/LanguagePackComponent";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  header: string;
  dataToView:ApiSchemaLanguagePackData
};

export function ModalOutsourcePack(props: Props): JSX.Element {
  const{dataToView}=props;
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      scrollable
    >
      <form action="">
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <LanguagePackComponent
                     editable={false}
                    languagePack={dataToView ?? undefined}
                    languagePackName={dataToView.name}
                  />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-secondary text-site text-uppercase"
            onClick={()=>props.handleShow(false)}
          >
            Ok
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
