import React from 'react';
import { PlatformType } from "../../../configs";
import { ApiSchemaJobFormFields } from "../../../models";

type Props = {
    register: any;
    setValue: any;
    errors: any;
    userId?: string;
    selectedServiceType: any;
    handleServiceTypeChange: any;
    jobFormData?: ApiSchemaJobFormFields | null;
    setJobFormData?: any;
};
export const ServiceType = (props: Props) => {
    const { selectedServiceType, handleServiceTypeChange, jobFormData } = props;
    return (
        <>
            <div className="form-group row align-items-center">
                {
                    (jobFormData?.isFaceToFaceEnabled || jobFormData?.isAudioEnabled || jobFormData?.isVideoEnabled) ? <label
                        htmlFor=""
                        className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
                    >
                        Service Type
                    </label>
                    : <p className='text-danger text-center w-100'>No booking contract is enabled for any platform (VRI / OPI / Face to Face)</p>
                }
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                    <div className="form-check-inline row align-items-center">
                        {
                            jobFormData?.isFaceToFaceEnabled && <div
                                className="pl-3"
                                aria-expanded="false"
                                aria-controls="face2facePanel"
                            >
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    data-toggle="collapse"
                                    data-target=".collapseServiceType:not(.show)"
                                    name="serviceType"
                                    checked={selectedServiceType === PlatformType.OnSite}
                                    value={PlatformType.OnSite}
                                    onChange={handleServiceTypeChange}
                                    ref={props.register({ required: true })}
                                />
                                <label htmlFor="faceToFace" className="form-check-label">
                                    Face to Face
                                </label>
                            </div>
                        }

                        {
                            jobFormData?.isAudioEnabled && <div className="pl-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="serviceType"
                                    data-toggle="collapse"
                                    data-target=".collapseServiceType.show"
                                    id="phoneCall"
                                    checked={selectedServiceType === PlatformType.OPI}
                                    onChange={handleServiceTypeChange}
                                    value={PlatformType.OPI}
                                    ref={props.register({ required: true })}
                                />
                                <label htmlFor="phoneCall" className="form-check-label mr-2">
                                    Phone Call
                                </label>
                            </div>
                        }

                        {
                            jobFormData?.isVideoEnabled && <div className="pl-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="serviceType"
                                    data-toggle="collapse"
                                    data-target=".collapseServiceType.show"
                                    onChange={handleServiceTypeChange}
                                    id="videoCall"
                                    checked={selectedServiceType === PlatformType.VRI}
                                    value={PlatformType.VRI}
                                    ref={props.register({ required: true })}
                                />
                                <label htmlFor="videoCall" className="form-check-label">
                                    Video Call
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
