import { faFileExcel, faFilePdf, faEnvelope, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { topLoaderProgress } from "../../configs";
import { ColumnSelectionsModel, ExportPayload } from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import { exportAsExcel, exportAsPdf, sendEmailReport } from "../../pages/hooks";
import { b64toBlob, downLoadBlob } from "../../utils";
import ModalInputElement from "../modals/common/singleInputModal";

type Props = {
  columnSelectionModalShow?: boolean;
  setColumnSelectionModalShow?: (value: boolean) => void;
  columnSelection?: ColumnSelectionsModel[];
  tableType?: number;
  filterData?: ApiSchemaFilter;
  downloadedFileName?: string;
  callCenterId?: string;
  companyId?: string;
  searchText?: string;
  setLoaderProgress?: any;
};
export function ExportOptions(props: Props): JSX.Element {
  const { setColumnSelectionModalShow, columnSelection, tableType, filterData, downloadedFileName, callCenterId, companyId, searchText, setLoaderProgress, } = props;
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailValidationMsg, setEmailValidationMsg] = useState("");

  const handleExportAsExcel = async () => {
    const enabledColumnList = columnSelection?.filter(
      (column: ColumnSelectionsModel) => column.isEnabled
    );
    const columnList = enabledColumnList?.map(
      (column: ColumnSelectionsModel) => {
        return {
          name: column.name,
          propertyName: column.propertyName,
        };
      }
    );
    const exportPayload: ExportPayload = {
      searchText: searchText,
      queryRequestModel: filterData,
      columnList: columnList,
    };
    const responseData = await exportAsExcel({
      tableType,
      exportPayload,
      callCenterId,
      companyId,
      setLoaderProgress,
    });
    if (responseData) {
      const blob = b64toBlob(
        responseData.data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      downLoadBlob(blob, downloadedFileName!);
    }
  };
  const handleExportAsPdf = async () => {
    const enabledColumnList = columnSelection?.filter(
      (column: ColumnSelectionsModel) => column.isEnabled
    );
    const columnList = enabledColumnList?.map(
      (column: ColumnSelectionsModel) => {
        return {
          name: column.name,
          propertyName: column.propertyName,
        };
      }
    );
    const exportPayload: ExportPayload = {
      searchText: searchText,
      queryRequestModel: filterData,
      columnList: columnList,
    };
    setLoaderProgress(topLoaderProgress.start);
    const responseData = await exportAsPdf({
      tableType,
      exportPayload,
      callCenterId,
      companyId,
      setLoaderProgress,
    });
    if (responseData) {
      setLoaderProgress(topLoaderProgress.complete);
      const blob = b64toBlob(responseData.data, "application/Pdf");
      downLoadBlob(blob, downloadedFileName!);
    }
  };

  const emailReportHandler = async () => {
    const enabledColumnList = columnSelection?.filter(
      (column: ColumnSelectionsModel) => column.isEnabled
    );
    const columnList = enabledColumnList?.map(
      (column: ColumnSelectionsModel) => {
        return {
          name: column.name,
          propertyName: column.propertyName,
        };
      }
    );
    const reportPayload: ExportPayload = {
      searchText: searchText,
      queryRequestModel: filterData,
      columnList: columnList,
    };
    setLoaderProgress(topLoaderProgress.start);
    const responseData = await sendEmailReport(
      tableType!,
      email,
      reportPayload,
      callCenterId ?? "",
      companyId ?? ""
    );
    if (responseData) {
      setLoaderProgress(topLoaderProgress.complete);
    }
  };

  const setReportEmail = (e: any) => {
    if (e.target.value == "") {
      setEmailValidationMsg("");
      setEmail("");
      return;
    }
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    if (regex.test(e.target.value)) {
      setEmailValidationMsg("");
      setEmail(e.target.value);
    } else {
      if (e.target.value != "") {
        setEmailValidationMsg("Please enter a valid email address");
        setEmail("");
      } else {
        setEmailValidationMsg("");
      }
    }
  };

  return (
    <>
      <div className="dropdown-menu py-0">
        <div className="dropdown-item py-0 px-0">
          <a className="btn btn-light text-site  text-left w-100" onClick={handleExportAsExcel}>
            <FontAwesomeIcon
              icon={faFileExcel}
              className="mr-2 text-success font-sz-16"
            />
            <span>Export as Excel</span>
          </a>
        </div>
        <div className="dropdown-item py-0 px-0">
          <a className="btn btn-light text-site text-left w-100" onClick={handleExportAsPdf}>
            <FontAwesomeIcon
              icon={faFilePdf}
              className="mr-2 text-danger font-sz-16"
            />
            <span>Export as PDF</span>
          </a>
        </div>
        <div className="dropdown-item py-0 px-0">
          <a className="btn btn-light text-site text-left w-100" onClick={() => setModalShow(true)}>
            <FontAwesomeIcon
              icon={faEnvelope}
              className="mr-2 text-primary font-sz-16"
            />
            <span>Email Report</span>
          </a>
        </div>
        <div className="dropdown-item py-0 px-0">
          <a className="btn btn-light text-site text-left w-100" onClick={() => {
            if (setColumnSelectionModalShow)
              setColumnSelectionModalShow(true);
          }}>
            <FontAwesomeIcon
              icon={faCheckSquare}
              className="mr-2 text-purple font-sz-16"
            />
            <span>
              Column Selection
            </span>
          </a>
        </div>
      </div>
      {
        modalShow && <ModalInputElement
          show={modalShow}
          handleShow={setModalShow}
          header={"Email exported items"}
          inputValue={email}
          inputMsg={
            "Please provide the email address where the exported report will be sent to."
          }
          onSaveHandler={emailReportHandler}
          onChangeHandler={setReportEmail}
          type="mail"
          errMsg={emailValidationMsg}
        />
      }
    </>
  );
}
