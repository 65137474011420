import {
  SubscriptionType,
  PaymentCycleType,
  PlatformType,
  AllocationFlowType,
  BookingFlowType,
} from "../../configs/appConstants";
import { MessageViewModel } from "./callcentermessage";

export type TypeContactPlatforms = {
  platform: PlatformType;
  isEnabled: boolean;
  connectionFee: number;
  technologyFee: number;
  languagePackId?: string;
};

export type TypeCreateCompanyStep1 = {
  name: string;
  code: string;
  address: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
  city: string;
  zipCode: string;
};

export type TypeCreateCompanyStep2 = {
  type?: SubscriptionType;
  startDate?: string | null;
  paymentCycle?: PaymentCycleType;
  subscriptionRate?: string;
  creditLimit?: string;
};

export type TypeCreateCompanyStep3n4 = {
  platform: PlatformType;
  isEnabled: boolean;
  connectionFee: number;
  technologyFee: number;
  languagePackName?: string;
  languagePackId: string;
};

export type TypeCreateCompanyStep5 = {
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  retypeEmail?: string;
  userRole: string;
  userType: string;
};

export type ApiSchemaCreateCompany = {
  name: string;
  type: number;
  code: string;
  parentId: string;
  address: {
    description: string;
    countryId: string;
    stateId: string;
    city: string;
    zipCode: string;
    email: string;
    fax: string;
    phone: string;
    website: string;
  };
  contactPersonDetails: {
    firstName: string;
    lastName: string;
    contactNumber: string;
    email: string;
  };
  contactPlatforms: TypeContactPlatforms[];
  subscription: {
    type: number;
    startDate: string | null;
    paymentCycle: PaymentCycleType | null;
    subscriptionRate: number | null;
    creditLimit: number;
  };
};

export type ApiSchemaCompanyData = {
  id?: string;
  name?: string;
  code?: string;
  users?: number;
  locations?: number;
  departments?: number;
  isActive?: boolean;
};

export interface Address {
  id?: string;
  description?: string;
  city?: string;
  countryId?: string;
  countryName?: string;
  stateId?: string;
  stateName?: string;
  email?: string;
  fax?: any;
  phone?: any;
  website?: string;
  zipCode?: string;
  country?: string;
  state?: string;
}

export interface UserProfile {
  contactNumber: string;
  alternativePhoneNumber?: any;
  timeZone?: any;
  education?: any;
  gender: number;
  address?: any;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  userProfile: UserProfile;
}

export interface ContactPerson {
  id: string;
  user: User;
  notes?: any;
}

export interface TypeContractTabContactPlatforms {
  id: string;
  platform: number;
  isEnabled: boolean;
  connectionFee: number;
  technologyFee: number;
  outboundFee?: any;
  travelTimeRate: number;
  mileageRate: number;
}

export interface TypeContractDocument {
  id: string;
  name: string;
  fileName: string;
  uploadDate: Date;
}

export interface BossContactPlatformMinimumTime {
  ticks: number,
  days: number,
  hours: number,
  milliseconds: number,
  minutes: number,
  seconds: number,
  totalDays: number,
  totalHours: number,
  totalMilliseconds: number,
  totalMinutes: number,
  totalSeconds: number
}

export interface BossContactPlatform {
  id?: string;
  platform: PlatformType;
  minimumRate: number;
  minimumTime?: BossContactPlatformMinimumTime,
  minimumTimeHour?: any;
  minimumTimeMinute?: any;
  isEnabled: boolean;
}

export interface BossContract {
  id: string;
  urgentJobMaximumTimeHour?: any;
  urgentJobMaximumTimeMinute?: any;
  urgentJobRate: number;
  bossContactPlatforms: BossContactPlatform[];
}

export interface Contract {
  id: string;
  contactPlatforms: TypeContractTabContactPlatforms[];
  contractDocuments: TypeContractDocument[];
  bossContract: BossContract;
}

export interface ContractFeesUpdate {
  isUpdateVriFees: boolean;
  isUpdateOpiFees: boolean;
  isUpdateOnsiteFees: boolean;
}

export interface ContractBossSystemHoursError {
  urgentHourError: string,
  opiHourError: string,
  vriHourError: string,
  faceToFaceHourError: string
}

export interface ContractBossSystemMinutesError {
  urgentMinutesError: string,
  opiMinutesError: string,
  vriMinutesError: string,
  faceToFaceMinutesError: string
}

export const DurationZeroError = {
  message: "Duration must be greater than 0"
}

export enum ContractBossSystemHoursFieldName {
  urgentHourField = "urgentJobMaximumTimeHour",
  opiHourField = "bossContactPlatforms.0.minimumTimeHour",
  vriHourField = "bossContactPlatforms.1.minimumTimeHour",
  faceToFaceHourField = "bossContactPlatforms.2.minimumTimeHour"
}

export enum ContractBossSystemMinutesFieldName {
  urgentMinuteField = "urgentJobMaximumTimeMinute",
  opiMinuteField = "bossContactPlatforms.0.minimumTimeMinute",
  vriMinuteField = "bossContactPlatforms.1.minimumTimeMinute",
  faceToFaceMinuteField = "bossContactPlatforms.2.minimumTimeMinute"
}

export interface Subscription {
  id: string;
  type: number;
  startDate: Date;
  endDate: Date;
  subscriptionRate: number;
  creditLimit: number;
  lateTimeMonthLimit: number;
  lateFeeRate: number;
  paymentCycle: number;
  status: number;
}
export interface AccountStatus {
  id: string;
  isActive: boolean;
}
export interface CallSetting {
  id: string;
  code: string;
  description: string;
  isEnabled: boolean;
}
export enum CallSettingsDisableToggle {
  language = "Language Selection"
}
export enum CallSettingsConferenceCallToggleHide {
  conferenceCall = "Conference Call"
}
export interface CustomSetting {
  id: string;
  name: string;
  description: string;
  isEnabled: boolean;
  code: string;
}

export interface CompanySettings {
  id?: string;
  callSettings?: CallSetting[];
  customFields?: CustomSetting[];
  bookingCustomFields?: CustomSetting[];
  callCenterSettings?: CallCenterSettingResponseModel[];
}
export interface CallCenterSettingResponseModel {
  id: string;
  code: string;
  description: string;
  isEnabled: boolean;
}
export interface DialPadKeyMappingModel {
  key: string;
  value: string;
  name: string;
}
export const unknownDialPad = {
  value: "3"
}
export enum DialPapType {
  language = 1,
  skill,
  gender,
}
export interface LanguageDialKeyMapping {
  type?: number;
  isHidden?: boolean;
  isEnabled?: boolean;
  isZeroEnabledToPinInput?: boolean;
  dialPadKeyMappingModels?: DialPadKeyMappingModel[];
}

export interface SkillDialKeyMapping {
  type?: number;
  isHidden?: boolean;
  isEnabled?: boolean;
  isZeroEnabledToPinInput?: boolean;
  dialPadKeyMappingModels?: DialPadKeyMappingModel[];
}

export interface GenderDialKeyMapping {
  type?: number;
  isHidden?: boolean;
  isEnabled?: boolean;
  isZeroEnabledToPinInput?: boolean;
  dialPadKeyMappingModels?: DialPadKeyMappingModel[];
}

export interface DialKeyMappingCriteria {
  languageDialKeyMapping: LanguageDialKeyMapping;
  skillDialKeyMapping: SkillDialKeyMapping;
  genderDialKeyMapping: GenderDialKeyMapping;
}

export interface IvrCustomField {
  id: string;
  name: string;
  code: string;
  isEnabled: boolean;
  inputType: number;
  enterKey: string;
  min: number;
  max: number;
}

export interface MessageMapping {
  id: string;
  ivrMessageId: string;
  audioMediaKey?: any;
  message: string;
  title: string;
  category: string;
}

export interface Ivr {
  id?: string;
  isSkillEnabled?: boolean;
  isLanguageEnabled?: boolean;
  isGenderEnabled?: boolean;
  isThreeWayCallEnabled?: boolean;
  isZeroEnabledToPinInputLanguage?: boolean;
  isZeroEnabledToPinInputSkill?: boolean;
  isZeroEnabledToPinInputGender?: boolean;
  isCustomFieldsHidden?: boolean;
  dialKeyMappingCriteria?: DialKeyMappingCriteria;
  ivrCustomFields?: IvrCustomField[];
  messageMappings?: MessageViewModel[];
  ivrCustomFieldsForWarning?: string[];
}

export interface LanguageRate {
  id?: string,
  rate: number;
  languageId: string;
  languageName: string;
}

export interface LanguagePack2 {
  id: string;
  flatRate: number;
  name: string;
  languageRates: LanguageRate[];
}

export interface LanguagePack {
  id?: string | "";
  platformType: number;
  languagePack: LanguagePack2;
}

export interface PagedUserData {
  id: string;
  name: string;
  email: string;
  departmentId: string;
  departmentName: string;
  locationId: string;
  locationName: string;
  userRole: string;
  status: number;
}

export interface PagedUserList {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: PagedUserData[];
}

export interface CancellationReason {
  id: string;
  reason: string;
}

export interface Address2 {
  id: string;
  description: string;
  city: string;
  countryId: string;
  countryName: string;
  stateId: string;
  stateName: string;
  email: string;
  fax: string;
  phone: string;
  website: string;
  zipCode: string;
}

export interface Datum2 {
  id: string;
  name: string;
  companyId: string;
  address: Address2;
}

export interface PagedDepartmentList {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: Datum2[];
}

export interface Address3 {
  id: string;
  description: string;
  city: string;
  countryId: string;
  countryName: string;
  stateId: string;
  stateName: string;
  email: string;
  fax: string;
  phone: string;
  website: string;
  zipCode: string;
}

export interface Datum3 {
  id: string;
  name: string;
  companyId: string;
  address: Address3;
}

export interface PagedLocationList {
  pageIndex: number;
  pageSize: number;
  count: number;
  data: Datum3[];
}
export interface CompanyModalityResponseModel {
  id?: string;
  status: boolean;
  modalityId?: string;
  modalityName?: string;
}

export interface CompanyAppointmentCategoryResponseModel {
  id?: string;
  status: boolean;
  appointmentCategoryId?: string;
  appointmentCategoryName?: string;
}

export interface CompanyAppointmentDescriptionResponseModel {
  id?: string;
  status: boolean;
  appointmentDescriptionId?: string;
  appointmentDescriptionName?: string;
}
export interface CompanyModalityRequestModel {
  modalityId?: string;
  status?: boolean;
  isAll?: boolean;
}

export interface CompanyAppointmentCategoryRequestModel {
  appointmentCategoryId?: string;
  status?: boolean;
  isAll?: boolean;
}

export interface CompanyAppointmentDescriptionRequestModel {
  appointmentDescriptionId?: string;
  status?: boolean;
  isAll?: boolean;
}
export interface CompanySkills {
  id?: string;
  status: boolean;
  skillId?: string;
  skillName?: string;
}
export interface ApiSchemaCompanyByIdData {
  id?: string;
  name?: string;
  type?: number;
  parentId?: string;
  isActive?: boolean;
  logoKey?: any;
  currency?: any;
  timeZone?: any;
  address?: Address;
  contactPerson?: ContactPerson;
  contract?: Contract;
  subscription?: Subscription;
  companySettings?: CompanySettings;
  ivr?: Ivr;
  languagePacks?: LanguagePack[];
  companySkills?: CompanySkills[];
  companyModalities?: CompanyModalityResponseModel[];
  companyAppointmentCategories?: CompanyAppointmentCategoryResponseModel[];
  companyAppointmentDescriptions?: CompanyAppointmentDescriptionResponseModel[];
  pagedUserList?: PagedUserList;
  cancellationReasons?: CancellationReason[];
  pagedDepartmentList?: PagedDepartmentList;
  pagedLocationList?: PagedLocationList;
  bookingPolicySettings?: BookingPolicyResponseModel;
  isReseller?: boolean;
}
export interface BookingPolicyResponseModel {
  companyId: string
  allocationFlow: AllocationFlowType;
  bookingFlow: BookingFlowType;
}

export interface ApiSchemaUpdateCompanySkill {
  skillId?: string;
  isAll?: boolean;
  status?: boolean;
}
export interface CompanyLanguagePackUpdateModel {
  companyId: string;
  languagePackId: string;
  platform: string;
}

export interface CompanyUpdateModel {
  name: string;
  timeZone: string;
  currency: string;
  address: Address3;
}

export interface CompanyFormUpdateModel {
  name: string,
  timeZone: string,
  currency: string,
  description: string,
  city: string,
  countryId: string,
  stateId: string,
  email: string,
  fax: string,
  phone: string,
  website: string,
  zipCode: string
}
