import { type } from 'os';
import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'

type Props = {
  rating: number,
  setRating?: (value: any) => void,
  view?: boolean
}
export default function StarComponent(props: Props) {

  const { view, rating, setRating } = props
  const [currentRating, setCurrentRating] = useState(rating * 20);
  const handleRating = (rate: number) => {
    setCurrentRating(rate);
    if (setRating) setRating(rate / 20.0)
  }
  return (
    <div>
      {view ?
              // <Rating transition={true} readonly={true} ratingValue={(rating * 20) || 0} fillColor={"#a77ce6"} allowFraction={true} size={25} initialValue={rating ? rating : 0} /> :
              // <Rating transition={true} onClick={handleRating} ratingValue={(currentRating) || 0} fillColor={"#a77ce6"} allowFraction={false} />
        <Rating transition={true} readonly={true}  fillColor={"#a77ce6"} allowFraction={true} size={25} initialValue={rating ? rating : 0} /> :
        <Rating transition={true} onClick={handleRating} fillColor={"#a77ce6"} allowFraction={false} />
      }
    </div>
  )
}
