import { useCallback } from "react";
import { PlatformApi, AppConstants } from "../../configs";
import { useAppSelector, AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { CallSummaryResponseModel } from "../../models/api/Call";

export function useFunctionalityCallSummary() {
    const userId = useAppSelector(state => state.auth.profile?.id); 
    const LoadById=useCallback(async (callId: string) => {
      const callLogUrl = PlatformApi.Call.GetCallSummary(callId );
      try {
      const res = await AxiosAuth.get<ApiResponse<CallSummaryResponseModel>>(callLogUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
      }
      catch(e){
        return Promise.reject(e);
      }
       
    }, []);
    return {
      LoadById
    }
  }