import React, { useEffect, useState } from 'react';
import {
  CustomInputMap,
  GenderMap,
  LanguageMap,
  MessageMap,
  SkillMap,
  ThreeWayCall,
} from '..';
import {
  CriteriaType,
  PlatformType,
  topLoaderProgress,
} from '../../../configs/appConstants';
import {
  ApiSchemaCompanyByIdData,
  DialPadKeyMappingModel,
  GenderDialKeyMapping,
  Ivr,
  IvrCustomField,
  LanguageDialKeyMapping,
  Option,
  SkillDialKeyMapping,
  unknownDialPad,
} from '../../../models';
import {
  useFunctionalityCompanyIvr,
  useFunctionalityCompanyIvrDetails,
  useFunctionalityLanguageByCompanyId,
  useFunctionalitySkill,
} from '../../../pages/hooks';
import { ModalIvrDialKeyMapping, ModalIvrThreeWayCall } from '../../modals';
import { genders } from '../../modals/jobFormData';
import { AppRouteUi } from '../../../configs';
import LoadingBar from 'react-top-loading-bar';
import { NoDataComponent } from '../../admin/noDataComponent';

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
};

export function IvrTab({ companyProfileData, editable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);

  const { onCompanyIvrDetails } = useFunctionalityCompanyIvrDetails({
    companyId: AppRouteUi.Company.Profile.useParam(),
    setLoaderProgress: setLoaderProgress,
  });

  const [companyIvrDetails, setCompanyIvrDetails] =
    useState<ApiSchemaCompanyByIdData>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);

  useEffect(() => {
    onCompanyIvrDetails()?.then((res) => {
      setCompanyIvrDetails(res?.data);
      setLoaderProgress(topLoaderProgress.complete);
    });
  }, [onUpdateSuccess]);

  const ivr: Ivr = companyIvrDetails?.ivr || {};

  const [ivrDialKeyModalShow, setIvrDialKeyModalShow] = React.useState(false);
  const ivrLanguageDialKeyMapping: LanguageDialKeyMapping =
    ivr?.dialKeyMappingCriteria?.languageDialKeyMapping || {};
  const ivrSkillDialKeyMapping: SkillDialKeyMapping =
    ivr?.dialKeyMappingCriteria?.skillDialKeyMapping || {};
  const ivrGenderDialKeyMapping: GenderDialKeyMapping =
    ivr?.dialKeyMappingCriteria?.genderDialKeyMapping || {};
  const ivrCustomFields: IvrCustomField[] = ivr?.ivrCustomFields || [];
  const ivrCustomFieldsForWarning: string[] =
    ivr?.ivrCustomFieldsForWarning || [];

  const { languageList } = useFunctionalityLanguageByCompanyId({
    plaformType: PlatformType.OPI,
    companyId: companyIvrDetails?.id,
  });
  // const { languageList } = useFunctionalityLanguage({ plaformType: PlatformType.OPI, companyId: companyIvrDetails?.id, shouldLanguageGetAllInvoke: true });
  const { optionMappedSkillList } = useFunctionalitySkill({
    shouldSkillGetAllInvoke: true,
  });

  const [modalKey, setModalKey] = useState(Date.now() + '');

  const [dialPadKeyMappingModels, setDialPadKeyMappingModels] = useState<
    DialPadKeyMappingModel[]
  >([]);
  const [threeWayCallModalShow, setThreeWayCallModalShow] =
    React.useState(false);
  const [ivrDialMapperModalHeader, setIvrDialMapperModalHeader] =
    useState<string>('');
  const [totalDialPadNumber, setTotalDialPadNumber] = useState<number>(0);
  const [dialPadDropdownList, setDialPadDropdownList] = useState<Option[]>([]);
  const [initialEnabledToggler, setInitialEnabledToggler] =
    useState<boolean>(false);
  let [initialZeroEnabledToggler, setInitialZeroEnabledToggler] =
    useState<boolean>(false);
  const [showZeroEnabledToPinInput, setShowZeroEnabledToPinInput] =
    useState<boolean>(false);
  const [showEnableToggler, setShowEnableToggler] = useState<boolean>(false);
  const [dialMapType, setDialMapType] = useState<PlatformType>(0);
  const [zeroInputToggle, setZeroInputToggle] = useState<boolean>(false);
  const [enabledToggle, setEnabledToggle] = useState<boolean>(false);
  const [isThreeWayCallEnable, setIsThreeWayCallEnable] =
    useState<boolean>(false);
  const [initThreeWayCallEnableToggler, setInitThreeWayCallEnabledToggler] =
    useState<boolean>(false);

  const { onUpdateDialKeyMapping, onUpdateThreeWayCall } =
    useFunctionalityCompanyIvr();

  const getDialPadDropDownList = (criteria: number) => {
    let dropDownList: Option[] = [];
    let labelName: string = 'Select a dial pad';

    if (criteria === CriteriaType.Language) {
      dropDownList = [...languageList];
      labelName = 'Select a dial pad language';
    } else if (criteria === CriteriaType.Skill) {
      dropDownList = [...optionMappedSkillList];
      labelName = 'Select a dial pad skill';
    } else if (criteria === CriteriaType.Gender) {
      dropDownList = [...genders];
      labelName = 'Select a dial pad gender';
    }

    return [
      {
        value: "",
        label: labelName
      },
      ...dropDownList,
    ];
  };

  const onClickConfigureDialKeyMapping = (criteria: number) => {
    if (criteria === CriteriaType.Language) {
      setIvrDialMapperModalHeader('IVR Language Mapping');
      setTotalDialPadNumber(9);
      setDialPadDropdownList(getDialPadDropDownList(criteria));
      setInitialEnabledToggler(ivrLanguageDialKeyMapping?.isEnabled || false);
      setInitialZeroEnabledToggler(
        ivrLanguageDialKeyMapping?.isZeroEnabledToPinInput || false
      );
      setShowZeroEnabledToPinInput(true);
      setShowEnableToggler(false);
      setDialMapType(1);
      setDialPadKeyMappingModels(
        ivrLanguageDialKeyMapping?.dialPadKeyMappingModels || []
      );
    } else if (criteria === CriteriaType.Gender) {
      setIvrDialMapperModalHeader('IVR Gender Mapping');
      setTotalDialPadNumber(3);
      setDialPadDropdownList(getDialPadDropDownList(criteria));
      setInitialEnabledToggler(ivrGenderDialKeyMapping?.isEnabled || false);
      setInitialZeroEnabledToggler(
        ivrGenderDialKeyMapping?.isZeroEnabledToPinInput || false
      );
      setShowZeroEnabledToPinInput(false);
      setShowEnableToggler(true);
      setDialMapType(3);
      setDialPadKeyMappingModels(
        ivrGenderDialKeyMapping?.dialPadKeyMappingModels || []
      );
    } else if (criteria === CriteriaType.Skill) {
      setIvrDialMapperModalHeader('IVR Skill Mapping');
      setTotalDialPadNumber(9);
      setDialPadDropdownList(getDialPadDropDownList(criteria));
      setInitialEnabledToggler(ivrSkillDialKeyMapping?.isEnabled || false);
      setInitialZeroEnabledToggler(
        ivrSkillDialKeyMapping?.isZeroEnabledToPinInput || false
      );
      setShowZeroEnabledToPinInput(true);
      setShowEnableToggler(true);
      setDialMapType(2);
      setDialPadKeyMappingModels(
        ivrSkillDialKeyMapping?.dialPadKeyMappingModels || []
      );
    }
    setIvrDialKeyModalShow(true);
  };
  const onClickThreeWayCallEdit = () => {
    setInitThreeWayCallEnabledToggler(ivr?.isThreeWayCallEnabled || false);
    setThreeWayCallModalShow(true);
  };

  const onDialKeyDropDownChangeHandle = (option: Option, key: number) => {
    if(option.value){
      const mapDialModel: DialPadKeyMappingModel = {
        key:key.toString(),
        value:option.value.toString(),
        name:option.label,
      };
      setDialPadKeyMappingModels((prev) => [
        ...prev.filter((x) => x.key !== mapDialModel.key),
        mapDialModel,
      ]);
    }else{
      setDialPadKeyMappingModels((prev) => [...prev.filter((x) => x.key !== key+'')]);
    }
  };

  const onZeroEnableToggleHandler = (isZeroEnabledToPinInput: boolean) => {
    setZeroInputToggle(!isZeroEnabledToPinInput);
  };
  const onEnabledToggleHandler = (enabled: boolean) => {
    setEnabledToggle(!enabled);
  };
  const threeWayCallToggleHandler = (enabled: boolean) => {
    setIsThreeWayCallEnable(!enabled);
  };

  const onDialKeyMapSaveHandler = (criteria: PlatformType) => {
    const remainingDialPadKey= dialPadKeyMappingModels.filter(item=>item.value !=="" && item.name !==null)
    const requestModel = {
      criteriaType: criteria,
      isEnabled: enabledToggle,
      isZeroEnabledToPinInput: zeroInputToggle,
      ivrDialKeyMappingModels: remainingDialPadKey,
    };
    onUpdateDialKeyMapping(requestModel, ivr?.id || '')?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setIvrDialKeyModalShow(false);
    });
  };
  const onThreeWayCallSaveHandler = () => {
    onUpdateThreeWayCall(isThreeWayCallEnable, ivr?.id || '').then(() => {
      setOnUpdateSuccess((prev) => !prev);
      setThreeWayCallModalShow(false);
    });
  };
  const [warningForCustomInput, setWarningForCustomInput] =
    useState<string>('');

  useEffect(() => {
    let warning = '';
    ivrCustomFieldsForWarning.forEach((field) => {
      warning = warning + field + ' is Disabled. ';
    });
    setWarningForCustomInput(warning);
  }, [ivrCustomFieldsForWarning]);

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {ivrCustomFieldsForWarning.length > 0 && (
        <div className="row">
          <div className="col text-white bg-warning-ivr px-4 py-1">
            {warningForCustomInput}
          </div>
        </div>
      )}
      {companyIvrDetails ? (
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="accordion" id="ivrTab">
              {!ivrLanguageDialKeyMapping?.isHidden && (
                <LanguageMap
                  editable={editable}
                  onClickConfigureDialKeyMapping={
                    onClickConfigureDialKeyMapping
                  }
                  dialPadKeyMappingModels={
                    ivrLanguageDialKeyMapping?.dialPadKeyMappingModels
                  }
                  languageList={languageList}
                />
              )}
            </div>
          </div>

          {!ivrSkillDialKeyMapping?.isHidden ? (
            <div className="col-12 col-md-6">
              <SkillMap
                editable={editable}
                onClickConfigureDialKeyMapping={onClickConfigureDialKeyMapping}
                dialPadKeyMappingModels={
                  ivrSkillDialKeyMapping?.dialPadKeyMappingModels
                }
                skillList={optionMappedSkillList}
              />
            </div>
          ) : null}

          {!ivrGenderDialKeyMapping?.isHidden ? (
            <div className="col-12 col-md-6">
              <GenderMap
                editable={editable}
                onClickConfigureDialKeyMapping={onClickConfigureDialKeyMapping}
                dialPadKeyMappingModels={
                  ivrGenderDialKeyMapping?.dialPadKeyMappingModels
                }
              />
            </div>
          ) : null}

          {!ivr?.isCustomFieldsHidden ? (
            <div className="col-12 col-md-6">
              <CustomInputMap
                ivrId={companyIvrDetails?.ivr?.id ?? ''}
                customFields={companyIvrDetails?.ivr?.ivrCustomFields ?? []}
                editable={editable}
              />
            </div>
          ) : null}
          <div className="col-12 col-md-6">
            <ThreeWayCall
              editable={editable}
              isThreeWayCallEnabled={
                companyIvrDetails?.ivr?.isThreeWayCallEnabled ?? false
              }
              onClickThreeWayCallEdit={onClickThreeWayCallEdit}
            />
          </div>

          <div className="col-12 mb-3">
            <MessageMap
              ivrId={companyIvrDetails?.ivr?.id ?? ''}
              editable={editable}
              messages={companyIvrDetails?.ivr?.messageMappings ?? []}
              onUpdateSuccess={onUpdateSuccess}
              setOnUpdateSuccess={setOnUpdateSuccess}
            />
          </div>
        </div>
      ) : (
        <NoDataComponent title="Loading..." />
      )}
      {ivrDialKeyModalShow ? (
        <ModalIvrDialKeyMapping
          key={modalKey}
          show={ivrDialKeyModalShow}
          handleShow={(value) => {
            setIvrDialKeyModalShow(value);
            setModalKey(Date.now() + '');
          }}
          header={ivrDialMapperModalHeader}
          totalDialPadNumber={totalDialPadNumber}
          dialPadDropdownList={dialPadDropdownList}
          isZeroEnabledToPinInput={initialZeroEnabledToggler}
          isEnabled={initialEnabledToggler}
          showZeroEnabledToPinInput={showZeroEnabledToPinInput}
          showEnableToggler={showEnableToggler}
          dialMapType={dialMapType}
          dialPadKeyMappingModels={dialPadKeyMappingModels}
          onDialKeyDropDownChangeHandle={onDialKeyDropDownChangeHandle}
          onSaveHandler={onDialKeyMapSaveHandler}
          onZeroEnableToggleHandler={onZeroEnableToggleHandler}
          onEnabledToggleHandler={onEnabledToggleHandler}
        />
      ) : null}
      {threeWayCallModalShow ? (
        <ModalIvrThreeWayCall
          show={threeWayCallModalShow}
          handleShow={setThreeWayCallModalShow}
          header={'OPI Three Way Call Mapping'}
          onSaveHandler={onThreeWayCallSaveHandler}
          isThreeWayCallEnabled={initThreeWayCallEnableToggler}
          toggleHandler={threeWayCallToggleHandler}
        />
      ) : null}
    </>
  );
}
