import React, { useState } from 'react'
import { ValidateEmailRegex } from '../../configs';

export function useFunctionalityValidateEmail() {
    const [emailValidationMsg, setEmailValidationMsg] = useState("");
    const validateEmail = (e: any) => {
        if (e.target.value == "") {
            setEmailValidationMsg("");
            return;
        }
        if (ValidateEmailRegex.test(e.target.value)) {
            setEmailValidationMsg("")
        }
        else {
            if (e.target.value != "") {
                setEmailValidationMsg("Please enter a valid email address")
            }
            else {
                setEmailValidationMsg("")
            }
        }
    }
    return {
        validateEmail,
        emailValidationMsg,
    }
}
