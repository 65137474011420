import { Option } from "../models";

class ObjectToOptionMapperImpl {
  mapperFunc(dataSet: Object) {
    const optionArray = [] as Option[];
    for (const [key, value] of Object.entries(dataSet)) {
      optionArray.push({
        value: value,
        label: key,
      });
    }

    return optionArray;
  }
}

export const ObjectToOptionMapper = new ObjectToOptionMapperImpl();
