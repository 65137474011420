import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../../components";
import { useFunctionalityLanguage, useFunctionalityOperator, useFunctionalitySkill } from "../../../pages/hooks";
import { genders } from "../../modals/jobFormData";
import { useState } from "react";
import { useFunctionalityInputChecker } from "../../../pages/hooks/functionalityInputChecker";
import { reactSelectFilterConfig } from "../../../configs";

type Props = {
  register: any,
  setValue: any,
  errors: any,
  selectedServiceType?: any,
  isSkillEnabled?: any;
  isGenderEnabled?: any;
}

export function RequirementsForm(props: Props): JSX.Element {
  const { isSkillEnabled, isGenderEnabled, selectedServiceType } = props;
  const { languageList } = useFunctionalityLanguage({ plaformType: selectedServiceType, shouldLanguageGetAllInvoke: true });
  const [languageId, setLanguageId] = useState("");
  const [genderType, setGenderType] = useState("");
  const [skillId, setSkillId] = useState("");
  const { optionMappedSkillList } = useFunctionalitySkill({ shouldSkillGetAllInvoke: props.isSkillEnabled });

  const { optionMappedOperatorNames } = useFunctionalityOperator({
    platform: props.selectedServiceType,
    languageId: languageId,
    genderType: genderType,
    skillId: skillId
  });

  const { handleSpecialCharacterWhiteSpacesForJobField } = useFunctionalityInputChecker();
  const onLanguageChangeHandler = (value: any) => {
    setLanguageId(value?.value);
  }
  const onGenderChangeHandler = (value: any) => {
    setGenderType(value?.value);
  }
  const onSkillChangeHandler = (value: any) => {
    setSkillId(value?.value);
  }
  const optionSkillUpdatedList = [
    {
      value: "",
      label: 'Any'
    },
    ...optionMappedSkillList,
  ];
  const optionOperatorUpdatedList = [
    {
      value: "",
      label: 'Any'
    },
    ...optionMappedOperatorNames,
  ];

  return (
    <>
      <div className="" >
        <div className="component-card rounded mb-3">
          <div className="component-card-header d-flex align-items-center justify-content-between  border rounded-top ">
            <div className="text-capitalize">Requirements</div>
          </div>
          <div className="component-card-body rounded-bottom  border-left border-right border-bottom">
            <div className="form-group row">
              <label
                htmlFor=""
                className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
              >
                Language
              </label>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                <input
                  className="d-none"
                  name="languageId"
                  ref={props.register({ required: true })}
                  style={{ border: props.errors.languageId ? '1px solid red' : '' }}
                />
                <Select
                  className={` ${props.errors.languageId?.type == "required" ? 'field-required' : ''} `}
                  placeholder="Select Language"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={languageList}
                  isDisabled={(languageList.length === 0) ? true : false}
                  filterOption={createFilter(reactSelectFilterConfig)}
                  onChange={(value) => {
                    onLanguageChangeHandler(value)
                    props.setValue("languageId", value?.value, {
                      shouldValidate: true,
                    });
                  }}
                />
                {props.errors.languageId && (
                  <FormErrorMessage message="required" />
                )}
              </div>
            </div>
            {
              isGenderEnabled && <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label"
                >
                  Gender
                </label>
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                  <input
                    className="d-none"
                    name="gender"
                    defaultValue={0}
                    ref={props.register()}
                  />
                  <Select
                    placeholder="Select gender"
                    components={{
                      IndicatorSeparator: () => null,
                    }}

                    defaultValue={
                      genders ? genders.find((x) => x.value === 0)
                        : null
                    }
                    options={genders}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    isDisabled={!languageId}
                    onChange={(value) => {
                      onGenderChangeHandler(value)
                      props.setValue("gender", value?.value);
                    }}
                  />
                  {/*{props.errors.gender && (*/}
                  {/*    <FormErrorMessage message="required" />*/}
                  {/*)}*/}
                </div>
              </div>
            }

            {
              isSkillEnabled && <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label"
                >
                  Skill
                </label>
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                  <input
                    className="d-none"
                    name="skillId"
                    defaultValue={""}
                    ref={props.register()}
                  />
                  <Select
                    placeholder="Select Request Type"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    defaultValue={
                      optionSkillUpdatedList ? optionSkillUpdatedList.find(
                        (x) => x.value === ""
                      )
                        : null
                    }
                    options={optionSkillUpdatedList}
                    isDisabled={(optionMappedSkillList.length === 0 || !languageId) ? true : false}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    onChange={(value) => {
                      onSkillChangeHandler(value)
                      props.setValue("skillId", value?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                </div>
              </div>
            }
            <div className="form-group row">
              <label
                htmlFor=""
                className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label"
              >
                Preferred Interpreter
              </label>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                <input
                  className="d-none"
                  name="preferredInterpreterId"
                  defaultValue={""}
                  ref={props.register()}
                />
                <Select
                  placeholder="Select Preferred Interpreter"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={
                    optionSkillUpdatedList ? optionSkillUpdatedList.find(
                      (x) => x.value === ""
                    )
                      : null
                  }
                  options={optionOperatorUpdatedList}
                  isDisabled={!languageId}
                  filterOption={createFilter(reactSelectFilterConfig)}
                  onChange={(value) => {
                    props.setValue("preferredInterpreterId", value?.value, {
                      shouldValidate: true,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor=""
                className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label "
              >
                Consumer Type
              </label>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                <input
                  placeholder="Max 160 characters"
                  className="form-control"
                  name="consumerType"
                  maxLength={160}
                  ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForJobField, maxLength: 160 })}
                  style={{ border: props.errors.consumerType ? '1px solid red' : '' }}
                />
                {props.errors.consumerType && props.errors.consumerType.type == "validate" && (
                  <FormErrorMessage message="Please enter a valid value" />
                )}
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor=""
                className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label "
              >
                Consumer
              </label>
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                <input
                  placeholder="Max 160 characters"
                  className="form-control"
                  name="consumer"
                  maxLength={160}
                  ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForJobField, maxLength: 160 })}
                  style={{ border: props.errors.consumer ? '1px solid red' : '' }}
                />
                {props.errors.consumer && props.errors.consumer.type == "validate" && (
                  <FormErrorMessage message="Please enter a valid value" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}