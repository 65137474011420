import { useCallback } from "react";
import { PlatformApi, AppConstants } from "../../configs";
import { useAppSelector, AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { CallSummaryResponseModel } from "../../models/api/Call";

export function useFunctionalityBookingSummary() {
    
    const LoadBookingSummaryById=useCallback(async (bookingId: string) => {
      const bookingLogUrl = PlatformApi.Booking.GetBookingSummary(bookingId );
      try {
      const res = await AxiosAuth.get<ApiResponse<CallSummaryResponseModel>>(bookingLogUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
      }
      catch(e){
        return Promise.reject(e);
      }
       
    }, []);
    return {
      LoadBookingSummaryById
    }
  }