import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { SessionStorageUtils } from '../../utils';

export function PageLog() {
  const history = useHistory();

  return (
    <div className='card border-radius-xl bg-white overflow-none m-2' id="log-page">
      <div className='card-header border-bottom-0 bg-white'>
        <button
          className='btn btn-primary btn-link text-site text-decoration-none'
          onClick={() => history.goBack()}
        >
          <span>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
          <span className='mx-1'>Back</span>
        </button>
      </div>
      <div className='card-body h-95 overflow-auto'>
        {SessionStorageUtils.log?.map((data, index) => (
          <p key={index}>{data}</p>
        ))}
      </div>
    </div>
  );
}
