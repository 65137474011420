import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from "axios";
import { logger } from "../../../utils";
import { LogConfig } from "../../../configs";

export class AxiosRedirectUnauthorize {
  /**
   * @static @function Add() Add interceptor to and instance
   * @param {AxiosInstance} instance Instance of Axios
   * @param {string} redirectTo URL to redirect
   * @param {(() => void) | undefined} onRedir Optional callback to execute when redirecting
   * @returns {number} Interceptor Id
   */
  static Add(instance: AxiosInstance, redirectTo: string, onRedir?: () => void): number {
    function OnFailure(): void {
      onRedir?.();
      window.location.href = redirectTo;
    }
    async function RedirectWhenUnauthorize(error: AxiosError<{ [key: string]: unknown}>): Promise<unknown> {
      if(axios.isCancel(error)) {
        throw error;
      }
      // if (error.response?.status === 403) {
      //   OnFailure();
      // } else if (error.response?.status === 401) {
      //   try {
      //     const body: ApiRefreshTokenBody = {
      //       RefreshToken: AuthStorage.RefreshToken || '',
      //     };
      //     const refrestTokenRes = await Axios.post<AuthRefreshData>(ApiRoutes.Auth.RefreshToken.Api, body);
      //     AuthStorage.refreshAuthData(refrestTokenRes.data);
      //     return instance(error.config);
      //   } catch (e) {
      //     OnFailure();
      //   }
      // }


      // @ts-ignore
      if (error.response?.status === 403 || error.response?.status === 401) {
        OnFailure();
      }
    
      // @ts-ignore
      const {url, method, }  = error.config
      const logData = {
        url,
        method,
              // @ts-ignore
        status: error.response?.status,
              // @ts-ignore
        data:error.response?.data || 'No response',
      }
      logger({ message: 'Error Response: ', data: logData });

      throw new Error(
              // @ts-ignore
        (error?.response?.data?.Message as string) ||
              // @ts-ignore

          (error?.response?.data?.Error as string) ||
                // @ts-ignore

          (error?.response?.data && JSON.stringify(error?.response?.data)) ||
          'Request Failed'
      );
    }

    return instance.interceptors.response.use((response) => {
      const {url, method}  = response.config
      let foundRoute = false;
      for(const route of LogConfig.allowed.api) {
        if(url?.includes(route)) {
          foundRoute = true;
          break;
        }
      }
      if(!foundRoute) {
        return response;
      }
      const {data, status}  = response
      const logData = {
        url,
        method,
        status,
        data,
      }
      logger({ message: 'Response: ', data: logData });
      return response;
    }, RedirectWhenUnauthorize);
  }
  /**
   * @static @function Remove() Eject the interceptor from the Axios instance
   * @param {AxiosInstance} instance Instance of Axios
   * @param {number} id Interceptor Id
   */
  static Remove(instance: AxiosInstance, id: number): void {
    instance.interceptors.response.eject(id);
  }
}