import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  DefaultImageType,
  FileBucketURL,
  PaginationSettings,
  PlatformApi,
  topLoaderProgress,
} from '../../configs';
import { OperatorRatingResponseModel } from '../../models/api/Operator';
import {
  useFunctionalityCallSummary,
  useFunctionalityOperatorProfileRatings,
} from '../../pages/hooks';
import { ModalCallSummary } from '..';
import { ModalDeleteOperatorRating } from '../modals/operatorRatingDeleteModal';
import { Pagination } from '../../components';
import { useFunctionalityImageView } from '../../pages/hooks/functionalityImageView';
import Star from '../../components/common/rating/Star';
import { Searchbar } from '../Common/searchbar';
import { CallSummaryResponseModel } from '../../models/api/Call';
import moment from 'moment';
import ReactDOM from 'react-dom';
import LoadingBar from 'react-top-loading-bar';
import { AssetSvg } from '../../assets';

type Props = {
  readonly: boolean;
  operatorId: string;
  userDetails: any;
  ratingCount?: any;
  avgrating?: any;
};
export function Ratings(props: Props) {
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const { operatorId, userDetails } = props;
  const [count, setCount] = useState(0);
  const [cumulativeCount, setCumulativeCount] = useState(0);
  const { defaultImageLoad, imageView, onUploadComplete } =
    useFunctionalityImageView();
  const [pagedRatingResponse, setPagedRatingResponse] =
    useState<OperatorRatingResponseModel[]>([]);
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [callSummaryData, setCallSummaryData] =
    React.useState<CallSummaryResponseModel>();
  const { LoadById } = useFunctionalityCallSummary();
  const [show, setShow] = React.useState(false);
  const [ratingId, setRatingId] = useState('');

  let pagedOperatorRatingUrl: string;
  pagedOperatorRatingUrl = PlatformApi.Operator.GetOperatorRatings(
    operatorId,
    pageSize,
    pageIndex,
    searchText
  );

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const { pagedRatingListCount, operatorRatingsList } =
    useFunctionalityOperatorProfileRatings({
      operatorId: props.operatorId || '',
      setLoaderProgress,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
    });

  useEffect(() => {
    setPagedRatingResponse(operatorRatingsList)
  }, [operatorRatingsList]);

  const calculateRating = (responseData: OperatorRatingResponseModel[]) => {
    var TotalCount = 0;
    var rateCount = 0;
    responseData.forEach((element) => {
      TotalCount = TotalCount + element.rating;
      rateCount++;
    });
    setCount(rateCount);
    setCumulativeCount(TotalCount);
  }
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="component-card  mb-3 d-xl-block d-lg-block d-md-block ">
        <div className="component-card-header d-flex align-items-center justify-content-between border-left border-right border-top ">
          <div>Ratings</div>
        </div>
        <div className="component-card-body border">
          <div className="row pb-3 align-items-center mb-1">
            <div className="col width-content pr-0">
              {count > 0 && (
                <h1 className="text-purple mb-0 font-500">
                  {(Math.ceil((cumulativeCount / count) * 100) / 100).toFixed(
                    2
                  )}
                </h1>
              )}
            </div>
            <div className="col">
              {count > 0 && (
                <div>
                  <Star rating={cumulativeCount / count} view={true} />
                </div>
              )}
              {count > 0 && (
                <div>
                  {' '}
                  {count} {count > 1 ? 'Reviews' : 'Review'}
                </div>
              )}
            </div>
          </div>
          <div>
            <Searchbar searchText={searchText} handleSearch={handleSearch} />
          </div>

          {pagedRatingResponse.length ? (
            pagedRatingResponse?.map((item:any) => {
              return (
                <div className="row border-bottom py-3 align-items-center">
                  <div className="pl-3">
                    <div className="rating-profile-image">
                      <img
                        src={`${FileBucketURL.Root}${item.callerId}?${imageView.fileTimeStamp}`}
                        alt=""
                        onError={(ev) => {
                          defaultImageLoad(
                            ev,
                            DefaultImageType.UserProfile,
                            '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                          );
                        }}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div> {item.callerName}</div>

                    <div> {item.callerCompanyName} </div>

                    <div> {moment(item.time).format('lll')} </div>

                    <div>
                      <Star
                        rating={item.rating}
                        view={true}
                      />
                    </div>
                  </div>
                  <div className="col-2 text-right pr-1">
                    <div
                      className="btn btn-link svg-color"
                      title="Edit"
                      onClick={() => {
                        setLoaderProgress(topLoaderProgress.start);
                        LoadById(item.callId).then((data) => {
                          setLoaderProgress(topLoaderProgress.complete);
                          if (data) setCallSummaryData(data);
                          setShow(true);
                        });
                      }}
                    >
                      <span>
                        <AssetSvg.Edit />
                      </span>
                    </div>

                    <div
                      className="btn btn-link svg-color"
                      title="Delete"
                      onClick={() => {
                        setRatingId(item.id);
                        setDeleteModalShow(true);
                        // setUserId(row.userId)
                      }}
                    >
                      <span>
                        <AssetSvg.Delete />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div> No Data Found </div>
          )}
          <ModalCallSummary
            summary={callSummaryData}
            show={show}
            handleShow={setShow}
            operatorId={operatorId}
          />
          <ModalDeleteOperatorRating
            show={deleteModalShow}
            handleShow={setDeleteModalShow}
            ratingId={ratingId}
            operatorId={operatorId}
          />
          <div className="pt-3">
            <Pagination
              pageSize={pageSize}
              pageIndex={pageIndex}
              count={pagedRatingListCount}
              onChangePageIndex={onChangePageIndex}
              onChangePageSize={onChangePageSize}
            />
          </div>
        </div>
      </div>
    </>
  );
}
