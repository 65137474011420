import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../components";
import { ApiSchemaCompanyByIdData, Option, CompanyUpdateModel, } from "../../models";
import {
  useFunctionalityCountry,
  useFunctionalityState,
  useFunctionalityValidateEmail,
  useFunctionalityZipcodeValidation,
} from "../../pages/hooks";
import { timeZones } from "./jobFormData";
import { currencyList } from "../../utils/currencies"
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { AppConstants, DefaultCountry, InputFieldValidationErrorMessage, reactSelectFilterConfig } from "../../configs";
import { useFunctionalityAddressFieldValidation } from "../../utils";
import { useAppSelector } from "../../core";
import useFunctionalityValidateUSAPhoneNumber from "../../pages/hooks/functionalityValidateUSAPhoneNumber";

type Props = {
  show: boolean;
  companyData?: ApiSchemaCompanyByIdData;
  handleShow: (value: boolean) => void;
  onUpdateHandler: any;
  initialStateList?: any;
};

export function ModalCompanyEditInfo(props: Props): JSX.Element {
  const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm<CompanyUpdateModel>();
  const { show, handleShow, companyData, onUpdateHandler, initialStateList } = props;
  const { countryList } = useFunctionalityCountry(show);
  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker()
  const { handleAddressLength, addressLengthError } = useFunctionalityAddressFieldValidation()
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const { stateList } = useFunctionalityState(selectedCountry, show);
  const onCountryChangeHandler = (value: Option | null) => {
    setSelectedCountry(value ? value.value : "");
    setValue("address.countryId", value?.value, {
      shouldValidate: true,
    });
  };
  const [currencyErrMsg, setCurrencyErrMsg] = useState("")
  const [faxValidationMsg, setFaxValidationMsg] = useState("")
  const [selectedZipcode, setSelectZipcode] = React.useState<string>("");
  const stateName = initialStateList.find((x: any) => x.value === companyData?.address?.stateId)
  const [selectedState, setSelectedState] = React.useState<string>("");
  const [CountryFieldkey, setCountryFieldKey] = useState(Date.now());
  const [StateFieldkey, setStateFieldKey] = useState(Date.now());
  const { validateEmail, emailValidationMsg, } = useFunctionalityValidateEmail();
  const { validateTeNumber, numberErrMsg } = useFunctionalityValidateUSAPhoneNumber();

  useEffect(() => {
    setSelectZipcode(companyData?.address?.zipCode ?? "");
    setSelectedCountry(companyData?.address?.countryId ?? "")
  }, [companyData?.address])

  useEffect(() => {
    setCountryFieldKey(Date.now())
  }, [countryList]);
  useEffect(() => {
    setStateFieldKey(Date.now())
  }, [stateList]);

  const validateFax = (e: any) => {

    var faxRegEx = /^\+?[0-9]{10,}$/;
    if (e.target.value.match(faxRegEx)) {
      setFaxValidationMsg("")
    }
    else {
      setFaxValidationMsg("Please enter a valid fax number")
    }
  }
  const handleState = (event: any) => {
    setSelectedState(event.label);
  };

  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value)
  }
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry: selectedCountry ? selectedCountry : companyData?.address?.countryId!,
    selectedState: selectedState ? selectedState : stateName?.label,
    selectedZipcode: selectedZipcode ? selectedZipcode : companyData?.address?.countryId!,
  });
  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode])

  const handleCurrency = (event: any) => {
    if (selectedCountry === DefaultCountry.USA) {
      if (event !== "USD") {
        setCurrencyErrMsg("Please Enter Valid Currency");
      }
      else {
        setCurrencyErrMsg("");
      }
    }
    else {
      setCurrencyErrMsg("");
    }
  };

  // const onClear = () => {
  //   selectInputRef.current.select.clearValue();
  // };

  const { profile } = useAppSelector((state) => state.auth);
  return (
    <Modal show={show} size="lg" onHide={() => handleShow(false)} centered>
      <form action="" onSubmit={handleSubmit(onUpdateHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Update Company Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="name" className="position-relative required">
                  Company Name
                </label>
                <input
                  {...register('name', { required: true, validate: useFunctionlityInputFieldValidation })}
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  defaultValue={companyData?.name}
                  readOnly={(profile?.userRole === AppConstants.UserRoles.Admin || profile?.userRole === AppConstants.UserRoles.ResellerAdmin) ? false : true}
                  style={{ border: errors.name ? '1px solid red' : '' }} />
                {errors.name && errors.name.type == "required" && (<FormErrorMessage message="required" />)}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
              </div>
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="position-relative required"
                >
                  Address
                </label>
                <input
                  {...register('address.description', {
                    required: true, minLength: 2, maxLength: 128
                  })}
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  defaultValue={companyData?.address?.description}
                  style={{ border: errors.address?.description || addressLengthError ? '1px solid red' : '' }}
                  onChange={handleAddressLength} />
                {errors.address?.description && <FormErrorMessage message="required" />}
                {
                  addressLengthError && <FormErrorMessage message={addressLengthError} />
                }
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="city" className="position-relative required">
                      City
                    </label>
                    <input
                      type="text"
                      {...register('address.city', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces
                      })}
                      className="form-control"
                      placeholder="City"
                      defaultValue={companyData?.address?.city}
                      onChange={(value) => {
                        // 
                        setValue("address.city", value.target.value, {
                          shouldValidate: true,

                        });
                      }}
                      style={{ border: errors.address?.city ? '1px solid red' : '' }} />
                    {
                      errors.address?.city && errors.address?.city.type == "validate" && (
                        <FormErrorMessage message="Please enter valid value" />
                      )
                    }
                    {errors.address?.city && <FormErrorMessage message="required" />}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="stateId"
                      className="position-relative required"
                    >
                      State
                    </label>
                    <input
                      className="d-none"
                      {...register('address.stateId', { required: true })}
                      defaultValue={companyData?.address?.stateId}
                      style={{ border: errors.address?.stateId ? '1px solid red' : '' }} />
                    <Select
                      key={StateFieldkey}
                      className={` ${errors.address?.stateId?.type == "required" ? 'field-required' : ''} `}
                      placeholder="Select state"
                      // ref={selectInputRef}
                      defaultValue={
                        companyData?.address?.stateId
                          ? stateList.find(
                            (x) => x.value === companyData?.address?.stateId
                          )
                          : null
                      }
                      isDisabled={stateList.length === 0 ? true : false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={selectedCountry !== "" && stateList.length > 0 ? stateList : initialStateList}
                      filterOption={createFilter(reactSelectFilterConfig)}
                      onChange={(value) => {
                        handleState(value);
                        setValue("address.stateId", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.address?.stateId && <FormErrorMessage message="required" />}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label
                    htmlFor="zipCode"
                    className="position-relative required"
                  >
                    Zip
                  </label>
                  <input
                    type="text"
                    {...register('address.zipCode', {
                      required: true,
                      validate: handleSpecialCharacterWhiteSpaces
                    })}
                    className="form-control"
                    placeholder="Zip"
                    defaultValue={companyData?.address?.zipCode}
                    style={{ border: errors.address?.zipCode ? '1px solid red' : '' }}
                    onChange={(event) => { handleZip(event) }} />
                  {
                    zipCodeError && (
                      <FormErrorMessage message={zipCodeError} />
                    )
                  }
                  {
                    errors.address?.zipCode && errors.address?.zipCode.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )
                  }
                  {errors.address?.zipCode && errors.address?.zipCode.type == "required" && <FormErrorMessage message="required" />}
                </div>
                <div className="col">
                  <label
                    htmlFor="countryId"
                    className="position-relative required"
                  >
                    Country
                  </label>
                  <input
                    className="d-none"
                    {...register('address.countryId', { required: true })}
                    defaultValue={companyData?.address?.countryId} />
                  <Select
                    key={CountryFieldkey}
                    className={` ${errors.address?.countryId?.type == "required" ? 'field-required' : ''} `}
                    placeholder="Select country"
                    isDisabled={countryList.length === 0 ? true : false}
                    defaultValue={
                      companyData?.address?.countryId
                        ? countryList.find(
                          (x) => x.value === companyData?.address?.countryId
                        )
                        : null
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={countryList}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    onChange={(value) => {
                      onCountryChangeHandler(value);
                      setValue("address.stateId", "", {
                      });
                    }}
                  />
                  {errors.address?.countryId && (
                    <FormErrorMessage message="required" />
                  )}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label
                    htmlFor="timeZone"
                    className=" position-relative required"
                  >
                    Time Zone
                  </label>
                  <input
                    className="d-none"
                    {...register('timeZone', { required: true })}
                    defaultValue={companyData?.timeZone}
                    style={{ border: errors.timeZone ? '1px solid red' : '' }} />
                  <Select
                    className={` ${errors.timeZone?.type == "required" ? 'field-required' : ''} `}
                    placeholder="Select Time Zone"
                    options={timeZones}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    defaultValue={
                      companyData?.timeZone ? timeZones.find((x) => x.value === companyData?.timeZone) : null
                    }
                    onChange={(value) => {
                      setValue("timeZone", value?.value? value?.value : "", {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.timeZone && <FormErrorMessage message="required" />}
                </div>

                <div className="col-6">
                  <label
                    htmlFor="currency"
                    className="position-relative required"
                  >
                    Currency
                  </label>
                  <input
                    {...register('currency', { required: true })}
                    className="d-none"
                    defaultValue={companyData?.currency}
                    style={{ border: errors.currency ? '1px solid red' : '' }} />
                  <Select
                    className={` ${errors.currency?.type == "required" ? 'field-required' : ''} `}
                    placeholder="Select Currency"
                    options={currencyList}
                    defaultValue={
                      currencyList ? currencyList.find((x) => x.value === companyData?.currency) : null
                    }
                    filterOption={createFilter(reactSelectFilterConfig)}
                    onChange={(value) => {
                      handleCurrency(value?.value)
                      setValue("currency", value?.value ? value?.value : "", {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.currency && <FormErrorMessage message="required" />}
                  {
                    currencyErrMsg && (
                      <FormErrorMessage message={currencyErrMsg} />
                    )
                  }
                </div>
              </div>

              <div className="form-group row ">
                <div className="col-6">
                  <label htmlFor="phone" className="col-12 p-0 required">
                    Phone
                  </label>
                  <input
                    type="text"
                    {...register('address.phone', { required: true })}
                    className="form-control"
                    placeholder="Phone"
                    defaultValue={companyData?.address?.phone}
                    onChange={(event) => validateTeNumber(event)}
                    style={{ border: errors.address?.phone ? '1px solid red' : '' }} />
                  {errors.address?.phone && <FormErrorMessage message="required" />}
                  {
                    numberErrMsg && (
                      <FormErrorMessage message={numberErrMsg} />
                    )
                  }
                </div>
                <div className="col-6">
                  <label htmlFor="fax" className="col-12 p-0" >
                    Fax
                  </label>
                  <input
                    type="fax"
                    {...register('address.fax')}
                    className="form-control"
                    onChange={(e) => validateFax(e)}
                    placeholder="Fax"
                    defaultValue={companyData?.address?.fax} />
                  {
                    faxValidationMsg && (<FormErrorMessage message={faxValidationMsg} />
                    )
                  }
                </div>
              </div>

              <div className="form-group row ">
                <div className="col-12">
                  <label htmlFor="email" className="col-12 p-0 required">
                    Email
                  </label>
                  <input
                    type="text"
                    {...register('address.email', { required: true })}
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => {
                      validateEmail(e)
                    }}
                    defaultValue={companyData?.address?.email}
                    style={{ border: errors.address?.email ? '1px solid red' : '' }} />
                  {errors.address?.email && <FormErrorMessage message="required" />}
                  {
                    emailValidationMsg && (
                      <FormErrorMessage message={emailValidationMsg} />
                    )
                  }
                </div>
                <div className="col-12">
                  <label htmlFor="website" className="col-12 p-0" >
                    Website
                  </label>
                  <input
                    type="website"
                    {...register('address.website')}
                    className="form-control"
                    placeholder="Website"
                    defaultValue={companyData?.address?.website} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              handleShow(false);
              // setSelectZipcode("");
              // setSelectedCountry("");
              // setSelectedState("")
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase login-from-submit-btn user-from-submit-btn"
            disabled={numberErrMsg == "" && emailValidationMsg == "" && currencyErrMsg == "" && zipCodeError == "" ? false : true}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
