import React, { useState } from 'react'
import { USAPhoneNumberValidationRegex } from '../../configs';

export default function useFunctionalityValidateUSAPhoneNumber() {
    const [numberErrMsg, setNumberErrMsg] = useState("");
    const validateTeNumber = (e: any) => {
        let phoneNumber = e.target.value;
        let phoneNumberForRegexCheck = phoneNumber?.replace(/\s+/g, "");
        if (phoneNumber?.length === 0) {
            setNumberErrMsg("");
        }
        else if (phoneNumberForRegexCheck?.match(USAPhoneNumberValidationRegex)) {
            setNumberErrMsg("");
        }
        else {
            setNumberErrMsg("Please enter a valid contact number");
        }
    }
    return {
        validateTeNumber,
        numberErrMsg
    }
}
