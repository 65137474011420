import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from '../../../components';
import { PlatformType } from '../../../configs';
import { TypeContractTabContactPlatforms, TypeUpdateCompanyContract } from '../../../models';
import { useFunctionalityCompanyContract } from '../../../pages/hooks';
import Select from "react-select";
import { useFunctionalityInputChecker } from '../../../pages/hooks/functionalityInputChecker';

type Props = {
  id: string,
  show: boolean,
  handleShow: (value: boolean) => void,
  contractId: string,
  onUpdateSuccess?: any,
  setOnUpdateSuccess?: any,
  vriContractPlatforms?: any,
  opiContractPlatforms?: any,
  onSiteContractPlatforms?: any,
  contractInfo?: TypeContractTabContactPlatforms,
  contractType: PlatformType,
  languagePackList?: any;
}

export function ModalContractPlatforms(props: Props) {
  const { id, show, handleShow, contractId, onUpdateSuccess, setOnUpdateSuccess, vriContractPlatforms, opiContractPlatforms, contractInfo, contractType, languagePackList, onSiteContractPlatforms } = props;
  const { onUpdateVri, onUpdateOpi, onUpdateOnSite } = useFunctionalityCompanyContract();

  const [contractPlatformToggle, setContractPlatformToggle] = useState(contractInfo?.isEnabled);
  const [travelTimeToggle, setTravelTimeToggle] = useState(false);
  const [showVriLanguageDropdown, setShowVriLanguageDropdown] = useState(!contractInfo?.isEnabled);
  const [showOpiLanguageDropdown, setShowOpiLanguageDropdown] = useState(!contractInfo?.isEnabled);
  const [showOnsiteLanguageDropdown, setShowOnsiteLanguageDropdown] = useState(!contractInfo?.isEnabled);
  const [selectedLanguagePack, setSelectedLanguagePack] = useState<any>('')
  const { handleSpecialCharacterWhiteSpaces, numberFieldValidation, handleCopyPasteInvalidValue, handleLimitDecimalPlaces } = useFunctionalityInputChecker();
  const [travelTimeRateError, setTravelTimeRateError] = useState<string>('');
  const [formClickDisable, setFormClickDisable] = useState<boolean>(false);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<TypeUpdateCompanyContract>();
  useEffect(() => {
    setContractPlatformToggle(contractInfo?.isEnabled ? true : false);
    setShowVriLanguageDropdown(!contractInfo?.isEnabled);
    setShowOpiLanguageDropdown(!contractInfo?.isEnabled);
    setShowOnsiteLanguageDropdown(!contractInfo?.isEnabled);
  }, [contractInfo]);

  const handleFormClickDisable = (value: boolean) => {
    setFormClickDisable(value);
  }

  const submitHandler = (form: TypeUpdateCompanyContract) => {
    if (contractType === PlatformType.VRI) {
      const data = contractPlatformToggle ? {
        connectionFee: parseFloat(form.connectionFee.toString()),
        technologyFee: parseFloat(form.technologyFee.toString()),
        platform: contractType,
        isEnabled: true,
        languagePackId: selectedLanguagePack,
      } : {
        isEnabled: false,
        platform: contractType,
      };

      onUpdateVri(data, id, contractId).then(() => {
        handleShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
        handleFormClickDisable(false);
      }).catch(e => console.error(e));
    }
    else if (contractType === PlatformType.OPI) {
      const data = contractPlatformToggle ? {
        connectionFee: parseFloat(form.connectionFee.toString()),
        technologyFee: parseFloat(form.technologyFee.toString()),
        outboundFee: parseFloat(form.outboundFee.toString()),
        platform: contractType,
        isEnabled: true,
        languagePackId: selectedLanguagePack,
      } : {
        isEnabled: false,
        platform: contractType,
      };

      onUpdateOpi(data, id, contractId).then(() => {
        handleShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
        handleFormClickDisable(false);
      }).catch(e => console.error(e));
    }
    else if (contractType === PlatformType.OnSite) {
      const data = contractPlatformToggle ? {
        ...form,
        connectionFee: parseFloat(form.connectionFee.toString()),
        platform: contractType,
        isEnabled: true,
        languagePackId: selectedLanguagePack,
        travelTimeRate: parseFloat(form.travelTimeRate?.toString()),
        mileageRate: parseFloat(form.mileageRate?.toString())
      } : {
        isEnabled: false,
        platform: contractType,
      };

      onUpdateOnSite(data, id, contractId).then(() => {
        handleShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
        handleFormClickDisable(false);
      }).catch(e => console.error(e));
    }
  }

  const handleTravelRateValidation = (e: any) => {
    const rate = Number(e.target.value);
    if (rate > 100) {
      setTravelTimeRateError('Travel time rate is invalid');
    } else {
      setTravelTimeRateError('');
    }
  }

  const handleContractPlatformToggle = (contractType: PlatformType) => {
    if (!contractPlatformToggle) {
      if (contractType === PlatformType.VRI) {
        setShowVriLanguageDropdown(!contractInfo?.isEnabled);
      } else if (contractType === PlatformType.OPI) {
        setShowOpiLanguageDropdown(!contractInfo?.isEnabled);
      } else if (contractType === PlatformType.OnSite) {
        setShowOnsiteLanguageDropdown(!contractInfo?.isEnabled);
      }
    } else {
      if (contractType === PlatformType.VRI) {
        setShowVriLanguageDropdown(!contractInfo?.isEnabled);
      } else if (contractType === PlatformType.OPI) {
        setShowOpiLanguageDropdown(!contractInfo?.isEnabled);
      } else if (contractType === PlatformType.OnSite) {
        setShowOnsiteLanguageDropdown(!contractInfo?.isEnabled);
      }
    }
    setContractPlatformToggle(!contractPlatformToggle)
  }


  return (
    <Modal
      show={show}
      onHide={() => {
        setContractPlatformToggle(contractInfo?.isEnabled)
        handleShow(false);
      }}
      centered
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{PlatformType[contractType]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="component-card rounded mb-0">
            <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
              <div className="col-6 pl-0">{PlatformType[contractType]}</div>
              <div className="col-6 pr-0 text-right">
                <button
                  type="button"
                  className={contractPlatformToggle ? "btn btn-toggle on-off-btn active" : "btn btn-toggle on-off-btn"}
                  onClick={() => handleContractPlatformToggle(contractType)}
                  disabled={formClickDisable}
                >
                  <div className="handle"></div>
                </button>
              </div>
            </div>
            {
              contractPlatformToggle ? (
                <div
                  className="component-card-body rounded-bottom  border-left border-right border-bottom"
                  id="UpdateRate-VRI-collapse"
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="" className="position-relative required">{PlatformType[contractType]} {contractType === PlatformType.OnSite ? 'Booking Fee' : 'Connection Fee Per Call'}</label>
                        <div className="input-group ">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="flat-rate">$</span>
                          </div>
                          <input
                            type="float"
                            className="form-control"
                            {...register('connectionFee', { required: true, validate: handleCopyPasteInvalidValue })}
                            defaultValue={contractType === PlatformType.VRI ? vriContractPlatforms?.connectionFee :
                              contractType === PlatformType.OPI ? opiContractPlatforms?.connectionFee : contractInfo?.connectionFee}
                            // onChange={(evt: any) => {
                            //   setValue("connectionFee", Number(evt.target.value), {
                            //     shouldValidate: true,
                            //   });
                            // }}
                            onKeyPress={(event) => {
                              if (!/[0-9]|\./.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onKeyDown={(evt) => numberFieldValidation(evt)}
                            onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                          {errors.connectionFee && errors.connectionFee?.type === "required" && (
                            <FormErrorMessage message="required" />
                          )}

                          {errors.connectionFee && errors.connectionFee?.type === "validate" && (
                            <FormErrorMessage message="Please enter a valid value" />
                          )}
                        </div>
                      </div>
                      {
                        contractType !== PlatformType.OnSite ?
                          <div className="form-group">
                            <label htmlFor="" className="position-relative required">
                              {PlatformType[contractType]} Technology Fee Per Minute
                            </label>
                            <div className="input-group ">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="flat-rate">$</span>
                              </div>
                              <input
                                type="float"
                                className="form-control"
                                {...register('technologyFee', { required: true, validate: handleCopyPasteInvalidValue })}
                                defaultValue={contractType === PlatformType.VRI ?
                                  vriContractPlatforms?.technologyFee : contractType === PlatformType.OPI ? opiContractPlatforms?.technologyFee : contractInfo?.technologyFee}
                                // onChange={(evt: any) => {
                                //   setValue("technologyFee", Number(evt.target.value), {
                                //     shouldValidate: true,
                                //   });
                                // }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]|\./.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onKeyDown={(evt) => numberFieldValidation(evt)}
                                onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                              {errors.technologyFee && errors.technologyFee?.type === "required" && (
                                <FormErrorMessage message="required" />
                              )}
                              {errors.technologyFee && errors.technologyFee?.type === "validate" && (
                                <FormErrorMessage message="Please enter a valid value" />
                              )}
                            </div>
                          </div> :
                          null
                      }

                      {
                        contractType === PlatformType.OPI ? (
                          <div className="form-group">
                            <label htmlFor="" className="position-relative required">OPI Outbound Fee Per Minute</label>
                            <div className="input-group ">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="flat-rate">$</span>
                              </div>
                              <input
                                type="float"
                                className="form-control"
                                {...register('outboundFee', { required: true, validate: handleCopyPasteInvalidValue })}
                                defaultValue={opiContractPlatforms?.outboundFee}
                                // onChange={(evt: any) => {
                                //   setValue("outboundFee", Number(evt.target.value), {
                                //     shouldValidate: true,
                                //   });
                                // }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]|\./.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onKeyDown={(evt) => numberFieldValidation(evt)}
                                onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                              {errors.outboundFee && errors.outboundFee?.type === "required" && (
                                <FormErrorMessage message="required" />
                              )}
                              {errors.outboundFee && errors.outboundFee?.type === "validate" && (
                                <FormErrorMessage message="Please enter a valid value" />
                              )}
                            </div>
                          </div>
                        ) : null
                      }

                      {/* for VRI */}
                      {
                        contractType === PlatformType.VRI && showVriLanguageDropdown ? (
                          <div className="form-group">
                            <div className="row">
                              <div className="col-12">
                                <label htmlFor="" className="position-relative required">{PlatformType[contractType]} Language Pack</label>

                                <input className="d-none" {...register('languagePackId', { required: true })} />
                                <Select
                                  className={` ${errors.languagePackId?.type === "required" ? 'field-required' : ''} `}

                                  placeholder={`Choose ${(['a', 'e', 'i', 'o', 'u'].indexOf(PlatformType[1][0].toLowerCase()) !== -1) ? "an " : "a "}${PlatformType[1]} package`}
                                  isDisabled={languagePackList.length === 0 ? true : false}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={languagePackList}
                                  onChange={(option:any) => {
                                    setValue("languagePackId", option?.value);
                                    setSelectedLanguagePack(option?.value)
                                  }}
                                />
                                {
                                  errors.languagePackId && (
                                    <FormErrorMessage message="required" />
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        ) : null
                      }

                      {/* for OPI */}
                      {
                        contractType === PlatformType.OPI && showOpiLanguageDropdown ? (
                          <div className="form-group">
                            <div className="row">
                              <div className="col-12">
                                <label htmlFor="" className="position-relative required">{PlatformType[contractType]} Language Pack</label>

                                <input className="d-none" {...register('languagePackId', { required: true })} />
                                <Select
                                  className={` ${errors.languagePackId?.type === "required" ? 'field-required' : ''} `}

                                  placeholder={`Choose ${(['a', 'e', 'i', 'o', 'u'].indexOf(PlatformType[1 + 1][0].toLowerCase()) !== -1) ? "an " : "a "}${PlatformType[1 + 1]} package`}
                                  isDisabled={languagePackList.length === 0 ? true : false}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={languagePackList}
                                  onChange={(option: any) => {
                                    setValue("languagePackId", option?.value);
                                    setSelectedLanguagePack(option?.value)
                                  }}
                                />
                                {
                                  errors.languagePackId && (
                                    <FormErrorMessage message="required" />
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                      {/* for OnSIte */}
                      {
                        contractType === PlatformType.OnSite && showOnsiteLanguageDropdown && onSiteContractPlatforms && onSiteContractPlatforms.isEnabled.toString() === "false" ? (
                          <div className="form-group">
                            <div className="row">
                              <div className="col-12">
                                <label htmlFor="" className="position-relative required">{PlatformType[contractType]} Language Pack</label>

                                <input className="d-none" {...register('languagePackId', { required: true })} />
                                <Select
                                  className={` ${errors.languagePackId?.type === "required" ? 'field-required' : ''} `}

                                  placeholder={`Choose ${(['a', 'e', 'i', 'o', 'u'].indexOf(PlatformType[1 + 1][0].toLowerCase()) !== -1) ? "an " : "a "}${PlatformType[1 + 2]} package`}
                                  isDisabled={languagePackList.length === 0 ? true : false}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  options={languagePackList}
                                  onChange={(option:any) => {
                                    setValue("languagePackId", option?.value);
                                    setSelectedLanguagePack(option?.value)
                                  }}
                                />
                                {
                                  errors.languagePackId && (
                                    <FormErrorMessage message="required" />
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              ) : null
            }
          </div>
          {
            contractType === PlatformType.OnSite ? (
              <>
                <div className="component-card rounded mt-3">
                  <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
                    <div className="col-6 pl-0">Travel Time Rate</div>
                    <div className="col-6 pr-0 text-right">
                      {/* <button
                      type="button"
                      className={travelTimeToggle ? "btn btn-toggle on-off-btn active" : "btn btn-toggle on-off-btn"}
                      onClick={() => setTravelTimeToggle(!travelTimeToggle)}
                    >
                      <div className="handle"></div>
                    </button> */}
                    </div>
                  </div>
                  <div
                    className="component-card-body rounded-bottom  border-left border-right border-bottom"
                    id="UpdateTravelTime-collapse"
                  >
                    <div className="form-group">
                      <label htmlFor="" className="position-relative required">Rate that will be charged on the total bill as travel time fee</label>
                      <div className="input-group ">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="flat-rate"
                          >
                            %
                          </span>
                        </div>
                        <input
                          type="float"
                          className="form-control"
                          {...register(
                            'travelTimeRate',
                            { required: true, validate: handleCopyPasteInvalidValue },
                          )}
                          defaultValue={contractInfo?.travelTimeRate}
                          onChange={(evt: any) => {
                            //   setValue("travelTimeRate", Number(evt.target.value), {
                            //     shouldValidate: true,
                            //   });
                            handleTravelRateValidation(evt)
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]|\./.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onKeyDown={(evt) => numberFieldValidation(evt)}
                          onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                        {
                          errors.travelTimeRate && errors.travelTimeRate?.type === "required" && (
                            <FormErrorMessage message="required" />
                          )
                        }

                        {errors.travelTimeRate && errors.travelTimeRate?.type === "validate" && (
                          <FormErrorMessage message="Please enter a valid value" />
                        )}
                        {
                          travelTimeRateError && <FormErrorMessage message={travelTimeRateError} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="component-card rounded mt-3">
                  <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
                    <div className="col-6 pl-0">Mileage Rate</div>
                    <div className="col-6 pr-0 text-right">
                      {/* <button
                      type="button"
                      className={travelTimeToggle ? "btn btn-toggle on-off-btn active" : "btn btn-toggle on-off-btn"}
                      onClick={() => setTravelTimeToggle(!travelTimeToggle)}
                    >
                      <div className="handle"></div>
                    </button> */}
                    </div>
                  </div>
                  <div
                    className="component-card-body rounded-bottom  border-left border-right border-bottom"
                    id="UpdateTravelTime-collapse"
                  >
                    <div className="form-group">
                      <label htmlFor="" className="position-relative required">Amount that will be charged on the total bill as mileage fee (per mile)</label>
                      <div className="input-group ">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="flat-rate">$</span>
                        </div>
                        <input
                          type="float"
                          className="form-control"
                          {...register('mileageRate', { required: true, validate: handleCopyPasteInvalidValue })}
                          defaultValue={contractInfo?.mileageRate}
                          onChange={(evt: any) => {
                            setValue("mileageRate", Number(evt.target.value), {
                              shouldValidate: true,
                            });
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]|\./.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onKeyDown={(evt) => numberFieldValidation(evt)}
                          onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                        {
                          errors.mileageRate && errors.mileageRate?.type === "required" && (
                            <FormErrorMessage message="required" />
                          )
                        }
                        {errors.mileageRate && errors.mileageRate?.type === "validate" && (
                          <FormErrorMessage message="Please enter a valid value" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null
          }
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              // handleContractPlatformToggle();
              setContractPlatformToggle(contractInfo?.isEnabled)
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase update-from-submit-btn"
            onClick={() => handleFormClickDisable(true)}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
