import { useCallback, useEffect, useState } from 'react';
import { SELECTED_AUDIO_OUTPUT_KEY } from '../../configs/appConstants';
import useDevices from '../../pages/hooks/useDevices';
 

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');

  const setActiveSinkId = useCallback((sinkId: string) => {
    try {
      window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
      _setActiveSinkId(sinkId);
    } catch(error) {
      console.error(error)
    }

  }, []);

  useEffect(() => {
    try {
      const selectedSinkId = window.localStorage.getItem(SELECTED_AUDIO_OUTPUT_KEY);
      const hasSelectedAudioOutputDevice = audioOutputDevices.some(
        device => selectedSinkId && device.deviceId === selectedSinkId
      );
      if (hasSelectedAudioOutputDevice) {
        _setActiveSinkId(selectedSinkId!);
      }
    } catch(error) {
      console.error(error)
    }
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId] as const;
}
