import React from 'react'
import useSWR from 'swr';
import { InvoiceType, PlatformApi, topLoaderProgress } from '../../configs'
import { AxiosAuth } from '../../core';
import { ApiResponse, BillingAddressResponseModel } from '../../models';

type Props = {
    billerId?: string;
    setLoaderProgress?: any;
    invoiceType: InvoiceType;
}
let BillingAddressUrl: string;
export function useFunctionalityBillingAddress(props: Props) {
    const { billerId, setLoaderProgress, invoiceType } = props;
    if (invoiceType === 1) {

        BillingAddressUrl = PlatformApi.FinancialAccount.CompanyBillingAddress(billerId);
    }
    else if (invoiceType === 2) {
        BillingAddressUrl = PlatformApi.FinancialAccount.OperatorBillingAddress(billerId);
    }
    const { data: apiPagedBillingAddressList } = useSWR<BillingAddressResponseModel>(
        BillingAddressUrl,
        () => {
            setLoaderProgress(topLoaderProgress.start);
            return AxiosAuth.get<
                ApiResponse<BillingAddressResponseModel>
            >(BillingAddressUrl)
                .then((r) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    return r.data.data;
                })
                .catch((e) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    console.error(e);
                    return e;
                });
        }
    );
    return {
        billingAddressList: apiPagedBillingAddressList
    }
}