import { ApiSchemaLanguagePackData } from "..";

export type ApiSchemaCallOutflowData = {
    callInflowId?: string;
    callCenterName?: string;
    vriPackId?: string;
    opiPackId?: string;
    status?: CallOutflowStatusType;
};

export type ApiCallOutflowSchemaChangeStatus = {
    callInflowIds?: any;
    companyId?: string;
    status?: boolean;
};

export enum CallOutflowStatusType {
    Enabled = "true",
    Disabled = "false",
    Pending = "pending",
}