import { useCallback, useEffect, useRef } from 'react';
import { videoGridConfig } from '../../configs';
import { videoGridHelper } from '../../helpers';

const { centerParticipant, getBestParticipantBounds } = videoGridHelper();

export function useVideoGrid() {
  const roomRef = useRef<HTMLDivElement | null>(null);

  const attachRef = useCallback((ref: HTMLDivElement) => {
    roomRef.current = ref;
  }, []);

  useEffect(() => {
    const room = roomRef.current!;
    const mutationConfig = { childList: true };

    const handleResize = () => {
      const roomRect = room.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(room);
      const gridGap = computedStyle.getPropertyValue('row-gap');

      const { participantWidth, participantHeight } = getBestParticipantBounds({
        width: roomRect.width,
        height: roomRect.height,
        totalParticipant: room.childElementCount,
        aspectRatio: videoGridConfig.aspectRatio,
        maxRows: videoGridConfig.maxRows,
        gridGap: parseInt(gridGap) || 10,
      });
      const fontSize = Math.min(participantWidth / 14, 16);
      room.style.setProperty('--participant-width', participantWidth + 'px');
      room.style.setProperty('--participant-height', participantHeight + 'px');
      room.style.setProperty('--participant-font-size', fontSize + 'px');
      room.style.setProperty(
        '--participant-font-padding',
        fontSize < 12 ? '5px' : '10px'
      );

      centerParticipant(room);
    };

    const handleMutation = (allMutationRecord: MutationRecord[]) => {
      const mutationRecord = allMutationRecord[0];
      if (
        mutationRecord.addedNodes.length ||
        mutationRecord.removedNodes.length
      ) {
        handleResize();
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    const mutationObserver = new MutationObserver(handleMutation);

    resizeObserver.observe(room);
    mutationObserver.observe(room, mutationConfig);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return { attachRef };
}
