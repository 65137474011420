type Props = {
data: any
}


export function ColumnToolTip(props: Props): JSX.Element {
  return (
    <>
    <span
             title={props.data}
            >
              {
               props.data
              }
            </span>
    </>
  )
}