import { useEffect, useState } from "react";
import { AssetSvg } from "../../assets";
import { AllocationFlowType, BookingFlowType } from "../../configs";
import { ModalJobSettings } from "../../features/modals/jobSettingsModal";
import { BookingPolicyResponseModel } from "../../models/api";
import { useFunctionalityCompany } from "../hooks";
type Props = {
  companyName: string
  bookingPolicy?: BookingPolicyResponseModel,
  onUpdateSuccess?: any,
  setOnUpdateSuccess?: any
}
export function PageJobSettings(props: Props): JSX.Element {
  const { companyName, bookingPolicy, onUpdateSuccess, setOnUpdateSuccess } = props;
  const [show, setShow] = useState<boolean>(false);
  const allocationFlowSelect = [
    { label: "Internal Pool", value: AllocationFlowType.InternalPool },
    { label: "Shared Pool", value: AllocationFlowType.SharedPool },
  ];
  const bookingFlowSelect = [
    { label: "Manual", value: BookingFlowType.Manual },
    { label: "Direct Assignment", value: BookingFlowType.DirectAssignment },
    { label: "Offer Confirm", value: BookingFlowType.OfferConfirm },
  ];

  const { onUpdateBookingPolicy } = useFunctionalityCompany({ companyId: bookingPolicy?.companyId })
  const [policy, setPolicy] = useState<BookingPolicyResponseModel>(Object);

  useEffect(() => {
    if (bookingPolicy) setPolicy(bookingPolicy);
  }, [bookingPolicy]);
  useEffect(() => { }, [policy])

  return (
    <>
      {/* <h4 className="mt-1 mb-4 font-weight-light">Job Settings</h4> */}
      <div className="component-card mb-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
          <div>Booking policy for {companyName}</div>
          <button className="btn btn-link px-0 text-site btn-sm text-uppercase" onClick={() => {
            setShow(true);
          }}>
            <span className="mr-2">
              <AssetSvg.Edit />
            </span>
            <span className="font-sz-10">Edit Info</span>
          </button>
        </div>
        <div className="component-card-body border">
          {/* <div className="row">
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <p className="">
                <strong>Allocation Flow</strong>
              </p>
            </div>
            <div className="col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">
              <p className="">
                {policy ? allocationFlowSelect?.find(x => x.value === policy?.allocationFlow)?.label : allocationFlowSelect[0].label}
                <p className="text-danger">
                  <small>
                    ( Allocates matching resources form {policy ? allocationFlowSelect?.find(x => x.value === policy?.allocationFlow)?.label : allocationFlowSelect[0].label} only )
                  </small>
                </p>
              </p>
            </div>
          </div> */}
          <div className="row">
            <div className="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
              <p className="">
                <strong>Booking Flow</strong>
              </p>
            </div>
            <div className="col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">
              <p className="">
                {policy ? bookingFlowSelect?.find(x => x.value === policy?.bookingFlow)?.label : bookingFlowSelect[2].label}
                <p className="text-danger">
                  <small>
                    ( A resource is offered and only allocated if confirmed )
                  </small>

                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ModalJobSettings onUpdateBookingPolicy={onUpdateBookingPolicy}
        setPolicy={setPolicy}
        policy={policy}
        show={show}
        handleShow={setShow}
        onUpdateSuccess={onUpdateSuccess}
        setOnUpdateSuccess={setOnUpdateSuccess} />
    </>
  );
}