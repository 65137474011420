import { useEffect, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppRouteUi, CallStatusType, CallType } from '../../configs';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiSchemaFilterOperatorRequest } from '../../models/api/Operator';
import {
  AudioCallInitiateParameter,
  videoCallInitiateParameter,
} from '../../models';
import { useDispatch } from 'react-redux';
import { ActionAudioCall, ActionVideoCall, useAppSelector } from '../../core';
import useFunctionalityMediaPermissions from '../hooks/mediaPermission/functionalityMediaPermission';
import { controlBrowserBackAndReloadButton } from '../../helpers';
import { UtilsJQuery } from '../../utils';

export default function OutgoingCallFailed(): JSX.Element {
  const retryTimerRef = useRef<number>();
  const { stopBothVideoAndAudio } = useFunctionalityMediaPermissions();
  const { controlBrowserBackButton } = controlBrowserBackAndReloadButton();
  const location = useLocation<{
    operatorFilterParam?: ApiSchemaFilterOperatorRequest;
    videoCallInitiateParam?: videoCallInitiateParameter;
    callInitiateTab?: string;
    directCallSelectedUserId?: string;
    callType: CallType;
    bookingId?: string;
    audioCallInitiateParam?: AudioCallInitiateParameter;
    message?: string;
  }>();

  const data = useAppSelector((state) => ({
    callStatus: state.videoCall.CallStatus,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRetry = () => {
    if (location.state?.callType === CallType.Video) {
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Zero,
          current: CallStatusType.Searching,
        })
      );

      history.push({
        pathname: AppRouteUi.VideoOutgoingCall.Root,
        state: {
          operatorFilterParam: location.state?.operatorFilterParam,
          callInitiateTab: location.state?.callInitiateTab,
          videoCallInitiateParam: location.state?.videoCallInitiateParam,
          directCallSelectedUserId: location.state?.directCallSelectedUserId,
          callType: CallType.Video,
        },
      });
    } else if (location.state?.callType === CallType.Audio) {
      dispatch(
        ActionAudioCall.SetAudioCallStatus({
          prev: CallStatusType.Zero,
          current: CallStatusType.Searching,
        })
      );

      history.push({
        pathname: AppRouteUi.OutgoingCall.Root,
        state: {
          operatorFilterParam: location.state?.operatorFilterParam,
          callInitiateTab: location.state?.callInitiateTab,
          audioCallInitiateParam: location.state?.audioCallInitiateParam,
          directCallSelectedUserId: location.state?.directCallSelectedUserId,
          callType: CallType.Audio,
        },
      });
    }
  };

  stopBothVideoAndAudio();

  useEffect(() => {
    if (data.callStatus?.current === CallStatusType.OperatorNotFound) {
      controlBrowserBackButton();
    }
  }, [data.callStatus?.current]);

  useEffect(() => {
    return () => {
      clearTimeout(retryTimerRef.current);
    };
  }, []);

  return (
    <div className="">
      <div className="site-call-page">
        <div className="site-call-page-wrapper bg-purple">
          <div className="container-fluid h-100 d-flex align-items-center justify-content-center ">
            <div className="row ">
              <div className="col-12 text-center">
                <div className="rounded-warning-white">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
              </div>
              <div className="col-12 text-center">
                <h5 className="text-white my-5"> {location.state?.message}</h5>
                <div>
                  <Link
                    to={AppRouteUi.Home.Root}
                    className="btn btn-primary"
                    role="button"
                  >
                    Back
                  </Link>
                  <span className="ml-3">
                    <button
                      className="btn btn-primary call-retry-btn"
                      onClick={() => {
                        const loading =
                          UtilsJQuery?.Ladda(".call-retry-btn")?.start?.();
                        retryTimerRef.current = window.setTimeout(() => {
                          loading?.stop?.();
                          handleRetry();
                        }, 2000);
                      }}
                    >
                      Retry
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
