import React, { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { PaginationSettings } from "../../configs";
import { AdminPageTemplate, ColumnToolTip } from "../../features";
import { ApiSchemaTinData } from "../../models/api/tin";
import { useFunctionalityPagedTin } from "../hooks";
import { ApiDataMapper } from "../../utils";
import ReactDOM from "react-dom";

export function PageTIN(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const { pagedTinList, pagedTinListCount } = useFunctionalityPagedTin({
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
  });

  useEffect(() => {
    if (pagedTinList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedTinList]);

  const tinNumberTableColumns = [
    {
      name: <ColumnToolTip data="TIN" />,
      selector: (row: ApiSchemaTinData) => {
        return <ColumnToolTip data={row.tinNumber} />;
      },
    },
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaTinData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="Email" />,
      selector: (row: ApiSchemaTinData) => {
        return <ColumnToolTip data={row.email} />;
      },
    },
    {
      name: <ColumnToolTip data="Role" />,
      selector: (row: ApiSchemaTinData) => {
        return (
          <ColumnToolTip data={ApiDataMapper.MapUserRole(row.role || "")} />
        );
      },
    },
    {
      name: <ColumnToolTip data="Company" />,
      selector: (row: ApiSchemaTinData) => {
        return <ColumnToolTip data={row.company} />;
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="TIN"
        tableHeaders={tinNumberTableColumns}
        tableData={pagedTinList || []}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedTinListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
    </>
  );
}
