import Modal from 'react-bootstrap/Modal';

export function EnableCookiesPage() {
  return (
    <Modal
      size="lg"
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      id="enable-all-cookies-modal"
    >
      <Modal.Body>
        <h4 className="display-4" id="message">
          Please enable all cookies in your browser to continue...
        </h4>
        <div className="text-center p-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
