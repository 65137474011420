import { Dispatch, MouseEventHandler, SetStateAction, useRef } from 'react';
import { SideNav } from './SideNav';

interface IProps {
  isToggled: boolean;
  setToggleStatus: Dispatch<SetStateAction<boolean>>;
}

export function AppDrawer({ isToggled, setToggleStatus }: IProps) {
  const isSubMenuClickedRef = useRef(false);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (isSubMenuClickedRef.current) {
      isSubMenuClickedRef.current = false;
      return;
    }
    setToggleStatus(false);
  };

  return (
    <div
      id="app-drawer"
      className={isToggled ? 'd-flex' : 'd-none'}
      onClick={handleClick}
    >
      <SideNav
        isToggled={isToggled}
        isSubMenuClickedRef={isSubMenuClickedRef}
      />
      <div className="w-100 h-100 custom-backdrop" />
    </div>
  );
}
