import { Modal } from "react-bootstrap"
import React, { useState } from "react";
import { FormErrorMessage } from "../../components";
import { useForm } from "react-hook-form";
import { ApiSchemaCreateJob, ApiSchemaJobDetailsById } from "../../models";
import { useFunctionalityJob, useFunctionalityJobEditRateChange } from "../../pages/hooks";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";

type Props = {
  show: boolean,
  handleShow: (value: boolean) => void,
  jobId?: any,
  rate?: number,
  noteForFinance?: string
  setSweetChangeProp?: any
}

export function ModalEditRate(props: Props): JSX.Element {

  const [newRate, setNewRate] = useState<number>(props?.rate || 0);
  const [financeNote, setFinanceNote] = useState<string>(props?.noteForFinance || '');
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<ApiSchemaCreateJob>();
  const { JobEditRateChange } = useFunctionalityJobEditRateChange();
  const [rateError, setRateError] = useState<string>('');
  const [textError, setTextError] = useState<string>('');

  const { handleCopyPasteInvalidValue, numberFieldValidation, handleSpecialCharacterWhiteSpaces, handleLimitDecimalPlaces } = useFunctionalityInputChecker()

  const handleRateChange = (event: any) => {
    const rateValue = event.target.value
    if (isNaN(rateValue) || parseInt(rateValue) < 0) {
      setRateError("Please Input Numeric Value")
      return
    }
    else {

      setRateError('')
    }
    setNewRate(Number(event.target.value));
  }

  const handleFinanceNote = (event: any) => {
    setFinanceNote(event.target.value);
  }

  const modalHideHandler = () => {
    props.handleShow(false);
  }

  const handleSubmitRateAndFinanceNote = (form: ApiSchemaJobDetailsById) => {
    if (!handleSpecialCharacterWhiteSpaces(financeNote)) {
      setTextError("Please enter valid value")
      return
    } else {
      setTextError("")
    }
    form = {
      ...form,
      rate: newRate,
      noteForFinance: financeNote
    }

    JobEditRateChange(props?.jobId, form)
      ?.then(() => {
        modalHideHandler();
        props?.setSweetChangeProp((prev: number) => prev + 1)
      })
      .catch(err => console.log(err));
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
    >
      {/*@ts-ignore */}
      <form onSubmit={handleSubmit(handleSubmitRateAndFinanceNote)} noValidate>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Custom rate for the job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="" className="col-12 p-0">Resource rate</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1"> &#36; </span>
                </div>
                <input
                  type="float"
                  className="form-control"
                  placeholder="Rate"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  defaultValue={newRate}
                  {...register('rate', {
                    required: true,
                    validate: handleCopyPasteInvalidValue
                  })}
                  onKeyPress={(event) => {
                    if (!/[0-9]|\./.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(evt) => numberFieldValidation(evt)}
                  onChange={handleRateChange}
                  onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
              </div>
              {rateError && (
                <FormErrorMessage message={rateError} />
              )}

              {errors.rate && errors.rate.type === "required" && (
                <FormErrorMessage message={"required"} />
              )}
              {errors.rate && errors.rate.type === "validate" && (
                <FormErrorMessage message={"Please enter a valid value"} />
              )}
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="" className="col-12 p-0">Comment for finance</label>
              <textarea name="noteForFinance"
                id=""
                className="form-control"
                defaultValue={financeNote}
                onBlur={handleFinanceNote} >

              </textarea>
              {textError && (
                <FormErrorMessage message={textError} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-link text-site text-uppercase" onClick={() => props.handleShow(false)}>Cancel</button>
          <button type="submit" className="login-from-submit-btn btn btn-primary text-uppercase">Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}