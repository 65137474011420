import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TwilioError } from 'twilio-video';
import { ActionAuth, AxiosAuth } from '..';
import { AppConstants, PlatformApi, device } from '../../configs';
import { useWebSocket } from '../../pages/hooks';
import { logger } from '../../utils';
import { useAppSelector } from '../Redux';

export const SocketContext = React.createContext<{
  socketConnection: WebSocket | null;
}>({ socketConnection: null });

export function SocketContextProvider(
  props: React.PropsWithChildren<{}>
): JSX.Element {
  const dispatch = useDispatch();
  const { webToken, isLoggedIn } = useAppSelector((state) => ({
    profileId: state.auth.profile?.id,
    isLoggedIn: state.auth.isLoggedIn,
    webToken: state.auth.VRItoken,
  }));
  const webTokenRef = useRef(webToken);
  const { webSocketConnection: socketConnection } = useWebSocket();
  const socketConnectionMemo = useMemo(
    () => ({ socketConnection }),
    [socketConnection]
  );

  const loadTwilioToken = useCallback(() => {
    logger({ message: 'Getting WebToken' });
    AxiosAuth.get(PlatformApi.Authentication.Token())
      .then((response) => {
        if (!!response.data && response.status === AppConstants.Api.StatusOK) {
          const token = response.data.token;
          if (webTokenRef.current) {
            logger({ message: 'Updating WebToken' });
            device.updateToken(token);
          } else {
            logger({ message: 'Initiating WebToken' });
            device.setup(token, { enableRingingState: true });
          }
          dispatch(ActionAuth.SetVRItoken(token));
          webTokenRef.current = token;
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          logger({
            message: 'Error: Fetching WebToken failed: ',
            data: error.response,
          });
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    loadTwilioToken();
  }, [isLoggedIn, loadTwilioToken]);

  useEffect(() => {
    const handleDeviceOffline = (error: TwilioError) => {
      if (error.code === 31205) {
        loadTwilioToken();
      }
    };
    device.on('error', handleDeviceOffline);

    return () => {
      device.off('error', handleDeviceOffline);
    };
  }, [loadTwilioToken]);

  return (
    <SocketContext.Provider value={socketConnectionMemo}>
      {props.children}
    </SocketContext.Provider>
  );
}
