import { TWILIO_CONFIG } from '../configs';
import { logger } from './logger';
import { UserAgentUtils } from './userAgentUtils';

interface IStopMediaParams {
  kind?: 'video' | 'audio';
  id?: string;
  tracks: MediaStreamTrack[];
}

const allTracksHashTable: { [key: string]: MediaStreamTrack } = {};

function getUpdatedConstraints(constraints?: MediaStreamConstraints) {
  let updatedConstraints = constraints || { audio: true, video: true };
  if (
    !updatedConstraints.video ||
    Object.keys(updatedConstraints.video).length
  ) {
    return updatedConstraints;
  } else {
    const isMobile =
      UserAgentUtils.isWebView() || UserAgentUtils.isMobileBrowser();
    updatedConstraints.video = isMobile
      ? TWILIO_CONFIG.videoCall.mobile.constraints
      : TWILIO_CONFIG.videoCall.desktop.constraints;
    return updatedConstraints;
  }
}

async function startMedia(
  constraints?: MediaStreamConstraints
): Promise<MediaStreamTrack[] | null> {
  try {
    const updatedConstraints = getUpdatedConstraints(constraints);
    const stream = await navigator.mediaDevices.getUserMedia(
      updatedConstraints
    );
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      const data = {
        kind: track.kind,
        id: track.id,
        label: track.label,
        muted: track.muted,
        enabled: track.enabled,
      };
      allTracksHashTable[track.id] = track;
      logger({
        message: '[userMedia]: Started Track: ',
        data: data,
      });
    });
    return tracks;
  } catch (error) {
    logger({ message: '[userMedia]: Error Get user media', data: error });
    return null;
  }
}

function stopMedia({ kind, id, tracks }: IStopMediaParams) {
  tracks.forEach((track) => {
    const data = {
      kind: track.kind,
      id: track.id,
      label: track.label,
      muted: track.muted,
      enabled: track.enabled,
    };

    if (kind || id) {
      if ((id && track.id === id) || (!id && track.kind === kind)) {
        track.stop();
        delete allTracksHashTable[track.id];
        logger({
          message: '[userMedia]: Stopped Track: ',
          data,
        });
      }
    } else {
      track.stop();
      delete allTracksHashTable[track.id];
      logger({
        message: '[UserMedia]: Stopped Track: ',
        data,
      });
    }
  });
}

function stopAllMedia() {
  const allTracks = Object.values(allTracksHashTable);
  if (!allTracks.length) {
    return;
  }
  logger({ message: '[userMedia]: Stopping All tracks', data: allTracks });

  allTracks.forEach((track) => {
    const data = {
      kind: track.kind,
      id: track.id,
      label: track.label,
      muted: track.muted,
      enabled: track.enabled,
    };
    track.stop();
    delete allTracksHashTable[track.id];
    logger({
      message: '[userMedia]: Stopped Track: ',
      data,
    });
  });
}

export const UserMediaUtils = {
  startMedia,
  stopMedia,
  stopAllMedia,
};
