import { Dispatch, SetStateAction, CSSProperties } from 'react';
import { NetworkConnection } from '../common';
import { NumberPad } from './NumberPad';

type TypeDialPadProps = {
  setDialedInput: Dispatch<SetStateAction<string>>;
  dialedInputStyle: CSSProperties;
  dialedInput: string;
  handleDisconnect: Function;
};

export function DialPad({
  setDialedInput,
  dialedInputStyle,
  dialedInput,
  handleDisconnect
}: TypeDialPadProps) {
  return (
    <>
      <NetworkConnection hangupHandler={handleDisconnect} />
      <div className="dialer-output shadow-sm" style={dialedInputStyle}>
        <input
          className="border form-control text-center"
          value={dialedInput}
          style={dialedInputStyle}
        />
      </div>
      <NumberPad setDialedInput={setDialedInput} />
    </>
  );
}
