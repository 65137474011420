import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AccountType,
  AppConstants,
  CompanyType,
  PlatformApi,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  AccountResponseModel,
  WithPagination,
} from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  parentCompanyId: string;
  accountType: AccountType;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
  onDataLoadCallBack?: any;
  isPageDataLoad?: boolean;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
  isPageFilterData?: boolean;
};

let pagedAccountUrl: string;

export function useFunctionalityPagedAccount(props: Props) {
  const {
    parentCompanyId,
    accountType,
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    onDataLoadCallBack,
    isPageFilterData,
    filterData,
    filterDataChange,
    isPageDataLoad,
  } = props;
  const [pagedAccountListCount, setPagedAccountListCount] = useState<number>(0);

  pagedAccountUrl = PlatformApi.Finance.Account.PagedGetAll(
    parentCompanyId,
    accountType,
    pageSize,
    pageNo,
    searchText,
    filterDataChange
  );

  const { data: apiPagedAccountList } = useSWR<AccountResponseModel[]>(
    isPageFilterData || isPageDataLoad ? pagedAccountUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.post<
        ApiResponse<WithPagination<AccountResponseModel[]>>
      >(pagedAccountUrl, filterData)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedAccountListCount(r.data.data?.count);

          return r.data?.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const pagedAccountList = React.useMemo(() => {
    const data = Array.isArray(apiPagedAccountList) ? apiPagedAccountList : [];
    return data;
  }, [apiPagedAccountList]);
  return {
    pagedAccountList,
    pagedAccountListCount,
  };
}
type AccountProps = {
  companyType: CompanyType;
};
