import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, PlatformType, PlatformTypeConstant, topLoaderProgress, } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, WithPagination } from "../../models";
import { AppointmentDescriptionRequestModel, AppointmentDescriptionResponseModel, } from "../../models/api/modality";
import { UtilsJQuery } from "../../utils";

type Props = {
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress: any;
  CompanyId?: string
};

let pagedAppointmentDescriptionUrl: string;

export function useFunctionalityPagedAppointmentDescription(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress, CompanyId } = props;
  const [pagedAppointmentDescriptionListCount, setPagedAppointmentDescriptionListCount,] = useState(0);

  pagedAppointmentDescriptionUrl = PlatformApi.AppointmentDescription.PagedGetAll(CompanyId, pageSize, pageNo, searchText);

  const { data: apiPagedAppointmentDescriptionData } = useSWR<
    AppointmentDescriptionResponseModel[]
  >(pagedAppointmentDescriptionUrl, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<
      ApiResponse<WithPagination<AppointmentDescriptionResponseModel[]>>
    >(pagedAppointmentDescriptionUrl)
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        setPagedAppointmentDescriptionListCount(r.data.data?.count);
        return r.data.data?.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  });

  const pagedAppointmentDescriptionList = React.useMemo(() => {
    const data = Array.isArray(apiPagedAppointmentDescriptionData)
      ? apiPagedAppointmentDescriptionData
      : [];
    return data;
  }, [apiPagedAppointmentDescriptionData]);

  return {
    pagedAppointmentDescriptionList,
    pagedAppointmentDescriptionListCount,
    pagedAppointmentDescriptionUrl,
  };
}
export function useFunctionalityAppointmentDescriptionTab(props: Props) {
  const LoadAppointmentDescription = useCallback(async () => {
    const { setLoaderProgress, CompanyId } = props;
    pagedAppointmentDescriptionUrl = PlatformApi.AppointmentDescription.AppointmentDescriptionTabGetAll(CompanyId);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<WithPagination<AppointmentDescriptionResponseModel[]>>>(pagedAppointmentDescriptionUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(res.data.data.data);
      }
    }
    catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      return Promise.reject(e);
    }

  }, []);
  return {
    LoadAppointmentDescription,
    pagedAppointmentDescriptionUrl
  }
}
export function useFunctionalityAppointmentDescription() {
  const appointmentDescriptionUrl = PlatformApi.AppointmentDescription.GetAll();
  const appointmentDescriptionCreateUrl =
    PlatformApi.AppointmentDescription.Create();
  const appointmentDescriptionDeleteUrl =
    PlatformApi.AppointmentDescription.Delete();

  const responseMappedToRequest = (
    value?: AppointmentDescriptionResponseModel
  ): AppointmentDescriptionRequestModel => {
    if (value) {
      const request: AppointmentDescriptionRequestModel = {
        name: value.name,
        //pin: value.pin,
        appointmentCategoryId: value.appointmentCategoryId,
      };
      return request;
    } else return { name: "", appointmentCategoryId: "" }; //pin: "",
  };
  const stringToServiceType = (value: string): PlatformType => {
    switch (value) {
      case PlatformTypeConstant.VRI:
        return PlatformType.VRI;
      case PlatformTypeConstant.OPI:
        return PlatformType.OPI;
      case PlatformTypeConstant.OnSite:
        return PlatformType.OnSite;
      default:
        return 0;
    }
  };

  const onAddAppointmentDescription = React.useCallback(
    async (form: AppointmentDescriptionRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(
        ".apointmentdescription-form-submit-btn"
      );
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(
          appointmentDescriptionCreateUrl,
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(appointmentDescriptionUrl);
          mutate(pagedAppointmentDescriptionUrl);
          toast.success("Appointment Description added successfully");
        }
      } catch (e: any) {
        toast.error(e.message);
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onEditAppointmentDescription = React.useCallback(
    (editForm: AppointmentDescriptionRequestModel, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(
        ".apointmentdescription-form-submit-btn"
      );
      btnLoading.start?.();

      return AxiosAuth.put(
        PlatformApi.AppointmentDescription.Edit(id),
        editForm
      )
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            mutate(appointmentDescriptionUrl);
            mutate(pagedAppointmentDescriptionUrl);
            toast.success("Appointment Description edited successfully");
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error(e.message);
          return Promise.reject(e);
        });
    },
    []
  );

  const onDeleteAppointmentDescription = React.useCallback(
    async (deleteForm: string[]) => {
      const btnLoading = UtilsJQuery.Ladda(".delete-button");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.delete(
          appointmentDescriptionDeleteUrl,
          {
            data: deleteForm,
          }
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(appointmentDescriptionUrl);
          mutate(pagedAppointmentDescriptionUrl);
          toast.success("Appointment Description deleted successfully");
        }
      } catch (e) {
        toast.error("Failed to delete Appointment Type.");
        return Promise.reject(e);
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    onAddAppointmentDescription,
    onEditAppointmentDescription,
    onDeleteAppointmentDescription,
    responseMappedToRequest,
  };
}
