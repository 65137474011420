import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { PaginationSettings } from "../../configs";
import { AdminPageTemplate } from "../../features";
import { ApiSchemaVendorCompanyData } from '../../models/api/vendor';
import { useFunctionalityPagedVendorCompany, useFunctionalityVendorCompany } from "../../pages/hooks";
import { ApiDataMapper } from '../../utils/apiDataMapper';
import { ModalAddVendorCompany, ModalConfirmDelete } from "../modals";
type Props = {
  parentCompanyId: string
}
export function VendorCompanyTab(props: Props): JSX.Element {
  const { parentCompanyId } = props;
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ApiSchemaVendorCompanyData>();


  const { pagedVendorCompanyList, pagedVendorCompanyListCount, pagedVendorCompanyUrl } = useFunctionalityPagedVendorCompany(
    {
      pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress
    }
  );

  useEffect(() => {
    if (pagedVendorCompanyListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedVendorCompanyList]);
  const { onDelete, onChangeCallForwardingOption, companyWithoutVendorUrl } = useFunctionalityVendorCompany({
    companyId: parentCompanyId
  });
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const onChangeCallForwardingHandler = (row: ApiSchemaVendorCompanyData, index: number) => {
    row = { ...row, isCallForwarding: !row.isCallForwarding }
    onChangeCallForwardingOption(ApiDataMapper.VendorChangeCallForwardingOption(row))
      .then(() => {
        mutate(pagedVendorCompanyUrl)
      })
      .catch((e) => console.error(e));
  };
  const deleteHandler = () => {
    if (selectedRow) {
      onDelete(selectedRow.id)
        .then(() => {
          mutate(pagedVendorCompanyUrl)
          mutate(companyWithoutVendorUrl)
          setDeleteShowModal(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const vendorsCompanyTableColumns = [
    {
      name: "Company",
      selector: (row: ApiSchemaVendorCompanyData) => row.name,
    },
    {
      name: "Code",
      selector: (row: ApiSchemaVendorCompanyData) => row.code,
    },
    {
      name: "Call Forwarding",
      selector: (row: ApiSchemaVendorCompanyData, index: any) => (
        <input
          type="checkbox"
          checked={row.isCallForwarding}
          onChange={() => onChangeCallForwardingHandler(row, index)}
        />
      )
    },
    {
      name: "Actions",
      allowOverflow: true,
      selector: (row: ApiSchemaVendorCompanyData) => (
        <FontAwesomeIcon icon={faTrash} onClick={() => { setDeleteShowModal(true); setSelectedRow(row) }} />
      )
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="col-12 my-3 px-3">
        <button
          className="btn btn-primary text-uppercase"
          onClick={() => setShowModal(true)}
        >
          <i className="s7 s7-plus"></i> Add Company
        </button>
      </div>
      <div className="px-3 pb-3">
        <AdminPageTemplate
          loaderProgress={loaderProgress}
          tableHeaders={vendorsCompanyTableColumns}
          tableData={pagedVendorCompanyList || []}
          searchText={searchText}
          handleSearch={handleSearch}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageSize={pageSize}
          count={pagedVendorCompanyListCount}
          onChangePageIndex={onChangePageIndex}
          onChangePageSize={onChangePageSize}
          hasPagingData={hasPagingData}
          setHasPagingData={setHasPagingData}
        />
      </div>
      {
        showModal && <ModalAddVendorCompany
          companyId={parentCompanyId}
          show={showModal}
          handleShow={setShowModal}
          pagedVendorCompanyUrl={pagedVendorCompanyUrl}
          companyWithoutVendorUrl={companyWithoutVendorUrl}
        />
      }
      {
        showDeleteModal && <ModalConfirmDelete
          show={showDeleteModal}
          handleShow={setDeleteShowModal}
          deleteHandler={deleteHandler}
        />
      }
    </>
  );
}
