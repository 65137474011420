import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useAppSelector } from "../../core";
import { ApiSchemaCreateJob, ApiSchemaJobFormFields, BookingCustomFieldRequestModel, Option, } from "../../models";
import { useFunctionalityJob } from "../../pages/hooks/functionalityJob";
import { AdditionalInfo, AppointmentType, FaceToFaceForm, RequirementsForm, ScheduleForm, } from "../Jobs/CreateJob";
import { JobCustomFieldsForm } from "../Jobs/CreateJob/JobCustomFieldsForm";
import { AppConstants, GenderType, PlatformType } from "../../configs";
import { shallowEqual } from "react-redux";
import { useFunctionalityCompanyUsers, useFunctionalityUserGetNamesForBooking, useFunctionalityUserGetUserById, } from "../../pages/hooks";
import { mutate } from "swr";
import { ModalLocationSaveModal } from "./locationSaveModal";
import { JobCreateWarningModal } from "./common";
import moment from "moment";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  mutateUrl?: string;
  mutateInGetAllForCalendar?: Function;
};
export function ModalCreateJob(props: Props): JSX.Element {
  const { show, mutateUrl, mutateInGetAllForCalendar } = props;
  const { register, handleSubmit, formState: { errors }, setValue, control, trigger } = useForm<ApiSchemaCreateJob>();
  const [selectedUser, setSelectedUser] = useState<Option>(Object);
  const [updatedContactList, setUpdatedContactList] = useState<any>([]);
  const [defaultRequestedUser, setDefaultRequestedUser] = useState<any>();
  const [selectedLocation, setSelectedLocation] = useState("");
  const [lessThan24Hours, setLessThan24Hours] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [companyId, setCompanyId] = useState("");
  const [jobFormData, setJobFormData] = useState<ApiSchemaJobFormFields | null>(null);
  const [customFieldValues, setCustomFieldValues] = useState<BookingCustomFieldRequestModel[]>([]);
  const [hoursErrorMsg, setHoursErrorMsg] = useState("");
  const [minutesErrorMsg, setMinutesErrorMsg] = useState("");
  const [selectedServiceType, setSelectedServiceType] = useState<PlatformType>(PlatformType.VRI);
  const canCreateBooking = jobFormData?.isFaceToFaceEnabled || jobFormData?.isAudioEnabled || jobFormData?.isVideoEnabled;
  const havePlatformContractEnabled = jobFormData?.isFaceToFaceEnabled || jobFormData?.isAudioEnabled || jobFormData?.isVideoEnabled || false;

  const data = useAppSelector(
    (state) => ({
      userProfile: state.auth.profile,
    }),
    shallowEqual
  );
  const { onCreateJob, errMsg } = useFunctionalityJob({});

  const selectCompanyFirst =
    data?.userProfile?.userRole === AppConstants.UserRoles.Admin ||
      data?.userProfile?.userRole === AppConstants.UserRoles.ResellerAdmin ||
      data?.userProfile?.userRole === AppConstants.UserRoles.Manager ||
      data?.userProfile?.userRole === AppConstants.UserRoles.ResellerManager ||
      data?.userProfile?.userRole === AppConstants.UserRoles.Operator
      ? true
      : false;

  const { userNamesForBooking: contactList } =
    useFunctionalityUserGetNamesForBooking({
      companyId: data?.userProfile?.companyId,
      userId: selectedUser?.value,
      isInvoke: !selectCompanyFirst,
    });
  const { userData: dialUser } = useFunctionalityUserGetUserById({
    companyId: data?.userProfile?.companyId,
    userId: selectedUser?.value,
  });
  const { companyUserNames } = useFunctionalityCompanyUsers({
    companyId: selectedCompanyId,
    isInvoke: selectCompanyFirst,
  });
  useEffect(() => { }, [selectedUser]);
  useEffect(() => {
    if (
      data?.userProfile?.userRole !== AppConstants.UserRoles.Admin &&
      data?.userProfile?.userRole !== AppConstants.UserRoles.ResellerAdmin &&
      data?.userProfile?.userRole !== AppConstants.UserRoles.Manager &&
      data?.userProfile?.userRole !== AppConstants.UserRoles.ResellerManager &&
      data?.userProfile?.userRole !== AppConstants.UserRoles.CompanyAdmin &&
      data?.userProfile?.userRole !== AppConstants.UserRoles.Operator
    ) {
      const filteredUpdatedContactList = contactList.find((contact: any) => contact.value === data?.userProfile?.id);
      setUpdatedContactList([filteredUpdatedContactList] ?? []);
      setDefaultRequestedUser(filteredUpdatedContactList);
      setSelectedUser(filteredUpdatedContactList!);
    } else {
      if (data?.userProfile?.userRole === AppConstants.UserRoles.CompanyAdmin) {
        setUpdatedContactList(contactList);
        const companyAdminHimself = contactList.find((contact: any) => contact.value === data?.userProfile?.id);
        setDefaultRequestedUser(companyAdminHimself);
        setSelectedUser(companyAdminHimself!);
      } else {
        setUpdatedContactList(companyUserNames);
      }
    }
  }, [contactList, companyUserNames]);

  useEffect(() => {
    if (!show) {
      setSelectedServiceType(PlatformType.VRI);
    }
  }, [show]);

  const { jobFormFieldsList } = useFunctionalityJob({
    userId: dialUser?.id || "",
  });
  useEffect(() => {
    if (jobFormFieldsList) {
      setJobFormData(jobFormFieldsList);

      const selectedService = jobFormFieldsList?.isVideoEnabled
        ? PlatformType.VRI
        : jobFormFieldsList?.isAudioEnabled
          ? PlatformType.OPI
          : PlatformType.OnSite;
      setSelectedServiceType(selectedService);
    }
  }, [jobFormFieldsList]);

  const handleServiceTypeChange = (event: any) => { setSelectedServiceType(Number(event.target.value)); };
  const modalHideHandler = () => { props.handleShow(false); };
  const handleWarning = (form: ApiSchemaCreateJob) => {
    if (durationChecker()) return;
    if (
      hoursErrorMsg ||
      minutesErrorMsg ||
      customFieldValues?.length !==
      jobFormData?.bookingCustomFieldModels?.length
    )
      return;
    const { companyId, ...restOfTheForm } = form;
    form = {
      ...restOfTheForm,
      bookingCustomFields: customFieldValues,
      gender: form?.gender ? Number(form?.gender) : GenderType.Any,
      noOfInterpreter: Number(form?.noOfInterpreter),
      serviceType: Number(form?.serviceType),
    };
    if (hoursErrorMsg == "" && minutesErrorMsg == "") {
      onCreateJob(form, modalHideHandler)
        ?.then(() => {
          if (mutateUrl) {
            mutate(mutateUrl!);
          }
          if (mutateInGetAllForCalendar) {
            mutateInGetAllForCalendar();
          }
          // modalHideHandler()
        })
        .catch((err) => console.log(err));
    }
  };

  const [formState, setFormState] = useState<ApiSchemaCreateJob>();

  const onCreateJobHandler = (form: ApiSchemaCreateJob) => {
    if (form.startDate === 'Invalid date') {
      return;
    }
    form.startDate = moment(form.startDate).format('Y-MM-DD');
    if (lessThan24Hours == false) {
      handleWarning(form);
    }
    if (lessThan24Hours == true) {
      setFormState(form);
      setWarningModalShow(true);
    }
  };

  const durationChecker = () => {
    var hour = document?.getElementById("durationHours") as HTMLInputElement;
    var hourValue = hour.value ?? 0;
    var mints = document.getElementById("durationMinutes") as HTMLInputElement;
    var mintsValue = mints.value ?? 0;
    if (parseInt(hourValue) == 0 && parseInt(mintsValue) == 0) {
      setHoursErrorMsg("Duration must be greater than 0");
      setMinutesErrorMsg("Duration must be greater than 0");
      return true;
    } else {
      // setHoursErrorMsg('')
      // setMinutesErrorMsg('')
      return false;
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      dialogClassName="site-large-modal"
    >
      <form onSubmit={handleSubmit(onCreateJobHandler)} noValidate>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Create New Job</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row px-2 py-2">
            <div className={`col-12 col-sm-12 col-md-12 px-2 ${!havePlatformContractEnabled ? 'col-12' : 'col-lg-6 col-xl-6'}`}>
              <div className="component-card rounded mb-3">
                <AppointmentType
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  selectedServiceType={selectedServiceType}
                  setSelectedServiceType={setSelectedServiceType}
                  handleServiceTypeChange={handleServiceTypeChange}
                  setJobFormData={setJobFormData}
                  setCompanyId={setCompanyId}
                  setDefaultRequestedUser={setDefaultRequestedUser}
                  setSelectedUser={setSelectedUser}
                  contactList={updatedContactList}
                  dialUser={dialUser}
                  jobFormData={jobFormData}
                  defaultRequestedUser={defaultRequestedUser}
                  selectCompanyFirst={selectCompanyFirst}
                  setSelectedCompanyId={setSelectedCompanyId}
                  havePlatformContractEnabled={havePlatformContractEnabled}
                />
                {jobFormData?.isFaceToFaceEnabled &&
                  selectedServiceType === PlatformType.OnSite && (
                    <FaceToFaceForm
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      companyId={companyId}
                      selectedServiceType={selectedServiceType}
                      trigger={trigger}
                      selectedLocation={selectedLocation}
                      setSelectedLocation={setSelectedLocation}
                      setShowLocationModal={setShowLocationModal}
                      showJobCreateModal={props.show}
                    />
                  )}
              </div>
              {jobFormData?.bookingCustomFieldModels?.length ? (
                <JobCustomFieldsForm
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  customFields={jobFormData.bookingCustomFieldModels}
                  customFieldValues={customFieldValues}
                  setCustomFieldValues={setCustomFieldValues}
                />
              ) : (
                <></>
              )}
            </div>

            {havePlatformContractEnabled && (
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-2">
                <RequirementsForm
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  selectedServiceType={selectedServiceType}
                  isSkillEnabled={jobFormData?.isSkillEnabled}
                  isGenderEnabled={jobFormData?.isGenderEnabled}
                />

                <ScheduleForm
                  Controller={Controller}
                  setLessThan24Hours={setLessThan24Hours}
                  lessThan24Hours={lessThan24Hours}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  durationChecker={durationChecker}
                  minimumTimesByPlatform={jobFormData?.minimumTimesByPlatform!}
                  selectedServiceType={selectedServiceType}
                  hoursErrorMsg={hoursErrorMsg}
                  setHoursErrorMsg={setHoursErrorMsg}
                  minutesErrorMsg={minutesErrorMsg}
                  setMinutesErrorMsg={setMinutesErrorMsg}
                  control={control}
                />

                <AdditionalInfo
                  register={register}
                  setValue={setValue}
                  errors={errors}
                />
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="login-from-submit-btn btn btn-primary text-uppercase"
            // disabled={(hoursErrorMsg || minutesErrorMsg || customFieldValues?.length !== jobFormData?.bookingCustomFieldModels?.length) ? true : false}
            disabled={!canCreateBooking}
          >
            Create Booking
          </button>
          {/* {errMsg && <FormErrorMessage message={errMsg} />} */}
        </Modal.Footer>
      </form>
      {showLocationModal &&
        jobFormData?.isFaceToFaceEnabled &&
        selectedServiceType === PlatformType.OnSite && (
          <ModalLocationSaveModal
            show={showLocationModal}
            handleShow={setShowLocationModal}
            modalHeader="Add Location"
            companyId={companyId ?? ''}
            isFaceToFaceEnable={true}
            setSelectedLocation={setSelectedLocation}
          />
        )}
      {warningModalShow && (
        <JobCreateWarningModal
          show={warningModalShow}
          handleShow={setWarningModalShow}
          setWarningModalShow={setWarningModalShow}
          handleWarning={handleWarning}
          form={formState!}
        ></JobCreateWarningModal>
      )}
    </Modal>
  );
}
