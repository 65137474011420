import { StorageLocalBase } from "./StorageBase";
import { ApiSchemaProfileData, ApiSchemaMenuSettingsData } from "../../models";
import { OperatorStatusType } from "../../models/api/Operator";

class StorageAuthImpl extends StorageLocalBase {
  StorageNamespace = "auth";

  readonly TokenType = "Bearer";

  private readonly KeyAccessToken: string = "accessToken";
  private readonly KeyExpiration: string = "expiration";
  private readonly KeyProfileData: string = "profile";
  private readonly KeyMenuSettingsData: string = "menusetings";
  private readonly KeyTwilioDeviceToken: string = "twiliodevicetoken";
  private readonly KeySessionIdeData: string = "SessionId";
  private readonly KeyLocalStreamData: string = "localStream";
  private readonly KeyLocalScreenShareData: string = "screenTrack";

  get AccessToken(): string | undefined {
    return this.getString(this.KeyAccessToken);
  }

  set AccessToken(token: string | undefined) {
    this.setString(this.KeyAccessToken, token);
  }
  get TwilioToken(): string | undefined {
    return this.getString(this.KeyTwilioDeviceToken);
  }

  set TwilioToken(token: string | undefined) {
    this.setString(this.KeyTwilioDeviceToken, token);
  }
  get SessionId(): string | undefined {
    return this.getString(this.KeySessionIdeData);
  }

  set SessionId(SessionId: string | undefined) {
    this.setString(this.KeySessionIdeData, SessionId);
  }
  get LocalStream(): MediaStream | undefined {
    return this.getObject(this.KeyLocalStreamData);
  }

  set LocalStream(localStream: MediaStream | undefined) {
    this.setObject(this.KeyLocalStreamData, localStream);
  }

  get Expiration(): string | undefined {
    return this.getString(this.KeyExpiration);
  }

  set Expiration(expiration: string | undefined) {
    this.setString(this.KeyExpiration, expiration);
  }

  get ProfileData(): ApiSchemaProfileData | undefined {
    return this.getObject(this.KeyProfileData);
  }

  set ProfileData(data: ApiSchemaProfileData | undefined) {
    this.setObject(this.KeyProfileData, data);
  }
  get MenuSettingsData(): ApiSchemaMenuSettingsData | undefined {
    return this.getObject(this.KeyMenuSettingsData);
  }

  set MenuSettingsData(data: ApiSchemaMenuSettingsData | undefined) {
    this.setObject(this.KeyMenuSettingsData, data);
  }

  get ScreenTrack(): MediaStreamTrack {
    // return this.getObject(this.KeyLocalScreenShareData)
    return {} as MediaStreamTrack;
  }

  set ScreenTrack(data: MediaStreamTrack | undefined) {
    this.setObject(this.KeyLocalScreenShareData, data);
  }


  set activeStatus(status: OperatorStatusType) {
    const modifiedProfileData = this.ProfileData;
    if(!modifiedProfileData) {
      return;
    }
    modifiedProfileData.userStatus = status;
    this.ProfileData = modifiedProfileData;
  }

  set loginStatus(status: string) {
    this.setString('loginStatus', status);
  }

  get loginStatus() {
    return this.getString('loginStatus') || '';
  }

  clearStorage() {
    window.localStorage.clear();
  }

  Clear(): void {
    this.AccessToken = undefined;
    this.TwilioToken = undefined;
    this.Expiration = undefined;
    this.ProfileData = undefined;
    this.MenuSettingsData = undefined;
    this.SessionId = undefined;
    this.LocalStream = undefined;
    this.ScreenTrack = {} as MediaStreamTrack;
  }
}

export const StorageAuth = new StorageAuthImpl();
