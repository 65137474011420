import React from "react";
import { AssetSvg } from "../../../../assets";
import { Subscription } from "../../../../models";

type Props = {
  handleShow: (value: boolean) => void,
  data?: Subscription,
  editable: boolean;
}

export function CardLate(props: Props): JSX.Element {
  const { handleShow, data, editable } = props;

  return (

    <div className="component-card mb-3">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
        <div>Late Fee</div>
        {editable ? (
          <button className="btn btn-link px-0 text-site btn-sm text-uppercase" onClick={() => handleShow(true)}>
            <div className="btn svg-color" title="contractLateFeesEdit">
              <span>
                <AssetSvg.Edit />
              </span>
            </div>
          </button>
        ) : (<></>)
        }
      </div>
      <div className="component-card-body border">
        {
          data?.lateTimeMonthLimit && data.lateFeeRate ? (
            <>
              <div className="row flex-wrap mb-2">
                <div className="col-12">
                  <p className="mb-0 text-muted">
                    Charge missed payments for
                  </p>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <p className="mb-0 currency-symbol text-dark font-500">{data.lateTimeMonthLimit} months</p>
                  </div>
                </div>
                {/* <div className="col-5 col-sm-6 col-md-7 col-lg-7 col-xl-5">
                    <p className="mb-0 text-dark font-500">
                      Charge missed payments for
                    </p>
                  </div>
                  <div className="col-7 col-sm-6 col-md-5 col-lg-5 col-xl-7">
                    <p className="mb-0  d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-muted">{data.lateTimeMonthLimit} months</div>
                    </p>
                  </div> */}
              </div>
              <div className="row flex-wrap mb-2">
                <div className="col-12">
                  <p className="mb-0 text-muted">
                    Amount charged
                  </p>
                </div>
                <div className="col-12">
                  <p className="d-flex flex-wrap align-items-center">
                    <div className="mb-0 currency-symbol text-dark font-500">{data?.lateFeeRate?.toFixed(2)} %</div>
                  </p>
                </div>
                {/* <div className="col-5 col-sm-6 col-md-7 col-lg-7 col-xl-5">
                    <p className="mb-0 text-dark font-500">
                      Amount charged
                    </p>
                  </div>
                  <div className="col-7 col-sm-6 col-md-5 col-lg-5 col-xl-7">
                    <p className="mb-0  d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-muted">{data.lateFeeRate} %</div>
                    </p>
                  </div> */}
              </div>
            </>
          ) : null
        }
      </div>
    </div>

  )
}