import { AppConstants } from "../../../configs";
import { CallLogPagedResponseModel } from "../../../models/api/Call";
import { handleNumberToTwoDecimalPoint } from "../../../utils";

type Prop = {
  callLogData: CallLogPagedResponseModel;
  userRole: string;
};
export function CallLogInfoBox(prop: Prop) {
  const { callLogData, userRole } = prop;
  return (
    <div className="col-12 py-3">
      <div className="summary-box-wrapper d-flex">
        <div className="call-info-grid">
          <div className="call-info-grid-child">
            <div className="call-log-box total-call-box">
              <div className="call-log-image">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" className="icon-resize-lg">

                  <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
                    <circle fill="#7860a8" cx="13" cy="13" r="13" />
                    <path fill="#fff" d="M6.74,8.7a2.24,2.24,0,0,1,1.32-2,2.18,2.18,0,0,1,.86-.28,1.11,1.11,0,0,1,.87.28,6.05,6.05,0,0,1,1.09,1.23,11.47,11.47,0,0,1,.78,1.3,1.1,1.1,0,0,1-.39,1.46l-.24.18a.85.85,0,0,0-.32,1.09,4.79,4.79,0,0,0,.92,1.51,5.9,5.9,0,0,0,2,1.45.8.8,0,0,0,.76.06,1.49,1.49,0,0,0,.37-.38,1.78,1.78,0,0,1,.68-.57,1.21,1.21,0,0,1,1.11,0,10,10,0,0,1,1.07.67q.58.42,1.14.87a1.2,1.2,0,0,1,.37,1.56A3.11,3.11,0,0,1,17,18.91a1.78,1.78,0,0,1-1.18-.17,17.46,17.46,0,0,1-4.13-2.47,15.22,15.22,0,0,1-2.86-3A17.17,17.17,0,0,1,7,9.83,3,3,0,0,1,6.74,8.7Z" />
                  </g>
                  </g>
                </svg>
              </div>
              <div className="box-info">
                <p className="mb-1">Total Calls</p>
                <h2 className="mb-0 counter-control" title={`${callLogData.totalCalls ? callLogData.totalCalls : 0}`}>{callLogData.totalCalls ? callLogData.totalCalls : 0}</h2>
              </div>
            </div>
          </div>
          {[
            AppConstants.UserRoles.Admin,
            AppConstants.UserRoles.Finance,
            AppConstants.UserRoles.ResellerAdmin,
            AppConstants.UserRoles.ResellerFinance,
            AppConstants.UserRoles.CompanyAdmin,
            AppConstants.UserRoles.CompanyFinance,
            AppConstants.UserRoles.Operator,
          ].includes(userRole) ? (
            <div className="call-info-grid-child">
              <div className="call-log-box Total-Income">
                <div className="call-log-image">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" className="icon-resize-lg" >

                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_8" data-name="Layer 8">
                        <circle fill="#7860a8" cx="13.5" cy="13.5" r="13.5" />
                        <path fill="#efefef" d="M15.29,5.76c0,.55-.23.84-.61.86A.63.63,0,0,1,14,6c0-.43-.19-.63-.55-.71a1,1,0,0,0-1,.45.68.68,0,0,0,.37,1.06,2.38,2.38,0,0,0,.53.11,2.47,2.47,0,0,1,2.14,1.86,2.11,2.11,0,0,1-1.36,2.44.7.7,0,0,0-.41.35.92.92,0,0,1-.56.37c-.26.06-.46-.12-.6-.37a.77.77,0,0,0-.31-.31A2.39,2.39,0,0,1,11,9a.64.64,0,0,1,1.27,0l0,.24a1,1,0,0,0,.62.87A1.23,1.23,0,0,0,14,9.82a.8.8,0,0,0,.11-1.11,1.34,1.34,0,0,0-.9-.51,3.84,3.84,0,0,1-.9-.23,1.82,1.82,0,0,1-1.24-1.73,2.07,2.07,0,0,1,1.18-2c.16-.08.29-.15.33-.37a.56.56,0,0,1,.55-.44.66.66,0,0,1,.65.37,1,1,0,0,0,.33.33A1.75,1.75,0,0,1,15.29,5.76Z" />
                        <rect fill="#efefef" x="7.87" y="16.62" width="3.26" height="4.31" />
                        <rect fill="#efefef" x="12.33" y="13.77" width="3.26" height="7.16" />
                        <rect fill="#efefef" x="16.85" y="11.35" width="3.26" height="9.57" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="box-info">
                  <p className="mb-1">{[
                    AppConstants.UserRoles.CompanyAdmin,
                    AppConstants.UserRoles.CompanyFinance,
                  ].includes(userRole) ? "Total Cost" : "Total Income"}</p>
                  <h2 className={callLogData?.totalIncome < 0 ? `${"mb-0 counter-control text-danger"}` : "mb-0 counter-control"} title={`${handleNumberToTwoDecimalPoint(callLogData?.totalIncome ? callLogData.totalIncome : 0)}`}>{handleNumberToTwoDecimalPoint(callLogData?.totalIncome ? callLogData.totalIncome : 0)}</h2>
                </div>
              </div>
            </div>
          ) : (<></>)
          }
          {[
            AppConstants.UserRoles.Admin,
            AppConstants.UserRoles.Finance,
            AppConstants.UserRoles.ResellerAdmin,
            AppConstants.UserRoles.ResellerFinance,
          ].includes(userRole) ? (
            <div className="call-info-grid-child">
              <div className="call-log-box Total-operator">
                <div className="call-log-image">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.79 27.04" className="icon-resize-sm">

                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8"  >
                      <path fill="#7860a8" d="M23.78,13.49a2.28,2.28,0,0,0-1.35-2.1.38.38,0,0,1-.24-.4,10.29,10.29,0,0,0-.74-4.49A10.08,10.08,0,0,0,12.4,0,9.73,9.73,0,0,0,5,2.71,10,10,0,0,0,1.65,9.48c0,.6,0,1.2,0,1.77A2.38,2.38,0,0,0,0,13.43c0,1.11,0,2.22,0,3.33a2.27,2.27,0,0,0,1,1.83,2.37,2.37,0,0,0,1.45.47c.78,0,1.55,0,2.35,0H6.34V11.13H3.21A8.77,8.77,0,0,1,7.46,2.81a8.57,8.57,0,0,1,9.63.51,8.82,8.82,0,0,1,3.52,7.81H17.46v7.93h3.15v2.32a2.38,2.38,0,0,1-2.49,2.49c-1.23,0-2.45,0-3.67,0a.39.39,0,0,1-.43-.27,2.36,2.36,0,0,0-2.34-1.32A2.41,2.41,0,0,0,9.61,24a2.27,2.27,0,0,0,.88,2.52,5.66,5.66,0,0,0,1.09.49h.69l.14-.05a2.36,2.36,0,0,0,1.64-1.34.3.3,0,0,1,.33-.2c1.28,0,2.56,0,3.83,0a4,4,0,0,0,4-4c0-.77,0-1.53,0-2.3a.3.3,0,0,1,.19-.32,2.29,2.29,0,0,0,1.4-2.15C23.8,15.62,23.8,14.56,23.78,13.49Z" />
                    </g></g></svg>


                </div>
                <div className="box-info">
                  <p className="mb-1">Total Operator Cost</p>
                  <h2 className={callLogData?.totalOperatorCost < 0 ? `${"mb-0 counter-control text-danger"}` : "mb-0 counter-control"} title={`${handleNumberToTwoDecimalPoint(callLogData.totalOperatorCost ? callLogData.totalOperatorCost : 0)}`}>{handleNumberToTwoDecimalPoint(callLogData.totalOperatorCost ? callLogData.totalOperatorCost : 0)}</h2>
                </div>
              </div>
            </div>
          ) : (<></>)}
          {[
            AppConstants.UserRoles.Admin,
            AppConstants.UserRoles.Finance,
            AppConstants.UserRoles.ResellerAdmin,
            AppConstants.UserRoles.ResellerFinance,
            AppConstants.UserRoles.CompanyAdmin,
            AppConstants.UserRoles.CompanyFinance,
          ].includes(userRole) ? (
            <div className="call-info-grid-child">
              <div className="call-log-box Total-conection">
                <div className="call-log-image">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">

                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_8" data-name="Layer 8">
                        <path fill="#7860a8" d="M12.71,24H11.3l-1-.11A11.73,11.73,0,0,1,4.1,21a11.77,11.77,0,0,1-3.92-7C.11,13.61.06,13.15,0,12.7V11.29c0-.31.07-.62.11-.92A11.54,11.54,0,0,1,2.93,4.15,11.76,11.76,0,0,1,13.21.05,11.14,11.14,0,0,1,19.47,2.6a11.72,11.72,0,0,1,4.32,11.64,11.33,11.33,0,0,1-3.24,6.19,11.78,11.78,0,0,1-6.47,3.39C13.63,23.89,13.17,23.94,12.71,24ZM7.25,7.47a15.82,15.82,0,0,0-.47,3.81h4.51V7.75Zm5.47,3.81h4.53a15.81,15.81,0,0,0-.49-3.78l-4,.26Zm-7.45,0,.51-4L3,6.58c-.06,0-.17,0-.21.12A9.74,9.74,0,0,0,1.85,9a9.9,9.9,0,0,0-.41,2.31Zm17.31,1.43H18.74l-.51,4,2.92.68A10.29,10.29,0,0,0,22.58,12.71Zm0-1.43a10.54,10.54,0,0,0-1.42-4.64l-2.91.65.5,4Zm-5.31,1.43H12.73v3.46l4,.27A18.41,18.41,0,0,0,17.27,12.71Zm-10.52,0a18.94,18.94,0,0,0,.49,3.71l4-.26V12.71Zm-1,3.93-.5-3.93H1.44a10.29,10.29,0,0,0,1.34,4.48.22.22,0,0,0,.22.08c.27-.06.54-.15.81-.21Zm6.94-10.3,3.7-.23c-.27-.6-.5-1.16-.76-1.7A6.79,6.79,0,0,0,14,2.17a3.26,3.26,0,0,0-1.23-.66ZM11.29,22.4V17.58l-3.63.22a9.07,9.07,0,0,0,2.08,3.64A3.66,3.66,0,0,0,11.29,22.4ZM7.63,6.09l3.66.24V1.46C9.22,2.34,8.4,4.15,7.63,6.09Zm8.74,11.73-3.65-.23V22.4A3.29,3.29,0,0,0,14,21.7,8.91,8.91,0,0,0,16.37,17.82ZM6.21,18l-2.54.54a10.32,10.32,0,0,0,4.49,3.31A13.06,13.06,0,0,1,6.21,18Zm9.65,3.85a10.36,10.36,0,0,0,4.45-3.26L17.79,18A12.91,12.91,0,0,1,15.86,21.84Zm.06-19.68a13,13,0,0,1,1.89,3.77l2.5-.53A10.49,10.49,0,0,0,15.92,2.16ZM6.21,5.88c.18-.47.33-.9.52-1.32a13.35,13.35,0,0,1,.64-1.25c.22-.39.48-.76.75-1.18A10.75,10.75,0,0,0,3.76,5.34Z" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="box-info">
                  <p className="mb-1">Total Connection Fee</p>
                  <h2 className={callLogData?.totalConnectionFee < 0 ? `${"mb-0 counter-control text-danger"}` : "mb-0 counter-control"} title={`${handleNumberToTwoDecimalPoint(callLogData.totalConnectionFee ? callLogData.totalConnectionFee : 0)}`}>{handleNumberToTwoDecimalPoint(callLogData.totalConnectionFee ? callLogData.totalConnectionFee : 0)}</h2>
                </div>
              </div>
            </div>
          ) : <></>}
          {[
            AppConstants.UserRoles.Admin,
            AppConstants.UserRoles.Finance,
            AppConstants.UserRoles.ResellerAdmin,
            AppConstants.UserRoles.ResellerFinance,
            AppConstants.UserRoles.CompanyAdmin,
            AppConstants.UserRoles.CompanyFinance,
          ].includes(userRole) ? (
            <div className="call-info-grid-child">
              <div className="call-log-box Total-technology">
                <div className="call-log-image">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">

                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_8" data-name="Layer 8">
                        <path fill="#7860a8" d="M3.14,18.68c-.35-.88-.67-1.72-1-2.56A.4.4,0,0,0,1.85,16c-.34,0-.69,0-1,0-.55,0-.82-.27-.82-.81V10.88c0-.57.26-.82.84-.83.4,0,.9.13,1.18-.06s.29-.69.44-1.05c.22-.53.46-1,.73-1.62-.27-.26-.57-.54-.87-.84a.73.73,0,0,1,0-1.17q1.49-1.51,3-3a.72.72,0,0,1,1.15,0c.25.24.48.5.74.72a.4.4,0,0,0,.35.06c.76-.3,1.51-.63,2.28-.92a.3.3,0,0,0,.22-.34c0-.34,0-.69,0-1A.71.71,0,0,1,10.83,0h4.32a.72.72,0,0,1,.79.8c0,.35,0,.69,0,1a.37.37,0,0,0,.17.29c.83.34,1.67.65,2.61,1,.22-.23.49-.54.78-.84a.73.73,0,0,1,1.18,0c1,1,2,2,3,3a.75.75,0,0,1,0,1.21c-.23.24-.48.46-.69.71a.43.43,0,0,0-.06.35c.3.76.62,1.52.94,2.27a.41.41,0,0,0,.29.21c.36,0,.72,0,1.07,0a.71.71,0,0,1,.79.76c0,1.46,0,2.91,0,4.36a.7.7,0,0,1-.76.75c-.37,0-.74,0-1.1,0a.34.34,0,0,0-.27.19c-.34.82-.66,1.65-1,2.57.23.22.53.5.82.79a.74.74,0,0,1,0,1.21c-1,1-2,2-3,3a.72.72,0,0,1-1.17,0c-.3-.3-.57-.61-.79-.84-.93.36-1.77.68-2.61,1a.36.36,0,0,0-.17.28c0,.36,0,.72,0,1.07a.72.72,0,0,1-.77.79H10.81a.7.7,0,0,1-.75-.76c0-.35,0-.71,0-1.07,0-.18-.05-.26-.23-.33-.82-.3-1.64-.63-2.55-1-.21.22-.49.53-.78.82a.74.74,0,0,1-1.21,0c-1-1-2-2-3-3a.74.74,0,0,1,0-1.18c.28-.28.56-.55.83-.83C3.16,18.66,3.16,18.64,3.14,18.68ZM22.25,20c-.26-.23-.54-.47-.8-.73a.71.71,0,0,1-.12-1,10.52,10.52,0,0,0,1.28-3.08c.13-.54.35-.7.91-.7h1v-3h-1a.77.77,0,0,1-.89-.69,10.26,10.26,0,0,0-1.28-3.08.73.73,0,0,1,.13-1L22.2,6,20,3.79c-.2.22-.42.47-.66.7a.76.76,0,0,1-1.14.14,10.12,10.12,0,0,0-3-1.24c-.58-.14-.74-.35-.74-1v-1h-3v1a.75.75,0,0,1-.67.86,10.26,10.26,0,0,0-3.11,1.3.73.73,0,0,1-1-.13C6.43,4.28,6.2,4,6,3.74L3.78,6c.23.22.49.45.73.69a.76.76,0,0,1,.14,1.12,10.23,10.23,0,0,0-1.26,3c-.13.55-.35.72-.93.72h-1v3h1c.56,0,.78.16.91.7a10.22,10.22,0,0,0,1.27,3.05.74.74,0,0,1-.14,1.08c-.25.25-.53.48-.79.71l2.18,2.15,0,0,.71-.7a.74.74,0,0,1,1.09-.13,10.25,10.25,0,0,0,3,1.26c.53.12.7.36.71.91v1h3v-1c0-.56.17-.78.71-.91a10.22,10.22,0,0,0,3.05-1.27.72.72,0,0,1,1,.12c.26.25.5.54.73.79Z" />
                        <path fill="#7860a8" d="M18.92,13A5.92,5.92,0,1,1,13,7.08,5.95,5.95,0,0,1,18.92,13ZM13,17.48A4.48,4.48,0,1,0,8.52,13,4.52,4.52,0,0,0,13,17.48Z" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="box-info">
                  <p className="mb-1">Total Technology Fee</p>
                  <h2 className={callLogData?.totalTechnologyFee < 0 ? `${"mb-0 counter-control text-danger"}` : "mb-0 counter-control"} title={`${handleNumberToTwoDecimalPoint(callLogData.totalTechnologyFee ? callLogData.totalTechnologyFee : 0)}`}>{handleNumberToTwoDecimalPoint(callLogData.totalTechnologyFee ? callLogData.totalTechnologyFee : 0)}</h2>
                </div>
              </div>
            </div>
          ) : (<></>)}
        </div>
      </div>
      {/* <div className="row summary-box-wrapper d-none d-sm-none d-md-flex d-lg-flex d-xl-flex mb-4">
          
          <InfoBox data={callLogInfoBoxData} />
        </div> */}
    </div>
  );
}
