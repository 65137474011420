import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import LoadingBar from 'react-top-loading-bar';
import { AdminPageTemplate } from '../..';
import { AssetSvg } from '../../../assets';
import { PaginationSettings } from '../../../configs';
import { ApiSchemaReasonRequestModel } from '../../../models/api/CompanyCancellationReason';
import { useFunctionalityPagedReason, useFunctionalityReason } from '../../../pages/hooks/functionalityReason';
import { ModalConfirmDelete, ModalReasonSave } from '../../modals/common';
type Props = {
	companyId: string
}
export function ReasonTab(props: Props): JSX.Element {
	const { companyId } = props;
	const [loaderProgress, setLoaderProgress] = useState(0);
	const [createModalShow, setCreateModalShow] = React.useState(false);
	const [deleteModalShow, setDeleteModalShow] = React.useState(false);
	const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
	const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
	const [searchText, setSearchText] = useState<string>("");
	const [reasonId, setReasonId] = useState("");
	const [reason, setReason] = useState("");
	const [hasPagingData, setHasPagingData] = useState<boolean>(false);
	const [modalHeader, setModalHeader] = useState("Add Cancellation Reason");
	const { onCreateCancellationReason, onUpdateCancellationReason, onDeleteCancellationReason } = useFunctionalityReason({ companyId: companyId });
	const { pagedReasonList } = useFunctionalityPagedReason({
		companyId: companyId,
		pageSize: pageSize,
		pageNo: pageIndex,
		searchText: searchText?.trimStart(),
		setLoaderProgress
	});

	useEffect(() => {
		if (pagedReasonList?.length > 0) {
			setHasPagingData(true);
		} else {
			setHasPagingData(false);
		}
	}, [pagedReasonList]);

	const handleSearch = (e: any) => {
		ReactDOM.unstable_batchedUpdates(() => {
			setSearchText(e.target.value);
			setPageIndex(1);
		});
	};
	const onChangePageIndex = (index: number) => {
		setPageIndex(index);
	};
	const onChangePageSize = (size: number) => {
		setPageSize(size);
	};

	const onSubmitHandler = (form: ApiSchemaReasonRequestModel) => {

		if (reasonId === "") {
			onCreateCancellationReason(form)
				.then(() => {
					setCreateModalShow(false);
					resetReasonData();
				})
				.catch((e) => console.error(e));
			resetReasonData();
		}
		else {
			onUpdateCancellationReason(reasonId, form)
				.then(() => {
					setCreateModalShow(false);
					resetReasonData();
				})
				.catch((e) => console.error(e));
			resetReasonData();
		}
	};

	const onDeleteHandler = () => {
		onDeleteCancellationReason(reasonId)
			.then(() => {
				setDeleteModalShow(false);
				resetReasonData();
			})
			.catch((e) => console.error(e));
		resetReasonData();
	};

	const resetReasonData = () => {
		setReasonId("");
		setReason("");
		setModalHeader("Add Cancellation Reason");
	}

	const locationTableColumns = [
		{
			name: "Reasons for Cancellation",
			selector: (row: any) => row.reason,
		},
		{
			name: "Actions",
			allowOverflow: true,
			cell: (row: any) => {
				return (
					<>
						<button className="btn btn-link svg-color" onClick={() => {
							setCreateModalShow(true);
							setModalHeader('Edit Cancellation Reason');
							setReasonId(row.id);
							setReason(row.reason)
						}}>
							<span >
								<AssetSvg.Edit />
							</span>
						</button>
						<button className="btn btn-link svg-color" onClick={() => {
							setReasonId(row.id);
							setDeleteModalShow(true)
						}}>
							<span >
								<AssetSvg.Delete />
							</span>
						</button>
					</>
				);
			},
		},
	];

	return (
		<>
			<LoadingBar
				progress={loaderProgress}
				onLoaderFinished={() => setLoaderProgress(0)}
			/>
			<div className="row flex-wrap  pb-sm-0 mb-3">
				<div className="col">
					<AdminPageTemplate
						loaderProgress={loaderProgress}
						pageTitle="Reasons"
						modalControlBtnLabel="Add Cancellation Reason"
						tableHeaders={locationTableColumns}
						tableData={pagedReasonList}
						setCreateModalShow={setCreateModalShow}
						showExportOptions={false}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
						pageSize={pageSize}
						onChangePageIndex={onChangePageIndex}
						onChangePageSize={onChangePageSize}
						handleSearch={handleSearch}
						hasPagingData={hasPagingData}
						setHasPagingData={setHasPagingData}
					/>
				</div>
			</div>
			<ModalReasonSave
				show={createModalShow}
				handleShow={setCreateModalShow}
				header={modalHeader}
				onSubmitHandler={onSubmitHandler}
				reason={reason}
				resetReasonData={resetReasonData}
			/>
			<ModalConfirmDelete show={deleteModalShow}
				handleShow={setDeleteModalShow}
				deleteHandler={onDeleteHandler}
			/>
		</>
	);
}

