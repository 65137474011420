import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CallStatusType } from '../../../configs';
import { VideoCallRouteParam } from './../../../models/videoCall';

export type TSliceVideoCall = {
  CallStatus?: VideoCallRouteParam;
  hasOperator?: boolean;
  callSummary: {
    roomId: string;
  };
  tracks: MediaStreamTrack[] | null;
};

function InitSliceCall(): TSliceVideoCall {
  return {
    CallStatus: {
      prev: CallStatusType.Zero,
      current: CallStatusType.Zero,
    },
    hasOperator: false,
    callSummary: {
      roomId: '',
    },
    tracks: null,
  };
}

const sliceCall = createSlice({
  name: 'sliceVideoCall',
  initialState: InitSliceCall(),
  reducers: {
    SetCallStatus: (
      state,
      action: PayloadAction<VideoCallRouteParam>
    ): TSliceVideoCall => ({
      ...state,
      CallStatus: action.payload,
    }),
    setOperatorStatus: (state, action): TSliceVideoCall => {
      return {
        ...state,
        hasOperator: action.payload,
      };
    },
    setCallSummaryInfo: (state, action): TSliceVideoCall => {
      return {
        ...state,
        callSummary: action.payload,
      };
    },
    setTracks: (state, action: PayloadAction<TSliceVideoCall['tracks']>) => {
      if (Array.isArray(action.payload)) {
        state.tracks = state.tracks || [];
        state.tracks = [...state.tracks, ...action.payload];
      } else {
        state.tracks = action.payload;
      }
    },
    resetData: () => {
      return InitSliceCall();
    },
  },
});
export const ReducerVideoCall = sliceCall.reducer;
export const ActionVideoCall = sliceCall.actions;
