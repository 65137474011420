import { ApiSchemaCreateLanguagePack, ApiSchemaLanguagePackData } from ".";

 
export interface ApiSchemaInflowSettings {
    enabled: boolean;
    vriPack?: ApiSchemaLanguagePackData;
    opiPack?: ApiSchemaLanguagePackData;
}
export interface ApiSchemaCompanyLanguagePackData {
    platformType: PlatformType;
    languagePack: ApiSchemaLanguagePackData;
}
export enum PlatformType {
    All=0,
    VRI = 1,
    OPI,
    OnSite
} 
export interface CallCenterInflowRequestModel {
    callCenterId: string;
    isEnabled: boolean;
}
export interface CallCenterInflowLanguagePackRequestModel {
    platformType: PlatformType;
    callCenterId: string;
    languagePackRequestModel: ApiSchemaCreateLanguagePack;
}
