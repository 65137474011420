interface IAppSession {
  log: string[];
}

export const SessionStorageUtils = {
  baseKey: 'app',

  set app(data: IAppSession) {
    window.sessionStorage.setItem(`${this.baseKey}`, JSON.stringify(data));
  },
  get app() {
    const data = window.sessionStorage.getItem(`${this.baseKey}`);
    return JSON.parse(data || '{}') as IAppSession;
  },
  set log(log: string[]) {
    const data = this.app;
    if (!Array.isArray(data.log)) {
      data.log = [];
    }
    data.log.push(...log);
    this.app = data;
  },
  get log(): string[] {
    const data = this.app;
    return data.log;
  },
};
