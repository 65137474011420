import { useCallback } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { PlatformApi, topLoaderProgress } from "../../configs";
import { AppConstants } from "../../configs/appConstants";
import { AppRouteUi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core";
import {
  ApiSchemaAccountStatus,
  ApiSchemaChangeCustomFiledName,
  ApiSchemaCompanySettings,
} from "../../models/api/companySettings";

export function useFunctionalityCompanySettings() {
  const companyId = AppRouteUi.Company.Profile.useParam();

  const onChangeCallSettingStatus = useCallback(async (data: ApiSchemaCompanySettings, setLoaderProgress: any) => {
    const changeCallSettingStatusUrl = PlatformApi.CompanySettings.ChangeCallSettingStatus(data.id, data?.isEnabled);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.post(changeCallSettingStatusUrl);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Call Settings " + res?.data?.message);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("CallSettings Status Changed Failed");
      return Promise.reject();
    }
  }, []);

  const onChangeCustomSettingStatus = useCallback(async (data: ApiSchemaCompanySettings, setLoaderProgress: any, locationFound?: boolean, departmentFound?: boolean) => {
    const changeCustomSettingStatusUrl = PlatformApi.CompanySettings.ChangeCustomSettingStatus(data.id, data?.isEnabled);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.post(changeCustomSettingStatusUrl);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Custom Settings " + res?.data?.message);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Company CustomSettingStatus Changed Failed");
      return Promise.reject();
    }
  }, []);
  const resellerId = AppRouteUi.Reseller.Profile.useParam();
  const onChangCallCenterSetting = useCallback(
    (data: ApiSchemaCompanySettings) => {
      AxiosAuth.post(
        PlatformApi.CompanySettings.ChangeCallCenterSettingStatus(
          data.id,
          data?.isEnabled
        )
      )
        .then((res) => {
          if (res?.status === AppConstants.Api.StatusOK) {
            toast.success("Call Settings " + res?.data?.message);
            mutate(PlatformApi.Reseller.GetById(resellerId));
          }
        })
        .catch((e) => {
          toast.error("Call inflow cannot be enabled while call center is disabled");
          console.log(e);
        });
    },
    []
  );
  const onChangeAccountStatus = useCallback(async (data: ApiSchemaAccountStatus, setAccStatus: any, setLoaderProgress: any) => {
    const changeAccountStatusUrl = PlatformApi.CompanySettings.ChangeAccountStatus(data.companyId, data?.isActive);

    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.post(changeAccountStatusUrl);

      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success("Account Status " + res?.data?.message);
        return Promise.resolve();
      }
    } catch (error) {
      toast.error("Account Status Changed Failed");
      return Promise.reject();
    }
  }, []);

  const onChangeCustomFieldName = useCallback(
    (data: ApiSchemaChangeCustomFiledName, updateStautus: any, setUpdateStatus: any) => {
      AxiosAuth.post(
        PlatformApi.CompanySettings.ChangeCustomFieldName(data.customSettingId),
        {
          name: data.name,
        }
      )
        .then((res) => {
          if (res?.status === AppConstants.Api.StatusOK) {
            toast.success("Custom Fields " + res?.data?.message);
            mutate(PlatformApi.Company.GetById(companyId));
            setUpdateStatus(!updateStautus)
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
          setUpdateStatus(!updateStautus)
        });
    },
    []
  );

  return {
    onChangeCallSettingStatus,
    onChangeCustomSettingStatus,
    onChangeAccountStatus,
    onChangeCustomFieldName,
    onChangCallCenterSetting,
  };
}
