class UtilsJQueryImpl {
  Ladda(selector: string) {
    const btn = ($(selector) as any).ladda();
    const start = btn ? () => btn.ladda("start") : undefined;
    const stop = btn ? () => btn.ladda("stop") : undefined;
    return {
      start,
      stop,
    };
  }
}

export const UtilsJQuery = new UtilsJQueryImpl();
