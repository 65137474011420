import React from 'react';
import { ChannelContext } from '../../core';
import { ChatHome, ChatUI } from './Chat';
import { LoadingOverlay } from '../../components';

type Props = {
  chatType: number;
  setChatType: any;
};
export function SidePanel(props: Props): JSX.Element {
  const { chatType } = props;
  const { selectedChannel, isBusy } = React.useContext(ChannelContext);

  return (
    <>
      <div
        className="site-chat-panel-visible bg-white collapse"
        id="site-chat-panel"
      >
        <div className="site-chat-inner">
          <div className="container-fluid d-block h-100">
            <div className="row bg-white h-100">
              <div className="w-100 h-100">
                <LoadingOverlay
                  className="site-loader chat-home-loader"
                  active={isBusy && chatType > 3}
                  animation="border"
                >
                  <>
                    {selectedChannel === undefined && chatType < 4 ? (
                      <ChatHome />
                    ) : null}
                    {selectedChannel ? <ChatUI /> : null}
                  </>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
