import { logger } from './logger';

async function getStatus(type: {
  audio?: boolean;
  video?: boolean;
}): Promise<boolean> {
  let permission = true;
  try {
    const stream = await navigator.mediaDevices.getUserMedia(type);
    stream.getTracks().forEach((track) => track.stop());
  } catch (error) {
    permission = false;
  }
  const logText = (() => {
    if (type.audio && type.video) {
      return 'audio & video';
    } else if (type.audio) {
      return 'audio';
    } else {
      return 'video';
    }
  })();
  logger({
    message: `Media access for ${logText} is ${permission}`,
  });
  return permission;
}

export const MediaPermission = { getStatus };
