import React, {useEffect, useState} from "react";
import {IvrCustomField, IvrCustomInputType} from "../../../models";
import Select from "react-select";
import {OptionMapper} from "../../../utils";

type Props = {
    customField: IvrCustomField;
    operatorInput: number;
    handleTotalEnabledCustomFields: any;
}

const keyList = [
    {
        id: 1,
        name: '#'
    },
    {
        id: 2,
        name: '*'
    }
];

export default function IvrCustomInputMappingData(props: Props) {
    const { customField, handleTotalEnabledCustomFields } = props;

    const [customFieldType, setCustomFieldType] = useState(props.customField?.id);
    const [toggled, setToogled] = useState(false);

    const keyListOptions = OptionMapper.mapperFunc(keyList);

    const handleToogle = (customField: IvrCustomField) => {
        setToogled(!toggled);
        handleTotalEnabledCustomFields(customField);
    }

    return (
        <>
            <div className="pt-3 pb-3 pb-sm-0" >
                <div className="component-card mb-1">
                    <div className="component-card-header d-flex  justify-content-between  border-top border-right border-left collapsed flex-wrap">
                        <div className="toggle-button-label"> {customField.name} </div>
                        <button
                            type="button"
                            className="btn btn-toggle on-off-btn"
                            data-toggle="button"
                            aria-pressed="false"
                            onClick={()=>handleToogle(customField)}
                        >
                            <div className="handle"></div>
                        </button>
                    </div>

                    {
                        toggled && <>
                            <div className="component-card-body border">

                                <div className="form-group row ">
                                    <label
                                        htmlFor=""
                                        className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                                    >
                                        Input Type:
                                    </label>

                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-4 mb-sm-4 mb-md-0">
                                        <div className="row mx-0">
                                            <div className="form-check form-check-inline col">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="type"
                                                    disabled={true}
                                                    onChange={(event) => {
                                                        // setIvrInputType(IvrCustomInputType.IVRInput)
                                                        // setOperatorInputType(IvrCustomInputType.IVRInput)
                                                        // handleIvrInputType(event)
                                                    }}
                                                />
                                                <label htmlFor="postPaidradio" className="form-check-label">
                                                    IVR Input
                                                </label>
                                            </div>

                                            <div className="form-check form-check-inline col">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    name="type"
                                                    checked={true}
                                                    onChange={(event) => {
                                                        // setIvrInputType(IvrCustomInputType.OperatorInput)
                                                        // setOperatorInputType(IvrCustomInputType.OperatorInput)
                                                        // handleIvrInputType(event)
                                                    }}
                                                />
                                                <label htmlFor="prePaidradio" className="form-check-label">
                                                    Operator Input
                                                </label>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-12 mb-2">
                                        <p className="font-sz-12 mb-0">
                                            User can only enter numeric values using telephone dial pad
                                        </p>
                                    </div>

                                    <label
                                        htmlFor=""
                                        className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label"
                                    >
                                        Enter Key
                                    </label>
                                    <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                                        <input
                                            className="d-none"
                                            name="key"
                                        />
                                        <Select
                                            placeholder="Select Key"
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            options={keyListOptions}
                                            defaultValue={customField?.enterKey ? keyListOptions?.find(x => x.label === customField?.enterKey) : null}
                                            isDisabled={true}
                                        />
                                        <p className="font-sz-12 mb-0 mt-1">
                                            User inputs are terminated by the 'Enter key', which is excluded from accepted input
                                        </p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="" className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required">
                                        Min
                                    </label>

                                    <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                                        <input
                                            name="min"
                                            type="text"
                                            className="form-control"
                                            defaultValue={customField?.min}
                                            disabled={true}
                                        />
                                        <p className="font-sz-12 mb-0 mt-1">
                                            Minimum digits required for a valid inputs
                                        </p>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="" className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required">
                                        Max
                                    </label>

                                    <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                                        <input
                                            name="max"
                                            type="text"
                                            className="form-control"
                                            defaultValue={customField?.max}
                                            disabled={true}
                                        />
                                        <p className="font-sz-12 mb-0 mt-1">
                                            Maximum digits allowed for a valid inputs.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}
