import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import LoadingBar from 'react-top-loading-bar';
import { AdminPageTemplate, ColumnToolTip } from '../..';
import { AppRouteUi, PaginationSettings } from '../../../configs';
import { ApiSchemaCompanyByIdData, ApiSchemaLocationData, CompanySettings, CustomSetting } from '../../../models';
import { useFunctionalityCompanySettingsDetails, useFunctionalityLocation, useFunctionalityPagedLocation } from '../../../pages/hooks';
import { ModalConfirmDelete, ModalLocationSaveModal } from '../../modals';
import { toast } from "react-toastify";
import { AssetSvg } from '../../../assets';
type Props = {
	editable: boolean
}
export function LocationTab(props: Props): JSX.Element {
	const { editable } = props
	const companyId = AppRouteUi.Company.Profile.useParam();
	const [loaderProgress, setLoaderProgress] = useState(0);
	const [locationId, setLocationId] = useState("");
	const { onDeleteLocation } = useFunctionalityLocation({ companyId: companyId });
	const [createModalShow, setCreateModalShow] = React.useState(false);
	const [deleteModalShow, setDeleteModalShow] = React.useState(false);
	const [dataToEdit, setDataToEdit] = React.useState<ApiSchemaLocationData | undefined>(undefined);
	const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
	const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
	const [searchText, setSearchText] = useState("");
	const [hasPagingData, setHasPagingData] = useState<boolean>(false);
	const [isEditLocation, setIsEditLocation] = useState<boolean>(false);

	const { optionMappedLocationList } = useFunctionalityLocation({ companyId: AppRouteUi.Company.Profile.useParam() || "", shouldLocationGetAllInvoke: deleteModalShow });

	const [companySettingsDetails, setCompanySettingsDetails] = useState<ApiSchemaCompanyByIdData>();
	const { onCompanySettingsDetails } = useFunctionalityCompanySettingsDetails({
		companyId: AppRouteUi.Company.Profile.useParam(),
		setLoaderProgress: setLoaderProgress
	});

	useEffect(() => {
		onCompanySettingsDetails()?.then((res) => {
			setCompanySettingsDetails(res?.data);
		});
	}, []);

	const companySettings: CompanySettings = companySettingsDetails?.companySettings || {};

	const handleSearch = (e: any) => {
		ReactDOM.unstable_batchedUpdates(() => {
			setSearchText(e.target.value);
			setPageIndex(1);
		});
	};
	const onChangePageIndex = (index: number) => {
		setPageIndex(index);
	};
	const onChangePageSize = (size: number) => {
		setPageSize(size);
	};
	const { pagedLocationList, pagedLocationListCount } = useFunctionalityPagedLocation({
		companyId: companyId,
		pageSize: pageSize,
		pageNo: pageIndex,
		searchText: searchText?.trimStart(),
		setLoaderProgress
	});

	useEffect(() => {
		if (pagedLocationList?.length > 0) {
			setHasPagingData(true);
		} else {
			setHasPagingData(false);
		}
	}, [pagedLocationList]);

	const onDeleteHandler = () => {
		onDeleteLocation(locationId)
			.then(() => {
				setDeleteModalShow(false);
			})
			.catch((e) => console.error(e));
	};

	const handleDeleteLocation = () => {
		const locationCustomField = companySettings?.customFields?.find((customSettingItem: CustomSetting) => customSettingItem?.code === "Location");
		if (locationCustomField?.isEnabled && optionMappedLocationList.length <= 1) {
			toast.error("Can't Delete because Location Custom Settings is Enable");
			return;
		} else {
			setDeleteModalShow(true);
		}
	}

	const locationTableColumns = [
		{
			name: "Location Name",
			selector: (row: ApiSchemaLocationData) => row.name,
		},
		{
			name: "Phone Number",
			allowOverflow: true,
			selector: (row: ApiSchemaLocationData) => row.address.phone,
		},
		{
			name: "Address",
			width: "350px",
			selector: (row: ApiSchemaLocationData) => {
				return <ColumnToolTip data={row?.address ? (row?.address.description + ", " +
					row?.address.city + ", " +
					row?.address.stateName + ", " +
					row?.address.zipCode + ", " +
					row?.address.countryName) : null} />;
			},
		},
	];
	if (editable) {
		locationTableColumns.push({
			name: "Actions",
			allowOverflow: true,
			selector: (row: ApiSchemaLocationData) => {
				return (
					<>
						<div className="btn">
							<div className="btn svg-color" title="locationEdit" onClick={() => {
								setDataToEdit(row);
								setCreateModalShow?.(true);
								setIsEditLocation(true);
							}}>
								<span >
									<AssetSvg.Edit />
								</span>
							</div>

						</div>
						<div className="btn">
							<div className="btn svg-color" title="locationDelete" onClick={() => {
								setLocationId(row.id);
								handleDeleteLocation();
							}}>
								<span >
									<AssetSvg.Delete />
								</span>
							</div>

						</div>
					</>
				);
			},
		})
	}
	return (
		<>
			<LoadingBar
				progress={loaderProgress}
				onLoaderFinished={() => setLoaderProgress(0)}
			/>
			<div className="row flex-wrap mb-3">
				<div className="col">
					<AdminPageTemplate
						loaderProgress={loaderProgress}
						pageTitle="Location"
						modalControlBtnLabel={editable ? "Add a Location" : ""}
						tableHeaders={locationTableColumns}
						tableData={pagedLocationList}
						searchText={searchText}
						handleSearch={handleSearch}
						pageIndex={pageIndex}
						setPageIndex={setPageIndex}
						pageSize={pageSize}
						onChangePageIndex={onChangePageIndex}
						onChangePageSize={onChangePageSize}
						setCreateModalShow={setCreateModalShow}
						showExportOptions={true}
						hasPagingData={hasPagingData}
						setHasPagingData={setHasPagingData}
						isHideAddButton
					/>
				</div>
			</div>
			{
				createModalShow && <ModalLocationSaveModal
					show={createModalShow}
					handleShow={setCreateModalShow}
					modalHeader={isEditLocation ? "Edit Location" : "Add Location"}
					companyId={companyId}
					dataToEdit={dataToEdit}
					setDataToEdit={setDataToEdit}
					setIsEditLocation={setIsEditLocation}
				/>
			}
			<ModalConfirmDelete show={deleteModalShow}
				handleShow={setDeleteModalShow}
				deleteHandler={onDeleteHandler} />
		</>
	);
}
