import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AppRouteUi, ConferenceCallSettingCode } from "../../configs";
import { CompanyTabs } from '../../configs/TabsHeaders';
import { NavTab } from "../../features";
import { CompanyConferenceInfo, CompanyProfileInfo, CompanyProfileTopBar } from "../../features/CompanyProfile";
import { useFunctionalityCompanyProfileDetails } from '../hooks/functionalityCompany';
import { ApiSchemaCompanyByIdData } from "../../models";
import { useAppSelector } from "../../core";
import { useLocation } from 'react-router-dom';

export function PageCompanyProfile(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const userCompany = useAppSelector((state) => state.auth.profile?.companyId);
  let location = useLocation<any>();
  let parentCompanyName = location?.state?.parentCompanyName
  const { onCompanyProfileDetails } = useFunctionalityCompanyProfileDetails({ companyId: AppRouteUi.Company.Profile.useParam() });
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const [companyProfileDetails, setCompanyProfileDetails] = useState<ApiSchemaCompanyByIdData>();

  useEffect(() => {
    onCompanyProfileDetails()
      ?.then((res) => {
        setCompanyProfileDetails(res?.data);
      }
      )
  }, [onUpdateSuccess]);

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {companyProfileDetails &&
        <CompanyProfileTopBar companyName={companyProfileDetails?.name} parentCompany={companyProfileDetails?.parentId === userCompany ? "" : parentCompanyName} heading={companyProfileDetails?.parentId === userCompany ? "Companies" : "Resellers"} />
      }
      <div className="row mt-3">
        <div className="col-profile-left-width">
          <CompanyProfileInfo companyProfileData={companyProfileDetails ?? {}}
            onUpdateSuccess={onUpdateSuccess}
            setOnUpdateSuccess={setOnUpdateSuccess} />

          {companyProfileDetails?.companySettings?.callSettings?.find(x => x.code === ConferenceCallSettingCode)?.isEnabled === true ? <CompanyConferenceInfo /> : null}
        </div>
        <div className="col-profile-right-width">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <NavTab
                  tabList={CompanyTabs}
                  activeTabName={CompanyTabs[0].name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}