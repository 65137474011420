import React from "react";
import { handleNumberToTwoDecimalPoint } from "../../../utils";

export function BalanceBox({ balance }: { balance: number }) {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mt-3">
          <div className="card component-card">
            <div className="card-body ">
              <div className="row justify-content-start flex-wrap align-items-sm-center mx-0">
                <div className="font-600 font-sz-18">Balance :</div>
                <div style={{ color: "red" }} className="font-600 font-sz-18">
                  <span className="ml-1"></span>{" "}
                  <span>{handleNumberToTwoDecimalPoint(balance ? balance : 0)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
