import React, { useEffect, useState } from "react";
import { BookingStatusType, FileBucketURL, InvoiceType, PlatformType, } from "../../configs";
import { ModalViewAgentBills } from "../modals/viewJobAgentBillsModal";
import { ModalViewClientBills } from "../modals/viewJobClientBillsModal";
import { AgentBills, RptInvoiceViewModel } from "../../models";
import { Table } from "react-bootstrap";
import { useFunctionalityBookingBillGenerator, useFunctionalityBookingBillsDetails } from "../../pages/hooks";
import { ModalCloseViewJob } from "../modals/viewCloseJobModal";
import { useFunctionalityInvoice } from "../../pages/hooks/functionalityInvoice";
import { ModalPrintInvoice } from "../modals";
import LoadingBar from "react-top-loading-bar";
import { handleNumberToTwoDecimalPoint } from "../../utils";
import { AssetSvg } from "../../assets";

type Props = {
  jobData: any
  setSweetChangeProp?: any
}

export function JobBills(props: Props): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [billChangeProp, setBillChangeProp] = useState<number>(0)
  const { jobData, setSweetChangeProp } = props;
  const [showAgentBillsDetails, setShowAgentBillsDetails] = React.useState(false);
  const [showClientBill, setShowClientBill] = React.useState(false);
  const [bookingBillsDetails, setBookingBillsDetails] = React.useState<any>({});
  const [agentBills, setAgentBills] = React.useState<any>([]);
  const [agentBillsDataIndex, setAgentBillsDataIndex] = React.useState<number>(0);
  const [showCloseEditModal, setShowCloseEditModal] = React.useState<boolean>(false);
  const [printInvoiceModalShow, setPrintInvoiceModalShow] = React.useState(false);
  const { onCompanyBookingBillsDetails } = useFunctionalityBookingBillsDetails({
    bookingId: jobData?.id ?? "",
  });

  const { onGenerateInvoice } = useFunctionalityBookingBillGenerator();
  const [invoiceDetails, setInvoiceDetails] = useState<RptInvoiceViewModel>();

  useEffect(() => {
    if (jobData?.id) {
      onCompanyBookingBillsDetails()?.then((res) => {
        setBookingBillsDetails(res?.data);
        setAgentBills(res?.data?.agentBills);
      });
    }
  }, [billChangeProp]);

  const agentDuration = (duration: any) => {
    const time = duration.split(":");
    const hours = Number(time[0]);
    const minutes = Number(time[1]);
    if (!hours && !minutes) return false;
    return true;
  };

  const spiltDuration = (duration: any) => {
    const time = duration?.split(":");
    const hours = Number(time ? time[0] : 0);
    const minutes = Number(time ? time[1] : 0);
    const seconds = Number(time ? time[2] : 0);
    return [hours, minutes, seconds];
  };

  const getAgentBill = (bill: any) => {
    if (!Number(bill)) return false;
    return true;
  };
  const { getInvoiceDetails } = useFunctionalityInvoice({ setLoaderProgress: setLoaderProgress, });
  const billGenerator = () => {
    onGenerateInvoice(jobData?.id)?.then((res) => {
      setSweetChangeProp((prev: number) => prev + 1)
    });
  };

  const invoiceModalOpen = () => {
    getInvoiceDetails(jobData?.clientBookingInvoiceId, InvoiceType.CompanyBooking, ".btn-invo").then((data) => {
      setInvoiceDetails(data?.data);
      setPrintInvoiceModalShow(true);
    });
  };

  const onAgentBillModalOpen = (id: string) => {
    getInvoiceDetails(id, InvoiceType.OperatorBooking).then((data) => {
      setInvoiceDetails(data?.data);
      setPrintInvoiceModalShow(true);
    });
  };
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="component-card my-3">
        <div className="bg-white component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
          <div>Booking Bills</div>
          {(jobData?.status === BookingStatusType.Closed || jobData?.status === BookingStatusType.Cancelled) && (
            <div className="row mx-0 justify-content-end">
              <button
                type="button"
                className="btn btn-purple text-uppercase btn-bill-gen"
                onClick={() => billGenerator()}
              >
                generate invoice
              </button>
            </div>
          )}
          {jobData?.status === BookingStatusType.Billed && (
            <div className="row mx-0 justify-content-end">
              <button
                type="button"
                className="btn btn-purple text-uppercase btn-invo"
                onClick={() => invoiceModalOpen()}
              >
                Invoice
              </button>
            </div>
          )}
        </div>
        <div className="component-card-body border">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-wrap ">
                <div className="width-fit-content border site-rounded-border py-2 px-3">
                  <span className="mr-3">
                    {" "}
                    <strong> Client Bills:</strong>
                  </span>
                  <span className={bookingBillsDetails?.totalClientBill < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.totalClientBill ? bookingBillsDetails?.totalClientBill : 0)}</span>
                  <button
                    className="btn btn-link py-0 text-purple site-rounded-border  ml-3 font-sz-14 text-uppercase"
                    onClick={() => {
                      setShowClientBill(true);
                    }}
                  >
                    view
                  </button>
                </div>
                <div className="width-fit-content border site-rounded-border py-2 px-3 ml-3">
                  <span className="mr-3">
                    {" "}
                    <strong> Profit: </strong>
                  </span>
                  <span className={bookingBillsDetails?.profit < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.profit ? bookingBillsDetails?.profit : 0)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="component-card mt-3">
            <div className="component-card-header border-top border-right border-left">
              Agent Bills
            </div>
            <div className="component-card-body border border-top-0 p-0">
              <div className="table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th scope="col" className="w-25">
                        Name
                      </th>
                      <th scope="col" className="w-25">
                        Duration
                      </th>
                      <th scope="col" className="w-25">
                        Bill
                      </th>
                      <th scope="col" className="w-25">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentBills?.map((agentBill: AgentBills, index: number) => (
                      <>
                        <tr key={agentBill.operatorUserId}>
                          <td scope="row" className="w-25">
                            {agentBill?.name}
                          </td>
                          <td scope="row" className="w-25">
                            {agentDuration(agentBill?.duration)
                              ? agentBill?.duration
                              : "-"}
                          </td>
                          <td scope="row" className="w-25">
                            {getAgentBill(agentBill?.bill)
                              ? handleNumberToTwoDecimalPoint(agentBill?.bill ? agentBill?.bill : 0)
                              : "-"}
                          </td>

                          {agentDuration(agentBill?.duration) ||
                            getAgentBill(agentBill?.bill) ? (
                            <td scope="row" className="w-25">
                              <div className="d-flex align-items-center">
                                {jobData.serviceType === PlatformType.OnSite && agentBill?.vosFormFileKey ? (
                                  <div
                                    className="btn btn-link text-purple text-uppercase py-0 pl-0"
                                    onClick={() =>
                                      window.open(
                                        `${FileBucketURL?.Root}${agentBill?.vosFormFileKey}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    vos
                                  </div>
                                ) : null}

                                <button
                                  className="btn px-3 py-0"
                                  onClick={() => {
                                    setShowAgentBillsDetails(true);
                                    setAgentBillsDataIndex(index);
                                  }}
                                >
                                  <div
                                    className="btn svg-color"
                                    title="bookingBillsView"
                                  >
                                    <span >
                                      <AssetSvg.ViewProfile />
                                    </span>
                                  </div>
                                </button>
                                {jobData.serviceType === PlatformType.OnSite && jobData.status !== BookingStatusType.Cancelled
                                  && !agentBill.bookingOperatorInvoiceId && (
                                    <button
                                      className="btn px-3 py-0"
                                      onClick={() => {
                                        setShowCloseEditModal(true);
                                        setAgentBillsDataIndex(index);
                                      }}
                                    >
                                      <div
                                        className="btn svg-color"
                                        title="bookingBsillsEdit"
                                      >
                                        <span >
                                          <AssetSvg.Edit />
                                        </span>
                                      </div>
                                    </button>
                                  )}
                                {jobData.serviceType === PlatformType.OnSite && agentBill.bookingOperatorInvoiceId && (
                                  <button
                                    className="btn px-3 py-0"
                                    onClick={() => {
                                      onAgentBillModalOpen(
                                        agentBill.bookingOperatorInvoiceId
                                      );
                                    }}
                                  >
                                    <div
                                      className="btn svg-color"
                                      title="bookingPdfView"
                                    >
                                      <span >
                                        <AssetSvg.ViewProfile />
                                      </span>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </td>
                          ) : (
                            <td scope="row" className="w-25">
                              <div className="d-flex align-items-center">
                                {
                                  jobData.serviceType === PlatformType.OnSite ? <button
                                    className="btn py-0 btn btn-link text-purple text-uppercase pl-0 py-0"
                                    onClick={() => {
                                      setShowCloseEditModal(true);
                                      setAgentBillsDataIndex(index);
                                    }}
                                  >
                                    close
                                  </button> : "-"
                                }
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {showClientBill && (
          <ModalViewClientBills
            show={showClientBill}
            handleShow={setShowClientBill}
            spiltDuration={spiltDuration}
            bookingBillsDetails={bookingBillsDetails}
            jobPlatformType={jobData.serviceType}
          />
        )}

        {showAgentBillsDetails && (
          <ModalViewAgentBills
            show={showAgentBillsDetails}
            handleShow={setShowAgentBillsDetails}
            spiltDuration={spiltDuration}
            bookingId={jobData?.id ?? ""}
            bookingAgentBills={agentBills[agentBillsDataIndex]}
            jobPlatformType={jobData.serviceType}
          />
        )}

        {
          showCloseEditModal && (
            <ModalCloseViewJob
              show={showCloseEditModal}
              handleShow={setShowCloseEditModal}
              bookingId={jobData?.id ?? ""}
              bookingAgentBills={agentBills[agentBillsDataIndex]}
              actualStartTime={""}
              actualEndTime={""}
              showSerialNoEditCloseModal={jobData?.serialNo ?? ""}
              setSweetBillChangeProp={setBillChangeProp}
              setSweetPageReloadProp={setSweetChangeProp}
            />
          )
        }
        <ModalPrintInvoice
          show={printInvoiceModalShow}
          handleShow={setPrintInvoiceModalShow}
          invoice={invoiceDetails}
        />
      </div>
    </>
  );
}
