import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { FormErrorMessage } from "../../components";
import { DefaultCountry, InputFieldValidationErrorMessage } from "../../configs";
import { ApiSchemaDepartmentData, Option } from "../../models";
import { useFunctionalityCountry, useFunctionalityDepartment, useFunctionalityState, useFunctionalityZipcodeValidation } from '../../pages/hooks';
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import useFunctionalityValidateUSAPhoneNumber from "../../pages/hooks/functionalityValidateUSAPhoneNumber";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  modalHeader: string;
  companyId: string;
  dataToEdit?: ApiSchemaDepartmentData;
  setDataToEdit?: (value?: ApiSchemaDepartmentData) => void;
  setIsEditDepartment?: any;
};
export function ModalDepartmentSaveModal(props: Props): JSX.Element {
  const { companyId, show, handleShow, dataToEdit, setDataToEdit, setIsEditDepartment } = props;
  const [CountryFieldkey, setCountryFieldKey] = useState(Date.now());
  const [StateFieldkey, setStateFieldKey] = useState(Date.now());
  const { countryList } = useFunctionalityCountry(show);
  const { onAddDepartment, onEditDepartment } = useFunctionalityDepartment({ companyId });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ApiSchemaDepartmentData>();

  const [selectedCountry, setSelectedCountry] = React.useState(dataToEdit?.address.countryId ? dataToEdit?.address.countryId : DefaultCountry.USA);
  const { stateList } = useFunctionalityState(selectedCountry, show);
  const stateName = dataToEdit?.address?.stateId ? stateList?.find((x: any) => x.value === dataToEdit?.address?.stateId)?.label : "";
  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const [selectedZipcode, setSelectZipcode] = React.useState<string>(dataToEdit?.address.zipCode ? dataToEdit?.address.zipCode : '');
  const [selectedState, setSelectedState] = React.useState<string>(dataToEdit?.address.stateName ? dataToEdit?.address.stateName : '');

  const { validateTeNumber, numberErrMsg } = useFunctionalityValidateUSAPhoneNumber();
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry,
    selectedState: selectedState ? selectedState : stateName!,
    selectedZipcode
  });

  useEffect(() => {
    setCountryFieldKey(Date.now())
    setStateFieldKey(Date.now())
  }, [countryList]);

  const modalHideHandler = () => {
    handleShow(false);
    setDataToEdit?.(undefined);
    setIsEditDepartment?.(false);
  }
  const onCreateHandler = (form: ApiSchemaDepartmentData) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    if (zipCodeError === "") {
      onAddDepartment(form)
        .then(() => modalHideHandler())
        .catch((e: any) => { console.error(e) });
    }
  };
  const onEditHandler = (form: ApiSchemaDepartmentData) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    if (zipCodeError === "") {
      onEditDepartment(form, dataToEdit?.id ?? "")
        .then(() => modalHideHandler())
        .catch((e) => { });
    }
  };
  const onCountryChangeHandler = (value: Option | null) => {
    setValue("address.countryName", value?.label, {
      shouldValidate: true,
    });
    setValue("address.countryId", value?.value, {
      shouldValidate: true,
    });
    setSelectedCountry(value ? value.value : "");
  }
  const handleState = (event: any) => {
    setSelectedState(event.label);
  };
  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value)
  }
  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode])
  return (
    <Modal show={props.show} onHide={modalHideHandler} centered>
      <form onSubmit={handleSubmit(dataToEdit ? onEditHandler : onCreateHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{props.modalHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className=" form-group">
                <label htmlFor="" className="required" >Department Name</label>
                <div className="">
                  <input
                    {...register('name', {
                      required: true,
                      // validate: handleSpecialCharacterWhiteSpaces
                      validate: useFunctionlityInputFieldValidation
                    })}
                    type="text"
                    className="form-control"
                    defaultValue={dataToEdit?.name} />
                  {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
                  {
                    errors.name && errors.name.type == "required" && (
                      <FormErrorMessage message="required" />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label
                  htmlFor=""
                  className=""
                >Phone
                </label>
                <div className="">
                  <input
                    {...register('address.phone', {})}
                    type="text"
                    className="form-control"
                    defaultValue={dataToEdit?.address.phone}
                    onChange={(event) => validateTeNumber(event)} />
                  {
                    numberErrMsg && (
                      <FormErrorMessage message={numberErrMsg} />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className=" form-group">
                <label htmlFor="" className=" required" >Address</label>
                <div className="">
                  <input
                    {...register('address.description', { required: true })}
                    type="text"
                    className="form-control"
                    defaultValue={dataToEdit?.address.description} />
                  {
                    errors.address?.description && errors.address?.description.type == "required" && (
                      <FormErrorMessage message="required" />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className=" form-group">
                <label htmlFor="" className=" required">City</label>
                <input
                  type="text"
                  {...register('address.city', {
                    required: true,
                    validate: handleSpecialCharacterWhiteSpaces
                  })}
                  className="form-control"
                  placeholder="City"
                  defaultValue={dataToEdit?.address.city} />
                {
                  errors.address?.city && errors.address?.city.type == "validate" && (
                    <FormErrorMessage message="Please enter valid value" />
                  )
                }
                {
                  errors.address?.city && errors.address?.city.type == "required" && (
                    <FormErrorMessage message="required" />
                  )
                }
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className=" form-group">
                <label htmlFor="" className=" required">State</label>
                <div className="">
                  <input
                    className="d-none"
                    {...register('address.stateId', { required: true })}
                    defaultValue={dataToEdit?.address.stateId} />
                  <input
                    className="d-none"
                    {...register('address.stateName', { required: true })}
                    defaultValue={dataToEdit?.address.stateName} />
                  <Select
                    key={StateFieldkey}
                    className={` ${errors.address?.stateId?.type == "required" ? 'field-required' : ''} `}
                    placeholder="Select state"
                    defaultValue={dataToEdit?.address.stateId ? stateList.find(x => x.value === dataToEdit?.address.stateId) : null}

                    isDisabled={(stateList.length === 0) ? true : false}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={stateList}
                    onChange={(value) => {
                      handleState(value);
                      setValue("address.stateName", value?.label, {
                        shouldValidate: true,
                      });
                      setValue("address.stateId", value?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {
                    errors.address?.stateId && (
                      <FormErrorMessage message="required" />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className=" form-group">
                <label htmlFor="" className=" required" >Zip</label>
                <input
                  type="text"
                  {...register('address.zipCode', {
                    required: true,
                    validate: handleSpecialCharacterWhiteSpaces
                  })}
                  className="form-control"
                  placeholder="Zip"
                  defaultValue={dataToEdit?.address.zipCode}
                  onChange={(event) => { handleZip(event) }} />
                {
                  zipCodeError && (
                    <FormErrorMessage message={zipCodeError} />
                  )
                }
                {
                  errors.address?.zipCode && errors.address?.zipCode.type == "validate" && (
                    <FormErrorMessage message="Please enter valid value" />
                  )
                }
                {
                  errors.address?.zipCode && errors.address?.zipCode.type == "required" && (
                    <FormErrorMessage message="required" />
                  )
                }
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className=" form-group">
                <label htmlFor="" className="position-relative required">Country</label>
                <input
                  className="d-none"
                  {...register('address.countryName', { required: true })}
                  defaultValue={dataToEdit?.address.countryName ?? "United States"} />
                <input
                  className="d-none"
                  {...register('address.countryId', { required: true })}
                  defaultValue={dataToEdit?.address.countryId ?? DefaultCountry.USA} />
                <Select
                  key={CountryFieldkey}
                  className={` ${errors.address?.countryId?.type == "required" ? 'field-required' : ''} `}
                  placeholder="Select country"
                  isDisabled={(countryList.length === 0) ? true : false}
                  defaultValue={dataToEdit?.address.countryId ? countryList.find(x => x.value === dataToEdit?.address.countryId) : countryList?.find(
                    (x: { value: string }) =>
                      x.value === DefaultCountry.USA
                  )}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={countryList}
                  onChange={(value) => {
                    setValue("address.stateId", "", {
                      shouldValidate: true,
                    });
                    onCountryChangeHandler(value)
                  }}

                />
                {
                  errors.address?.countryId &&
                  errors.address.countryId.type == "required" && (
                    <FormErrorMessage message="required" />
                  )
                }
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <button className="btn text-uppercase" type="button" onClick={modalHideHandler}>Cancel</button>
          <button type="submit" className=" btn btn-primary text-uppercase department-form-submit-btn">Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
