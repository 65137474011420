function getBrowserName(userAgent: string) {
  if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
    return 'Google Chrome';
  }

  if (/Firefox/.test(userAgent)) {
    return 'Mozilla Firefox';
  }

  if (
    /Safari/.test(userAgent) &&
    !/Chrome/.test(userAgent) &&
    !/Edg/.test(userAgent)
  ) {
    return 'Apple Safari';
  }

  if (/Edg/.test(userAgent)) {
    return 'Microsoft Edge';
  }

  if (/Trident/.test(userAgent)) {
    return 'Internet Explorer';
  }

  if (/Opera/.test(userAgent) || /OPR/.test(userAgent)) {
    return 'Opera';
  }

  return 'Unknown Browser';
}

function isMobileBrowser() {
  const userAgent = window.navigator.userAgent;
  let isMobile = RegExp(/Android|webOS|iPhone|iPod|iPad/i).test(userAgent);
  if (!isMobile) {
    const isMac = RegExp(/Macintosh/i).test(userAgent);
    if (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      isMobile = true;
    }
  }
  return isMobile;
}

function isWebView() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes('WebView')) {
    return true;
  }
  return false;
}

function isElectron() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes('Electron')) {
    return true;
  }
  return false;
}

function isAndroid() {
  const userAgent = window.navigator.userAgent;
  if (userAgent.match(/Android/i)) {
    return true;
  }
  return false;
}

export const UserAgentUtils = {
  getBrowserName,
  isWebView,
  isElectron,
  isMobileBrowser,
  isAndroid,
};
