import { useState } from "react";
import { AssetSvg } from "../../assets";
import { ApiSchemaCompanyByIdData, CompanyUpdateModel } from "../../models";
import { ModalCompanyEditInfo } from "../modals/companyUpdateModal";
import { useFunctionalityCompany, useFunctionalityState, } from "../../pages/hooks";
import { AppRouteUi, DefaultImageType, FileBucketURL, } from "../../configs";
import { useFunctionalityImageView } from "../../pages/hooks/functionalityImageView";
import { ModalChangeLogo } from "../modals/companyInfoChangeLogoModal";
import UserProfileImageLoader from "../Common/Components/CommonUserProfile/UserProfileImageLoader";

type Props = {
  companyProfileData: ApiSchemaCompanyByIdData;
  onUpdateSuccess?: any;
  setOnUpdateSuccess?: any;
};
export function CompanyProfileInfo({ companyProfileData, onUpdateSuccess, setOnUpdateSuccess, }: Props) {
  const [editModalShow, setEditModalShow] = useState(false);
  const [profilePictureModalShow, setProfilePictureModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const { updateCompanyProfile } = useFunctionalityCompany({
    companyId: AppRouteUi.Company.Profile.useParam(),
  });
  const getCompanyOrResellerId = () => {
    return companyProfileData?.id ?? "";
  };

  const { defaultImageLoad, imageView, onUploadComplete } = useFunctionalityImageView();
  const onUpdateHandler = (form: CompanyUpdateModel) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    updateCompanyProfile(getCompanyOrResellerId(), form)
      .then(() => {
        setEditModalShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  const { stateList } = useFunctionalityState(
    companyProfileData?.address?.countryId ?? "", editModalShow
  );
  const initialStateList = [...stateList];

  return (
    <>
      <div className="side-profile-img-container d-flex justify-content-center align-items-center">
        {
          loading && <UserProfileImageLoader />
        }
        {
          companyProfileData?.id &&
          <img
            src={`${FileBucketURL.Root}${companyProfileData?.id}?${imageView.fileTimeStamp}`}
            onError={(ev) => {
              defaultImageLoad(
                ev,
                DefaultImageType.DefaultCompanyProfile,
                "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              );
            }}
            onLoad={() => setLoading(false)}
            className={`img-fluid ${loading ? 'd-none' : ""}`}
          />
        }
      </div>
      <div className="row justify-content-center">
        <button
          type="button"
          onClick={() => setProfilePictureModalShow(true)}
          className="btn  btn-purple text-site font-sz-14  my-3"
        >
          Upload Logo
        </button>
      </div>
      <div className="row  align-items-center mx-auto mb-3">
        <div className="col px-1 ">
          <h4 className="m-0 font-300 text-dark">{companyProfileData?.name}</h4>
        </div>
        <div className="name-edit-btn">
          {companyProfileData?.name ? (
            <button
              type="button"
              onClick={() => setEditModalShow(true)}
              className="btn btn-link text-site px-0 svg-color"
              title="Edit"
            >
              {/*<FontAwesomeIcon icon={faEdit} />*/}
              <div className="btn svg-color" title="profileEdit">
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </button>
          ) : null}
        </div>
      </div>
      <div className="row border mx-0 border-radius-xl mt-4 mb-3">
        <div className="col-12 py-3  ">
          <div className="row border-bottom">
            <p className=" col  border-grey mb-2 pb-1 font-500">Address</p>
          </div>
          <div className="row ">
            <p className="col text-dark mt-3 mb-1 miscelleous-container text-break">
              {companyProfileData?.address?.description
                ? companyProfileData?.address?.description?.concat(", ")
                : ""}
              {companyProfileData?.address?.city
                ? companyProfileData?.address?.city?.concat(", ")
                : ""}
              {companyProfileData?.address?.stateName
                ? companyProfileData?.address?.stateName?.concat(", ")
                : ""}
              {companyProfileData?.address?.zipCode
                ? companyProfileData?.address?.zipCode?.concat(", ")
                : ""}
              {companyProfileData?.address?.countryName}
              {/* 372 N 24th St, SpringField, California, 49037, United States */}
            </p>
          </div>
        </div>
      </div>
      {
        companyProfileData?.address?.email && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col  font-500 border-grey mb-2 pb-1">Email</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {companyProfileData?.address?.email}
              </p>
            </div>
          </div>
        </div>
      }
      {
        companyProfileData?.address?.website && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col font-500 border-grey mb-2 pb-1">Website</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {companyProfileData?.address?.website}
              </p>
            </div>
          </div>
        </div>
      }
      {
        companyProfileData?.address?.phone && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col  font-500 border-grey mb-2 pb-1">Phone Number</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {companyProfileData?.address?.phone}
              </p>
            </div>
          </div>
        </div>
      }
      {
        companyProfileData?.address?.fax && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col font-500 border-grey mb-2 pb-1">Fax</p>
            </div>
            <div className="row ">
              <p className="col  text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {companyProfileData?.address?.fax}
              </p>
            </div>
          </div>
        </div>
      }
      {
        editModalShow ?
          <ModalCompanyEditInfo
            companyData={companyProfileData}
            show={editModalShow}
            handleShow={setEditModalShow}
            onUpdateHandler={onUpdateHandler}
            initialStateList={initialStateList}
          />
          : null
      }

      <ModalChangeLogo
        companyType={companyProfileData?.type}
        companyId={companyProfileData?.id ?? ""}
        onUploadComplete={onUploadComplete}
        show={profilePictureModalShow}
        handleShow={setProfilePictureModalShow}
      />
    </>
  );
}
