import React, { useCallback } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaCallCenterMessageData, ApiSchemaRequestUpdateCallCenterMessage, MessageViewModel } from "../../models";
import { UtilsJQuery } from "../../utils";

type Props = {
  callCenterId: string;
  setLoaderProgress?: any;
};
let callcenterMessageUrl: string;
type UserProps = {
  callCenterId: string;
  setLoaderProgress?: any;

};
export function useFunctionalityCallCenterMessage(props: UserProps) {
  const { callCenterId, setLoaderProgress } = props;
  const callcenterMessageUrl = PlatformApi.CallCenter.GetAllMessages(callCenterId);
 

  const { data: apiCallCenterMessageList } = useSWR<MessageViewModel[]>(callcenterMessageUrl, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<ApiResponse<MessageViewModel[]>>(callcenterMessageUrl)
      .then((r) => {
         
        setLoaderProgress(topLoaderProgress.complete);
        return r.data?.data
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      })
  });

  const CallCenterMessageList = React.useMemo(() => {
    const data = Array.isArray(apiCallCenterMessageList) ? apiCallCenterMessageList : [];
    return data;
  }, [apiCallCenterMessageList]);


  const onEditMessage = useCallback(async (callCenterId: string, form: FormData, category?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".btn-message-submit");
    btnLoading.start?.();
    try {
      var res = await AxiosAuth.post<FormData>(
        PlatformApi.CallCenter.UpdateCallCenterMessage(callCenterId),
        form
      );
      if (res?.status === AppConstants.Api.StatusOK) {
        toast.success(category + " Successfully saved");
        mutate(callcenterMessageUrl);
      }
      btnLoading.stop?.(); 
    } catch (e:any) {
      btnLoading.stop?.();
      toast.error(e.Message);
      console.log(e);
       
    }
    
    return Promise.resolve(null);
  }, []);
   
  return {
    CallCenterMessageList,
    onEditMessage,

  };
}
