import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AssetSvg, AssetsPng } from '../../../assets';
import { DefaultImageType } from '../../../configs';
import { ChannelContext } from '../../../core';
import { ApiResponseConversation } from '../../../models';
import {
  useFunctionalityChat,
  useFunctionalityImageView,
} from '../../../pages/hooks';
import { CreateGroupContactList } from './createGroupContactList';
import { LoadingOverlay } from '../../../components';

type Props = {
  searchValue: string;
};

export function GroupList(props: Props): JSX.Element {
  const { searchValue } = props;
  const [create, setCreate] = useState<boolean>(false);
  const [groupConvos, setGroupConvos] = useState<any>();
  const [filteredGroupConvos, setFilteredGroupConvos] = useState<any>();
  const { onGetGroupConversations } = useFunctionalityChat();
  const {
    setSelectedChannel,
    conversationsClient,
    setChatId,
    setCurrentConvo,
    setConvoType,
    messageAdded,
  } = React.useContext(ChannelContext);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { defaultImageLoad } = useFunctionalityImageView();
  const onClickHandler = () => {
    console.log('Load all contact list');
    setCreate(true);
  };

  useEffect(() => {
    setIsBusy(true);
    onGetGroupConversations(setGroupConvos).then(() => {
      setIsBusy(false);
    });
  }, []);

  useEffect(() => {}, [groupConvos]);

  useEffect(() => {
    onGetGroupConversations(setGroupConvos).then(() => {});
  }, [messageAdded]);

  const getConvoBySid = (
    id: string,
    convo: ApiResponseConversation,
    chatId: string
  ) => {
    setChatId(chatId);
    setCurrentConvo(convo);
    setConvoType(convo.conversationType);
    setIsBusy(true);
    conversationsClient
      ?.getConversationBySid(id)
      .then((conversation: any) => {
        console.log('Conversation: ', conversation);
        setSelectedChannel(conversation);
        setIsBusy(false);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    if (groupConvos) filterList();
  }, [groupConvos, searchValue]);

  const filterList = () => {
    let substring = searchValue.toLowerCase();

    let returningList: ApiResponseConversation[] = [];

    if (groupConvos.length > 0) {
      groupConvos?.map((contact: any) => {
        let lowerLabel = contact.conversationName?.toLocaleLowerCase();
        if (lowerLabel?.includes(substring)) {
          returningList.push(contact);
        }
      });
      setFilteredGroupConvos(returningList);
    }
  };

  return (
    <>
      {!create ? (
        <div className="col-12 contacts-chat-list group-list-height position-relative">
          <LoadingOverlay active={isBusy} animation="border">
            <div className="row position-sticky sticky-top bg-white">
              <div className="col-12 text-center py-3 group-chat-tab">
                <a
                  href="#"
                  className="d-flex align-items-center create-new text-decoration-none  justify-content-center my-1 newChatBtn"
                  onClick={onClickHandler}
                >
                  <AssetSvg.GroupChat />
                  <span className="ml-2 font-600"> Create New Group</span>
                </a>
              </div>
            </div>

            {filteredGroupConvos &&
              filteredGroupConvos.map((niceConvo: ApiResponseConversation) => {
                return (
                  <div
                    className="row contacts-chat-list-item align-items-center pb-2 cursor-pointer"
                    onClick={() =>
                      getConvoBySid(
                        niceConvo.conversationSid,
                        niceConvo,
                        niceConvo.id
                      )
                    }
                  >
                    <div className="header-profile-image incoming-chat-image col ">
                      <img
                        src={AssetsPng.DeFaultChatImage}
                        alt="logo"
                        className="img-fluid"
                        onError={(ev) => {
                          defaultImageLoad(
                            ev,
                            DefaultImageType.DefaultChat,
                            '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                          );
                        }}
                      />
                    </div>
                    <div className="col col-contact-name">
                      <div className="row ">
                        <div className="col-8 pl-0 ">
                          <p className="mb-0 text-truncate cursor-pointer">
                            {niceConvo.conversationName}
                          </p>
                        </div>
                        <div className="col-4 text-right">
                          <p className="mb-0  font-sz-10 text-muted">
                            {moment(niceConvo.lastConversationTime).format(
                              'll'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="empty-image-width"></div>
                        <div className="col">
                          <div className="border-bottom row mt-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </LoadingOverlay>
        </div>
      ) : (
        <CreateGroupContactList
          searchValue={searchValue}
          setCreate={setCreate}
          editGroup={false}
        />
      )}
    </>
  );
}
