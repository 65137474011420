import { Modal } from "react-bootstrap";
import { AppConstants } from "../../configs";
import { useFunctionalityCompany } from "../../pages/hooks";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  companyId: string;
  setDeletedCompanyId: (value: any) => void;
};

export function ModalDeleteCompany(props: Props): JSX.Element {
  const { companyId, setDeletedCompanyId } = props;
  const { onDelete } = useFunctionalityCompany({ companyId: companyId });
  let onClickHandler = () => {
    onDelete(companyId).then((r) => {
      if (r?.status == AppConstants.Api.StatusOK) {
        props.handleShow(false);
      }
    });
  };
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <Modal.Body>Are you sure you want to delete this company?</Modal.Body>

      <Modal.Footer>
        <button
          onClick={() => {
            setDeletedCompanyId("");
            props.handleShow(false);
          }}
          className="btn btn-link text-uppercase text-site"
        >
          Cancel
        </button>
        <button
          onClick={() => {onClickHandler()}}
          className="btn btn-primary text-uppercase login-from-submit-btn"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}
