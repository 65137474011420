import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../components";
import { AppointmentDescriptionRequestModel, AppointmentDescriptionResponseModel, } from "../../models/api/modality";
import { Option } from "../../models";
import { useFunctionalityAppointmentDescription } from "../../pages/hooks/functionalityAppointmentDescription";
import { useFunctionalityDropDownList } from "../../pages/hooks/functionalityDropdown";
import { InputFieldValidationErrorMessage, reactSelectFilterConfig } from "../../configs";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit?: AppointmentDescriptionResponseModel;
  setDataToEdit?: (value?: AppointmentDescriptionResponseModel) => void;
};

export function ModalAppointmentDescription(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit } = props;
  const [appointmentCategoryList, setAppointmentCategoryList] = useState<Option[]>([]);
  const [appoinmentTypeFieldkey, setAppoinmentTypeFieldkey] = useState(Date.now());
  const { LoadAppointmentCategoryList } = useFunctionalityDropDownList();
  const {
    onAddAppointmentDescription,
    onEditAppointmentDescription,
    responseMappedToRequest
  } = useFunctionalityAppointmentDescription();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<AppointmentDescriptionRequestModel>();
  const [errMsg, setErrMsg] = useState("");
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const modalHideHandler = () => {
    setDataToEdit?.(undefined);
    handleShow(false);
    $("#frmapDes").trigger("reset");
  };
  const onEditHandler = (form: AppointmentDescriptionRequestModel) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    onEditAppointmentDescription(form, dataToEdit?.id ?? "")
      .then(() => modalHideHandler())
      .catch((e) => { });
  };
  const onCreateHandler = (form: AppointmentDescriptionRequestModel) => {
    form = {
      ...form,
      name: form.name.trim()
    }
    onAddAppointmentDescription(form)
      .then(() => modalHideHandler())
      .catch((e) => { });
  };
  const onValidatePin = (value: any) => {
    if (value < 2 || isNaN(value)) {
      setErrMsg("Please enter at least 2 numbers.");
      return false;
    }
    setErrMsg("");
    return true;
  };
  useEffect(() => {
    LoadAppointmentCategoryList("").then((r) => {
      setAppointmentCategoryList(r?.data ?? []);
    });
  }, []);

  useEffect(() => {
    setAppoinmentTypeFieldkey(Date.now())
  }, [appointmentCategoryList]);
  return (
    <Modal show={show} onHide={modalHideHandler} centered backdrop="static">
      <form
        id="frmapDes"
        onSubmit={handleSubmit(dataToEdit ? onEditHandler : onCreateHandler)}
      >
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>
            {dataToEdit
              ? "Update Appointment Description"
              : "Add  Appointment Description"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Appointment Type
                </label>
                <input
                  className="d-none"
                  {...register('appointmentCategoryId', { required: true })}
                  defaultValue={
                    responseMappedToRequest(dataToEdit).appointmentCategoryId
                  } />
                <Select
                  key={appoinmentTypeFieldkey}
                  className={` ${errors.appointmentCategoryId?.type == "required"
                    ? "field-required"
                    : ""
                    } `}
                  placeholder="Select Appointment Type"
                  isDisabled={
                    appointmentCategoryList.length === 0 ? true : false
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={
                    dataToEdit?.appointmentCategoryId
                      ? appointmentCategoryList.find(
                        (x) =>
                          x.value ===
                          responseMappedToRequest(dataToEdit)
                            .appointmentCategoryId
                      )
                      : null
                  }
                  options={appointmentCategoryList}
                  filterOption={createFilter(reactSelectFilterConfig)}
                  onChange={(value) => {
                    setValue("appointmentCategoryId", value?.value);
                  }}
                />
                {errors.appointmentCategoryId && (
                  <FormErrorMessage message="required" />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Appointment Description
                </label>
                <input
                  type="text"
                  {...register('name', { required: true, validate: useFunctionlityInputFieldValidation })}
                  className="form-control"
                  placeholder="Appointment Description"
                  defaultValue={dataToEdit?.name} />
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
              </div>

              {/* <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Pin
                </label>
                <input
                  type="text"
                  name="pin"
                  className="form-control"
                  placeholder="Enter PIN(Min 2 digits, Max 4 digits)"
                  defaultValue={dataToEdit?.pin}
                  ref={register({ required: true, validate: onValidatePin })}
                  maxLength={4}
                />
                {(errors.pin || errMsg) && (
                  <FormErrorMessage message={errMsg || "required"} />
                )}
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="apointmentdescription-form-submit-btn btn btn-primary text-uppercase"
          >
            {dataToEdit ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
