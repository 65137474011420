import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage, PlatformType } from "../../configs";
import { ModalityRequestModel, ModalityResponseModel, serviceTypes } from "../../models/api/modality";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { useFunctionalityModality } from "../../pages/hooks/functionalityModality";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit?: ModalityResponseModel;
  setDataToEdit?: (value?: ModalityResponseModel) => void;
};

export function ModalModality(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit } = props;
  const { onAddModality, onEditModality, responseMappedToRequest } = useFunctionalityModality(show);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ModalityRequestModel>();
  const [errMsg, setErrMsg] = useState("");
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const modalHideHandler = () => {
    setDataToEdit?.(undefined);
    handleShow(false);
  }
  const onEditHandler = (form: ModalityRequestModel) => {
    form = {
      ...form,
      serviceType: Number(form.serviceType),
      name: form.name.trim()
    }
    onEditModality(form, dataToEdit?.id ?? "")
      .then(() => modalHideHandler())
      .catch((e) => { })
  };

  const onCreateHandler = (form: ModalityRequestModel) => {
    form = {
      ...form,
      serviceType: Number(form.serviceType),
      name: form.name.trim()
    }
    onAddModality(form)
      .then(() => modalHideHandler())
      .catch((e) => { })
  };

  const onValidatePin = (value: any) => {
    if (value < 2 || isNaN(value)) {
      setErrMsg("Please enter at least 2 numbers.");
      return false;
    }
    setErrMsg("");
    return true;
  };
  return (
    <Modal
      show={show}
      onHide={modalHideHandler}
      centered
      backdrop="static"
    >
      <form onSubmit={handleSubmit(dataToEdit ? onEditHandler : onCreateHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>
            {dataToEdit ? "Update Modality" : "Add A Modality"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Service Type
                </label>
                <input
                  className="d-none"
                  {...register('serviceType', { required: true })}
                  defaultValue={responseMappedToRequest(dataToEdit).serviceType} />
                <Select
                  className={` ${errors.serviceType?.type == "required" ? "field-required" : ""} `}
                  placeholder="Select service Type"
                  isDisabled={serviceTypes.length === 0 ? true : false}
                  components={{ IndicatorSeparator: () => null }}
                  defaultValue={dataToEdit?.serviceType ? serviceTypes.find(x => x.value === responseMappedToRequest(dataToEdit).serviceType) : null}
                  options={serviceTypes}
                  onChange={(value) => {
                    setValue("serviceType", value?.value ? value?.value : 0);
                  }}
                />
                {errors.serviceType && <FormErrorMessage message="required" />}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Modality
                </label>
                <input
                  type="text"
                  {...register('name', { required: true, validate: useFunctionlityInputFieldValidation })}
                  className="form-control"
                  placeholder="Modality"
                  defaultValue={dataToEdit?.name} />
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
              </div>

              {/* <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Pin
                </label>
                <input
                  type="text"
                  name="pin"
                  className="form-control"
                  placeholder="Enter PIN(Min 2 digits, Max 4 digits)"
                  defaultValue={dataToEdit?.pin}
                  ref={register({ required: true, validate: onValidatePin })}
                  maxLength={4}
                />
                {(errors.pin || errMsg) && (
                  <FormErrorMessage message={errMsg || "required"} />
                )}
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="modality-form-submit-btn btn btn-primary text-uppercase"
          >
            {dataToEdit ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
