import React, { useEffect } from "react";
import { ModalIvrCustomInputMapping } from "../..";
import { APIEnabledIvrCustomFields, IvrCustomField } from "../../../models";
import { useFunctionalityCompanyIvr } from "../../../pages/hooks";

type Props = {
  ivrId: string;
  editable: boolean;
  customFields: IvrCustomField[];
};
export function CustomInputMap(props: Props) {
  const { ivrId, editable, customFields } = props;
  const [ivrCustomInputModalShow, setIvrCustomInputModalShow] = React.useState(false);
  const [customFieldsState, setCustomFieldsState] = React.useState<IvrCustomField[]>([]);

  const { onUpdateCustomField } = useFunctionalityCompanyIvr();

  const updatingCustomFields = (updatedCustomField: IvrCustomField) => {
    return [...customFieldsState?.filter((customField: IvrCustomField) => customField?.id !== updatedCustomField?.id), updatedCustomField];
  }

  const handleToogle = (customField: IvrCustomField) => {
    const enabledOrDisabledCustomField = { ...customField, isEnabled: !customField?.isEnabled };

    setCustomFieldsState((prevState: IvrCustomField[]) => {
      const newState = prevState.map((prevCustomField: IvrCustomField) => {
        if (prevCustomField?.id === customField?.id) {
          return enabledOrDisabledCustomField;
        }
        return prevCustomField;
      })
      return newState;
    })

    let form: APIEnabledIvrCustomFields;
    const filteredCustomFields: IvrCustomField[] = updatingCustomFields(enabledOrDisabledCustomField);

    form = { ivrCustomFieldModels: filteredCustomFields };

    onUpdateCustomField(ivrId, form)
      ?.then(() => {
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    if (customFields.length) {
      setCustomFieldsState(customFields)
    }
  }, [customFields])

  return (
    <div className="component-card mb-3">
      {editable && (
        <div className="component-card-header border-top border-right border-left collapsed py-3" id="headingCustomInputMap"
          data-toggle="collapse"
          data-target="#customInputSection"
          aria-expanded="true"
          aria-controls="customInputSection"
        >
          {/* <button
            className="btn btn-link btn-block text-left text-decoration-none text-site siteAccordion"
            type="button"            
          > */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="flex-grow-1">Custom Input Mapping</div>
            <div className="d-flex justify-content-between align-items-center">
              {/* <div className="mr-3">                 
                  <a className="btn btn-link p-0 text-site font-sz-16">

                    <div className="btn svg-color" title="customInputMapEdit" onClick={() => setIvrCustomInputModalShow(false)}>
                      <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_113_2)">
                          <path d="M397.995 19.84L137.899 279.936C127.965 289.816 120.089 301.569 114.728 314.514C109.367 327.459 106.627 341.338 106.667 355.349V384C106.667 389.658 108.914 395.084 112.915 399.085C116.916 403.086 122.342 405.333 128 405.333H156.651C170.662 405.373 184.541 402.633 197.486 397.272C210.431 391.911 222.184 384.035 232.064 374.101L492.16 114.005C504.627 101.508 511.629 84.5755 511.629 66.9227C511.629 49.2699 504.627 32.3377 492.16 19.84C479.481 7.72039 462.617 0.956787 445.077 0.956787C427.538 0.956787 410.674 7.72039 397.995 19.84V19.84ZM461.995 83.84L201.899 343.936C189.869 355.892 173.611 362.622 156.651 362.667H149.333V355.349C149.378 338.389 156.108 322.131 168.064 310.101L428.16 50.0053C432.717 45.6524 438.776 43.2233 445.077 43.2233C451.379 43.2233 457.438 45.6524 461.995 50.0053C466.473 54.4963 468.989 60.5801 468.989 66.9227C468.989 73.2653 466.473 79.349 461.995 83.84V83.84Z" />
                          <path d="M490.667 191.552C485.009 191.552 479.583 193.8 475.582 197.8C471.581 201.801 469.333 207.227 469.333 212.885V320H384C367.026 320 350.748 326.743 338.745 338.745C326.743 350.748 320 367.026 320 384V469.333H106.667C89.6928 469.333 73.4142 462.591 61.4118 450.588C49.4095 438.586 42.6667 422.307 42.6667 405.333V106.667C42.6667 89.6928 49.4095 73.4142 61.4118 61.4118C73.4142 49.4095 89.6928 42.6667 106.667 42.6667H299.563C305.221 42.6667 310.647 40.4191 314.648 36.4183C318.648 32.4175 320.896 26.9913 320.896 21.3333C320.896 15.6754 318.648 10.2492 314.648 6.24839C310.647 2.24761 305.221 0 299.563 0L106.667 0C78.3873 0.0338743 51.2759 11.2828 31.2794 31.2794C11.2828 51.2759 0.0338743 78.3873 0 106.667L0 405.333C0.0338743 433.613 11.2828 460.724 31.2794 480.721C51.2759 500.717 78.3873 511.966 106.667 512H348.651C362.665 512.04 376.548 509.3 389.496 503.939C402.444 498.578 414.201 490.703 424.085 480.768L480.747 424.064C490.683 414.184 498.561 402.432 503.925 389.487C509.29 376.543 512.034 362.663 512 348.651V212.885C512 207.227 509.752 201.801 505.752 197.8C501.751 193.8 496.325 191.552 490.667 191.552ZM393.92 450.603C385.344 459.158 374.5 465.08 362.667 467.669V384C362.667 378.342 364.914 372.916 368.915 368.915C372.916 364.914 378.342 362.667 384 362.667H467.733C465.094 374.475 459.179 385.3 450.667 393.899L393.92 450.603Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0_113_2">
                            <rect width="512" height="512" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </a>
                 </div> */}
              <div>

                {/*
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="closed-panel-icon"
                  />
                  */}
                <button className="btn btn-link text-site font-sz-16 pr-0">
                  <div className="closed-panel-icon">
                    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M399.147 175.147C397.163 173.147 394.804 171.56 392.204 170.477C389.605 169.394 386.816 168.836 384 168.836C381.184 168.836 378.395 169.394 375.796 170.477C373.196 171.56 370.836 173.147 368.853 175.147L271.147 272.853C269.163 274.853 266.804 276.44 264.204 277.523C261.605 278.606 258.816 279.164 256 279.164C253.184 279.164 250.395 278.606 247.796 277.523C245.196 276.44 242.836 274.853 240.853 272.853L143.147 175.147C141.163 173.147 138.804 171.56 136.204 170.477C133.605 169.394 130.816 168.836 128 168.836C125.184 168.836 122.395 169.394 119.796 170.477C117.196 171.56 114.836 173.147 112.853 175.147C108.88 179.144 106.65 184.551 106.65 190.187C106.65 195.823 108.88 201.23 112.853 205.227L210.773 303.147C222.773 315.132 239.04 321.864 256 321.864C272.96 321.864 289.227 315.132 301.227 303.147L399.147 205.227C403.12 201.23 405.35 195.823 405.35 190.187C405.35 184.551 403.12 179.144 399.147 175.147V175.147Z" />
                    </svg>
                  </div>
                  <div className="open-panel-icon">
                    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M384 330.667C381.192 330.683 378.409 330.145 375.81 329.083C373.211 328.021 370.847 326.457 368.853 324.48L271.147 226.56C269.163 224.56 266.804 222.973 264.204 221.89C261.605 220.807 258.816 220.25 256 220.25C253.184 220.25 250.395 220.807 247.796 221.89C245.196 222.973 242.837 224.56 240.853 226.56L143.147 324.48C139.129 328.497 133.681 330.754 128 330.754C122.319 330.754 116.87 328.497 112.853 324.48C108.836 320.463 106.579 315.014 106.579 309.333C106.579 306.52 107.133 303.735 108.21 301.136C109.286 298.537 110.864 296.176 112.853 294.187L210.773 196.48C222.932 184.799 239.139 178.275 256 178.275C272.861 178.275 289.068 184.799 301.227 196.48L399.147 294.187C401.146 296.17 402.733 298.529 403.816 301.129C404.899 303.729 405.457 306.517 405.457 309.333C405.457 312.15 404.899 314.938 403.816 317.538C402.733 320.137 401.146 322.497 399.147 324.48C397.153 326.457 394.789 328.021 392.19 329.083C389.591 330.145 386.808 330.683 384 330.667Z" />
                    </svg>
                  </div>
                </button>

              </div>
            </div>
          </div>
        </div>
      )}

      <div
        id="customInputSection"
        className="collapse"
        aria-labelledby="headingCustomInputMap"
        data-parent="#ivrTab"
      >
        <div className="component-card-body border p-3">
          <div className="row">
            {customFieldsState?.length > 0 ?
              customFieldsState?.map((customField: IvrCustomField) => <div className="pt-3 pb-3 pb-sm-0 col-lg-6" >
                <div className="component-card mb-1">
                  <div className="component-card-header border-top border-right border-left collapsed">
                    <div className="custom-input-map d-flex flex-wrap">
                      <div className="p-0 mr-2 my-auto">
                          <button
                            type="button"
                            className={
                              customField?.isEnabled ? "btn btn-toggle active" : "btn btn-toggle"
                            }
                            // data-toggle="button"
                            aria-pressed="false"
                            id={customField?.id}
                            name={customField?.name}
                            onClick={() => handleToogle(customField)}
                          >
                            <div className="handle"></div>
                          </button>
                        </div>
                        <div className="toggle-button-label p-0"> {customField?.name} </div>
                    </div>
                    
                      
                    </div>
                </div>
              </div>) : <h4 className=" py-2 text-muted font-weight-light text-center m-auto">no data found</h4>
            }
          </div>
        </div>
      </div>

      <ModalIvrCustomInputMapping
        show={ivrCustomInputModalShow}
        handleShow={setIvrCustomInputModalShow}
        header="IVR Custom user inputs"
        ivrId={ivrId}
        customFields={customFields}
        onSaveHandler={() => { }}
      />
    </div>
  );
}
