import { useContext, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AppConstants,
  SocketConstants,
  SocketNotificationType,
} from '../../configs';
import { CallContext, SocketContext, useAppSelector } from '../../core';
import { SocketNotificationModel } from '../../models/socket';
import { useFunctionalityAuth } from '../../pages/hooks/functionalityAuthentication';
import { logger } from '../../utils';
import {
  BookingSocketNotification,
  CallRedirection,
} from './socketnotifications';

export function SocketNotificationBase(): null {
  const dispatch = useDispatch();
  const { onLogout } = useFunctionalityAuth();
  const history = useHistory();
  const { setIsAccepted } = useContext(CallContext);
  const { socketConnection } = useContext(SocketContext);
  const lastPingRef = useRef(0);
  const lastPongRef = useRef(0);
  const data = useAppSelector(
    (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      VRItoken: state.auth.VRItoken,
      userRole: state.auth.profile?.userRole,
      callStatus: state.videoCall?.CallStatus,
      audioCallStatus: state.audioCall.audioCallStatus,
      currentUserDeviceId: state.auth.sessionId,
      id: state.auth.profile?.id,
      userStatus: state.auth.profile?.userStatus,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!!data.isLoggedIn && !!data.VRItoken && !!socketConnection) {
      let responseModel: SocketNotificationModel<any> = {
        Action: '',
        Type: SocketNotificationType.None,
        Data: null,
      };
      socketConnection.onmessage = (event) => {
        responseModel = JSON.parse(event.data);
        responseModel.Type !== SocketNotificationType.Pong &&
          logger({
            message: 'WebSocket Message (Received): ',
            data: JSON.parse(event.data),
          });
        if (
          responseModel.Type === SocketNotificationType.Call ||
          responseModel.Type === SocketNotificationType.OutBoundCall
        ) {
          CallRedirection({
            responseModel,
            currentUser: data,
            dispatch,
            history,
            setIsAccepted,
            onLogout,
          });
        } else if (responseModel.Type === SocketNotificationType.Booking) {
          BookingSocketNotification({ responseModel, dispatch, history, data });
        } else if (responseModel.Type === SocketNotificationType.UserPresence) {
          const RequestModel = JSON.stringify({
            UserId: data.id,
            Status: data.userStatus,
          });
          logger({
            message: 'WebSocket Message (Sent): ',
            data: {
              message: SocketConstants.onAcknowledged,
              data: RequestModel,
            },
          });
          socketConnection?.send(
            JSON.stringify({
              message: SocketConstants.onAcknowledged,
              data: RequestModel,
            })
          );
        } else if (responseModel.Type === SocketNotificationType.Pong) {
          lastPongRef.current = Date.now();
        }
      };
    }
  }, [data, socketConnection]);

  useEffect(() => {
    if (!socketConnection) {
      return;
    }

    let intervalId: number | null = null;

    const pingServer = () => {
      let delayDifference = lastPingRef.current - lastPongRef.current;
      if (delayDifference >= AppConstants.WebSocket.pongDelay) {
        logger({
          message: '[Pong] Delay exceeded limit: ',
          data: delayDifference,
        });
        socketConnection.close();
        return;
      }
      const message = JSON.stringify({
        message: 'OnPing',
        data: `${data.id}`,
      });
      socketConnection.send(message);
      lastPingRef.current = Date.now();
    };

    intervalId = window.setInterval(() => {
      try {
        pingServer();
      } catch (error) {
        logger({ message: '[Ping] Error: ', data: error });
      }
    }, AppConstants.WebSocket.pingDelay);

    return () => {
      intervalId && clearInterval(intervalId);
      lastPingRef.current = 0;
      lastPongRef.current = 0;
    };
  }, [socketConnection, data.id, dispatch]);

  return null;
}
