import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
import {
  AppConstants,
  AppRouteUi,
  PlatformApi,
  PlatformType,
  topLoaderProgress,
} from '../../configs';
import { AxiosAuth, useAppSelector } from '../../core';
import {
  ApiResponse,
  ApiSchemaLanguageData,
  WithPagination,
} from '../../models';
import { OptionMapper, UtilsJQuery } from '../../utils';

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
};

let pagedLanguageUrl: string;

export function useFunctionalityPagedLanguage(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedLanguageListCount, setPagedLanguageListCount] =
    useState<number>(0);

  pagedLanguageUrl = PlatformApi.Language.PagedGetAll(
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiPagedLanguageData } = useSWR<ApiSchemaLanguageData[]>(
    pagedLanguageUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<WithPagination<ApiSchemaLanguageData[]>>
      >(pagedLanguageUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedLanguageListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedLanguageList = useMemo(() => {
    return apiPagedLanguageData ? apiPagedLanguageData : [];
  }, [apiPagedLanguageData]);

  return {
    pagedLanguageList,
    pagedLanguageListCount,
  };
}
type languageProps = {
  plaformType?: PlatformType;
  companyId?: string;
  shouldLanguageGetAllInvoke?: boolean;
};
export function useFunctionalityLanguage(props: languageProps) {
  const { plaformType, companyId, shouldLanguageGetAllInvoke = false } = props;
  const userId = useAppSelector((state) => state.auth.profile?.id);
  const userRole = useAppSelector((state) => state.auth.profile?.userRole);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  // const companyId = AppRouteUi.UserCalendar.Scheduler.useParam();

  const languageCreateUrl = PlatformApi.Language.Create();
  const adminLanguageUrl = PlatformApi.Language.GetAll();
  // const companyLanguageUrl = PlatformApi.Language.

  const privateLanguageUrl = PlatformApi.Language.GetByUserId(
    userId ?? '',
    plaformType ?? PlatformType.All
  );
  // const publicLanguageUrl = PlatformApi.Vri.LanguagesByCompany.Root(
  //   companyId ?? ""
  // );
  const publicLanguageUrl = PlatformApi.Language.GetByCompanyId(
    companyId ?? '',
    plaformType ?? PlatformType.All
  );

  const languageDeleteUrl = PlatformApi.Language.Delete();

  let url: string = '';
  // Language list is global for all, this logic is no need
  // if (companyId) url = publicLanguageUrl;
  // else if (userRole === AppConstants.UserRoles.Admin) url = adminLanguageUrl;
  if (!!userRole && userRole !== AppConstants.UserRoles.Consumer)
    url = privateLanguageUrl;
  else url = publicLanguageUrl;

  const { data: apiLanguageData } = useSWR<ApiSchemaLanguageData[]>(
    isLoggedIn && shouldLanguageGetAllInvoke ? url : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLanguageData[]>>>(url)
        .then((r) =>
          r.data.data.data !== undefined ? r.data.data.data : r.data.data
        )
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const adminLanguageList = useMemo(() => {
    return apiLanguageData ? apiLanguageData : [];
  }, [apiLanguageData]);

  const languageList = useMemo(() => {
    const data = Array.isArray(apiLanguageData)
      ? OptionMapper.mapperFunc(apiLanguageData)
      : [];
    return data;
  }, [apiLanguageData]);

  const onAdd = useCallback(async (form: ApiSchemaLanguageData) => {
    const btnLoading = UtilsJQuery.Ladda('.login-from-submit-btn');
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.post(languageCreateUrl, form);

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(url);
        toast.success('Language added successfully');
      }
    } catch (e) {
      toast.error('Failed to add language.');
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onEdit = useCallback(
    async (editForm: ApiSchemaLanguageData, id: string) => {
      const btnLoading = UtilsJQuery.Ladda('.login-from-submit-btn');
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.put(
          PlatformApi.Language.Edit(id),
          editForm
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(url);
          toast.success('Language edited successfully');
        }
      } catch (e) {
        toast.error('Failed to edit language.');
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onDelete = useCallback(async (deleteList: string[]) => {
    const btnLoading = UtilsJQuery.Ladda('.delete-button');
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(languageDeleteUrl, {
        data: deleteList,
      });

      if (response.status === AppConstants.Api.StatusOK) {
        toast.success('Language deleted successfully');
        // mutate(pagedLanguageUrl);
      }
    } catch (e) {
      toast.error('Failed to delete language.');
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    languageList,
    adminLanguageList,
    onAdd,
    editLanguage: onEdit,
    onDelete,
  };
}

export function useFunctionalityLanguageListNames() {
  const userId = useAppSelector((state) => state.auth.profile?.id);
  const onLanguageListName = useCallback(async (plaformType?: PlatformType) => {
    const privateLanguageUrl = PlatformApi.Language.GetByUserId(
      userId ?? '',
      plaformType ?? PlatformType.All
    );
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(privateLanguageUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(companyProfileDetailsUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);
  return {
    onLanguageListName,
  };
}
export function useFunctionalityLanguageByCompanyId(props: languageProps) {
  const { plaformType, companyId } = props;
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const publicLanguageUrl = PlatformApi.Language.GetByCompanyId(
    companyId ?? '',
    plaformType ?? PlatformType.All
  );

  const { data: apiLanguageData } = useSWR<ApiSchemaLanguageData[]>(
    isLoggedIn && companyId ? publicLanguageUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaLanguageData[]>>>(
        publicLanguageUrl
      )
        .then((r) =>
          r.data.data.data !== undefined ? r.data.data.data : r.data.data
        )
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const languageList = useMemo(() => {
    const data = Array.isArray(apiLanguageData)
      ? OptionMapper.mapperFunc(apiLanguageData)
      : [];
    return data;
  }, [apiLanguageData]);

  return {
    languageList,
  };
}
