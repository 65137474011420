import { faMinusCircle, faPlusCircle, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import { SearchInterpreterForm } from ".";
import { Spinner } from "../../assets/Spinner";
import { ContactInfo } from "../../models/api/videoconference";
import { useFunctionalityDepartment, useFunctionalityLanguage, useFunctionalityLocation, useFunctionalitySkill } from "../../pages/hooks";
import { useFunctionalityVideoScheduler } from '../../pages/hooks/functionalityVideoScheduler';
import { useForm } from "react-hook-form";
import { AppRouteUi, CallInitiateParam, device, EmailValidation, ExtendedCriteriaType, PhoneValidation, USAPhoneNumberValidationRegex, ValidateEmailRegex } from "../../configs";
import { PlatformType } from "../../models";
import { ActionVideoCall, useAppDispatch, useAppSelector } from "../../core";
import { useFunctionalityGender } from "../../pages/hooks/functionalityOperatorCriteria";
import Select from "react-select";
import { FormErrorMessage } from "../../components";
import { timeZones } from "../modals/jobFormData";
import moment from "moment";
import { MediaPermission, UserMediaUtils } from "../../utils";
import { useHistory } from 'react-router-dom';

interface PropsType {
  pagedOperatorCriteriaList: any
}

const emailValidationMap = new Map();
const phoneValidationMap = new Map();

export function VideoConference({ pagedOperatorCriteriaList }: PropsType): JSX.Element {
  const { languageList } = useFunctionalityLanguage({ plaformType: PlatformType.VRI, shouldLanguageGetAllInvoke: true });
  const { optionMappedSkillList } = useFunctionalitySkill({ shouldSkillGetAllInvoke: true });
  const {
    state,
    generateButtonDisable,
    setGenerateButtonDisable,
    setRoomId,
    setContactInfo,
    setConferenceType,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setInterpreterRequired,
    setLanguage,
    setLanguageName,
    setSkill,
    setGender,
    setDepartment,
    setLocation,
    setCustomFields,
    setTimeZone,
    onCreateSchedule
  } = useFunctionalityVideoScheduler();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { twilioDeviceStatus, isBackdatedVersion } = useAppSelector((state) => state.common);
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";
  const companyId = useAppSelector((state) => state.auth.profile?.companyId || "");
  const { optionMappedGenderList } = useFunctionalityGender();
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: companyId, shouldLocationGetAllInvoke: true
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: companyId, shouldDepartmentGetAllInvoke: true
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<any>();
  const [errMsg, setErrMsg] = React.useState<string>();
  const clearErrMsg = React.useCallback(() => setErrMsg(undefined), []);

  const generateLink = React.useCallback(() => {
    batch(() => {
      setRoomId(uuidv4());
      setGenerateButtonDisable(true);
    })
    toast.success("Room key generated")
  }, []);

  useEffect(() => {
    return () => {
      emailValidationMap.clear();
      phoneValidationMap.clear();
    }
  }, []);

  const handleToggleButton = React.useCallback((isPlus: boolean) => {
    return isPlus ?
      <div className="d-flex">
        <div>
          <FontAwesomeIcon
            className="text-success"
            icon={faPlusCircle}
          />
        </div>

        <p className="my-auto ml-2 font-sz-12">Add</p>

      </div>
      :
      <div className="d-flex">
        <div>
          <FontAwesomeIcon
            className="text-danger"
            icon={faMinusCircle}
          />
        </div>

        <p className="my-auto ml-2 font-sz-12">Remove</p>

      </div>

  }, []);
  const handleCreateScheduleClick = async ()=> {
   if(state.conferenceType === 'instantConference') {
    if (twilioDeviceStatus === 'offline') {
      toast.error(
        'Please, try again after few seconds'
      );
      return;
    }
    const tracks = await UserMediaUtils.startMedia();
    if (!tracks) {
      history.push({
        pathname: AppRouteUi.OutgoingCallFailedForMedia.Root,
      });
      return;
    }
    dispatch(ActionVideoCall.setTracks(tracks));
   }
    onCreateSchedule();
  }
  const handleAdd = () => {
    const values = [...state.contactInfo];
    values.push({ email: null || "", phone: null || "" } as ContactInfo);
    setContactInfo(values);
  }

  const handleRemove = (i: number) => {
    const values = [...state.contactInfo];
    values.splice(i, 1);
    setContactInfo(values);

    const emailValidationMapSize = emailValidationMap?.size;
    for (let index = i; index < emailValidationMapSize; index++) {
      const previousMessage = emailValidationMap.get(index + 1);
      emailValidationMap.set(index, previousMessage);
    }

    const phoneValidationMapSize = phoneValidationMap?.size;
    for (let index = i; index < phoneValidationMapSize; index++) {
      const previousMessage = phoneValidationMap.get(index + 1);
      phoneValidationMap.set(index, previousMessage);
    }
  }

  const handleEmailChange = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let values = [...state.contactInfo];
    values[i].email = event.target.value;
    setContactInfo(values);
  }

  const handlePhoneChange = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let values = [...state.contactInfo];
    values[i].phone = event.target.value;
    setContactInfo(values);
  }

  const optionSkillUpdatedList = [
    {
      value: "",
      label: 'Any'
    },
    ...optionMappedSkillList,
  ];

  interface ITypeScheduleLaterFieldNameList {
    name: string;
    criteriaType: number;
    isMandatory: boolean;
  }

  const scheduleLaterFieldNameList: ITypeScheduleLaterFieldNameList[] = [
    { name: 'Language', criteriaType: 1, isMandatory: true },
    { name: 'Skill', criteriaType: 2, isMandatory: false },
    { name: 'Gender', criteriaType: 3, isMandatory: false },
  ]
  const generateRoomLink = (roomId: string) => {
    return `${rootUrl}/lobby/${roomId}`;
  };

  const handleScheduleConf = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConferenceType(e.target.value)
    setLanguage('')
    setLanguageName('')
    setSkill('')
    setGender('')
  }

  const validateEmail = (idx: number, e: any) => {
    if (e.target.value === "") {
      emailValidationMap.set(idx, "");
      return;
    }
    if (ValidateEmailRegex.test(e.target.value)) {
      emailValidationMap.set(idx, "");
    } else {
      if (e.target.value !== "") {
        emailValidationMap.set(idx, EmailValidation.Error);
      } else {
        emailValidationMap.set(idx, "");
      }
    }
  };

  const validateTeNumber = (idx: number, e: any) => {
    let phoneNumber = e.target.value;
    let phoneNumberForRegexCheck = phoneNumber?.replace(/\s+/g, "");
    if (phoneNumber?.length === 0) {
      phoneValidationMap.set(idx, "");
    }
    else if (phoneNumberForRegexCheck?.match(USAPhoneNumberValidationRegex)) {
      phoneValidationMap.set(idx, "");
    }
    else {
      phoneValidationMap.set(idx, PhoneValidation.Error);
    }
  }

  const checkEmailValidationFailed = () => {
    const values = Array.from(emailValidationMap.values());
    return values.includes(EmailValidation.Error);
  }

  const checkPhoneValidationFailed = () => {
    const values = Array.from(phoneValidationMap.values());
    return values.includes(PhoneValidation.Error);
  }

  const hasEmailValidationError = (id: number) => {
    const msg = emailValidationMap.get(id);
    return !!msg;
  }

  const hasPhoneValidationError = (id: number) => {
    const msg = phoneValidationMap.get(id);
    return !!msg;
  }

  const defaultTimeZoneUser = useAppSelector((state) => state.auth.profile?.timeZone);
  let defaultTime = timeZones.find((x) => x.value === defaultTimeZoneUser)

  return <>
    <label htmlFor="" className="text-muted font-sz-12">Conference Link</label>
    <div className="form-group row ">
      <div className="col-md-10 col-sm-12 col-12 pr-xl-0 pr-lg-0 pr-md-0">
        <input
          id="roomId"
          placeholder="Generate Link"
          value={state.roomId ? generateRoomLink(state.roomId) : ''}
          type="text"
          className="form-control"
          name="roomId"
          readOnly />
      </div>
      <div className="col-md-2 col-sm-12 col-12 my-3 my-sm-3 my-md-0 my-lg-0 my-xl-0">
        <button onClick={generateLink} className="btn btn-primary  w-100 text-uppercase " disabled={isBackdatedVersion || generateButtonDisable}>generate</button>
      </div>
    </div>



    <label htmlFor="" className="text-muted font-sz-12">Add or remove participants</label>
    {
      state.contactInfo.map((field, idx) => (
        <div key={idx} className="form-group row  flex-wrap">
         
            <div className="col-12 col-sm-12 col-md-10 flex-md-grow-1 flex-grow-1 flex-sm-grow-1">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                  <input
                    id="emails"
                    placeholder="Email"
                    type="text"
                    className="form-control"
                    name="email"
                    value={field.email ?? ''}
                    onChange={e => {
                      handleEmailChange(idx, e);
                      validateEmail(idx, e);
                    }}
                  />
                  {hasEmailValidationError(idx) && (
                    <FormErrorMessage message={emailValidationMap.get(idx)} />
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-md-0 form-group">
                  <input
                    id="phones"
                    placeholder="Phone"
                    type="text"
                    className="form-control"
                    name="phone"
                    value={field.phone ?? ''}
                    onChange={e => {
                      handlePhoneChange(idx, e);
                      validateTeNumber(idx, e);
                    }}
                  />
                  {hasPhoneValidationError(idx) && (
                    <FormErrorMessage message={phoneValidationMap.get(idx)} />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-2 flex-md-grow-0 flex-grow-1 flex-sm-grow-1">
              <button
                type="button"
                className="btn call-control-btn call-control-btn conference-btn m-auto px-0 py-1"
                data-toggle="button"
                aria-pressed="false"
                onClick={() => { idx == state.contactInfo.length - 1 ? handleAdd() : handleRemove(idx) }}
              >
                {handleToggleButton(idx == state.contactInfo.length - 1)}
              </button>
            </div>
         


        </div>
      ))
    }



    <label htmlFor="" className="text-muted font-sz-12">Schedule conference</label>
    <div className="form-group row ">
      <div className="col-12">
        <div className="form-check form-check-inline pb-2">
          <input
            className="form-check-input"
            type="radio"
            checked={state.conferenceType === 'instantConference'}
            name="conferenceType"
            id="instantConference"
            value="instantConference"
            data-toggle="collapse"
            data-target=".collapseContactInfo.show"
            onChange={handleScheduleConf}
          />
          <label htmlFor="inlineRadio1" className="form-check-label" >Start  now</label>
        </div>
        <div
          className="form-check form-check-inline pb-2"
        >
          <input
            className="form-check-input"
            type="radio"
            checked={state.conferenceType === 'scheduledConference'}
            name="conferenceType"
            id="scheduledConference"
            data-toggle="collapse"
            data-target=".collapseContactInfo:not(.show)"
            value="scheduledConference"
            onChange={handleScheduleConf}
          />
          <label htmlFor="inlineRadio2" className="form-check-label" >Schedule for later</label>
        </div>
      </div>
    </div>


    {state.conferenceType === 'scheduledConference' ?
      <div className="collapse collapseContactInfo" id="forLater">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-12 p-0">Start Date</label>
            <DatePicker
              className='form-control'
              dateFormat="yyyy-MM-dd"
              selected={new Date(state.startDate!)}
              onChange={(currentDate: Date | null) => {
                if (currentDate) {
                  setStartDate(moment(currentDate).format('yyyy-MM-DD'));
                }
              }}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-12 p-0">End Date</label>
            <DatePicker
              className='form-control'
              dateFormat="yyyy-MM-dd"
              selected={new Date(state.endDate!)}
              onChange={(currentDate: Date | null) => {
                if (currentDate) {
                  setEndDate(moment(currentDate).format('yyyy-MM-DD'));
                }
              }}
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-12 p-0">Start Time</label>
            <input
              name="startTime"
              type="time"
              className="form-control"
              onChange={e => setStartTime(e.target.value)}
              value={state.startTime}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-12 p-0">End Time</label>
            <input
              name="endTime"
              type="time"
              className="form-control"
              onChange={e => setEndTime(e.target.value)}
              value={state.endTime}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-12 p-0 required">Time Zone</label>
            <input
              className="d-none"
              {...register('timeZone', { required: true })}
              defaultValue={defaultTime?.value}
              style={{ border: errors.timeZone ? '1px solid red' : '' }} />
            <Select
              className={` ${errors.timeZone?.type == "required" ? 'field-required' : ''} `}
              placeholder="Select Time Zone"
              components={{
                IndicatorSeparator: () => null,
              }}
              defaultValue={timeZones.find((x) => x.value === defaultTimeZoneUser)}
              options={timeZones}
              onChange={(value) => {
                setValue("timeZone", value?.value, {
                  shouldValidate: true,
                });
                setTimeZone(value?.value);
              }}
            />
            {errors.timeZone && (
              <FormErrorMessage message="required" />
            )}
          </div>
        </div>
      </div>
      : null}



    <div className="form-group row">
      <div className="col-12">
        <div className="form-check form-check-inline pb-2">
          <input
            className="form-check-input"
            type="checkbox"
            checked={state.interpreterRequired}
            disabled={state.conferenceType === ''}
            name="interpreterRequired"
            id="interpreterRequired"
            data-toggle="collapse"
            data-target="#includeInterpreter"
            onChange={() => setInterpreterRequired(!state.interpreterRequired)}
          />
          <label htmlFor="inlineRadio3" className="form-check-label" > Include language interpreter</label>
        </div>
      </div>
    </div>

    {state.interpreterRequired ?
      <div className="collapse" id="includeInterpreter">
        {state.conferenceType === 'scheduledConference' ?
          <div className="form-row">
            {
              scheduleLaterFieldNameList?.map((scheduleLaterFieldName: ITypeScheduleLaterFieldNameList, index) =>
                <div className="form-group col-md-4 col-sm-12 col-12" key={index}>
                  <label htmlFor="" className={`text-muted font-sz-12 ${scheduleLaterFieldName.isMandatory ? 'required' : ''}`}>
                    {scheduleLaterFieldName.name}
                  </label>

                  <input
                    className="d-none"
                    {...register(
                      scheduleLaterFieldName.name,
                      { required: scheduleLaterFieldName.isMandatory },
                    )} />
                  <Select
                    className={` ${errors[scheduleLaterFieldName.name] ? 'field-required' : ''} `}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={
                      scheduleLaterFieldName.criteriaType === ExtendedCriteriaType.Language ? languageList :
                        scheduleLaterFieldName.criteriaType === ExtendedCriteriaType.Skill ? optionMappedSkillList :
                          scheduleLaterFieldName.criteriaType === ExtendedCriteriaType.Gender ? optionMappedGenderList : []
                    }
                    placeholder={`Select a ${scheduleLaterFieldName.name}`}
                    defaultValue={
                      scheduleLaterFieldName.criteriaType === ExtendedCriteriaType.Skill ? optionMappedSkillList ? optionSkillUpdatedList?.find(x => x.label === 'Any') : null :
                        scheduleLaterFieldName.criteriaType === ExtendedCriteriaType.Gender ? optionMappedGenderList ? optionMappedGenderList?.find(x => x.label === 'Any') : null : null
                    }
                    onChange={(option) => {
                      setValue(scheduleLaterFieldName.name, option?.value, {
                        shouldValidate: true,
                      });
                      if (scheduleLaterFieldName?.criteriaType === ExtendedCriteriaType.Language) {
                        setLanguage(option?.value);
                      }
                      if (scheduleLaterFieldName?.criteriaType === ExtendedCriteriaType.Skill) {
                        setSkill(option?.value);
                      }
                      if (scheduleLaterFieldName?.criteriaType === ExtendedCriteriaType.Gender) {
                        setGender(option?.value);
                      }
                      return scheduleLaterFieldName?.criteriaType === ExtendedCriteriaType.Language ? setLanguageName(option?.label ?? "") : ''
                    }
                    }
                  />

                  <span> {
                    errors[scheduleLaterFieldName.name] && <FormErrorMessage message="This field is required" />
                  }
                  </span>

                </div>
              )
            }
          </div>
          :
          <SearchInterpreterForm
            languageList={languageList}
            setLanguage={setLanguage}
            setLanguageName={setLanguageName}
            optionMappedSkillList={optionSkillUpdatedList}
            setSkill={setSkill}
            setGender={setGender}
            register={register}
            errors={errors}
            setValue={setValue}
            optionSkillListForDefaultValue={optionSkillUpdatedList}
            optionMappedGenderList={optionMappedGenderList}
            optionMappedDepartmentList={optionMappedDepartmentList}
            optionMappedLocationList={optionMappedLocationList}

            setDepartment={setDepartment}
            setLocation={setLocation}
            customFields={state.customFields}
            setCustomFields={setCustomFields}
            pagedOperatorCriteriaList={pagedOperatorCriteriaList}

          />
        }
      </div>
      : null}


    <div className="row  justify-content-center  flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row  mx-0">
    <div className="width-content mx-auto">
    <button
        onClick={handleCreateScheduleClick}
        className="btn video-call-btn-ctl mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 mx-0 my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2  p-0 direct-call-btn"
        disabled={isBackdatedVersion || checkEmailValidationFailed() || checkPhoneValidationFailed()}
      >

        <div className="d-flex align-items-center justify-content-center ">
          <div className="bg-purple text-white">
          <svg   x="0px" y="0px" viewBox="0 0 2400 2400">
                <g>
                    <polygon fill="currentColor" points="2395,627.8 1800.1,1021.9 1800.1,1471.8 2395,1816 	"/>
                  <path fill="currentColor" d="M1535.9,1791.8c0,40.9-33.1,74-74,74H79c-40.9,0-74-33.1-74-74V606.7c0-40,32.4-72.5,72.5-72.5 c0.5,0,1,0,1.5,0h1383c40-0.8,73.1,30.9,74,70.9c0,0.5,0,1,0,1.5V1791.8z"/>
              </g>
            </svg>

          </div>
          <p className="ml-2 my-0">
            {state.isBusy ? <Spinner fillHtmlCode="#000" /> : 'Create Schedule'}
          </p>

        </div>


      </button>
    </div>
    
    </div>
  </>;
}