import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiSchemaJobDataById } from "../../../models";

export type TSliceCalender = {
  viewType: string;
  showJobModal: boolean;
  selectedJob?: ApiSchemaJobDataById;
};

function InitSliceCalender(): TSliceCalender {
  return {
    viewType: 'timeGridDay',
    showJobModal: false,
  };
}

const sliceCalender = createSlice({
  name: "sliceCalender",
  initialState: InitSliceCalender(),
  reducers: {
    SetViewType: (
      state,
      action: PayloadAction<string>
    ): TSliceCalender => ({
      ...state,
      viewType: action.payload,
    }),
    SetShowJobModal: (
      state,
      action: PayloadAction<boolean>
    ): TSliceCalender => ({
      ...state,
      showJobModal: action.payload,
    }),
    SetSelectedJob: (
      state,
      action: PayloadAction<ApiSchemaJobDataById>
    ): TSliceCalender => ({
      ...state,
      selectedJob: action.payload,
    }),
    resetData: ()=> {
      return InitSliceCalender();
    }
  },
});

export const ReducerCalender = sliceCalender.reducer;
export const ActionCalender = sliceCalender.actions;
