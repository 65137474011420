import React, { useEffect, useState } from "react";
import { ChannelContext } from "../../../../core";
import { ApiResponseConversation, ApiResponseParticipant } from "../../../../models";
import { useFunctionalityChat } from "../../../../pages/hooks";
import { ConversationComponent } from "./CoversationComponent";
import { GroupChatDetail } from "./chatGroupDetail";
import { ProfileComponent } from "./profileComponent";


type Props = {
  chatUiOpen?: boolean
  convoDetail?: ApiResponseConversation,
  arrowclickHandler?: boolean,
}
export function ChatUI(props: Props): JSX.Element {
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false)
  const [addParticipant, setAddParticipant] = useState<boolean>(false)
  const [selectGroupDetail, setSelectGroupDetail] = useState<boolean>(false)
  const [groupParticipantList, selectGroupParticipantList] = useState<ApiResponseParticipant[]>([])

  const { selectedChannel, isBusy } = React.useContext(ChannelContext);
  const { onFetchGroupDetails } = useFunctionalityChat()


  useEffect(() => {

  }, [selectedChannel])

  useEffect(() => {
    onFetchGroupDetails(selectedChannel?.sid, selectGroupParticipantList)
  }, [selectedChannel, addParticipant, deleteModalShow]);


  return (
    <>
    
        <ProfileComponent selectGroupDetail={selectGroupDetail} setSelectGroupDetail={setSelectGroupDetail} arrowclickHandler={props?.arrowclickHandler?? false} />
        {!selectGroupDetail ? <ConversationComponent groupParticipantList={groupParticipantList} /> : <GroupChatDetail deleteModalShow={deleteModalShow} setDeleteModalShow={setDeleteModalShow} addParticipant={addParticipant} setAddParticipant={setAddParticipant} groupParticipantList={groupParticipantList} selectGroupParticipantList={selectGroupParticipantList} />
        }
     

    </>
  );
}

