import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Option } from "../../../models";
import Select from 'react-select';
import { FormErrorMessage } from "../../../components";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  header: string;
  dropDownList?: Option[];
  dropdownPlaceHolder?: string;
  onSelectItem?: any;
  onChangeHandler?: any;
  onSaveHandler?: any;
  packNameAsProp?: string;
  contactPersonId?: string;
  medicalHistoryAlreadyAdd?: string[]
};

export function ModalSingleDropdown(props: Props) {

  const { show, handleShow, dropDownList, dropdownPlaceHolder, onSelectItem, onChangeHandler, onSaveHandler, packNameAsProp, contactPersonId, medicalHistoryAlreadyAdd } = props;
  const [packageSelection, setPackageSelection] = useState(true);
  const getDefaultValue = () => {
    if (packNameAsProp) {
      return dropDownList ? dropDownList.find(x => x.label === packNameAsProp) : null;
    } else if(contactPersonId) {
      return dropDownList ? dropDownList.find(x => x.value === contactPersonId) : null;
    }
    return onSelectItem;
  }
  const [addVanccineErrMsg, setAddVanccineErrMsg] = useState("")
  const handleCheckDuplicate = (e: any) => {
    if (medicalHistoryAlreadyAdd?.find(item => item === e?.label)) {
      setAddVanccineErrMsg("Already added this vaccine");
      setPackageSelection(true)
    }
    else {
      setAddVanccineErrMsg("")
      setPackageSelection(false)
    }
  }
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <form >
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">

              {/* <select
                className="form-control"
                id=""
                defaultValue={onSelectItem}
                
                onChange={(selected) => {
                  onChangeHandler(dropDownList ? dropDownList[selected.target.selectedIndex].value : "");
                }}
              >
                {dropDownList?.map((option, index) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select> */}

              <input
                className="d-none "
                name="packName"
                style={{ border: addVanccineErrMsg ? '1px solid red' : '' }}
              // ref={register({ required: true })}

              />
              <Select
                placeholder={dropdownPlaceHolder ?? "Select Language Pack"}
                // defaultValue={step1Data?.stateCode ? stateList.find(x => x.value === step1Data.stateCode) : null}
                isDisabled={(dropDownList?.length === 0) ? true : false}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={getDefaultValue()}
                options={dropDownList}

                onChange={(selected) => {
                  setPackageSelection(false);
                  handleCheckDuplicate(selected);
                  onChangeHandler(dropDownList ? selected?.value : "");
                }}
              />
              {
                addVanccineErrMsg && (
                  <FormErrorMessage message={addVanccineErrMsg} />
                )
              }

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase "
            onClick={() => {
              setPackageSelection(true);
              props.handleShow(false);
              setAddVanccineErrMsg("")
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary text-uppercase login-from-submit-btn"
            onClick={onSaveHandler}
            disabled={ (!onSelectItem || packNameAsProp || addVanccineErrMsg !== "") ? packageSelection : false}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
