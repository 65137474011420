import {AppointedInterpreter} from "../../models";
import {useEffect, useState} from "react";

type Props = {
    appointedInterpreter: AppointedInterpreter,
    handleOnSelectInterpreter: any,
    changedAppointedOperators?: any,
    chooseMaxNoOfOperator?: number,
    allAppointedOperatorIsSelected?: boolean,
    setAllAppointedOperatorIsSelected?: any,
    trackUser?: any
}

export function ModalChooseInterpreterData(props: Props): JSX.Element {
    const { appointedInterpreter, handleOnSelectInterpreter, changedAppointedOperators,
        chooseMaxNoOfOperator, allAppointedOperatorIsSelected, setAllAppointedOperatorIsSelected, trackUser } = props;

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const alreadySelected = changedAppointedOperators.find((id: string) => id === appointedInterpreter?.operatorUserId);
        setIsChecked(alreadySelected)
    }, [])

    useEffect(() => {
        setAllAppointedOperatorIsSelected(changedAppointedOperators?.length === chooseMaxNoOfOperator ? true : false);
    }, [changedAppointedOperators]);

    return (
        <>
            <tr>
                <td scope="row">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input"
                               type="checkbox"
                               checked={isChecked}
                               disabled={allAppointedOperatorIsSelected && !trackUser.get(appointedInterpreter?.operatorUserId) && !isChecked ? true : false}
                               id={appointedInterpreter?.operatorUserId}
                               value={appointedInterpreter?.operatorUserId}
                               onChange={(event)=>{
                                   setIsChecked(!isChecked)
                                   handleOnSelectInterpreter(event)
                               }} />
                    </div>
                </td>
                <td scope="row">
                    {
                        appointedInterpreter?.distance ?? 'N/A'
                    }
                </td>
                <td scope="row">
                    {
                        appointedInterpreter?.name ?? '-'
                    }
                </td>
                <td scope="row">
                    {
                        appointedInterpreter?.phoneNumber ?? '-'
                    }
                </td>
                <td scope="row">
                    {
                        appointedInterpreter?.rate ?? 'N/A'
                    }
                </td>
            </tr>
        </>
    )
}