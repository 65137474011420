import React from "react";
import { PlatformApi } from "../../configs";
import useSWR from "swr";
import { ApiSchemaCountryData } from "../../models/api/country";
import { AxiosAuth } from "../../core";
import { OptionMapper } from "../../utils/optionMapper";
import { ApiResponse } from "../../models/api/common";

export function useFunctionalityCountry(showModal?: boolean) {
  const countryUrl = PlatformApi.Address.Countries();
  const { data: apiCountryData } = useSWR<ApiSchemaCountryData[]>(
    showModal ? countryUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaCountryData[]>>(countryUrl)
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const countryList = React.useMemo(() => {
    const data = Array.isArray(apiCountryData)
      ? OptionMapper.mapperFunc(apiCountryData)
      : [];
    return data;
  }, [apiCountryData]);
  return {
    countryList,
  };
}
