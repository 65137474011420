import React from 'react'
import useSWR from 'swr';
import { InvoiceType, PlatformApi, topLoaderProgress } from '../../configs';
import { AxiosAuth } from '../../core';
import { ApiResponse, FinanceProfileWithBalanceModalData } from '../../models';


type Props = {
    companyId?: string;
    operatorUserId?: string;
    setLoaderProgress?: any;
    // invoiceType: InvoiceType;
}
let BalancWithAddresseUrl: string;
export function useFunctionalityFinancialBalanceWithAddress(props: Props) {
    const { companyId, operatorUserId, setLoaderProgress } = props;
    if (companyId) {

        BalancWithAddresseUrl = PlatformApi.FinancialAccount.GetCompanyBalanceWithAddress(companyId);
    }
    else if (operatorUserId) {
        BalancWithAddresseUrl = PlatformApi.FinancialAccount.GetOperatorBalanceWithAddress(operatorUserId);
    }
    const { data: apiFinancialBalanceWithAddress } = useSWR<FinanceProfileWithBalanceModalData>(
        BalancWithAddresseUrl,
        () => {
            setLoaderProgress(topLoaderProgress.start);
            return AxiosAuth.get<
                ApiResponse<FinanceProfileWithBalanceModalData>
            >(BalancWithAddresseUrl)
                .then((r) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    return r.data.data;
                })
                .catch((e) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    console.error(e);
                    return e;
                });
        }
    );
    return {
        apiFinancialBalanceWithAddress,
        BalancWithAddresseUrl
    }
}
