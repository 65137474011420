import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioCallRouteParam } from "../../../models/audioCall";
 

export type TSliceCall = {
  audioCallStartTime?: string;
  audioCallEndTime?: string;
  audioCallStatus?: AudioCallRouteParam;
};

function InitSliceCall(): TSliceCall {
  return {
    audioCallStartTime: "",
    audioCallEndTime: "",
    audioCallStatus: {
      prev: 0,
      current: 0,
    },
  };
}

const sliceAudioCall = createSlice({
  name: "sliceAudioCall",
  initialState: InitSliceCall(),
  reducers: {
    SetAudioCallStartTime: (
      state,
      action: PayloadAction<string>
    ): TSliceCall => ({
      ...state,
      audioCallStartTime: action.payload,
    }),
    SetAudioCallEndTime: (
      state,
      action: PayloadAction<string>
    ): TSliceCall => ({
      ...state,
      audioCallEndTime: action.payload,
    }),

     
    SetAudioCallStatus: (
      state,
      action: PayloadAction<AudioCallRouteParam>
    ): TSliceCall => ({
      ...state,
      audioCallStatus: action.payload,
    }),
    resetData: ()=> {
      return InitSliceCall();
    }
  },
});

export const ReducerAudioCall = sliceAudioCall.reducer;
export const ActionAudioCall = sliceAudioCall.actions;
