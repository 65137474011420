import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, MenuItem, Typography, Select } from '@mui/material';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import { VideoCallContext } from '../../../../core';
import { twilioParticipantHelper } from '../../../../helpers';

const PREFIX = 'VideoInputList';

const classes = {
  preview: `${PREFIX}-preview`
};

const Root = styled('div')({
  [`& .${classes.preview}`]: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
});

const { getVideoTrack, getParticipantVideoTrack } = twilioParticipantHelper();

interface IVideoInputProp {
  selectedVideoTrack?: LocalVideoTrack;
  setSelectedVideoTrack: Dispatch<SetStateAction<LocalVideoTrack | undefined>>;
  setVideoInputDeviceChangeStatus: Dispatch<SetStateAction<boolean>>;
}
function VideoInputList({
  selectedVideoTrack,
  setSelectedVideoTrack,
  setVideoInputDeviceChangeStatus,
}: IVideoInputProp) {

  const [videoInputDevice, setVideoInputDevice] = useState<MediaDeviceInfo[]>(
    []
  );
  const { room } = useContext(VideoCallContext);
  const participantVideoTrack =
    room && getParticipantVideoTrack(room?.localParticipant);

  const selectVideoInputDevice: MediaDeviceInfo | undefined =
    videoInputDevice.find(
      (device) => device.label === participantVideoTrack?.mediaStreamTrack.label
    );

  async function replaceTrack(newDeviceId: string) {
    await selectedVideoTrack?.restart({
      deviceId: { exact: newDeviceId },
    });
    if (
      participantVideoTrack?.mediaStreamTrack.label ===
      selectedVideoTrack?.mediaStreamTrack.label
    ) {
      setVideoInputDeviceChangeStatus(false);
    } else {
      setVideoInputDeviceChangeStatus(true);
    }
  }

  useEffect(() => {
    let videoInputDevices: MediaDeviceInfo[];
    let initialVideoTrack: LocalVideoTrack;

    const getPreviewTrack = async () => {
      initialVideoTrack = await getVideoTrack({ additionalSupport: 'iOSApp' });
      const currentDevice = videoInputDevices?.find(
        (device) =>
          device.label === participantVideoTrack?.mediaStreamTrack.label
      );
      await initialVideoTrack.restart({
        deviceId: currentDevice?.deviceId,
      });
      setSelectedVideoTrack(initialVideoTrack);
    };

    const getVideoInputDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      videoInputDevices = mediaDevices.filter(
        (mediaDevice) => mediaDevice.kind === 'videoinput'
      );
      getPreviewTrack();
      setVideoInputDevice(videoInputDevices);
    };

    getVideoInputDevices();

    return () => {
      if (
        initialVideoTrack &&
        participantVideoTrack?.mediaStreamTrack.label ===
          initialVideoTrack?.mediaStreamTrack.label
      ) {
        initialVideoTrack.stop();
      }
    };
  }, [participantVideoTrack, setSelectedVideoTrack]);

  return (
    <Root>
      {selectedVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={selectedVideoTrack} />
        </div>
      )}
      {videoInputDevice.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Select
            onChange={(e) => replaceTrack(e.target.value as string)}
            defaultValue={selectVideoInputDevice?.deviceId}
            variant="outlined"
          >
            {videoInputDevice.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Typography>
            {selectedVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
          </Typography>
        </>
      )}
    </Root>
  );
}

export default VideoInputList;
