import React from "react";
import { AppRouteUi } from "../../../configs";
import { PageLanguagePacks } from "../../../pages";

export function ResellerPackageTab() {
  const companyId = AppRouteUi.Reseller.Profile.useParam();
  return (
    <>
      <div className="row flex-wrap mb-3">
        <div className="col">
          <PageLanguagePacks parentCompanyId={companyId ?? ""} />
        </div>
      </div>
    </>
  );
}
