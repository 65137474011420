import React from "react";
import useSWR from "swr";
import { PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiSchemaStateData, ApiResponse } from "../../models";
import { OptionMapper } from "../../utils";

export function useFunctionalityDirectCall(currentUserCompanyId: string) {
  const directCallNameUrl = PlatformApi.Users.GetNames(currentUserCompanyId)
  const { data: apiStateData } = useSWR<ApiSchemaStateData[]>(directCallNameUrl, () =>
  AxiosAuth.get(PlatformApi.Users.GetNames(currentUserCompanyId))
      .then((r) => r.data.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const nameList = React.useMemo(() => {
    const data = Array.isArray(apiStateData)
      ? OptionMapper.mapperFunc(apiStateData)
      : [];
    return data;
  }, [apiStateData]);

  return {
    nameList,
  };
}
