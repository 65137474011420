import {OptionMapper} from "../../utils";

export function useFunctionalityGender() {
    const genderList = [
        {
            id:0,
            name:'Any'
        },
        {
            id:1,
            name:'Male'
        },
        {
            id:2,
            name:'Female'
        }
    ];

    const optionMappedGenderList = Array.isArray(genderList)
        ? OptionMapper.mapperFunc(genderList)
        : [];

    return {
        optionMappedGenderList
    };
}
