import { Modal } from "react-bootstrap";
import { ApiSchemaSupportedPLatform } from "../../models";

type Props = {
    show: boolean;
    handleShow: (value: boolean) => void;
    onSaveHandler: any;
    supportedPlatformData: ApiSchemaSupportedPLatform,
    setVriToggleHandler: any,
    setOpiToggleHandler: any;
    setOnsiteToggleHandler: any;
};

export function ModalSupportPlatform(props: Props): JSX.Element {
    const { show, handleShow, onSaveHandler, supportedPlatformData, setVriToggleHandler, setOpiToggleHandler, setOnsiteToggleHandler } = props;
    return (
        <Modal show={show} onHide={() => handleShow(false)} centered>
            {/*@ts-ignore */}
                    {/*@ts-ignore */}
        <Modal.Header closeButton>
                <Modal.Title>Change Support Type</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    <div className="col-12 col-sm-4 mb-4 mb-sm-0">
                        <button
                            type="button"
                            data-toggle="button"
                            className={supportedPlatformData?.isVriEnabled ? 'btn btn-toggle active' : 'btn btn-toggle'}
                            onClick={() => { setVriToggleHandler(!supportedPlatformData?.isVriEnabled) }}
                        >
                            <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label">VRI</span>
                    </div>
                    <div className="col-12 col-sm-4 mb-4 mb-sm-0">
                        <button
                            type="button"
                            data-toggle="button"
                            className={supportedPlatformData?.isOpiEnabled ? 'btn btn-toggle active' : 'btn btn-toggle'}
                            onClick={() => { setOpiToggleHandler(!supportedPlatformData?.isOpiEnabled) }}
                        >
                            <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label">OPI</span>
                    </div>
                    <div className="col-12 col-sm-4 mb-4 mb-sm-0">
                        <button
                            type="button"
                            data-toggle="button"
                            className={supportedPlatformData?.isOnSiteEnabled ? 'btn btn-toggle active' : 'btn btn-toggle'}
                            onClick={() => { setOnsiteToggleHandler(!supportedPlatformData?.isOnSiteEnabled) }}
                        >
                            <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label">OnSite</span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-link text-uppercase text-site" onClick={() => handleShow(false)}>Cancel</button>
                <button className="btn btn-primary text-uppercase form-submit-btn" onClick={() => { onSaveHandler(); }}>Save</button>
            </Modal.Footer>
        </Modal>
    );
}
