import React, { useEffect } from "react";
import { ApiSchemaCompanyByIdData, CompanyAppointmentCategoryRequestModel, CompanyAppointmentCategoryResponseModel, } from "../../../models";
import { useFunctionalityAppointmentCategoryTab, useFunctionalityCompany } from "../../../pages/hooks";
import { useState } from "react";
import { AppConstants, AppRouteUi } from "../../../configs";
import { useAppSelector } from "../../../core";
import LoadingBar from "react-top-loading-bar";
import { NoDataComponent } from "../../admin/noDataComponent";
import { AppointmentCategoryResponseModel } from "../../../models/api/modality";
import { mutate } from "swr";
type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
};
export function AppointmentCategoryTab({ editable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.Company.Profile.useParam();
  const resellerId = AppRouteUi.Reseller.Profile.useParam();
  const [companyAppointmentTypeDetails, setCompanyAppointmentTypeDetails] = useState<AppointmentCategoryResponseModel[]>();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const userRole = useAppSelector((state) => state.auth.profile?.userRole);
  const [isLoadingAppointmentCategory, setIsAppointmentCategory] = useState<boolean>(false);

  const { LoadAppointmentCategory, pagedAppointmentCategoryUrl } = useFunctionalityAppointmentCategoryTab({
    CompanyId: companyId ? companyId : resellerId,
    setLoaderProgress,
  });

  useEffect(() => {
    LoadAppointmentCategory().then((data) => {
      setCompanyAppointmentTypeDetails(data);
      setIsAppointmentCategory(true);
    });
  }, [onUpdateSuccess])

  const companyAppointmentCategories: AppointmentCategoryResponseModel[] =
    companyAppointmentTypeDetails || [];
  const { onUpdateCompanyAppointmentCategory } = useFunctionalityCompany({
    companyId: companyId ? companyId : resellerId
  });
  const checkIsAllEnabled = () => {
    const notAllAppointmentCategorysEnabled = companyAppointmentCategories.find((x) => x.status === false);
    if (notAllAppointmentCategorysEnabled) return false;
    return true;
  };

  let isAll: boolean = checkIsAllEnabled();
  useEffect(() => {
  }, [onUpdateSuccess]);

  const onAppointmentCategoryToggleHandler = (appointmentCategory: AppointmentCategoryResponseModel) => {
    appointmentCategory = { ...appointmentCategory, status: !appointmentCategory.status };
    const body: CompanyAppointmentCategoryRequestModel = {
      appointmentCategoryId: appointmentCategory.id,
      status: appointmentCategory.status,
    };
    onUpdateCompanyAppointmentCategory(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      mutate(pagedAppointmentCategoryUrl)
    });
  };
  const onAllUpdateToggle = () => {
    isAll = !isAll;
    const body: CompanyAppointmentCategoryRequestModel = {
      isAll: true,
      status: isAll
    };
    onUpdateCompanyAppointmentCategory(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      mutate(pagedAppointmentCategoryUrl)
    });
  };
  const showAppointmentTypeName = (appointmentType: CompanyAppointmentCategoryResponseModel) => {
    if (userRole === AppConstants.UserRoles.CompanyAdmin) {
      if (appointmentType?.status) return true;
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {
        !isLoadingAppointmentCategory ? <NoDataComponent title="Loading..." />
          :
          <>
            {
              companyAppointmentTypeDetails?.length! > 0 ?
                <div className="pt-3 pb-3 pb-sm-0 ">
                  <div className="component-card mb-3">
                    <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right flex-wrap">
                      <div className="col">{editable ? 'Add AppointmentCategorys to this Company profile-VRI & OPI' : 'Available appointmentCategorys for VRI&OPI'} </div>
                      {(companyAppointmentTypeDetails && companyAppointmentTypeDetails?.length > 0 && editable) && (
                        <div className="col text-right">
                          <button
                            type="button"
                            className={
                              checkIsAllEnabled() ? "btn btn-toggle active" : "btn btn-toggle"}
                            onClick={() => {
                              onAllUpdateToggle();
                            }}
                          >
                            <div className="handle"></div>
                          </button>
                          <span className="toggle-button-label">All</span>
                        </div>
                      )}
                    </div>
                    <div className="component-card-body border">
                      <div className="row flex-wrap px-3">
                        {companyAppointmentTypeDetails?.map((appointmentCategory, index) => (
                          <>
                            {
                              showAppointmentTypeName(appointmentCategory) && <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 my-3" key={appointmentCategory.id}>
                                {editable && (
                                  <button
                                    type="button"
                                    className={
                                      appointmentCategory?.status ? "btn btn-toggle active" : "btn btn-toggle"
                                    }
                                    onClick={() => {
                                      onAppointmentCategoryToggleHandler(appointmentCategory);
                                    }}
                                  >
                                    <div className="handle"></div>
                                  </button>
                                )}
                                <span className="toggle-button-label">
                                  {appointmentCategory?.name}
                                </span>
                              </div>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                : <NoDataComponent title="No Data Found" />
            }
          </>
      }
    </>
  );
}
