import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormErrorMessage } from '../../../components';
type Props = {
    show: boolean,
    handleShow: (value: boolean) => void,
    header: string,
    onSaveHandler: any,
    onChangeHandler: any,
    inputValue: any,
    inputMsg?: string,
    type?: string,
    errMsg?: string,
}
export default function ModalInputElement(props: Props) {
    const { show, handleShow, errMsg, header, inputMsg, type, onSaveHandler, inputValue, onChangeHandler } = props;
    const onSubmitHandler = (value: string) => {
        onSaveHandler(value);
        handleShow(false);
    };
    const onMailReportHandler = (value: string) => {
        if (inputValue !== "" && errMsg === "") {
            onSaveHandler(value);
            handleShow(false);
        };
    }

    return (
        <Modal
            show={show}
            onHide={() => handleShow(false)}
            centered
        >
                    {/*@ts-ignore */}
        <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className=" form-group">

                            <span>{inputMsg ?? ""}</span>
                            <input name="name" type="text" className="form-control"
                                defaultValue={inputValue} onChange={(e) => { onChangeHandler(e) }}
                            />
                        </div>
                        <FormErrorMessage message={errMsg ?? ""} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-link text-site text-uppercase"
                    onClick={() => { handleShow(false) }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="skill-form-submit-btn btn btn-primary text-uppercase"
                    onClick={() => {
                        if (type == "mail") onMailReportHandler(inputValue)
                        else onSubmitHandler(inputValue)
                    }}
                >
                    {type === "mail" ? "Send" : "Save"}
                </button>
            </Modal.Footer>
        </Modal>
    )
}
