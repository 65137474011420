
import React, { useCallback } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, AppRouteUi, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import {
  BossContract
} from "../../models";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityBossSystem() {
  const onUpdateBossSystem = useCallback(async (id: string, contractId: any, form: BossContract) => {
    //  const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    const url = PlatformApi.CompanyContract.UpdateBossSystem(contractId)
    const btnLoading = UtilsJQuery.Ladda(".btn-update-boss");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.put<BossContract>(
        url,
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        //  mutate(resellerUrlToMutate);
        toast.success("Boss system updated successfully");
        btnLoading.stop?.();
      }
    } catch (e) {
      toast.error("Boss system update failed");
      btnLoading.stop?.();
    }


    return Promise.resolve(null);
  }, []);

  return {
    onUpdateBossSystem,
  };

}