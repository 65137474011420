export function PageNotFound(): JSX.Element {
  return (
    <div className="site-call-page">
      <div className="site-call-page-wrapper bg-purple">
        <div className="container-fluid h-100 d-flex align-items-center justify-content-center ">
          <div className="row ">
            <div className="col-12 text-center p-5">
              <h1 className="text-white">404</h1>
            </div>
            <div className="col-12 text-center">
              <h5 className="text-white"> Page not found</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
