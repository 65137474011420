import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select, { createFilter } from "react-select";
import { FooterButtons } from "..";
import { StepIndicatorTabs } from "../../..";
import { FormErrorMessage } from "../../../../components";
import { CompanyType, PlatformApi, StepNumber, DefaultCountry, reactSelectFilterConfig, InputFieldValidationErrorMessage, } from "../../../../configs";
import { ActionCompany, AxiosAuth, useAppSelector } from "../../../../core";
import { ApiResponse, Option, TypeCreateCompanyStep1, } from "../../../../models";
import { ExistModel } from "../../../../models/api/Operator";
import { useFunctionalityCountry, useFunctionalityState, useFunctionalityZipcodeValidation } from "../../../../pages/hooks";
import { useFunctionalityInputChecker } from "../../../../pages/hooks/functionalityInputChecker";
import { useFunctionalityAddressFieldValidation } from "../../../../utils";
type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
  countryList?: any;
  show?: boolean
};

export function Step1CreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType, show } = props;
  const [CountryFieldkey, setCountryFieldKey] = useState(Date.now());
  const { countryList } = useFunctionalityCountry(show);
  useEffect(() => { setCountryFieldKey(Date.now()) }, [countryList]);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<TypeCreateCompanyStep1>();
  const [codeLengthErrMsg, setCodeLengthErrMsg] = useState("");
  const dispatch = useDispatch();
  const step1Data: TypeCreateCompanyStep1 | undefined = useAppSelector(
    (state) => state.company.companyStep1
  );

  const [selectedCountry, setSelectedCountry] = React.useState(step1Data?.countryCode || DefaultCountry.USA);
  const [selectedZipcode, setSelectZipcode] = React.useState<string>(step1Data?.zipCode ? step1Data?.zipCode : '');
  const [selectedState, setSelectedState] = React.useState<string>(step1Data?.state ? step1Data?.state : '');
  const { stateList } = useFunctionalityState(selectedCountry, show);
  const [code, setCode] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const { handleAddressLength, addressLengthError } = useFunctionalityAddressFieldValidation()


  const onCountryChangeHandler = (value: Option | null) => {
    setValue("country", value?.label ? value?.label: "", {
      shouldValidate: true,
    });
    setValue("countryCode", value?.value, {
      shouldValidate: true,
    });
    setSelectedCountry(value ? value.value : "");
  };

  const onSubmitStep1 = (data: TypeCreateCompanyStep1) => {
    data = {
      ...data,
      name: data.name.trim()
    }
    if (errorMsg === "" && zipCodeError === "") {
      dispatch(ActionCompany.SetCompanyStep1(data));
      setStepCounter(stepCounter + 1);
    }
  };

  const codeLengthValidation = (value: any) => {
    if (value.length < 4) {
      setCodeLengthErrMsg("Please enter atleast 4 characters");
    } else {
      setCodeLengthErrMsg("");
    }
  };

  const checkExistence = (code: string) => {
    setCode(code);
    const codeUrl = PlatformApi.Company.IsCodeExist(code);
    AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        let temp: ExistModel = r.data?.data;
        if (r.data?.data) {
          setErrorMsg("Company code already exist");
        } else {
          setErrorMsg("");
        }
        return r.data?.data;
      })
      .catch((e) => {
      });
  };
  const generateCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    var value: string = e.target.value.toString();
    value = value.slice(0, 4);
    value = value.toUpperCase();
    setCode(value);
    checkExistence(value);
  };
  const handleState = (event: any) => {
    setSelectedState(event.label);
  };
  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value)
  }
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry,
    selectedState,
    selectedZipcode
  });
  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode])

  return <>
    <form onSubmit={handleSubmit(onSubmitStep1)} id="companyCreateForm">
      <Modal.Body>
        <div className=" component-padding border border-gry">
          <div className="row">
            <div className="col-12">
              <StepIndicatorTabs
                step={stepCounter || StepNumber.firstStep}
                companyType={companyType}
              />

              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Company Name
                </label>
                <input
                  type="text"
                  {...register('name', {
                    required: true,
                    validate: useFunctionlityInputFieldValidation
                    //validate: handleSpecialCharacterWhiteSpaces,
                  })}
                  className="form-control"
                  placeholder="Company Name"
                  defaultValue={step1Data?.name}
                  onChange={(e) => {
                    generateCode(e);
                  }}
                  style={{ border: errors.name ? '1px solid red' : '' }} />
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
                {errors.name && errors.name.type == "required" && (<FormErrorMessage message="required" />)}

              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Company Code
                </label>
                <input
                  type="text"
                  {...register('code', {
                    required: true,
                    validate: handleSpecialCharacterWhiteSpaces
                  })}
                  className="form-control"
                  placeholder="Company Code"
                  value={code ?? step1Data?.code}
                  style={{ border: errors.code ? '1px solid red' : '' }}
                  onChange={(e) => {
                    checkExistence(e.target.value);
                    codeLengthValidation(e.target.value);
                  }} />
                {errors.code && errors.code.type == "validate" && (
                  <FormErrorMessage message="Please enter valid value" />
                )}

                {errors.code && errors.code.type == "required" && (
                  <FormErrorMessage message="required" />
                )}
                {errorMsg && <FormErrorMessage message={errorMsg} />}

                {codeLengthErrMsg && (
                  <FormErrorMessage message={codeLengthErrMsg} />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Address
                </label>
                <input
                  type="text"
                  {...register('address', {
                    required: true, minLength: 2, maxLength: 128
                  })}
                  className="form-control"
                  placeholder="Address"
                  defaultValue={step1Data?.address}
                  style={{ border: errors.address || addressLengthError ? '1px solid red' : '' }}
                  onChange={handleAddressLength} />
                {errors.address && errors.address?.type == "required" && (
                  <FormErrorMessage message="required" />
                )}
                {
                  addressLengthError && <FormErrorMessage message={addressLengthError} />
                }
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      City
                    </label>
                    <input
                      type="text"
                      {...register('city', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces,
                      })}
                      className="form-control"
                      placeholder="City"
                      defaultValue={step1Data?.city}
                      style={{ border: errors.city ? '1px solid red' : '' }} />
                    {errors.city && errors.city.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )}
                    {errors.city && errors.city.type == "required" && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      State
                    </label>
                    <input
                      className="d-none"
                      {...register('state', { required: true })}
                      defaultValue={step1Data?.state} />
                    <input
                      className="d-none"
                      {...register('stateCode', { required: true })}
                      defaultValue={step1Data?.stateCode} />
                    <Select
                      className={` ${errors.stateCode?.type == "required" ? 'field-required' : ''} `}

                      placeholder="Select state"
                      defaultValue={
                        step1Data?.stateCode
                          ? stateList.find(
                            (x) => x.value === step1Data.stateCode
                          )
                          : null
                      }
                      isDisabled={stateList.length === 0 ? true : false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={stateList}
                      filterOption={createFilter(reactSelectFilterConfig)}
                      onChange={(value) => {
                        handleState(value);
                        setValue("state", value?.label ? value?.label : "", {
                          shouldValidate: true,
                        });
                        setValue("stateCode", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />

                    {errors.stateCode &&
                      errors.stateCode.type == "required" && (
                        <FormErrorMessage message="required" />
                      )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Zip
                    </label>
                    <input
                      type="text"
                      {...register('zipCode', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces,
                      })}
                      className="form-control"
                      placeholder="Zip"
                      defaultValue={step1Data?.zipCode}
                      style={{ border: errors.zipCode ? '1px solid red' : '' }}
                      onChange={(event) => { handleZip(event) }} />
                    {
                      zipCodeError && (
                        <FormErrorMessage message={zipCodeError} />
                      )
                    }
                    {errors.zipCode && errors.zipCode.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )}
                    {errors.zipCode && errors.zipCode.type == "required" && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Country
                    </label>
                    <input
                      className="d-none"
                      {...register('country', { required: true })}
                      defaultValue={
                        step1Data?.country
                          ? step1Data?.country
                          : "United States"
                      } />

                    <input
                      className="d-none"
                      {...register('countryCode', { required: true })}
                      defaultValue={
                        step1Data?.countryCode
                          ? step1Data?.countryCode
                          : DefaultCountry.USA
                      } />

                    <Select
                      key={CountryFieldkey}
                      className={` ${errors.countryCode?.type == "required" ? 'field-required' : ''} `}

                      placeholder="Select country"
                      isDisabled={countryList?.length === 0 ? true : false}
                      defaultValue={
                        step1Data?.countryCode
                          ? countryList?.find(
                            (x: { value: string }) =>
                              x.value === step1Data.countryCode
                          )
                          : countryList?.find(
                            (x: { value: string }) =>
                              x.value === DefaultCountry.USA
                          )
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={countryList}
                      filterOption={createFilter(reactSelectFilterConfig)}
                      onChange={(value) => {
                        setValue("stateCode", "", {
                          shouldValidate: true,
                        });
                        onCountryChangeHandler(value);
                      }}
                    />

                    {errors.countryCode &&
                      errors.countryCode.type == "required" && (
                        <FormErrorMessage message="required" />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <FooterButtons
          counter={stepCounter || StepNumber.firstStep}
          setCounter={setStepCounter}
          handleShow={handleShow}
          reset={reset}
        />
      </Modal.Footer>
    </form>
  </>;
}
