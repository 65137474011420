import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  ActionAuth,
  ActionCalender,
  ActionVideoCall,
  ActionCompany,
  ActionCompanyContract,
  ActionNotification,
  ActionUi,
} from '../../core';

export function useReduxHelper() {
  const dispatch = useDispatch();

  const resetAllReduxData = useCallback(() => {
    dispatch(ActionAuth.resetData());
    dispatch(ActionCalender.resetData());
    dispatch(ActionVideoCall.resetData());
    dispatch(ActionCompany.resetData());
    dispatch(ActionCompanyContract.resetData());
    dispatch(ActionNotification.resetData());
    dispatch(ActionUi.resetData());
  }, [dispatch]);

  return {
    resetAllReduxData,
  };
}
