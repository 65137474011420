import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  AppRouteUi,
  OpiApi,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  ApiSchemaCreateVendorCompany,
  ApiSchemaVendorCompanyData,
  WithPagination,
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { ApiSchemaChangeCallForwardingOption } from "../../models/api/vendor";

type Props = {
  companyId: string;
  showModal?: boolean;
  shouldVendorCompanyGetAllInvoke?: boolean;
};
type pageVendorProps = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
};
let pagedVendorCompanyUrl: string;
export function useFunctionalityPagedVendorCompany(props: pageVendorProps) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;

  const [pagedVendorCompanyListCount, setPagedVendorCompanyListCount] =
    useState(0);
  const vendorId = AppRouteUi.Vendors.Profile.useParam();
  pagedVendorCompanyUrl = OpiApi.Vendors.GetCompanyById(
    vendorId,
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiPagedVendorCompanyList } = useSWR<
    ApiSchemaVendorCompanyData[]
  >(pagedVendorCompanyUrl, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<
      ApiResponse<WithPagination<ApiSchemaVendorCompanyData[]>>
    >(pagedVendorCompanyUrl)
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        setPagedVendorCompanyListCount(r.data.data.count);
        return r.data.data.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        // toast.error("Failed to load vendor data.");
        return e;
      });
  });

  const pagedVendorCompanyList = useMemo(() => {
    const data = Array.isArray(apiPagedVendorCompanyList)
      ? apiPagedVendorCompanyList
      : [];
    return data;
  }, [apiPagedVendorCompanyList]);

  return {
    pagedVendorCompanyList,
    pagedVendorCompanyListCount,
    pagedVendorCompanyUrl,
  };
}
export function useFunctionalityVendorCompany(props: Props) {
  const {
    companyId,
    showModal,
    shouldVendorCompanyGetAllInvoke = false,
  } = props;
  const vendorId = AppRouteUi.Vendors.Profile.useParam();
  const vendorCompanyUrl = OpiApi.Vendors.GetAllCompanyById(vendorId);
  const companyWithoutVendorUrl =
    OpiApi.Vendors.GetCompanyWithoutVendor(companyId);

  const { data: apiCompanyListWithoutVendor } = useSWR<
    ApiSchemaVendorCompanyData[]
  >(showModal ? companyWithoutVendorUrl : null, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaVendorCompanyData[]>>>(
      companyWithoutVendorUrl
    )
      .then((r) => {
        return r.data.data.data;
      })
      .catch((e) => {
        console.log(e);
        return e;
      })
  );

  const companyListWithoutVendor = React.useMemo(() => {
    return Array.isArray(apiCompanyListWithoutVendor)
      ? OptionMapper.mapperFunc(apiCompanyListWithoutVendor)
      : [];
  }, [apiCompanyListWithoutVendor]);

  const { data: apiVendorCompanyData } = useSWR<ApiSchemaVendorCompanyData[]>(
    shouldVendorCompanyGetAllInvoke ? vendorCompanyUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaVendorCompanyData[]>>>(
        vendorCompanyUrl
      )
        .then((r) => {
          return r.data.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const vendorCompanyList = React.useMemo(() => {
    return Array.isArray(apiVendorCompanyData) ? apiVendorCompanyData : [];
  }, [apiVendorCompanyData]);

  const onDelete = React.useCallback(async (companyId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.delete<ApiSchemaCreateVendorCompany>(
        OpiApi.Vendors.DeleteCompany(vendorId, companyId)
      );
      if (res.status === AppConstants.Api.StatusOK) {
        toast.success("Company deleted successfully");
      }
    } catch (e) {
      toast.error("Company delete failed");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onChangeCallForwardingOption = React.useCallback(
    async (data: ApiSchemaChangeCallForwardingOption) => {
      try {
        const res = await AxiosAuth.post<ApiSchemaChangeCallForwardingOption>(
          OpiApi.Vendors.ChangeCallForwardingOption(
            data.companyId,
            data.isCallForwardingEnabled
          )
        );
        if (res.status === AppConstants.Api.StatusOK) {
          mutate(vendorCompanyUrl);
          toast.success("Call forwarding status updated");
        }
      } catch (e) {
        toast.error("Call forwarding status update failed");
      }

      return Promise.resolve(null);
    },
    []
  );

  const onAddCompany = React.useCallback(
    async (form: ApiSchemaCreateVendorCompany) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();
      try {
        const res = await AxiosAuth.post<ApiSchemaCreateVendorCompany>(
          OpiApi.Vendors.CreateCompany(vendorId, form.companyId)
        );
        if (res.status === AppConstants.Api.StatusOK) {
          toast.success("Company added successfully");
        }
      } catch (e) {
        toast.error("Adding company failed");
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    vendorCompanyList,
    companyListWithoutVendor,
    onAddCompany,
    onDelete,
    onChangeCallForwardingOption,
    companyWithoutVendorUrl,
  };
}
