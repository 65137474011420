import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
type Props = {
  custom1Name: string | "";
  custom1Value: string | "";
  custom2Name: string | "";
  custom2Value: string | "";
  custom3Name: string | "";
  custom3Value: string | "";
  custom4Name: string | "";
  custom4Value: string | "";
};
export function CallSummaryCustomField(props: Props): JSX.Element {
  const {
    custom1Name,
    custom1Value,
    custom2Name,
    custom2Value,
    custom3Name,
    custom3Value,
    custom4Name,
    custom4Value,
  } = props;
  return (
    <>
      <div className="card">
        <div className="card-header px-0 py-1" id="headingCustomInput">
          <button
            className="btn btn-link btn-block text-left text-decoration-none text-site siteAccordion collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#custom-input-collapse"
            aria-expanded="true"
            aria-controls="custom-input-collapse"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="font-weight-bold">Custom Fields Input </span>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="closed-panel-icon"
                />
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="open-panel-icon"
                />
              </div>
            </div>
          </button>
        </div>
        <div
          className="collapse"
          id="custom-input-collapse"
          aria-labelledby="headingCustomInput"
          data-parent="#call-summary-panels"
        >
          <div className="card-body px-3 py-2">
            <div className="row mt-1">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="row mb-2">
                  <div className="col-6">{custom1Name}:</div>
                  <div className="col-6 text-muted">
                    {custom1Value ?? "N/A"}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-6">{custom2Name}:</div>
                  <div className="col-6 text-muted">
                    {custom2Value ?? "N/A"}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-6">{custom3Name}:</div>
                  <div className="col-6 text-muted">
                    {custom3Value ?? "N/A"}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 border-sm-top">
                <div className="row mb-2">
                  <div className="col-6">{custom4Name}:</div>
                  <div className="col-6 text-muted">
                    {custom4Value ?? "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
