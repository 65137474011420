import React, { useState } from 'react';
import { ModalConfirmDelete, ModalUploadCertificate } from '..';
import { AppRouteUi, FileBucketURL, OperatorCriteriaType } from '../../configs';
import { ApiSchemaOperatorSkill, Option } from '../../models';
import {
  useFunctionalityOperatorProfile,
  useFunctionalitySkill,
} from '../../pages/hooks';
import {
  certificateExpirationInDays,
  certificateIsExpired,
  dateFormatInUSA,
} from '../../utils';
import { ModalSingleDropdown } from '../modals';
import { toast } from 'react-toastify';
import { AssetSvg } from '../../assets';

type Props = {
  readonly: boolean;
  operatorId?: string;
};

export function Skills(props: Props) {
  const [deleteModalShow, handleDeleteModalShow] = useState<boolean>(false);
  const [skillModalShow, handleSkillModalShow] = useState<boolean>(false);
  const [operatorSkillIdToDelete, setOperatorSkillIdToDelete] =
    useState<string>('');
  const [skillModalHeader, setSkillModalHeader] = useState<string>('');
  const [submitType, setSubmitType] = useState<string>('');
  const [skillId, setSkillId] = useState<string>('');
  const [operatorSkillId, setOperatorSkillId] = useState<string>('');
  const [criteriaId, setCriteriaId] = useState('');
  const [dropdownPlaceHolder, setDropdownPlaceHolder] = useState('');
  const operatorId = props.operatorId
    ? props.operatorId
    : AppRouteUi.Operator.Profile.useParam();
  const [initialOnSelectDropdownSkill, setInitialOnSelectDropdownSkill] =
    useState<any>('');
  const {
    operatorSkillList,
    onUpdateOperatorSkill,
    onDeleteOperatorSkill,
    onCreateOperatorSkill,
  } = useFunctionalityOperatorProfile({ operatorId });
  const [showUploadCertificateModal, handleShowUploadCertificateModal] =
    useState<boolean>(false);
  const { optionMappedSkillList } = useFunctionalitySkill({
    shouldSkillGetAllInvoke: skillModalShow,
  });

  function forceDownload(url: string | URL, fileName: string) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  let remainingDropDownList: Option[] = [];

  const getDropDownList = () => {
    const ignoreDropDownListId = operatorSkillList?.map(
      (operatorSkill: ApiSchemaOperatorSkill) => operatorSkill?.skill?.id
    );
    remainingDropDownList =
      optionMappedSkillList?.filter(
        (x) => !ignoreDropDownListId?.includes(x.value)
      ) ?? [];
    return remainingDropDownList;
  };
  const onChangeSkillHandler = (skillId: string) => {
    setSkillId(skillId);
    const selectedSkill = optionMappedSkillList.find(
      (skill) => skill.value === skillId
    );
    setInitialOnSelectDropdownSkill(selectedSkill?.label);
  };
  const onClickAddSkill = () => {
    setSubmitType('Save');
    handleSkillModalShow(true);
    setSkillModalHeader('Add Skill');
    setDropdownPlaceHolder('Select Skill');
    setInitialOnSelectDropdownSkill('');
  };
  const onClickUpdateHandler = (operatorSkill: ApiSchemaOperatorSkill) => {
    setSubmitType('Update');
    handleSkillModalShow(true);
    setSkillModalHeader('Update Skill');
    setDropdownPlaceHolder('Select Skill');
    setInitialOnSelectDropdownSkill(operatorSkill?.skill?.name);
    setOperatorSkillId(operatorSkill?.id);
    setSkillId(operatorSkill?.skillId);
  };
  const onClickDeleteHandler = (id: string) => {
    setOperatorSkillIdToDelete(id);
    handleDeleteModalShow(true);
  };
  const onSaveHandler = () => {
    if (initialOnSelectDropdownSkill) {
      submitType === 'Save'
        ? onCreateOperatorSkill(operatorId, skillId)
            .then(() => handleSkillModalShow(false))
            .catch((e: any) => {
              console.error(e);
            })
        : onUpdateOperatorSkill(operatorSkillId, operatorId, skillId)
            .then(() => handleSkillModalShow(false))
            .catch((e: any) => {
              console.error(e);
            });
    } else {
      toast.error('Please select a skill');
    }
  };
  const onDeleteHandler = () => {
    onDeleteOperatorSkill(operatorSkillIdToDelete)
      .then(() => handleDeleteModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };
  const skillCertificateExpirationMap = new Map();
  const checkingSkillCertificateExpiration = (
    skill: ApiSchemaOperatorSkill
  ) => {
    const expirationClass = certificateIsExpired(
      skill?.operatorCertificates ?? []
    );
    if (expirationClass) {
      skillCertificateExpirationMap.set(skill?.id, expirationClass);
    }
    return expirationClass;
  };

  return (
    <div className="component-card mb-3 d-xl-block d-lg-block d-md-block ">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left bordr-right">
        <div>Skills</div>
        {props.readonly ? (
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase"
            onClick={() => onClickAddSkill()}
          >
            <div className="btn mr-1" title="add">
              <span>
                <AssetSvg.PlusCircle />
              </span>
            </div>
            <span className="font-sz-12 text-uppercase">Add Skill</span>
          </button>
        ) : null}
      </div>
      <div className="component-card-body border">
        <div className="row">
          <div className="col-12">
            {operatorSkillList?.map((skill: ApiSchemaOperatorSkill) => (
              <div
                key={skill.id + String(Math.floor(Math.random() * 1000))}
                className="component-card mb-3 d-xl-block d-lg-block d-md-block d-sm-none bg-white shadow-0"
              >
                <div
                  className={`component-card-header d-flex align-items-center justify-content-between 
                                border-top border-right border-left bg-white text-capitalize
                                ${checkingSkillCertificateExpiration(skill)}`}
                >
                  <div className="px-1">{skill?.skill?.name}</div>
                  {props.readonly ? (
                    <div>
                      <div
                        className="btn svg-color"
                        title="Edit"
                        onClick={() => onClickUpdateHandler(skill)}
                      >
                        <span>
                          <AssetSvg.Edit />
                        </span>
                      </div>
                      <div
                        className="btn svg-color"
                        title="Delete"
                        onClick={() => onClickDeleteHandler(skill?.id)}
                      >
                        <span>
                          <AssetSvg.Delete />
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div
                  className={`component-card-body border ${skillCertificateExpirationMap.get(
                    skill?.id
                  )}`}
                >
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-primary text-uppercase"
                        onClick={() => {
                          setCriteriaId(skill.id ?? '');
                          handleShowUploadCertificateModal(true);
                        }}
                      >
                        upload certificate
                      </button>
                    </div>

                    <div className="col-12 mt-3">
                      <div className="table-responsive card ">
                        <table className="table table-hover mb-0">
                          <thead>
                            <tr>
                              <th className="border-top-0">Certification</th>
                              <th className="border-top-0">Start Date</th>
                              <th className="border-top-0">End Date</th>
                              <th className="border-top-0">Time Left</th>
                              <th className="border-top-0">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {skill?.operatorCertificates?.map((cert: any) => (
                              <tr key={cert.name}>
                                <td className="py-1 align-middle">
                                  {cert.name}
                                </td>
                                <td className="py-1 align-middle">
                                  {cert?.startDate
                                    ? dateFormatInUSA(cert?.startDate)
                                    : 'N/A'}
                                </td>
                                <td className="py-1 align-middle">
                                  {cert?.endDate
                                    ? dateFormatInUSA(cert?.endDate)
                                    : 'N/A'}
                                </td>
                                <td className="py-1 align-middle">
                                  {cert?.endDate
                                    ? certificateExpirationInDays(cert?.endDate)
                                    : 'N/A'}{' '}
                                </td>
                                <td className="py-1 align-middle">
                                  <div
                                    className="btn svg-color"
                                    title="certificateDownload"
                                  >
                                    <span
                                      onClick={() => {
                                        forceDownload(
                                          FileBucketURL.Root + cert.fileKey,
                                          'certificate'
                                        );
                                      }}
                                    >
                                      <AssetSvg.Download />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalUploadCertificate
        key={Date.now() + ''}
        criteriaId={criteriaId}
        show={showUploadCertificateModal}
        handleShow={handleShowUploadCertificateModal}
        criteriaType={OperatorCriteriaType.Skill}
      />
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={handleDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
      {skillModalShow ? (
        <ModalSingleDropdown
          show={skillModalShow}
          handleShow={handleSkillModalShow}
          header={skillModalHeader}
          dropDownList={getDropDownList()}
          dropdownPlaceHolder={dropdownPlaceHolder}
          onSelectItem={optionMappedSkillList.find(
            (skill) => skill.label === initialOnSelectDropdownSkill
          )}
          onChangeHandler={onChangeSkillHandler}
          onSaveHandler={onSaveHandler}
        />
      ) : null}
    </div>
  );
}
