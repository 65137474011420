import Select, { createFilter } from 'react-select';
import { FormErrorMessage } from '../../../components';
import { timeZones } from '../../modals/jobFormData';
import {
  ApiSchemaJobDetailsById,
  ITypeMinimumTimesByPlatform,
} from '../../../models/api/jobs';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useFunctionalityInputChecker } from '../../../pages/hooks/functionalityInputChecker';
import { useAppSelector } from '../../../core';
import { shallowEqual } from 'react-redux';
import { reactSelectFilterConfig } from '../../../configs';

type Props = {
  Controller: any;
  register: any;
  setValue: any;
  errors: any;
  jobInfosToEdit?: ApiSchemaJobDetailsById;
  hoursErrorMsg?: any;
  setHoursErrorMsg?: any;
  minutesErrorMsg?: any;
  setMinutesErrorMsg?: any;
  durationChecker: any;
  control: any;
  minimumTimesByPlatform?: ITypeMinimumTimesByPlatform[];
  selectedServiceType?: number;
  lessThan24Hours?: boolean;
  setLessThan24Hours: any;
};

export function ScheduleForm(props: Props): JSX.Element {
  const { Controller, control, minimumTimesByPlatform, selectedServiceType } =
    props;
  const afterOneWeekDate = props.jobInfosToEdit?.date
    ? moment(props.jobInfosToEdit?.date).format('YYYY-MM-DD')
    : moment().add(7, 'd').format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState<Date | null>(new Date(afterOneWeekDate));
  const [dateMinutes, setDateMinutes] = useState(165898934743300);
  const [timeMinutes, setTimeMinutes] = useState(165898934743300);
  const [hourValue, setHourValue] = useState<string>('');
  const [mintValue, setMintValue] = useState<string>('');
  const [hours, setHours] = useState<string>("0.0");
  const [minutes, setMinutes] = useState<string>("0.0");

  const userData = useAppSelector(
    (state) => ({
      timeZone: state.auth.profile?.timeZone,
    }),
    shallowEqual
  );
  const defaultTimeZoneUser = userData.timeZone;
  const defaultTime = timeZones.find((x) => x.value === defaultTimeZoneUser);
  const [timeZoneTime, setTimeZoneTime] = useState(
    moment(
      moment(),
      props.jobInfosToEdit?.timeZone ?? defaultTime?.value
    ).valueOf()
  );

  useEffect(() => {
    props.setLessThan24Hours(
      dateMinutes + timeMinutes - timeZoneTime <= 86400000
    );
  }, [dateMinutes, timeMinutes, timeZoneTime, props.lessThan24Hours]);

  const {
    numberFieldValidationForDuration,
    handleCopyPasteInvalidValueForDuration,
  } = useFunctionalityInputChecker();
  const handleScheduleHours = async (value: any) => {
    const hours = value;
    setHourValue(hours);
    if (await props.durationChecker()) return;
    if (parseInt(hours) > 999) {
      props.setHoursErrorMsg('Please enter a proper hour range');
    } else {
      props.setHoursErrorMsg('');
    }
  };

  const handleScheduleMinutes = async (value: any) => {
    const minutes = value;
    setMintValue(minutes);
    if (await props.durationChecker()) return;

    if (parseInt(minutes) > 59 || parseInt(minutes) < 0) {
      props.setMinutesErrorMsg('Please enter a proper minutes range');
    } else {
      props.setMinutesErrorMsg('');
    }
  };
  const findMinimumTimesByPlatform = minimumTimesByPlatform?.find(
    (minimumTimePlatform) =>
      minimumTimePlatform.platform === selectedServiceType
  );
  return (
    <>
      <div className="component-card rounded mb-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border rounded-top">
          <div className="text-capitalize">Schedule</div>
        </div>
        <div className="component-card-body rounded-bottom border-left border-right border-bottom">
          <div className="form-group row">
            <div className="col-6">
              <label htmlFor="" className="col-12 p-0 required">
                Start Date
              </label>
              <Controller
                name="startDate"
                control={control}
                defaultValue={startDate}
                ref={props.register({ required: true })}
                render={() => (
                  <DatePicker
                    className={`form-control ${!startDate && 'is-invalid'}`}
                    selected={startDate}
                    onChange={(currentDate: Date | null) => {
                      props.setValue(
                        'startDate',
                        moment(currentDate).format('YYYY-MM-DD'),
                        {
                          shouldValidate: true,
                        }
                      );
                      setStartDate(currentDate);
                      const dateMinute = moment(currentDate).valueOf();
                      setDateMinutes(dateMinute);
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                )}
              />
              {!startDate && <FormErrorMessage message="required" />}
            </div>
            <div className="col-6">
              <label htmlFor="" className="col-12 p-0 required">
                Start Time
              </label>
              <input
                name="startTime"
                type="time"
                className="form-control"
                defaultValue={
                  props.jobInfosToEdit?.startTime
                    ? moment(
                        props.jobInfosToEdit?.startTime,
                        'hh:mm:ss A'
                      ).format('HH:mm')
                    : moment().format('HH:mm')
                }
                onChange={(e) => {
                  props.setValue('startTime', e.target.value, {
                    shouldValidate: true,
                  });
                  const times = moment(e.target.value, 'HH:mm')
                    .format('HH:mm')
                    ?.split(':');
                  setTimeMinutes(
                    Number(times[0]) * 3600000 + Number(times[1]) * 60000
                  );
                }}
                ref={props.register({
                  required: true,
                })}
              />
              {props.errors.startTime && (
                <FormErrorMessage message="required" />
              )}
            </div>
          </div>
          <div className="col-12 font-weight-bold p-0">Duration:</div>
          <div className="form-group row">
            <div className="col-6">
              <label htmlFor="" className="col-12 p-0 col-form-label required">
                Hours
              </label>
              <input
                name="durationHours"
                type="number"
                id="durationHours"
                step="any"
                min="0.0"
                className={
                  props.hoursErrorMsg
                    ? 'form-control field-required '
                    : 'form-control'
                }
                defaultValue={
                  props.jobInfosToEdit?.durationHours
                    ? props.jobInfosToEdit?.durationHours
                    : findMinimumTimesByPlatform?.minimumTime.split(':')[0]
                }
                ref={props.register({
                  required: true,
                  validate: handleCopyPasteInvalidValueForDuration,
                })}
                onChange={(e) => {
                  handleScheduleMinutes(mintValue);
                  handleScheduleHours(e.target.value);
                  setHours(e.target.value);
                }}
                onKeyDown={(evt) => numberFieldValidationForDuration(evt)}
                style={{
                  border:
                    (props.errors.durationHours &&
                      !findMinimumTimesByPlatform?.minimumTime) ||
                    !hours
                      ? '1px solid red'
                      : '',
                }}
              />
              {((props.errors.durationHours &&
                props.errors.durationHours.type === 'required' &&
                !findMinimumTimesByPlatform?.minimumTime) ||
                !hours) && <FormErrorMessage message="required" />}

              {/* {
                props.hoursErrorMsg && <span className="text-danger">{props.hoursErrorMsg}</span>
              } */}
              {props.errors.durationHours &&
                props.errors.durationHours.type === 'validate' && (
                  <FormErrorMessage message="Please enter a valid value" />
                )}
            </div>
            <div className="col-6">
              <label htmlFor="" className="col-12 p-0 col-form-label required">
                Minutes
              </label>
              <input
                name="durationMinutes"
                id="durationMinutes"
                type="number"
                step="any"
                min="0.0"
                className={
                  props.minutesErrorMsg
                    ? 'form-control field-required '
                    : 'form-control'
                }
                defaultValue={
                  props.jobInfosToEdit?.durationMinutes
                    ? props.jobInfosToEdit?.durationMinutes
                    : findMinimumTimesByPlatform?.minimumTime.split(':')[1]
                }
                ref={props.register({
                  required: true,
                  validate: handleCopyPasteInvalidValueForDuration,
                })}
                onChange={(e) => {
                  handleScheduleHours(hourValue);
                  handleScheduleMinutes(e.target.value);
                  setMinutes(e.target.value);
                }}
                onKeyDown={(evt) => numberFieldValidationForDuration(evt)}
                style={{
                  border:
                    ((props.errors.durationMinutes &&
                      !findMinimumTimesByPlatform?.minimumTime) ||
                    !minutes)
                      ? '1px solid red'
                      : '',
                }}
              />
              {((props.errors.durationMinutes &&
                props.errors.durationMinutes.type === 'required' &&
                !findMinimumTimesByPlatform?.minimumTime) ||
                !minutes) && <FormErrorMessage message="required" />}

              {props.minutesErrorMsg && (
                <span className="text-danger">{props.minutesErrorMsg}</span>
              )}
              {props.errors.durationMinutes &&
                props.errors.durationMinutes.type === 'validate' && (
                  <FormErrorMessage message="Please enter a valid value" />
                )}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor=""
              className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
            >
              Time Zone
            </label>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
              <input
                className="d-none"
                name="timeZone"
                ref={props.register({ required: true })}
                defaultValue={
                  props.jobInfosToEdit?.timeZone ?? defaultTime?.value
                }
                style={{ border: props.errors.timeZone ? '1px solid red' : '' }}
              />
              <Select
                className={` ${
                  props.errors.timeZone?.type == 'required'
                    ? 'field-required'
                    : ''
                } `}
                placeholder="Select Time Zone"
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={
                  props.jobInfosToEdit?.timeZone
                    ? timeZones.find(
                        (x) => x.value === props.jobInfosToEdit?.timeZone
                      )
                    : timeZones.find((x) => x.value == defaultTimeZoneUser)
                }
                options={timeZones}
                filterOption={createFilter(reactSelectFilterConfig)}
                onChange={(value) => {
                  props.setValue('timeZone', value?.value, {
                    shouldValidate: true,
                  });
                  setTimeZoneTime(moment(moment(), value?.value).valueOf());
                }}
              />
              {props.errors.timeZone && <FormErrorMessage message="required" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
