import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { AssetSvg } from "../../assets";
import { FormErrorMessage } from "../../components/common";
import { AppConstants, AppRouteUi, device } from "../../configs";
import {
  ActionApp,
  SocketContext,
  StorageAuth,
  StorageUserCalendar,
  useAppSelector,
} from "../../core";
import { ApiBodyLogin } from "../../models/login";
import { useFunctionalityAuth, useVersionCheck } from "../../pages/hooks";
import { UtilInputValidation } from "../../utils";

const CustomEmailValidationType = "validEmail";

function GetEmailValidationMessage(type?: string): string {
  return type === CustomEmailValidationType
    ? "A valid email is required"
    : !!type
      ? "Email is required"
      : "";
}

export function Login(): JSX.Element {
  const { isBackdatedVersion } = useVersionCheck();
  const { onLogin, onCheckUserStatus } = useFunctionalityAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isActivate, setIsActivate] = useState<boolean>(false);

  const data = useAppSelector(
    (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      role: state.auth.profile?.userRole,
      previousPath: state.ui.previousRoute,
      isForcefullyLoggedout: state.ui.isForceFullylogedout,
    }),
    shallowEqual
  );
  const location = useLocation<{ ForceFullyLoggedout: boolean }>();
  const { register, handleSubmit, formState: { errors } } = useForm<ApiBodyLogin>();
  const { socketConnection } = React.useContext(SocketContext);
  const [errMsg, setErrMsg] = React.useState<string>();
  const clearErrMsg = React.useCallback(() => setErrMsg(undefined), []);

  useEffect(() => {
    if (location.state?.ForceFullyLoggedout) {
      toast.error("You have been logged out forcefully");
      socketConnection?.close();
      StorageAuth.Clear();
      StorageUserCalendar.Clear();
      dispatch(ActionApp.ResetStore());
      device.destroy();
    }
  }, []);
  if (data.isLoggedIn && data.role !== AppConstants.UserRoles.Operator) {
    return <Redirect to={AppRouteUi.Home.Root} />;
  } else if (data.isLoggedIn && data.role === AppConstants.UserRoles.Operator) {
    return <Redirect to={AppRouteUi.CallLog.Root} />;
  }
  const handleLogin = (data: ApiBodyLogin) => {
    if (!isActivate) {
      onLogin(data);
    } else {
      onCheckUserStatus({ username: data.username });
    }
  };
  return <>
    <div className="container-md container-login-sm px-0 px-sm-0 px-md-3 px-lg-3 h-100 d-flex align-items-center">
      <div className="row m-auto login-container-card">
        <div className="col-12">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="login-logo m-3">
                <AssetSvg.VRILogo />
              </div>
            </div>
            <div className="col-12">
              <form
                className="login-form p-4 "
                onSubmit={handleSubmit(handleLogin)}
                noValidate
              >
                <div className="form-group">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text text-muted">
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                    </div>
                    <input
                      type="email"
                      id="username"
                      className="form-control"
                      {...register('username', {
                        required: true,
                        validate: {
                          [CustomEmailValidationType]:
                            UtilInputValidation.IsValidEmail,
                        },
                      })}
                      placeholder="Email"
                      autoFocus
                      onClick={clearErrMsg} />
                  </div>
                  {errors.username && (
                    <FormErrorMessage
                      message={GetEmailValidationMessage(
                        errors.username.type
                      )}
                    />
                  )}
                </div>
                {!isActivate && (
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>

                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        {...register('password', {
                          required: true,
                        })}
                        placeholder="Password"
                        onClick={clearErrMsg} />
                    </div>
                    {errors.password && (
                      <FormErrorMessage message="Password is required" />
                    )}
                  </div>
                )}
                <div className="form-group d-flex justify-content-sm-between align-items-sm-center flex-wrap mb-1 pt-3">
                  {/* <a
                    onClick={() => setIsActivate((prev) => !prev)}
                    className="btn btn-link font-sz-14 btn-site-sm mt-3 mt-sm-0 px-0 order-1 order-sm-0"
                  >
                    {!isActivate ? "Activate" : "Back to login"}
                  </a> */}
                  {!isActivate && (
                    <a
                      onClick={() =>
                        history.push(AppRouteUi.ForgotPassword.Root)
                      }
                      className="btn btn-link font-sz-14 btn-site-sm mt-3 mt-sm-0 px-0 order-1 order-sm-0"
                    >
                      Forgot Password?
                    </a>
                  )}
                  <button
                    type="submit"
                    className="ladda-button font-sz-14 login-form-submit-btn btn btn-purple btn-site-sm order-0 order-sm-1"
                    disabled={isBackdatedVersion}
                  >
                    Submit
                  </button>
                  {errMsg && <FormErrorMessage message={errMsg} />}
                </div>
              </form>
            </div>
            {/* ) : null} */}
          </div>
        </div>
      </div>
    </div>
  </>;
}
