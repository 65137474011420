import { Option, OptionWithActiveStatus  } from '../models';

class OptionMapperImpl {
  mapperFuncBookingCancellationReason(dataSet: any) {
      const optionArray = [] as Option[];

      dataSet.map((data: any) =>
          optionArray.push({
              value: data.id,
              label: data.reason,
          })
      );

      return optionArray;
  }

  mapperFunc(dataSet: any) {
    const optionArray = [] as Option[];

    dataSet.map((data: any) =>
      optionArray.push({
        value: data.id,
        label: data.name,
      })
    );

    return optionArray;
  }

  mapperFuncWithActiveStatus(dataSet: any) {
    const optionArray = [] as OptionWithActiveStatus[];

    dataSet.map((data: any) =>
      optionArray.push({
        value: data.id,
        label: data.name,
        isOnline: data.isOnline,
      })
    );

    return optionArray;
  }
}

export const OptionMapper = new OptionMapperImpl