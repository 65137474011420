import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FooterButtons, StepIndicatorTabs } from "../../..";
import { FormErrorMessage } from "../../../../components";
import { CompanyType, PlatformType, StepNumber } from "../../../../configs";
import { ActionCompany, useAppSelector } from "../../../../core";
import { TypeCreateCompanyStep3n4 } from "../../../../models";
import { TypeContactPlatforms } from '../../../../models/api/company';
import { useFunctionalityInputChecker } from "../../../../pages/hooks/functionalityInputChecker";

type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
};

const descriptions = [
  "Video Remote Interpretation",
  "Over Phone Interpretation",
  "On-Site Interpretation",
];

type CreateCompanyStep3Form = {
  companyStep3: [
    {
      connectionFee: number;
      technologyFee: number;
    }
  ];
};

export function Step3CreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType } = props;
  const { register, handleSubmit, formState: { errors } } = useForm<CreateCompanyStep3Form>();
  const [noPlatformSelectedErr, setNoPlatformSelectedErr] = useState(false);
  const { numberFieldValidation, handleCopyPasteInvalidValue, handleLimitDecimalPlaces } = useFunctionalityInputChecker()
  const dispatch = useDispatch();
  const step3Data: TypeCreateCompanyStep3n4[] = useAppSelector((state) => state.company.companyStep3n4);
  const [contractPlatforms, setContractPlatforms] = useState<TypeCreateCompanyStep3n4[]>(step3Data);

  const onToggleButton = (contractPlatform: TypeContactPlatforms) => {
    const _tmpContracts = contractPlatforms.map((arrayElement, index) => {
      if (arrayElement.platform === contractPlatform.platform) {
        arrayElement = { ...arrayElement, isEnabled: !arrayElement.isEnabled };
      }
      return arrayElement;
    });
    setContractPlatforms(_tmpContracts);
    setNoPlatformSelectedErr(false);
  };
  const preventMinus = (e: any) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };
  const onSubmitStep3 = (data: CreateCompanyStep3Form, e: any) => {
    if (contractPlatforms.find((x) => x.isEnabled.toString() === "true")) {
      const _contractPlatforms = contractPlatforms.map(
        (arrayElement, index) => {
          arrayElement = {
            ...arrayElement,
            connectionFee: data?.companyStep3[index]?.connectionFee ? parseFloat((data?.companyStep3[index]?.connectionFee)?.toString()) : 0,
            technologyFee: data?.companyStep3[index]?.connectionFee ? parseFloat((data?.companyStep3[index]?.technologyFee)?.toString()) : 0,
          };
          return arrayElement;
        }
      );
      dispatch(ActionCompany.SetCompanyStep3n4(_contractPlatforms));
      setStepCounter(stepCounter + 1);
    } else {
      setNoPlatformSelectedErr(true);
      dispatch(ActionCompany.SetCompanyStep3n4(contractPlatforms));
    }
  };
  return <>
    <form onSubmit={handleSubmit(onSubmitStep3)} id="companyCreateForm">
      <Modal.Body>
        <div className=" component-padding border border-gry">
          <div className="row">
            <div className="col-12">
              <StepIndicatorTabs step={stepCounter || StepNumber.firstStep} companyType={companyType} />
              <div className="row mt-4">
                <div className="col-12">
                  {
                    contractPlatforms.map((contractPlatform, index) => (
                      <div key={index} className="component-card rounded mb-3 shadow-none ">
                        <div className="component-card-header border rounded-top">
                          <div className="row flex-wrap align-items-center">
                            <div className="col-6">
                              <div>{PlatformType[index + 1]} </div>
                              <div>
                                <p className="mb-0">{descriptions[index]}</p>
                              </div>
                            </div>
                            <div className="col-6 ">
                              <div className="d-flex align-items-center justify-content-end pr-4 mr-2">
                                <button
                                  type="button"
                                  className={
                                    contractPlatform.isEnabled.toString() ===
                                      "true"
                                      ? "btn btn-toggle on-off-btn active"
                                      : "btn btn-toggle on-off-btn"
                                  }
                                  onClick={() =>
                                    onToggleButton(contractPlatform)
                                  }
                                >
                                  <div className="handle"></div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          contractPlatform.isEnabled.toString() === "true" ? (
                            <div
                              className="component-card-body rounded-bottom border-left border-right border-bottom"
                              id="collapseExample"
                            >
                              <div className="form-group">
                                <label
                                  htmlFor="subscriptionRate"
                                  className="position-relative required"
                                >
                                  {
                                    contractPlatform?.platform === PlatformType.OnSite ? 'Booking Fee Per Assignment' : 'Connection Fee Per Call'
                                  }
                                </label>
                                <div className="input-group mb-3">
                                  <div className="input-group-prepend">
                                    <span
                                      className="input-group-text"
                                      id="subscriptionRateValue"
                                    >
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="float"
                                    // min="0.0"
                                    className="form-control"
                                    id="subscriptionRate"
                                    aria-describedby="subscriptionRateValue"
                                    {...register(
                                      //@ts-ignore
                                      `companyStep3[${index}].connectionFee`,
                                      { required: true, validate: handleCopyPasteInvalidValue },
                                    )}
                                    defaultValue={
                                      contractPlatform.connectionFee || 0
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]|\./.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onKeyDown={(evt) => numberFieldValidation(evt)}
                                    onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                                </div>
                                {
                                  errors.companyStep3?.[index]?.connectionFee && errors.companyStep3?.[index]?.connectionFee?.type === "required" && (
                                    <FormErrorMessage message="required" />
                                  )
                                }
                                {errors.companyStep3?.[index]?.connectionFee && errors.companyStep3?.[index]?.connectionFee?.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                              </div>

                              {
                                contractPlatform?.platform !== PlatformType.OnSite && <div className="form-group">
                                  <label
                                    htmlFor="subscriptionRate"
                                    className="position-relative required"
                                  >
                                    Technology Fee Per Minute
                                  </label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text"
                                        id="subscriptionRateValue"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <input
                                      type="float"
                                      className="form-control"
                                      id="subscriptionRate"
                                      aria-describedby="subscriptionRateValue"
                                      {...register(
                                        //@ts-ignore
                                        `companyStep3[${index}].technologyFee`,
                                        { required: true, validate: handleCopyPasteInvalidValue },
                                      )}
                                      defaultValue={
                                        contractPlatform.technologyFee || 0
                                      }
                                      onKeyPress={(event) => {
                                        if (!/[0-9]|\./.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onKeyDown={(evt) => numberFieldValidation(evt)}
                                      onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                                  </div>
                                  {errors.companyStep3?.[index]?.technologyFee && errors.companyStep3?.[index]?.technologyFee?.type === "required" && (
                                    <FormErrorMessage message="required" />
                                  )}
                                  {errors.companyStep3?.[index]?.technologyFee && errors.companyStep3?.[index]?.technologyFee?.type === "validate" && (
                                    <FormErrorMessage message="Please enter a valid value" />
                                  )}
                                </div>
                              }
                            </div>
                          ) : null
                        }
                      </div>
                    ))
                  }

                  {
                    noPlatformSelectedErr && (
                      <FormErrorMessage message="* Select any platform please" />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <FooterButtons
          counter={stepCounter || StepNumber.firstStep}
          setCounter={setStepCounter}
          handleShow={handleShow}
        />
      </Modal.Footer>
    </form>
  </>;
}
