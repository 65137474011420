import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  AppRouteUi,
  CallStatusType,
  CallType,
} from "../../configs";
import { ActionUi, SocketContext, useAppSelector } from "../../core";
import { Option } from "../../models";
import { useFunctionalityOperator } from "../../pages/hooks";
import { useFunctionalityCalls } from "../../pages/hooks/functionalityCalls";
import { apiPolling, logger, OptionMapper } from "../../utils";
type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  callType: CallType;
};
export function ModalAddOperatorParticipant(props: Props): JSX.Element {
  const currentUserCompanyId = useAppSelector(
    (state) => state.auth.profile?.companyId
  );
  let routeParamRoomId = AppRouteUi.VideoCall.Room.useParam();
  if (!routeParamRoomId) {
    routeParamRoomId = AppRouteUi.VideoConference.Room.useParam();
  }
  const { onUpdate } =
    useFunctionalityCalls();
  const { inCallOperatorList } = useFunctionalityOperator({});
  const [apiUserNames, setApiUserNames] = useState<Option[]>([]);
  const currentUserId = useAppSelector((state) => state.auth.profile?.id);
  const filteredOperatorList = apiUserNames.filter(
    (user) => user.value !== currentUserId
  );
  const data = useAppSelector((state) => ({
    callerId: state.auth.profile?.id,
    //roomId: state.ui.roomId,
    chatSid: state.ui.chatSid
  }));
  const mapToOption = (apiUserNames: any) => {
    const data = Array.isArray(apiUserNames)
      ? OptionMapper.mapperFunc(apiUserNames)
      : [];
    return data;
  };
  useEffect(() => {
    let timeIntervalId: number;
    if(!props.show) {
      return;
    }
    timeIntervalId = apiPolling(()=> {
      inCallOperatorList(routeParamRoomId).then((data) => {
        setApiUserNames(data.data);
      })
    }, 30000);
    return ()=> {
      timeIntervalId && clearInterval(timeIntervalId)
    }
  }, [props.show]);

  const dispatch = useDispatch();

  const [selectedUserId, setSelectedUserId] = React.useState<any>();

  const [selectedUserName, setSelectedUserName] = React.useState<any>();

  const videoConnect = (connection: any, event: any) => {
    event?.preventDefault();
    logger({ message: `Trying to invite operator to call: ${selectedUserName} (${selectedUserId})` });
    onUpdate(routeParamRoomId, {
      callStatus: CallStatusType.ParticipantInvited,
      callType: props.callType,
      eventTime: new Date().toISOString(),
      isCallStarted: false,
      newParticipantId: selectedUserId,
      callerUserId: data.callerId,
      chatSid: data.chatSid
    }).then((data) => {
      if (!data?.isSuccess) {
        toast.error(data?.message);
        return;
      }
      logger({ message: `Inviting operator: ${selectedUserName} (${selectedUserId})` });
      toast.info("Inviting Operator " + selectedUserName);
      setSelectedUserId("");
      setSelectedUserName("")
    });

    // connection
    //   .invoke(
    //     "OnOutgoing",
    //     selectedUserId,
    //     data.roomId,
    //     data.callerId,
    //     "video call incoming"
    //   )
    //   .catch(function (err: any) {
    //     return console.error(err.toString());
    //   });
  };
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  // };
  const onModalClose = () => {
    props.handleShow(false);
    setSelectedUserId("");
    setSelectedUserName("")
  };
  return (
    <Modal
      show={props.show}
      onHide={() => onModalClose()}
      centered
      backdrop="static"
    >
      {/* <form> */}
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title> Add to Call</Modal.Title>
      </Modal.Header>
      {filteredOperatorList?.length > 0 ? (
        <>
          <Modal.Body>
            <form>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label htmlFor="" className="text-muted font-sz-12">
                    Operator
                  </label>

                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={filteredOperatorList}
                    placeholder="Select an Operator..."
                    onChange={(value) => {
                      setSelectedUserId(value?.value);

                      if (!!value?.value) {
                        setSelectedUserName(value?.label);
                        dispatch(ActionUi.SetReceiverName(value?.label));
                        dispatch(ActionUi.SetReceiverId(value?.value));
                      }
                    }}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <div
              className="form-row 
                justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
                flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
                mx-0"
            >
              <SocketContext.Consumer>
                {(connection) => (
                  <button
                    disabled={(!selectedUserId && !selectedUserName)}
                    className="  btn btn-outline-dark border-grey 
                      d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                      flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                      justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
                    onClick={(event) => {
                      logger({ message: `Pressed button to add call: ${selectedUserName} (${selectedUserId})` });
                      videoConnect(connection.socketConnection, event);
                      props.handleShow(false);
                    }}
                  >
                    Add to Call
                  </button>
                )}
              </SocketContext.Consumer>
            </div>
          </Modal.Footer>
        </>
      ) : (
        <>
          <h4  className="p-4 text-center">No Operator found</h4>
        </>
      )}
    </Modal>
  );
}
