import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import {
    ApiResponse,
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { ApiSchemaCallOutflowData, ApiCallOutflowSchemaChangeStatus } from "../../models/api/callOutflow";

let pagedOutflowUrl: string;

type CallOutflowProps = {
    companyId?: string;
    setLoaderProgress: any
};
export function useFunctionalityCallOutflow(props: CallOutflowProps) {
    const { companyId, setLoaderProgress } = props;

    pagedOutflowUrl = PlatformApi.CallOutflow.GetOutflowListByCompanyId(companyId || "");

    const { data: apiCallOutflowData } = useSWR<ApiSchemaCallOutflowData[]>(
        companyId ? PlatformApi.CallOutflow.GetOutflowListByCompanyId(companyId) : null,
        () => {
            setLoaderProgress(topLoaderProgress.start);
            return AxiosAuth.get<ApiResponse<ApiSchemaCallOutflowData[]>>(
                pagedOutflowUrl
            )
                .then((r) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    return r.data.data;
                })
                .catch((e) => {
                    setLoaderProgress(topLoaderProgress.complete);
                    console.error(e);
                    return e;
                })
        }
    );

    const callOutflowData = React.useMemo(() => {
        const data = Array.isArray(apiCallOutflowData) ? apiCallOutflowData : [];
        return data;
    }, [apiCallOutflowData]);

    const onChangeStatus = useCallback(async (form: ApiCallOutflowSchemaChangeStatus) => {
        const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
        btnLoading.start?.();
        try {
            const res = await AxiosAuth.put(PlatformApi.CallOutflow.ChangeCallOutflowStatus(), form);
            if ((res.status = AppConstants.Api.StatusOK)) {
                mutate(pagedOutflowUrl);
                toast.success("Status changed");
            }
        } catch (e) {
            toast.error("Failed to change status");
        }
        btnLoading.stop?.();
        return Promise.resolve(null);
    }, []);

    return {
        callOutflowData,
        onChangeStatus,
    };
}