import { PlatformType, PlatformTypeConstant } from "../../configs/appConstants";

export interface ModalityResponseModel {
  id: string;
  serviceType: string;
  name: string;
  pin: string;
  status: boolean;
}
export interface ModalityRequestModel {
  serviceType: PlatformType;
  name: string;
  // pin: string;
}
export interface AppointmentCategoryResponseModel {
  id: string;
  name: string;
  pin: string;
  status: boolean
}
export interface AppointmentCategoryRequestModel {
  name: string;
  //pin: string;
}
export interface AppointmentDescriptionResponseModel {
  id: string;
  name: string;
  appointmentCategoryId: string;
  appointmentCategoryName: string;
  pin: string;
  status: boolean
}
export interface AppointmentDescriptionRequestModel {
  appointmentCategoryId: string;
  name: string;
  //pin: string;
}
export const serviceTypes = [
  {
    label: PlatformTypeConstant.OnSite,
    value: PlatformType.OnSite
  },
  {
    label: PlatformTypeConstant.VRI,
    value: PlatformType.VRI
  },
  {
    label: PlatformTypeConstant.OPI,
    value: PlatformType.OPI
  }
]

