import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { FaceToFaceAssignment, MedicalHistory, UserGeneralInfo } from "../../features";
import { AppRouteUi, PlatformApi } from "../../configs";
import { useFunctionalityUser2, useFunctionalityUserGetDetailsById, } from "../hooks";
import { SupportedPlatform, LanguageRates, Skills, Ratings, } from "../../features/OperatorProfile";
import { ApiSchemaUpdateUser } from "../../models";
import { mutate } from "swr";

export function PageOperatorProfile() {
    const routeLocation = useLocation<{
        userId: string;
        avgRating: any;
        ratingCount: any;
    }>();
    const { userId, avgRating, ratingCount } = routeLocation.state ?? {};
    const { userDetailsData } = useFunctionalityUserGetDetailsById({ userId: userId, });
    const { updateUserDetails } = useFunctionalityUser2({ userId: userId });
    const [isOnsiteEnabled, setIsOnsiteEnabled] = useState(false);
    const history = useHistory();
    const operatorId = AppRouteUi.Operator.Profile.useParam();
    const [editModalShow, setEditModalShow] = useState(false);

    const onUpdateHandler = (form: ApiSchemaUpdateUser) => {
        form = {
            ...form,
            id: userDetailsData?.id,
            gender: Number(form.gender),
        };
        updateUserDetails(form)
            .then(() => {
                mutate(PlatformApi.Users.GetDetailsById(userId || ""));
                setEditModalShow(false);
            })
            .catch((e: any) => {
                console.error(e);
            });
    };

    return (
        <>
            <div className="row">
                <div className="col-12 mt-3">
                    <Button onClick={() => history.goBack()} className="btn-purple">
                        Back to List
                    </Button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-3">
                    <UserGeneralInfo
                        profile={userDetailsData}
                        onUpdateHandler={onUpdateHandler}
                        userDetailsData={userDetailsData}
                        editModalShow={editModalShow}
                        setEditModalShow={setEditModalShow}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 pr-sm-0">
                    <SupportedPlatform
                        setIsOnsiteEnabled={setIsOnsiteEnabled}
                        readonly={true}
                    />
                    {isOnsiteEnabled && (
                        <FaceToFaceAssignment readonly={true} />
                    )}
                    <LanguageRates readonly={true} />
                    <Skills readonly={true} />
                </div>
                <div className="col-12 col-sm-6">
                    <Ratings
                        readonly={true}
                        operatorId={operatorId}
                        userDetails={userDetailsData}
                        avgrating={avgRating}
                        ratingCount={ratingCount}
                    />
                    <MedicalHistory readonly={true} />
                </div>
            </div>
        </>
    );
}
