import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { AdminPageTemplate, ColumnToolTip } from "../..";
import { AppRouteUi, PaginationSettings } from "../../../configs";
import { ApiSchemaCompanyByIdData, ApiSchemaDepartmentData, CompanySettings, CustomSetting } from "../../../models";
import { useFunctionalityCompanySettingsDetails, useFunctionalityDepartment, useFunctionalityPagedDepartment } from "../../../pages/hooks";
import { ModalConfirmDelete, ModalDepartmentSaveModal } from "../../modals";
import { toast } from "react-toastify";
import { AssetSvg } from "../../../assets";
type Props = {
  editable: boolean;
};
export function DepartmentTab(props: Props): JSX.Element {
  const { editable } = props;
  const companyId = AppRouteUi.Company.Profile.useParam();
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [locationId, setDepartmentId] = useState("");
  const { onDeleteDepartment } = useFunctionalityDepartment({ companyId: companyId, });
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState<ApiSchemaDepartmentData | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isEditDepartment, setIsEditDepartment] = useState<boolean>(false);

  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: AppRouteUi.Company.Profile.useParam() || "",
    shouldDepartmentGetAllInvoke: createModalShow,
  });

  const [companySettingsDetails, setCompanySettingsDetails] = useState<ApiSchemaCompanyByIdData>();
  const { onCompanySettingsDetails } = useFunctionalityCompanySettingsDetails({
    companyId: AppRouteUi.Company.Profile.useParam(),
    setLoaderProgress: setLoaderProgress,
  });

  useEffect(() => {
    onCompanySettingsDetails()?.then((res) => {
      setCompanySettingsDetails(res?.data);
    });
  }, []);

  const companySettings: CompanySettings = companySettingsDetails?.companySettings || {};

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const { pagedDepartmentList, pagedDepartmentListCount } =
    useFunctionalityPagedDepartment({
      companyId: companyId,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
    });

  useEffect(() => {
    if (pagedDepartmentList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedDepartmentList]);

  const onDeleteHandler = () => {
    onDeleteDepartment(locationId)
      .then(() => {
        setDeleteModalShow(false);
      })
      .catch((e) => console.error(e));
  };

  const handleDeleteDepartment = () => {
    const departmentCustomField = companySettings?.customFields?.find(
      (customSettingItem: CustomSetting) =>
        customSettingItem?.code === "Department"
    );

    if (
      departmentCustomField?.isEnabled &&
      optionMappedDepartmentList.length <= 1
    ) {
      toast.error("Can't Delete because Department Custom Settings is Enable");
      return;
    } else {
      setDeleteModalShow(true);
    }
  };

  const locationTableColumns = [
    {
      name: <ColumnToolTip data="Department Name" />,
      allowOverflow: true,
      selector: (row: ApiSchemaDepartmentData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: "Phone Number",
      allowOverflow: true,
      selector: (row: ApiSchemaDepartmentData) => {
        return <ColumnToolTip data={row.address.phone} />;
      },
    },
    {
      name: <ColumnToolTip data="Address" />,
      selector: (row: ApiSchemaDepartmentData) => {
        return (
          <ColumnToolTip
            data={
              row?.address
                ? row?.address.description +
                ", " +
                row?.address.city +
                ", " +
                row?.address.stateName +
                ", " +
                row?.address.zipCode +
                ", " +
                row?.address.countryName
                : null
            }
          />
        );
      },
    },
  ];
  if (editable) {
    locationTableColumns.push({
      name: "Actions",
      allowOverflow: true,
      selector: (row: ApiSchemaDepartmentData) => {
        return (
          <>
            <div className="btn">
              <div
                className="btn svg-color"
                title="departmentEdit"
                onClick={() => {
                  setDataToEdit(row);
                  setCreateModalShow?.(true);
                  setIsEditDepartment(true);
                }}
              >
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </div>
            <div className="btn">
              <div
                className="btn svg-color"
                title="departmentDelete"
                onClick={() => {
                  setDepartmentId(row.id);
                  handleDeleteDepartment();
                }}
              >
                <span >
                  <AssetSvg.Delete />
                </span>
              </div>
            </div>
          </>
        );
      },
    });
  }
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="row flex-wrap  mb-3">
        <div className="col">
          <AdminPageTemplate
            loaderProgress={loaderProgress}
            pageTitle="Department"
            modalControlBtnLabel={editable ? "Add a Department" : ""}
            tableHeaders={locationTableColumns}
            tableData={pagedDepartmentList}
            searchText={searchText}
            handleSearch={handleSearch}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={pageSize}
            count={pagedDepartmentListCount}
            onChangePageIndex={onChangePageIndex}
            onChangePageSize={onChangePageSize}
            setCreateModalShow={setCreateModalShow}
            showExportOptions={false}
            hasPagingData={hasPagingData}
            setHasPagingData={setHasPagingData}
            isHideAddButton
          />
        </div>
      </div>
      {createModalShow && (
        <ModalDepartmentSaveModal
          show={createModalShow}
          handleShow={setCreateModalShow}
          modalHeader={isEditDepartment ? "Edit Department" : "Add Department"}
          companyId={companyId}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
          setIsEditDepartment={setIsEditDepartment}
        />
      )}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </>
  );
}
