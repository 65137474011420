import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { FormErrorMessage } from "../../components";
import { AllocationFlowType, AppConstants, BookingFlowType, } from "../../configs";
import { BookingPolicyResponseModel } from "../../models";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  policy?: BookingPolicyResponseModel;
  setPolicy: (value: any) => void;
  onUpdateBookingPolicy: (companyId: string, value: any) => any;
  onUpdateSuccess?: any;
  setOnUpdateSuccess?: any;
};

export function ModalJobSettings(props: Props): JSX.Element {
  const { show, handleShow, policy, onUpdateBookingPolicy, setPolicy, onUpdateSuccess, setOnUpdateSuccess, } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<BookingPolicyResponseModel>();
  const allocationFlowSelect = [
    { label: "Internal Pool", value: AllocationFlowType.InternalPool },
    { label: "Shared Pool", value: AllocationFlowType.SharedPool },
  ];

  const bookingFlowSelect = [
    { label: "Manual", value: BookingFlowType.Manual },
    { label: "Direct Assignment", value: BookingFlowType.DirectAssignment },
    { label: "Offer Confirm", value: BookingFlowType.OfferConfirm },
  ];
  const onSubmitHandler = (form: BookingPolicyResponseModel) => {
    form = {
      ...form,
      allocationFlow: form.allocationFlow
        ? Number(form.allocationFlow)
        : policy?.allocationFlow ?? 0,
      bookingFlow: form.bookingFlow
        ? Number(form.bookingFlow)
        : policy?.bookingFlow ?? 0,
    };
    onUpdateBookingPolicy(policy?.companyId ?? "", form).then((res: any) => {
      if (res.status === AppConstants.Api.StatusOK) {
        handleShow(false);
        setPolicy({
          ...policy,
          allocationFlow: form.allocationFlow,
          bookingFlow: form.bookingFlow,
        });
      }
      setOnUpdateSuccess(!onUpdateSuccess);
    });
  };

  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Update Booking Flow</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-inline mb-3 flex-wrap align-items-sm-center">
                <div className="form-group col-12 pl-0 pr-0 ">
                  {/* col-sm-12 col-md-12 col-lg-12 col-xl-12   pr-sm-0 pr-md-0 pr-lg-0   mb-0 mb-sm-0 mb-md-0*/}

                  {/* <input
                    className="d-none"
                    name="allocationFlow"
                    ref={register({ required: true })}
                    defaultValue={
                      policy
                        ? allocationFlowSelect?.find(
                          (x) => x.value === policy?.allocationFlow
                        )?.value
                        : allocationFlowSelect[0].value
                    }
                  />
                  <Select
                    className={` ${errors.allocationFlow?.type === "required" ? 'field-required' : ""} w-100`}

                    // className="w-100"
                    options={allocationFlowSelect}
                    defaultValue={
                      policy
                        ? allocationFlowSelect?.find(
                          (x) => x.value === policy?.allocationFlow
                        )
                        : allocationFlowSelect[0]
                    }
                    closeMenuOnSelect={true}
                    onChange={(value) => {
                      setValue("allocationFlow", value?.value, {
                        shouldValidate: true,
                      });
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  /> */}
                  {/* {errors.allocationFlow && <FormErrorMessage message="required" />} */}
                </div>
              </div>
              <div className="form-inline mb-3 flex-wrap align-items-sm-center">
                <div className="form-group col-12 pl-0 pr-0 ">
                  <input
                    className="d-none"
                    {...register('bookingFlow', { required: true })}
                    defaultValue={
                      policy
                        ? bookingFlowSelect?.find(
                          (x) => x.value === policy?.bookingFlow
                        )?.value
                        : bookingFlowSelect[2].value
                    } />
                  <Select
                    //placeholder="Select Language"
                    className={` ${errors.bookingFlow?.type === "required"
                      ? "field-required"
                      : ""
                      } w-100`}
                    // className="w-100"
                    options={bookingFlowSelect}
                    defaultValue={
                      policy
                        ? bookingFlowSelect?.find(
                          (x) => x.value === policy?.bookingFlow
                        )
                        : bookingFlowSelect[2]
                    }
                    closeMenuOnSelect={true}
                    onChange={(value) => {
                      setValue("bookingFlow", Number(value?.value), {
                        shouldValidate: true,
                      });
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {errors.bookingFlow && (
                    <FormErrorMessage message="required" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="login-from-submit-btn btn btn-primary text-uppercase upload-button"
          >
            Change Settings
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
