import { useEffect, useState } from 'react';
import { logger } from '../../utils';

export function useDetectNetwork() {
  const [haveNetwork, setHaveNetwork] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      logger({ message: 'Network re-established' });
      setHaveNetwork(true);
    };
    const handleOffline = () => {
      logger({ message: 'Network lost' });
      setHaveNetwork(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return {
    haveNetwork,
  };
}
