import React from "react";
import useSWR from "swr";
import { PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaMedicalHistoryData, WithPagination } from "../../models";
import { OptionMapper } from "../../utils";

type Props = {
	pageSize: number;
	pageNo: number;
	searchText?: string;
	setLoaderProgress: any;
};

export function useFunctionalityMedicalHistory(showModal?: boolean) {
	const medicalHistoryUrl = PlatformApi.Medical.GetAll();

	const { data: apiMedicalHistoryList } = useSWR<ApiSchemaMedicalHistoryData[]>(showModal ? medicalHistoryUrl : null, () =>
		AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaMedicalHistoryData[]>>>(medicalHistoryUrl)
			.then((r) => r.data.data?.data)
			.catch((e) => {
				console.error(e);
				return e;
			})
	);

	const optionMappedMedicalHistoryList = React.useMemo(() => {
		const data = Array.isArray(apiMedicalHistoryList)
			? OptionMapper.mapperFunc(apiMedicalHistoryList)
			: [];
		return data;
	}, [apiMedicalHistoryList]);

	return {
		optionMappedMedicalHistoryList
	};
}
