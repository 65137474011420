import { useLocation } from 'react-router-dom';
import { CompanyProfileTopBar } from "../../features/CompanyProfile";
import { VendorCompanyTab, VendorLanguageTab } from '../../features/VendorProfile';
import { VendorRoutParam } from "../../models";
import { useFunctionalityVendors } from "../hooks";

export function PageVendorsProfile(): JSX.Element {
  const location = useLocation<VendorRoutParam>()
  const { companyId, vendroName } = location?.state

  // const vendorId = AppRouteUi.Vendors.Profile.useParam();

  // const { vendorList } = useFunctionalityVendors({ parentCompanyId: companyId ?? "" });
  // const getVendorName = (vendorId: string) => {
  //   return vendorList.find(x => x.id === vendorId)?.name;
  // }
  return (
    <>
      <CompanyProfileTopBar companyName={vendroName} heading="Vendors" />

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <nav>
                  <div className="nav nav-tabs" id="profile-nav-tab" role="tablist">
                    <a className="nav-link active" id="nav-profile-vendor-company-tab" data-toggle="tab" href="#nav-profile-vendor-company" role="tab" aria-controls="nav-profile-vendor-company" aria-selected="true">Company</a>
                    {/* <a className="nav-link" id="nav-profile-vendor-language-tab" data-toggle="tab" href="#nav-profile-vendor-language" role="tab" aria-controls="nav-profile-vendor-language" aria-selected="false">Language</a> */}
                  </div>
                </nav>
                <div className="tab-content profile-tab-content-container" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-profile-vendor-company" role="tabpanel" aria-labelledby="nav-profile-vendor-company-tab">
                    <VendorCompanyTab parentCompanyId={companyId} />
                  </div>
                  <div className="tab-pane fade show" id="nav-profile-vendor-language" role="tabpanel" aria-labelledby="nav-profile-vendor-language-tab">
                    {/* <VendorLanguageTab /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}