import { AppConstants } from "../configs/appConstants";
import {
  ApiSchemaChangeCallForwardingOption,
  ApiSchemaVendorCompanyData,
} from "../models/api/vendor";

export class ApiDataMapper {
  static VendorChangeCallForwardingOption(
    data: ApiSchemaVendorCompanyData
  ): ApiSchemaChangeCallForwardingOption {
    let model: ApiSchemaChangeCallForwardingOption = {
      companyId: data.id,
      isCallForwardingEnabled: data.isCallForwarding,
    };
    return model;
  }

  static UserRoleOptionMapper(data: string) {
     
    let userRoles: Object;
    if (data === AppConstants.UserRoles.Admin) {
      userRoles = AppConstants.BabelUserSetRole;
    } else if (data === AppConstants.UserRoles.ResellerAdmin) {
      userRoles = AppConstants.ResellerUserSetRole;
    } else {
      userRoles = AppConstants.CompanyUserSetRole;
    }
    return userRoles;
  }

  static MapUserRole(data: string): string {
    if (
      data === AppConstants.UserRoles.Admin ||
      data === AppConstants.UserRoles.ResellerAdmin ||
      data === AppConstants.UserRoles.CompanyAdmin
    )
      return AppConstants.GenericUserRole.Admin;
    else if (
      data === AppConstants.UserRoles.CompanyFinance ||
      data === AppConstants.UserRoles.ResellerFinance ||
      data === AppConstants.UserRoles.Finance
    )
      return AppConstants.GenericUserRole.Finance;
    else if (
      data === AppConstants.UserRoles.ResellerManager ||
      data === AppConstants.UserRoles.Manager
    )
      return AppConstants.GenericUserRole.Manager;
    else if (data === AppConstants.UserRoles.User)
      return AppConstants.GenericUserRole.User;

    return "";
  }
}
