import React, { useEffect } from "react";
import { AppRouteUi } from "../../configs/appRoute";
import {
  ApiSchemaJobDataById,
  ApiSchemaJobList,
  ApiSchemaLPagedJob as ApiSchemaPagedJob,
  ColumnSelectionsModel,
  TableColumnExtend,
} from "../../models";
import { ModalViewJob } from "../modals";
import moment from "moment";
import { AppConstants, BookingStatusColor, BookingStatusType, GenderType, PlatformType, topLoaderProgress } from "../../configs";
import { AdminPageTemplate } from "../admin";
import { useFunctionalityJobDetailsById, useFunctionalityJobGetById, } from "../../pages/hooks";
import { useHistory } from "react-router";
import LoadingBar from "react-top-loading-bar";
import { useAppSelector } from "../../core";
import { shallowEqual } from "react-redux";
import { ColumnToolTip } from "./CreateJob/columToolTip";
import { AssetSvg } from "../../assets";

type Props = {
  jobListData?: ApiSchemaJobList[] | null;
  pageSize?: number;
  setPageSize?: any;
  pageIndex?: number;
  setPageIndex?: any;
  setSearchText?: any;
  loaderProgress: any;
  setLoaderProgress: any;
  hasPagingData?: boolean;
  setHasPagingData?: any;
  setFilterData?: Function;
  setFilterDataChange?: Function;
  columnSelection?: ColumnSelectionsModel[];
  totalJobList?: number;
  mutateUrl?: string;
};

export function JobTableWeb(props: Props): JSX.Element {
  const { jobListData, loaderProgress, setLoaderProgress, hasPagingData, setHasPagingData, setFilterData, setFilterDataChange, columnSelection, totalJobList, mutateUrl, } = props;
  const [show, setShow] = React.useState(false);
  const [selectedJob, setSelectedJob] = React.useState<ApiSchemaJobDataById>();
  const { LoadByJobId } = useFunctionalityJobGetById({
    setLoaderProgress,
  });
  const history = useHistory();
  const data = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole,
  }));
  const onChangePageIndex = (index: number) => {
    props.setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    props.setPageSize(size);
  };

  const jobTableColumns: TableColumnExtend<ApiSchemaJobList>[] = [
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "SerialNumber"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={row.serialNumber ?? "-"} />;
      },
      width: "100px",
      sortable: true,
      sortField: "SerialNo",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "SerialNumber"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Date"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return (
          <ColumnToolTip
            data={row.date ? moment(row.date?.toString()).format("lll") : "-"}
          />
        );
      },
      width: "180px",
      sortable: true,
      sortField: "Start",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Date"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Reseller"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={row.reseller ?? "-"} />;
      },
      sortable: true,
      sortField: "Reseller.name",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Reseller"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "RequestedBy"
            )?.name
          }
        />
      ),
      width: "150px",
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={row.requestedBy ?? "-"} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "RequestedBy"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Company"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={row.company ?? "-"} />;
      },
      sortable: true,
      sortField: "Company.name",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Company"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "ServiceType"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={PlatformType[row.serviceType ?? 0]} />;
      },

      width: "90px",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "ServiceType"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Language"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return <ColumnToolTip data={row.language ?? "-"} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Language"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "RequestType"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => {
        return (
          <>
            <ColumnToolTip data={row.requestType ? row.requestType : "Any"} />
          </>
        );
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "RequestType"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Gender"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => (
        <ColumnToolTip data={GenderType[row.gender ?? 0]} />
      ),

      width: "90px",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Gender"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "AssignedToNames"
            )?.name
          }
        />
      ),
      width: "170px",
      selector: (row: ApiSchemaJobList) => {
        return (
          <ColumnToolTip
            data={
              row.assignedToNames?.length
                ? row.assignedToNames?.join(", ")
                : "-"
            }
          />
        );
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "AssignedToNames"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "Status"
            )?.name
          }
        />
      ),
      width: "105px",
      selector: (row: ApiSchemaJobList) => {
        return (
          <>
            <span
              className={`badge p-1 rounded-0 text-uppercase text-white`}
              style={{
                backgroundColor:
                  row.status === BookingStatusType.Open
                    ? BookingStatusColor.Open
                    : row.status === BookingStatusType.Offered
                      ? BookingStatusColor.Offered
                      : row.status === BookingStatusType.Confirmed
                        ? BookingStatusColor.Confirmed
                        : row.status === BookingStatusType.Closed
                          ? BookingStatusColor.Closed
                          : row.status === BookingStatusType.Cancelled
                            ? BookingStatusColor.Cancelled
                            : row.status === BookingStatusType.Unfulfilled
                              ? BookingStatusColor.Unfulfilled
                              : row.status === BookingStatusType.NoShow
                                ? BookingStatusColor.NoShow
                                : row.status === BookingStatusType.Billed
                                  ? BookingStatusColor.Billed
                                  : row.status === BookingStatusType.Paid
                                    ? BookingStatusColor.Paid
                                    : "",
              }}
            >
              {BookingStatusType[row.status ?? 0]}
            </span>
          </>
        );
      },
      sortable: true,
      sortField: "Status",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "Status"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "BookingCustomField1"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => row.bookingCustomField1 ?? "-",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "BookingCustomField1"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "BookingCustomField2"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => row.bookingCustomField2 ?? "-",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "BookingCustomField2"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "BookingCustomField3"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => row.bookingCustomField3 ?? "-",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "BookingCustomField3"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === "BookingCustomField4"
            )?.name
          }
        />
      ),
      selector: (row: ApiSchemaJobList) => row.bookingCustomField4 ?? "-",
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === "BookingCustomField4"
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: <ColumnToolTip data={"Actions"} />,
      allowOverflow: true,
      width: "120px",
      selector: (row: ApiSchemaJobList) => {
        return (
          <>
            <div className="d-flex align-items-center ">
              <div
                className="btn svg-color pl-0"
                title="view"
                onClick={() => {
                  if (setLoaderProgress)
                    setLoaderProgress(topLoaderProgress.start);

                  LoadByJobId(row.id).then((data) => {
                    if (setLoaderProgress)
                      setLoaderProgress(topLoaderProgress.complete);
                    setSelectedJob(data);
                    setShow(true);
                  });
                }}
              >
                <span >
                  <AssetSvg.ViewProfile />
                </span>
              </div>
              {(data.userRole === AppConstants.UserRoles.Admin ||
                data.userRole === AppConstants.UserRoles.Finance ||
                data.userRole === AppConstants.UserRoles.ResellerAdmin ||
                data.userRole === AppConstants.UserRoles.ResellerFinance) && (
                  <div
                    className="btn svg-color"
                    title="Edit"
                    onClick={() => {
                      history.push({
                        pathname: AppRouteUi.EditJobs.Root,
                        state: {
                          jobId: row.id,
                          loaderProgress: loaderProgress,
                        },
                      });
                    }}
                  >
                    <span >
                      <AssetSvg.Edit />
                    </span>
                  </div>
                )}
            </div>
          </>
        );
      },
    },
  ];

  const stateData = useAppSelector(
    (state) => ({
      bookingIdForModalData: state.notification.bookingIdForModalData,
      bookingModalShow: state.notification.bookingModalShow,
    }),
    shallowEqual
  );

  const { bookingIdForModalData, bookingModalShow } = stateData;

  useEffect(() => {
    if (bookingIdForModalData) {
      LoadByJobId(bookingIdForModalData).then((data) => {
        setSelectedJob(data);
        setShow(bookingModalShow!);
      });
    }
  }, [bookingModalShow]);

  const handleBookingRowClick = (row: ApiSchemaJobList) => {
    if (setLoaderProgress) setLoaderProgress(topLoaderProgress.start);
    LoadByJobId(row.id).then((data) => {
      if (setLoaderProgress) setLoaderProgress(topLoaderProgress.complete);

      setSelectedJob(data);
      setShow(true);
    });
  };

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Jobs"
        tableHeaders={jobTableColumns}
        tableData={jobListData || []}
        // setCreateModalShow={setCreateModalShow}
        // searchText={searchText}
        // handleSearch={handleSearch}
        pageIndex={props.pageIndex}
        setPageIndex={props.setPageIndex}
        pageSize={props.pageSize}
        count={totalJobList}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hideSearchBar={true}
        // callLogInfoBoxData={callLogInfoBoxData}
        handleBookingRowClick={handleBookingRowClick}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        setFilterDataForAnyTable={setFilterData}
        setFilterDataChangeForAnyTable={setFilterDataChange}
      />
      {selectedJob && (
        <ModalViewJob
          show={show}
          handleShow={setShow}
          selectedJob={selectedJob}
          mutateUrl={mutateUrl}
        />
      )}
    </>
  );
}
