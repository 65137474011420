import React from "react";
import { AppRouteUi } from "../../configs/appRoute";
import { toast } from "react-toastify";

export function CompanyConferenceInfo() {
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";

  const companyId = AppRouteUi.Company.Profile.useParam();

  const makeScheduleUrl = `${rootUrl}${AppRouteUi.UserCalendar.Root}/${companyId}`;
  const copyToWebsiteDescription = `<iframe width="992px" height="640px" src="${makeScheduleUrl}" frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

  return (
    <>
      <div className="col-12 word-wrap">
        <div className="text-secondary"> {copyToWebsiteDescription}</div>
        <hr />
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-outline-dark border-grey d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                      flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                      justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
            onClick={() => {
              navigator.clipboard.writeText(copyToWebsiteDescription);
              toast.success("Copied!");
            }}
          >
            Copy
          </button>
        </div>

      </div>
    </>
  );
}
