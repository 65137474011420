import { useCallback } from 'react';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { AppConstants, OpiApi } from '../../configs';
import { AxiosAuth } from '../../core';
import { ITypeCustomInputPayload } from '../../models';
import { UtilsJQuery } from '../../utils';

export function useFunctionalityGetCustomInput(callerId?: string) {
  const onCustomInputData = useCallback(async () => {
    try {
      const res = callerId ? await AxiosAuth.get(OpiApi.CustomInput.GetCustomInput(callerId)) : null;

      if (res?.status === AppConstants.Api.StatusOK) {
      }
      return Promise.resolve(res?.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);
  return {
    onCustomInputData,
  };
}
export async function updateCustomInputFields(callId?: string, payload?: ITypeCustomInputPayload, handleShow?: Function) {
  const btnLoading = UtilsJQuery.Ladda(".custom-field-submit-btn-save");
  btnLoading.start?.();
  const updateCustomInputFieldsUrl = OpiApi.CustomInput.UpdateCustomInputFields(callId)
  try {
    const response = await AxiosAuth.post(updateCustomInputFieldsUrl, payload);
    if (response.status === AppConstants.Api.StatusOK) {
      toast.success('Successfully updated!');
      handleShow && handleShow(false);
    }
    btnLoading.stop?.();
  }
  catch (error) {
    toast.error("Failed to update!");
    btnLoading.stop?.();
  }
}