import { AppRouteUi } from "../../../configs";
import { PageCallOutflow } from "../../../pages";

export function OutflowTab(): JSX.Element {
  const companyId = AppRouteUi.Reseller.Profile.useParam();

  return (
    <>
      <div className="row flex-wrap mb-3">
        <div className="col">
          <PageCallOutflow companyId={companyId ?? ""} />
        </div>
      </div>

    </>
  );
}