import React from "react";
export function useFunctionalityAddressFieldValidation() {
    const [addressLengthError, setAddressLengthError] = React.useState<string>("");
    const handleAddressLength = (e: any) => {
        if (e.target.value.length == 1 || e.target.value.length > 128) {
            setAddressLengthError("Please enter address between 2 to 128 characters long");
        } else {
            setAddressLengthError('');
        }
    }
    return {
        handleAddressLength,
        addressLengthError
    }
}