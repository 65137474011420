import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AssetSvg } from "../../assets";
import { FormErrorMessage } from "../../components";
import { ApiBodyResetPassword } from "../../models";
import { useFunctionalityAuth } from "../hooks";
import { useHistory, useLocation } from 'react-router-dom';
import { AppRouteUi } from "../../configs/appRoute";

export function PageResetPassword(): JSX.Element {
  const { onReset } = useFunctionalityAuth();
  const history = useHistory();
  const location = useLocation<string>();

  const { register, handleSubmit, formState: { errors } } = useForm<ApiBodyResetPassword>();

  const [inputReadOnly, setInputReadyOnly] = React.useState(true);
  const [errMsg, setErrMsg] = React.useState<string>();
  const clearErrMsg = React.useCallback(() => setErrMsg(undefined), []);

  const onSubmit = (form: ApiBodyResetPassword) => {
    if (form.newPassword === form.confirmPassword) {
      delete form.confirmPassword;

      const newForm: ApiBodyResetPassword = {
        ...form,
        email: location.state,
      };

      onReset(newForm);
    } else {
      setErrMsg("Password doesn't match");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setInputReadyOnly(false);
    }, 500);
  }, []);

  return <>
    <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
      <div className="container-md container-login-sm py-3 px-0 px-sm-0 px-md-3 px-lg-3">
        <div className="row m-auto">
          <div className="col-12 text-center my-5">
            <h1 className=" text-uppercase page-title">Update password</h1>
          </div>
        </div>
        <div className="row m-auto login-container-card">
          <div className="col-12 bg-white">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="login-logo m-3">
                  <AssetSvg.VRILogo />
                </div>
              </div>
              <div className="col-12 ">
                <div className="border border-warning rounded overflow-hidden p-2 text-center mx-4 mb-2">
                  {/* <span>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-warning" />
                </span> */}
                  <span>
                    {/* You need to change your password to activate your account. */}
                    A verification code sent to your email address
                  </span>
                </div>
              </div>
              <div className="col-12">
                <form
                  className="login-form p-4"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                >
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        {...register('confirmationCode', {
                          required: true,
                        })}
                        placeholder="Verification code"
                        readOnly={inputReadOnly}
                        autoFocus />
                    </div>
                    {errors.confirmationCode && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        {...register('newPassword', {
                          required: true,
                        })}
                        placeholder="New Password"
                        readOnly={inputReadOnly}
                        onClick={clearErrMsg} />
                    </div>
                    {errors.newPassword && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text text-muted">
                          <FontAwesomeIcon icon={faLock} />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        {...register('confirmPassword', {
                          required: true,
                        })}
                        placeholder="Confirm Password"
                        readOnly={inputReadOnly}
                        onClick={clearErrMsg} />
                    </div>
                    {errors.confirmPassword && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                  <div className="form-group d-flex justify-content-sm-between align-items-sm-center flex-wrap mb-1 pt-3">
                    <a
                      onClick={() => history.push(AppRouteUi.Login.Root)}
                      className="btn btn-link font-sz-14 btn-site-sm mt-3 mt-sm-0 px-0 order-1 order-sm-0"
                    >
                      {`Back to login`}
                    </a>
                    <button
                      type="submit"
                      className="ladda-button reset-pass-submit-btn btn btn-purple btn-site-sm font-sz-14"
                    >
                      Submit
                    </button>
                    {errMsg && <FormErrorMessage message={errMsg} />}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}
