import React from "react";
import useSWR from "swr";
import { PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiSchemaStateData, ApiResponse } from "../../models";
import { OptionMapper } from "../../utils";

export function useFunctionalityState(selectedCountry: string, showModal?: boolean) {
  const stateUrl = PlatformApi.Address.States(selectedCountry);
  const { data: apiStateData } = useSWR<ApiSchemaStateData[]>(showModal ? stateUrl : null, () =>
    AxiosAuth.get<ApiResponse<ApiSchemaStateData[]>>(stateUrl)
      .then((r) => r.data.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const stateList = React.useMemo(() => {
    const data = Array.isArray(apiStateData)
      ? OptionMapper.mapperFunc(apiStateData)
      : [];
    return data;
  }, [apiStateData]);

  return {
    stateList,
  };
}
