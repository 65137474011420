import { SessionStorageUtils } from './sessionStorage';

interface ILogger {
  message: string;
  logLevel?: 'info' | 'warn' | 'error' | 'debug' | 'log';
  data?: unknown;
  stringify?: boolean;
  prettify?: boolean;
}

export function logger({
  logLevel,
  data,
  message,
  stringify = true,
  prettify = false,
}: ILogger) {
  const date = new Date();
  const formattedData = data ? formatData({ data, stringify, prettify }) : '';
  const dateTimeStamp = date.toISOString().split('T');
  const millisecondTimeStamp = date.getTime();
  const log = `${dateTimeStamp[0]} ${dateTimeStamp[1]} [${millisecondTimeStamp}]: ${message}`;
  const logData = `${log} ${formattedData ?? '.'}`;

  SessionStorageUtils.log = [logData];

  switch (logLevel) {
    case 'info':
      console.info(logData);
      break;
    case 'error':
      console.error(logData);
      break;
    case 'warn':
      console.warn(logData);
      break;
    case 'debug':
      console.debug(logData);
      break;
    default:
      console.log(logData);
  }
}

function formatData({
  data,
  stringify,
  prettify,
}: Pick<ILogger, 'prettify' | 'stringify' | 'data'>) {
  if (stringify) {
    if (prettify) {
      return JSON.stringify(data, null, 2);
    } else {
      return JSON.stringify(data);
    }
  } else {
    return data;
  }
}
