import React from 'react';
import { AppConstants, OpiApi, PlatformApi } from '../../configs';
import { AxiosAuth } from '../../core';
import { ApiResponse } from '../../models';
import {
  CallRequestModel,
  CallUpdateRequestModel,
  ThreeWayCallReDialRequest,
} from '../../models/api/Call';

export function useFunctionalityCalls() {
  const oncreate = React.useCallback(async (model: CallRequestModel) => {
    try {
      const response = await AxiosAuth.post<ApiResponse<string>>(
        PlatformApi.Call.Create(),
        model
      );
      if ((response.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(response.data.data);
      }
    } catch (e) {
      console.error(e);
      // return Promise.reject(e);
    }
  }, []);
  const onUpdate = React.useCallback(
    async (callId: string, model: CallUpdateRequestModel) => {
      try {
        const response = await AxiosAuth.put(
          PlatformApi.Call.Update(callId),
          model
        );
        if ((response.status = AppConstants.Api.StatusOK)) {
          return Promise.resolve(response.data);
        }
      } catch (e) {
        console.error(e);
        // return Promise.reject(e);
      }
    },
    []
  );
  const onStartInitiate = React.useCallback(
    async (callSid: string, deviceId: string) => {
      try {
        const response = await AxiosAuth.post(
          OpiApi.Operator.StartInitiated(),
          {
            deviceId: deviceId,
            callSid: callSid,
          }
        );
        if ((response.status = AppConstants.Api.StatusOK)) {
          return Promise.resolve(response.data.data);
        }
      } catch (e) {
        console.error(e);
        //return Promise.reject(e);
      }
    },
    []
  );
  const onOutboundRedial = React.useCallback(
    async (data: ThreeWayCallReDialRequest) => {
      try {
        const response = await AxiosAuth.post(
          OpiApi.ThreeWayCall.OutboundRedial(),
          data
        );
        if ((response.status = AppConstants.Api.StatusOK)) {
          return Promise.resolve(response);
        }
      } catch (e) {
        console.error(e);
        //return Promise.reject(e);
      }
    },
    []
  );
  const onComplete = React.useCallback(async (callId: string) => {
    let endTime: string = new Date().toISOString();

    try {
      const response = await AxiosAuth.put(
        PlatformApi.Call.CompleteCall(callId, endTime)
      );
      if ((response.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(response.data.data);
      }
    } catch (e) {
      console.error(e);
      // return Promise.reject(e);
    }
  }, []);
  const onParticipantDisconnected = React.useCallback(
    async (callId: string, participantUserId: string) => {
      try {
        const response = await AxiosAuth.post(
          PlatformApi.Call.ParticipantDisconnected(callId, participantUserId)
        );
        if ((response.status = AppConstants.Api.StatusOK)) {
          return Promise.resolve(response.data.data);
        }
      } catch (e) {
        console.error(e);
        //return Promise.reject(e);
      }
    },
    []
  );

  return {
    oncreate,
    onUpdate,
    onComplete,
    onParticipantDisconnected,
    onStartInitiate,
    onOutboundRedial,
  };
}
