import React from "react";
import { AppRouteUi } from "../../../configs";
import { PageVendors } from "../../../pages";

export function VendorTab() {
  const companyId = AppRouteUi.Reseller.Profile.useParam();

  return (
    <>
      <div className="row flex-wrap mb-3">
        <div className="col">
          <PageVendors parentCompanyId={companyId ?? ""} />
        </div>
      </div>
    </>
  );
}
