import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { AppConstants, AppRouteUi, PlatformApi } from '../../configs';
import { ActionAuth, ActionCompanyContract, AxiosAuth, useAppDispatch } from '../../core';
import { ApiSchemaUpdateOnSite, ApiSchemaUpdateOpi, ApiSchemaCompanyContractSubscription, ApiSchemaUpdateVri, PlatformType, ApiSchemaLateFee } from '../../models';
import { UtilsJQuery } from '../../utils';
import { batch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getContractFeesPayload } from "./functionalityContractPayload";

export function useFunctionalityCompanyContract() {
  const dispatch = useAppDispatch();
  // const companyId = AppRouteUi.Company.Profile.useParam();
  // const resellerId = AppRouteUi.Reseller.Profile.useParam();
  let companyUrl = window.location.href.split("/")
  let companyId = companyUrl[companyUrl.length - 1]

  const subscriptionUrl = PlatformApi.CompanyContract.UpdateSubscription(companyId);
  const vriUrl = (id: string) => PlatformApi.CompanyContract.UpdateVri(id);
  const opiUrl = (id: string) => PlatformApi.CompanyContract.UpdateOpi(id);
  const onSiteUrl = (id: string) => PlatformApi.CompanyContract.UpdateOnSite(id);
  const fileUploadUrl = (id: string) => PlatformApi.CompanyContract.UploadDocument(id);

  // const urlToMutate = PlatformApi.Company.GetById(companyId);

  const onUpdateSubscription = useCallback(async (id: string, form: ApiSchemaCompanyContractSubscription) => {
    const btnLoading = UtilsJQuery.Ladda(".update-from-submit-btn");
    btnLoading.start?.();

    //  const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.put<ApiSchemaCompanyContractSubscription>(
        subscriptionUrl,
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        //mutate(urlToMutate);
        // mutate(resellerUrlToMutate);
        toast.success("Subscription updated successfully");
      }
    } catch (e) {
      toast.error("Subscription update failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);




  const onUpdateVri = useCallback(async (form: ApiSchemaUpdateVri | { isEnabled: boolean, platform: PlatformType, }, id: string, contractId?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".update-from-submit-btn");
    btnLoading.start?.();

    //const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.put<ApiSchemaUpdateVri>(
        vriUrl(contractId ?? ''),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        if (form?.isEnabled) {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateVriFees: true })));
        } else {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateVriFees: false })));
        }

        // mutate(urlToMutate);
        //mutate(resellerUrlToMutate);
        toast.success("Vri info updated successfully");
      }
    } catch (e) {
      toast.error("Vri info update failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);



  const onUpdateOpi = useCallback(async (form: ApiSchemaUpdateOpi | { isEnabled: boolean, platform: PlatformType, }, id: string, contractId?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".update-from-submit-btn");
    btnLoading.start?.();

    // const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.put<ApiSchemaUpdateOpi>(
        opiUrl(contractId ?? ''),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        if (form?.isEnabled) {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateOpiFees: true })));
        } else {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateOpiFees: false })));
        }

        // mutate(urlToMutate);
        //mutate(resellerUrlToMutate);
        toast.success("Opi info updated successfully");
      }
    } catch (e) {
      toast.error("Opi info update failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);



  const onUpdateOnSite = useCallback(async (form: ApiSchemaUpdateOnSite | { isEnabled: boolean, platform: PlatformType, }, id: string, contractId?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".update-from-submit-btn");
    btnLoading.start?.();

    const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.put<ApiSchemaUpdateOnSite>(
        onSiteUrl(contractId ?? ''),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        if (form?.isEnabled) {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateOnsiteFees: true })));
        } else {
          dispatch(ActionCompanyContract.SetUpdateContractFees(getContractFeesPayload({ isUpdateOnsiteFees: false })));
        }

        // mutate(urlToMutate);
        // mutate(resellerUrlToMutate);
        toast.success("On Site contract info updated successfully");
      }
    } catch (e) {
      toast.error("On Site contract info update failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);


  const onUpdateLateFee = useCallback(async (id: string, form: ApiSchemaLateFee) => {
    const lateFeeUrl = PlatformApi.CompanyContract.UpdateLateFee(id);
    const btnLoading = UtilsJQuery.Ladda(".update-from-submit-btn");
    btnLoading.start?.();

    const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.put<ApiSchemaLateFee>(
        lateFeeUrl,
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(urlToMutate);
        // mutate(resellerUrlToMutate);
        toast.success("Late Fee updated successfully");
      }
    } catch (e) {
      toast.error("Late Fee update failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);


  const onUploadContract = useCallback(async (form: FormData, id: string, contractId?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".upload-button");
    btnLoading.start?.();

    const resellerUrlToMutate = PlatformApi.Reseller.GetById(id);
    try {
      const res = await AxiosAuth.post<FormData>(
        fileUploadUrl(contractId ?? ''),
        form
      );

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(urlToMutate);
        // mutate(resellerUrlToMutate);
        toast.success("Uploaded successfully");
      }
    } catch (e) {
      toast.error("Upload failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);
  const onDeleteContract = useCallback(async (resellerId?: string, id?: string) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();

    const resellerUrlToMutate = PlatformApi.Reseller.GetById(resellerId ?? '');
    try {
      const res = await AxiosAuth.delete(
        PlatformApi.CompanyContract.DeleteDocument(id || "")
      );
      if (res.status === AppConstants.Api.StatusOK) {
        //  mutate(urlToMutate);
        //  mutate(resellerUrlToMutate);
        toast.success("document deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);



  return {
    onUpdateSubscription,
    onUpdateVri,
    onUpdateOpi,
    onUpdateOnSite,
    onUpdateLateFee,
    onUploadContract,
    onDeleteContract
  }
}