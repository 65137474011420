import React, { useEffect, useState } from "react";
import { Pagination } from "../components/common";
import DataTable from "react-data-table-component";
import { topLoaderProgress } from "../configs";
import { CallLogResponseModel } from "../models/api/Call";
import { CallStatusType } from "../configs";
import { useFunctionalityCallSummary } from "../pages/hooks";
import { NoDataComponent } from "./admin/noDataComponent";

type Props = {
  pageHeader: string;
  tableHeaders: any[];
  tableData: any[];
  setCreateModalShow?: (value: boolean) => void;
  pageSize?: number;
  pageIndex?: number;
  count?: number;
  onChangePageIndex?: any;
  onChangePageSize?: any;
  isPageEnabled?: boolean | true;
  loaderProgress: number;
  setCallSummaryData?: any;
  setLoaderProgress?: any;
  setShow?: any;
};

export function CallLogTableDataWeb(props: Props): JSX.Element {
  const { tableData, tableHeaders, pageSize, pageIndex, count, onChangePageIndex, onChangePageSize, isPageEnabled, pageHeader, loaderProgress, setCallSummaryData, setLoaderProgress, setShow } = props;
  const [noDataComponentText, setNoDataComponentText] = useState("Loading...");
  useEffect(() => {
    if (loaderProgress == topLoaderProgress.complete) {
      if (tableData?.length == 0) {
        setNoDataComponentText("There are no records to display");
      }
      else {
        setNoDataComponentText("Loading...");
      }
    }
  }, [loaderProgress, pageSize, pageIndex, tableData]);


  const { LoadById } = useFunctionalityCallSummary();
  const handleCallLogDetailsView = (callLog: any) => {
    if (setLoaderProgress)
      setLoaderProgress(topLoaderProgress.start);
    LoadById(callLog.id).then((data) => {
      if (setLoaderProgress)
        setLoaderProgress(topLoaderProgress.complete);
      if (setCallSummaryData && data) setCallSummaryData(data);
      setShow(true);
    });
  };
  const conditionalRowStyles = [
    {
      when: (row: CallLogResponseModel) => row.callStatusType == CallStatusType.Missed
        || row.callStatusType == CallStatusType.Rejected,
      style: {
        color: 'red',
      },
    },
  ]

  return (
    <>
      <div className="col-12">
        <div className="component-card">
          <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left ">
            <div>
              <span>{pageHeader}</span>
            </div>
          </div>
          <div className="component-card-body border p-0 overflow-hidden">
            <div className="call-log-table-container-lg  d-md-block d-lg-block d-xl-block">
              <div className="table-responsive">
                <DataTable
                  noDataComponent={
                    <NoDataComponent title={noDataComponentText} />
                  }
                  columns={tableHeaders}
                  conditionalRowStyles={conditionalRowStyles}
                  data={tableData}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  onRowClicked={handleCallLogDetailsView} />
                {isPageEnabled ? (
                  <Pagination
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    count={count}
                    onChangePageIndex={onChangePageIndex}
                    onChangePageSize={onChangePageSize}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
