import { UserStatusType } from "../../models/api/userlist";
import { PlatformType } from "../appConstants";

export const operatorPresence = [
  { value: "", label: "Any" },
  { value: "incall", label: "INCALL" },
  { value: "online", label: "ONLINE" },
  { value: "busy", label: "BUSY" },
  { value: "offline", label: "OFFLINE" },
  { value: "away", label: "AWAY" },
  { value: "disabled", label: "DISABLED" },
];
export const operatorAccountStatus = [
  { value: UserStatusType.Any, label: "Any" },
  { value: UserStatusType.Enabled, label: "Enabled" },
  { value: UserStatusType.Disabled, label: "Disabled" },
  { value: UserStatusType.Pending, label: "Pending" },
];
export const operatorRating = [
  { value: "", label: "Any Rating" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];
export const operatorCallPlatform = [
  { value: PlatformType.All, label: "Any Platform" },
  { value: PlatformType.VRI, label: "vri" },
  { value: PlatformType.OPI, label: "opi" },
  { value: PlatformType.OnSite, label: "on Site" },
];
