import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiSchemaMenuSettingsData } from "../../../models/api/menuSettings";
import { ApiSchemaProfileData } from "../../../models/api/profile";
import { StorageAuth } from "../../LocalStorage/StorageAuth";
import { OperatorStatusType } from "../../../models/api/Operator";

type TSliceAuth = {
  isLoggedIn: boolean;
  profile?: ApiSchemaProfileData;
  VRItoken?: string;
  menuSettings?: ApiSchemaMenuSettingsData;
  sessionId?: string;
  localStream?: MediaStream;
  screenTrack?: any;
};

function InitSliceAuth(): TSliceAuth {
  return {
    isLoggedIn: !!StorageAuth.AccessToken,
    profile: StorageAuth.ProfileData,
    menuSettings: StorageAuth.MenuSettingsData,
    sessionId: StorageAuth.SessionId ?? "",
    localStream: StorageAuth.LocalStream,
    screenTrack: StorageAuth.ScreenTrack,
  };
}

const sliceAuth = createSlice({
  name: "sliceAuth",
  initialState: InitSliceAuth(),
  reducers: {
    SetIsLoggedIn: (state, action: PayloadAction<boolean>): TSliceAuth => ({
      ...state,
      isLoggedIn: action.payload,
    }),
    SetProfileData: (
      state,
      action: PayloadAction<ApiSchemaProfileData>
    ): TSliceAuth => {
      StorageAuth.ProfileData = action.payload;
      return { ...state, profile: action.payload };
    },
    SetUserStatus(state, action: PayloadAction<OperatorStatusType>) {
      if(!state.profile) {
        state.profile = {} as ApiSchemaProfileData;
      }
      state.profile.userStatus = action.payload;
    },
    SetMenuSettingsData: (
      state,
      action: PayloadAction<ApiSchemaMenuSettingsData>
    ): TSliceAuth => {
      StorageAuth.MenuSettingsData = action.payload;
      return { ...state, menuSettings: action.payload };
    },
    SetVRItoken: (state, action: PayloadAction<string>): TSliceAuth => {
      StorageAuth.TwilioToken = action.payload;
      return { ...state, VRItoken: action.payload };
    },
    SetSessionId: (state, action: PayloadAction<string>): TSliceAuth => {
      StorageAuth.SessionId = action.payload;
      return { ...state, sessionId: action.payload };
    },
    setLocalStream: (state, action: PayloadAction<MediaStream>): TSliceAuth => {
      StorageAuth.LocalStream = action.payload;
      return { ...state, localStream: action.payload };
    },
    setScreenShareTrack: (state, action: PayloadAction<any>): TSliceAuth => {
      StorageAuth.ScreenTrack = action.payload;
      return { ...state, screenTrack: action.payload };
    },
    resetData: () => {
      return InitSliceAuth();
    }
  },
});

export const ReducerAuth = sliceAuth.reducer;
export const ActionAuth = sliceAuth.actions;

function uuid4(): string {
  throw new Error("Function not implemented.");
}
