import React from "react";
import { CriteriaType } from "../../../configs";
import { DialPadKeyMappingModel, Option, unknownDialPad } from "../../../models";
import { AssetSvg } from "../../../assets";
type Props = {
  onClickConfigureDialKeyMapping: any;
  dialPadKeyMappingModels?: DialPadKeyMappingModel[];
  editable: boolean | true;
  languageList: Option[];
};
export function LanguageMap(props: Props) {
  const { onClickConfigureDialKeyMapping, dialPadKeyMappingModels, editable, languageList } = props;
  let sortedDialPadMappingModels: DialPadKeyMappingModel[] = dialPadKeyMappingModels ? [...dialPadKeyMappingModels] : [];
  sortedDialPadMappingModels.sort((a, b) =>
    (parseInt(a?.key) > parseInt(b?.key)) ? 1 : ((parseInt(b?.key) > parseInt(a?.key)) ? -1 : 0));
  const getLanguageNameByValue = (value: string) => {
    return languageList?.find(language => language?.value === value)?.label;
  }

  return (
    <div className="component-card mb-3">
      <div className="component-card-header border-top border-right border-left collapsed py-3" id="headingLanguage"
        data-toggle="collapse"
        data-target="#languageSection"
        aria-expanded="true"
        aria-controls="languageSection"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-grow-1">Language Mapping</div>
          <div className="d-flex justify-content-between align-items-center">

            {editable && (
              <button title="Edit" className="btn btn-link  text-site font-sz-16 svg-color pr-0" onClick={(event) => {
                event.stopPropagation();
                onClickConfigureDialKeyMapping(CriteriaType.Language);
              }
              }>
                <span >
                  <AssetSvg.Edit />
                </span>
              </button>
            )}
            <button className="btn btn-link text-site font-sz-16 svg-color pr-0">
              <div className="closed-panel-icon">
                <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M399.147 175.147C397.163 173.147 394.804 171.56 392.204 170.477C389.605 169.394 386.816 168.836 384 168.836C381.184 168.836 378.395 169.394 375.796 170.477C373.196 171.56 370.836 173.147 368.853 175.147L271.147 272.853C269.163 274.853 266.804 276.44 264.204 277.523C261.605 278.606 258.816 279.164 256 279.164C253.184 279.164 250.395 278.606 247.796 277.523C245.196 276.44 242.836 274.853 240.853 272.853L143.147 175.147C141.163 173.147 138.804 171.56 136.204 170.477C133.605 169.394 130.816 168.836 128 168.836C125.184 168.836 122.395 169.394 119.796 170.477C117.196 171.56 114.836 173.147 112.853 175.147C108.88 179.144 106.65 184.551 106.65 190.187C106.65 195.823 108.88 201.23 112.853 205.227L210.773 303.147C222.773 315.132 239.04 321.864 256 321.864C272.96 321.864 289.227 315.132 301.227 303.147L399.147 205.227C403.12 201.23 405.35 195.823 405.35 190.187C405.35 184.551 403.12 179.144 399.147 175.147V175.147Z" fill="black" />
                </svg>
              </div>
              <div className="open-panel-icon">
                <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M384 330.667C381.192 330.683 378.409 330.145 375.81 329.083C373.211 328.021 370.847 326.457 368.853 324.48L271.147 226.56C269.163 224.56 266.804 222.973 264.204 221.89C261.605 220.807 258.816 220.25 256 220.25C253.184 220.25 250.395 220.807 247.796 221.89C245.196 222.973 242.837 224.56 240.853 226.56L143.147 324.48C139.129 328.497 133.681 330.754 128 330.754C122.319 330.754 116.87 328.497 112.853 324.48C108.836 320.463 106.579 315.014 106.579 309.333C106.579 306.52 107.133 303.735 108.21 301.136C109.286 298.537 110.864 296.176 112.853 294.187L210.773 196.48C222.932 184.799 239.139 178.275 256 178.275C272.861 178.275 289.068 184.799 301.227 196.48L399.147 294.187C401.146 296.17 402.733 298.529 403.816 301.129C404.899 303.729 405.457 306.517 405.457 309.333C405.457 312.15 404.899 314.938 403.816 317.538C402.733 320.137 401.146 322.497 399.147 324.48C397.153 326.457 394.789 328.021 392.19 329.083C389.591 330.145 386.808 330.683 384 330.667Z" fill="black" />
                </svg>
              </div>
            </button>
          </div>
        </div>
        {/* </button> */}
      </div>
      <div
        id="languageSection"
        className="collapse "
        aria-labelledby="headingLanguage"
        data-parent="#ivrTab"
      >
        <div className="component-card-body border p-3">
          <div className="row">
            <div className="col-12">
              {dialPadKeyMappingModels?.length ?
                <div className="table-responsive">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="text-black text-underline text-site-normal py-0 pb-2"
                        >
                          Dialpad number
                        </th>
                        <th
                          scope="col"
                          className="text-black text-underline text-site-normal py-0 pb-2"
                        >
                          Language
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedDialPadMappingModels?.map((item) => (
                        <>
                          {
                            item?.value !== unknownDialPad.value && <tr>
                              <td className="py-2">{item.key}</td>
                              <td className="py-2">{getLanguageNameByValue(item?.value)}</td> {/* TODO: will try to check if { item.name } is providing correct value from API, then print { item.name } instantly */}
                            </tr>
                          }
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                : <h4 className=" py-2 text-muted font-weight-light text-center m-auto">no data found</h4>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
