export const AccessList = {
  BABEL_ADMIN: [
    'Call',
    'Job_Settings',
    'Internal_CallLog',
    'Overflow_CallLog',
    'Company',
    'Reseller',
    'Call_Outflow',
    'User',
    'TIN',
    'Language',
    'Language_Packs',
    'Skill',
    'Modality',
    'AppointmentCategory',
    'AppointmentDescription',
    'Audit_Logs',
    'My_Profile',
    'My_Account',
    'Finance_Company',
    'Operator',
    'Vendor'
  ],
  BABEL_MANAGER: [
    'Call',
    'Job_Settings',
    'Call_Log',
    'My_Profile',
  ],
  BABEL_FINANCE: [
    'Call',
    'Job_Settings',
    'Internal_CallLog',
    'Overflow_CallLog',
    'My_Profile',
    'My_Account',
    'Finance_Company',
    'Operator',
  ],
  BABEL_RESELLER_ADMIN: [
    'Call',

    'Settings',
    'Internal_CallLog',
    'Inflow_CallLog',
    'Outflow_CallLog',
    'Company',
    'User',
    // 'TIN',
    'Language_Packs',
    'Skill',
    'Modality',
    'AppointmentCategory',
    'AppointmentDescription',
    //'Audit_Logs',
    'My_Profile',
    'My_Account',
    'Finance_Company',
    'Operator',
  ],
  BABEL_RESELLER_MANAGER: [
    'Call',
    'Settings',
    'Call_Log',
    'My_Profile',
  ],
  BABEL_RESELLER_FINANCE: [
    'Call',
    'Internal_CallLog',
    'Overflow_CallLog',
    'My_Profile',
    'My_Account',
    'Finance_Company',
    'Operator'
  ],
  BABEL_COMPANY_ADMIN: [
    'Call',
    'Settings',
    'Call_Log',
    'Company_Profile',
    'My_Account'
  ],
  BABEL_COMPANY_FINANCE: [
    'Call',
    'Call_Log',
    'My_Profile',
    'Company_Profile',
    'My_Account'
  ],
  BABEL_COMPANY_USER: [
    'Call',
    'Call_Log',
    'My_Profile',
  ],
  BABEL_OPERATOR: [
    'Call_Log',
    'My_Profile'
  ]
}

export const AccessControlTemplate = {
  Call: false,
  Settings: false,
  Job_Settings: false,
  Call_Log: false,
  Internal_CallLog: false,
  Overflow_CallLog: false,
  Inflow_CallLog: false,
  Outflow_CallLog: false,
  Company: false,
  Reseller: false,
  Call_Outflow: false,
  User: false,
  TIN: false,
  Language: false,
  Language_Packs: false,
  Skill: false,
  Modality: false,
  AppointmentCategory: false,
  AppointmentDescription: false,
  Audit_Logs: false,
  My_Profile: false,
  Company_Profile: false,
  My_Account: false,
  Finance_Company: false,
  Operator: false,
  Vendor: false
}