import React, { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalCreateJob } from "..";

type props = {
  operatorName: string
}

export function CallSummarySchedule(prop: props): JSX.Element {
  const { operatorName } = prop;
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  return (
    <div className="card">
      <div className="card-header px-0 py-1" id="headingCallSchedule">
        <button
          className="btn btn-link btn-block text-left text-decoration-none text-site siteAccordion collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#call-schedule-collapse"
          aria-expanded="true"
          aria-controls="call-schedule-collaps"
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <span className="font-weight-bold">Schedule Call </span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="closed-panel-icon"
              />
              <FontAwesomeIcon
                icon={faChevronUp}
                className="open-panel-icon"
              />
            </div>
          </div>
        </button>
      </div>
      <div
        className="component-card-body rounded-bottom  border-left border-right border-bottom collapse"
        id="call-schedule-collapse"
      >
        <div className="row">
          <div className="col-12 pb-4">
            Want to schedule a future call with <span>{operatorName}</span>?
          </div>
          <div className="col-12 text-right">
            <button className="btn btn-primary text-uppercase" onClick={() => { setShowScheduleModal(true) }}>
              schedule now
            </button>
          </div>
        </div>
      </div>
      {
        showScheduleModal && <ModalCreateJob handleShow={setShowScheduleModal} show={showScheduleModal} />
      }
    </div>

  );
}
