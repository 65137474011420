import { AppConstants, AppRouteUi, BookingNotificationType } from "../../../configs";
import { ActionNotification, incrementAsync } from "../../../core";
import { SocketNotificationModel } from "../../../models/socket";

interface PropsType {
    responseModel: SocketNotificationModel<any>;
    dispatch: any;
    history: any;
    data: any;
}

export function BookingSocketNotification({responseModel, dispatch, history, data}: PropsType) {
    dispatch(incrementAsync())
    dispatch(ActionNotification.SetPageNo(1))
    
    if(responseModel.Action === BookingNotificationType.BookingClosed){
        // if there are more user then we should change redirection logic
        const contentData = JSON.parse(responseModel.Data.Content)
        history.push({
            pathname: data.userRole === AppConstants.UserRoles.Operator ? AppRouteUi.CallLog.Root : AppRouteUi.Home.Root,
            state: {
              bookingId: contentData.BookingId,
            },
        });
    }
}