import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { AssetSvg } from "../../assets";
import { AppRouteUi, PaginationSettings } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ColumnToolTip, ModalConfirmDelete, ModalCreateVendor, ModalEditVendorPhone, } from "../../features";
import { ApiSchemaVendorData } from "../../models";
import { useFunctionalityVendors } from "../hooks";
import { useFunctionalityPagedVendors } from "../hooks/functionalityVendors";
type Props = {
  parentCompanyId: string;
};
export function PageVendors(props: Props): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const { parentCompanyId } = props;
  const { profile } = useAppSelector((state) => state.auth);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [editPhoneModalShow, setEditPhoneModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [dataToDelete, setDataToDelete] = useState<string>("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const [selectedVendor, setSelectedVendor] =
    useState<ApiSchemaVendorData>(Object);

  const { onDeleteVendor } = useFunctionalityVendors({
    parentCompanyId: parentCompanyId ? parentCompanyId : profile?.companyId,
  });
  const { pagedVendorList, pagedVendorListCount, pagedVendorUrl } =
    useFunctionalityPagedVendors({
      parentCompanyId: parentCompanyId ? parentCompanyId : profile?.companyId,
      pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
    });

  useEffect(() => {
    if (pagedVendorList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedVendorList]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const onDeleteHandler = () => {
    onDeleteVendor(dataToDelete)
      .then(() => {
        setDeleteModalShow(false);
        mutate(pagedVendorUrl);
      })
      .catch((e) => console.error(e));
  };

  const vendorsTableColumns = [
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaVendorData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: "Phone",
      selector: (row: ApiSchemaVendorData) => (
        <>
          <span className="mr-3">
            <ColumnToolTip data={row.phoneNumber} />
          </span>
          <button
            className="btn btn-link svg-color px-0 "
            onClick={() => {
              setSelectedVendor(row);
              setEditPhoneModalShow(true);
            }}
          >
            <span >
              <AssetSvg.Edit />
            </span>
          </button>
        </>
      ),
    },
    {
      name: "Actions",
      allowOverflow: true,
      selector: (row: ApiSchemaVendorData) => {
        return (
          <>
            <Link
              to={{
                pathname: AppRouteUi.Vendors.Profile.Load(row.id),
                state: {
                  companyId: row.parentCompanyId,
                  vendroName: row.name,
                },
              }}
              className="btn btn-link svg-color  px-0"
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </Link>
            <button
              className="btn btn-link svg-color py-0 "
              onClick={() => {
                setDeleteModalShow?.(true);
                setDataToDelete(row.id);
              }}
            >
              <span >
                <AssetSvg.Delete />
              </span>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Vendor"
        modalControlBtnLabel="Create Vendor"
        tableHeaders={vendorsTableColumns}
        tableData={pagedVendorList || []}
        setCreateModalShow={setCreateModalShow}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedVendorListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
      {createModalShow ? (
        <ModalCreateVendor
          parentCompanyId={parentCompanyId}
          show={createModalShow}
          handleShow={setCreateModalShow}
          pagedVendorUrl={pagedVendorUrl}
        />
      ) : null}
      {editPhoneModalShow ? (
        <ModalEditVendorPhone
          parentCompanyId={parentCompanyId}
          show={editPhoneModalShow}
          handleShow={setEditPhoneModalShow}
          selectedVendor={selectedVendor}
          pagedVendorUrl={pagedVendorUrl}
        />
      ) : null}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </>
  );
}
