import { Participant } from 'twilio-video';
import { AssetSvg } from '../../../assets';
import NetworkQualityLevel from '../../NetworkQualityLevel/NetworkQualityLevel';

interface ICallIndicatorProps {
  participant: Participant;
  audioTrackMute: boolean;
}

export function CallIndicator({
  participant,
  audioTrackMute,
}: ICallIndicatorProps) {
  return (
    <div className="user-conference-status">
      <div className="d-flex align-items-center flex-btn-container-right">
        <div className="signal-indicator">
          <NetworkQualityLevel participant={participant} />
        </div>

        {audioTrackMute && (
          <div className="mute-indicator">
            <AssetSvg.MuteButton />
          </div>
        )}
      </div>
    </div>
  );
}
