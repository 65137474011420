import { callStatues, callStatuses, filterGenders, genders, Statuses } from "../modals/jobFormData";
import Select from "react-select";
import {
  useFunctionalityCompanyNames,
  useFunctionalityLanguage,
  useFunctionalityLanguageListNames,
  useFunctionalitySkill,
  useFunctionalitySkillListNames,
} from "../../pages/hooks";
import { useAppSelector } from "../../core";
import { shallowEqual } from "react-redux";
import { AppConstants } from "../../configs/appConstants";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import {
  ApiSchemaColumn,
  ApiSchemaFilter,
  ApiSchemaFormFilter,
} from "../../models/api/filter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import moment from "moment";
import { useFunctionalityResellerNames } from "../../pages/hooks/functionalityReseller";
import { OptionMapper } from "../../utils";

type Props = {
  setFilterData: any;
  setLoaderProgress: any;
  setFilterDataChange?: any;
  pageName?: 'jobsPage' | 'callLogPage';
  setPageIndex: Function;
};

export function CommonFilter(props: Props): JSX.Element {
  const { setFilterData, setFilterDataChange, pageName, setPageIndex } = props;
  const { onCompanyNames } = useFunctionalityCompanyNames();
  const { onResellerNames } = useFunctionalityResellerNames();
  const { onLanguageListName } = useFunctionalityLanguageListNames();
  const { onSkillListName } = useFunctionalitySkillListNames();
  const [companyNames, setCompanyNames] = useState<any>();
  const [languageNameList, setLanguageNameList] = useState<any>([]);
  const [resellerNames, setResellerNames] = useState<any[]>([]);
  const [skillListNames, setSkillListName] = useState<any>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm<ApiSchemaFormFilter>();

  const userRole = useAppSelector(
    (state) => state.auth.profile?.userRole,
    shallowEqual
  );

  const companyId = useAppSelector(
    (state) => state.auth.profile?.companyId,
    shallowEqual
  );
  React.useEffect(() => {
    if ((userRole === AppConstants.UserRoles.Admin ||
      userRole === AppConstants.UserRoles.Manager ||
      userRole === AppConstants.UserRoles.ResellerAdmin ||
      userRole === AppConstants.UserRoles.ResellerManager) || !(userRole === AppConstants.UserRoles.Operator || userRole === AppConstants.UserRoles.CompanyAdmin
        || userRole === AppConstants.UserRoles.CompanyFinance || userRole === AppConstants.UserRoles.User)) {
      onCompanyNames(companyId ?? "").then((res) => {
        setCompanyNames([
          {
            value: "",
            label: "Any",
          },
          ...res.data,
        ]);
      });
    }
    if (pageName === 'callLogPage' && (userRole === AppConstants.UserRoles.Admin || userRole === AppConstants.UserRoles.Finance || userRole === AppConstants.UserRoles.Manager)) {
      onResellerNames()
        .then((res) => {
          setResellerNames([
            {
              value: "",
              label: 'Any'
            },
            ...res.data])
        })
    }
    if (pageName === 'jobsPage') {
      onSkillListName()
        .then((res) => {
          setSkillListName([
            {
              value: "",
              label: 'Any'
            },
            ...OptionMapper.mapperFunc(res.data)])
        })
    }
    if (userRole !== AppConstants.UserRoles.Operator) {
      onLanguageListName()
        .then((res) => {
          setLanguageNameList([
            {
              value: "",
              label: 'Any'
            },
            ...OptionMapper.mapperFunc(res.data)])
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFilterHandler = (data: ApiSchemaFormFilter) => {
    let columns: ApiSchemaColumn[] = [];
    let start, end;

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (value === "" || value === undefined) continue;
        if (key === "startDate") {
          if (value) {
            start = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            start = "";
          }
        } else if (key === "endDate") {
          if (value) {
            end = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            end = "";
          }
        } else {
          let column: ApiSchemaColumn = { columnName: key, value: value };
          columns.push(column);
        }
      }
      if (start || end) {
        let dateColumn: ApiSchemaColumn = {
          columnName: pageName === 'jobsPage' ? "Start" : "CreatedAt",
          dateQuery: { startDate: start, endDate: end },
        };
        columns.push(dateColumn);
      }
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setPageIndex(1);
      setFilterData((prevState: ApiSchemaFilter) => {
        return {
          ...prevState,
          filterQueries: columns,
        };
      });
      setFilterDataChange &&
        setFilterDataChange(Math.floor(Math.random() * 100) + 1);
    });
  };
  const [selectedLanguage, setSelectedLanguage] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedStatus, setSelectedStatus] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedCallStatus, setSelectedCallStatus] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedCompany, setSelectedCompany] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedGender, setSelectedGender] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedSkill, setSelectedSkill] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedResellerNames, setSelectedResellerNames] = useState<any>([{ value: "", label: "Any" }]);

  let dateLabel;
  switch (pageName) {
    case 'callLogPage':
      dateLabel = <label>Call Date</label>
      break;
    case 'jobsPage':
      dateLabel = <label>Booking Date</label>
      break;
    default:
      break;
  }

  let languageFormComponent = (
    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
      <label htmlFor="">Language</label>
      <input className="d-none" {...register('LanguageId', {})} />
      <Select
        options={languageNameList}
        value={selectedLanguage}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder="Select a language"
        onChange={(value) => {
          setValue("LanguageId", value?.value, {
            shouldValidate: true,
          });
          setSelectedLanguage([
            { label: value?.label, value: value?.value },
          ]);
        }}
      />
    </div>
  )

  let companyFormComponent = (
    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
      <label htmlFor="">Company</label>
      <input className="d-none" {...register('CompanyId', {})} />
      <Select
        options={companyNames}
        value={selectedCompany}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder="Select a company"
        onChange={(value) => {
          setValue("CompanyId", value?.value, {
            shouldValidate: true,
          });
          setSelectedCompany([
            { label: value?.label, value: value?.value },
          ]);
        }}
      />
    </div>
  )
  return (
    // <form onSubmit={handleSubmit(onSubmitStep1)} className="col-12 collapse mt-3" id="jobFilterPanel" >
    <div className="col-12 collapse mt-3" id="commonFilterPanel">
      <div className="card card-body border-radius-xl">
        <form onSubmit={handleSubmit(onFilterHandler)}>
          {dateLabel}
          <div className="row">
            <label htmlFor="bookingDate"></label>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start date"
                    className="form-control"
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(value) => {
                      setStartDate(value!);
                      field.onChange(value)
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    maxDate={endDate}
                  />
                )}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End date"
                    className="form-control"
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(value)=> {
                      field.onChange(value);
                      setEndDate(value ? value : undefined);
                    }}
                    minDate={startDate}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            {(pageName === 'callLogPage' && userRole !== AppConstants.UserRoles.Operator) ?
              languageFormComponent :
              (pageName === 'jobsPage') ? languageFormComponent : null
            }
            {pageName === 'jobsPage' ?
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Status</label>
                <input className="d-none" {...register('Status', {})} />
                <Select
                  options={Statuses}
                  value={selectedStatus}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select a status"
                  onChange={(value) => {
                    setValue("Status", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedStatus([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
              :
              (pageName === 'callLogPage' && !(userRole === AppConstants.UserRoles.Operator || userRole === AppConstants.UserRoles.CompanyAdmin
                || userRole === AppConstants.UserRoles.CompanyFinance || userRole === AppConstants.UserRoles.User)) ?
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                  <label htmlFor="">Call Status</label>
                  <input className="d-none" {...register('CallStatus', {})} />
                  <Select
                    options={callStatues}
                    value={selectedCallStatus}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a call status"
                    onChange={(value) => {
                      setValue("CallStatus", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedCallStatus([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
                : null
            }
            {pageName === 'jobsPage' ?
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Skill</label>
                <input className="d-none" {...register('SkillId', {})} />
                <Select
                  options={skillListNames}
                  value={selectedSkill}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select a skill"
                  onChange={(value) => {
                    setValue("SkillId", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedSkill([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
              :
              pageName === 'callLogPage' && (userRole === AppConstants.UserRoles.Admin || userRole === AppConstants.UserRoles.Finance || userRole === AppConstants.UserRoles.Manager) ?
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                  <label htmlFor="">Reseller Name</label>
                  <input className="d-none" {...register('ResellerId', {})} />
                  <Select
                    options={resellerNames}
                    value={selectedResellerNames}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a reseller"
                    onChange={(value) => {
                      setValue("ResellerId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedResellerNames([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
                : null
            }
            {pageName === 'jobsPage' &&
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Gender</label>
                <input className="d-none" {...register('Gender', {})} />
                <Select
                  options={filterGenders}
                  value={selectedGender}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select a gender"
                  onChange={(value) => {
                    setValue("Gender", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedGender([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
            }
            {(pageName === 'jobsPage' && (userRole === AppConstants.UserRoles.Admin ||
              userRole === AppConstants.UserRoles.Manager ||
              userRole === AppConstants.UserRoles.ResellerAdmin ||
              userRole === AppConstants.UserRoles.ResellerManager)) ?
              companyFormComponent :
              (pageName === 'callLogPage' && !(userRole === AppConstants.UserRoles.Operator || userRole === AppConstants.UserRoles.CompanyAdmin
                || userRole === AppConstants.UserRoles.CompanyFinance || userRole === AppConstants.UserRoles.User)) ?
                companyFormComponent : null
            }
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-link text-uppercase filter-reset-btn"
                onClick={() => {
                  // setFilterData({ filterQueries: [] });
                  setFilterData((prevState: ApiSchemaFilter) => {
                    return {
                      ...prevState,
                      filterQueries: []
                    }
                  });
                  setFilterDataChange && setFilterDataChange(Math.floor(Math.random() * 100) + 1);
                  // reset();
                  setValue('startDate', undefined)
                  setValue('endDate', undefined)
                  setValue('CompanyId', "")
                  setValue('ResellerId', "")
                  setValue("LanguageId", "")
                  setValue("SkillId", "")
                  setValue("Gender", "")
                  setValue("CallStatus", "")
                  setValue("Status", "")
                  setSelectedCompany([]);
                  setSelectedGender([]);
                  setSelectedLanguage([]);
                  setSelectedSkill([]);
                  setSelectedStatus([]);
                  setSelectedCallStatus([]);
                  setSelectedResellerNames([]);
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary text-uppercase mx-3"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
