const videoCall = {
  desktop: {
    constraints: {
      aspectRatio: 1.77777778,
      frameRate: 24,
      width: 1280,
      height: 720,
    },
    bandwidthProfile: {
      video: {
        mode: 'grid',
      },
    },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  },
  mobile: {
    constraints: {
      aspectRatio: 1.77777778,
      frameRate: 24,
      facingMode: 'user',
      width: 640,
      height: 480,
    },
    bandwidthProfile: {
      video: {
        mode: 'grid',
        maxSubscriptionBitrate: 2500000,
      },
    },
    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
  },
};

export const TWILIO_CONFIG = {
  videoCall,
};
