import React, { useState } from 'react';
import { AssetSvg } from '../../assets';
import { AppRouteUi, FileBucketURL, OperatorCriteriaType } from '../../configs';
import { ApiSchemaOperatorMedicalHistory } from '../../models';
import { useFunctionalityOperatorProfile } from '../../pages/hooks';
import { useFunctionalityMedicalHistory } from '../../pages/hooks/functionalityOperatorMedicalHistory';
import {
  certificateExpirationInDays,
  certificateIsExpired,
  dateFormatInUSA,
} from '../../utils';
import {
  ModalConfirmDelete,
  ModalSingleDropdown,
  ModalUploadCertificate,
} from '../modals';

type Props = {
  readonly: boolean;
  operatorId?: string;
};
export function MedicalHistory(props: Props) {
  const operatorId = props.operatorId
    ? props.operatorId
    : AppRouteUi.Operator.Profile.useParam();
  const [skillModalShow, handleMedicalModalShow] = useState<boolean>(false);
  const [deleteModalShow, handleDeleteModalShow] = useState<boolean>(false);
  const [showUploadCertificateModal, handleShowUploadCertificateModal] =
    useState<boolean>(false);
  const [skillModalHeader, setMedicalModalHeader] = useState<string>('');
  const [submitType, setSubmitType] = useState<string>('');
  const [medicalHistoryId, setMedicalHistoryId] = useState<string>('');
  const [operatorMedicalHistoryId, setOperatorMedicalHistoryId] =
    useState<string>('');
  const [
    operatorMedicalHistoryIdToDelete,
    setOperatorMedicalHistoryIdToDelete,
  ] = useState<string>('');
  const [
    initialOnSelectDropdownMedicalHistory,
    setInitialOnSelectDropdownMedicalHistory,
  ] = useState<any>('');
  const [criteriaId, setCriteriaId] = useState('');
  const [dropdownPlaceHolder, setDropdownPlaceHolder] = useState('');

  function forceDownload(url: string | URL, fileName: string) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }

  const {
    operatorMedicalHistoryList,
    onUpdateOperatorMedicalHistory,
    onDeleteOperatorMedicalHistory,
    onCreateOperatorMedicalHistory,
  } = useFunctionalityOperatorProfile({ operatorId });
  const medicalHistoryAlreadyAdd = operatorMedicalHistoryList.map(
    (medicalHistory) => {
      return medicalHistory?.medicalHistory?.name;
    }
  );
  const { optionMappedMedicalHistoryList } =
    useFunctionalityMedicalHistory(skillModalShow);

  const onClickMedicalHistory = () => {
    setSubmitType('Save');
    handleMedicalModalShow(true);
    setMedicalModalHeader('Add Medical History');
    setDropdownPlaceHolder('Select Vaccine');
    setInitialOnSelectDropdownMedicalHistory('');
  };

  const onChangeMedicalHistoryHandler = (medicalHistoryId: string) => {
    setMedicalHistoryId(medicalHistoryId);
    const selectedMedicalHistory = optionMappedMedicalHistoryList.find(
      (medicalHistory) => medicalHistory.value === medicalHistoryId
    );
    setInitialOnSelectDropdownMedicalHistory(selectedMedicalHistory?.label);
  };

  const onClickUpdateHandler = (
    operatorMedicalHistory: ApiSchemaOperatorMedicalHistory
  ) => {
    setSubmitType('Update');
    handleMedicalModalShow(true);
    setMedicalModalHeader('Update Medical Skill');
    setDropdownPlaceHolder('Select Vaccine');
    setInitialOnSelectDropdownMedicalHistory(
      operatorMedicalHistory?.medicalHistory?.name
    );
    setOperatorMedicalHistoryId(operatorMedicalHistory?.medicalHistoryId);
  };

  const onClickDeleteHandler = (id: string) => {
    setOperatorMedicalHistoryIdToDelete(id);
    handleDeleteModalShow(true);
  };

  const onDeleteHandler = () => {
    onDeleteOperatorMedicalHistory(operatorMedicalHistoryIdToDelete)
      .then(() => handleDeleteModalShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };

  const onSaveHandler = () => {
    submitType === 'Save'
      ? onCreateOperatorMedicalHistory(operatorId, medicalHistoryId)
          .then(() => handleMedicalModalShow(false))
          .catch((e: any) => {
            console.error(e);
          })
      : onUpdateOperatorMedicalHistory(
          operatorMedicalHistoryId,
          operatorId,
          medicalHistoryId
        )
          .then(() => handleMedicalModalShow(false))
          .catch((e: any) => {
            console.error(e);
          });
  };

  const medicalHistoryCertificateExpirationMap = new Map();
  const checkingMedicalHistoryCertificateExpiration = (
    medicalHistory: ApiSchemaOperatorMedicalHistory
  ) => {
    const expirationClass = certificateIsExpired(
      medicalHistory?.operatorCertificates ?? []
    );
    if (expirationClass) {
      medicalHistoryCertificateExpirationMap.set(
        medicalHistory?.id,
        expirationClass
      );
    }
    return expirationClass;
  };

  return (
    <div className="component-card mb-3 d-xl-block d-lg-block d-md-block">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right ">
        <div>Medical History</div>
        {props.readonly ? (
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase"
            onClick={() => onClickMedicalHistory()}
          >
            <div className="btn mr-1 svg-color" title="medicalHistoryAdd">
              <span>
                <AssetSvg.PlusCircle />
              </span>
            </div>
            <span className="font-sz-12 text-uppercase">Add Vaccine</span>
          </button>
        ) : null}
      </div>
      <div className="component-card-body border">
        <div className="row">
          <div className="col-12">
            {operatorMedicalHistoryList?.map(
              (medicalHistory: ApiSchemaOperatorMedicalHistory) => (
                <div
                  className="component-card mb-3 d-xl-block d-lg-block d-md-block d-sm-none bg-white shadow-0"
                  key={medicalHistory.id}
                >
                  <div
                    className={`component-card-header d-flex align-items-center justify-content-between border-top 
                                    border-left border-right bg-white text-capitalize ${checkingMedicalHistoryCertificateExpiration(
                                      medicalHistory
                                    )}`}
                  >
                    <div className="px-1">
                      {medicalHistory?.medicalHistory?.name}
                    </div>
                    {props.readonly ? (
                      <div>
                        <button
                          className="btn btn-link  text-site btn-sm text-uppercase"
                          onClick={() => onClickUpdateHandler(medicalHistory)}
                        >
                          <div
                            className="btn svg-color"
                            title="medicalHistoryEdit"
                          >
                            <span>
                              <AssetSvg.Edit />
                            </span>
                          </div>
                        </button>
                        <button
                          className="btn btn-link  text-site btn-sm text-uppercase"
                          onClick={() =>
                            onClickDeleteHandler(medicalHistory?.id)
                          }
                        >
                          <div
                            className="btn svg-color"
                            title="medicalHistoryDelete"
                          >
                            <span>
                              <AssetSvg.Delete />
                            </span>
                          </div>
                        </button>
                      </div>
                    ) : null}
                  </div>
                  {props.readonly ? (
                    <div
                      className={`component-card-body border ${medicalHistoryCertificateExpirationMap.get(
                        medicalHistory?.id
                      )}`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <button
                            className="btn btn-primary text-uppercase"
                            onClick={() => {
                              setCriteriaId(medicalHistory.id ?? '');
                              handleShowUploadCertificateModal(true);
                            }}
                          >
                            upload certificate
                          </button>
                        </div>
                        {medicalHistory &&
                          medicalHistory?.operatorCertificates &&
                          medicalHistory?.operatorCertificates?.length > 0 && (
                            <div className="col-12 mt-3">
                              <div className="table-responsive card">
                                <table className="table table-hover mb-0">
                                  <thead>
                                    <tr>
                                      <th className="border-top-0">
                                        Certification
                                      </th>
                                      <th className="border-top-0">
                                        Start Date
                                      </th>
                                      <th className="border-top-0">End Date</th>
                                      <th className="border-top-0">
                                        Time Left
                                      </th>
                                      {props.readonly ? <th></th> : null}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {medicalHistory?.operatorCertificates?.map(
                                      (file) => (
                                        <tr key={file.id}>
                                          <td className="py-1 align-middle">
                                            {file.name}
                                          </td>
                                          <td className="py-1 align-middle">
                                            {file.startDate
                                              ? dateFormatInUSA(file.startDate)
                                              : 'N/A'}
                                          </td>
                                          <td className="py-1 align-middle">
                                            {file.endDate
                                              ? dateFormatInUSA(file.endDate)
                                              : 'N/A'}
                                          </td>
                                          <td className="py-1 align-middle">
                                            {file?.endDate
                                              ? certificateExpirationInDays(
                                                  file?.endDate
                                                )
                                              : 'N/A'}{' '}
                                          </td>
                                          {props.readonly ? (
                                            <td className="text-right">
                                              {/* <a href={`${FileBucketURL.Root}${file.fileKey}`} target="_blank" className="btn btn-link text-site" role="button"> */}
                                              {/*<FontAwesomeIcon icon={faFileDownload} className="" />*/}
                                              <div
                                                className="btn svg-color"
                                                title="certificateDownload"
                                              >
                                                <span
                                                  onClick={() => {
                                                    forceDownload(
                                                      FileBucketURL.Root +
                                                        file.fileKey,
                                                      'certificate'
                                                    );
                                                  }}
                                                >
                                                  <AssetSvg.Download />
                                                </span>
                                              </div>
                                              {/* </a> */}
                                            </td>
                                          ) : null}
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            )}
          </div>
        </div>
      </div>
      {showUploadCertificateModal ? (
        <ModalUploadCertificate
          criteriaId={criteriaId}
          show={showUploadCertificateModal}
          handleShow={handleShowUploadCertificateModal}
          criteriaType={OperatorCriteriaType.MedicalHistory}
        />
      ) : null}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={handleDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
      {skillModalShow ? (
        <ModalSingleDropdown
          show={skillModalShow}
          handleShow={handleMedicalModalShow}
          header={skillModalHeader}
          dropDownList={optionMappedMedicalHistoryList}
          dropdownPlaceHolder={dropdownPlaceHolder}
          onSelectItem={initialOnSelectDropdownMedicalHistory}
          onChangeHandler={onChangeMedicalHistoryHandler}
          onSaveHandler={onSaveHandler}
          medicalHistoryAlreadyAdd={medicalHistoryAlreadyAdd}
        />
      ) : null}
    </div>
  );
}
