import { useFunctionalityChangedStatus } from "../../pages/hooks";
import { BookingStatusType } from "../../configs";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ChangeJobStatus } from "../../models";
import { ActionCalender, ActionNotification, useAppDispatch } from "../../core";
import { batch } from "react-redux";

type Props = {
    status?: BookingStatusType;
    jobId?: any;
    show: boolean;
    handleShow: (value: boolean) => void;
    jobViewModalHandleShow?: (value: boolean) => void;
}
export function ModalConfirmJobStatus(props: Props): JSX.Element {
    const { OnChangeStatus } = useFunctionalityChangedStatus();
    const { handleSubmit } = useForm<ChangeJobStatus>();
    const dispatch = useAppDispatch();
    const modalHideHandler = () => {
        props.handleShow(false);
        props.jobViewModalHandleShow && props.jobViewModalHandleShow(false);
        batch(() => {
            dispatch(ActionCalender.SetShowJobModal(false))
            dispatch(ActionNotification.SetBookingNotificationShow(false));
        })
    }
    const onChangeStatusHandler = (form: ChangeJobStatus) => {
        form = {
            ...form,
            statusType: props?.status
        };
        OnChangeStatus(props?.jobId ?? '', form)
            ?.then(() => {
                props.handleShow(false);
                modalHideHandler();
            }).catch(err => console.log(err));
    };

    return (
        <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
            <Modal.Body>Are you sure you want to change status?</Modal.Body>

            <Modal.Footer>
                <form onSubmit={handleSubmit(onChangeStatusHandler)}>
                    <button
                        type="button"
                        onClick={() => {
                            props.handleShow(false);
                        }}
                        className="btn btn-link text-uppercase text-site"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="login-from-submit-btn btn btn-primary text-uppercase"
                    >
                        Change
                    </button>
                </form>
            </Modal.Footer>
        </Modal>
    );
}
