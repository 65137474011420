import React from "react";
import { CommonUser } from "../..";

type Props = {
  companyId: string;
  adminRole: string;
  pageName?: string;
};
export function UsersTab(props: Props) {
  const { companyId, adminRole, pageName } = props;
  return (
    <>
      <div className="row flex-wrap pb-sm-0 mb-3">
        <div className="col">
          <CommonUser
            modalControlBtnLabel="Add User"
            pageTitle="Users"
            companyId={companyId}
            adminRole={adminRole}
            pageName={pageName}
          />
        </div>
      </div>
    </>
  );
}
