import { StorageLocalBase } from "./StorageBase";
import { calendarInterpreterSettings } from "../../models";
import { Option } from "../../models/options";

class StorageUserCalendarClass extends StorageLocalBase {
  StorageNamespace = "userCalendar";

  private readonly KeyCalendarDate: string = "calendar";
  private readonly KeyCompanyUser: string = "companyUser";
  private readonly KeyCalendarInterpreterSettingsData: string =
    "calendarInterpreterSettings";

  get CalendarDate(): Date | undefined {
    return this.getObject(this.KeyCalendarDate);
  }

  set CalendarDate(data: Date | undefined) {
    this.setObject(this.KeyCalendarDate, data);
  }

  get CompanyUser(): Option | undefined {
    return this.getObject(this.KeyCompanyUser);
  }

  set CompanyUser(data: Option | undefined) {
    this.setObject(this.KeyCompanyUser, data);
  }

  get CalendarInterpreterSettingsData():
    | calendarInterpreterSettings
    | undefined {
    return this.getObject(this.KeyCalendarInterpreterSettingsData);
  }

  set CalendarInterpreterSettingsData(
    data: calendarInterpreterSettings | undefined
  ) {
    this.setObject(this.KeyCalendarInterpreterSettingsData, data);
  }

  Clear(): void {
    this.CalendarDate = undefined;
    this.CompanyUser = undefined;
    this.CalendarInterpreterSettingsData = undefined;
  }
}

export const StorageUserCalendar = new StorageUserCalendarClass();
