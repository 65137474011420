import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  Step1CreateCompany,
  Step2CreateCompany,
  Step3CreateCompany,
  Step4CreateCompany,
  Step5CreateCompany,
  StepConfirmationCreateCompany,
  StepConfirmationCreateReseller,
} from ".";
import {
  CompanyType,
  DefaultStep1Data,
  DefaultStep5Data,
  StepNumber,
} from "../../configs";
import { ActionCompany, useAppSelector } from "../../core";
import { TypeCreateCompanyStep3n4 } from "../../models";
import { useFunctionalityCountry } from "../../pages/hooks/functionalityCountry";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  stepCounter: number;
  setStepCounter: (value: number) => void;
  companyType?: CompanyType;
  parentId?: string | "";
  title: string;
};
export function ModalCreateCompany(props: Props) {
  const {
    stepCounter,
    setStepCounter,
    handleShow,
    companyType,
    parentId,
    title,
    show
  } = props;

  // const { countryList } = useFunctionalityCountry(show);
  const [formResetHandler, seTformResetHandler] = useState(false);
  const dispatch = useDispatch();
  const step3Data: TypeCreateCompanyStep3n4[] = useAppSelector(
    (state) => state.company.companyStep3n4
  );
  const [contractPlatforms, setContractPlatforms] =
    useState<TypeCreateCompanyStep3n4[]>(step3Data);

  const step2Data = {}

  const onModalhideHandler = () => {
    dispatch(ActionCompany.SetCompanyStep1(DefaultStep1Data));
    dispatch(ActionCompany.SetCompanyStep2(step2Data));
    dispatch(ActionCompany.SetCompanyStep3n4(contractPlatforms));
    dispatch(ActionCompany.SetCompanyStep3n4(contractPlatforms));
    dispatch(ActionCompany.SetCompanyStep5(DefaultStep5Data));
    props.handleShow(false);
  }
  const companyModalBodyComponents = [
    <Step1CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      show={show}
      companyType={companyType}
    // countryList={countryList}
    />,
    <Step2CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <Step3CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <Step4CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
      parentId={parentId}
    />,
    <Step5CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <StepConfirmationCreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
      parentId={parentId}
    />,
  ];

  const resellerModalBodyComponents = [
    <Step1CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      show={show}
      handleShow={handleShow}
      companyType={companyType}
    // countryList={countryList}
    />,
    <Step2CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <Step3CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <Step5CreateCompany
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
    />,
    <StepConfirmationCreateReseller
      stepCounter={stepCounter}
      setStepCounter={setStepCounter}
      handleShow={handleShow}
      companyType={companyType}
      parentId={parentId}
    />,
  ];

  return (
    <Modal
      show={props.show}
      onHide={() => onModalhideHandler()}
      backdrop="static"
      centered
      size="lg"
      id="createCompanyModal"
    >
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      {companyType == CompanyType.Customer
        ? companyModalBodyComponents[stepCounter || StepNumber.firstStep]
        : resellerModalBodyComponents[stepCounter || StepNumber.firstStep]}
    </Modal>
  );
}
