import { useEffect, useState } from "react";
import { PaginationSettings } from "../../configs";
import { useFunctionalityGetBookingHistories } from "../../pages/hooks";
import BookingHistoryData from "./BookingHistoryData";

type Props = {
  bookingId: string;
  sweetChangeProp: any
}

export function JobHistory(props: Props): JSX.Element {
  const [pageSize, setPageSize] = useState<number>(PaginationSettings.initialPageSize)
  const [pageNo, setPageNo] = useState<number>(PaginationSettings.initialPageIndex)
  const [searchText, setSearchText] = useState<string>('')
  const { getBookingHistoriesData } = useFunctionalityGetBookingHistories(props.bookingId, pageSize, pageNo, searchText, props?.sweetChangeProp)
  useEffect(() => { }, [props.sweetChangeProp])

  return (
    <div className="component-card mb-3">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
        <div className="">History</div>
      </div>
      <div className="component-card-body border border-top-0 p-0">
        {getBookingHistoriesData ?
          <BookingHistoryData
            bookingHistoryData={getBookingHistoriesData?.data.data.data}
            count={getBookingHistoriesData?.data.data.count}
            pageSize={pageSize}
            pageNo={pageNo}
            setPageSize={setPageSize}
            setPageNo={setPageNo}
          />
          :
          <p className='text-center p-3'>Loading...</p>
        }
      </div>
    </div>
  );
}