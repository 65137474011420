import useSWR from 'swr';
import { PlatformApi } from '../../configs';
import { AxiosAuth } from '../../core';

export function useFunctionalityGetAllForCalendar(startDate: string, endDate?: string, statusType?: number) {
  const fetcher = (url: string) => AxiosAuth.get(url);

  const { data, mutate } = useSWR(
    startDate ? PlatformApi.Booking.GetAllForCalendar(startDate, endDate, statusType) : null,
    fetcher
  );

  return {
    getAllForCalendarData: data,
    mutateInGetAllForCalendar: mutate
  };
}