import { toast } from "react-toastify";
import { PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ExportPayload } from "../../models";


type Props = {
    tableType?: number;
    exportPayload?: ExportPayload;
    callCenterId?: string;
    companyId?: string;
    setLoaderProgress?: any;
};
export async function exportAsExcel(props: Props) {
    const {
        tableType,
        exportPayload,
        callCenterId,
        companyId,
        setLoaderProgress,
    } = props;
    try {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.start);
        const response = await AxiosAuth.post(
            PlatformApi.Export.ExportAsExcel(
                tableType!,
                callCenterId ?? "",
                companyId ?? ""
            ),
            exportPayload
        );
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        return response.data;
    } catch {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        toast.error("Failed to export!");
        return Promise.reject();
    }
}
export async function exportAsPdf(props: Props) {
    const {
        tableType,
        exportPayload,
        callCenterId,
        companyId,
        setLoaderProgress,
    } = props;
    try {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.start);
        const response = await AxiosAuth.post(
            PlatformApi.Export.ExportAsPdf(
                tableType!,
                callCenterId ?? "",
                companyId ?? ""
            ),
            exportPayload
        );
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        return response.data;
    } catch {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        toast.error("Failed to export!");
        return Promise.reject();
    }
}


export async function sendEmailReport(tableType: number, email: string, payload: ExportPayload, callCenterId?: string, companyId?: string) {
    try {
        const response = await AxiosAuth.post(PlatformApi.Export.SendEmailReport(tableType, email, callCenterId, companyId), payload);
        if (response.data.isSuccess)
            toast.success("Email Sent");
        else toast.error("Something went wrong");
        return response.data;
    } catch {
        toast.error("Failed to send email!");
        return Promise.reject();
    }
}

