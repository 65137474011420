import React from 'react';
import { FormErrorMessage } from '../../../components';
import { BookingCustomFieldType } from "../../../configs";

type Props = {
    register: any,
    setValue: any,
    errors: any,
    customField?: any;
    customFieldValues?: any;
    setCustomFieldValues?: any;
}
export function JobCustomFields(props: Props): JSX.Element {
    const { customField, customFieldValues, setCustomFieldValues } = props;
    const handleCustomFieldChange = (event: any, name: string, type: BookingCustomFieldType) => {
        let editedCustomField = customFieldValues.find((customField: any) => customField?.name === name);
        let filteredCustomFieldValues = [...customFieldValues];
        if (editedCustomField && event.target.value) {
            filteredCustomFieldValues = filteredCustomFieldValues.filter((customField: any) => customField !== editedCustomField);
            editedCustomField = {
                name: name,
                value: event.target.value,
                type: type
            }
        }
        setCustomFieldValues([
            ...filteredCustomFieldValues,
            editedCustomField ? editedCustomField : {
                name: name,
                value: event.target.value,
                type: type
            }
        ]);
    }

    return (
        <div className="form-group row">
            <label
                htmlFor=""
                className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
            >
                {customField?.name}
            </label>
            <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
                <input
                    className="form-control"
                    name="bookingCustomFields"
                    onBlur={(event) => handleCustomFieldChange(event, customField?.name, customField?.type)}
                    ref={props.register({ required: true })}
                    style={{ border: props.errors.bookingCustomFields ? '1px solid red' : '' }}
                />
                {props.errors.bookingCustomFields && props.errors.bookingCustomFields.type == "required" && (
                    <FormErrorMessage message="required" />
                )}
            </div>
        </div>
    );
}