import { WithPagination } from "./../../models/api/common";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  OperatorCriteriaType,
  PlatformApi,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  ApiSchemaFaceToFace,
  ApiSchemaOperatorLanguageRate,
  ApiSchemaSupportedPLatform,
  ApiSchemaUpdateOperatorLanguageRate,
  ApiSchemaOperatorSkill,
  ApiSchemaOperatorMedicalHistory,
  TypeCertificates,
  ApiSchemaOperatorRatings,
} from "../../models";
import { UtilsJQuery } from "../../utils";
import { OperatorRatingResponseModel } from "../../models/api/Operator";

type OperatorProps = {
  operatorId: string;
  setLoaderProgress?: any;
  criteriaType?: OperatorCriteriaType;
};

export function useFunctionalityOperatorProfile(props: OperatorProps) {
  const { operatorId, setLoaderProgress, criteriaType } = props;

  const getSupportedPlatformUrl =
    PlatformApi.Operator.GetSupportedPlatform(operatorId);
  const getFaceToFacePlatformUrl =
    PlatformApi.Operator.GetFaceToFaceAssignment(operatorId);
  const getLanguageRateUrl =
    PlatformApi.Operator.GetOperatorLanguage(operatorId);
  const getSkillUrl = PlatformApi.Operator.GetOperatorSkill(operatorId);

  const getMedicalHistoryUrl =
    PlatformApi.Operator.GetOperatorMedicalHistory(operatorId);
  const UploadCertificateUrl = PlatformApi.Operator.UploadCertificate();
  // const GetCertificateUrl = PlatformApi.Operator.GetCertificate(
  //   operatorId,
  //   criteriaType ?? 3
  // );

  const { data: apiSupportedPlatformData } = useSWR<ApiSchemaSupportedPLatform>(
    operatorId ? getSupportedPlatformUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<ApiSchemaSupportedPLatform>>(
        getSupportedPlatformUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const supportedPlatformData = useMemo(() => {
    const data: ApiSchemaSupportedPLatform =
      typeof apiSupportedPlatformData === "object"
        ? apiSupportedPlatformData
        : {};
    return data;
  }, [apiSupportedPlatformData]);

  const onUpdateSupportedPlatform = useCallback(
    async (operatorId: string, form: ApiSchemaSupportedPLatform) => {
      const btnLoading = UtilsJQuery.Ladda(".form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.put(
          PlatformApi.Operator.UpdateSupportedPlatform(operatorId),
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getSupportedPlatformUrl);
          toast.success(response?.data?.message);
          return Promise.resolve(response);
        }
      } catch (e) {
        toast.error("Failed to update support platform");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const { data: apiFaceToFaceAssignmentData } = useSWR<ApiSchemaFaceToFace>(
    operatorId ? getFaceToFacePlatformUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<ApiSchemaFaceToFace>>(
        getFaceToFacePlatformUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const faceToFaceAssignmentData = useMemo(() => {
    const data: ApiSchemaFaceToFace =
      typeof apiFaceToFaceAssignmentData === "object"
        ? apiFaceToFaceAssignmentData
        : {};
    return data;
  }, [apiFaceToFaceAssignmentData]);
  const onUpdateFaceToFaceAssignment = useCallback(
    async (operatorId: string, form: ApiSchemaFaceToFace) => {
      const btnLoading = UtilsJQuery.Ladda(".form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.put(
          PlatformApi.Operator.UpdateFaceToFaceAssignment(operatorId),
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getFaceToFacePlatformUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to update face to face assignment");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const { data: apiOperatorLanguageRateData } = useSWR<
    ApiSchemaOperatorLanguageRate[]
  >(operatorId ? getLanguageRateUrl : null, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<ApiResponse<ApiSchemaOperatorLanguageRate[]>>(
      getLanguageRateUrl
    )
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        return r.data.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  });
  const operatorLanguageList = useMemo(() => {
    const data: ApiSchemaOperatorLanguageRate[] = Array.isArray(
      apiOperatorLanguageRateData
    )
      ? apiOperatorLanguageRateData
      : [];
    return data;
  }, [apiOperatorLanguageRateData]);

  const onCreateLanguageRate = useCallback(
    async (form: ApiSchemaUpdateOperatorLanguageRate) => {
      const btnLoading = UtilsJQuery.Ladda(".form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(
          PlatformApi.Operator.CreateOperatorLanguage(),
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getLanguageRateUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to add operator language");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onUpdateLanguageRate = useCallback(
    async (
      operatorLanguageId: string,
      form: ApiSchemaUpdateOperatorLanguageRate
    ) => {
      const btnLoading = UtilsJQuery.Ladda(".form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.put(
          PlatformApi.Operator.UpdateOperatorLanguage(operatorLanguageId),
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getLanguageRateUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to update operator language");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const { data: apiOperatorSkillData } = useSWR<ApiSchemaOperatorSkill[]>(
    operatorId ? getSkillUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<ApiSchemaOperatorSkill[]>>(getSkillUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const operatorSkillList = useMemo(() => {
    const data: ApiSchemaOperatorSkill[] = Array.isArray(apiOperatorSkillData)
      ? apiOperatorSkillData
      : [];
    return data;
  }, [apiOperatorSkillData]);

  const onCreateOperatorSkill = useCallback(
    async (operatorId?: string, skillId?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(
          PlatformApi.Operator.CreateOperatorSkill(operatorId, skillId)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getSkillUrl);
          toast.success("Skill " + response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to create operator skill");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const onUpdateOperatorSkill = useCallback(
    async (operatorSkillId: string, operatorId?: string, skillId?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.put(
          PlatformApi.Operator.UpdateOperatorSkill(
            operatorSkillId,
            operatorId,
            skillId
          )
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getSkillUrl);
          toast.success("Skill " + response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to update operator skill");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const onDeleteOperatorLanguage = useCallback(
    async (operatorLanguageId: string) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.delete(
          PlatformApi.Operator.DeleteOperatorLanguage(operatorLanguageId)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getLanguageRateUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to delete operator language");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const onDeleteOperatorSkill = useCallback(async (operatorSkillId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.delete(
        PlatformApi.Operator.DeleteOperatorSkill(operatorSkillId)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(getSkillUrl);
        toast.success(response?.data?.message);
      }
    } catch (e) {
      toast.error("Failed to delete operator skill");
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const { data: apiOperatorMedicalHistoryData } = useSWR<
    ApiSchemaOperatorMedicalHistory[]
  >(operatorId ? getMedicalHistoryUrl : null, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<ApiResponse<ApiSchemaOperatorMedicalHistory[]>>(
      getMedicalHistoryUrl
    )
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        return r.data.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  });
  const operatorMedicalHistoryList = useMemo(() => {
    const data: ApiSchemaOperatorMedicalHistory[] = Array.isArray(
      apiOperatorMedicalHistoryData
    )
      ? apiOperatorMedicalHistoryData
      : [];
    return data;
  }, [apiOperatorMedicalHistoryData]);

  const onCreateOperatorMedicalHistory = useCallback(
    async (operatorId?: string, medicalHistoryId?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(
          PlatformApi.Operator.CreateOperatorMedicalHistory(
            operatorId,
            medicalHistoryId
          )
        );
        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getMedicalHistoryUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to create operator skill");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const onUpdateOperatorMedicalHistory = useCallback(
    async (
      operatorMedicalHistoryId: string,
      operatorId?: string,
      medicalHistoryId?: string
    ) => {
      const btnLoading = UtilsJQuery.Ladda(".form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.put(
          PlatformApi.Operator.UpdateOperatorMedicalHistory(
            operatorMedicalHistoryId,
            operatorId,
            medicalHistoryId
          )
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getMedicalHistoryUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to update operator skill");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const onDeleteOperatorMedicalHistory = useCallback(
    async (medicalHistoryId: string) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.delete(
          PlatformApi.Operator.DeleteOperatorMedicalHistory(medicalHistoryId)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(getMedicalHistoryUrl);
          toast.success(response?.data?.message);
        }
      } catch (e) {
        toast.error("Failed to delete operator skill");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onUploadCertificate = useCallback(
    async (form: FormData, type: OperatorCriteriaType) => {
      const btnLoading = UtilsJQuery.Ladda(".upload-button");
      btnLoading.start?.();
      try {
        const res = await AxiosAuth.post<FormData>(UploadCertificateUrl, form);

        if (res.status === AppConstants.Api.StatusOK) {
          if (type == OperatorCriteriaType.Language) mutate(getLanguageRateUrl);
          else if (type == OperatorCriteriaType.Skill) mutate(getSkillUrl);
          if (type == OperatorCriteriaType.MedicalHistory)
            mutate(getMedicalHistoryUrl);
          toast.success("Certificate Uploaded successfully");
        }
      } catch (e) {
        toast.error("Certificate Upload failed");
      }
      btnLoading.stop?.();

      return Promise.resolve(null);
    },
    []
  );

  // const { data: apiCertificateData } = useSWR<TypeCertificates[]>(
  //   GetCertificateUrl,
  //   () => {
  //     // setLoaderProgress(topLoaderProgress.start);
  //     return AxiosAuth.get<ApiResponse<TypeCertificates[]>>(GetCertificateUrl)
  //       .then((r) => {
  //         // setLoaderProgress(topLoaderProgress.complete);
  //         return r.data.data;
  //       })
  //       .catch((e) => {
  //         // setLoaderProgress(topLoaderProgress.complete);
  //         console.error(e);
  //         return e;
  //       });
  //   }
  // );
  // const certificates = useMemo(() => {
  //   const data: TypeCertificates[] = apiCertificateData ?? [];
  //   return data;
  // }, [apiCertificateData]);

  return {
    supportedPlatformData,
    faceToFaceAssignmentData,
    operatorLanguageList,
    operatorSkillList,
    onUpdateSupportedPlatform,
    onUpdateFaceToFaceAssignment,
    onUpdateLanguageRate,
    onCreateLanguageRate,
    onCreateOperatorSkill,
    onDeleteOperatorLanguage,
    onDeleteOperatorSkill,
    onUpdateOperatorSkill,
    operatorMedicalHistoryList,
    onCreateOperatorMedicalHistory,
    onUpdateOperatorMedicalHistory,
    onDeleteOperatorMedicalHistory,
    onUploadCertificate,
    //certificates,
  };
}
type OperatorRatingsProps = {
  operatorId: string;
  setLoaderProgress?: any;
  pageNo: number;
  pageSize: number;
  searchText: string;
};
export function useFunctionalityOperatorProfileRatings(
  props: OperatorRatingsProps
) {
  const { operatorId, setLoaderProgress, pageNo, pageSize, searchText } = props;
  const [pagedRatingListCount, setPagedRatingListCount] = useState<number>(0);
  const getRatingsUrl = PlatformApi.Operator.GetOperatorRatings(
    operatorId,
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiOperatorRatingsData } = useSWR<OperatorRatingResponseModel[]>(
    operatorId ? getRatingsUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<WithPagination<OperatorRatingResponseModel[]>>
      >(getRatingsUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedRatingListCount(r.data.data?.count);
          return r.data.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const operatorRatingsList = useMemo(() => {
    const data: OperatorRatingResponseModel[] = Array.isArray(
      apiOperatorRatingsData
    )
      ? apiOperatorRatingsData
      : [];
    return data;
  }, [apiOperatorRatingsData]);

  return {
    operatorRatingsList,
    pagedRatingListCount,
  };
}
