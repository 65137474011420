import { IBestParticipantBoundsParams, TypeBestGrid } from '../models';

export function videoGridHelper() {
  let modifiedParticipant: HTMLDivElement[] = [];

  const getRoomInfo = (room: HTMLDivElement) => {
    if (!room) return null;

    let totalColumn = 0;
    const computedStyle = window.getComputedStyle(room);
    const roomHeight = room.offsetHeight;
    const roomWidth = room.offsetWidth;
    const gridTemplateColumns = computedStyle
      .getPropertyValue('grid-template-columns')
      .split(' ');
    const participants = room.children;
    const participant = room.children[0] as HTMLDivElement;
    const gridGap = computedStyle.getPropertyValue('row-gap');
    const totalParticipant = room.childElementCount;
    gridTemplateColumns.forEach((column) => {
      if (column !== '0px') {
        totalColumn++;
      }
    });

    const participantWidth = participant.offsetWidth;
    const participantHeight = participant.offsetHeight;
    const totalRow = Math.ceil(totalParticipant / totalColumn);
    const totalParticipantHeight = totalRow * participantHeight;
    const totalGridGap = parseFloat(gridGap) * totalParticipant;

    return {
      roomHeight,
      totalRow,
      totalColumn,
      participants,
      participantWidth,
      roomWidth,
      totalParticipant,
      totalParticipantHeight,
      gridGap,
      totalGridGap,
    };
  };

  const hasOverflowGrid = (room: HTMLDivElement) => {
    if (!room) return null;

    const { roomHeight, totalParticipantHeight, totalGridGap } =
      getRoomInfo(room)!;
    if (roomHeight < totalParticipantHeight + totalGridGap) {
      return true;
    }
    return false;
  };

  const resetElementStyle = (needToReset: number) => {
    for (let i = 0; i < needToReset; i++) {
      modifiedParticipant[i].style.left = '';
    }
  };

  const centerParticipant = (room: HTMLDivElement) => {
    if (!room) return null;

    const { totalRow, totalColumn, totalParticipant, participants, gridGap } =
      getRoomInfo(room)!;
    if (totalRow * totalColumn > totalParticipant) {
      let difference = totalRow * totalColumn - totalParticipant;
      let needToShift = totalColumn - difference;
      if (modifiedParticipant.length > needToShift) {
        resetElementStyle(modifiedParticipant.length - needToShift);
      }
      modifiedParticipant = [];
      while (needToShift) {
        const shiftingPercentage = 100 * (difference / 2);
        const participant = participants[
          totalParticipant - needToShift
        ] as HTMLDivElement;
        modifiedParticipant.push(participant);
        participant.style.left = `calc(${shiftingPercentage}% + ${gridGap})`;
        needToShift--;
      }
    } else {
      while (modifiedParticipant.length) {
        const participant = modifiedParticipant.pop()!;
        participant.style.left = '';
      }
    }
  };

  const getBestParticipantBounds = (room: IBestParticipantBoundsParams) => {
    const roomRatio = room.width / room.height;
    const theoreticalHeight = 100;
    const theoreticalWidth = theoreticalHeight * room.aspectRatio;
    let bestGrid: TypeBestGrid = null;
    const bestSize = {
      width: 0,
      height: 0,
    };
    const calculateByWidthAndHeight = (
      totalColumn: number,
      totalRow: number
    ) => {
      const heightByRow =
        (room.height - room.gridGap * bestGrid!.totalRow) / bestGrid!.totalRow;
      const widthByHeight = heightByRow * room.aspectRatio;
      const widthByColumn =
        (room.width - room.gridGap * bestGrid!.totalColumn) /
        bestGrid!.totalColumn;
      const heightByWidth = widthByColumn / room.aspectRatio;
      const ratioByHeight = widthByHeight / heightByRow;
      const ratioByWidth = widthByColumn / heightByWidth;

      if (
        ratioByHeight > 1.7 &&
        ratioByHeight <= 1.8 &&
        widthByHeight * totalColumn <= room.width &&
        heightByRow * totalRow <= room.height &&
        widthByHeight > bestSize.width &&
        heightByRow > bestSize.height
      ) {
        bestSize.width = widthByHeight;
        bestSize.height = heightByRow;
      }
      if (
        ratioByWidth > 1.7 &&
        ratioByWidth <= 1.8 &&
        widthByColumn * totalColumn <= room.width &&
        heightByWidth * totalRow <= room.height &&
        widthByColumn > bestSize.width &&
        heightByRow > bestSize.height
      ) {
        bestSize.width = widthByColumn;
        bestSize.height = heightByWidth;
      }
    };

    for (let totalRow = 1; totalRow <= room.maxRows; totalRow++) {
      if (totalRow > room.totalParticipant) break;
      const totalColumn = Math.ceil(room.totalParticipant / totalRow);
      const ratio =
        (theoreticalWidth * totalColumn) / (theoreticalHeight * totalRow);
      if (
        !bestGrid ||
        Math.abs(ratio - roomRatio) < Math.abs(bestGrid.ratio - roomRatio)
      ) {
        bestGrid = { totalRow, totalColumn, ratio };
        if (room.totalParticipant === 2) {
          calculateByWidthAndHeight(totalColumn, totalRow);
        }
      }
    }

    const result = {
      participantHeight: bestSize.height,
      participantWidth: bestSize.width,
    };

    if (room.totalParticipant === 2) {
      return result;
    }

    if (bestGrid!.ratio < roomRatio) {
      result.participantHeight =
        (room.height - room.gridGap * bestGrid!.totalRow) / bestGrid!.totalRow;
      result.participantWidth = result.participantHeight * room.aspectRatio;
    } else {
      result.participantWidth =
        (room.width - room.gridGap * bestGrid!.totalColumn) /
        bestGrid!.totalColumn;
      result.participantHeight = result.participantWidth / room.aspectRatio;
    }
    return result;
  };

  return {
    getRoomInfo,
    centerParticipant,
    hasOverflowGrid,
    getBestParticipantBounds,
  };
}
