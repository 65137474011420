import { CompanyType } from "../../configs"

type Props = {
  step: number,
  companyType?: CompanyType;
}

const companyTabheaders = [
  "General Information",
  "Subscription",
  "Contract",
  "Packages",
  "Contact Person"
]

const resellerTabheaders = [
  "General Information",
  "Subscription",
  "Contract",
  "Contact Person",
  "Confirm Info"
]

export function StepIndicatorTabs(props: Props): JSX.Element {

  const activeTabSetter = (tab: number) => (tab === props.step) ? 'current' : ''

  return (
    <>
      {
        props.companyType==CompanyType.Customer ? (
          <div className="d-flex flex-wrap mb-3">
            <ul className="steps">
              {
                companyTabheaders.map((header, idx) => (
                  <li key={idx} className={activeTabSetter(idx)} >
                    <div className="seqInnerTitle">Step {idx + 1}: </div>
                    <div className="seqInnerSubTitle">{header}</div>
                  </li>
                ))
              }
            </ul>
          </div>
        ) : (
          <div className="d-flex flex-wrap">
            <ul className="steps">
              {
                resellerTabheaders.map((header, idx) => (
                  <li key={idx} className={activeTabSetter(idx)} >
                    <div className="seqInnerTitle">Step {idx + 1}: </div>
                    <div className="seqInnerSubTitle">{header}</div>
                  </li>
                ))
              }
            </ul>
          </div>
        )
      }
    </>
  )
}