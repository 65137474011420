import React, { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ApiSchemaEditJob, ApiSchemaJobDetailsById, } from "../../models";
import { ScheduleForm } from "../Jobs/CreateJob/schedule";
import { useFunctionalityJob } from "../../pages/hooks/functionalityJob";

type Props = {
  data: ApiSchemaJobDetailsById;
  show: boolean;
  handleShow: (value: boolean) => void;
  selectedJob: ApiSchemaJobDetailsById;
  setSweetChangeProp: any;
};

export function ModalEditJob(props: Props): JSX.Element {
  const [hoursErrorMsg, setHoursErrorMsg] = useState("");
  const [minutesErrorMsg, setMinutesErrorMsg] = useState("");
  const { setSweetChangeProp } = props;
  const { onEditJob } = useFunctionalityJob({ id: props?.selectedJob?.id || "", });
  const [lessThan24Hours, setLessThan24Hours] = useState(false);
  const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<ApiSchemaJobDetailsById>();
  const modalHideHandler = () => { props.handleShow(false); };

  const onEditJobHandler = (form: ApiSchemaEditJob) => {
    if (form.startDate === 'Invalid date') {
      return;
    }
    form.startDate = moment(form.startDate).format('Y-MM-DD');
    form = {
      ...form,
      startDate: String(form?.startDate),
      startTime: String(form?.startTime),
    };
    onEditJob(props.selectedJob?.id, form)
      ?.then(() => {
        setSweetChangeProp((prev: number) => prev + 1);
        modalHideHandler();
      })
      .catch((err) => console.log(err));
  };
  const durationChecker = () => {
    const hour = document?.getElementById("durationHours") as HTMLInputElement;
    const hourValue = hour.value;
    const mints = document.getElementById("durationMinutes") as HTMLInputElement;
    const mintsValue = mints.value;

    if (parseInt(hourValue) == 0 && parseInt(mintsValue) == 0) {
      setHoursErrorMsg("Duration must be greater than 0");
      setMinutesErrorMsg("Duration must be greater than 0");
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      size="lg"
      centered
    >
      {/*@ts-ignore */}
      <form onSubmit={handleSubmit(onEditJobHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Edit Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ScheduleForm
            Controller={Controller}
            control={control}
            setLessThan24Hours={setLessThan24Hours}
            lessThan24Hours={lessThan24Hours}
            durationChecker={durationChecker}
            register={register}
            setValue={setValue}
            errors={errors}
            jobInfosToEdit={props.selectedJob}
            hoursErrorMsg={hoursErrorMsg}
            setHoursErrorMsg={setHoursErrorMsg}
            minutesErrorMsg={minutesErrorMsg}
            setMinutesErrorMsg={setMinutesErrorMsg}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => props.handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="login-from-submit-btn btn btn-primary text-uppercase"
            disabled={hoursErrorMsg !== "" || minutesErrorMsg !== ""}
          >
            Edit Job
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
