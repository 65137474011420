import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, PlatformType, PlatformTypeConstant, topLoaderProgress, } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, WithPagination } from "../../models";
import { AppointmentCategoryRequestModel, AppointmentCategoryResponseModel } from "../../models/api/modality";
import { UtilsJQuery } from "../../utils";

type Props = {
  CompanyId?: string
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress: any;
};

let pagedAppointmentCategoryUrl: string;

export function useFunctionalityPagedAppointmentCategory(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress, CompanyId } = props;
  const [pagedAppointmentCategoryListCount, setPagedAppointmentCategoryListCount] = useState(0);
  pagedAppointmentCategoryUrl = PlatformApi.AppointmentCategory.PagedGetAll(
    CompanyId,
    pageSize,
    pageNo,
    searchText
  );
  const { data: apiPagedAppointmentCategoryData, error } = useSWR<AppointmentCategoryResponseModel[]>(
    pagedAppointmentCategoryUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<WithPagination<AppointmentCategoryResponseModel[]>>
      >(pagedAppointmentCategoryUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedAppointmentCategoryListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const pagedAppointmentCategoryList = React.useMemo(() => {
    const data = Array.isArray(apiPagedAppointmentCategoryData)
      ? apiPagedAppointmentCategoryData
      : [];
    return data;
  }, [apiPagedAppointmentCategoryData]);
  return {
    pagedAppointmentCategoryList,
    pagedAppointmentCategoryListCount,
    pagedAppointmentCategoryUrl
  };
}

export function useFunctionalityAppointmentCategoryTab(props: Props) {
  const LoadAppointmentCategory = useCallback(async () => {
    const { setLoaderProgress, CompanyId } = props;
    pagedAppointmentCategoryUrl = PlatformApi.AppointmentCategory.AppointmentCategoryTabGetAll(CompanyId);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<WithPagination<AppointmentCategoryResponseModel[]>>>(pagedAppointmentCategoryUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(res.data.data.data);
      }
    }
    catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      return Promise.reject(e);
    }

  }, []);
  return {
    LoadAppointmentCategory,
    pagedAppointmentCategoryUrl
  }
}

export function useFunctionalityAppointmentCategory() {
  const appointmentCategoryUrl = PlatformApi.AppointmentCategory.GetAll();
  const appointmentCategoryCreateUrl = PlatformApi.AppointmentCategory.Create();
  const appointmentCategoryDeleteUrl = PlatformApi.AppointmentCategory.Delete();


  const responseMappedToRequest = (value?: AppointmentCategoryResponseModel): AppointmentCategoryRequestModel => {

    if (value) {
      const request: AppointmentCategoryRequestModel = {
        name: value.name,
        //pin: value.pin,

      };
      return request;
    }
    else return { name: "" };//, pin: "" 
  };
  const stringToServiceType = (value: string): PlatformType => {
    switch (value) {
      case PlatformTypeConstant.VRI:
        return PlatformType.VRI;
      case PlatformTypeConstant.OPI:
        return PlatformType.OPI;
      case PlatformTypeConstant.OnSite:
        return PlatformType.OnSite;
      default: return 0;
    }
  };

  const onAddAppointmentCategory = React.useCallback(
    async (form: AppointmentCategoryRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".appointmentCategory-form-submit-btn");

      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(appointmentCategoryCreateUrl, form);

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(appointmentCategoryUrl);
          mutate(pagedAppointmentCategoryUrl);
          toast.success("Appointment Type added successfully");
        }
      } catch (e) {
        toast.error("Failed to add appointmentCategory.");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onEditAppointmentCategory = React.useCallback(
    (editForm: AppointmentCategoryRequestModel, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(".appointmentCategory-form-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(PlatformApi.AppointmentCategory.Edit(id), editForm)
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            mutate(appointmentCategoryUrl);
            mutate(pagedAppointmentCategoryUrl);
            toast.success("Appointment Type edited successfully");
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    },
    []
  );

  const onDeleteAppointmentCategory = React.useCallback(async (deleteForm: string[]) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(appointmentCategoryDeleteUrl, {
        data: deleteForm,
      });

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(appointmentCategoryUrl);
        mutate(pagedAppointmentCategoryUrl);
        toast.success("Appointment Type deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete Appointment Type.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    onAddAppointmentCategory,
    onEditAppointmentCategory,
    onDeleteAppointmentCategory,
    responseMappedToRequest
  };
}
