import React, { useCallback, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import { PlatformApi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core";
import {
  ApiResponse, ApiSchemaCompanyByIdData,
  ApiSchemaLanguageData,
  ApiSchemaLanguagePackData,
  DropDownModel,
  PlatformType,
  WithPagination,
} from "../../models";
import { OptionMapper } from "../../utils";
import { useAppSelector } from "../../core/Redux/ReduxStore";
import { ApiSchemaCreateLanguagePack } from "../../models/api/languagePack";
import { UtilsJQuery } from "../../utils/jQuery";
import { AppConstants, topLoaderProgress } from "../../configs/appConstants";
import { toast } from "react-toastify";

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  companyId: string;
};

let pagedLanguagePackUrl: string;

export function useFunctionalityPagedLanguagePack(props: Props) {
  //const companyId = useAppSelector(state => state.auth.profile?.companyId) ?? '';

  const { pageSize, pageNo, searchText, setLoaderProgress, companyId } = props;
  const [pagedLanguagePackListCount, setPagedLanguagePackListCount] =
    useState<number>(0);

  pagedLanguagePackUrl = PlatformApi.LanguagePack.PagedGetAll(
    companyId,
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiPagedLanguagePackData } = useSWR<
    ApiSchemaLanguagePackData[]
  >(pagedLanguagePackUrl, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.get<
      ApiResponse<WithPagination<ApiSchemaLanguagePackData[]>>
    >(pagedLanguagePackUrl)
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        setPagedLanguagePackListCount(r.data.data?.count);
        return r.data.data?.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  });

  const pagedLanguagePackList = useMemo(() => {
    return apiPagedLanguagePackData ? apiPagedLanguagePackData : [];
  }, [apiPagedLanguagePackData]);

  return {
    pagedLanguagePackList,
    pagedLanguagePackListCount,
    pagedLanguagePackUrl,
  };
}
type lProps = {
  setLoaderProgress?: any;
  companyId: string;
  shouldLanguagePackGetAllInvoke?: boolean;
};
export function useFunctionalityLanguagePack(props: lProps) {
  const { companyId, setLoaderProgress, shouldLanguagePackGetAllInvoke = false } = props;
  const languagePackUrl = PlatformApi.LanguagePack.GetAll(companyId);
  // alert(companyId)
  const languagePackDeleteUrl = PlatformApi.LanguagePack.Delete();
  const { data: apiLanguagePackData } = useSWR<ApiSchemaLanguagePackData[]>(
    shouldLanguagePackGetAllInvoke ? languagePackUrl : null,
    () => {
      const btnLoading = UtilsJQuery.Ladda(".modal-title");
      btnLoading.start?.();
      //setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<WithPagination<ApiSchemaLanguagePackData[]>>
      >(languagePackUrl)
        .then((r) => {
          //setLoaderProgress(topLoaderProgress.complete);
          btnLoading.stop?.();
          return r.data.data?.data;
        })
        .catch((e) => {
          //setLoaderProgress(topLoaderProgress.complete);
          btnLoading.stop?.();
          console.log(e);
          return e;
        });

    }
  );

  const adminLanguagePackList = useMemo(() => {
    return apiLanguagePackData ? apiLanguagePackData : [];
  }, [apiLanguagePackData]);

  const languagePackList = useMemo(() => {
    const data = Array.isArray(apiLanguagePackData)
      ? OptionMapper.mapperFunc(apiLanguagePackData)
      : [];

    return data;
  }, [apiLanguagePackData, companyId]);

  const onChooseAnotherPackage = React.useCallback(
    async (companyId: string, platformType: PlatformType) => {
      const btnLoading = UtilsJQuery.Ladda(".modal-title");
      btnLoading?.start?.();
      try {
        const response = await AxiosAuth.get(
          PlatformApi.LanguagePack.GetNotTakenLanguagePackAll(
            companyId,
            platformType
          )
        );
        if (response.status === AppConstants.Api.StatusOK) {
          //setLoaderProgress(topLoaderProgress.complete);
          btnLoading?.stop?.();
        }
        return Promise.resolve(response.data.data);
      } catch (e) {
        btnLoading?.stop?.();
        //setLoaderProgress(topLoaderProgress.complete);
        toast.error("Task failed");
        return Promise.resolve(null);
      }


    },
    []
  );
  const onLoadLanguagePackById = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    setLoaderProgress(topLoaderProgress.start);

    try {
      const response = await AxiosAuth.get(
        PlatformApi.LanguagePack.GetById(id)
      );
      if (response.status === AppConstants.Api.StatusOK) {
        setLoaderProgress(topLoaderProgress.complete);
      }
      return Promise.resolve(response.data.data);
    } catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      toast.error("Task failed");
      return Promise.resolve(null);
    }

    btnLoading?.stop?.();
  }, []);

  const onLanguagePackNameIsExists = useCallback(async (vendorCompanyId?: string, languagePackName?: string, languageId?: string) => {
    const languagePackNameExistsUrl = PlatformApi.LanguagePack.IsNameExists(vendorCompanyId ?? "", languagePackName ?? "", languageId);

    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading?.start?.();

    try {
      const response = await AxiosAuth.get(languagePackNameExistsUrl);
      if (response.status === AppConstants.Api.StatusOK) {
        btnLoading?.stop?.();
        return Promise.resolve(response?.data?.data);
      }
    } catch (e) {
      toast.error("Task failed");
      btnLoading?.stop?.();
      return Promise.reject(e);
    }
  }, []
  );

  const onSubmit = useCallback(
    async (form: ApiSchemaCreateLanguagePack, id?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading?.start?.();

      try {
        const response = id
          ? await AxiosAuth.put(PlatformApi.LanguagePack.Edit(id), form)
          : await AxiosAuth.post(PlatformApi.LanguagePack.Create(), form);

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(languagePackUrl);
          id ? toast.success("Package updated successfully") : toast.success("Package added successfully");
          return Promise.resolve(response.data?.data);
        }
      } catch (e) {
        toast.error("Task failed");
      }

      btnLoading?.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onDelete = useCallback(async (deleteForm: string[]) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(languagePackDeleteUrl, {
        data: deleteForm,
      });

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(languagePackUrl);
        toast.success("Language pack deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete pack.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    languagePackList,
    onChooseAnotherPackage,
    adminLanguagePackList,
    onLanguagePackNameIsExists,
    onSubmit,
    onDelete,
    onLoadLanguagePackById,
  };
}
export function useFunctionalityChooseLanguagePack(props: lProps) {
  const { companyId, setLoaderProgress } = props;
  //const companyId = useAppSelector(state => state.auth.profile?.companyId) ?? '';

  const languagePackUrl = PlatformApi.LanguagePack.GetAll(companyId);

  const onChooseAnotherPackage = React.useCallback(
    async (companyId: string, platformType: PlatformType) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      setLoaderProgress(topLoaderProgress.start);
      btnLoading.start?.()

      try {
        const response = await AxiosAuth.get(
          PlatformApi.LanguagePack.GetNotTakenLanguagePackAll(
            companyId,
            platformType
          )
        );
        if (response.status === AppConstants.Api.StatusOK) {
          // setLoaderProgress(topLoaderProgress.complete);
          btnLoading?.stop?.();
        }
        return Promise.resolve(response.data.data);
      } catch (e) {
        setLoaderProgress(topLoaderProgress.complete);
        toast.error("Task failed");
        return Promise.resolve(null);
      }


    },
    []
  );
  const onLoadLanguagePackById = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    setLoaderProgress(topLoaderProgress.start);

    try {
      const response = await AxiosAuth.get(
        PlatformApi.LanguagePack.GetById(id)
      );
      if (response.status === AppConstants.Api.StatusOK) {
        setLoaderProgress(topLoaderProgress.complete);
      }
      return Promise.resolve(response.data.data);
    } catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      toast.error("Task failed");
      return Promise.resolve(null);
    }

    btnLoading?.stop?.();
  }, []);
  const onSubmit = useCallback(
    async (form: ApiSchemaCreateLanguagePack, id?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading?.start?.();

      try {
        const response = id
          ? await AxiosAuth.put(PlatformApi.LanguagePack.Edit(id), form)
          : await AxiosAuth.post(PlatformApi.LanguagePack.Create(), form);

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(languagePackUrl);
          toast.success("Package updated successfully");
        }
      } catch (e) {
        toast.error("Task failed");
      }

      btnLoading?.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  return {
    onChooseAnotherPackage,
    onSubmit,
    onLoadLanguagePackById,
  };
}

