import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../../components";
import { ThreeWayCallReDialRequest } from "../../../models/api/Call";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  callinfo: any;
  onOutboundRedial: (value: ThreeWayCallReDialRequest) => Promise<any>;
};

export function ModalIvrOutboundCallRedial({ show, handleShow, callinfo, onOutboundRedial }: Props) {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<any>();
  const onSubmitData = (value: ThreeWayCallReDialRequest) => {
    value.callSid = callinfo.customParameters?.get("CallSid");
    onOutboundRedial(value).then((a) => {
      handleShow(false);
    });
  };
  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <form onSubmit={handleSubmit(onSubmitData)}>
        {/*@ts-ignore */}
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Outbound Phone Number</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="px-3 py-2">
            <div className="row mt-1">
              <div className="col-12">
                <div>
                  <div className="row mb-2">
                    <div className="col-4">
                      <label htmlFor="" className={`text-muted font-sz-12 $`}>
                        Phone Number
                      </label>
                    </div>
                    <div className="col-8">
                      <input
                        type="text"
                        className={`form-control ${errors["phoneNumber"] ? "field-required" : ""
                          }`}
                        {...register('phoneNumber', {
                          required: true,
                        })}
                        defaultValue={callinfo.customParameters?.get(
                          "OutboundPhoneNumber"
                        )} />

                      <span>
                        {errors["phoneNumber"] && (
                          <FormErrorMessage message="This field is required" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => handleShow(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase btn-message-submit custom-field-submit-btn-save"
          >
            Redial
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
