import React, { useEffect, useState } from 'react';
import { AssetSvg } from '../../assets';
import { AppRouteUi } from '../../configs';
import { ApiSchemaFaceToFace } from '../../models';
import { useFunctionalityOperatorProfile } from '../../pages/hooks';
import { handleNumberToTwoDecimalPoint } from '../../utils';
import { ModalFaceToFaceAssignment } from '../modals/faceToFaceAssignmentModal';

type Props = {
  readonly: boolean;
  operatorId?: string;
};
export function FaceToFaceAssignment(props: Props) {
  const [show, handleShow] = useState<boolean>(false);
  const operatorId = props.operatorId
    ? props.operatorId
    : AppRouteUi.Operator.Profile.useParam();

  const { faceToFaceAssignmentData, onUpdateFaceToFaceAssignment } =
    useFunctionalityOperatorProfile({ operatorId });
  const [isMilageEnabled, setIsMilageEnabled] = useState(
    faceToFaceAssignmentData?.isMileageEnabled
  );
  const [mileAgeToggleHandler, setMileAgeToggleHandler] = useState(
    faceToFaceAssignmentData?.isMileageEnabled
  );
  const onSaveHandler = (form: ApiSchemaFaceToFace) => {
    let requestModel: ApiSchemaFaceToFace = {
      isMileageEnabled:
        mileAgeToggleHandler ?? faceToFaceAssignmentData?.isMileageEnabled,
      mileageDistance: Number(form?.mileageDistance),
      mileageAdditionalRate: Number(form?.mileageAdditionalRate),
    };
    onUpdateFaceToFaceAssignment(operatorId, requestModel)
      .then(() => handleShow(false))
      .catch((e: any) => {
        console.error(e);
      });
  };
  useEffect(() => {
    setIsMilageEnabled(faceToFaceAssignmentData?.isMileageEnabled);
  }, [faceToFaceAssignmentData]);

  return (
    <div className="component-card mb-3 d-xl-block d-lg-block d-md-block">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
        <div>Face to Face Assignment</div>
        {props.readonly ? (
          <button className="btn btn-link px-0 text-site btn-sm text-uppercase">
            <div
              className="btn svg-color"
              title="faceToFace"
              onClick={() => handleShow(true)}
            >
              <span>
                <AssetSvg.Edit />
              </span>
            </div>
          </button>
        ) : null}
      </div>

      <div className="component-card-body border">
        {isMilageEnabled && (
          <div className="row">
            <div className="col-12">
              <p className="text-muted">
                Distance:
                {faceToFaceAssignmentData?.mileageDistance! < 0 ? (
                  <span className="text-danger">
                    ($
                    {Math.abs(
                      faceToFaceAssignmentData?.mileageDistance!
                    )?.toLocaleString('en-Us', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    )
                  </span>
                ) : (
                  <span>
                    {faceToFaceAssignmentData?.mileageDistance?.toLocaleString(
                      'en-Us',
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                  </span>
                )}{' '}
                miles
              </p>
              <p className="text-muted">
                Additional Per Mile fee:{' '}
                <span
                  className={
                    faceToFaceAssignmentData?.mileageAdditionalRate! < 0
                      ? `${'text-danger'}`
                      : ''
                  }
                >
                  {handleNumberToTwoDecimalPoint(
                    faceToFaceAssignmentData?.mileageAdditionalRate!
                  )}
                </span>
                {/* {
                  (faceToFaceAssignmentData?.mileageAdditionalRate === 0 || faceToFaceAssignmentData?.mileageAdditionalRate) ?
                    '$'.concat(faceToFaceAssignmentData?.mileageAdditionalRate.toString()) :
                    ""
                } */}
              </p>
            </div>
          </div>
        )}
      </div>

      <ModalFaceToFaceAssignment
        show={show}
        handleShow={handleShow}
        faceToFaceAssignmentData={faceToFaceAssignmentData}
        setMileAgeToggleHandler={setMileAgeToggleHandler}
        onSaveHandler={onSaveHandler}
      />
    </div>
  );
}
