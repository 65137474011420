import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage } from "../../configs";
import { ApiSchemaSkillData } from "../../models/api/skill";
import { useFunctionalityExists } from "../../pages/hooks/functionalityExists";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { useFunctionalitySkill } from "../../pages/hooks/functionalitySkills";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit?: ApiSchemaSkillData;
  setDataToEdit?: (value?: ApiSchemaSkillData) => void;
};
export function ModalSkill(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit } = props;
  const { onAddSkill, onEditSkill } = useFunctionalitySkill();
  const { register, handleSubmit, formState: { errors } } = useForm<ApiSchemaSkillData>();
  const [errMsg, setErrMsg] = useState("");
  const [pinErrMsg, setPinErrMsg] = useState("");
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { skillPinExist, skillNameExist } = useFunctionalityExists();
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const modalHideHandler = () => {
    setDataToEdit?.(undefined);
    setPinErrMsg("");
    setNameErrMsg("");
    handleShow(false);
  }
  const onEditHandler = async (form: ApiSchemaSkillData) => {
    const skillElem = document?.getElementById("name") as HTMLInputElement
    const skillValue = skillElem.value
    const pinElem = document.getElementById("pin") as HTMLInputElement
    const pinValue = pinElem.value
    setButtonDisabled(true)
    form = {
      ...form,
      name: form.name.trim()
    }
    await skillPinExist(pinValue, dataToEdit)
      .then((r) => {
        if (r) setPinErrMsg("Pin Already Exists")
        else setPinErrMsg("")
        skillNameExist(skillValue, dataToEdit)
          .then((rr) => {
            if (rr) setNameErrMsg("Skill Already Exists")
            else setNameErrMsg("")
            if (!r && !rr) {
              onEditSkill(form, dataToEdit?.id ?? "")
                .then(() => modalHideHandler())
                .catch((e) => { })
            }
          })
      })
    setButtonDisabled(false)
  };
  const onCreateHandler = async (form: ApiSchemaSkillData) => {
    const skillElem = document?.getElementById("name") as HTMLInputElement
    const skillValue = skillElem.value
    const pinElem = document.getElementById("pin") as HTMLInputElement
    const pinValue = pinElem.value
    setButtonDisabled(true)
    form = {
      ...form,
      name: form.name.trim()
    }
    await skillPinExist(pinValue, dataToEdit)
      .then((r) => {
        if (r) setPinErrMsg("Pin Already Exists")
        else setPinErrMsg("")
        skillNameExist(skillValue, dataToEdit)
          .then((rr) => {
            if (rr) setNameErrMsg("Skill Already Exists")
            else setNameErrMsg("")
            if (!r && !rr) {
              onAddSkill(form)
                .then(() => {
                  modalHideHandler()
                })
                .catch((e) => { })
            }
          })
      })
    setButtonDisabled(false)
  };

  const onValidatePin = (value: any) => {
    if (value < 2 || isNaN(value)) {
      setErrMsg("Please enter at least 2 numbers.");
      return false;
    }
    setErrMsg("");
    return true;
  };

  const onPinExistValidation = async (value: any) => {
    await skillPinExist(value, dataToEdit)
      .then((r) => {
        if (r) setPinErrMsg("Pin Already Exists")
        else setPinErrMsg("")
      })

  }
  const onNameExistsValidation = async (value: any) => {
    await skillNameExist(value, dataToEdit)
      .then((r) => {
        if (r) setNameErrMsg("Skill Already Exists")
        else setNameErrMsg("")
      })
  }

  return (
    <Modal
      show={show}
      onHide={modalHideHandler}
      centered
      backdrop="static"
    >
      <form onSubmit={handleSubmit(dataToEdit ? onEditHandler : onCreateHandler)}>
        {/*@ts-ignore */}
        <Modal.Header closeButton >
          <Modal.Title className="">
            {dataToEdit ? "Update Skill" : "Add A Skill"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required ">
                  Skill
                </label>
                <input
                  type="text"
                  {...register('name', { required: true, validate: useFunctionlityInputFieldValidation })}
                  className="form-control"
                  placeholder="Skill"
                  id="name"
                  defaultValue={dataToEdit?.name}
                  onChange={(e) => onNameExistsValidation(e.target.value)} />
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
                {errors.name && errors.name.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
                {nameErrMsg && <FormErrorMessage message={nameErrMsg} />}
              </div>
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Pin
                </label>
                <input
                  type="text"
                  {...register('pin', {
                    required: true,
                    minLength: { value: 2, message: "Please enter atleast 2 number" },
                    // validate: onPinExistValidation
                  })}
                  id="pin"
                  className="form-control"
                  placeholder="Enter PIN(Min 2 digits, Max 4 digits)"
                  defaultValue={dataToEdit?.pin}
                  maxLength={4}
                  onChange={(e) => onPinExistValidation(e.target.value)} />
                {errors.pin && (errors.pin?.type == "validate") && (
                  <FormErrorMessage message={pinErrMsg} />
                )}
                {errors.pin?.message && (errors.pin?.type == "minLength") && (
                  <FormErrorMessage message={errors.pin.message} />
                )}
                {errors.pin && (errors.pin?.type == "required" || errMsg) && (
                  <FormErrorMessage message={errMsg || "required"} />
                )}
                {pinErrMsg && <FormErrorMessage message={pinErrMsg} />}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="skill-form-submit-btn btn btn-primary text-uppercase"
            disabled={buttonDisabled}
          >
            {dataToEdit ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
