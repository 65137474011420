import React from "react";
import { AssetSvg } from "../../../../assets";
import { PlatformType } from "../../../../configs";
import { TypeContractTabContactPlatforms } from "../../../../models";

type Props = {
  handleShow: (value: boolean) => void,
  data: TypeContractTabContactPlatforms | null,
  setPlatformType: (value: PlatformType) => void
  editable: boolean;
}

export function CardOnSite(props: Props): JSX.Element {
  const { handleShow, data, setPlatformType, editable } = props;

  return (

    <div className="component-card mb-3">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
        <div>On-site Fees</div>
        {editable ? (
          <button className="btn btn-link px-0 text-site btn-sm text-uppercase" onClick={() => handleShow(true)}>
            <div className="btn svg-color" title="contractOnsiteEdit" onClick={() => setPlatformType(PlatformType.OnSite)}>
              <span>
                <AssetSvg.Edit />
              </span>
            </div>
          </button>
        ) : (<></>)
        }
      </div>
      <div className="component-card-body border">
        {
          data?.isEnabled ? (
            <>
              <div className="row flex-wrap mb-2">
                <div className="col-12">
                  <p className="mb-0 text-muted">
                    Booking fee
                  </p>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <p className="mb-0 currency-symbol text-dark font-500">{`$${data.connectionFee?.toFixed(2)}`}</p>
                  </div>
                </div>
                {/* <div className="col-5 col-sm-6 col-md-7 col-lg-7 col-xl-5">
                    <p className="mb-0 text-dark font-500">
                      Booking fee
                    </p>
                  </div>
                  <div className="col-7 col-sm-6 col-md-5 col-lg-5 col-xl-7">
                    <p className="mb-0  d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-muted">{`$${data.connectionFee}`}</div>
                    </p>
                  </div> */}
              </div>
              <div className="row flex-wrap mb-2">
                <div className="col-12">
                  <p className="mb-0 text-muted">
                    Travel time rate
                  </p>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <p className="mb-0 currency-symbol text-dark font-500">{`${data.travelTimeRate?.toFixed(2) ?? 'not yet set'}%`}</p>
                  </div>
                </div>
                {/* <div className="col-5 col-sm-6 col-md-7 col-lg-7 col-xl-5">
                    <p className="mb-0 text-dark font-500">
                      Travel time rate
                    </p>
                  </div>
                  <div className="col-7 col-sm-6 col-md-5 col-lg-5 col-xl-7">
                    <p className="mb-0  d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-muted">{`${data.travelTimeRate ?? 'not yet set'}%`}</div>
                    </p>
                  </div> */}
              </div>
              <div className="row flex-wrap mb-2">
                <div className="col-12">
                  <p className="mb-0 text-muted">
                    Mileage rate
                  </p>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-wrap align-items-center">
                    <p className="mb-0 currency-symbol text-dark font-500">{`$${data.mileageRate?.toFixed(2) ?? 'not yet set'}`}</p>
                  </div>
                </div>
                {/* <div className="col-5 col-sm-6 col-md-7 col-lg-7 col-xl-5">
                    <p className="mb-0 text-dark font-500">
                      Mileage rate
                    </p>
                  </div>
                  <div className="col-7 col-sm-6 col-md-5 col-lg-5 col-xl-7">
                    <p className="mb-0  d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-muted">{`$${data.mileageRate ?? 'not yet set'}`}</div>
                    </p>
                  </div> */}
              </div>
            </>
          ) : null
        }
      </div>
    </div>
  )
}