import { faCamera, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "jquery";
import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Prev } from "react-bootstrap/esm/PageItem";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { AppConstants, AudioSupportedType, FileSize } from "../../configs";
import { v4 as uuidv4 } from 'uuid';
import {
  ApiSchemaCallCenterMessageData,
  ApiSchemaRequestUpdateCallCenterMessage,
  MessageModel,
  MessageViewModel,
} from "../../models";
import {
  useFunctionalityCallCenterMessage,
  useFunctionalityCountry,
  useFunctionalityState,
} from "../../pages/hooks";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;

  dataToEdit: MessageViewModel;
  setDataToEdit: (value: MessageViewModel) => void;
  onSaveHandler: (value: FormData, category?: string) => void;
};

export function ModalMessageEditModal(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, setDataToEdit, onSaveHandler } = props;
  const { countryList } = useFunctionalityCountry();
  //const { onEditMessage } = useFunctionalityCallCenterMessage({ callCenterId });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<MessageModel>();
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const { stateList } = useFunctionalityState(selectedCountry);
  const [selectedAudioFile, setSelectedAudioFile] = React.useState('');
  const [audioValidationMsg, setAudioValidationMsg] = React.useState("")

  const modalHideHandler = () => {
    handleShow(false);
  };


  const onEditHandler = (form: any) => {
    form = {
      ...form,
      id: dataToEdit.id,
      title: dataToEdit.title,
      audioMediaKey: dataToEdit.audioMediaKey,
      category: dataToEdit.category,
      isDefault: dataToEdit.isDefault,
      isTtsEnabled: dataToEdit.isTtsEnabled,
    };
    const audioMediaKey = form.audioMediaKey;
    const body = new FormData();
    body.append("id", form.id);
    body.append("title", form.title);
    body.append("category", form.category);
    body.append("isDefault", form.isDefault);
    body.append("isTtsEnabled", form.isTtsEnabled);
    
    if (form.file?.length > 0) {
      const fileName = form.file[0].name;
      const fileExtension = fileName.substr(fileName.lastIndexOf('.'));
      body.append('mediaFile', form.file[0]);

      if (!audioMediaKey) {
        body.append('audioMediaKey', uuidv4() + fileExtension);
      } else {
        const fileNameWithoutExtension = audioMediaKey.slice(
          0,
          audioMediaKey.lastIndexOf('.')
        );
        body.append('audioMediaKey', fileNameWithoutExtension + fileExtension);
      }
    } else {
      body.append('message', form.message);
    }
    if (audioValidationMsg === "") {
      onSaveHandler(body, dataToEdit?.category);
    }
  };
  const audioFileHandler = (event: any) => {

    const audioType = event.target.files[0]?.type;
    if (event.target.files[0]?.size > FileSize.AudioSizeLimit) {
      setAudioValidationMsg("File size must be smaller than 3 MB")
      setSelectedAudioFile('');
      return
    }

    if ((audioType == AudioSupportedType.MP3) || (audioType == AudioSupportedType.WAV)) {
      setAudioValidationMsg("")
      const AudioInputValue = event.target.files[0].name
      setSelectedAudioFile(AudioInputValue);
    }
    else {
      setAudioValidationMsg("Please select only mp3 file")
      setSelectedAudioFile('');
    }



  }

  const onButtonClickHandler = () => {
    const input = document.getElementById('file');
    if (input) {
      input.click();
    }
  }
  return (
    <Modal show={props.show} onHide={() => {
      props.handleShow(false)
      setSelectedAudioFile('')
    }} centered>
      <form onSubmit={handleSubmit(onEditHandler)} encType="multipart/form-data">
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{dataToEdit?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <div className="col-xs-12 offset0">
              <label>
                <input
                  name="ivrMessageType"
                  type="radio"
                  value="message"
                  onClick={() => {
                    setDataToEdit({
                      ...dataToEdit,
                      isTtsEnabled: true,
                    });
                  }}
                  defaultChecked={dataToEdit?.isTtsEnabled ?? true}
                />
                Text message
              </label>
              {dataToEdit?.isTtsEnabled && (
                <div className="messageInputText">
                  <textarea
                    className="form-control"
                    id="message"
                    {...register('message')}
                    rows={3}
                    cols={50}
                    maxLength={300}
                    defaultValue={dataToEdit?.message}></textarea>
                </div>
              )}
            </div>
            <div className="col-xs-12 marginTop10 offset0">
              <label>
                <input
                  name="ivrMessageType"
                  type="radio"
                  value="audio"
                  onClick={() => {
                    setAudioValidationMsg("")
                    setSelectedAudioFile('');
                    setDataToEdit({
                      ...dataToEdit,
                      isTtsEnabled: false,
                    });
                  }}
                  defaultChecked={!(dataToEdit?.isTtsEnabled ?? true)}
                />
                Audio message
              </label>
              {!dataToEdit?.isTtsEnabled && (
                <div className="messageInputAudio">
                  <div className="col-xs-12 offset0 ">
                    <div className="input-group ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Choose an audio file… e.g. mp3,wav etc (file size < 3MB)"
                        aria-label="Upload file"
                        name="photo"
                        value={selectedAudioFile}
                        aria-describedby="photo"
                        readOnly
                      />
                      <input
                        className="d-none"
                        type="file"
                        id="file"
                        //@ts-ignore
                        {...register('file', { required: true })}
                        accept=".mp3, .wav"
                        onChange={audioFileHandler} />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          id="photo"
                          onClick={(e) => {
                            e.preventDefault()
                            onButtonClickHandler()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPaperclip}
                            className="mr-2"
                          />
                          <label className="mb-0" htmlFor="file">
                            Browse
                          </label>
                        </button>

                      </div>

                    </div>
                    {audioValidationMsg && <FormErrorMessage message={audioValidationMsg} />}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn text-uppercase"
            type="button"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary text-uppercase btn-message-submit">
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}