import React, { useEffect, useState } from "react";
import { DefaultImageType, FileBucketURL } from "../../../configs";
import { ApiResponseUserDetails } from "../../../models";
import { useFunctionalityChat, useFunctionalityImageView } from "../../../pages/hooks";
import { AssetsPng } from "../../../assets";

interface Props {
    userId: string;
}
export function ContanctUserDetail(props: Props): JSX.Element {
    const { userId } = props
    const [userDetail, setUserDetails] = useState<ApiResponseUserDetails>()
    const { onUserDetailsById } = useFunctionalityChat()

    useEffect(() => {
        if (userId)
            onUserDetailsById(userId, setUserDetails)
                .then(() => {
                })
    }, [userId])
    const { defaultImageLoad, imageView } = useFunctionalityImageView();

    return (
        <>
            {userDetail && <div>
                <div className="d-flex popover-container">
                    <div>
                        <div className="user-image">
                            <img
                                src={`${FileBucketURL?.Root}${userId}?${imageView?.fileTimeStamp}`}
                                alt=""
                                onError={(ev) => { defaultImageLoad(ev, DefaultImageType.UserProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") }}
                                className="img-fluid "
                            // onError={({ currentTarget }) => {
                            //     currentTarget.onerror = null; // prevents looping
                            //     currentTarget.src=AssetsPng.GenericUser
                            //   }}
                            />
                        </div>
                    </div>
                    <div className="pl-4 popover-container-text" >
                        <h4 className="mb-0 word-wrap">{userDetail.name}</h4>
                        <div className="text-purple word-wrap">{userDetail.email}</div>
                        <div className={`font-600 word-wrap ${userDetail?.onlineStatus === "Online"
                            ? `text-success`
                            : userDetail?.onlineStatus === "InCall"
                                ? `text-danger`
                                : userDetail?.onlineStatus === "Busy"
                                    ? `text-orange`
                                    : userDetail?.onlineStatus === "Disabled" ||
                                        userDetail?.onlineStatus === "Offline"
                                        ? `text-secondary`
                                        : ""
                            }`}>{userDetail.onlineStatus}</div>
                    </div>
                </div>
                <div className="d-flex px-3 pt-1 pb-4">
                    <div className="border rounded p-1">{userDetail.role}</div>
                    <div className="border-bottom my-auto w-100"></div>
                </div>
                {
                    userDetail.role === "Operator" && <div className="row">
                        <div className="col-6 ">
                            <div className="pl-3 pr-0">
                                Languages
                            </div>
                        </div>
                        <div className="col-6">
                            <div className=" word-wrap pr-3 pl-1 ">{userDetail.language ? userDetail.language : "N/A"}</div>
                        </div>

                    </div>
                }
                {
                    userDetail.role === "Operator" && <div className="row pb-4">
                        <div className="col-6 ">
                            <div className="pl-3 pr-0">
                                skill
                            </div>
                        </div>
                        <div className="col-6">
                            <div className=" word-wrap pr-3 pl-1 ">{userDetail.skill ? userDetail.skill : "N/A"}</div>
                        </div>

                    </div>
                }
            </div>}
        </>
    );
}
