import { FormErrorMessage } from "../../../components";
import { useFunctionalityInputChecker } from "../../../pages/hooks/functionalityInputChecker";

type Props = {
  register: any;
  setValue: any;
  errors: any;
}

export function AdditionalInfo(props: Props): JSX.Element {
  const { handleSpecialCharacterWhiteSpacesForJobFieldExtraInfo } = useFunctionalityInputChecker();

  return (
    <>
      <div className="" >
        <div className="component-card rounded mb-3">
          <div className="component-card-header d-flex align-items-center justify-content-between  border rounded-top ">
            <div className="text-capitalize">
              Additional Information
            </div>
          </div>
          <div className="component-card-body rounded-bottom border-left border-right border-bottom">
            <div className="form-group row ">
              <div className="col-12">
                <label htmlFor="" className="col-12 p-0">
                  Extra Information
                </label>
                <textarea
                  name="extraInformation"
                  placeholder="Max 300 characters"
                  id="extraInfo"
                  maxLength={300}
                  data-resize="none"
                  className="form-control"
                  ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForJobFieldExtraInfo, maxLength: 300  })}
                  style={{ border: props.errors.extraInformation ? '1px solid red' : '' }}

                >

                </textarea>
                {props.errors.extraInformation && props.errors.extraInformation.type == "validate" && (
                  <FormErrorMessage message="Please enter a valid value" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}