import { Modal } from 'react-bootstrap'
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { ApiSchemaFileUpload } from '../../../models';
import { FormErrorMessage } from '../../../components';
import { useFunctionalityCompanyContract } from '../../../pages/hooks/functionalityCompanyContract';
import React, { useState } from 'react';
import { useFunctionalityInputChecker } from "../../../pages/hooks/functionalityInputChecker";

type Props = {
	id: string,
	show: boolean,
	handleShow: (value: boolean) => void,
	contractId: string;
	onUpdateSuccess?: any,
	setOnUpdateSuccess?: any,
}

export function ModalContractDocumentUpload(props: Props) {
	const { id, show, handleShow, contractId, onUpdateSuccess, setOnUpdateSuccess } = props;

	const { register, handleSubmit, formState: { errors } } = useForm<ApiSchemaFileUpload>();
	const { onUploadContract } = useFunctionalityCompanyContract();
	const [selectedDocument, setSelectedDocument] = useState<string>('');
	const { handleSpecialCharacterWhiteSpacesForJobField, handleFileChecker } = useFunctionalityInputChecker();
	const [nameLengthError, setNameLengthError] = useState('');
	const [fileTypeError, setFileTypeError] = useState('');

	const contractUploadHandler = (form: any) => {

		const body = new FormData();

		body.append('File', form.file[0]);
		body.append('Name', form.name);

		onUploadContract(body, id, contractId)
			.then(() => {
				setSelectedDocument('');
				handleShow(false);
				setOnUpdateSuccess(!onUpdateSuccess);
			})
			.catch(e => console.error(e));
	}

	const selectedDocumentHandler = (event: any) => {
		const logoInputValue = event.target.files[0]?.name;
		setSelectedDocument(logoInputValue);

		if (handleFileChecker(event)) {
			setFileTypeError('');
		} else {
			setFileTypeError('File type is invalid');
		}
	}

	const handleCharLength = (event: any) => {
		const fileName = event.target.value;
		if ((fileName?.trimStart())?.length > 100) setNameLengthError('Document name max length exceed');
		else if((fileName?.trimStart())?.length < 2) setNameLengthError('Document name should contain at least two character');
		else setNameLengthError('');
	}

	const initializeErrorState = () => {
		setSelectedDocument('');
		setNameLengthError('');
		setFileTypeError('');
	}
	const onButtonClickHandler = () => {
		const input = document.getElementById('file');
		if (input) {
			input.click();
		}
	}
	return (
        <Modal
			show={show}
			onHide={() => {
				initializeErrorState();
				handleShow(false)
			}
			}
			centered
			dialogClassName="modal-lg"
		>
			<form onSubmit={handleSubmit(contractUploadHandler)}>
				        {/*@ts-ignore */}
        <Modal.Header closeButton>
					<Modal.Title>Upload Contract</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label htmlFor="" className="position-relative required">Document Name</label>
								<input
                                    type="text"
                                    className="form-control"
                                    placeholder="Document Name"
                                    {...register('name', {
										required: true,
										validate: handleSpecialCharacterWhiteSpacesForJobField,
									})}
                                    onChange={handleCharLength} />
								{errors.name && errors.name.type === "validate" && (
									<FormErrorMessage message="Please enter valid value" />
								)}
								{errors.name && errors.name.type === "required" && (
									<FormErrorMessage message="required" />
								)}
								{
									nameLengthError && <FormErrorMessage message={nameLengthError} />
								}
							</div>
							<div className="form-group">
								<label htmlFor="" className="position-relative required">Upload File</label>
								<div className="input-group ">
									<input
										type="text"
										className="form-control"
										placeholder="Choose a File"
										aria-label="Upload file"
										name="button-upload-file"
										value={selectedDocument}
										aria-describedby="button-upload-file"
										readOnly
									/>
									<input
                                        className="d-none"
                                        type="file"
                                        id="file"
                                        {...register('file', { required: true })}
                                        onChange={selectedDocumentHandler} />
									<div className="input-group-append">
										<button
											className="btn btn-primary"
											type="button"
											id="button-upload-file"
											onClick={(e) => {
												e.preventDefault()
												onButtonClickHandler()
											}}
										>
											<FontAwesomeIcon icon={faPaperclip} className="mr-2" />
											<label className="mb-0" htmlFor="file">attach document</label>
										</button>
									</div>
								</div>
							</div>
							{errors.file && (
								<FormErrorMessage message="required" />
							)}
							{
								fileTypeError && <FormErrorMessage message={fileTypeError} />
							}
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button
						type="button"
						className="btn btn-link text-site text-uppercase"
						onClick={() => {
							initializeErrorState();
							handleShow(false)
						}
						}
					>
						Cancel
					</button>
					<button
						type="submit"
						className="btn btn-primary text-uppercase upload-button"
						disabled={!!(nameLengthError || fileTypeError)}
					>
						Upload
					</button>
				</Modal.Footer>
			</form>
		</Modal>
    );
}
