import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, PlatformType, PlatformTypeConstant, topLoaderProgress, } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, WithPagination } from "../../models";
import { ModalityRequestModel, ModalityResponseModel, } from "../../models/api/modality";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  CompanyId?: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress: any;
};

let pagedModalityUrl: string;

export function useFunctionalityPagedModality(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress, CompanyId } = props;
  const [pagedModalityListCount, setPagedModalityListCount] = useState(0);

  pagedModalityUrl = PlatformApi.Modality.PagedGetAll(
    CompanyId,
    pageSize,
    pageNo,
    searchText
  );

  const { data: apiPagedModalityData, error } = useSWR<ModalityResponseModel[]>(
    pagedModalityUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<WithPagination<ModalityResponseModel[]>>
      >(pagedModalityUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedModalityListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedModalityList = React.useMemo(() => {
    const data = Array.isArray(apiPagedModalityData)
      ? apiPagedModalityData
      : [];
    return data;
  }, [apiPagedModalityData]);
  return {
    pagedModalityList,
    pagedModalityListCount,
    pagedModalityUrl
  };
}

export function useFunctionalityModalityTab(props: Props) {
  const LoadModality = useCallback(async () => {
    const { setLoaderProgress, CompanyId } = props;
    pagedModalityUrl =
      PlatformApi.Modality.ModalityTabGetAll(CompanyId);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<WithPagination<ModalityResponseModel[]>>>(pagedModalityUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(res.data.data.data);
      }
    }
    catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      return Promise.reject(e);
    }

  }, []);
  return {
    LoadModality,
    pagedModalityUrl
  }
}

export function useFunctionalityModality(showModal?: boolean) {
  const modalityUrl = PlatformApi.Modality.GetAll();
  const modalityCreateUrl = PlatformApi.Modality.Create();
  const modalityDeleteUrl = PlatformApi.Modality.Delete();

  const { data: apiModalityList } = useSWR<ModalityResponseModel[]>(
    showModal ? modalityUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ModalityResponseModel[]>>>(
        modalityUrl
      )
        .then((r) => r.data.data?.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );
  const responseMappedToRequest = (value?: ModalityResponseModel): ModalityRequestModel => {
    if (value) {
      const request: ModalityRequestModel = {
        name: value.name,
        //pin: value.pin,
        serviceType: stringToServiceType(value.serviceType),
      };
      return request;
    }
    else return { name: "", serviceType: 0 };//pin: "",
  };
  const stringToServiceType = (value: string): PlatformType => {
    switch (value) {
      case "VRI":
        return PlatformType.VRI;
      case "OPI":
        return PlatformType.OPI;
      case "OnSite":
        return PlatformType.OnSite;
      default: return 0;
    }
  };
  const optionMappedModalityList = React.useMemo(() => {
    const data = Array.isArray(apiModalityList)
      ? OptionMapper.mapperFunc(apiModalityList)
      : [];
    return data;
  }, [apiModalityList]);

  const onAddModality = React.useCallback(
    async (form: ModalityRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".modality-form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(modalityCreateUrl, form);

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(modalityUrl);
          mutate(pagedModalityUrl);
          toast.success("Modality added successfully");
        }
      } catch (e) {
        toast.error("Failed to add modality.");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onEditModality = React.useCallback(
    (editForm: ModalityRequestModel, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(".modality-form-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(PlatformApi.Modality.Edit(id), editForm)
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            mutate(modalityUrl);
            mutate(pagedModalityUrl);
            toast.success("Modality edited successfully");
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    },
    []
  );

  const onDeleteModality = React.useCallback(async (deleteForm: string[]) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(modalityDeleteUrl, {
        data: deleteForm,
      });

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(modalityUrl);
        mutate(pagedModalityUrl);
        toast.success("Modality deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete modality.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    optionMappedModalityList,
    onAddModality,
    onEditModality,
    onDeleteModality,
    responseMappedToRequest
  };
}
