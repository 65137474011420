import React from 'react';
import { Pagination } from "../../components";
import { BookingCustomHistoryModel } from "../../models";
import { dateFormatInUSA } from '../../utils';

type Props = {
    bookingHistoryData?: BookingCustomHistoryModel[];
    count: number;
    pageSize: number;
    pageNo: number;
    setPageSize: Function;
    setPageNo: Function;
}

export default function BookingHistoryData(props: Props): JSX.Element {
    const { bookingHistoryData } = props;

    const onChangePageIndex = (index: number) => {
        props.setPageNo(index);
    };

    const onChangePageSize = (size: number) => {
        props.setPageSize(size);
        props.setPageNo(1);
    };

    return (
        <>
            <div className="table-responsive d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="w-25"><small>Name</small></th>
                            <th scope="col" className="w-25"><small>Activity</small></th>
                            <th scope="col"><small>Date</small></th>
                            <th scope="col"><small>TIme</small></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookingHistoryData?.length ? bookingHistoryData.map((bookingHistoryData: BookingCustomHistoryModel) => (
                            <tr key={bookingHistoryData?.name}>
                                <td scope="row" className="w-25"> {bookingHistoryData?.name ?? 'System'}</td>
                                <td scope="row" className="w-25"> {`${bookingHistoryData?.activity} ${bookingHistoryData?.actedUpon ?? ''}`} </td>
                                <td scope="row">{bookingHistoryData?.date ? dateFormatInUSA(bookingHistoryData?.date) : ''}</td>
                                <td scope="row" > {bookingHistoryData?.time} </td>
                            </tr>)
                        ) : null
                        }
                    </tbody>
                </table>
            </div>

            <div className="history-mobile-table d-block d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="card card-body">
                    <div className="row pb-3">
                        <div className="col-6">
                            <strong>Date : </strong><span>May 19, 2021</span>
                        </div>
                        <div className="col-6">
                            <strong>Time : </strong><span>17:32:22</span>
                        </div>
                    </div>
                    <div className="row pb-3">
                        <div className="col-12">
                            <strong>Action : </strong><span>Custom rate has been changed to 12.0</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <strong>By : </strong><span>Bob the Admin</span>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination
                pageSize={props.pageSize}
                pageIndex={props.pageNo}
                count={props.count}
                onChangePageIndex={onChangePageIndex}
                onChangePageSize={onChangePageSize}
            />
        </>
    );
}