import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { ImageSupportedType, CompanyType } from '../../configs/appConstants';
import { AppRouteUi } from "../../configs/appRoute";
import { useFunctionalityCompany } from "../../pages/hooks";
import { toast } from "react-toastify";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  onUploadComplete: () => void;
  companyId: string;
  companyType?: CompanyType
}
type TypeProfilePicture = {
  photo: File;

};

export function ModalChangeLogo(props: Props): JSX.Element {
  const { show, handleShow, onUploadComplete, companyId, companyType } = props;
  const [imageValidaitonMsg, setImageValidaitonMsg] = useState("");
  const { register, handleSubmit, formState: { errors } } = useForm<TypeProfilePicture>();

  const id = companyType == 2 ? AppRouteUi.Reseller.Profile.useParam() : AppRouteUi.Company.Profile.useParam()
  const { onUploadLogo } = useFunctionalityCompany({ companyId: id });
  const [selectedLogo, setSelectedLogo] = useState<string>('')

  const profilePicUploader = (form: any) => {
    if (imageValidaitonMsg == "") {
      const body = new FormData();

      body.append("file", form.file[0]);
      if (form.file[0].size > 2097152) {
        toast.error('Maximum image size exceeded');
        return;
      }
      onUploadLogo(body, id)
        .then(() => {
          handleShow(false);
          onUploadComplete();
          setSelectedLogo('')
        })
        .catch((e) => console.error(e));
    }
  };

  const onButtonClickHandler = () => {
    const input = document.getElementById('file');
    if (input) {
      input.click();
    }
  }

  const selectedLogoHandler = (event: any) => {
    if (event.target.files[0]?.size > 3000000) {
      setImageValidaitonMsg("Image size should not be greater than 3MB");
    } else {
      const imageType = event.target.files[0]?.type;
      if (imageType == ImageSupportedType.JPEG || imageType == ImageSupportedType.JPG || imageType == ImageSupportedType.PNG || imageType == ImageSupportedType.SVG || imageType == ImageSupportedType.GIF) {
        setImageValidaitonMsg("")
        const logoInputValue = event.target.files[0].name
        setSelectedLogo(logoInputValue)
      }
      else {
        setImageValidaitonMsg("Only accepted file extensions are .jpg, .jpeg, .png, .svg, .gif")
      }
    }
  }
  return (
    <Modal show={show} onHide={() => {
      handleShow(false)
      setSelectedLogo('')
      setImageValidaitonMsg('')
    }} centered>
      <form onSubmit={handleSubmit(profilePicUploader)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Upload Company Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Upload File
                </label>
                <small className="d-block text-muted">Image size must not exceed 2MB</small>
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Choose a File"
                    aria-label="Upload file"
                    name="photo"
                    value={selectedLogo}
                    aria-describedby="photo"
                    readOnly
                  />
                  <input
                    className="d-none"
                    type="file"
                    id="file"
                    {...register('photo', { required: true })}
                    accept=".png, .jpg, .jpeg, .svg, .gif"
                    onChange={selectedLogoHandler} />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="photo"
                      onClick={(e) => {
                        e.preventDefault()
                        onButtonClickHandler()
                      }}
                    >
                      <FontAwesomeIcon icon={faCamera} className="mr-2" />
                      <label className="mb-0" htmlFor="file">
                        Select a photo
                      </label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {imageValidaitonMsg && <FormErrorMessage message={imageValidaitonMsg} />}
            {errors.photo && <FormErrorMessage message="required" />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              handleShow(false)
              setSelectedLogo('')
              setImageValidaitonMsg('')
            }
            }
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase upload-button"
          >
            Upload
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
