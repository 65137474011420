import { useState } from "react";
import { AssetSvg } from "../../assets";
import { ApiSchemaUpdateUser, ApiSchemaUserDetailsData, OperatorFinanceProfileModel, } from "../../models";
import { useFunctionalityState, useFunctionalityUser2, } from "../../pages/hooks";
import { DefaultImageType, FileBucketURL } from "../../configs";
import { ModalChangeProfilePhoto, ModalEditUserInfo } from "../modals";
import { useFunctionalityImageView } from "../../pages/hooks/functionalityImageView";
import { genders } from "../modals/jobFormData";
import UserProfileImageLoader from "../Common/Components/CommonUserProfile/UserProfileImageLoader";

type Props = {
  userProfileData?: OperatorFinanceProfileModel;
  onUpdateSuccess?: any;
  setOnUpdateSuccess?: any;
};
export function OperatorProfileInfo({ userProfileData, onUpdateSuccess, setOnUpdateSuccess, }: Props) {
  const [editModalShow, setEditModalShow] = useState(false);
  const [profilePictureModalShow, setProfilePictureModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const { defaultImageLoad, imageView, onUploadComplete } = useFunctionalityImageView();
  const { updateUserDetails } = useFunctionalityUser2({ userId: userProfileData?.id, });
  const onUpdateHandler = (form: ApiSchemaUpdateUser) => {
    form = { ...form, id: userProfileData?.id, gender: Number(form.gender) };
    updateUserDetails(form)
      .then(() => {
        // mutate(PlatformApi.Users.GetDetailsById(userProfileData?.id || ""));
        setOnUpdateSuccess(!onUpdateSuccess);
        setEditModalShow(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const userDetails: ApiSchemaUserDetailsData = {
    id: userProfileData?.id,
    name: userProfileData?.name,
    email: userProfileData?.email,
    address: userProfileData?.address?.description,
    phoneNumber: userProfileData?.contactNumber,
    education: userProfileData?.education,
    gender: userProfileData?.gender + '',
    timeZone: userProfileData?.timeZone,
    notes: userProfileData?.notes,
    firstName: userProfileData?.firstName,
    lastName: userProfileData?.lastName,
    city: userProfileData?.address?.city,
    state: userProfileData?.address?.stateId,
    zip: userProfileData?.address?.zipCode,
    country: userProfileData?.address?.countryId,
    alternativePhoneNumber: userProfileData?.alternativePhoneNumber,
  }
  const { stateList } = useFunctionalityState(userProfileData?.address?.countryId ?? "", editModalShow);
  const initialStateList = [...stateList];
  // const { optionMappedLocationList } = useFunctionalityLocation({
  //   companyId: userProfileData?.id || "", shouldLocationGetAllInvoke: editModalShow
  // });
  // const { optionMappedDepartmentList } = useFunctionalityDepartment({
  //   companyId: userProfileData?.id || "", shouldDepartmentGetAllInvoke: editModalShow
  // });
  return (
    <>
      <div className="side-profile-img-container d-flex justify-content-center align-items-center">
        {
          loading && <UserProfileImageLoader />
        }
        {
          userProfileData?.id &&
          <img
            src={`${FileBucketURL.Root}${userProfileData?.id}?${imageView.fileTimeStamp}`}
            onError={(ev) => {
              defaultImageLoad(
                ev,
                DefaultImageType.DefaultCompanyProfile,
                "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              );
            }}
            onLoad={() => setLoading(false)}
            className={`img-fluid ${loading ? 'd-none' : ""}`}
          />
        }
      </div>
      <div className="row justify-content-center">
        <button
          type="button"
          onClick={() => setProfilePictureModalShow(true)}
          className="btn  btn-purple text-site font-sz-14  my-3"
        >
          Upload Logo
        </button>
      </div>
      <div className="row  align-items-center mx-auto mb-3">
        <div className="col px-1 ">
          <h4 className="m-0 font-300 text-dark">{userProfileData?.name}</h4>
        </div>
        <div className="name-edit-btn">
          {userProfileData?.name ? (
            <button
              type="button"
              onClick={() => setEditModalShow(true)}
              className="btn btn-link text-site px-0 svg-color"
              title="Edit"
            >
              <div className="btn svg-color" title="profileEdit">
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </button>
          ) : null}
        </div>
      </div>
      <div className="row border mx-0 border-radius-xl mt-4 mb-3">
        <div className="col-12 py-3  ">
          <div className="row border-bottom">
            <p className=" col  border-grey mb-2 pb-1 font-500">Address</p>
          </div>
          <div className="row ">
            <p className="col text-dark mt-3 mb-1 miscelleous-container text-break">
              {userProfileData?.address?.description
                ? userProfileData?.address?.description?.concat(", ")
                : ""}
              {userProfileData?.address?.city
                ? userProfileData?.address?.city?.concat(", ")
                : ""}
              {userProfileData?.address?.stateName
                ? userProfileData?.address?.stateName?.concat(", ")
                : ""}
              {userProfileData?.address?.zipCode
                ? userProfileData?.address?.zipCode?.concat(", ")
                : ""}
              {userProfileData?.address?.countryName}
            </p>
          </div>
        </div>
      </div>
      {
        userProfileData?.email && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col  font-500 border-grey mb-2 pb-1">Email</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {userProfileData?.email}
              </p>
            </div>
          </div>
        </div>
      }
      {
        userProfileData?.address?.website && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col font-500 border-grey mb-2 pb-1">Website</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {userProfileData?.address?.website}
              </p>
            </div>
          </div>
        </div>
      }

      {
        userProfileData?.contactNumber && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col  font-500 border-grey mb-2 pb-1">Phone Number</p>
            </div>
            <div className="row ">
              <p className="col text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {userProfileData?.contactNumber}
              </p>
            </div>
          </div>
        </div>
      }

      {
        userProfileData?.address?.fax && <div className="row border mx-0 border-radius-xl mt-4 mb-3">
          <div className="col-12 py-3  ">
            <div className="row border-bottom">
              <p className=" col font-500 border-grey mb-2 pb-1">Fax</p>
            </div>
            <div className="row ">
              <p className="col  text-dark mt-3 mb-1 miscelleous-container word-wrap">
                {userProfileData?.address?.fax}
              </p>
            </div>
          </div>
        </div>
      }
      {
        editModalShow ?
          <ModalEditUserInfo
            onUpdateHandler={onUpdateHandler}
            userDetailsData={userDetails}
            // optionMappedLocationList={optionMappedLocationList}
            // optionMappedDepartmentList={optionMappedDepartmentList}
            show={editModalShow}
            handleShow={setEditModalShow}
            initialStateList={initialStateList}
            genders={genders}
          />
          : null
      }
      <ModalChangeProfilePhoto
        useId={userProfileData?.id ?? ""}
        onUploadComplete={onUploadComplete}
        show={profilePictureModalShow}
        handleShow={setProfilePictureModalShow}
      />
    </>
  );
}
