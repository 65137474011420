import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { useFunctionalityJobNoteAddOrEdit } from "../../pages/hooks";
import { FormErrorMessage } from '../../components';
import { ApiSchemaJobDetailsById } from "../../models";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
type Props = {
  register?: any,
  handleSubmit?: any,
  errors?: any,
  setValue?: any,
  jobData?: ApiSchemaJobDetailsById,
  show: boolean,
  handleShow: (value: boolean) => void
  setSweetChangeProp: any
}
export function ModalAddNotes(props: Props): JSX.Element {
  const { handleSubmit, jobData, setSweetChangeProp } = props;
  const [customerNote, setCustomerNote] = useState(jobData?.customerNote || '');
  const [operatorNote, setOperatorNote] = useState(jobData?.operatorNote || '');
  const [adminNote, setAdminNote] = useState(jobData?.adminNote || '');
  const [adminNoteCharLimitError, setAdminNoteCharLimitError] = useState<string>("");
  const [customerNoteCharLimitError, setCustomerNoteCharLimitError] = useState<string>("");
  const [operatorNoteCharLimitError, setOperatorNoteCharLimitError] = useState<string>("");
  const { JobAddOrEditNotes } = useFunctionalityJobNoteAddOrEdit();
  const { handleSpecialCharacterWhiteSpacesForNote } = useFunctionalityInputChecker()

  const modalHideHandler = () => {
    props.handleShow(false);
  }
  const onAddOrEditJobNotesHandler = (form: ApiSchemaJobDetailsById) => {
    form = {
      ...form,
      customerNote: form?.customerNote,
      operatorNote: form?.operatorNote,
      adminNote: form?.adminNote
    }
    JobAddOrEditNotes(jobData?.id ?? '', form)
      ?.then(() => {
        setSweetChangeProp((prev: number) => prev + 1)
        modalHideHandler();
      })
      .catch(err => console.log(err));
  }
  const handleCustomerNote = (event: any) => {
    const customerNote = event.target.value;
    if (customerNote?.length > 500) {
      setCustomerNoteCharLimitError("Comment exceeded max character limit");
      return;
    } else {
      props.setValue("customerNote", customerNote);
      setCustomerNote(customerNote);
      setCustomerNoteCharLimitError("");
    }
  }
  const handleOperatorNote = (event: any) => {
    const operatorNote = event.target.value;
    if (operatorNote?.length > 500) {
      setOperatorNoteCharLimitError("Comment exceeded max character limit");
      return;
    } else {
      props.setValue("operatorNote", operatorNote);
      setOperatorNote(operatorNote);
      setOperatorNoteCharLimitError("");
    }
  }
  const handleAdminNote = (event: any) => {
    const adminNote = event.target.value;
    if (adminNote?.length > 500) {
      setAdminNoteCharLimitError("Comment exceeded max character limit");
      return;
    } else {
      props.setValue("adminNote", adminNote);
      setAdminNote(adminNote);
      setAdminNoteCharLimitError("");
    }
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
    >
      <form onSubmit={handleSubmit(onAddOrEditJobNotesHandler)} noValidate>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="" className="col-12 p-0">Customer note</label>
              <textarea name="customerNote"
                id="customerNote"
                className="form-control"
                placeholder={"Max 500 character"}
                defaultValue={customerNote}
                ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForNote })}
                onChange={handleCustomerNote}
                style={{ border: props.errors.customerNote ? '1px solid red' : '' }}
              />
              {props.errors.customerNote && props.errors.customerNote.type == "validate" && (
                <FormErrorMessage message="Please enter a valid value" />
              )}
              {
                customerNoteCharLimitError && <FormErrorMessage message={customerNoteCharLimitError} />
              }
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="" className="col-12 p-0">Operator note</label>
              <textarea name="operatorNote"
                id="operatorNote"
                className="form-control"
                placeholder={"Max 500 character"}
                defaultValue={operatorNote}
                ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForNote })}
                onChange={handleOperatorNote}
                style={{ border: props.errors.operatorNote ? '1px solid red' : '' }}
              />
              {props.errors.operatorNote && props.errors.operatorNote.type == "validate" && (
                <FormErrorMessage message="Please enter a valid value" />
              )}
              {
                operatorNoteCharLimitError && <FormErrorMessage message={operatorNoteCharLimitError} />
              }
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="" className="col-12 p-0">Admin note</label>
              <textarea name="adminNote"
                id="adminNote"
                className="form-control"
                defaultValue={adminNote}
                placeholder={"Max 500 character"}
                ref={props.register({ validate: handleSpecialCharacterWhiteSpacesForNote })}
                onChange={handleAdminNote}
                style={{ border: props.errors.adminNote ? '1px solid red' : '' }}
              />
              {props.errors.adminNote && props.errors.adminNote.type == "validate" && (
                <FormErrorMessage message="Please enter a valid value" />
              )}
              {
                adminNoteCharLimitError && <FormErrorMessage message={adminNoteCharLimitError} />
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-link text-site text-uppercase" onClick={() => props.handleShow(false)}>Cancel</button>
          <button type="submit" className="login-from-submit-btn btn btn-primary text-uppercase" disabled={!!(customerNoteCharLimitError || operatorNoteCharLimitError || adminNoteCharLimitError)}>Comment</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}