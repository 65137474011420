const api = [
  'api/User/GetUserMenuSettings',
  'api/Auth/WebToken',
  'api/Auth/Login',
  'api/Auth/externalLogin',
  'api/Auth/Logout',
  'api/Call/Create',
  'api/Call/Update',
  'api/Call/CompleteCall',
  'api/Call/GetCallSummary',
  'api/Call/GetCallParticipantInfo',
  'api/ThreeWayCall/RedialOutbound',
  'api/Room/IsRoomCreated',
  'api/Operator/ChangeCurrentStatus',
  'api/Operator/StartInitiated',
  'api/Operator/FilterOperator',
  'api/Tin/GetUserTinById',
  'api/Tin/IsTinExists',
];

export const LogConfig = {
  allowed: {
    api,
  },
};
