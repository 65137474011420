import React from "react";
import { PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { ExistModel } from "../../models/api/Operator";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityExists() {
  const skillPinExist = async (pin: string, dataToEdit: any) => {
    const btnLoading = UtilsJQuery.Ladda(".skill-form-submit-btn");
    const codeUrl = PlatformApi.Skill.IsPinExists(pin)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        btnLoading.start?.();
        if (r.data?.data) {
          if (dataToEdit && dataToEdit.pin === pin) {
            btnLoading.stop?.();
            return false
          } else {
            btnLoading.stop?.();
            return true;
          }
        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
      });
    return exist
  }
  const skillNameExist = async (name: string, dataToEdit: any) => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();
    const codeUrl = PlatformApi.Skill.IsNameExists(name)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        if (r.data?.data) {
          if (dataToEdit && dataToEdit.name === name) {
            btnLoading.stop?.();
            return false
          } else {
            btnLoading.stop?.();
            return true
          }
        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
        console.log("err", e)
      });
    return exist
  }

  const onLanguagePinExists = async (pin: string, dataToEdit: any) => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();
    const codeUrl = PlatformApi.Language.IsPinExists(pin)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        if (r.data?.data) {
          if (dataToEdit && dataToEdit.pin === pin) {
            return false
          } else {
            return true
          }

        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
        console.log("err", e)
      })
    btnLoading.stop?.();
    return exist
  }

  const onLanguageCodeExists = async (code: string, dataToEdit: any) => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();
    const codeUrl = PlatformApi.Language.IsCodeExists(code)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        if (r.data?.data) {
          if (dataToEdit && dataToEdit.code === code) {
            return false
          } else {
            return true
          }
        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
        console.log("err", e)
      })
    btnLoading.stop?.();
    return exist
  }

  const onLanguageNameExists = async (name: string, dataToEdit: any) => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();
    const codeUrl = PlatformApi.Language.IsNameExists(name)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        if (r.data?.data) {
          if (dataToEdit && dataToEdit.name === name) {
            return false
          } else {
            return true
          }
        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
        console.log("err", e)
      })
    btnLoading.stop?.();
    return exist
  }

  const onTinExists = async (tin: any, prevTin: any) => {
    const btnLoading = UtilsJQuery.Ladda(".test-modal");
    btnLoading.start?.();
    const codeUrl = PlatformApi.Tin.IsTinExists(tin)
    const exist = await AxiosAuth.get<ApiResponse<ExistModel>>(codeUrl)
      .then((r) => {
        if (r.data?.data) {
          if (prevTin === tin) {
            return false
          }
          else {
            return true
          }
        }
        else {
          btnLoading.stop?.();
          return false
        }
      })
      .catch((e) => {
        btnLoading.stop?.();
        console.log("err", e)
      })
    btnLoading.stop?.();
    return exist
  }

  return {
    onLanguagePinExists,
    onLanguageCodeExists,
    onLanguageNameExists,
    onTinExists,
    skillPinExist,
    skillNameExist
  };
}
