import useSWR from "swr";
import { PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";

export function useFunctionalityGetBookingHistories(
  bookingId: string,
  pageSize?: number,
  pageNo?: number,
  searchText?: string,
  setSweetChangeProp?: any
) {
  const fetcher = (url: string) => AxiosAuth.get(url);

  const { data } = useSWR(
    bookingId
      ? PlatformApi.Booking.GetBookingHistories(
          bookingId,
          pageSize,
          pageNo,
          searchText
        )
      : null,
    fetcher
  );

  return {
    getBookingHistoriesData: data,
  };
}
