import React, { useState } from "react";
import { PlatformApi, topLoaderProgress } from "../../configs";
import useSWR from "swr";
import { ApiResponse, WithPagination } from "../../models";
import { AxiosAuth } from "../../core";
import { ApiSchemaCallCenterActivityData } from "../../models/api/CallCenterActivity";
import { ApiSchemaFilter } from "../../models/api/filter";

type Props = {
  callCenterId: string;
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};

let pagedCallCenterActivityUrl: string;

export function useFunctionalityCallCenterActivity(props: Props) {
  const {
    callCenterId,
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    filterData,
    filterDataChange,
  } = props;
  const [pagedActivityListCount, setPagedActivityListCount] =
    useState<number>(0);
  const [activityTableData, setActivityTableData] =
    useState<ApiSchemaCallCenterActivityData[]>();

  pagedCallCenterActivityUrl = PlatformApi.CallCenter.Activity(
    callCenterId,
    pageSize,
    pageNo,
    searchText,
    filterDataChange
  );

  useSWR<ApiSchemaCallCenterActivityData[]>(pagedCallCenterActivityUrl, () => {
    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.post<
      ApiResponse<WithPagination<ApiSchemaCallCenterActivityData[]>>
    >(pagedCallCenterActivityUrl, filterData)
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        setPagedActivityListCount(r.data.data?.count);
        setActivityTableData(r.data.data.data);
        return r.data.data?.data;
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  });

  // const pagedActivityList = React.useMemo(() => {
  //     const data = Array.isArray(apiPagedCallCenterActivityList) ? apiPagedCallCenterActivityList : [];
  //     return data;
  // }, [apiPagedCallCenterActivityList]);
  return {
    pagedActivityList: activityTableData,
    pagedActivityListCount,
    pagedCallCenterActivityUrl,
  };
}
