import React from "react";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssetSvg } from '../../assets';
export function PageChat(): JSX.Element {
  return (
    <div className="site-call-page">
      <div className="site-call-page-wrapper">
        <div className="container-fluid h-100">
          <div className="row h-100 bg-white">
            <div className="w-100 position-fixed h-100">
              <div className="col-12">
                <div className="row align-items-center">
                  <button className="btn btn-link pb-0 pt-1 ">
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="text-muted mr-1 ml-1"
                    />
                  </button>
                </div>
              </div>
              <div className="col-12 py-2">
                <div className="input-group">
                  <input
                    type="text"
                    className="search-input-control form-control"
                    aria-label="search-input"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-dark border-grey text-muted"
                      type="button"
                    >
                       <AssetSvg.SearchIcon />
                    </button>

                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mx-auto ">
                <div className="row align-items-center justify-content-between ">
                  <a href="" className="btn  col py-4 text-muted" role="button">
                    <svg viewBox="0 0 24 24" height="24px" width="24px" fill="currentColor">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M21 5v14h2V5h-2zm-4 14h2V5h-2v14zM14 5H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM8 7.75c1.24 0 2.25 1.01 2.25 2.25S9.24 12.25 8 12.25 5.75 11.24 5.75 10 6.76 7.75 8 7.75zM12.5 17h-9v-.75c0-1.5 3-2.25 4.5-2.25s4.5.75 4.5 2.25V17z" />
                    </svg>
                  </a>
                  <a href="" className="btn  col py-4 text-muted" role="button">
                    <svg viewBox="0 0 24 24" height="24px" width="24px" fill="currentColor">
                      <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none" />
                      <path d="M20 0H4v2h16V0zM4 24h16v-2H4v2zM20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25S9.75 10.24 9.75 9 10.76 6.75 12 6.75zM17 17H7v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5V17z" />
                    </svg>
                  </a>
                  <a href="" className="btn col py-4 text-muted" role="button">
                    <svg viewBox="0 0 24 24" height="24px" width="24px" fill="currentColor">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="recent-chat-tab">
                <div className="col-12 text-center py-4 ">
                  <a href="d-flex align-items-center btn btn-link" className="text-dark">

                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                      viewBox="0 0 24 24" width="24px" height="24px">

                      <path fill="none" stroke="currentColor" stroke-width="1.25" strokeMiterlimit="10" d="M12,20.2c-1.48,0-2.94-0.23-4.33-0.67l-1.26-0.4l-1.08,0.76c-0.79,0.56-1.87,1.18-3.18,1.6
	c0.4-0.67,0.8-1.42,1.1-2.22l0.59-1.55L2.7,16.51c-1-1.07-2.2-2.83-2.2-5.16C0.5,6.47,5.66,2.5,12,2.5s11.5,3.97,11.5,8.85
	S18.34,20.2,12,20.2z"/>
                      <circle fill="#d3d3d3" cx="17.26" cy="16.62" r="6" />
                      <path d="M20.51,15.77l-0.65,0.51c-0.07,0.05-0.16,0.03-0.21-0.04l-1.11-1.76c-0.05-0.07-0.03-0.18,0.04-0.23l0.65-0.51
	c0.27-0.21,0.63-0.13,0.82,0.16l0.6,0.95C20.84,15.15,20.78,15.56,20.51,15.77z M18.01,14.68l-3.73,2.9l-0.51,1.59
	c-0.07,0.21,0.07,0.44,0.28,0.44l1.51,0.01l3.73-2.9c0.07-0.05,0.08-0.15,0.04-0.23l-1.11-1.76C18.17,14.65,18.08,14.63,18.01,14.68
	L18.01,14.68z M15.58,17.53c-0.05-0.09-0.04-0.21,0.04-0.27l2.18-1.7c0.08-0.06,0.18-0.04,0.24,0.05c0.05,0.09,0.04,0.21-0.04,0.27
	l-2.18,1.7C15.74,17.64,15.63,17.62,15.58,17.53L15.58,17.53z M14.96,18.57l0.58,0.12l-0.08,0.49l-0.8,0l-0.31-0.49l0.27-0.84
	l0.44,0.09L14.96,18.57z"/>
                    </svg>
                    <span className="ml-1 text-muted">
                      Start New Conversation
                    </span>

                  </a>
                </div>
                <div className="col-12 recent-chat-list">

                  <div className="row recent-chat-list-item ">
                    <div className="incoming-chat-image mx-2 p-1">
                      <img
                        src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                    <div className="col">
                      <div className="row align-items-start">
                        <div className="col-8 pl-0">
                          <p className="text-muted mb-0 text-truncate">
                            Both Operator
                          </p>
                          <p className="text-truncate mb-0">
                            <small>
                              <strong>Hello</strong>
                            </small>
                          </p>
                        </div>
                        <div className="col-4 text-right">
                          <span>
                            <small> April 20,2021</small>
                          </span>
                        </div>
                      </div>
                      <div className="row border-bottom pt-1 "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
