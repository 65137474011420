import React, { useEffect, useState } from 'react';
import {
  CallLogTableDataWeb,
  ModalCallSummary,
  Searchbar,
} from '../../features';
import { CommonFilter } from '../../features/Jobs/commonFilter';
import {
  useFunctionalityBookingSummary,
  useFunctionalityCallLog,
  useFunctionalityCallSummary,
  useFunctionalityColumnSelections,
  useFunctionalityUserProfile,
} from '../hooks';
import { useLocation } from 'react-router-dom';
import {
  CallLogPagedResponseModel,
  CallSummaryResponseModel,
} from '../../models/api/Call';
import {
  AppConstants,
  CallLogType,
  CallStatusType,
  ColumnSelectionTableType,
  ExportTableType,
  PaginationSettings,
  topLoaderProgress,
} from '../../configs';
import LoadingBar from 'react-top-loading-bar';
import { useAppSelector } from '../../core';
import { useFunctionalityCallLogColumns } from '../hooks/functionalityCallLogColumns';
import { CallLogInfoBox } from '../../features/Common/Components/CallLogInfoBox';
import { ApiSchemaMenuSettingsData } from '../../models/api/menuSettings';
import ReactDOM from 'react-dom';
import { ColumnSelectionsModel } from '../../models';
import { ApiSchemaFilter } from '../../models/api/filter';
import useFunctionalityMediaPermissions from '../hooks/mediaPermission/functionalityMediaPermission';
import { controlBrowserBackAndReloadButton } from '../../helpers';

export function PageCallLog(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [callSummaryData, setCallSummaryData] =
    React.useState<CallSummaryResponseModel>(Object);
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );
  const currentSession = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole,
    callStatus: state.videoCall.CallStatus,
  }));
  const { controlBrowserBackButton } = controlBrowserBackAndReloadButton();
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>('');
  const [callLogData, setCallLogData] =
    useState<CallLogPagedResponseModel>(Object);
  const [pagedCallListCount, setPagedCallListCount] = useState(0);
  const location = useLocation<{
    callId: string;
    bookingId: string;
    CallStatus: CallStatusType;
  }>();
  const callId = location.state?.callId;
  const bookingId = location.state?.bookingId;
  const { LoadById } = useFunctionalityCallSummary();
  const { LoadBookingSummaryById } = useFunctionalityBookingSummary();
  const [menuSettings, setMenuSettings] = useState<ApiSchemaMenuSettingsData>();
  const { stopBothVideoAndAudio } = useFunctionalityMediaPermissions();

  const getCallLogType = () => {
    if (
      currentSession.userRole === AppConstants.UserRoles.Operator ||
      currentSession.userRole === AppConstants.UserRoles.User ||
      currentSession.userRole === AppConstants.UserRoles.CompanyAdmin ||
      currentSession.userRole === AppConstants.UserRoles.CompanyFinance
    )
      return CallLogType.CallLog;
    else return CallLogType.InternalCall;
  };

  const [columnSelection, setColumnSelection] = useState<
    ColumnSelectionsModel[]
  >([]);
  const [columnSelectionModalShow, setColumnSelectionModalShow] =
    React.useState(false);
  const getColumnSelectionsType = () => {
    if (
      currentSession.userRole === AppConstants.UserRoles.Operator ||
      currentSession.userRole === AppConstants.UserRoles.User ||
      currentSession.userRole === AppConstants.UserRoles.CompanyAdmin ||
      currentSession.userRole === AppConstants.UserRoles.CompanyFinance
    )
      return ColumnSelectionTableType.CallLog;
    else return ColumnSelectionTableType.InternalCall;
  };
  const { LoadColumnSelections } = useFunctionalityColumnSelections({
    type: getColumnSelectionsType(),
  });

  useEffect(() => {
    LoadColumnSelections().then((r) => {
      if (r?.data) setColumnSelection(r?.data);
    });
  }, []);

  useEffect(() => {
    stopBothVideoAndAudio();
  }, [callSummaryData]);

  const { callLogColumn } = useFunctionalityCallLogColumns({
    userRole: currentSession.userRole ?? '',
    setShow: setShow,
    setCallSummaryData: setCallSummaryData,
    setLoaderProgress: setLoaderProgress,
    callLogType: getCallLogType(),
    columnSelection: columnSelection,
  });

  let initialFilterData: ApiSchemaFilter = {
    filterQueries: [],
    sortQuery: {},
  };
  const [filterData, setFilterData] =
    useState<ApiSchemaFilter>(initialFilterData);

  const { LoadCallLog } = useFunctionalityCallLog({
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    callLogType: getCallLogType(),
    setLoaderProgress,
    filterData,
  });

  useEffect(() => {
    if (callId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadById(callId).then((data) => {
        setLoaderProgress(topLoaderProgress.complete);
        if (data?.id) {
          setCallSummaryData(data);
          setShow(true);
        }
      });
    } else if (bookingId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadBookingSummaryById(bookingId).then((data) => {
        setLoaderProgress(topLoaderProgress.complete);
        if (data) setCallSummaryData(data);
        setShow(true);
      });
    }
    setLoaderProgress(topLoaderProgress.start);
    LoadCallLog().then((r) => {
      setLoaderProgress(topLoaderProgress.complete);

      if (r?.data) setCallLogData(r?.data);
      if (r?.data) setPagedCallListCount(r?.data.totalCount);
    });
  }, [pageSize, pageIndex, callId, bookingId, filterData, searchText]);

  useEffect(() => {}, [menuSettings]);
  const { getMenusettings } = useFunctionalityUserProfile();
  useEffect(() => {
    if (currentSession.userRole == AppConstants.UserRoles.Operator) {
      setLoaderProgress(topLoaderProgress.start);
      getMenusettings().then((d) => {
        if (d) setMenuSettings(d);
        setLoaderProgress(topLoaderProgress.complete);
      });
    }
  }, []);

  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };

  const getExportTableType = () => {
    if (
      currentSession.userRole === AppConstants.UserRoles.Operator ||
      currentSession.userRole === AppConstants.UserRoles.User ||
      currentSession.userRole === AppConstants.UserRoles.CompanyAdmin ||
      currentSession.userRole === AppConstants.UserRoles.CompanyFinance
    )
      return ExportTableType.CallLog;
    else return ExportTableType.InternalCall;
  };

  useEffect(() => {
    if (
      location.state?.CallStatus === CallStatusType.Canceled ||
      currentSession.callStatus?.current === CallStatusType.Rejected
    ) {
      controlBrowserBackButton();
    }
  }, [location.state?.CallStatus, currentSession.callStatus]);
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light">Internal Call</h4>
      <div className="call-log-page ">
        <div className="row">
          <div className="col-12">
            <Searchbar
              showFilter={true}
              showExportOptions={true}
              filterPanel={'commonFilterPanel'}
              searchText={searchText}
              handleSearch={handleSearch}
              columnSelectionModalShow={columnSelectionModalShow}
              setColumnSelectionModalShow={setColumnSelectionModalShow}
              columnSelection={columnSelection}
              setColumnSelection={setColumnSelection}
              showAutoRefresh={true}
              tableType={getExportTableType()}
              filterData={filterData}
              downloadedFileName={'CallLog'}
              setLoaderProgress={setLoaderProgress}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
          {isShowCommonFilter ? (
            <CommonFilter
              setFilterData={setFilterData}
              setLoaderProgress={setLoaderProgress}
              pageName="callLogPage"
              setPageIndex={setPageIndex}
            />
          ) : null}

          <CallLogInfoBox
            callLogData={callLogData}
            userRole={currentSession.userRole ?? ''}
          />
          <CallLogTableDataWeb
            loaderProgress={loaderProgress}
            pageHeader="Call Log"
            isPageEnabled={true}
            tableData={callLogData.callLogs}
            tableHeaders={callLogColumn}
            pageIndex={pageIndex}
            pageSize={pageSize}
            count={pagedCallListCount}
            onChangePageIndex={onChangePageIndex}
            onChangePageSize={onChangePageSize}
            setCallSummaryData={setCallSummaryData}
            setLoaderProgress={setLoaderProgress}
            setShow={setShow}
          />
          <ModalCallSummary
            summary={callSummaryData}
            show={show}
            handleShow={setShow}
          />
        </div>
      </div>
    </>
  );
}
