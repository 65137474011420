import { AppConstants, AppRouteUi, InvoiceGenerationType, InvoiceType, TransactionType } from ".";
import { useAppSelector } from "../core";
import { BillingAddressTab } from "../features/Account/AccountTabs/billingAddressTab";
import {
  CommonInvoice,
  CommontTransaction,
} from "../features/Common/Components/CommonAccountInvoice";
import {
  ContactTab,
  ContractTab,
  DepartmentTab,
  IvrTab,
  LocationTab,
  PackageTab,
  ReasonTab,
  SettingsTab,
  SkillTab,
  UsersTab,
} from "../features/CompanyProfile/companyProfileTabs";
import { AppointmentCategoryTab } from "../features/CompanyProfile/companyProfileTabs/appointCategoryTab";
import { AppointmentDescriptionTab } from "../features/CompanyProfile/companyProfileTabs/appointmentDescriptionTab";
import { CompanyTab } from "../features/CompanyProfile/companyProfileTabs/companyTab";
import { ModalityTab } from "../features/CompanyProfile/companyProfileTabs/modalityTab";
import {
  OutflowTab,
  ProductsTab,
  VendorTab,
  ResellerPackageTab,
} from "../features/ResellerProfile/ResellerProfileTab";
import {
  ApiSchemaCompanyByIdData,
  CompanyAccountViewModel,
  FinanceViewModel,
} from "../models";

export type Tab = {
  name: string;
  tabId: string;
  referenceId: string;
  component: any;
  loaderProgress?: number;
  setLoaderProgress?: any;
};

export const CompanyTabs = [
  {
    name: "Users",
    tabId: "nav-profile-users-tab",
    referenceId: "nav-profile-users",
    component: () => (
      <UsersTab
        adminRole={AppConstants.UserRoles.CompanyAdmin}
        companyId={AppRouteUi.Company.Profile.useParam()}
        pageName="companyProfilePage"
      />
    ),
  },
  {
    name: "Contract",
    tabId: "nav-profile-contracts-tab",
    referenceId: "nav-profile-contracts",
    component: (handleTab: any) => (
      <ContractTab editable={true} />
    ),
  },
  {
    name: "IVR",
    tabId: "nav-profile-IVR-tab",
    referenceId: "nav-profile-IVR",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <IvrTab editable={true} />
    ),
  },
  {
    name: "Package",
    tabId: "nav-profile-packages-tab",
    referenceId: "nav-profile-packages",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <PackageTab editable={true} copyable={true} />
    ),
  },
  {
    name: "Skill",
    tabId: "nav-profile-skills-tab",
    referenceId: "nav-profile-skills",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <SkillTab editable={true} />
    ),
  },
  {
    name: "Location",
    tabId: "nav-profile-locations-tab",
    referenceId: "nav-profile-locations",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <LocationTab editable={true} />
    ),
  },
  {
    name: "Department",
    tabId: "nav-profile-departments-tab",
    referenceId: "nav-profile-departments",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <DepartmentTab editable={true} />
    ),
  },
  {
    name: "Contact",
    tabId: "nav-profile-contacts-tab",
    referenceId: "nav-profile-contacts",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ContactTab editable={true} />
    ),
  },
  {
    name: "Reasons",
    tabId: "nav-profile-reasons-tab",
    referenceId: "nav-profile-reasons",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ReasonTab companyId={AppRouteUi.Company.Profile.useParam()} />
    ),
  },
  {
    name: "Settings",
    tabId: "nav-profile-settings-tab",
    referenceId: "nav-profile-settings",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <SettingsTab />
    ),
  },
  {
    name: "Modality",
    tabId: "nav-profile-modality-tab",
    referenceId: "nav-profile-modality",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ModalityTab editable={true} />
    ),
  },
  {
    name: "Appointment Type",
    tabId: "nav-profile-at-tab",
    referenceId: "nav-profile-at",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <AppointmentCategoryTab editable={true} />
    ),
  },
  {
    name: "Description",
    tabId: "nav-profile-ad-tab",
    referenceId: "nav-profile-ad",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <AppointmentDescriptionTab editable={true} />
    ),
  },
];
export const CompanyTabsView = [
  {
    name: "Users",
    tabId: "nav-profile-users-tab",
    referenceId: "nav-profile-users",
    component: () => (
      <UsersTab
        adminRole={AppConstants.UserRoles.CompanyAdmin}
        companyId={AppRouteUi.Company.Profile.useParam()}
      />
    ),
  },
  {
    name: "Contract",
    tabId: "nav-profile-contracts-tab",
    referenceId: "nav-profile-contracts",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ContractTab editable={false} />
    ),
  },
  {
    name: "IVR",
    tabId: "nav-profile-IVR-tab",
    referenceId: "nav-profile-IVR",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <IvrTab editable={false} companyProfileData={companyProfileData} />
    ),
  },
  {
    name: "Package",
    tabId: "nav-profile-packages-tab",
    referenceId: "nav-profile-packages",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <PackageTab editable={false} companyProfileData={companyProfileData} />
    ),
  },
  {
    name: "Skill",
    tabId: "nav-profile-skills-tab",
    referenceId: "nav-profile-skills",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <SkillTab editable={false} />
    ),
  },
  {
    name: "Location",
    tabId: "nav-profile-locations-tab",
    referenceId: "nav-profile-locations",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <LocationTab editable={true} />
    ),
  },
  {
    name: "Department",
    tabId: "nav-profile-departments-tab",
    referenceId: "nav-profile-departments",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <DepartmentTab editable={true} />
    ),
  },
  {
    name: "Contact",
    tabId: "nav-profile-contacts-tab",
    referenceId: "nav-profile-contacts",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ContactTab editable={false} companyProfileData={companyProfileData} />
    ),
  },
  {
    name: "Reasons",
    tabId: "nav-profile-reasons-tab",
    referenceId: "nav-profile-reasons",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ReasonTab companyId={AppRouteUi.Company.Profile.useParam()} />
    ),
  },
  {
    name: "Modality",
    tabId: "nav-profile-modality-tab",
    referenceId: "nav-profile-modality",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ModalityTab editable={false} />
    ),
  },
  {
    name: "Appointment Type",
    tabId: "nav-profile-at-tab",
    referenceId: "nav-profile-at",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <AppointmentCategoryTab editable={false} />
    ),
  },
  {
    name: "Description",
    tabId: "nav-profile-ad-tab",
    referenceId: "nav-profile-ad",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <AppointmentDescriptionTab editable={false} />
    ),
  },
];

export const CompanyTabsViewCompanyFinance = [
  {
    name: "Contract",
    tabId: "nav-profile-contracts-tab",
    referenceId: "nav-profile-contracts",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ContractTab editable={false} />
    ),
  },
  {
    name: "IVR",
    tabId: "nav-profile-IVR-tab",
    referenceId: "nav-profile-IVR",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <IvrTab editable={false} companyProfileData={companyProfileData} />
    ),
  },
  // {
  //   name: "Package",
  //   tabId: "nav-profile-packages-tab",
  //   referenceId: "nav-profile-packages",
  //   component: (companyProfileData: ApiSchemaCompanyByIdData) => (
  //     <PackageTab editable={false} companyProfileData={companyProfileData} />
  //   ),
  // },
];

export const ResellerTabs = [
  {
    name: "Users",
    tabId: "nav-profile-users-tab",
    referenceId: "nav-profile-users",
    component: () => (
      <UsersTab
        adminRole={AppConstants.UserRoles.ResellerAdmin}
        companyId={AppRouteUi.Reseller.Profile.useParam()}
      // pageName="companyProfilePage"
      />
    ),
  },
  {
    name: "Companies",
    tabId: "nav-profile-companies-tab",
    referenceId: "nav-profile-companies",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => <CompanyTab companyProfileData={companyProfileData} />,
  },
  {
    name: "Contract",
    tabId: "nav-profile-contacts-tab",
    referenceId: "nav-profile-contracts",
    component: () => (
      <ContractTab editable={true} />
    ),
  },
  {
    name: "Package",
    tabId: "nav-profile-packages-tab",
    referenceId: "nav-profile-packages",
    component: () => (
      <ResellerPackageTab />
    ),
  },
  {
    name: "Skill",
    tabId: "nav-profile-skills-tab",
    referenceId: "nav-profile-skills",
    component: () => (
      <SkillTab editable={true} />
    ),
  },
  {
    name: "Contact",
    tabId: "nav-profile-contacts-tab",
    referenceId: "nav-profile-contacts",
    component: () => (
      <ContactTab editable={true} />
    ),
  },
  {
    name: "Reasons",
    tabId: "nav-profile-reasons-tab",
    referenceId: "nav-profile-reasons",
    component: () => (
      <ReasonTab companyId={AppRouteUi.Reseller.Profile.useParam()} />
    ),
  },
  {
    name: "Products",
    tabId: "nav-profile-products-tab",
    referenceId: "nav-profile-products",
    component: (companyProfileData: ApiSchemaCompanyByIdData) => (
      <ProductsTab companyProfileData={companyProfileData} />
    ),
  },
  {
    name: "Phone",
    tabId: "nav-profile-phone-tab",
    referenceId: "nav-profile-phone",
    component: () => <VendorTab />,
  },
  {
    name: "Outflow",
    tabId: "nav-profile-outflow-tab",
    referenceId: "nav-profile-outflow",
    component: () => <OutflowTab />,
  },
  {
    name: "Modality",
    tabId: "nav-profile-modality-tab",
    referenceId: "nav-profile-modality",
    component: () => (
      <ModalityTab editable={true} />
    ),
  },
  {
    name: "Appointment Type",
    tabId: "nav-profile-at-tab",
    referenceId: "nav-profile-at",
    component: () => (
      <AppointmentCategoryTab editable={true} />
    ),
  },
  {
    name: "Description",
    tabId: "nav-profile-ad-tab",
    referenceId: "nav-profile-ad",
    component: () => (
      <AppointmentDescriptionTab editable={true} />
    ),
  },

];
export const ProviderAccountTabs = [
  {
    name: "Customer Invoices",
    tabId: "nav-account-customer-tab",
    referenceId: "nav-account-customer",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.Customer}
        invoiceGenerationType={InvoiceGenerationType.Company}
      />
    ),
  },
  {
    name: "CallCenter Invoices",
    tabId: "nav-account-callCenter-tab",
    referenceId: "nav-account-callCenter",
    component: (
      loaderProgress: number,
      setLoaderProgress: any
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        loaderProgress={loaderProgress}
        setLoaderProgress={setLoaderProgress}
        invoiceType={InvoiceType.CallCenter}
        invoiceGenerationType={InvoiceGenerationType.Reseller}
      />
    ),
  },
  {
    name: "Operator Invoices",
    tabId: "nav-account-operator-tab",
    referenceId: "nav-account-operator",
    component: (
      loaderProgress: number,
      setLoaderProgress: any
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        loaderProgress={loaderProgress}
        setLoaderProgress={setLoaderProgress}
        invoiceType={InvoiceType.Operator}
        invoiceGenerationType={InvoiceGenerationType.Operator}
      />
    ),
  },
  {
    name: "Customer Booking Invoice",
    tabId: "nav-account-customer-booking-tab",
    referenceId: "nav-account-customer-booking",
    component: (
      loaderProgress: number,
      setLoaderProgress: any,
      infoBoxData: any
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        loaderProgress={loaderProgress}
        setLoaderProgress={setLoaderProgress}
        invoiceType={InvoiceType.CompanyBooking}
        invoiceGenerationType={InvoiceGenerationType.CompanyBooking}
      />
    ),
  },
  {
    name: "Operator Booking Invoice",
    tabId: "nav-account-operator-booking-tab",
    referenceId: "nav-account-operator-booking",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.OperatorBooking}
        invoiceGenerationType={InvoiceGenerationType.OperatorBooking}
      />
    ),
  },
  {
    name: "Transactions",
    tabId: "nav-account-transaction-tab",
    referenceId: "nav-account-transaction",
    component: (
    ) => (
      <CommontTransaction
        invoiceType={InvoiceType.All}
      />
    ),
  },
];
export const ResellerAccountTabs = [
  {
    name: "Customer Invoices",
    tabId: "nav-account-customer-tab",
    referenceId: "nav-account-customer",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.Customer}
        invoiceGenerationType={InvoiceGenerationType.Company}
      />
    ),
  },
  {
    name: "CallCenter Invoices",
    tabId: "nav-account-callCenter-tab",
    referenceId: "nav-account-callCenter",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.CallCenter}
        pageName={"reseller"}
        invoiceGenerationType={InvoiceGenerationType.Reseller}
      />
    ),
  },
  {
    name: "Operator Invoices",
    tabId: "nav-account-operator-tab",
    referenceId: "nav-account-operator",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.Operator}
        invoiceGenerationType={InvoiceGenerationType.Operator}
      />
    ),
  },
  {
    name: "Customer Booking Invoice",
    tabId: "nav-account-customer-booking-tab",
    referenceId: "nav-account-customer-booking",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.CompanyBooking}
        invoiceGenerationType={InvoiceGenerationType.CompanyBooking}
      />
    ),
  },
  {
    name: "Operator Booking Invoice",
    tabId: "nav-account-operator-booking-tab",
    referenceId: "nav-account-operator-booking",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.OperatorBooking}
        invoiceGenerationType={InvoiceGenerationType.OperatorBooking}
      />
    ),
  },
  {
    name: "Transactions",
    tabId: "nav-account-transaction-tab",
    referenceId: "nav-account-transaction",
    component: (
    ) => (
      <CommontTransaction
      />
    ),
  },
  {
    name: "Billing Address",
    tabId: "nav-account-address-tab",
    referenceId: "nav-account-address",
    component: () => (
      <BillingAddressTab
        billerId={AppRouteUi.CompanyFinance.Profile.useParam()}
        invoiceType={InvoiceType.Company}
      />
    ),
  },
];
export const CompanyFinanceProfileTab = [
  {
    name: "Invoices",
    tabId: "nav-account-customer-tab",
    referenceId: "nav-account-customer",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.Company}
        invoiceGenerationType={InvoiceGenerationType.Company}
      />
    ),
  },
  {
    name: "Booking Invoices",
    tabId: "nav-account-customer-booking-tab",
    referenceId: "nav-account-customer-booking",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.CompanyBooking}
        pageName={"company"}
        invoiceGenerationType={InvoiceGenerationType.CompanyBooking}
      />
    ),
  },
  {
    name: "Transaction",
    tabId: "nav-account-transaction-tab",
    referenceId: "nav-account-transaction",
    component: (
    ) => (
      <CommontTransaction
        invoiceType={InvoiceType.Company}
        id={AppRouteUi.CompanyFinance.Profile.useParam()}
      />
    ),
  },
  {
    name: "Billing Address",
    tabId: "nav-account-address-tab",
    referenceId: "nav-account-address",
    component: () => (
      <BillingAddressTab
        billerId={AppRouteUi.CompanyFinance.Profile.useParam()}
        invoiceType={InvoiceType.Company}
      // email={financeViewModel.email}
      />
    ),
  },
];
export const OperatorFinanceProfileTab = [
  {
    name: "Invoices",
    tabId: "nav-account-operator-tab",
    referenceId: "nav-account-operator",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.Operator}
        pageName={"operator"}
        invoiceGenerationType={InvoiceGenerationType.Operator}
      />
    ),
  },
  {
    name: "Booking Invoices",
    tabId: "nav-account-operator-booking-tab",
    referenceId: "nav-account-operator-booking",
    component: (
    ) => (
      <CommonInvoice
        transactionType={TransactionType.CustomerBilling}
        invoiceType={InvoiceType.OperatorBooking}
        pageName={"operator"}
        invoiceGenerationType={InvoiceGenerationType.OperatorBooking}
      />
    ),
  },
  {
    name: "Transaction",
    tabId: "nav-account-transaction-tab",
    referenceId: "nav-account-transaction",
    component: (
    ) => (
      <CommontTransaction
        invoiceType={InvoiceType.Operator}
        id={AppRouteUi.OperatorFinance.Profile.useParam()}
      />
    ),
  },
  {
    name: "Billing Address",
    tabId: "nav-account-address-tab",
    referenceId: "nav-account-address",
    component: () => (
      <BillingAddressTab
        billerId={AppRouteUi.OperatorFinance.Profile.useParam()}
        //  billingAddress={financeViewModel.billingAddress}
        invoiceType={InvoiceType.Operator}
      />
    ),
  },
];
