import React, { useState } from "react";
import { PlatformApi, topLoaderProgress } from "../../configs";
import useSWR from "swr";
import { ApiResponse, WithPagination } from "../../models";
import { AxiosAuth } from "../../core";
import { ApiSchemaAuditLogsData } from "../../models/api/AuditLogs";
import { ApiSchemaFilter } from "../../models/api/filter";

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};

let pagedAuditLogsUrl: string;

export function useFunctionalityAuditLogs(props: Props) {
  const {
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    filterData,
    filterDataChange,
  } = props;
  const [pagedAuditLogsListCount, setPagedAuditLogsListCount] =
    useState<number>(0);
  const [auditLogTableData, setAuditLogTableData] =
    useState<ApiSchemaAuditLogsData[]>();

  pagedAuditLogsUrl = PlatformApi.Activity.AuditLogs(
    pageSize,
    pageNo,
    searchText,
    filterDataChange
  );

  const { data: apiPagedAuditLogsList } = useSWR<ApiSchemaAuditLogsData[]>(
    pagedAuditLogsUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.post<
        ApiResponse<WithPagination<ApiSchemaAuditLogsData[]>>
      >(pagedAuditLogsUrl, filterData)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedAuditLogsListCount(r.data.data?.count);
          setAuditLogTableData(r.data.data.data);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  // const pagedAuditLogsList = React.useMemo(() => {
  //     const data = Array.isArray(apiPagedAuditLogsList) ? apiPagedAuditLogsList : [];
  //     return data;
  // }, [apiPagedAuditLogsList]);
  return {
    pagedAuditLogsList: auditLogTableData,
    pagedAuditLogsListCount,
  };
}
