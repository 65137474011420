import { Modal } from "react-bootstrap";
import { AppConstants } from "../../configs";
import { useFunctionalityOperator } from "../../pages/hooks";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  ratingId: string;
  operatorId: string
};

export function ModalDeleteOperatorRating(props: Props): JSX.Element {
  const { ratingId, operatorId } = props;
  const { onDeleteOperatorRating } = useFunctionalityOperator({})
  var onClickHandler = () => {
    onDeleteOperatorRating(ratingId, operatorId).then((r) => {
      if (r?.status == AppConstants.Api.StatusOK) {
        props.handleShow(false);
      }
    });
  };
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <Modal.Body>Are you sure you want to delete this rating?</Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            props.handleShow(false);
          }}
          className="btn btn-link text-uppercase text-site"
        >
          Cancel
        </button>
        <button
          onClick={(e) => {
            e.preventDefault()
            onClickHandler()
          }}
          className="btn btn-primary text-uppercase rating-delete"
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}
