import { faEye, faTrash, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function AllScheduleList(): JSX.Element {
  return (
    <div className="recentCall-table-container">
      <div className="component-card rounded mb-4">
        <div className="component-card-header d-flex align-items-center justify-content-between border">
          <div>All Schedule List</div>
        </div>
        <div className="component-card-body rounded-bottom border-left border-right border-bottom">
          <div className="row align-items-center justify-content-center  py-5 my-3 w-100">
            <h2 className="font-weight-normal">The list is empty</h2>
          </div>

          {/* <div className="table-responsive d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th><small>Date</small></th>
                  <th><small>time</small></th>
                  <th><small>Chatroom link</small></th>
                  <th><small>emails</small></th>
                  <th><small>phone</small></th>
                  <th><small>language</small></th>
                  <th><small>actions</small></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-data">06.20.2021</td>
                  <td className="table-data">01:30PM</td>
                  <td className="table-data">https://chatroom.link/id</td>
                  <td className="table-data">email@address.com, email@address.com</td>
                  <td className="table-data">01947465404, 0171337889</td>
                  <td className="table-data">English</td>
                  <td>
                    <a href="" data-toggle="modal" role="button"
                      data-target="" className="btn btn-link py-0"

                    >
                      <FontAwesomeIcon icon={faEye} />
                    </a>
                    <a href="" data-toggle="modal" role="button" className="btn btn-link py-0"
                      data-target=""
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                    <a href="" role="button" className=" btn btn-outline-dark border-grey  text-uppercase  ml-2">
                      <FontAwesomeIcon icon={faVideo} className="mr-2" />Start room
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
    </div>
  )
}