import React from 'react';

type Props = {
  title: string
}

export function NoDataComponent(props: Props): JSX.Element {
  const { title } = props;

  return (
    <div className="py-5 text-muted text-center">
      <h1 className="font-weight-light">{title}</h1>
    </div>
  )
}
