import { logger } from './logger';
interface IPushNotificationParams {
  title: string;
  options: NotificationOptions;
  onClick?: Function;
}

function checkPermission() {
  logger({
    message: `Notification permission status: ${Notification.permission}`,
  });
  if (Notification.permission !== 'denied') {
    logger({
      message: 'User have not given any notification permission. Asking for it',
    });
    Notification.requestPermission()
      .then((permission) => {
        logger({
          message: `User have ${permission} notification permission`,
        });
      })
      .catch((error) => {
        logger({ message: 'Error: ', data: error });
      });
  }
}

function sendPushNotification({
  title,
  options,
  onClick,
}: IPushNotificationParams) {
  if (Notification.permission !== 'granted') {
    return;
  }
  const notification = new Notification(title, options);
  notification.onclick = function (this: Notification, event: Event) {
    onClick?.(this, event);
  };
}

export const NotificationUtils = {
  sendPushNotification,
  checkPermission,
};
