import React from "react";
import { useCallback } from "react"
import { toast } from "react-toastify";
import { AppConstants, PlatformApi } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { ApiSchemaReportProblem } from "../../models/api/reportProblem"
import { UtilsJQuery } from "../../utils";

export function useFunctionalityReportProblem() {
  const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");

  const SendProblemReport = useCallback(async (form: ApiSchemaReportProblem) => {
    const reportUrl = PlatformApi.ErrorSubmit.Root()
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post<ApiResponse<ApiSchemaReportProblem>>(reportUrl, form);

      if ((res.status = AppConstants.Api.StatusOK)) {
        if (res.status === AppConstants.Api.StatusOK) {
          toast.success("Submitted successfully");
          return Promise.resolve(res.data.data);
        }
      }
    } catch (e) {
      btnLoading.stop?.();
      return Promise.resolve(null);
    }
  }, []);
  return {
    SendProblemReport,
  };
}
