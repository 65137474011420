import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { AppConstants, PaginationSettings } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ModalConfirmDelete, ModalAppointmentDescription, ColumnToolTip, } from "../../features";
import { AppointmentDescriptionResponseModel, } from "../../models/api/modality";
import { useFunctionalityPagedAppointmentDescription, useFunctionalityAppointmentDescription, } from "../hooks";

export function PageAppointmentDescription(): JSX.Element {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [dataToEdit, setDataToEdit] = React.useState<AppointmentDescriptionResponseModel | undefined>(undefined);
  const [dataToDelete, setDataToDelete] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const data = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole,
    companyId: state.auth.profile?.companyId
  }));

  const { onDeleteAppointmentDescription } =
    useFunctionalityAppointmentDescription();
  const {
    pagedAppointmentDescriptionList,
    pagedAppointmentDescriptionListCount,
  } = useFunctionalityPagedAppointmentDescription({
    CompanyId: data.userRole === AppConstants.UserRoles.ResellerAdmin ? data.companyId : "",
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
  });

  useEffect(() => {
    if (pagedAppointmentDescriptionListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedAppointmentDescriptionList]);

  const onDeleteHandler = () => {
    onDeleteAppointmentDescription(dataToDelete)
      .then(() => {
        setDeleteModalShow(false);
      })
      .catch((e) => console.error(e));
  };

  const appointmentDescriptionTableColumns = [
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: AppointmentDescriptionResponseModel) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    // {
    //   name: "Pin",
    //   selector: (row: AppointmentDescriptionResponseModel) => row.pin,
    // },
    {
      name: <ColumnToolTip data="Appointment Type" />,
      selector: (row: AppointmentDescriptionResponseModel) => {
        return <ColumnToolTip data={row.appointmentCategoryName} />;
      },
    },

    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: AppointmentDescriptionResponseModel) => {
        return (
          <>
            <div
              className="btn svg-color"
              title="appointmentDescriptionEdit"
              onClick={() => {
                setDataToEdit(row);
                setModalShow?.(true);
              }}
            >
              <span >
                <AssetSvg.Edit />
              </span>
            </div>

            <div
              className="btn svg-color"
              title="appointmentDescriptionDelete"
              onClick={() => {
                setDeleteModalShow?.(true);
                setDataToDelete((prev) => [...prev, row.id]);
              }}
            >
              <span >
                <AssetSvg.Delete />
              </span>
            </div>
          </>
        );
      },
      omit: data.userRole === AppConstants.UserRoles.Admin ? false : true,
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Appointment Description"
        modalControlBtnLabel="Add Appointment Description"
        tableHeaders={appointmentDescriptionTableColumns}
        tableData={pagedAppointmentDescriptionList || []}
        setCreateModalShow={setModalShow}
        selectableRows={true}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedAppointmentDescriptionListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
      {modalShow ? (
        <ModalAppointmentDescription
          show={modalShow}
          handleShow={setModalShow}
          dataToEdit={dataToEdit}
          setDataToEdit={setDataToEdit}
        />
      ) : null}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
    </>
  );
}
