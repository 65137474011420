import { InfoBoxTitle } from "../../configs/appConstants";

type Props = {
  data?: any[];
};

export function InfoBox(props: Props): JSX.Element {
  const backGround = (type: string) => {
    let bg = "finance-log-box ";
    if (type == InfoBoxTitle.Confirmed) bg += "total-call-box";
    else if (type == InfoBoxTitle.Closed) bg += "Total-Income";
    else if (type == InfoBoxTitle.Offered) bg += "Total-operator";
    else if (type == InfoBoxTitle.Unfulfilled) bg += "Total-conection";
    else if (type == InfoBoxTitle.Paid) bg += "total-call-box";
    else if (type == InfoBoxTitle.Due) bg += "Total-operator";
    else if (type == InfoBoxTitle.Total) bg += "Total-technology";
    else if (type == InfoBoxTitle.TotalOnline) bg += "total-call-box";
    else if (type == InfoBoxTitle.TotalIncall) bg += "Total-operator";
    else if (type == InfoBoxTitle.TotalOperators) bg += "Total-technology";
    else if (type == InfoBoxTitle.Open) bg += "total-call-box";
    else if (type == InfoBoxTitle.Cancelled) bg += "cancelled-box";
    else if (type == InfoBoxTitle.Billed) bg += "billed-box";
    else if (type == InfoBoxTitle.NoShow) bg += "noShow-box";
    else bg += "Total-technology";
    return bg;
  };
  const getIcon = (type: string) => {
    if (type == InfoBoxTitle.Confirmed) return returnConfirm();
    if (type == InfoBoxTitle.Closed) return returnClosed();
    if (type == InfoBoxTitle.Offered) return returnOffered();
    if (type == InfoBoxTitle.Offered) return returnOffered();
    if (type == InfoBoxTitle.Unfulfilled) return returnUnfullfilled();
    if (type == InfoBoxTitle.Paid) return returnPaid();
    if (type == InfoBoxTitle.Due) return returnDue();
    if (type == InfoBoxTitle.Total) return returnTotal();

    if (type == InfoBoxTitle.TotalOnline) return returnTotalOnline();
    if (type == InfoBoxTitle.TotalIncall) return returnTotalIncall();
    if (type == InfoBoxTitle.TotalOperators) return returnTotalOperator();
    if (type == InfoBoxTitle.Open) return returnOpen();
    if (type == InfoBoxTitle.Cancelled) return returnCancelled();
    if (type == InfoBoxTitle.NoShow) return returnNoShow();
    if (type == InfoBoxTitle.Billed) return returnBilled();
    else return returnDefault();
  };
  const returnConfirm = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.94 24.7">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <path fill="#7860a8" d="M22.24,12.85a6.6,6.6,0,0,0-5.61-1.39,6.69,6.69,0,0,0-.55,13c.45.13.92.19,1.38.29h1.11a12.54,12.54,0,0,0,1.5-.34,6.58,6.58,0,0,0,4.59-5.63,1.24,1.24,0,0,1,0-.16V17.46c0-.19-.07-.38-.1-.57a5.13,5.13,0,0,0-.21-.88L23.1,17.3a4.37,4.37,0,0,1,.06.72,5.15,5.15,0,1,1-10.29,0,5.5,5.5,0,0,1,.08-.91A5.15,5.15,0,0,1,18,12.87,5.12,5.12,0,0,1,21.15,14Z" />
            <path fill="#7860a8" d="M19,7.73H1.54v8.85A1.3,1.3,0,0,0,3,18H8.65a.77.77,0,0,1,.86.84.75.75,0,0,1-.66.69H2.94A2.83,2.83,0,0,1,0,16.61V5A2.83,2.83,0,0,1,2.37,2.1a3.94,3.94,0,0,1,.6,0H4.11V1.19c0-.18,0-.36,0-.53A.76.76,0,0,1,5.65.75c0,.43,0,.85,0,1.29h9.26v-.9a3.53,3.53,0,0,1,0-.46.77.77,0,0,1,1.53.08c0,.42,0,.84,0,1.3h1.22a2.69,2.69,0,0,1,1.52.4,2.75,2.75,0,0,1,1.38,2.4c0,1.36,0,2.73,0,4.1a.78.78,0,0,1-.68.81.75.75,0,0,1-.82-.52,1.2,1.2,0,0,1,0-.38C19,8.5,19,8.12,19,7.73ZM14.92,3.62H5.65c0,.45,0,.87,0,1.29A.76.76,0,0,1,4.13,5a3,3,0,0,1,0-.43v-1c-.48,0-.93,0-1.38,0a1.15,1.15,0,0,0-1.12.92,12.49,12.49,0,0,0,0,1.62H19c0-.48,0-.94,0-1.39a1.11,1.11,0,0,0-.88-1.08,11.61,11.61,0,0,0-1.69-.11c0,.49,0,.92,0,1.34a.74.74,0,0,1-.79.73.77.77,0,0,1-.75-.81C14.91,4.44,14.92,4,14.92,3.62Z" />
            <path fill="#7860a8" d="M24.94,13.81V14a1,1,0,0,1-.33.5l-3.36,3.36-.95.95L18.12,21a.47.47,0,0,1-.47.15.55.55,0,0,1-.21-.12L15,18.55a.67.67,0,0,1,0-1l.29-.29a.67.67,0,0,1,1,0l1.48,1.49.07.1.08-.11,1.72-1.72c.27-.27.55-.54.81-.81l3.07-3.07a.67.67,0,0,1,1,0l.14.14A1,1,0,0,1,24.94,13.81Z" /></g></g></svg>
      </>
    );
  };
  const returnClosed = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <path fill="#7860a8" d="M17.46,24.7c-.46-.09-.93-.16-1.38-.29a6.69,6.69,0,1,1,8.53-7.51c0,.19.06.38.09.57v1.11c0,.05,0,.1,0,.15a6.58,6.58,0,0,1-4.59,5.64,12.51,12.51,0,0,1-1.5.33ZM12.87,18A5.15,5.15,0,1,0,18,12.87,5.15,5.15,0,0,0,12.87,18Z" />
            <path fill="#7860a8" d="M19,7.73H1.54v8.85A1.3,1.3,0,0,0,3,18H8.65a.77.77,0,0,1,.86.84.75.75,0,0,1-.66.69H2.94A2.83,2.83,0,0,1,0,16.61V5A2.83,2.83,0,0,1,2.37,2.1a3.94,3.94,0,0,1,.6,0H4.11V1.19c0-.18,0-.36,0-.53A.76.76,0,0,1,5.65.75c0,.43,0,.85,0,1.29h9.26v-.9a3.53,3.53,0,0,1,0-.46.77.77,0,0,1,1.53.08c0,.42,0,.84,0,1.3h1.22a2.69,2.69,0,0,1,1.52.4,2.75,2.75,0,0,1,1.38,2.4c0,1.36,0,2.73,0,4.1a.78.78,0,0,1-.68.81.75.75,0,0,1-.82-.52,1.2,1.2,0,0,1,0-.38C19,8.5,19,8.12,19,7.73ZM14.92,3.62H5.65c0,.45,0,.87,0,1.29A.76.76,0,0,1,4.13,5a3,3,0,0,1,0-.43v-1c-.48,0-.93,0-1.38,0a1.15,1.15,0,0,0-1.12.92,12.49,12.49,0,0,0,0,1.62H19c0-.48,0-.94,0-1.39a1.11,1.11,0,0,0-.88-1.08,11.61,11.61,0,0,0-1.69-.11c0,.49,0,.92,0,1.34a.74.74,0,0,1-.79.73.77.77,0,0,1-.75-.81C14.91,4.44,14.92,4,14.92,3.62Z" />
            <path fill="#7860a8" d="M18,16.92l1.43-1.44a.74.74,0,0,1,.82-.19.73.73,0,0,1,.51.62.79.79,0,0,1-.26.69L19.1,18l1.42,1.42a.79.79,0,0,1,.26.67.78.78,0,0,1-1.32.45c-.42-.41-.83-.83-1.24-1.24-.07-.07-.12-.14-.19-.22-.49.48-1,.95-1.42,1.42a.79.79,0,0,1-.69.27.74.74,0,0,1-.64-.52.75.75,0,0,1,.19-.8l1.25-1.24A1.86,1.86,0,0,1,17,18l-.74-.73-.74-.74a.77.77,0,1,1,1.1-1.08l1.25,1.24Z" /></g></g></svg>
      </>
    );
  };
  const returnOffered = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.11 21.88">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <path fill="#7860a8" d="M0,9.12H3.79A2.88,2.88,0,0,0,7.06,11l-.55.82-.86,1.29a.91.91,0,1,0,1.52,1c.58-.85,1.15-1.71,1.72-2.57l.2-.29V21.88H3A2.75,2.75,0,0,1,0,19.62a.21.21,0,0,0,0-.11Z" />
            <path fill="#7860a8" d="M0,2.37l.12-.43A2.71,2.71,0,0,1,2.62,0C4.74,0,6.85,0,9,0L9.1,0V6.48c-.25-.16-.49-.31-.71-.47a3,3,0,0,0-1.85-.66A2.79,2.79,0,0,0,3.91,7a2.29,2.29,0,0,1-.1.23l0,0H0Z" />
            <path fill="#7860a8" d="M13,11c1.48.24,2.46-.33,3.26-1.87H31.11V19a2.75,2.75,0,0,1-2.91,2.91H11V11.28c.06.09.12.15.16.22l1.77,2.65a.89.89,0,0,0,.94.42.8.8,0,0,0,.71-.7,1.26,1.26,0,0,0-.17-.76c-.37-.63-.79-1.22-1.2-1.83Zm9.85,3.58h3.64A.93.93,0,0,0,27.4,14a.92.92,0,0,0-.93-1.25H19.28a.93.93,0,0,0-1.05.92c0,.55.42.9,1.07.9Zm0,3.65h3.61a.92.92,0,1,0,0-1.82H19.24a1.51,1.51,0,0,0-.3,0,.91.91,0,0,0,.21,1.8Z" />
            <path fill="#7860a8" d="M31.11,7.29H16.54A.39.39,0,0,1,16.12,7,2.82,2.82,0,0,0,12,5.79l-1,.69V0H28.22a2.74,2.74,0,0,1,2.89,2.9Z" />
            <path fill="#7860a8" d="M8.39,8.21c-.51.33-1,.64-1.42.91A1,1,0,0,1,5.7,8.85a1,1,0,0,1,0-1.31A1,1,0,0,1,7,7.3C7.45,7.57,7.89,7.88,8.39,8.21Z" />
            <path fill="#7860a8" d="M11.67,8.2c.49-.32.93-.63,1.39-.9a1,1,0,0,1,1.28.25,1,1,0,0,1,0,1.31,1,1,0,0,1-1.29.26L12.88,9Z" />
          </g>
          </g>
        </svg>
      </>
    );
  };
  const returnPaid = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.88 27.37">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <path fill="#7860a8" d="M28.24,11a2.59,2.59,0,0,0-2.58.68l-3,3-.26.25a2.56,2.56,0,0,0-2.37-1c-1.37,0-2.75,0-4.13,0a12.32,12.32,0,0,1-2.58-.33,6.47,6.47,0,0,0-6.59,1.88c-.3.31-.56.66-.85,1l-.11-.05a2.09,2.09,0,0,0-2.64.41l-2.87,3a.6.6,0,0,0,0,1l.86.86c.49.48,5,4.72,5.48,5.19l.07.05.35.33a.55.55,0,0,0,.85,0c1-1,2-2.07,3-3.12a2,2,0,0,0,.45-1.86c0-.09,0-.17,0-.31.89-.06,1.74-.15,2.6-.16,2.62,0,5.25,0,7.87-.08a3.53,3.53,0,0,0,2.65-1.25c1.66-1.92,3.32-3.85,4.94-5.81A2.22,2.22,0,0,0,28.24,11ZM9.92,23.41c-.42.44-.84.88-1.28,1.31s-.82.78-1.25,1.19c-.18-.17-.38-.37-.6-.56l-.06,0C5.5,24.15,3.08,21.89,3,21.77L1.45,20.19c.87-.89,1.75-1.82,2.65-2.71a.87.87,0,0,1,1.28.08C6.9,19,8.41,20.49,9.91,22A.93.93,0,0,1,9.92,23.41Zm18.5-9.5c-.48.6-1,1.17-1.49,1.76-1,1.18-2,2.35-3,3.56a3.21,3.21,0,0,1-2.6,1.29l-2.8,0c-1.91,0-3.81,0-5.72.05-.69,0-1.38.15-2.07.22a.46.46,0,0,1-.31-.07l-3.64-3.5a5.43,5.43,0,0,1,6-2.58,11.61,11.61,0,0,0,3.26.4h4.29a1.15,1.15,0,0,1,.79,2.06,1.35,1.35,0,0,1-.91.26H16.62a1.58,1.58,0,0,0-.38,0,.55.55,0,0,0-.43.59.56.56,0,0,0,.48.53,1.34,1.34,0,0,0,.28,0c1.24,0,2.47,0,3.7,0a2.32,2.32,0,0,0,2.49-2.14.66.66,0,0,1,.18-.34c1.17-1.17,2.36-2.33,3.53-3.51a1.5,1.5,0,0,1,1.37-.41A1.06,1.06,0,0,1,28.42,13.91Z" /><path fill="#7860a8" d="M23.31,6.2A6.19,6.19,0,1,1,17.13,0,6.17,6.17,0,0,1,23.31,6.2Zm-6.19,5a5,5,0,1,0-5-5A5,5,0,0,0,17.12,11.21Z" /><path fill="#7860a8" d="M18.9,4.44c0,.5-.2.76-.55.78a.58.58,0,0,1-.61-.57c0-.39-.17-.57-.49-.64a.9.9,0,0,0-.88.41.62.62,0,0,0,.34,1,2.66,2.66,0,0,0,.49.1,2.24,2.24,0,0,1,1.93,1.68,1.9,1.9,0,0,1-1.24,2.21.57.57,0,0,0-.36.32A.82.82,0,0,1,17,10a.51.51,0,0,1-.54-.33.69.69,0,0,0-.28-.28,2.18,2.18,0,0,1-1.21-2,.56.56,0,0,1,.54-.57.56.56,0,0,1,.61.52l0,.22a.89.89,0,0,0,.56.79,1.11,1.11,0,0,0,1-.24.72.72,0,0,0,.1-1A1.26,1.26,0,0,0,17,6.64a3.56,3.56,0,0,1-.81-.2A1.65,1.65,0,0,1,15.1,4.87a1.86,1.86,0,0,1,1.07-1.79c.15-.08.26-.14.3-.34a.49.49,0,0,1,.5-.39.6.6,0,0,1,.58.33.89.89,0,0,0,.31.3A1.58,1.58,0,0,1,18.9,4.44Z" /></g></g></svg>
      </>
    );
  };
  const returnDue = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.78 20.03">

          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <circle fill="#7860a8" cx="25.27" cy="15.51" r="4.52" />
            <path fill="#7860a8" d="M27.83,3.52V8.81a7.25,7.25,0,0,0-2.54-.46,7.17,7.17,0,0,0-7.15,7.18,6.3,6.3,0,0,0,.15,1.39H3.54A3.52,3.52,0,0,1,0,13.4V3.52A3.54,3.54,0,0,1,3.54,0H24.29A3.54,3.54,0,0,1,27.83,3.52Z" />
            <path fill="#efefef" d="M11,6.14c0,.66-.28,1-.74,1a.75.75,0,0,1-.8-.75c0-.51-.23-.75-.66-.85a1.18,1.18,0,0,0-1.16.55A.81.81,0,0,0,8,7.38a2.82,2.82,0,0,0,.64.14,3,3,0,0,1,2.57,2.23,2.53,2.53,0,0,1-1.64,2.93.84.84,0,0,0-.49.42,1,1,0,0,1-.67.44c-.31.08-.55-.14-.72-.43a.88.88,0,0,0-.37-.37,2.9,2.9,0,0,1-1.6-2.68.74.74,0,0,1,.71-.76.75.75,0,0,1,.82.69l0,.29a1.17,1.17,0,0,0,.75,1.05A1.45,1.45,0,0,0,9.42,11a1,1,0,0,0,.13-1.34,1.65,1.65,0,0,0-1.08-.61A4.2,4.2,0,0,1,7.4,8.79,2.18,2.18,0,0,1,5.9,6.72,2.46,2.46,0,0,1,7.32,4.34a.59.59,0,0,0,.4-.45.66.66,0,0,1,.66-.53.79.79,0,0,1,.77.45,1.27,1.27,0,0,0,.41.39A2.12,2.12,0,0,1,11,6.14Z" />
            <path fill="#efefef" d="M25.87,16.26H24.66l-.18-3.47h1.58Zm-1.38,1.28a.69.69,0,0,1,.2-.52,1,1,0,0,1,1.14,0,.68.68,0,0,1,.19.52.63.63,0,0,1-.2.51.92.92,0,0,1-1.12,0A.64.64,0,0,1,24.49,17.54Z" /></g></g></svg>
      </>
    );
  };
  const returnTotal = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">

          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <circle fill="#7860a8" cx="13.5" cy="13.5" r="13.5" />
            <path fill="#fff" d="M12.8,16.6V13.54a4,4,0,0,1-2.1-1.24,3.12,3.12,0,0,1-.67-2,2.85,2.85,0,0,1,.76-2,3,3,0,0,1,2-.94V6.62h1.06v.72a3.18,3.18,0,0,1,1.85.79,3.07,3.07,0,0,1,.87,1.75l-1.84.24A1.53,1.53,0,0,0,13.86,9v2.86A4.8,4.8,0,0,1,16.25,13,2.92,2.92,0,0,1,16.88,15a3.23,3.23,0,0,1-.8,2.23,3.53,3.53,0,0,1-2.22,1.11v1.36H12.8V18.35a3.33,3.33,0,0,1-2.05-.94,3.89,3.89,0,0,1-1-2.23l1.91-.2a2.42,2.42,0,0,0,.43,1A2.14,2.14,0,0,0,12.8,16.6Zm0-7.67a1.41,1.41,0,0,0-.69.5,1.28,1.28,0,0,0,0,1.49,1.68,1.68,0,0,0,.71.54Zm1.06,7.77a1.52,1.52,0,0,0,.89-.51,1.4,1.4,0,0,0,.34-1,1.29,1.29,0,0,0-.28-.84,1.89,1.89,0,0,0-1-.55Z" /></g></g></svg>
      </>
    );
  };
  const returnOpen = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" className="icon-resize-lg">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <circle fill="#7860a8" cx="13" cy="13" r="13" />
            <path fill="#fff" d="M6.74,8.7a2.24,2.24,0,0,1,1.32-2,2.18,2.18,0,0,1,.86-.28,1.11,1.11,0,0,1,.87.28,6.05,6.05,0,0,1,1.09,1.23,11.47,11.47,0,0,1,.78,1.3,1.1,1.1,0,0,1-.39,1.46l-.24.18a.85.85,0,0,0-.32,1.09,4.79,4.79,0,0,0,.92,1.51,5.9,5.9,0,0,0,2,1.45.8.8,0,0,0,.76.06,1.49,1.49,0,0,0,.37-.38,1.78,1.78,0,0,1,.68-.57,1.21,1.21,0,0,1,1.11,0,10,10,0,0,1,1.07.67q.58.42,1.14.87a1.2,1.2,0,0,1,.37,1.56A3.11,3.11,0,0,1,17,18.91a1.78,1.78,0,0,1-1.18-.17,17.46,17.46,0,0,1-4.13-2.47,15.22,15.22,0,0,1-2.86-3A17.17,17.17,0,0,1,7,9.83,3,3,0,0,1,6.74,8.7Z" /></g></g></svg>
      </>
    );
  };
  const returnCancelled = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.7 24.7">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_8" data-name="Layer 8"><path fill="#7860a8" d="M22.08,21.15a5,5,0,0,1-1.2,1.13l.85.85L22.9,22Z" />
              <path fill="#7860a8" d="M24.61,16.9a6.69,6.69,0,1,0-8.53,7.51c.45.13.92.2,1.39.29h1.11a12.79,12.79,0,0,0,1.49-.33,6.58,6.58,0,0,0,4.59-5.63c0-.06,0-.11,0-.16V17.47ZM22.9,22l-1.17,1.17-.85-.85a5.14,5.14,0,0,1-7.14-7.13l7.14,7.13a5,5,0,0,0,1.2-1.13L14.88,14A5.09,5.09,0,0,1,18,12.87a5.13,5.13,0,0,1,4,8.28Z" />
              <path fill="#7860a8" d="M19,7.73H1.54v8.85A1.3,1.3,0,0,0,3,18H8.65a.77.77,0,0,1,.86.84.75.75,0,0,1-.66.69H2.94A2.83,2.83,0,0,1,0,16.61V5A2.83,2.83,0,0,1,2.37,2.1a3.94,3.94,0,0,1,.6,0H4.11V1.19c0-.18,0-.36,0-.53A.76.76,0,0,1,5.65.75c0,.43,0,.85,0,1.29h9.26v-.9a3.53,3.53,0,0,1,0-.46.77.77,0,0,1,1.53.08c0,.42,0,.84,0,1.3h1.22a2.69,2.69,0,0,1,1.52.4,2.75,2.75,0,0,1,1.38,2.4c0,1.36,0,2.73,0,4.1a.78.78,0,0,1-.68.81.75.75,0,0,1-.82-.52,1.2,1.2,0,0,1,0-.38C19,8.5,19,8.12,19,7.73ZM14.92,3.62H5.65c0,.45,0,.87,0,1.29A.76.76,0,0,1,4.13,5a3,3,0,0,1,0-.43v-1c-.48,0-.93,0-1.38,0a1.15,1.15,0,0,0-1.12.92,12.49,12.49,0,0,0,0,1.62H19c0-.48,0-.94,0-1.39a1.11,1.11,0,0,0-.88-1.08,11.61,11.61,0,0,0-1.69-.11c0,.49,0,.92,0,1.34a.74.74,0,0,1-.79.73.77.77,0,0,1-.75-.81C14.91,4.44,14.92,4,14.92,3.62Z" />
              <path fill="#7860a8" d="M22.9,22l-1.17,1.17-.85-.85a5,5,0,0,0,1.2-1.13Z" />
            </g>
          </g>
        </svg>
      </>
    );
  };
  const returnBilled = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.99 23.37" className="icon-resize-sm">

          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_8" data-name="Layer 8">
              <path fill="#7860a8" d="M2.16,23.37a6.47,6.47,0,0,1-.77-.3A2.49,2.49,0,0,1,0,20.88c0-.44,0-.88,0-1.32V2.7A2.55,2.55,0,0,1,2.21,0a4.35,4.35,0,0,1,.51,0h12a1,1,0,0,1,.79.33c2,2,4.07,4.08,6.12,6.11a1,1,0,0,1,.31.77V20.64a2.56,2.56,0,0,1-2.08,2.68l-.08.05ZM14.33,1.29H14.1c-4.15,0-7.29,0-11.44,0A1.29,1.29,0,0,0,1.28,2.73V20.64a1.31,1.31,0,0,0,1.46,1.45H19.25a1.31,1.31,0,0,0,1.47-1.48V7.67H16.9a2.54,2.54,0,0,1-2.57-2.58c0-1.07,0-2.14,0-3.2Zm1.27,1c0,.94,0,1.85,0,2.77A1.29,1.29,0,0,0,17,6.39c.85,0,1.71,0,2.57,0l.16,0Z" />
              <rect fill="#7860a8" x="9.62" y="10.22" width="9.07" height="1.28" rx="0.39" />
              <rect fill="#7860a8" x="3.3" y="14.05" width="15.39" height="1.28" rx="0.39" />
              <rect fill="#7860a8" x="3.3" y="17.87" width="15.39" height="1.28" rx="0.39" />
              <path fill="#7860a8" d="M5.07,9.55V8A2.14,2.14,0,0,1,4,7.3a1.64,1.64,0,0,1-.35-1,1.45,1.45,0,0,1,.4-1,1.59,1.59,0,0,1,1.05-.5V4.33h.55V4.7a1.64,1.64,0,0,1,1,.42A1.62,1.62,0,0,1,7.05,6l-1,.13a.78.78,0,0,0-.46-.61V7a2.45,2.45,0,0,1,1.25.65,1.52,1.52,0,0,1,.33,1,1.66,1.66,0,0,1-.41,1.16,1.82,1.82,0,0,1-1.17.58v.72H5.07v-.7A1.7,1.7,0,0,1,4,10a2,2,0,0,1-.53-1.16l1-.11a1.21,1.21,0,0,0,.23.53A1.11,1.11,0,0,0,5.07,9.55Zm0-4a.68.68,0,0,0-.36.26.64.64,0,0,0-.13.4.66.66,0,0,0,.12.38.84.84,0,0,0,.37.28ZM5.62,9.6a.81.81,0,0,0,.47-.26.77.77,0,0,0,.18-.5.67.67,0,0,0-.15-.44,1,1,0,0,0-.5-.29Z" />
            </g>
          </g>
        </svg>
      </>
    );
  };
  const returnNoShow = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">

          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_8" data-name="Layer 8">
              <circle fill="#7860a8" cx="13" cy="13" r="13" />
              <path fill="#efefef" d="M11.41,14.66V14a2.56,2.56,0,0,1,.31-1.29,3.4,3.4,0,0,1,1.11-1,4,4,0,0,0,.91-.71,1.09,1.09,0,0,0,.28-.74.58.58,0,0,0-.3-.51,1.37,1.37,0,0,0-.77-.2,6,6,0,0,0-2.75.84L9.11,8.23a8.09,8.09,0,0,1,4.06-1.11,4.36,4.36,0,0,1,2.73.76,2.5,2.5,0,0,1,1,2.09,3,3,0,0,1-.44,1.63A4.62,4.62,0,0,1,15,12.91a5.53,5.53,0,0,0-1,.77.93.93,0,0,0-.2.58v.4Zm-.33,2.73a1.47,1.47,0,0,1,.41-1.11,1.75,1.75,0,0,1,1.23-.39,1.66,1.66,0,0,1,1.2.4,1.43,1.43,0,0,1,.42,1.1,1.41,1.41,0,0,1-.44,1.1,1.68,1.68,0,0,1-1.18.39,1.78,1.78,0,0,1-1.21-.39A1.37,1.37,0,0,1,11.08,17.39Z" />
            </g>
          </g>
        </svg>
      </>
    );
  };
  const returnDefault = () => {
    return (
      <>
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <g clip-path="url(#clip0_125_22)">
            <path
              d="M60 30C60 35.9334 58.2405 41.7336 54.9441 46.6671C51.6477 51.6006 46.9623 55.4458 41.4805 57.7164C35.9987 59.987 29.9667 60.5811 24.1473 59.4236C18.3279 58.266 12.9824 55.4088 8.78681 51.2132C4.59123 47.0176 1.73401 41.6721 0.576455 35.8527C-0.581102 30.0333 0.0129986 24.0013 2.28363 18.5195C4.55426 13.0377 8.39943 8.35235 13.3329 5.05591C18.2664 1.75947 24.0666 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30Z"
              fill="#FFF9C4"
            />
            <path
              d="M26.1101 39.12C26.1041 36.5467 26.9039 34.0362 28.3975 31.9407C29.891 29.8452 32.0032 28.27 34.4376 27.4361C36.872 26.6022 39.5064 26.5515 41.9711 27.2911C44.4358 28.0307 46.607 29.5235 48.1801 31.56V19.42C48.1801 18.4325 47.7885 17.4852 47.0911 16.786C46.3938 16.0868 45.4476 15.6926 44.4601 15.69H15.5401C14.5525 15.6926 13.6064 16.0868 12.909 16.786C12.2117 17.4852 11.8201 18.4325 11.8201 19.42V37.42C11.8201 38.4075 12.2117 39.3548 12.909 40.054C13.6064 40.7532 14.5525 41.1474 15.5401 41.15H26.2901C26.1759 40.4893 26.1157 39.8204 26.1101 39.15"
              fill="#FBC02D"
            />
            <path
              d="M48 39.24C48.0377 41.1661 47.501 43.0599 46.4584 44.6798C45.4157 46.2998 43.9143 47.5726 42.1455 48.336C40.3767 49.0994 38.4207 49.3188 36.5267 48.9662C34.6328 48.6137 32.8867 47.7052 31.511 46.3565C30.1354 45.0078 29.1925 43.28 28.8026 41.3934C28.4127 39.5068 28.5933 37.5468 29.3216 35.7632C30.0498 33.9797 31.2927 32.4534 32.8917 31.3789C34.4907 30.3044 36.3735 29.7304 38.2999 29.73C39.5615 29.7168 40.8133 29.9524 41.9837 30.4233C43.1541 30.8942 44.2203 31.5912 45.1211 32.4745C46.022 33.3577 46.7399 34.4098 47.2339 35.5707C47.7279 36.7316 47.9882 37.9785 48 39.24Z"
              fill="#388E3C"
            />
            <path
              d="M36.3301 43.95L32.05 39.71L33.34 38.43L36.3301 41.4L43.2701 34.52L44.55 35.8L36.3301 43.95Z"
              fill="#FFFDE7"
            />
            <path
              d="M21.5999 31.22C21.6029 31.0018 21.5609 30.7853 21.4766 30.584C21.3923 30.3828 21.2675 30.201 21.1099 30.05C20.6405 29.6499 20.0918 29.3534 19.4999 29.18C18.634 28.9568 17.8347 28.528 17.1699 27.93C16.9016 27.6568 16.6929 27.3308 16.5569 26.9728C16.4209 26.6148 16.3606 26.2325 16.3799 25.85C16.3483 25.0997 16.606 24.3658 17.0999 23.8C17.6195 23.2303 18.3323 22.8739 19.0999 22.8V21.15H20.3099V22.85C20.6994 22.8879 21.0773 23.0039 21.421 23.1912C21.7646 23.3785 22.067 23.6332 22.3099 23.94C22.8075 24.6603 23.0503 25.5261 22.9999 26.4H21.4999C21.5231 25.7971 21.3475 25.2032 20.9999 24.71C20.8455 24.5024 20.6427 24.3355 20.4092 24.224C20.1757 24.1124 19.9185 24.0596 19.6599 24.07C19.4147 24.052 19.1684 24.0844 18.9362 24.1653C18.7041 24.2461 18.4909 24.3736 18.3099 24.54C18.1536 24.7148 18.0334 24.9188 17.9562 25.1401C17.879 25.3615 17.8463 25.596 17.8599 25.83C17.8488 26.051 17.884 26.2719 17.9633 26.4786C18.0425 26.6852 18.1639 26.873 18.3199 27.03C18.8109 27.4374 19.3839 27.7341 19.9999 27.9C20.8632 28.135 21.6606 28.5662 22.3299 29.16C22.83 29.7337 23.1055 30.469 23.1055 31.23C23.1055 31.9911 22.83 32.7264 22.3299 33.3C21.7361 33.8505 20.9684 34.1759 20.1599 34.22V35.69H18.9999V34.23C18.1883 34.1587 17.4274 33.8048 16.8499 33.23C16.5503 32.9164 16.3199 32.5434 16.1735 32.1352C16.027 31.727 15.9679 31.2925 15.9999 30.86H17.4499C17.4198 31.167 17.4579 31.4768 17.5616 31.7673C17.6654 32.0577 17.8321 32.3216 18.0499 32.54C18.4567 32.8768 18.972 33.0545 19.4999 33.04C20.0559 33.072 20.6053 32.9054 21.0499 32.57C21.2351 32.4078 21.3809 32.2055 21.4762 31.9785C21.5715 31.7515 21.6138 31.5058 21.5999 31.26"
              fill="#388E3C"
            />
          </g>
          <defs>
            <clipPath id="clip0_125_22">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </>
    );
  };

  const returnTotalOnline = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">

          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <circle fill="#7860a8" cx="13" cy="13" r="13" />
            <path fill="#efefef" d="M13,13.25A3.21,3.21,0,1,0,9.79,10,3.22,3.22,0,0,0,13,13.25Z" />
            <path fill="#efefef" d="M12.68,19.6l-.58-.07A6,6,0,0,1,10,18.88a6.55,6.55,0,0,1-2.48-2.31.39.39,0,0,1,0-.43A4,4,0,0,1,10.22,14c.1,0,.2,0,.3-.06A.62.62,0,0,1,11,14a3.4,3.4,0,0,0,2.12.61,2.92,2.92,0,0,0,1.63-.52A1.14,1.14,0,0,1,15.87,14a4.06,4.06,0,0,1,2.47,1.9c.24.41.25.41,0,.81a6.36,6.36,0,0,1-2.53,2.24,6.09,6.09,0,0,1-2.43.64l-.08,0Z" /></g></g></svg>
      </>
    );
  };
  const returnTotalIncall = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" >

          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <circle fill="#7860a8" cx="13" cy="13" r="13" />
            <path fill="#fff" d="M5,10.27a2.25,2.25,0,0,1,1.31-2A2.17,2.17,0,0,1,7.16,8,1.11,1.11,0,0,1,8,8.23,6.37,6.37,0,0,1,9.12,9.46a11.47,11.47,0,0,1,.78,1.3,1.1,1.1,0,0,1-.39,1.46l-.24.18A.85.85,0,0,0,9,13.49,4.79,4.79,0,0,0,9.86,15a5.83,5.83,0,0,0,2,1.46.8.8,0,0,0,.76.06,1.67,1.67,0,0,0,.37-.38,1.75,1.75,0,0,1,.67-.57,1.23,1.23,0,0,1,1.12,0,9,9,0,0,1,1.06.67c.4.27.77.57,1.14.87a1.19,1.19,0,0,1,.38,1.56,3.07,3.07,0,0,1-2.07,1.77,1.72,1.72,0,0,1-1.18-.16A17.76,17.76,0,0,1,10,17.83a15.69,15.69,0,0,1-2.85-3A17,17,0,0,1,5.23,11.4,3,3,0,0,1,5,10.27Z" />
            <path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" d="M20.15,13.32a6.5,6.5,0,0,0,.26-1.27,6.36,6.36,0,0,0-5.73-6.92,7.62,7.62,0,0,0-1.17,0,6.43,6.43,0,0,0-2,.5" />
            <path fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" d="M17.57,12.72a3.55,3.55,0,0,0,.12-.65,4,4,0,0,0-3.6-4.35,3.62,3.62,0,0,0-.57,0,4.34,4.34,0,0,0-1.4.32" /></g></g></svg>
      </>
    );
  };
  const returnTotalOperator = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.79 27.04" className="icon-resize-sm">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_8" data-name="Layer 8">
              <path fill="#7860a8" d="M23.78,13.49a2.28,2.28,0,0,0-1.35-2.1.38.38,0,0,1-.24-.4,10.29,10.29,0,0,0-.74-4.49A10.08,10.08,0,0,0,12.4,0,9.73,9.73,0,0,0,5,2.71,10,10,0,0,0,1.65,9.48c0,.6,0,1.2,0,1.77A2.38,2.38,0,0,0,0,13.43c0,1.11,0,2.22,0,3.33a2.27,2.27,0,0,0,1,1.83,2.37,2.37,0,0,0,1.45.47c.78,0,1.55,0,2.35,0H6.34V11.13H3.21A8.77,8.77,0,0,1,7.46,2.81a8.57,8.57,0,0,1,9.63.51,8.82,8.82,0,0,1,3.52,7.81H17.46v7.93h3.15v2.32a2.38,2.38,0,0,1-2.49,2.49c-1.23,0-2.45,0-3.67,0a.39.39,0,0,1-.43-.27,2.36,2.36,0,0,0-2.34-1.32A2.41,2.41,0,0,0,9.61,24a2.27,2.27,0,0,0,.88,2.52,5.66,5.66,0,0,0,1.09.49h.69l.14-.05a2.36,2.36,0,0,0,1.64-1.34.3.3,0,0,1,.33-.2c1.28,0,2.56,0,3.83,0a4,4,0,0,0,4-4c0-.77,0-1.53,0-2.3a.3.3,0,0,1,.19-.32,2.29,2.29,0,0,0,1.4-2.15C23.8,15.62,23.8,14.56,23.78,13.49Z" />
            </g>
          </g>
        </svg>
      </>
    );
  };
  const returnUnfullfilled = () => {
    return (
      <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.79 20.03">
          <g id="Layer_2" data-name="Layer 2"><g id="Layer_8" data-name="Layer 8">
            <path fill="#7860a8" d="M25.27,11a4.52,4.52,0,1,0,4.52,4.52A4.51,4.51,0,0,0,25.27,11Zm2.37,5.41H22.89a.42.42,0,0,1-.42-.42v-.94a.42.42,0,0,1,.42-.43h4.75a.43.43,0,0,1,.43.43V16A.42.42,0,0,1,27.64,16.41Z" />
            <path fill="#7860a8" d="M27.83,3.52V8.81a7.25,7.25,0,0,0-2.54-.46,7.17,7.17,0,0,0-7.15,7.18,6.3,6.3,0,0,0,.15,1.39H3.54A3.52,3.52,0,0,1,0,13.4V3.52A3.54,3.54,0,0,1,3.54,0H24.29A3.54,3.54,0,0,1,27.83,3.52Z" />
            <path fill="#efefef" d="M11,6.14c0,.66-.28,1-.74,1a.75.75,0,0,1-.8-.75c0-.51-.23-.75-.66-.85a1.18,1.18,0,0,0-1.16.55A.81.81,0,0,0,8,7.38a2.82,2.82,0,0,0,.64.14,3,3,0,0,1,2.57,2.23,2.53,2.53,0,0,1-1.64,2.93.84.84,0,0,0-.49.42,1,1,0,0,1-.67.44c-.31.08-.55-.14-.72-.43a.88.88,0,0,0-.37-.37,2.9,2.9,0,0,1-1.6-2.68.74.74,0,0,1,.71-.76.75.75,0,0,1,.82.69l0,.29a1.17,1.17,0,0,0,.75,1.05A1.45,1.45,0,0,0,9.42,11a1,1,0,0,0,.13-1.34,1.65,1.65,0,0,0-1.08-.61A4.2,4.2,0,0,1,7.4,8.79,2.18,2.18,0,0,1,5.9,6.72,2.46,2.46,0,0,1,7.32,4.34a.59.59,0,0,0,.4-.45.66.66,0,0,1,.66-.53.79.79,0,0,1,.77.45,1.27,1.27,0,0,0,.41.39A2.12,2.12,0,0,1,11,6.14Z" />
          </g>
          </g>
        </svg>
      </>
    );
  };
  return (
    <>
      <div className="finance-acc-grid">
        {props.data
          ? props.data?.map((box) =>
            box ? (
              <div className="call-info-grid-child">
                <div className={backGround(box[0])}>
                  <div className="finance-log-image">{getIcon(box[0])}</div>
                  <div className="finance-box-info">
                    <p className="mb-1">{box[0]}</p>
                    {typeof box[1] === "string" && box[1].includes("(") ?
                      <h3 className="mb-0 counter-control text-danger">{box[1]}</h3>
                      :
                      <h3 className="mb-0 counter-control" title={`${box[1]}`}>{box[1]}</h3>
                    }
                    <h2 className="mb-0 ">{box[2]}</h2>
                  </div>
                </div>
              </div>
            ) : null
          )
          : null}{" "}
      </div>
    </>
  );
}
