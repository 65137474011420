import { CalendarProvider } from "../../configs";

export enum genderType {
  Any = 0,
  Male = 1,
  Female,
}
export enum UserStatusType {
  Any = 0,
  Enabled = 1,
  Disabled,
  Pending,
}
export type ApiUserNames = {
  id: string;
  name: string;
  companyName: string;
};
export type ApiSchemaUserProfileData = {
  email?: string;
  id?: string;
  name?: string;
  companyId?: string;
  companyName?: string;
  userRole?: string;
};
export type ApiSchemaCalenderProvider = {
  calendarProvider: CalendarProvider,
  isConnected: boolean
};
export type ApiSchemaUserData = {
  id: string;
  name?: string;
  email?: string;
  departmentId?: string;
  departmentName?: string;
  locationId?: string;
  locationName?: string;
  userRole?: string;
  status: UserStatusType;
};

export type ApiSchemaUserDetailsData = {
  id?: string;
  name?: string;
  email?: string;
  profilePictureKey?: string;
  companyName?: string;
  companyId?: string;
  address?: string;
  displayAddress?: string;
  phoneNumber?: string;
  education?: string;
  gender?: string;
  timeZone?: string;
  notes?: string;
  firstName?: string;
  lastName?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  departmentId?: string;
  locationId?: string;
  alternativePhoneNumber?: string;
};

export type APISchemaCreateUser = {
  email: string;
  firstName: string;
  lastName: string;
  userRole: string;
  companyId: string;
};

export interface CreateCompanyUserForm extends APISchemaCreateUser {
  retypeEmail: string;
}
export type ApiSchemaUpdateUser = {
  id?: string;
  firstName?: string;
  lastName?: string;
  address?: string;
  city?: string;
  countryId?: string;
  stateId?: string;
  zipCode?: string;
  contactNumber?: string;
  alternativePhoneNumber?: string;
  education?: string;
  timeZone?: string;
  notes?: string;
  email?: string;
  name?: string;
  gender?: number;
  departmentId?: string;
  locationId?: string;
};
export type ApiSchemaChangeStatus = {
  userId?: string;
  status?: number;
};
export type ApiSchemaChangeRole = {
  userId?: string;
  role?: string;
};
export type ApiSchemaUpdatePassword = {
  userId?: string;
  password?: string;
  retypePassword?: string;
};

export type ApiSchemaAvailableManager = {
  id: string;
  name: string;
  companyName: string;
};
