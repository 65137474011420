import React from "react";
import { toast } from "react-toastify";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiSchemaJobVosFormById, EVosExportPayload } from "../../models";
import { UtilsJQuery } from "../../utils";

type Props = {
    id: string,
    setLoaderProgress?: any;
    exportPayload?: EVosExportPayload
}

export function useFunctionalityVosForm(props: Props) {
    const { id, setLoaderProgress } = props
    const onSaveVosForm = React.useCallback(async (form: ApiSchemaJobVosFormById) => {
        const btnLoading = UtilsJQuery.Ladda(".voss-form-submit-btn");

        btnLoading.start?.();
        var body = new FormData();
        body.append("vosFormFile", form.vosFormFile)
        body.append("actualStart", form.actualStart)
        body.append("actualEnd", form.actualEnd)
        body.append("mileage", form.mileage)

        try {
            setLoaderProgress(topLoaderProgress.start);
            const response = await AxiosAuth.put(PlatformApi.Booking.SubmitVos(id), body);

            if (response.status === AppConstants.Api.StatusOK) {
                setLoaderProgress(topLoaderProgress.complete);
                toast.success("Voss Form Submit Successfully");
            }
        } catch (e) {
            setLoaderProgress(topLoaderProgress.complete);
            toast.error("Voss Form Submit  Failed");
        }
        btnLoading.stop?.();
        return Promise.resolve(null);
    }, []);

    return {
        onSaveVosForm
    };
}
export async function eVosExportAsPdf(props: Props) {
    const {
        setLoaderProgress,
        exportPayload
    } = props;
    const btnLoading = UtilsJQuery.Ladda(".voss-form-download-btn");
    btnLoading.start?.();
    try {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.start);
        const response = await AxiosAuth.post(
            PlatformApi.Export.eVosExportAsPdf(), exportPayload);
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        btnLoading.stop?.();
        return response.data;
    } catch {
        setLoaderProgress && setLoaderProgress(topLoaderProgress.complete);
        toast.error("Failed to export!");
        return Promise.reject();
        btnLoading.stop?.();
    }
}