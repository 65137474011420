import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FileBucketURL, DefaultImageType, AppConstants } from "../../configs";
import { CallParticipateResponseModel } from "../../models/api/Call";
import { useFunctionalityImageView } from "../../pages/hooks/functionalityImageView";
type Props = {
  participants?: CallParticipateResponseModel[];
};

export function CallSummaryParticipantDetail(props: Props): JSX.Element {
  const { participants } = props;
  const { defaultImageLoad, imageView } = useFunctionalityImageView()
  return (
    <>
      <div className="card">
        <div className="card-header px-0 py-1" id="headingCallParticipant">
          <button
            className="btn btn-link btn-block text-left text-decoration-none text-site siteAccordion collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#call-participant"
            aria-expanded="true"
            aria-controls="call-participant"
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>Call Participants</div>
              <div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="closed-panel-icon"
                />
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="open-panel-icon"
                />
              </div>
            </div>
          </button>
        </div>
        <div
          className="collapse"
          id="call-participant"
          aria-labelledby="headingCallParticipant"
          data-parent="#call-summary-panels"
        >
          <div className="card-body px-3 py-1">
            <div className="row">
              {participants?.map((participant) => {
                return (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" key={participant.id}>
                    <div className="row">
                      <div className="pl-2 ml-1 py-2">
                        <div className="header-profile-image d-block d-sm-block d-md-block">
                          <img
                            src={`${FileBucketURL.Root}${participant.id}?${imageView.fileTimeStamp}`}
                            alt=""
                            onError={(ev) => { defaultImageLoad(ev, DefaultImageType.UserProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") }}
                            className="img-fluid"
                          />

                        </div>
                      </div>
                      <div className="col py-2 ">
                        <div className="row">
                          <div className="col-12 font-500 font-sz-15">{participant.name}</div>
                          {
                            participant.role !== AppConstants.UserRoles.Operator && (
                              <div className="col-12 font-sz-12">{participant.companyName}</div>
                            )
                          }
                          <div className="col-12  text-success font-sz-12">
                            {participant.isCaller ? "Caller" : "Agent"} {" "}
                          </div>
                          <div className="col-12 font-sz-12">

                            {
                              participant.startTime && participant.endTime ?
                                moment(
                                  new Date(participant.startTime ?? "")
                                ).format("HH:mm:ss ") +
                                " - " +
                                moment(
                                  new Date(participant.endTime ?? "")
                                ).format("HH:mm:ss ")
                                : ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
