import React from "react";
import { AppConstants, AppRouteUi } from "../../../configs";
import { ApiSchemaCompanyByIdData } from "../../../models";
import { PageCompany } from "../../../pages/admin/CompanyPage";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData
}
export function CompanyTab(props: Props) {
  const companyId = AppRouteUi.Reseller.Profile.useParam();
  return (
    <>
      <div className="row flex-wrap mb-3">
        <div className="col">
          <PageCompany parentCompanyId={companyId} parentCompanyName={props?.companyProfileData?.name} />
        </div>
      </div>
    </>
  );
}
