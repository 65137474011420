import React, { useEffect, useState } from "react";
import {
    MiscellaneousBillsDetails,
    MiscellaneousDocumentFileNames,
    MiscellaneousInfo
} from "../../../../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPaperclip, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FileBucketURL } from "../../../../configs";
import { useFunctionalityInputChecker } from "../../../../pages/hooks/functionalityInputChecker";

type Props = {
    miscellaneousData: any;
    handleRemoveMiscellaneousInfo: any;
    handleMiscellaneousDescription: any;
    handleMiscellaneousAmount: any;
    handleMiscellaneousFile: any;
    miscellaneousDocumentFilesName: any;
}

export function JobMiscellaneousDescription(props: Props): JSX.Element {

    const { miscellaneousData, handleRemoveMiscellaneousInfo, handleMiscellaneousDescription, handleMiscellaneousAmount, handleMiscellaneousFile, miscellaneousDocumentFilesName } = props;
    const { handleLimitDecimalPlaces } = useFunctionalityInputChecker()
    useEffect(() => {
    }, [miscellaneousDocumentFilesName, miscellaneousData]);

    const getFileName = (id: number) => {
        const file: MiscellaneousDocumentFileNames = miscellaneousDocumentFilesName?.find((file: MiscellaneousDocumentFileNames) => file?.documentId === id);
        return file?.documentFileName ?? '';
    }

    const onButtonClickHandler = (id: string) => {
        const input = document.getElementById(id);
        if (input) {
            input.click();
        }
    }

    return (
        <>
            {
                miscellaneousData?.map((data: MiscellaneousBillsDetails) =>
                    <>
                        <div key={data?.miscellaneousBillSerialNo} className="card mb-3 mr-2">
                            <div className="card-body border-radius-xl pt-0">
                                <div className="row justify-content-end">
                                    <button type="button" onClick={() => handleRemoveMiscellaneousInfo(data?.miscellaneousBillSerialNo)} className="btn btn-link text-site">
                                        <FontAwesomeIcon icon={faTrashAlt} className="" />
                                    </button>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="">Description</label>
                                        <input type="text" defaultValue={data?.description} onBlur={(event) => handleMiscellaneousDescription(event, data?.miscellaneousBillSerialNo)} className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="" className="required">Amount</label>
                                        <input type="number" min="0" defaultValue={data?.amount} onBlur={(event) => handleMiscellaneousAmount(event, data?.miscellaneousBillSerialNo)} onInput={(event) => handleLimitDecimalPlaces(event, 2)} className="form-control" />
                                    </div>
                                </div>
                                <div className="form-group row">

                                    <div className="col-12 px-0">
                                        <div className="col">
                                            <label htmlFor="" className="">Document</label>
                                            {
                                                data?.fileKey && <button className="btn px-3 py-0"
                                                    onClick={(event: any) => {
                                                        event.preventDefault();
                                                        window.open(`${FileBucketURL?.Root}${data?.fileKey}`, "_blank");
                                                    }} >
                                                    <FontAwesomeIcon
                                                        icon={faEye} />
                                                </button>
                                            }
                                        </div>

                                        <div className="input-group col" >
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="upload"
                                                aria-label="Upload file"
                                                name="button-upload-file"
                                                aria-describedby="button-upload-file"
                                                readOnly
                                                value={getFileName(data?.miscellaneousBillSerialNo)}
                                            />
                                            <input
                                                className="d-none"
                                                type="file"
                                                id={data?.miscellaneousBillSerialNo + ''}
                                                name={data?.miscellaneousBillSerialNo + ''}
                                                onChange={(event) => {
                                                    handleMiscellaneousFile(event, data?.miscellaneousBillSerialNo)
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" id="button-upload-file" onClick={(e) => {
                                                    e.preventDefault()
                                                    onButtonClickHandler(data?.miscellaneousBillSerialNo + '')
                                                }}>
                                                    <label className="mb-0" htmlFor={data?.miscellaneousBillSerialNo + ''}>
                                                        <FontAwesomeIcon icon={faPaperclip} className="" />
                                                    </label>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)
            }
        </>
    );
}