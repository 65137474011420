import React from "react";
import { BossContract, PlatformType } from "../../../../models";
import { BossContactPlatform } from "../../../../models/api/company";
import { AssetSvg } from "../../../../assets";

type Props = {
  handleShow: (value: boolean) => void;
  data: BossContract | undefined;
  editable: boolean;
};

export function CardBossSystem(props: Props): JSX.Element {
  const { handleShow, data, editable } = props;

  return (
    <div className="component-card mb-3">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
        <div>Boss System Fees</div>
        {
          editable &&
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase"
            onClick={() => handleShow(true)}
          >
            <div className="btn svg-color" title="contractBossEdit">
              <span>
                <AssetSvg.Edit />
              </span>
            </div>
          </button>
        }
      </div>

      {
        data && <div className="component-card-body border">
          <div className="row flex-wrap mb-2">
            <div className="col-12">
              <p className="mb-0 text-muted">Urgent job maximum time</p>
            </div>
            <div className="col-12">
              <div className="mb-0 d-flex flex-wrap align-items-center">
                <p className="currency-symbol text-dark font-500 mb-0">{`${data?.urgentJobMaximumTimeHour ? data?.urgentJobMaximumTimeHour : 0} hours 
                ${data?.urgentJobMaximumTimeMinute ? data?.urgentJobMaximumTimeMinute : 0} minutes`}</p>
              </div>
            </div>
          </div>

          <div className="row flex-wrap mb-2">
            <div className="col-12">
              <p className="mb-0 text-muted">Urgent job rate</p>
            </div>
            <div className="col-12">
              <div className="mb-0 d-flex flex-wrap align-items-center">
                <p className="currency-symbol text-dark font-500">{`$${data?.urgentJobRate ? data?.urgentJobRate.toFixed(2) : 0}`}</p>
              </div>
            </div>
          </div>

          {
            data?.bossContactPlatforms &&
            data?.bossContactPlatforms.map((bossContactPlatform: BossContactPlatform) => bossContactPlatform.isEnabled &&
              <>
                <div className="row flex-wrap mb-2">
                  <div className="col-12">
                    <p className="mb-0 text-muted">
                      {PlatformType[bossContactPlatform.platform]} minimum time
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="mb-0 d-flex flex-wrap align-items-center">
                      <div className="currency-symbol text-dark font-500">{`${bossContactPlatform?.minimumTimeHour ? bossContactPlatform?.minimumTimeHour : 0}
                       hours ${bossContactPlatform?.minimumTimeMinute ? bossContactPlatform?.minimumTimeMinute : 0} minutes`}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="row flex-wrap mb-2">
                  <div className="col-12">
                    <p className="mb-0 text-muted">
                      {PlatformType[bossContactPlatform.platform]} rate
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="mb-0 d-flex flex-wrap align-items-center">
                      <p className="currency-symbol text-dark font-500">{`$${bossContactPlatform.minimumRate ? bossContactPlatform.minimumRate.toFixed(2) : 0}`}</p>
                    </div>
                  </div>
                </div> */}
              </>
            )
          }
        </div>
      }
    </div>
  );
}
