import React, {useState} from 'react'
import { Modal } from 'react-bootstrap'
import IvrCustomInputMappingData from "./ivrCustomInputMappingData";
import {APIEnabledIvrCustomFields, IvrCustomField} from "../../../models";
import {useForm} from "react-hook-form";
import {useFunctionalityCompanyIvr} from "../../../pages/hooks";
type Props = {
    show: boolean,
    handleShow: (value: boolean) => void,
    header: string,
    ivrId: string,
    customFields:IvrCustomField[],
    onSaveHandler?: any
}
export function ModalIvrCustomInputMapping(props: Props) {
    const { customFields, ivrId } = props;
    const [ivrEnabledCustomValues, setIvrEnabledCustomValues] = useState<any>([]);
    const [operatorInput, setOperatorInput] = useState(2);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<APIEnabledIvrCustomFields>();

    const { onUpdateCustomField } = useFunctionalityCompanyIvr();

    const handleTotalEnabledCustomFields = (enabledCustomField: IvrCustomField) => {
        const isCustomFieldAlreadyPresent = ivrEnabledCustomValues.includes(enabledCustomField);

        if(!isCustomFieldAlreadyPresent) {
            setIvrEnabledCustomValues([
                ...ivrEnabledCustomValues,
                enabledCustomField
            ]);
        } else {
            const filteredEnabledCustomValues = ivrEnabledCustomValues.filter((customFields: IvrCustomField) => customFields?.id !== enabledCustomField?.id);

            setIvrEnabledCustomValues([
                ...filteredEnabledCustomValues,
            ]);
        }
    }

    const modalHideHandler = () => {
        props.handleShow(false);
    }

    const onUpdateEnabledIvrCustomFields = (form: APIEnabledIvrCustomFields) => {
        form = {
            ...form,
            ivrCustomFieldModels: ivrEnabledCustomValues
        }

        onUpdateCustomField(ivrId, form)
            ?.then(()=>{
                modalHideHandler();
            })
            .catch(err=>console.log(err));
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.handleShow(false)}
            centered
        >
            <form onSubmit={handleSubmit(onUpdateEnabledIvrCustomFields)} noValidate>
                        {/*@ts-ignore */}
        <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        customFields ? customFields.map((customField: IvrCustomField) => <IvrCustomInputMappingData key={customField.id}
                                                                                                                    customField={customField}
                                                                                                                    operatorInput={operatorInput}
                                                                                                                    handleTotalEnabledCustomFields={handleTotalEnabledCustomFields} />) : null
                    }
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-link text-site text-uppercase"
                        onClick={modalHideHandler}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary text-uppercase btn-message-submit"

                    >
                        Save
                    </button>

                </Modal.Footer>
            </form>
        </Modal>
    )
}
