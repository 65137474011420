import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ContractFeesUpdate} from "../../../models";

type TSliceCompanyContract = {
  isUpdateContractFees: ContractFeesUpdate;
};

function InitSliceCompanyContract(): TSliceCompanyContract {
  return {
    isUpdateContractFees: {
      isUpdateVriFees: false,
      isUpdateOpiFees: false,
      isUpdateOnsiteFees: false
    }
  };
}

const sliceCompanyContract = createSlice({
  name: "sliceCompanyContract",
  initialState: InitSliceCompanyContract(),
  reducers: {
    SetUpdateContractFees: (
        state,
        action: PayloadAction<ContractFeesUpdate>
    ): TSliceCompanyContract => ({
      ...state,
      isUpdateContractFees: action.payload
    }),
    resetData: ()=> {
      return InitSliceCompanyContract();
    }
  },
});

export const ReducerCompanyContract = sliceCompanyContract.reducer;
export const ActionCompanyContract = sliceCompanyContract.actions;
