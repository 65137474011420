import { useCallback, useEffect, useRef, useState } from "react";

export function useCountdown() {
  const [seconds, setSeconds] = useState<number | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const intervalIdRef = useRef<null | number>(null);

  const startCountdown = useCallback(
    (seconds: number) => {
      if (isRunning) {
        return;
      }
      intervalIdRef.current && clearInterval(intervalIdRef.current);
      setIsFinished(false);
      setIsRunning(true);
      setSeconds(seconds);
      intervalIdRef.current = window.setInterval(() => {
        setSeconds(--seconds);
      }, 1000);
    },
    [isRunning]
  );

  const endCountdown = useCallback(() => {
    setIsRunning(false);
    setSeconds(null);
    intervalIdRef.current && clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  }, []);

  useEffect(() => {
    if (seconds !== null && seconds <= 0 && isRunning) {
      setIsFinished(true);
      endCountdown();
    }
  }, [seconds, endCountdown, isRunning]);

  return {
    seconds,
    isRunning,
    isFinished,
    startCountdown,
    endCountdown,
  };
}
