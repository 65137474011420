import { AssetSvg } from '../../assets';

type TypeWaitingScreenProp = {
  handleHangup: Function;
};

export function WaitingScreen({ handleHangup }: TypeWaitingScreenProp) {
  return (
    <div className="site-call-page">
      <div className="site-call-page-wrapper">
        <div className="container-fluid h-100">
          <div className="row justify-content-center align-items-center h-100 Page-call overflow-auto">
            <div className="col-12 text-center d-flex align-items-center justify-content-center pt-3">
              <ul className="babelCallAnimation bg-white caller-image ivr-call">
                <AssetSvg.PhoneCall />
                <li />
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
            <h5 className="my-4 font-500 ivr-message p-2">Calling to OPI. Please wait...</h5>
            <div className="col-12 text-center p-3 text-white">
              <div className="key-row dial-button mt-2">
                <button
                  className="btn ripple"
                  onClick={() => {
                    handleHangup();
                  }}
                >
                  <AssetSvg.Disconnect />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
