import { ColumnSelectionsModel } from "../../models";

export const OperatorColumns: ColumnSelectionsModel[] = [
    { name: 'Vri', propertyName: 'vriAvailabilityStatusType', isEnabled: true },
    { name: 'Opi', propertyName: 'opiAvailabilityStatusType', isEnabled: true },
    { name: 'Operator', propertyName: 'name', isEnabled: true },
    { name: 'Email', propertyName: 'email', isEnabled: true },
    { name: 'Rating', propertyName: 'ratingScore', isEnabled: true },
    { name: 'Language', propertyName: 'operatorLanguages', isEnabled: true },
    { name: 'Skills', propertyName: 'operatorSkills', isEnabled: true },
    { name: 'Account', propertyName: 'userStatusType', isEnabled: true },
]
export const myAccountColumns = [
    { name: 'Invoice', propertyName: 'Invoice' },
    { name: 'Company', propertyName: 'CompanyName' },
    { name: 'From', propertyName: 'From' },
    { name: 'To', propertyName: 'To' },
    { name: 'Amount', propertyName: 'Amount' },
    { name: 'Status', propertyName: 'Status' }
]