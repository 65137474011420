import React, { useEffect, useState } from "react";
import { ModalEditUserInfo } from "../..";
import { AssetSvg } from "../../../assets";
import {
  ApiSchemaUpdateUser,
  ApiSchemaUserData,
  UserStatusType,
} from "../../../models";
import { ApiSchemaCompanyByIdData } from "../../../models/api/company";
import {
  useFunctionalityChangeCompanyContact,
  useFunctionalityCompanyContactDetails,
  useFunctionalityContactUsers,
  useFunctionalityDepartment,
  useFunctionalityImageView,
  useFunctionalityLocation,
  useFunctionalityState,
  useFunctionalityUser,
  useFunctionalityUser2,
  useFunctionalityUserGetDetailsById,
} from "../../../pages/hooks";
import { ModalSingleDropdown } from "../../modals";
import {
  AppRouteUi,
  DefaultImageType,
  FileBucketURL,
  PlatformApi,
  topLoaderProgress,
} from "../../../configs";
import { genders } from "../../modals/jobFormData";
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { OptionMapper } from "../../../utils";
import { NoDataComponent } from "../../admin/noDataComponent";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
};

export function ContactTab({ companyProfileData, editable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const resellerId = AppRouteUi.Reseller.Profile.useParam() ?? "";
  const companyId = AppRouteUi.Company.Profile.useParam()

  const { onCompanyContactDetails } = useFunctionalityCompanyContactDetails({
    companyId: companyId ?? resellerId,
    setLoaderProgress: setLoaderProgress,
  });

  const [companyContactDetails, setCompanyContactDetails] =
    useState<ApiSchemaCompanyByIdData>();

  useEffect(() => {
    if (companyId || resellerId) {
      onCompanyContactDetails()?.then((res) => {
        setCompanyContactDetails(res?.data);
        setLoaderProgress(topLoaderProgress.complete);
      });
    }
  }, [onUpdateSuccess]);

  const getUserId = () => {
    if (companyProfileData) {
      return companyProfileData?.contactPerson?.user?.id || "";
    }
    return companyContactDetails?.contactPerson?.user.id || "";
  };

  const getContactName = () => {
    if (companyProfileData) {
      return companyProfileData?.contactPerson?.user?.name || "";
    }
    return companyContactDetails?.contactPerson?.user?.name || "";
  };

  const getContactMobile = () => {
    if (companyProfileData) {
      return companyProfileData?.contactPerson?.user?.userProfile
        ?.contactNumber;
    }
    return companyContactDetails?.contactPerson?.user?.userProfile
      ?.contactNumber;
  };

  const getContactEmail = () => {
    if (companyProfileData) {
      return companyProfileData?.contactPerson?.user?.email;
    }
    return companyContactDetails?.contactPerson?.user?.email;
  };

  const [editModalShow, setEditModalShow] = useState(false);
  const [contactModalShow, setContactModalShow] = useState(false);
  const { userDetailsData } = useFunctionalityUserGetDetailsById({ userId: getUserId() });
  const { updateUserDetails } = useFunctionalityUser2({ userId: getUserId() });
  const { optionMappedLocationList } = useFunctionalityLocation({
    companyId: userDetailsData?.companyId || "", shouldLocationGetAllInvoke: editModalShow
  });
  const { optionMappedDepartmentList } = useFunctionalityDepartment({
    companyId: userDetailsData?.companyId || "", shouldDepartmentGetAllInvoke: editModalShow
  });

  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  const { stateList } = useFunctionalityState(userDetailsData?.country ?? "", editModalShow);
  const initialStateList = [...stateList];

  const { onContactUsers } = useFunctionalityContactUsers();
  const { onChangeCompanyContact } = useFunctionalityChangeCompanyContact();
  const [contactPersonsName, setContactPersonsName] = useState<any[]>([]);
  const [selectedContactPersonId, setSelectedContactPersonId] =
    useState<string>();

  const contactPersonHandler = () => {
    setLoaderProgress(topLoaderProgress.start);
    onContactUsers(userDetailsData?.companyId ?? "")?.then((res) => {
      const names =
        res?.filter(
          (contactPerson: ApiSchemaUserData) =>
            contactPerson?.status === UserStatusType.Enabled
        ) ?? [];
      var optionList = OptionMapper.mapperFunc(names);
      setContactPersonsName(optionList);
      setLoaderProgress(topLoaderProgress.complete);
    });
  };

  const onChangeHandler = (contactPersonId: string) => {
    setSelectedContactPersonId(contactPersonId ?? "");
  };

  const onContactPersonSaveHandler = () => {
    setLoaderProgress(topLoaderProgress.start);

    onChangeCompanyContact(
      userDetailsData?.companyId ?? "",
      selectedContactPersonId ?? ""
    )
      .then(() => {
        setLoaderProgress(topLoaderProgress.complete);
        setContactModalShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
      })
      .catch(() => {
        setContactModalShow(false);
      });
  };

  const onUpdateHandler = (form: ApiSchemaUpdateUser) => {
    form = { ...form, id: userDetailsData?.id, gender: Number(form.gender) };
    updateUserDetails(form)
      .then(() => {
        setEditModalShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
        mutate(PlatformApi.Reseller.GetById(resellerId));
        mutate(PlatformApi.Users.GetDetailsById(getUserId() || ""));
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  return (
    <>
      {companyProfileData ? (
        <></>
      ) : (
        <LoadingBar
          progress={loaderProgress}
          onLoaderFinished={() => setLoaderProgress(0)}
        />
      )}
      {
        companyContactDetails || companyProfileData ?
          <div className="pt-3 pb-sm-0 pb-3">
            <div className="component-card mb-3">
              <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
                <div>Contact Person</div>
                {editable && (
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-link py-0 text-site btn-sm text-uppercase"
                      onClick={() => {
                        if (
                          userDetailsData &&
                          genders
                        ) {
                          setEditModalShow(true);
                        }
                      }}
                    >
                      <span >
                        <AssetSvg.Edit />
                      </span>

                      <span className="font-sz-10 ml-2">Edit Info</span>
                    </button>
                    <button
                      className="btn btn-link py-0 text-site btn-sm text-uppercase" title="Select contact person"
                      onClick={() => {
                        contactPersonHandler();
                        setContactModalShow(true);
                      }}
                    >
                      <span >
                        <AssetSvg.ContactPerson />
                      </span>

                    </button>
                  </div>
                )}
              </div>
              <div className="component-card-body border">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-3 col-sm-12 col-12 company-profileImage-container">
                    <div className="company-profile-image">
                      <img
                        src={`${FileBucketURL.Root}${getUserId()}?${imageView.fileTimeStamp
                          }&auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`}
                        alt=""
                        onError={(ev) => {
                          defaultImageLoad(
                            ev,
                            DefaultImageType.UserProfile,
                            "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                          );
                        }}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-8 col-md-9 col-sm-12 col-12 company-profileInfo-container">
                    <div className="profile-detail ">
                      <div className="row pl-3 mb-3">
                        <div className="profile-detail-label">Name :</div>
                        <div className="profile-detail-info">
                          {" "}
                          {getContactName()}
                        </div>
                      </div>
                      <div className="row pl-3  mb-3">
                        <div className="profile-detail-label ">
                          Mobile Number :{" "}
                        </div>
                        <div className="profile-detail-info ">
                          {getContactMobile()}
                        </div>
                      </div>
                      <div className="row pl-3  mb-3">
                        <div className="profile-detail-label ">Email : </div>
                        <div className="profile-detail-info ">
                          {getContactEmail()}
                        </div>
                      </div>
                      <div className="row pl-3  mb-3">
                        <div className="profile-detail-label ">Address : </div>
                        <div className="profile-detail-info ">
                          {userDetailsData?.displayAddress}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <NoDataComponent title="Loading..." />
      }
      <ModalSingleDropdown
        show={contactModalShow}
        handleShow={setContactModalShow}
        header="Select Contact Person"
        dropdownPlaceHolder="Select a contact person"
        dropDownList={contactPersonsName}
        onChangeHandler={onChangeHandler}
        contactPersonId={userDetailsData?.id}
        onSaveHandler={onContactPersonSaveHandler}
      />
      {
        editModalShow && <ModalEditUserInfo
          optionMappedLocationList={optionMappedLocationList}
          optionMappedDepartmentList={optionMappedDepartmentList}
          onUpdateHandler={onUpdateHandler}
          userDetailsData={userDetailsData}
          show={editModalShow}
          handleShow={setEditModalShow}
          companyId={userDetailsData.companyId}
          initialStateList={initialStateList}
          genders={genders}
        />
      }
    </>
  );
}
