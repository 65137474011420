import {useCallback, useState} from "react";
import {AppConstants, PlatformApi} from "../../configs";
import {AxiosAuth} from "../../core";
import {ApiResponse} from "../../models";
import {ExistModel} from "../../models/api/Operator";

export function useFunctionalityCheckEmailExistence() {
  const [emailExistMsg, setEmailExistMsg] = useState<string>("");

  const onCheckEmailExistence = useCallback(async (value: string) => {
    const emailExistUrl = PlatformApi.Users.IsEmailExist(encodeURIComponent(value));
    try {
      const res = await AxiosAuth.get<ApiResponse<ExistModel>>(emailExistUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        if(res?.data?.data) {
          setEmailExistMsg("Email already exist");
        } else {
          setEmailExistMsg("");
        }
        return Promise.resolve(res?.data);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    onCheckEmailExistence,
    emailExistMsg,
    setEmailExistMsg
  };
}
