import { PlatformApi } from '../../configs/appRoute';
import { AxiosAuth } from '../../core';
import { ApiResponse } from '../../models';
import { CallLogPagedResponseModel,  } from '../../models/api/Call';
import { AppConstants, CallLogType } from '../../configs';
import { ApiSchemaFilter } from '../../models/api/filter';

type Props = {
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
  callLogType:CallLogType,
  filterData: ApiSchemaFilter,
};

export function useFunctionalityCallLog(props:Props) {
  const {pageSize, pageNo, searchText ,callLogType, filterData } = props;
  const pagedCallLogsUrl = PlatformApi.Call.FilteredGetCallLog(callLogType,pageSize,pageNo,searchText);
  
  const LoadCallLog=async ()=>{
    const res = await AxiosAuth.post<ApiResponse<CallLogPagedResponseModel>>(pagedCallLogsUrl, filterData);
    if ((res.status = AppConstants.Api.StatusOK)) {
      return Promise.resolve(res.data);
    }
  }
  return {
    LoadCallLog
  };
}