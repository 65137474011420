import React from 'react'
import { useHistory } from 'react-router-dom';

type Props = {
	companyName?: string;
	heading?: string,
	parentCompany?: any
}
export function CompanyProfileTopBar(props: Props) {
	const history = useHistory();

	return (
		<>
			<h4 className="mt-3 mb-0 font-weight-light">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb bg-transparent p-0">
						<li className="breadcrumb-item "><a href="javascript:void(0)" onClick={() => {
							props?.parentCompany ? history.go(-2) : history.goBack()
						}} className="font-weight-light">{props.heading ?? "Companies"}</a></li>
						{props?.parentCompany &&
							<li className="breadcrumb-item "><a href="javascript:void(0)" onClick={() => history.goBack()}
								className="font-weight-light">{props?.parentCompany}</a></li>
						}
						<li className="breadcrumb-item font-weight-light active" aria-current="page">{props.companyName}</li>
					</ol>
				</nav>
			</h4>
		</>
	)
}
