import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate} from "swr"; //cache commented
import {
  AppConstants,
  AppRouteUi,
  CompanyType,
  InvoiceGenerationType,
  InvoiceType,
  PlatformApi,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, PagedResponse, WithPagination } from "../../models/api";
import { ApiSchemaFilter } from "../../models/api/filter";
import {
  BillingAddressResponseModel,
  CompanyAccountViewModel,
  FinanceViewModel,
  InvoiceViewModel,
  RptInvoiceViewModel,
} from "../../models/api/Finance";

import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  companyType?: CompanyType;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
  onDataLoadCallBack?: any;
  invoiceType?: InvoiceType;
  isPageDataLoad?: boolean;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
  hasPagingData?: boolean;
  isPageFilterData?: boolean;
};

let pagedInvoiceUrl: string;

export function useFunctionalityPagedInvoice(props: Props) {
  const {
    companyType,
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    onDataLoadCallBack,
    invoiceType,
    filterData,
    filterDataChange,
    hasPagingData,
    isPageFilterData,
  } = props;

  const [pagedInvoiceListCount, setPagedInvoiceListCount] = useState<number>(0);
  if (invoiceType == InvoiceType.Company) {
    pagedInvoiceUrl = PlatformApi.Finance.Invoice.PagedGetAllByCompanyId(
      invoiceType,
      AppRouteUi.CompanyFinance.Profile.useParam(),
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  } else if (
    invoiceType == InvoiceType.Customer ||
    invoiceType == InvoiceType.CallCenter
  ) {
    pagedInvoiceUrl = PlatformApi.Finance.Invoice.GetPagedCompanyInvoices(
      invoiceType,
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  } else if (
    invoiceType == InvoiceType.Operator ||
    invoiceType == InvoiceType.AllOperator
  ) {
    pagedInvoiceUrl = PlatformApi.Finance.Invoice.GetPagedOperatorInvoices(
      invoiceType,
      invoiceType == InvoiceType.AllOperator
        ? ""
        : AppRouteUi.OperatorFinance.Profile.useParam(),
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  } else if (invoiceType == InvoiceType.CompanyBooking) {
    const companyId = AppRouteUi.CompanyFinance.Profile.useParam();
    pagedInvoiceUrl = PlatformApi.Finance.Invoice.PagedGetCompanyBookingInvoice(
      companyId ? companyId : "",
      invoiceType,
      pageSize,
      pageNo,
      searchText,
      filterDataChange
    );
  } else if (invoiceType == InvoiceType.OperatorBooking) {
    const userId = AppRouteUi.OperatorFinance.Profile.useParam();
    pagedInvoiceUrl =
      PlatformApi.Finance.Invoice.GetPagedOperatorBookingInvoices(
        invoiceType,
        userId ? userId : "",
        pageSize,
        pageNo,
        searchText,
        filterDataChange
      );
  }
  // cache.clear();
  const { data: apiInvoiceViewModel } = useSWR<InvoiceViewModel>(
    pagedInvoiceUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.post<ApiResponse<InvoiceViewModel>>(
        pagedInvoiceUrl,
        filterData
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedInvoiceListCount(r.data.data?.invoiceModels.count);
          if (onDataLoadCallBack) onDataLoadCallBack(r.data.data);
          return r.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  // const pagedInvoiceList = React.useMemo(() => {
  //   const data = Array.isArray(apiInvoiceViewModel?.invoiceModels?.data)
  //     ? apiInvoiceViewModel?.invoiceModels.data
  //     : [];
  //   return data;
  // }, [apiInvoiceViewModel?.invoiceModels?.data]);

  const invoiceInfoModel = React.useMemo(() => {
    const data =
      apiInvoiceViewModel?.summaryInfoModel != null
        ? apiInvoiceViewModel?.summaryInfoModel
        : null;
    return data;
  }, [apiInvoiceViewModel?.summaryInfoModel]);
  return {
    pagedInvoiceList: apiInvoiceViewModel?.invoiceModels?.data,
    pagedInvoiceListCount,
    invoiceInfoModel,
    pagedInvoiceUrl,
  };
}
export function useFunctionalityCompanyAccount(props: Props) {
  const { setLoaderProgress, isPageDataLoad } = props;
  var companyAccountUrl = PlatformApi.Finance.Invoice.GetCompanyAccount();
  const { data: apiCompanyAccount } = useSWR<CompanyAccountViewModel>(
    !isPageDataLoad ? companyAccountUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<CompanyAccountViewModel>>(
        companyAccountUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const companyAccount = React.useMemo(() => {
    const data = apiCompanyAccount ?? null;
    return data;
  }, [apiCompanyAccount]);
  return {
    companyAccount,
  };
}
type InvoiceProps = {
  companyType?: CompanyType;
  setLoaderProgress?: any;
};
export function useFunctionalityInvoice(props: InvoiceProps) {
  const { companyType, setLoaderProgress } = props;

  const getInvoiceDetails = React.useCallback(
    async (invoiceId: string, invoiceType: InvoiceType, className?: string) => {
      const btnLoading = UtilsJQuery.Ladda(className ?? ".btn-default");
      btnLoading.start?.();
      setLoaderProgress(topLoaderProgress.start);
      try {
        const response = await AxiosAuth.get<ApiResponse<RptInvoiceViewModel>>(
          PlatformApi.Finance.Invoice.GetInvoiceById(invoiceId, invoiceType)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          setLoaderProgress(topLoaderProgress.complete);
          btnLoading.stop?.();
          return response.data;
        }
      } catch (e) {
        toast.error("Failed to Generate Invoice.");
        setLoaderProgress(topLoaderProgress.complete);
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const downloadInvoicePdf = React.useCallback(
    async (invoiceId: string, invoiceType: InvoiceType) => {
      const btnLoading = UtilsJQuery.Ladda(".skill-form-submit-btn");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.get<ApiResponse<string>>(
          PlatformApi.Finance.Invoice.DownloadInvoicePdf(invoiceId, invoiceType)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          btnLoading.stop?.();
          return response.data.data;
        }
      } catch (e) {
        toast.error("Failed to Download Invoice.");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const generateInvoice = React.useCallback(async (invoiceId: string, invoiceGenerationType: InvoiceGenerationType) => {
    const btnLoading = UtilsJQuery.Ladda(".skill-form-submit-btn");
    setLoaderProgress(topLoaderProgress.start);
    console.log("invoiceId", `${PlatformApi.Invoice.Root()}/RegenerateInvoice/${invoiceId}/${invoiceGenerationType}`)
    try {
      const response = await AxiosAuth.post(PlatformApi.Invoice.invoiceGenerate(invoiceId, invoiceGenerationType));
      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Invoice Generated successfully");
        mutate(pagedInvoiceUrl);
        setLoaderProgress(topLoaderProgress.complete);
      }
    } catch (e) {
      toast.error("Failed to Generate Invoice.");
      setLoaderProgress(topLoaderProgress.complete);
      btnLoading.stop?.();
      // return Promise.reject(e);
    }
  }, []);
  const saveBillingAddress = React.useCallback(
    async (
      invoiceType: InvoiceType,
      form: BillingAddressResponseModel,
      billerId?: string
    ) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();
      let response;
      try {
        response = await AxiosAuth.put<
          ApiResponse<BillingAddressResponseModel>
        >(
          PlatformApi.Finance.Invoice.SaveBillingAddress(invoiceType, billerId),
          form
        );
        if ((response.status = AppConstants.Api.StatusOK)) {
          toast.success("Billing Address added Successfully success");
        }
      } catch (e) {
        toast.error("Failed to Update Company Details");
        console.log(e);
      }
      btnLoading.stop?.();
      return response;
    },
    []
  );
  const sendInvoiceMail = React.useCallback(
    async (invoiceId: string, invoiceType: InvoiceType) => {
      setLoaderProgress(topLoaderProgress.start);
      try {
        const response = await AxiosAuth.post(
          PlatformApi.Finance.Invoice.SendEmail(invoiceId, invoiceType)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          toast.success("Invoice Send successfully");
          setLoaderProgress(topLoaderProgress.complete);
        }
      } catch (e) {
        toast.error("Failed to Send Email.");

        return Promise.reject(e);
      }
    },
    []
  );

  return {
    getInvoiceDetails,
    generateInvoice,
    sendInvoiceMail,
    // getAllInvoicesByCompanyId,
    // getAllInvoicesByUserId,
    saveBillingAddress,
    downloadInvoicePdf,
  };
}
