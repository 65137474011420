import { AppConstants, CallType } from '../configs';

export function callPermissionHelper() {
  const checkAddOperatorPermission = (
    userRole: string,
    isVideoConferenceCall: boolean,
    isInterpreterRequired: boolean
  ) => {
    return (
      userRole === AppConstants.UserRoles.Operator ||
      (userRole !== AppConstants.UserRoles.Consumer &&
        userRole !== AppConstants.UserRoles.Operator &&
        isVideoConferenceCall &&
        isInterpreterRequired)
    );
  };
  const checkAddParticipantModalPermission = (
    callType: number,
    addParticipant: boolean
  ) => {
    return callType === CallType.Video && addParticipant;
  };
  const checkAddParticipantPermission = (
    userRole: string,
    callType: number,
    isInterpreterRequired: boolean,
    addParticipant: boolean
  ) => {
    return (
      (callType === CallType.Video &&
        userRole === AppConstants.UserRoles.Operator) ||
      (callType === CallType.Video && addParticipant) ||
      (userRole !== AppConstants.UserRoles.Consumer &&
        userRole !== AppConstants.UserRoles.Operator &&
        callType === CallType.VideoConference &&
        isInterpreterRequired)
    );
  };

  return {
    checkAddOperatorPermission,
    checkAddParticipantModalPermission,
    checkAddParticipantPermission
  };
}
