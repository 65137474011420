import { Modal } from "react-bootstrap";
import Select from "react-select";
import { PlatformType } from "../../../configs";
import {DialPadKeyMappingModel, DialPapType, Option, unknownDialPad} from "../../../models";
import {FormErrorMessage} from "../../../components";
import React, {useEffect, useState} from "react";
type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  header: string;
  totalDialPadNumber: number;
  dialPadDropdownList: Option[];
  onSaveHandler: any;
  showZeroEnabledToPinInput: boolean;
  showEnableToggler: boolean;
  isZeroEnabledToPinInput: boolean;
  isEnabled: boolean;
  dialMapType: PlatformType;
  onDialKeyDropDownChangeHandle: any;
  dialPadKeyMappingModels: DialPadKeyMappingModel[];
  onZeroEnableToggleHandler: any;
  onEnabledToggleHandler: any;
};

let errorMapping = new Map();
let selectedDialPadKey: DialPadKeyMappingModel[] = [];

export function ModalIvrDialKeyMapping(props: Props) {

  const { show, handleShow, header, totalDialPadNumber, dialPadDropdownList, onSaveHandler, onDialKeyDropDownChangeHandle, showEnableToggler, showZeroEnabledToPinInput, isEnabled, isZeroEnabledToPinInput, dialMapType, dialPadKeyMappingModels, onZeroEnableToggleHandler, onEnabledToggleHandler} = props;

  let remainingDropDownList: Option[] = [];

  const dropDownDefaultValue = (dialPadNumber: string) => {
    const isExist = dialPadKeyMappingModels?.find(x => x.key === dialPadNumber);
    const ignoreDropDownListName = dialPadKeyMappingModels.map(x => x.name);
    remainingDropDownList = dialPadDropdownList?.filter(x => !(ignoreDropDownListName.includes(x.label)));
    return isExist?.value !== unknownDialPad.value ? dialPadDropdownList?.find(x => x.value.toString() === isExist?.value.toString()) : null;
  };

  const clearStates = () => {
    selectedDialPadKey = [];
    errorMapping.clear();
  }

  const checkWhichDialPadIsSelected = (dialPad: string) => {
    const isExist = dialPadKeyMappingModels?.find(x => x.key === dialPad);

    if(isExist) {
      selectedDialPadKey = [
        ...selectedDialPadKey,
        {
          key: dialPad,
          name: isExist?.name,
          value: isExist?.value?.toString()
        }
      ];
    }
  }

  useEffect(() => {
    clearStates();
    for(let i=0; i<totalDialPadNumber; i++) {
      checkWhichDialPadIsSelected((i + 1).toString());
    }
  }, [dialPadKeyMappingModels]);

  const filterAndAdd = (option: Option, dialPadNumber: string) => {
    const alreadyOccurred = selectedDialPadKey?.find(data => data?.key === dialPadNumber);
    selectedDialPadKey = alreadyOccurred ? selectedDialPadKey?.filter(data => data?.key !== dialPadNumber) : selectedDialPadKey;
    selectedDialPadKey = [
      ...selectedDialPadKey,
      {
        key: dialPadNumber,
        name: option?.label,
        value: option?.value?.toString()
      }
    ];
  }

  const checkDuplicates = () => {
    const allValues = selectedDialPadKey.map(item => item?.value);

    for(let value of allValues) {
      if(value !== "") {
        const filtered = selectedDialPadKey?.filter(data => data?.value === value) || [];
        if(filtered?.length > 1) {
          filtered?.map(data => errorMapping.set(data?.key, 'Duplicate'));
        } else {
          errorMapping.set(filtered[0]?.key, '');
        }
      }
    }
  }

  const handleOptionChange = (option: any, dialPadNumber: string) => {
    filterAndAdd(option, dialPadNumber);
    checkDuplicates();
  }

  const hasDuplicateValue = () => {
    const errors = Array.from(errorMapping.values());
    return errors.includes('Duplicate');
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        clearStates();
        handleShow(false);
      }}
      centered
      scrollable
    >

              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showEnableToggler ? <div className="d-flex flex-wrap pb-4 justify-content-between align-items-center">
            <div className="col px-0">
              <div>Enable {header?.replace('IVR','')}</div>
            </div>
            <div className="px-3 mr-3">
              <button
                type="button"
                data-toggle="button"
                className={isEnabled ? `btn btn-toggle text-right on-off-btn active` : `btn btn-toggle text-right on-off-btn`}
                onClick={() => { onEnabledToggleHandler(isEnabled) }}
              >
                <div className="handle"></div>
              </button>
            </div>
          </div> : null
        }

        {
          Array.from({ length: totalDialPadNumber }, (_, i) => (
            <div className="form-group row align-items-center" key={i}>
              <label
                htmlFor="DialPadNumber"
                className=" col-12 col-sm-4 text-muted d-block mb-0"
              >
                Dial Pad {i + 1}
              </label>
              <div className="col-12 col-sm-8 text-muted">
                <Select
                  className={` ${ errorMapping.get((i+1).toString()) === "Duplicate" ? 'field-required' : ''} `}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={dropDownDefaultValue((i + 1).toString())}
                  placeholder={`Select a dial pad ${DialPapType[dialMapType]}`}
                  options={dialPadDropdownList}
                  onChange={(option) => {
                    onDialKeyDropDownChangeHandle(option, i + 1);
                    handleOptionChange(option, (i+1).toString());
                  }}
                />
                {
                  errorMapping.get((i+1).toString()) && <FormErrorMessage message="*Duplicate" />
                }
              </div>
            </div>
          ))
        }

        {
          showZeroEnabledToPinInput ? <div className="d-flex flex-wrap  justify-content-between align-items-center pt-1">
            <div className="col px-0">
              <div>Enable "0" for pin input</div>
            </div>
            <div className="px-3 mr-3">
              <button
                type="button"
                data-toggle="button"
                className={isZeroEnabledToPinInput ? `btn btn-toggle text-right on-off-btn active` : `btn btn-toggle text-right on-off-btn`}
                onClick={() => { onZeroEnableToggleHandler(isZeroEnabledToPinInput) }}
              >
                <div className="handle"></div>
              </button>
            </div>
          </div> : null
        }
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-link text-site text-uppercase"
          onClick={() => {
            clearStates();
            handleShow(false);
          }}
        >
          Cancel
        </button>
        <button type="button" className="btn btn-primary login-from-submit-btn text-uppercase"
                onClick={() => { onSaveHandler(dialMapType) }}
                disabled={hasDuplicateValue()}
        >
          Save
        </button>
      </Modal.Footer>

    </Modal>
  );
}
