import { useState } from "react";
import {
  CompanyConferenceInfo
} from "../../features/CompanyProfile";
type Props = {
  companyName: string;
  companyProfileData?: any;
};
export function PageConferenceSettings(props: Props): JSX.Element {
  const { companyName, companyProfileData } = props;
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      {/* <h4 className="mt-1 mb-4 font-weight-light">Job Settings</h4> */}
      <div className="component-card mb-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
          <div>Copy to your website {companyName}</div>
        </div>
        <div className="component-card-body border">
         
            <CompanyConferenceInfo />
          
        </div>
      </div>
    </>
  );
}
