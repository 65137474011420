import { useEffect, useContext } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AssetSvg } from '../../assets';
import GoogleLoginComponent from '../../components/common/google/googleLoginComponent';
import { AppConstants, AppRouteUi, device, ExternalLoginConstants } from '../../configs';
import {
  ActionApp,
  SocketContext,
  StorageAuth,
  StorageUserCalendar,
  useAppSelector,
} from '../../core';

const clientId = ExternalLoginConstants.Google.ClientId;

export function ExternalLogin(): JSX.Element {
  const dispatch = useDispatch();
  const data = useAppSelector(
    (state) => ({
      isLoggedIn: state.auth.isLoggedIn,
      role: state.auth.profile?.userRole,
      previousPath: state.ui.previousRoute,
      isForcefullyLoggedout: state.ui.isForceFullylogedout,
    }),
    shallowEqual
  );
  const location = useLocation<{ ForceFullyLoggedout: boolean }>();
  const { socketConnection } = useContext(SocketContext);

  useEffect(() => {
    if (location.state?.ForceFullyLoggedout) {
      toast.error('You have been logged out forcefully by another user');
      socketConnection?.close();
      StorageAuth.Clear();
      StorageUserCalendar.Clear();
      dispatch(ActionApp.ResetStore());
      device.destroy();
    }
  }, []);

  if (data.isLoggedIn && data.role !== AppConstants.UserRoles.Operator) {
    return <Redirect to={AppRouteUi.Home.Root} />;
  } else if (data.isLoggedIn && data.role === AppConstants.UserRoles.Operator) {
    return <Redirect to={AppRouteUi.CallLog.Root} />;
  }

  return (
    <>
      <div className="container-md container-login-sm px-0 px-sm-0 px-md-3 px-lg-3">
        <div className="row m-auto login-container-card">
          <div className="col-12">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="login-logo m-3">
                  <AssetSvg.VRILogo />
                </div>
              </div>
              <div className="col-12 py-4">
                <p className="font-weight-bold text-capitalize text-center"></p>
                <div className="px-4">
                  <div className="btn  btn-block mb-3">
                    <GoogleOAuthProvider clientId={clientId}>
                      <GoogleLoginComponent/>
                    </GoogleOAuthProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
