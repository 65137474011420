import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { ModalSupportPlatform } from '..';
import { AssetSvg } from '../../assets';
import { AppRouteUi } from '../../configs';
import { ApiSchemaSupportedPLatform } from '../../models';
import { useFunctionalityOperatorProfile } from '../../pages/hooks';

type Props = {
    readonly: boolean;
    operatorId?: string;
    setIsOnsiteEnabled: any
}
export function SupportedPlatform({ readonly, operatorId, setIsOnsiteEnabled }: Props) {
    const [show, handleShow] = useState<boolean>(false);
    const selectedOperatorId = operatorId ? operatorId : AppRouteUi.Operator.Profile.useParam();
    const [loaderProgress, setLoaderProgress] = useState(0);
    const { supportedPlatformData, onUpdateSupportedPlatform } = useFunctionalityOperatorProfile({ operatorId: selectedOperatorId, setLoaderProgress });
    const [vriToggleHandler, setVriToggleHandler] = useState(supportedPlatformData?.isVriEnabled);
    const [opiToggleHandler, setOpiToggleHandler] = useState(supportedPlatformData?.isOpiEnabled);
    const [onsiteToggleHandler, setOnsiteToggleHandler] = useState(supportedPlatformData?.isOnSiteEnabled);
    const onSaveHandler = () => {
        let requestModel: ApiSchemaSupportedPLatform = {
            isVriEnabled: vriToggleHandler ?? supportedPlatformData?.isVriEnabled,
            isOpiEnabled: opiToggleHandler ?? supportedPlatformData?.isOpiEnabled,
            isOnSiteEnabled: onsiteToggleHandler ?? supportedPlatformData?.isOnSiteEnabled
        };
        onUpdateSupportedPlatform(selectedOperatorId, requestModel).
            then(() => {
                handleShow(false);
            });

    };
    useEffect(() => {
        setIsOnsiteEnabled(supportedPlatformData?.isOnSiteEnabled)
    }, [supportedPlatformData])
    return (
        <>
            <LoadingBar
                progress={loaderProgress}
                onLoaderFinished={() => setLoaderProgress(0)}
            />
            <div className="component-card mb-3">
                <div className="component-card-header d-flex align-items-center justify-content-between border-left border-top border-right ">
                    <div>Supported Platforms</div>
                    {
                        readonly ?
                            <button className="btn btn-link px-0 text-site btn-sm text-uppercase">
                                <div className="btn svg-color" title="supportedPlatformEdit" onClick={() => handleShow(true)}>
                                    <span >
                                        <AssetSvg.Edit />
                                    </span>
                                </div>
                            </button>
                            : null
                    }
                </div>
                <div className="component-card-body border">
                    <div className="row">
                        <div className="col-12">
                            {supportedPlatformData?.isVriEnabled ? <h3 className="text-purple mb-1 font-500">
                                VRI
                            </h3> : null}
                            {supportedPlatformData?.isOpiEnabled ? <h3 className="text-purple mb-1 font-500">
                                OPI
                            </h3> : null}
                            {supportedPlatformData?.isOnSiteEnabled ? <h3 className="text-purple mb-1 font-500">
                                ON_SITE
                            </h3> : null}
                        </div>
                    </div>
                </div>
                <ModalSupportPlatform show={show} handleShow={handleShow} onSaveHandler={onSaveHandler} supportedPlatformData={supportedPlatformData} setVriToggleHandler={setVriToggleHandler} setOpiToggleHandler={setOpiToggleHandler} setOnsiteToggleHandler={setOnsiteToggleHandler} />
            </div>
        </>
    )
}
