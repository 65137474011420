import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from '../../../components';
import { PlatformType } from '../../../configs';
import {
  BossContactPlatform,
  BossContract,
  ContractBossSystemHoursError,
  ContractBossSystemHoursFieldName,
  ContractBossSystemMinutesError,
  ContractBossSystemMinutesFieldName,
  DurationZeroError,
} from '../../../models';
import { useFunctionalityInputChecker } from '../../../pages/hooks/functionalityInputChecker';
import { useFunctionalityBossSystem } from '../../../pages/hooks/functionlaityBossSystem';
type Props = {
  id: string;
  show: boolean;
  handleShow: (value: boolean) => void;
  header: string;
  data: BossContract | undefined;
  contractId: string;
  setOnUpdateSuccess?: any;
};
export function ModalBossSystemFees(props: Props) {
  const { id, show, handleShow, data, contractId } = props;

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<BossContract>();

  const { onUpdateBossSystem } = useFunctionalityBossSystem();

  const [opiToggle, setOpiToggle] = useState(false);
  const [vriToggle, setVriToggle] = useState(false);
  const [f2fToggle, setF2fToggle] = useState(false);
  const [opiToggleDisable, setOpiToggleDisable] = useState(false);
  const [vriToggleDisable, setVriToggleDisable] = useState(false);
  const [f2fToggleDisable, setF2fToggleDisable] = useState(false);
  const [opiData, setOpiData] = useState<BossContactPlatform>();
  const [vriData, setVriData] = useState<BossContactPlatform>();
  const [f2fData, setF2fData] = useState<BossContactPlatform>();

  const [hoursErrorMsg, setHoursErrorMsg] =
    useState<ContractBossSystemHoursError>({
      urgentHourError: '',
      vriHourError: '',
      opiHourError: '',
      faceToFaceHourError: '',
    });
  const [minutesErrorMsg, setMinutesErrorMsg] =
    useState<ContractBossSystemMinutesError>({
      urgentMinutesError: '',
      vriMinutesError: '',
      opiMinutesError: '',
      faceToFaceMinutesError: '',
    });

  const {
    handleSpecialCharacterWhiteSpaces,
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleLimitDecimalPlaces,
  } = useFunctionalityInputChecker();

  const initializeErrorState = () => {
    setHoursErrorMsg({
      urgentHourError: '',
      vriHourError: '',
      opiHourError: '',
      faceToFaceHourError: '',
    });

    setMinutesErrorMsg({
      urgentMinutesError: '',
      vriMinutesError: '',
      opiMinutesError: '',
      faceToFaceMinutesError: '',
    });
  };

  useEffect(() => {
    data?.bossContactPlatforms.forEach((contactPlatform) => {
      if (contactPlatform.platform == PlatformType.OPI) {
        setOpiToggle(contactPlatform.isEnabled);
        setOpiData(contactPlatform);
      }
      if (contactPlatform.platform == PlatformType.VRI) {
        setVriToggle(contactPlatform.isEnabled);
        setVriData(contactPlatform);
      }
      if (contactPlatform.platform == PlatformType.OnSite) {
        setF2fToggle(contactPlatform.isEnabled);
        setF2fData(contactPlatform);
      }
    });
  }, [data]);

  const toggleHandler = (togglePlatform: PlatformType) => {
    if (togglePlatform == PlatformType.OPI) {
      setOpiToggle(!opiToggle);
    }
    if (togglePlatform == PlatformType.VRI) {
      setVriToggle(!vriToggle);
    }
    if (togglePlatform == PlatformType.OnSite) {
      setF2fToggle(!f2fToggle);
    }
  };

  const handleToggleDisable = (value: boolean) => {
    setOpiToggleDisable(value);
    setVriToggleDisable(value);
    setF2fToggleDisable(value);
  };

  const formUpdate = (
    form: BossContract,
    toggleInfo: boolean,
    index: number
  ) => {
    form.bossContactPlatforms[index].isEnabled = toggleInfo;
    form.bossContactPlatforms[index].minimumRate = form.bossContactPlatforms[
      index
    ].minimumRate
      ? parseFloat(form.bossContactPlatforms[index].minimumRate.toString())
      : 0;
    form.bossContactPlatforms[index].platform = parseInt(
      form.bossContactPlatforms[index].platform.toString()
    );
    return form;
  };

  const submitHandler = (form: BossContract) => {
    form.urgentJobRate = parseFloat(form.urgentJobRate.toString());
    var defalultValueOpi: BossContactPlatform = {
      isEnabled: false,
      minimumRate: 0,
      minimumTimeHour: '0',
      minimumTimeMinute: '0',
      platform: 2,
    };
    var defalultValueVri: BossContactPlatform = {
      isEnabled: false,
      minimumRate: 0,
      minimumTimeHour: '0',
      minimumTimeMinute: '0',
      platform: 1,
    };

    var defalultValueOnsite: BossContactPlatform = {
      isEnabled: false,
      minimumRate: 0,
      minimumTimeHour: '0',
      minimumTimeMinute: '0',
      platform: 3,
    };
    if (!form.bossContactPlatforms) form.bossContactPlatforms = [];

    if (opiToggle) {
      form = formUpdate(form, opiToggle, 0);
    } else {
      form.bossContactPlatforms[0] = defalultValueOpi;
    }
    if (vriToggle) {
      form = formUpdate(form, vriToggle, 1);
    } else {
      form.bossContactPlatforms[1] = defalultValueVri;
    }

    if (f2fToggle) {
      form = formUpdate(form, f2fToggle, 2);
    } else {
      form.bossContactPlatforms[2] = defalultValueOnsite;
    }
    onUpdateBossSystem(id, contractId, form)?.then(() => {
      props.setOnUpdateSuccess((prev: any) => !prev);
      handleToggleDisable(false);
      handleShow(false);
    });
  };

  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const charsWithoutDigit = /[a-zA-Z]/g;

  const savingHourErrorMessage = (fieldName: string, message: string) => {
    if (fieldName === ContractBossSystemHoursFieldName.urgentHourField) {
      setHoursErrorMsg({
        ...hoursErrorMsg,
        urgentHourError: message,
      });
    } else if (fieldName === ContractBossSystemHoursFieldName.opiHourField) {
      setHoursErrorMsg({
        ...hoursErrorMsg,
        opiHourError: message,
      });
    } else if (fieldName === ContractBossSystemHoursFieldName.vriHourField) {
      setHoursErrorMsg({
        ...hoursErrorMsg,
        vriHourError: message,
      });
    } else if (
      fieldName === ContractBossSystemHoursFieldName.faceToFaceHourField
    ) {
      setHoursErrorMsg({
        ...hoursErrorMsg,
        faceToFaceHourError: message,
      });
    }
  };

  const savingMinuteErrorMessage = (fieldName: string, message: string) => {
    if (fieldName === ContractBossSystemMinutesFieldName.urgentMinuteField) {
      setMinutesErrorMsg({
        ...minutesErrorMsg,
        urgentMinutesError: message,
      });
    } else if (
      fieldName === ContractBossSystemMinutesFieldName.opiMinuteField
    ) {
      setMinutesErrorMsg({
        ...minutesErrorMsg,
        opiMinutesError: message,
      });
    } else if (
      fieldName === ContractBossSystemMinutesFieldName.vriMinuteField
    ) {
      setMinutesErrorMsg({
        ...minutesErrorMsg,
        vriMinutesError: message,
      });
    } else if (
      fieldName === ContractBossSystemMinutesFieldName.faceToFaceMinuteField
    ) {
      setMinutesErrorMsg({
        ...minutesErrorMsg,
        faceToFaceMinutesError: message,
      });
    }
  };

  const getPerSectionHourFieldName = (minuteFieldName: string) => {
    const index = minuteFieldName?.indexOf('Minute');
    return minuteFieldName?.substring(0, index).concat('Hour');
  };

  const getPerSectionMinuteFieldName = (hourFieldName: string) => {
    const index = hourFieldName?.indexOf('Hour');
    return hourFieldName?.substring(0, index).concat('Minute');
  };

  const getFieldValue = (fieldId: string) => {
    const field = document?.getElementById(fieldId) as HTMLInputElement;
    return field.value ?? 0;
  };

  const hasZeroHoursError = (hourFieldName: string) => {
    if (hourFieldName === ContractBossSystemHoursFieldName.urgentHourField) {
      return hoursErrorMsg?.urgentHourError === DurationZeroError.message;
    } else if (
      hourFieldName === ContractBossSystemHoursFieldName.opiHourField
    ) {
      return hoursErrorMsg?.opiHourError === DurationZeroError.message;
    } else if (
      hourFieldName === ContractBossSystemHoursFieldName.vriHourField
    ) {
      return hoursErrorMsg?.vriHourError === DurationZeroError.message;
    } else if (
      hourFieldName === ContractBossSystemHoursFieldName.faceToFaceHourField
    ) {
      return hoursErrorMsg?.faceToFaceHourError === DurationZeroError.message;
    }
  };

  const hasZeroMinutesError = (minuteFieldName: string) => {
    if (
      minuteFieldName === ContractBossSystemMinutesFieldName.urgentMinuteField
    ) {
      return minutesErrorMsg?.urgentMinutesError === DurationZeroError.message;
    } else if (
      minuteFieldName === ContractBossSystemMinutesFieldName.opiMinuteField
    ) {
      return minutesErrorMsg?.opiMinutesError === DurationZeroError.message;
    } else if (
      minuteFieldName === ContractBossSystemMinutesFieldName.vriMinuteField
    ) {
      return minutesErrorMsg?.vriMinutesError === DurationZeroError.message;
    } else if (
      minuteFieldName ===
      ContractBossSystemMinutesFieldName.faceToFaceMinuteField
    ) {
      return (
        minutesErrorMsg?.faceToFaceMinutesError === DurationZeroError.message
      );
    }
  };

  const zeroDurationChecker = (
    hours: number,
    minutes: number,
    hourFieldName: string,
    minuteFieldName: string
  ) => {
    if (minutes === 0 && hours === 0) {
      savingHourErrorMessage(hourFieldName, DurationZeroError.message);
      savingMinuteErrorMessage(minuteFieldName, DurationZeroError.message);
      return true;
    } else {
      hasZeroHoursError(hourFieldName) &&
        savingHourErrorMessage(hourFieldName, '');
      hasZeroMinutesError(minuteFieldName) &&
        savingMinuteErrorMessage(minuteFieldName, '');
      return false;
    }
  };

  const handleHours = (e: any) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const hours = fieldValue;

    const minuteFieldName = getPerSectionMinuteFieldName(fieldName);
    const minutes = getFieldValue(minuteFieldName);

    if (
      zeroDurationChecker(
        parseInt(hours),
        parseInt(minutes),
        fieldName,
        minuteFieldName
      )
    )
      return true;

    if (
      hours.indexOf(' ') >= 0 ||
      specialChars.test(hours) ||
      charsWithoutDigit.test(hours)
    ) {
      savingHourErrorMessage(fieldName, 'Please enter valid hour');
    } else if (parseInt(hours) > 999) {
      savingHourErrorMessage(fieldName, 'Please enter a proper hour range');
    } else {
      savingHourErrorMessage(fieldName, '');
    }
  };

  const handleMinutes = (e: any) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const minutes = fieldValue;

    const hourFieldName = getPerSectionHourFieldName(fieldName);
    const hours = getFieldValue(hourFieldName);

    if (
      zeroDurationChecker(
        parseInt(hours),
        parseInt(minutes),
        hourFieldName,
        fieldName
      )
    )
      return true;

    if (
      minutes.indexOf(' ') >= 0 ||
      specialChars.test(minutes) ||
      charsWithoutDigit.test(minutes)
    ) {
      savingMinuteErrorMessage(fieldName, 'Please enter valid minute');
    } else if (parseInt(minutes) > 59) {
      savingMinuteErrorMessage(fieldName, 'Please enter a proper minute range');
    } else {
      savingMinuteErrorMessage(fieldName, '');
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        initializeErrorState();
        handleShow(false);
      }}
      centered
    >
      <form onSubmit={handleSubmit(submitHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="component-card rounded mb-3">
            <div className="component-card-header rounded-top border ">
              <div className="text-capitalize ">Urgent Job</div>
            </div>
            <div className="component-card-body rounded-bottom  border-left border-right border-bottom">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">Maximum Time</div>
                  <div className="form-group row">
                    <div className="col-6">
                      <label htmlFor="" className="position-relative required">
                        Hours
                      </label>
                      <input
                        id="urgentJobMaximumTimeHour"
                        {...register('urgentJobMaximumTimeHour', {
                          required: true,
                          validate: handleSpecialCharacterWhiteSpaces,
                        })}
                        type="text"
                        className="form-control"
                        defaultValue={data?.urgentJobMaximumTimeHour ?? 0}
                        onChange={(e: any) => handleHours(e)} />
                      {errors.urgentJobMaximumTimeHour &&
                        errors.urgentJobMaximumTimeHour.type == 'validate' && (
                          <FormErrorMessage message="Please enter valid value" />
                        )}
                      {errors.urgentJobMaximumTimeHour &&
                        errors.urgentJobMaximumTimeHour.type == 'required' && (
                          <FormErrorMessage message="required" />
                        )}
                      {hoursErrorMsg?.urgentHourError && (
                        <FormErrorMessage
                          message={hoursErrorMsg?.urgentHourError}
                        />
                      )}
                    </div>
                    <div className="col-6">
                      <label htmlFor="" className="position-relative required">
                        Minutes
                      </label>
                      <input
                        id="urgentJobMaximumTimeMinute"
                        {...register('urgentJobMaximumTimeMinute', {
                          required: true,
                          validate: handleSpecialCharacterWhiteSpaces,
                        })}
                        type="text"
                        className="form-control"
                        defaultValue={data?.urgentJobMaximumTimeMinute ?? 0}
                        onChange={(e: any) => handleMinutes(e)} />
                      {errors.urgentJobMaximumTimeMinute &&
                        errors.urgentJobMaximumTimeMinute.type ==
                          'validate' && (
                          <FormErrorMessage message="Please enter valid value" />
                        )}
                      {errors.urgentJobMaximumTimeMinute &&
                        errors.urgentJobMaximumTimeMinute.type ==
                          'required' && <FormErrorMessage message="required" />}
                      {minutesErrorMsg?.urgentMinutesError && (
                        <FormErrorMessage
                          message={minutesErrorMsg?.urgentMinutesError}
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <label htmlFor="" className="position-relative required">
                      Rate
                    </label>
                    <div className="input-group ">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="flat-rate">
                          $
                        </span>
                      </div>
                      <input
                        type="float"
                        className="form-control"
                        {...register('urgentJobRate', {
                          required: true,
                          validate: handleCopyPasteInvalidValue,
                        })}
                        aria-describedby="flatRate"
                        defaultValue={data?.urgentJobRate ?? 0}
                        onKeyPress={(event) => {
                          if (!/[0-9]|\./.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onKeyDown={(evt) => numberFieldValidation(evt)}
                        onInput={(event) => handleLimitDecimalPlaces(event, 2)} />
                      {errors.urgentJobRate &&
                        errors.urgentJobRate.type === 'required' && (
                          <FormErrorMessage message="required" />
                        )}
                      {errors.urgentJobRate &&
                        errors.urgentJobRate.type === 'validate' && (
                          <FormErrorMessage message="Please enter a valid value" />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="component-card rounded mb-3">
            <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
              <div className="col-6 pl-0">OPI</div>
              <div className="col-6 pr-0 text-right">
                <button
                  type="button"
                  className={
                    opiToggle
                      ? 'btn btn-toggle on-off-btn active'
                      : 'btn btn-toggle on-off-btn'
                  }
                  onClick={() => toggleHandler(PlatformType.OPI)}
                  disabled={opiToggleDisable}
                >
                  <div className="handle"></div>
                </button>
              </div>
            </div>
            {opiToggle ? (
              <div
                className="component-card-body rounded-bottom  border-left border-right border-bottom"
                id="UpdateRate-OPI-collapse"
              >
                <input
                  type="hidden"
                  {...register('bossContactPlatforms.0.platform')}
                  defaultValue={PlatformType.OPI} />

                <div className="row">
                  <div className="col-12">
                    <div className="form-group">Minimum Time</div>
                    <div className="form-group row">
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Hours
                        </label>
                        <input
                          id="bossContactPlatforms.0.minimumTimeHour"
                          {...register('bossContactPlatforms.0.minimumTimeHour', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={opiData ? opiData?.minimumTimeHour : 0}
                          onChange={(e: any) => handleHours(e)} />
                        {errors.bossContactPlatforms?.[0]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[0]?.minimumTimeHour
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[0]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[0]?.minimumTimeHour
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {hoursErrorMsg?.opiHourError && (
                          <FormErrorMessage
                            message={hoursErrorMsg?.opiHourError}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Minutes
                        </label>
                        <input
                          id="bossContactPlatforms.0.minimumTimeMinute"
                          {...register('bossContactPlatforms.0.minimumTimeMinute', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={
                            opiData ? opiData?.minimumTimeMinute : 0
                          }
                          onChange={(e: any) => handleMinutes(e)} />
                        {errors.bossContactPlatforms?.[0]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[0]?.minimumTimeMinute
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[0]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[0]?.minimumTimeMinute
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {minutesErrorMsg?.opiMinutesError && (
                          <FormErrorMessage
                            message={minutesErrorMsg?.opiMinutesError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="component-card rounded mb-3">
            <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
              <div className="col-6 pl-0">VRI</div>
              <div className="col-6 pr-0 text-right">
                <button
                  type="button"
                  className={
                    vriToggle
                      ? 'btn btn-toggle on-off-btn active'
                      : 'btn btn-toggle on-off-btn'
                  }
                  onClick={() => toggleHandler(PlatformType.VRI)}
                  disabled={vriToggleDisable}
                >
                  <div className="handle"></div>
                </button>
              </div>
            </div>
            {vriToggle ? (
              <div
                className="component-card-body rounded-bottom  border-left border-right border-bottom"
                id="UpdateRate-VRI-collapse"
              >
                <input
                  type="hidden"
                  {...register('bossContactPlatforms.1.platform')}
                  defaultValue={PlatformType.VRI} />
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">Minimum Time</div>
                    <div className="form-group row">
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Hours
                        </label>
                        <input
                          id="bossContactPlatforms.1.minimumTimeHour"
                          {...register('bossContactPlatforms.1.minimumTimeHour', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={vriData ? vriData?.minimumTimeHour : 0}
                          onChange={(e: any) => handleHours(e)} />
                        {errors.bossContactPlatforms?.[1]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[1]?.minimumTimeHour
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[1]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[1]?.minimumTimeHour
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {hoursErrorMsg?.vriHourError && (
                          <FormErrorMessage
                            message={hoursErrorMsg?.vriHourError}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Minutes
                        </label>
                        <input
                          id="bossContactPlatforms.1.minimumTimeMinute"
                          {...register('bossContactPlatforms.1.minimumTimeMinute', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={
                            vriData ? vriData?.minimumTimeMinute : 0
                          }
                          onChange={(e: any) => handleMinutes(e)} />
                        {errors.bossContactPlatforms?.[1]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[1]?.minimumTimeMinute
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[1]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[1]?.minimumTimeMinute
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {minutesErrorMsg?.vriMinutesError && (
                          <FormErrorMessage
                            message={minutesErrorMsg?.vriMinutesError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="component-card rounded mb-3">
            <div className="component-card-header d-flex align-items-center justify-content-between rounded-top border">
              <div className="col-6 pl-0">Face to Face</div>
              <div className="col-6 pr-0 text-right">
                <button
                  type="button"
                  className={
                    f2fToggle
                      ? 'btn btn-toggle on-off-btn active'
                      : 'btn btn-toggle on-off-btn'
                  }
                  onClick={() => toggleHandler(PlatformType.OnSite)}
                  disabled={f2fToggleDisable}
                >
                  <div className="handle"></div>
                </button>
              </div>
            </div>
            {f2fToggle ? (
              <div
                className="component-card-body rounded-bottom  border-left border-right border-bottom"
                id="UpdateRate-faceToface-collapse"
              >
                <input
                  type="hidden"
                  {...register('bossContactPlatforms.2.platform')}
                  defaultValue={PlatformType.OnSite} />
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">Minimum Time</div>
                    <div className="form-group row">
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Hours
                        </label>
                        <input
                          id="bossContactPlatforms.2.minimumTimeHour"
                          {...register('bossContactPlatforms.2.minimumTimeHour', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={f2fData ? f2fData?.minimumTimeHour : 0}
                          onChange={(e: any) => handleHours(e)} />
                        {errors.bossContactPlatforms?.[2]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[2]?.minimumTimeHour
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[2]?.minimumTimeHour &&
                          errors.bossContactPlatforms?.[2]?.minimumTimeHour
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {hoursErrorMsg?.faceToFaceHourError && (
                          <FormErrorMessage
                            message={hoursErrorMsg?.faceToFaceHourError}
                          />
                        )}
                      </div>
                      <div className="col-6">
                        <label
                          htmlFor=""
                          className="position-relative required"
                        >
                          Minutes
                        </label>
                        <input
                          id="bossContactPlatforms.2.minimumTimeMinute"
                          {...register('bossContactPlatforms.2.minimumTimeMinute', {
                            required: true,
                            validate: handleSpecialCharacterWhiteSpaces,
                          })}
                          type="text"
                          className="form-control"
                          defaultValue={
                            f2fData ? f2fData?.minimumTimeMinute : 0
                          }
                          onChange={(e: any) => handleMinutes(e)} />
                        {errors.bossContactPlatforms?.[2]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[2]?.minimumTimeMinute
                            .type == 'validate' && (
                            <FormErrorMessage message="Please enter valid value" />
                          )}
                        {errors.bossContactPlatforms?.[2]?.minimumTimeMinute &&
                          errors.bossContactPlatforms?.[2]?.minimumTimeMinute
                            .type == 'required' && (
                            <FormErrorMessage message="required" />
                          )}
                        {minutesErrorMsg?.faceToFaceMinutesError && (
                          <FormErrorMessage
                            message={minutesErrorMsg?.faceToFaceMinutesError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              initializeErrorState();
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn-update-boss btn btn-primary text-uppercase"
            disabled={
              !!(
                hoursErrorMsg?.urgentHourError ||
                hoursErrorMsg?.opiHourError ||
                hoursErrorMsg?.vriHourError ||
                hoursErrorMsg?.faceToFaceHourError ||
                minutesErrorMsg?.urgentMinutesError ||
                minutesErrorMsg?.opiMinutesError ||
                minutesErrorMsg?.vriMinutesError ||
                minutesErrorMsg?.faceToFaceMinutesError
              )
            }
            onClick={() => handleToggleDisable(true)}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
