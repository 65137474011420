export function debounce(callback: Function, delayInSeconds: number = 1000) {
  let timeoutId: number | null = null;

  return (...args: any) => {
    timeoutId && clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, delayInSeconds);
  };
}
