import {
  faBars,
  faCheckCircle,
  faCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Dispatch,
  ReactText,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { batch, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastOptions, toast } from 'react-toastify';
import { SidePanel } from '..';
import { AppConfig, DefaultImageType } from '../../configs';
import { AppRouteUi, FileBucketURL } from '../../configs/appRoute';
import {
  ActionAuth,
  ActionNotification,
  ChannelContext,
  SocketContext,
  StorageAuth,
  incrementAsync,
  useAppDispatch,
  useAppSelector
} from '../../core';
import { OperatorStatusType } from '../../models/api/Operator';
import { useChangeStatus, useFunctionalityAuth } from '../../pages/hooks';
import { useFunctionalityImageView } from '../../pages/hooks/functionalityImageView';
import { UserAgentUtils, logger } from '../../utils';
import { NotificationList } from '../Notification';

type Props = {
  toggleButton: boolean;
  toggler: Dispatch<SetStateAction<boolean>>;
};

export function TopNav(props: Props): JSX.Element {
  const previousUserStatusRef = useRef<OperatorStatusType>(
    StorageAuth.ProfileData?.userStatus!
  );
  const userId = useAppSelector(
    (state) => state.auth.profile?.id,
    shallowEqual
  );
  const [haveConnectionProblem, setHaveConnectionProblem] = useState(false);
  const { twilioDeviceStatus, websocketStatus } = useAppSelector(
    (state) => state.common
  );
  const { shouldConnectWS } = useAppSelector((state) => state.common);
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const { setNewChatMessageCount, newChatMessageCount, chatType, setChatType } =
    React.useContext(ChannelContext);
  const { changeStatus } = useChangeStatus();

  const unreadCount = useAppSelector(
    (state) => state.notification?.unreadNotificationCount,
    shallowEqual
  );
  const notificationShown = useAppSelector(
    (state) => state.notification?.notificationShown,
    shallowEqual
  );

  const profileData = useAppSelector((state) => state.auth.profile);
  const userStatus = (() => {
    if (profileData?.userStatus === OperatorStatusType.Online) {
      return {
        icon: faCheckCircle,
        text: 'Available',
        style: 'text-success',
      };
    } else if (profileData?.userStatus === OperatorStatusType.Busy) {
      return {
        icon: faMinusCircle,
        text: 'Busy',
        style: 'text-warning',
      };
    } else {
      return {
        icon: faCircle,
        text: 'Offline',
        style: 'text-muted',
      };
    }
  })();
  const dispatch = useAppDispatch();
  const user = useAppSelector(
    (state) => ({
      name: state.auth.profile?.name,
    }),
    shallowEqual
  );

  const { onLogout } = useFunctionalityAuth();

  const [notification, setNotification] = React.useState('');

  const handleStatusChange = (status: OperatorStatusType) => {
    if (haveConnectionProblem) {
      return;
    }
    previousUserStatusRef.current = status;
    changeStatus(status);
  };

  useEffect(() => {
    dispatch(incrementAsync());
  }, [dispatch]);

  useEffect(() => {
    if (
      twilioDeviceStatus === 'online' &&
      websocketStatus === 'online' &&
      haveConnectionProblem
    ) {
      setHaveConnectionProblem(false);
      if (previousUserStatusRef.current === OperatorStatusType.Busy) {
        changeStatus(OperatorStatusType.Busy);
      } else {
        changeStatus(OperatorStatusType.Online);
      }
    }
    if (
      (twilioDeviceStatus === 'offline' || websocketStatus === 'offline') &&
      !haveConnectionProblem
    ) {
      setHaveConnectionProblem(true);
    }
  }, [
    twilioDeviceStatus,
    websocketStatus,
    profileData?.userStatus,
    haveConnectionProblem,
    dispatch,
    changeStatus,
  ]);

  useEffect(() => {
    if (
      haveConnectionProblem &&
      profileData?.userStatus !== OperatorStatusType.Offline &&
      profileData?.userStatus !== OperatorStatusType.Busy
    ) {
      previousUserStatusRef.current = profileData?.userStatus!;
      dispatch(ActionAuth.SetUserStatus(OperatorStatusType.Offline));
      changeStatus(OperatorStatusType.Offline);
    }
  }, [haveConnectionProblem, profileData?.userStatus, dispatch, changeStatus]);

  useEffect(() => {
    if (AppConfig.tabQueue && !shouldConnectWS) {
      return;
    }
    if (haveConnectionProblem) {
      let toastId: ReactText | null = null;
      const toastOptions: ToastOptions = {
        autoClose: false,
        closeOnClick: false,
        position: 'bottom-left',
      };
      toastId = toast.info('Trying to connect...', toastOptions);
      return () => {
        toastId && toast.dismiss(toastId);
      };
    }
  }, [haveConnectionProblem, shouldConnectWS]);

  useEffect(() => {
    if (!profileData) {
      return;
    }
    let status = '';
    switch (profileData?.userStatus) {
      case OperatorStatusType.Online:
        status = 'Online';
        break;
      case OperatorStatusType.Offline:
        status = 'Offline';
        break;
      case OperatorStatusType.InCall:
        status = 'In Call';
        break;
      case OperatorStatusType.Busy:
        status = 'Busy';
        break;
      case OperatorStatusType.Disabled:
        status = 'Disabled';
        break;
      case OperatorStatusType.Away:
        status = 'Away';
        break;
      default:
        break;
    }
    logger({ message: `User status: ${status}` });
  }, [profileData]);

  return (
    <nav className="navbar navbar-dark navbar-expand-lg site-header px-0 justify-content-between font-sz-14">
      <div className="top-nav-ctl justify-content-between">
        <button
          className={`btn btn-link text-site d-block ${
            UserAgentUtils.isWebView() ? '' : 'd-lg-none d-xl-none'
          }`}
          id="menu-toggle"
          onClick={() => {
            props.toggler(!props.toggleButton);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div
          className={`brandLogo ${UserAgentUtils.isWebView() ? 'd-none' : ''}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.35 43.69">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  fill="#faaf40"
                  d="M22.39,4.09A8.23,8.23,0,0,1,21,7.84a11,11,0,0,1-2.1,2.4,13.92,13.92,0,0,1-1.26.89c-.13-.2-.27-.38-.41-.57s-.13-.16-.18-.24,0-.06,0-.11l.2-.18A5.05,5.05,0,0,0,18.45,8.3a4.78,4.78,0,0,0,.31-1,3.33,3.33,0,0,0,.07-.54V6.53l-.22,0a3.17,3.17,0,0,1-1.95-1,3.19,3.19,0,0,1-.75-1.23A3.25,3.25,0,0,1,19.53,0a3.58,3.58,0,0,1,2.85,3.33A5.77,5.77,0,0,1,22.39,4.09Z"
                />
                <path
                  fill="#61449a"
                  d="M18.19,26.13,17.93,26l.26-.12c4.42-2,4.76-7,4.76-8.44a11.93,11.93,0,0,0-2.24-6.7,10.93,10.93,0,0,1-4.11,2.94,7.2,7.2,0,0,1,1,3.76C17.59,21.22,15,24,11.5,24s-6.14-2.68-6.14-6.52a6.72,6.72,0,0,1,1.75-4.77,5.55,5.55,0,0,1,.61-.56,6,6,0,0,1,3.78-1.27,6.2,6.2,0,0,1,2.31.44c1.53-1,2.87-3.17,2.84-4.12A10,10,0,0,0,12.75,6c-.4,0-.83,0-1.25,0A11.76,11.76,0,0,0,3.82,8.64c-.12.1-.24.2-.35.31A11.23,11.23,0,0,0,0,17.44c0,7,4.41,11.41,11.5,11.41,2.87,0,5.95,1.48,5.95,4.75,0,3-2.39,5.07-5.95,5.07s-6.06-2.11-6.14-4.93H0c.07,5.87,4.78,9.95,11.5,9.95s11.36-4,11.36-10.09A7.57,7.57,0,0,0,18.19,26.13Z"
                />
                <text
                style={{
                  fill:"#4d4d4f",
                  fontSize:"18px",
                  fontFamily:"OpenSans-Semibold, Open Sans",
                  fontWeight:"600",
                  transform:"translate(33.6 33)"
                }}

                >
                  Gateway
                </text>
              </g>
            </g>
          </svg>
        </div>
        <div className="" id="navbarSupportedContent">
          <div className="nav nav-item drop-down header-state-info position-relative text-white pr-0 notification-dd">
            <a
              className="nav-link dropdown-toggle text-white"
              id="navbarNotificationDropdwon"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => {
                batch(() => {
                  dispatch(ActionNotification.SetPageNo(1));
                  // dispatch(ActionNotification.SetNotificationShown(!notificationShown))
                });
              }}
            >
              <svg viewBox="0 0 448 512" fill="currentColor">
                <path d="M256 32V51.2C329 66.03 384 130.6 384 208V226.8C384 273.9 401.3 319.2 432.5 354.4L439.9 362.7C448.3 372.2 450.4 385.6 445.2 397.1C440 408.6 428.6 416 416 416H32C19.4 416 7.971 408.6 2.809 397.1C-2.353 385.6-.2883 372.2 8.084 362.7L15.5 354.4C46.74 319.2 64 273.9 64 226.8V208C64 130.6 118.1 66.03 192 51.2V32C192 14.33 206.3 0 224 0C241.7 0 256 14.33 256 32H256zM224 512C207 512 190.7 505.3 178.7 493.3C166.7 481.3 160 464.1 160 448H288C288 464.1 281.3 481.3 269.3 493.3C257.3 505.3 240.1 512 224 512z" />
              </svg>

              {/* <FontAwesomeIcon icon={faBell} /> */}
              {unreadCount ? (
                <sup>{unreadCount > 99 ? '99+' : unreadCount}</sup>
              ) : null}
            </a>
            <div
              className="dropdown-menu dropdown-menu-right status-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <div className="dropdown-item">
                <NotificationList />
              </div>
            </div>
          </div>

          {/* <div
          className="nav nav-item nav-link text-white btn-lg"
          role="button"
          data-toggle="collapse"
          data-target="#site-notification-panel"
          aria-expanded="false"
          aria-controls="collapseExample"
          onClick={() => {
            batch(()=> {
              dispatch(ActionNotification.SetPageNo(1))
              dispatch(ActionNotification.SetNotificationShown(!notificationShown))
            })
          } }
        >
          <FontAwesomeIcon icon={faBell} />
          <sup> {unreadCount === 0 ? null : unreadCount}</sup>
        </div> */}
          <div
            className="nav nav-item nav-link text-white position-relative notification-dd"
            role="button"
            data-toggle="collapse"
            data-target="#site-chat-panel"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => {
              setChatType(1);
              setNewChatMessageCount(0);
            }}
          >
            <svg viewBox="0 0 512 512" fill="currentColor">
              <path d="M511.1 63.1v287.1c0 35.25-28.75 63.1-64 63.1h-144l-124.9 93.68c-7.875 5.75-19.12 .0497-19.12-9.7v-83.98h-96c-35.25 0-64-28.75-64-63.1V63.1c0-35.25 28.75-63.1 64-63.1h384C483.2 0 511.1 28.75 511.1 63.1z" />
            </svg>

            {/* <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8V48H20V59L32 48H62V8H2Z" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 38H38M14 18H50H14ZM14 28H50H14Z" stroke="#fff" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg> */}

            {/* <FontAwesomeIcon icon={faComment} /> */}
            {newChatMessageCount ? (
              <sup>
                {newChatMessageCount > 99 ? '99+' : newChatMessageCount}
              </sup>
            ) : null}
          </div>
          <div className="nav nav-item drop-down header-state-info position-relative ">
            <a
              href=""
              className="nav-link dropdown-toggle"
              id="navbarUserStatusDropdwon"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="d-none d-sm-none d-md-none  d-lg-flex d-xl-flex align-items-center top-nav-user">
                <div className="header-profile-image top-nav-profile-image">
                  <img
                    src={`${FileBucketURL.Root}${userId}?${imageView.fileTimeStamp}&auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`}
                    alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                      );
                    }}
                    className="img-fluid"
                  />
                  {profileData?.userStatus === OperatorStatusType.Online ? (
                    <div className="opr-status-lg">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />
                    </div>
                  ) : profileData?.userStatus === OperatorStatusType.Busy ? (
                    <div className="opr-status-lg">
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="text-warning"
                      />
                    </div>
                  ) : (
                    <div className="opr-status-lg">
                      <FontAwesomeIcon icon={faCircle} className="text-muted" />
                    </div>
                  )}
                </div>
                <div className="ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 ml-0 text-white">
                  {user.name}
                </div>
                <div className="ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 ml-0 text-white">
                  {notification}
                </div>
              </div>
              <div className="d-flex d-sm-flex d-md-flex  d-lg-none d-xl-none align-items-center ">
                <div className="ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 ml-0 text-white">
                  <FontAwesomeIcon
                    icon={userStatus.icon}
                    className={`mr-2 bg-white rounded-circle ${userStatus.style}`}
                  />
                  {userStatus.text}
                </div>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right status-menu-right"
              aria-labelledby="navbarDropdown"
            >
              <a
                // href="javascript:void(0)"//added e.preventDefault() to the onClick function below instead of this.
                href="#"
                className={`dropdown-item ${
                  haveConnectionProblem && 'disable-interaction'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleStatusChange(OperatorStatusType.Online)
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-3 text-success bg-white rounded-circle"
                />
                Available
              </a>
              <a
                // href="javascript:void(0)"
                href="#"
                className={`dropdown-item ${
                  haveConnectionProblem && 'disable-interaction'
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleStatusChange(OperatorStatusType.Busy)
                }}
              >
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  className="mr-3 text-warning bg-white rounded-circle"
                />
                Busy
              </a>
              <div className="dropdown-divider  d-lg-block d-xl-block"></div>
              <SocketContext.Consumer>
                {(connection) => (
                  <Link
                    to={AppRouteUi.Login.Root}
                    className="dropdown-item  d-lg-block d-xl-block"
                    onClick={(event) => {
                      event.preventDefault();
                      connection.socketConnection?.close();
                      onLogout();
                    }}
                  >
                    <i className="s7 s7-power icon-call mr-3"></i>
                    Log out
                  </Link>
                )}
              </SocketContext.Consumer>
            </div>
          </div>
        </div>
        {/* <NotificationList /> */}
        {chatType !== 0 ? (
          <SidePanel setChatType={setChatType} chatType={chatType} />
        ) : null}
      </div>
    </nav>
  );
}
