import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { AppRouteUi } from "../../configs";
import { CompanyFinanceProfileTab } from '../../configs/TabsHeaders';
import { BalanceBox, NavTab } from "../../features";
import { CompanyProfileInfo } from "../../features/CompanyProfile";
import { FinanceProfileWithBalanceModalData } from "../../models";
import { useFunctionalityFinancialBalanceWithAddress } from "../hooks/functionalityFinancialBalanceWithAddress";

export function PageCompanyFinanceProfile(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const [balanceWithAddress, setBalanceWithAddress] = useState<FinanceProfileWithBalanceModalData>()

  const companyId = AppRouteUi.CompanyFinance.Profile.useParam();
  const { apiFinancialBalanceWithAddress, BalancWithAddresseUrl } = useFunctionalityFinancialBalanceWithAddress({ companyId: companyId, setLoaderProgress })

  useEffect(() => {
    setBalanceWithAddress(apiFinancialBalanceWithAddress)
  }, [apiFinancialBalanceWithAddress])
  useEffect(() => {
    if (onUpdateSuccess) {
      mutate(BalancWithAddresseUrl)
    }
  }, [onUpdateSuccess])

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="row mt-3">
        <div className="col-profile-left-width">
          <CompanyProfileInfo companyProfileData={balanceWithAddress?.companyFinanceProfileModel ?? {}}
            onUpdateSuccess={onUpdateSuccess}
            setOnUpdateSuccess={setOnUpdateSuccess} />
        </div>
        <div className="col-profile-right-width">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <BalanceBox balance={balanceWithAddress?.accountBalance ? balanceWithAddress.accountBalance : 0} />
                <NavTab
                  tabList={CompanyFinanceProfileTab}
                  activeTabName={CompanyFinanceProfileTab[0].name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}