import React from "react";
import { Modal, Table } from "react-bootstrap";
import { BookingBillsDetails } from "../../models";
import { handleNumberToTwoDecimalPoint } from "../../utils";
import { PlatformType } from "../../configs";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  spiltDuration: (duration: any) => number[];
  bookingBillsDetails?: BookingBillsDetails;
  jobPlatformType?: PlatformType
}

export function ModalViewClientBills(props: Props): JSX.Element {
  const { bookingBillsDetails, jobPlatformType } = props;
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.handleShow(false);
        }}
        size="lg"
        centered
      >
        {/*<form action="">*/}
        <div>
                  {/*@ts-ignore */}
        <Modal.Header closeButton>
            <Modal.Title>{"Client Bills Details"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Cumulative Duration: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div>
                        {
                          props.spiltDuration(
                            bookingBillsDetails?.cumulativeDuration
                          )[0]
                        }
                        h &nbsp;
                        {
                          props.spiltDuration(
                            bookingBillsDetails?.cumulativeDuration
                          )[1]
                        }
                        m
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Client Rate: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div>
                        <span className={bookingBillsDetails?.clientRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.clientRate ? bookingBillsDetails?.clientRate : 0)}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  jobPlatformType !== PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Technology Fee Rate: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className={bookingBillsDetails?.technologyFeeRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.technologyFeeRate ? bookingBillsDetails?.technologyFeeRate : 0)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                  // </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Travel Time Rate: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>
                          {bookingBillsDetails?.travelTimeRate! < 0 ?
                            <span className="text-danger">(${Math.abs(bookingBillsDetails?.travelTimeRate ? bookingBillsDetails?.travelTimeRate : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>
                            :
                            <span>{(bookingBillsDetails?.travelTimeRate ? bookingBillsDetails?.travelTimeRate : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                          } %
                        </div>
                      </div>
                    </div>
                  </div>

                }
                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>  {bookingBillsDetails?.clientMileage! < 0 ?
                          <span className="text-danger">(${Math.abs(bookingBillsDetails?.clientMileage ? bookingBillsDetails?.clientMileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span>
                          :
                          <span>{(bookingBillsDetails?.clientMileage ? bookingBillsDetails?.clientMileage : 0)?.toLocaleString('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        } miles</div>
                      </div>
                    </div>
                  </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage Rate: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className={bookingBillsDetails?.mileageRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.mileageRate ? bookingBillsDetails?.mileageRate : 0)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                  // </div>
                }
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3 ">
                    <p>
                      <strong>Client Bill: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div><span className={bookingBillsDetails?.clientBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.clientBill ? bookingBillsDetails?.clientBill : 0)}</span></div>
                    </div>
                  </div>
                </div>

                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>{jobPlatformType === PlatformType.OnSite ? "Booking Fee:" : "Connection Fee:"}</strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <div><span className={bookingBillsDetails?.bookingFee! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.bookingFee ? bookingBillsDetails?.bookingFee : 0)}</span></div>
                    </div>
                  </div>
                </div>

                {
                  jobPlatformType !== PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Technology Fee Bill: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div>
                          <span className={bookingBillsDetails?.technologyFeeRate! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.technologyFeeBill ? bookingBillsDetails?.technologyFeeBill : 0)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                  // </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Travel Time Bill: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div><span className={bookingBillsDetails?.travelTimeBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.travelTimeBill ? bookingBillsDetails?.travelTimeBill : 0)}</span></div>
                      </div>
                    </div>
                  </div>
                }

                {
                  jobPlatformType === PlatformType.OnSite && <div className="row flex-wrap">
                    <div className="col-fixed-width px-3">
                      <p>
                        <strong>Mileage Bill: </strong>
                      </p>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <div><span className={bookingBillsDetails?.mileageBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.mileageBill ? bookingBillsDetails?.mileageBill : 0)}</span></div>
                      </div>
                    </div>
                  </div>
                }


                <div className="row flex-wrap">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Total Client Bill: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <p>
                        <strong className={bookingBillsDetails?.totalClientBill! < 0 ? `${"text-danger"}` : ""}>{handleNumberToTwoDecimalPoint(bookingBillsDetails?.totalClientBill ? bookingBillsDetails?.totalClientBill : 0)}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Modal.Body>

          <Modal.Footer></Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
