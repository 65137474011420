import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';

interface ILoadingOverlay extends SpinnerProps {
  children: JSX.Element | JSX.Element[];
  active: boolean;
}

export function LoadingOverlay({ children, active, ...rest }: ILoadingOverlay) {
  return active ? (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <Spinner className="site-loader " {...rest} />
    </div>
  ) : (
    <>{children}</>
  );
}
