type Props = {
  type: string;
  value?: string;
}

export function Note(props: Props): JSX.Element {
  return (
    <>
      {
        props.value && <div className="component-card  mb-3 shadow-none">
            <div className="component-card-header border-top border-right border-left">
                <div className="">Note for {props.type}</div>
            </div>
            <div className="component-card-body border word-wrap">
                {props.value}
            </div>
        </div>
      }
    </>
  )
}