import { PlatformApi } from '../../configs/appRoute';
import { AxiosAuth } from '../../core';
import { ApiResponse, ColumnSelectionsModel } from '../../models';
import { AppConstants } from '../../configs';

type Props = {
  type: number;
};

export function useFunctionalityColumnSelections(props:Props) {
  const { type } = props;
  const pagedColumnSelectionsUrl = PlatformApi.ColumnSelections.GetColumnSelections(type);
  const LoadColumnSelections = async () => {
    const res = await AxiosAuth.get<ApiResponse<ColumnSelectionsModel[]>>(pagedColumnSelectionsUrl);
    if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data);
    }
  }
  return {
    LoadColumnSelections
  };
}
