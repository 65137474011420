import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../components";
import { Option, CompanyUpdateModel, BillingAddressResponseModel, } from "../../models";
import { useFunctionalityCountry, useFunctionalityState, useFunctionalityValidateEmail, useFunctionalityZipcodeValidation, } from "../../pages/hooks";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { DefaultCountry, reactSelectFilterConfig } from "../../configs";
import useFunctionalityValidateUSAPhoneNumber from "../../pages/hooks/functionalityValidateUSAPhoneNumber";
type Props = {
  show: boolean;
  billingAddress?: BillingAddressResponseModel;
  handleShow: (value: boolean) => void;
  onUpdateHandler: any;
  initialStateList?: any;
};

export function ModalAddressEditInfo(props: Props): JSX.Element {
  const { show, handleShow, billingAddress, onUpdateHandler, initialStateList } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<CompanyUpdateModel>();
  const { countryList } = useFunctionalityCountry(show);
  const { handleSpecialCharacterWhiteSpaces } = useFunctionalityInputChecker()
  const [selectedCountry, setSelectedCountry] = React.useState(billingAddress?.address?.countryId ? billingAddress?.address?.countryId : DefaultCountry.USA);

  const { stateList } = useFunctionalityState(selectedCountry, show);
  const onCountryChangeHandler = (value: Option | null) => {
    setSelectedCountry(value ? value.value : "");
    setValue("address.countryId", value?.value, {
      shouldValidate: true,
    });
  };
  const [selectedZipcode, setSelectZipcode] = React.useState<string>(billingAddress?.address?.zipCode ? billingAddress?.address?.zipCode : "");
  const stateName = billingAddress?.address?.stateId ? initialStateList?.find((x: any) => x.value === billingAddress?.address?.stateId) : "";
  const [selectedState, setSelectedState] = React.useState<string>();
  const [CountryFieldkey, setCountryFieldKey] = useState(Date.now());
  const [StateFieldkey, setStateFieldKey] = useState(Date.now());
  const { validateEmail, emailValidationMsg, } = useFunctionalityValidateEmail()
  const { validateTeNumber, numberErrMsg } = useFunctionalityValidateUSAPhoneNumber();
  useEffect(() => {
    setCountryFieldKey(Date.now())
    setStateFieldKey(Date.now())
  }, [countryList]);

  const handleState = (event: any) => {
    setSelectedState(event.label);
  };

  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value)
  }
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry,
    selectedState: selectedState ? selectedState : stateName?.label,
    selectedZipcode,
  });
  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode])
  return (
    <Modal show={show} size="lg" onHide={() => handleShow(false)} centered>
      <form action="" onSubmit={handleSubmit(onUpdateHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Edit billing address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="name" className="position-relative required">
                  Name
                </label>
                <input
                  type="hidden"
                  className="form-control"
                  //@ts-ignore
                  {...register('id', { required: false })}
                  aria-describedby="languageName"
                  defaultValue={billingAddress?.id} />
                <input
                  {...register('name', { required: true })}
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  defaultValue={billingAddress?.name}
                  style={{ border: errors.name ? '1px solid red' : '' }} />
                {errors.name && <FormErrorMessage message="required" />}
              </div>
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="position-relative required"
                >
                  Address
                </label>
                <input
                  {...register('address.description', {
                    required: true,

                  })}
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  defaultValue={billingAddress?.address?.description}
                  style={{ border: errors.address?.description ? '1px solid red' : '' }} />


                {errors.address?.description && <FormErrorMessage message="required" />}
              </div>

              <div className="form-group">
                <div className="row">

                  <div className="col-6">
                    <label htmlFor="city" className="position-relative required">
                      City
                    </label>
                    <input
                      type="text"
                      {...register('address.city', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces
                      })}
                      className="form-control"
                      placeholder="City"
                      defaultValue={billingAddress?.address?.city}
                      onChange={(value) => {
                        // 
                        setValue("address.city", value.target.value, {
                          shouldValidate: true,

                        });
                      }}
                      style={{ border: errors.address?.city ? '1px solid red' : '' }} />
                    {
                      errors.address?.city && errors.address?.city.type == "validate" && (
                        <FormErrorMessage message="Please enter valid value" />
                      )
                    }
                    {errors.address?.city && <FormErrorMessage message="required" />}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="stateId"
                      className="position-relative required"
                    >
                      State
                    </label>
                    <input
                      className="d-none"
                      {...register('address.stateId', { required: true })}
                      defaultValue={billingAddress?.address?.stateId}
                      style={{ border: errors.address?.stateId ? '1px solid red' : '' }} />
                    <Select
                      key={StateFieldkey}
                      placeholder="Select state"
                      // ref={selectInputRef}
                      defaultValue={
                        billingAddress?.address?.stateId
                          ? stateList.find(
                            (x) => x.value === billingAddress?.address?.stateId
                          )
                          : null
                      }
                      isDisabled={stateList.length === 0 ? true : false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={selectedCountry !== "" && stateList.length > 0 ? stateList : initialStateList}
                      filterOption={createFilter(reactSelectFilterConfig)}
                      onChange={(value) => {
                        handleState(value);
                        setValue("address.stateId", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.address?.stateId && <FormErrorMessage message="required" />}
                  </div>
                </div>
              </div>

              <div className="form-group row">

                <div className="col-6">
                  <label
                    htmlFor="zipCode"
                    className="position-relative required"
                  >
                    Zip
                  </label>
                  <input
                    type="text"
                    {...register('address.zipCode', {
                      required: true,
                      validate: handleSpecialCharacterWhiteSpaces
                    })}
                    className="form-control"
                    placeholder="Zip"
                    defaultValue={billingAddress?.address?.zipCode}
                    style={{ border: errors.address?.zipCode ? '1px solid red' : '' }}
                    onChange={(event) => { handleZip(event) }} />
                  {
                    zipCodeError && (
                      <FormErrorMessage message={zipCodeError} />
                    )
                  }
                  {
                    errors.address?.zipCode && errors.address?.zipCode.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )
                  }
                  {errors.address?.zipCode && errors.address?.zipCode.type == "required" && <FormErrorMessage message="required" />}
                </div>
                <div className="col">
                  <label
                    htmlFor="countryId"
                    className="position-relative required"
                  >
                    Country
                  </label>
                  <input
                    className="d-none"
                    {...register('address.countryId', { required: true })}
                    defaultValue={billingAddress?.address?.countryId ?? DefaultCountry.USA}
                    style={{ border: errors.address?.countryId ? '1px solid red' : '' }} />
                  <Select
                    key={CountryFieldkey}
                    placeholder="Select country"
                    isDisabled={countryList.length === 0 ? true : false}
                    defaultValue={
                      billingAddress?.address?.countryId
                        ? countryList.find(
                          (x) => x.value === billingAddress?.address?.countryId
                        )
                        : countryList?.find(
                          (x: { value: string }) =>
                            x.value === DefaultCountry.USA
                        )
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={countryList}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    onChange={(value) => {
                      onCountryChangeHandler(value);
                      setValue("address.stateId", "", {
                      });
                    }}
                  />
                  {errors.address?.countryId && (
                    <FormErrorMessage message="required" />
                  )}
                </div>
              </div>



              <div className="form-group row ">
                <div className="col-6">
                  <label htmlFor="phone" className="col-12 p-0 required">
                    Phone
                  </label>
                  <input
                    type="text"
                    {...register('address.phone', { required: true })}
                    className="form-control"
                    placeholder="Phone"
                    defaultValue={billingAddress?.address?.phone}
                    onChange={(event) => validateTeNumber(event)}
                    style={{ border: errors.address?.phone ? '1px solid red' : '' }} />
                  {errors.address?.phone && <FormErrorMessage message="required" />}
                  {
                    numberErrMsg && (
                      <FormErrorMessage message={numberErrMsg} />
                    )
                  }
                </div>
                <div className="col-6">
                  <label htmlFor="email" className="col-12 p-0 required">
                    Email
                  </label>
                  <input
                    type="text"
                    {...register('address.email', { required: true })}
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => {
                      validateEmail(e)
                    }}
                    defaultValue={billingAddress?.address?.email}
                    style={{ border: errors.address?.email ? '1px solid red' : '' }} />
                  {errors.address?.email && <FormErrorMessage message="required" />}
                  {
                    emailValidationMsg && (
                      <FormErrorMessage message={emailValidationMsg} />
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase login-from-submit-btn user-from-submit-btn"
            disabled={numberErrMsg == "" && emailValidationMsg == "" ? false : true}
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
