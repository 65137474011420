import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { shallowEqual, useDispatch } from "react-redux";
import Select from "react-select";
import { FooterButtons } from "../..";
import { StepIndicatorTabs } from "../../..";
import { FormErrorMessage } from "../../../../components";
import { ActionCompany, useAppSelector } from "../../../../core";
import { StepNumber } from "../../../../configs";
import { Option } from '../../../../models/options';
import { TypeCreateCompanyStep3n4 } from '../../../../models/api/company';
import { useFunctionalityLanguagePack } from '../../../../pages/hooks';
import { CompanyType, PlatformType } from '../../../../configs/appConstants';

type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
  parentId?: string | "";
};

type CreateCompanyStep4Form = {
  companyStep4: [
    {
      languagePackId: string;
    }
  ];
};

export function Step4CreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType, parentId } = props;
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<CreateCompanyStep4Form>();
  const dispatch = useDispatch();
  const contractPlatforms = useAppSelector((state) => state.company.companyStep3n4);
  const currentUserInfo = useAppSelector(
    (state) => ({
      companyId: state.auth.profile?.companyId || "",

    }),
    shallowEqual
  );
  const { languagePackList } = useFunctionalityLanguagePack({ companyId: parentId ?? "", shouldLanguagePackGetAllInvoke: true });
  const setStep4Value = (value: Option | null, contractPlatform: TypeCreateCompanyStep3n4, index: number) => {
    const updatedData = contractPlatforms.map((data) => {
      if (value?.value && data.platform === contractPlatform.platform) {
        data = { ...data, languagePackName: value?.label, languagePackId: value?.value };
      }
      return data;
    });
    dispatch(ActionCompany.SetCompanyStep3n4(updatedData));
    //@ts-ignore
    setValue(`companyStep4[${index}].languagePackId`, value?.value, { shouldValidate: true, });
  }
  const onSubmitStep4 = () => {
    setStepCounter(stepCounter + 1);
  };

  return <>
    <form onSubmit={handleSubmit(onSubmitStep4)} id="companyCreateForm">
      <Modal.Body>
        <div className=" component-padding border border-gry">
          <div className="row">
            <div className="col-12">
              <StepIndicatorTabs step={stepCounter || StepNumber.firstStep} companyType={companyType} />
              {
                contractPlatforms && contractPlatforms?.map((contractPlatform, index) =>
                  contractPlatform.isEnabled.toString() === "true" ? (
                    <div key={index} className="form-group">
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="" className="position-relative required language-pack-load" >
                            {PlatformType[index + 1]} Package
                          </label>
                          <input
                            className="d-none"
                            //@ts-ignore
                            {...register(`companyStep4[${index}].languagePackId`, { required: true })}
                            defaultValue={contractPlatform?.languagePackId} />
                          <Select
                            className={` ${errors.companyStep4?.[index]?.languagePackId?.type == "required" ? 'field-required' : ''} `}
                            placeholder={`Choose ${(['a', 'e', 'i', 'o', 'u'].indexOf(PlatformType[index + 1][0].toLowerCase()) !== -1) ? "an " : "a "}${PlatformType[index + 1]} package`}
                            isDisabled={languagePackList.length === 0 ? true : false}
                            // defaultValue={contractPlatforms ? languagePackList.find((x) => x.value === contractPlatform.languagePackId) : null}
                            defaultValue={contractPlatforms ? languagePackList.find((x) => x.value === contractPlatform.languagePackId) : null}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={languagePackList}
                            onChange={value => {
                              setStep4Value(value, contractPlatform, index)
                            }
                            }
                          />
                          {
                            errors.companyStep4?.[index]?.languagePackId && (
                              <FormErrorMessage message="required" />
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ) : null)
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <FooterButtons
          counter={stepCounter || StepNumber.firstStep}
          setCounter={setStepCounter}
          handleShow={handleShow}
        />
      </Modal.Footer>
    </form>
  </>;
}
