import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { APISchemaCreateOperator, ExistModel } from "../../models/api/Operator";
import { useFunctionalityOperator } from '../../pages/hooks/functionalityOpeartor';
import { useFunctionalityCheckEmailExistence } from "../../pages/hooks/functionalityCheckEmailExistence";
import { UtilsJQuery } from "../../utils";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { InputFieldValidationErrorMessage } from "../../configs";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  companyId: string;
  callCenterId: string;
};
export function ModalAddOperator(props: Props): JSX.Element {
  const { companyId, show, handleShow, callCenterId } = props;
  const { onAddCompanyOperator } = useFunctionalityOperator({ callCenterId: callCenterId, companyId: companyId });
  const { register, handleSubmit, formState: { errors } } = useForm<APISchemaCreateOperator>();
  const [email, setEmail] = useState("");
  const [retypeEmail, setRetypeEmail] = useState("");
  const { onCheckEmailExistence, emailExistMsg, setEmailExistMsg } = useFunctionalityCheckEmailExistence();
  const { useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();

  const modalHideHandler = () => {
    handleShow(false);
    setEmailExistMsg("");
  }
  const onCreateHandler = async (form: APISchemaCreateOperator) => {
    const btnLoading = UtilsJQuery.Ladda(".next-btn");
    btnLoading.start?.();
    const mail = document?.getElementById("email") as HTMLInputElement;
    const finalEmailValidation = await checkEmailExistence(mail.value);
    btnLoading.stop?.();
    form = {
      ...form,
      firstName: form.firstName.trim(),
      lastName: form.lastName.trim()
    }
    if (
      emailExistMsg === ""
      && !finalEmailValidation
    ) {
      onAddCompanyOperator(form)
        .then(() => modalHideHandler())
        .catch((e: any) => { console.error(e) });
    }
  };

  const checkEmailExistence = async (value: string) => {
    setEmail(value);
    const isEmailExist = await onCheckEmailExistence(value ?? "")?.then((res) => {
      const exist: ExistModel = res?.data!;
      return exist;
    })
      .catch((e: any) => {
        console.error(e);
      });
    return isEmailExist;
  }
  return (
    <Modal
      show={show}
      onHide={modalHideHandler}
      centered
      id="addCompanyOperatorModal"
    >
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>Add Operator</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onCreateHandler)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Email
                </label>
                <input
                  type="text"
                  {...register('email', { required: true })}
                  id="email"
                  className="form-control"
                  placeholder="email@example.com"
                  onBlur={(e) => {
                    checkEmailExistence(e.target.value);
                  }} />
                {emailExistMsg && (
                  <FormErrorMessage message={emailExistMsg} />
                )}
                {errors.email && <FormErrorMessage message="required" />}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Retype Email
                </label>
                <input
                  type="text"
                  name="retypeEmail"
                  className="form-control"
                  placeholder="email@example.com"
                  onBlur={(e) => {
                    setRetypeEmail(e.target.value);
                  }}
                />
                {email !== retypeEmail && (
                  <FormErrorMessage
                    message={retypeEmail ? "Please enter the email again" : ""}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  First Name
                </label>
                <input
                  type="text"
                  {...register(
                    'firstName',
                    { required: true, validate: useFunctionlityInputFieldValidation },
                  )}
                  className="form-control" />
                {errors.firstName?.type === "required" && <FormErrorMessage message="required" />}
                {errors.firstName?.type === "validate" && <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Last Name
                </label>
                <input
                  type="text"
                  {...register(
                    'lastName',
                    { required: true, validate: useFunctionlityInputFieldValidation },
                  )}
                  className="form-control" />
                {errors.lastName?.type === "required" && <FormErrorMessage message="required" />}
                {errors.lastName?.type === "validate" && <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />}
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={modalHideHandler}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase company-user-from-submit-btn"
          >
            Create User
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
