import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { AssetSvg } from '../../assets';

export function DownloadPage() {
  const windowsAppLink = process.env.REACT_APP_DOWNLOAD_LINK_DESKTOP_WINDOWS;
  const macAppLink = process.env.REACT_APP_DOWNLOAD_LINK_DESKTOP_MAC;
  const androidAppLink = process.env.REACT_APP_DOWNLOAD_LINK_ANDROID;
  const iOSAppLink = process.env.REACT_APP_DOWNLOAD_LINK_IOS;
  const history = useHistory();

  return (
    <div id="download-page">
      <div className="card border-radius-xl bg-white overflow-hidden">
        <div className="card-header border-bottom-0 bg-white">
          <button className="btn btn-link text-site text-decoration-none" onClick={() => history.goBack()}>
            <span><FontAwesomeIcon icon={faChevronLeft} /></span>
            <span className="mx-1">Back</span>
          </button>
        </div>
        <div className="card-body bg-white">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <div className="logo-container mx-auto">
                <AssetSvg.VRILogo />
              </div>
            </div>
            <div className="col-12 text-center font-700">
              <h3 className="my-3">Download Gateway To Access App</h3>
            </div>
            <div className="col-12 text-center font-600">
              <p className="text-muted">
                Please use the following options to download the supported
                application.
              </p>
            </div>
            <div className="col-12 mt-3 mb-4 ">
              <div className="row  justify-content-center custom-btn-container mx-0">
                <a
                  className="btn btn-outline-purple font-sz-18 mx-sm-3 mb-3 btn-xl-block btn-sm-block"
                  href={windowsAppLink}
                  target="_blank"
                >
                  <AssetSvg.WindowsLogo className="mr-2" />
                  Windows App
                </a>

                <a
                  className="btn btn-outline-purple font-sz-18 mx-sm-3 mb-3 btn-xl-block btn-sm-block"
                  href={macAppLink}
                  target="_blank"
                >
                  <AssetSvg.AppleLogo className="mr-2 mb-1" />
                  Mac OS App
                </a>

                <a
                  className="btn btn-outline-purple font-sz-18 mx-sm-3 mb-3 btn-xl-block btn-sm-block"
                  href={androidAppLink}
                  target="_blank"
                >
                  <AssetSvg.AndroidLogo className="mr-2 mb-1" />
                  Android App
                </a>

                <a
                  className="btn btn-outline-purple font-sz-18 mx-sm-3 mb-3 btn-xl-block btn-sm-block"
                  href={iOSAppLink}
                  target="_blank"
                >
                  <AssetSvg.AppleStoreLogo className="mr-2" /> iOS App</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
