import { useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { AppRouteUi } from "../../configs";
import { CompanyFinanceProfileTab, OperatorFinanceProfileTab } from "../../configs/TabsHeaders";
import { BalanceBox, NavTab } from "../../features";
import { OperatorProfileInfo } from "../../features/OperatorProfile/operatorProfileInfo";
import { useFunctionalityFinancialBalanceWithAddress } from "../hooks/functionalityFinancialBalanceWithAddress";

export function PageOperatorFinanceProfile(): JSX.Element {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const userId = AppRouteUi.OperatorFinance.Profile.useParam();

  const { apiFinancialBalanceWithAddress, BalancWithAddresseUrl } = useFunctionalityFinancialBalanceWithAddress({ operatorUserId: userId, setLoaderProgress })
  useEffect(() => {
    if (onUpdateSuccess) {
      mutate(BalancWithAddresseUrl)
    }
  }, [onUpdateSuccess])

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="row mt-3">
        <div className="col-profile-left-width">
          <OperatorProfileInfo
            userProfileData={apiFinancialBalanceWithAddress?.operatorFinanceProfileModel ?? {}}
            onUpdateSuccess={onUpdateSuccess}
            setOnUpdateSuccess={setOnUpdateSuccess}
          />
        </div>
        <div className="col-profile-right-width">
          <div className="row">
            <div className="col-12">
              <div className="profile-tab-container">
                <BalanceBox balance={apiFinancialBalanceWithAddress?.accountBalance ?? 0} />
                <NavTab
                  tabList={OperatorFinanceProfileTab}
                  activeTabName={CompanyFinanceProfileTab[0].name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
