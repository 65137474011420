import React from "react";
import { AssetSvg } from "../../../assets";
import { Modals } from "../../../configs";
import { BootstrapUtils } from "../../../utils";
type Props = {
	onToggle: () => void,
	sharing: boolean,
	screenShareStatus: boolean,
}
export function ToggleScreenShareButton(props: Props) {
	const { onToggle, sharing, screenShareStatus } = props;
	return (
		<>
			{!sharing ?
				<button
					type="button"
					className="btn btn-opaque conference-control-btn"
					data-toggle="modal"
					aria-pressed="false"
					title={screenShareStatus? "Screen share running" : "Share your screen"}
					// data-target={BootstrapUtils.GetSelectorById(Modals.ScreenShare)}
					onClick={!screenShareStatus ? onToggle : () => { console.log("Nothing happend") }}
					disabled={screenShareStatus}
				>
					{!screenShareStatus ? <AssetSvg.ScreenShareOn /> :		
						<AssetSvg.ScreenShareRunning />}
				</button>
				:

			<button
				type="button"
				className="btn btn-opaque conference-control-btn"
				data-toggle="modal"
				aria-pressed="false"
				title="Stop share"
				// data-target={BootstrapUtils.GetSelectorById(Modals.ScreenShare)}
				onClick={onToggle}
				style={{"background":"red"}}
			>
			<AssetSvg.ScreenShareEnd />
			</button>
}

		</>
	);
}
