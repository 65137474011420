import { CompanyType } from '../configs';
import { TSliceCompany } from '../core/Redux/Slices/sliceCompany';
import { ApiSchemaCreateCompany, TypeContactPlatforms } from '../models/api/company';

export const companyMapper = (formData: TSliceCompany, companyType: CompanyType, parentId?: string) => {
  const unMappedFormData: TSliceCompany = JSON.parse(JSON.stringify(formData));

  const mappedCompanyCreationData: ApiSchemaCreateCompany = {
    name: unMappedFormData.companyStep1?.name ?? '',
    type: companyType,
    parentId: parentId ?? "",
    code: unMappedFormData.companyStep1?.code ?? '',
    address: {
      description: unMappedFormData.companyStep1?.address ?? '',
      countryId: unMappedFormData.companyStep1?.countryCode ?? '',
      stateId: unMappedFormData.companyStep1?.stateCode ?? '',
      city: unMappedFormData.companyStep1?.city ?? '',
      zipCode: unMappedFormData.companyStep1?.zipCode ?? '',
      email: "",
      fax: "",
      phone: "",
      website: ""
    },
    subscription: {
      type: unMappedFormData.companyStep2?.type ? parseInt(unMappedFormData.companyStep2.type.toString()) : 0,
      startDate: unMappedFormData.companyStep2?.startDate ?? null,
      paymentCycle: unMappedFormData.companyStep2?.paymentCycle ? parseInt(unMappedFormData.companyStep2.paymentCycle.toString()) : 0,
      subscriptionRate: unMappedFormData.companyStep2?.subscriptionRate ? parseInt(unMappedFormData.companyStep2.subscriptionRate) : null,
      creditLimit: unMappedFormData.companyStep2?.creditLimit ? parseInt(unMappedFormData.companyStep2.creditLimit) : 0,
    },
    contactPlatforms: unMappedFormData.companyStep3n4
      .filter((arr) => arr.isEnabled === true)
      .map((arr) => {
        const mappedArrayData: TypeContactPlatforms = {
          platform: arr.platform,
          isEnabled: arr.isEnabled,
          connectionFee: arr.connectionFee,
          technologyFee: arr.technologyFee,
          languagePackId: arr.languagePackId,
        }
        return mappedArrayData;
      }),
    contactPersonDetails: {
      firstName: unMappedFormData.companyStep5?.firstName ?? '',
      lastName: unMappedFormData.companyStep5?.lastName ?? '',
      contactNumber: unMappedFormData.companyStep5?.contactNumber ?? '',
      email: unMappedFormData.companyStep5?.email ?? '',
    },
  };

  return mappedCompanyCreationData;
}