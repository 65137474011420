import { ColumnSelectionsModel } from "../../models";

export const UserPageColumns: ColumnSelectionsModel[] = [
  { name: "Id", propertyName: "Id", isEnabled: false },
  { name: "Name", propertyName: "Name", isEnabled: true },
  { name: "Email", propertyName: "Email", isEnabled: true },
  { name: "Department", propertyName: "DepartmentName", isEnabled: true },
  { name: "Location", propertyName: "LocationName", isEnabled: true },
  { name: "Role", propertyName: "UserRole", isEnabled: true },
  { name: "Status", propertyName: "Status", isEnabled: true },
];
