type Props = {
  handleShow: (value: boolean) => void,
  setDataToEdit?: any,
  handleSubmit?: any,
  disbaled?:any,
  setDisabled?:any,
  operationName?: string,
  zeroRateLanguagePack?: boolean
}

export function ModalFooter(props: Props): JSX.Element {
  const {operationName, zeroRateLanguagePack} = props;

  const saveOrAdd = (footerName: string) => {
    if(footerName === 'Copy' || footerName === 'Update') return 'Save';
    return 'Add';
  }

  const handleButtonClick = (event: any) => {
    event.preventDefault();
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-link text-site text-uppercase"
        onClick={() => {
          props?.setDataToEdit?.(undefined)
          props.handleShow(false)
        }}
      >
        Cancel
      </button>
      <button
        type="submit"
        disabled={props?.disbaled}
        className="login-from-submit-btn btn btn-primary text-uppercase"
        onClick= {zeroRateLanguagePack ? handleButtonClick : props.handleSubmit}
      >
        {/* {props.setDataToEdit ? 'Update' : 'Add'} */}
        {operationName ? saveOrAdd(operationName) : 'Add'}
      </button>
    </>
  )
}