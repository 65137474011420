import axios from "axios";
import React, { useCallback, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  PaginationSettings,
  PlatformApi,
  topLoaderProgress,
} from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  ApiSchemaChangeStatus,
  ApiSchemaLanguageData,
  ApiSchemaUpdatePassword,
  ApiSchemaUpdateTin,
  Option,
  SelectModel,
  WithPagination,
} from "../../models";
import { ApiSchemaFilter } from "../../models/api/filter";
import {
  APISchemaCreateOperator,
  ApiSchemaFilterOperatorRequest,
  ApiSchemaOperatorNames,
  ApiSchemaOperatorResponseData,
  FilterOperatorResponseModel,
  OperatorRatingRequest,
  OperatorRatingUpdateRequest,
} from "../../models/api/Operator";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { ApiSchemaOperatorPagedResponseData } from "./../../models/api/Operator";

type Props = {
  callCenterId: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
  filterData?: ApiSchemaFilter;
  filterDataChange?: number;
};
let pagedOperatorUrl: string;
export function useFunctionalityPagedOperator(props: Props) {
  const {
    searchText,
    setLoaderProgress,
    callCenterId,
    pageNo,
    pageSize,
    filterData,
    filterDataChange,
  } = props;
  const [pagedListCount, setPagedListCount] = useState<number>(0);
  const [totalOnline, setTotalOnline] = useState<number>(0);
  const [totalInCall, setTotalInCall] = useState<number>(0);
  const [totalOperator, setTotalOperator] = useState<number>(0);
  const [operatorTableData, setOperatorTableData] =
    useState<ApiSchemaOperatorResponseData[]>();

  pagedOperatorUrl = PlatformApi.Operator.GetAll(
    callCenterId,
    pageSize,
    pageNo,
    searchText,
    filterDataChange
  );
  const { data: apiOperatorList } = useSWR<ApiSchemaOperatorPagedResponseData>(
    callCenterId ? pagedOperatorUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.post<ApiResponse<ApiSchemaOperatorPagedResponseData>>(
        pagedOperatorUrl,
        filterData
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedListCount(r.data.data.count);
          setTotalOperator(r.data.data.totalOperator);
          setTotalInCall(r.data.data.totalInCall);
          setTotalOnline(r.data.data.totalOnline);
          setOperatorTableData(r.data.data.operatorResponse);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  // const operatorList = useMemo(() => {
  //   const data = Array.isArray(apiOperatorList?.operatorResponse)
  //     ? apiOperatorList?.operatorResponse
  //     : [];
  //   return data;
  // }, [apiOperatorList?.operatorResponse]);

  return {
    totalOnline,
    totalInCall,
    totalOperator,
    operatorList: operatorTableData,
    pagedListCount,
    pagedOperatorUrl,
  };
}

type OperatorProps = {
  callCenterId?: string;
  companyId?: string;
  platform?: any;
  languageId?: any;
  genderType?: any;
  skillId?: any;
};
export function useFunctionalityOperator(props: OperatorProps) {
  const { callCenterId, companyId, platform, languageId, genderType, skillId } =
    props;

  const filteredOperatorList = useCallback(
    async (form: ApiSchemaFilterOperatorRequest, platform: number) => {
      const btnLoading = UtilsJQuery.Ladda(".company-user-from-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.post<
          ApiResponse<FilterOperatorResponseModel>
        >(PlatformApi.Operator.FilterOperator(platform), form);
        if (res.status === AppConstants.Api.StatusOK) {
          return Promise.resolve(res.data);
        }
      } catch (e) {
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );
  const inCallOperatorList = useCallback(async (callId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".company-user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await axios.get(
        PlatformApi.Operator.InCallFilterOperator(callId)
      );

      if (res?.status === AppConstants.Api.StatusOK) {
        return Promise.resolve(res?.data);
      }
    } catch (e) {
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const onAddCompanyOperator = useCallback(
    async (form: APISchemaCreateOperator) => {
      const btnLoading = UtilsJQuery.Ladda(".company-user-from-submit-btn");
      btnLoading.start?.();
      form = { ...form, companyId: companyId || "" };
      try {
        const res = await AxiosAuth.post<APISchemaCreateOperator>(
          PlatformApi.Operator.Create(),
          form
        );
        if (res.status === AppConstants.Api.StatusOK) {
          mutate(pagedOperatorUrl);
          toast.success("Operator added successfully");
        }
      } catch (e) {
        toast.error("Adding user failed");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onForceOffline = useCallback(async (userId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".cofirm-button");
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.post(
        PlatformApi.Authentication.ForceOffline.Root(userId)
      );
      mutate(pagedOperatorUrl);
      return response.data;
    } catch (error) {
      console.error(error);
    }

    btnLoading.stop?.();
  }, []);

  const onChangeStatus = useCallback(async (form: ApiSchemaChangeStatus) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.put(PlatformApi.Users.ChangeStatus(), form);

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedOperatorUrl);
        toast.success("Status changed");
      }
    } catch (e) {
      toast.error("Failed to change status");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onUpdatePassword = useCallback(
    async (form: ApiSchemaUpdatePassword) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.put(
          PlatformApi.Users.UpdatePassword(),
          form
        );

        if ((res.status = AppConstants.Api.StatusOK)) {
          mutate(pagedOperatorUrl);
          toast.success("Password Updated");
        }
      } catch (e) {
        toast.error("Failed Updating Password");
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onDeleteUser = useCallback(async (userId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.delete(
        PlatformApi.Users.Delete(userId || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedOperatorUrl);
        toast.success("User Deleted");
      }
    } catch (e) {
      toast.error("User Delete Failed");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const userTinData = useCallback(async (userId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.get<ApiResponse<string>>(
        PlatformApi.Tin.GetUserTinById(userId || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onUpdateTin = useCallback(async (form: ApiSchemaUpdateTin) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.post(
        PlatformApi.Tin.UpdateUserTin(form.userId || "", form.tin || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedOperatorUrl);
        toast.success("Tin changed");
      }
    } catch (e) {
      toast.error("Failed to change tin");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onCreateOperatorRating = useCallback(
    async (form: OperatorRatingRequest) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");

      btnLoading.start?.();
      try {
        const res = await AxiosAuth.post(
          PlatformApi.Operator.CreateOperatorRating(),
          form
        );
        if ((res.status = AppConstants.Api.StatusOK)) {
          mutate(pagedOperatorUrl);
          toast.success("Rating Added!");
        }
      } catch (e) {
        toast.error("Failed to add rating");
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const onUpdateOperatorRating = useCallback(
    async (
      form: OperatorRatingUpdateRequest,
      operatorRatingId: string,
      operatorId: string
    ) => {
      let pagedOperatorRatingUrl: string;
      pagedOperatorRatingUrl = PlatformApi.Operator.GetOperatorRatings(
        operatorId,
        PaginationSettings.initialPageSize,
        PaginationSettings.initialPageIndex,
        ""
      );

      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.put(
          PlatformApi.Operator.UpdateOperatorRating(operatorRatingId),
          form
        );
        if ((res.status = AppConstants.Api.StatusOK)) {
          mutate(pagedOperatorUrl);
          mutate(pagedOperatorRatingUrl);
          toast.success("Rating Updated!");
        }
      } catch (e) {
        toast.error("Failed to Update rating");
      }
      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );

  const operatorNamesUrl = PlatformApi.Operator.OperatorNames(
    platform,
    languageId,
    genderType,
    skillId
  );
  const { data: apiOperatorNames } = useSWR<Option[]>(operatorNamesUrl, () =>
    AxiosAuth.get<ApiResponse<WithPagination<Option[]>>>(operatorNamesUrl)
      .then((r) => {
        return r.data?.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const optionMappedOperatorNames = React.useMemo(() => {
    const data = Array.isArray(apiOperatorNames) ? apiOperatorNames : [];
    return data;
  }, [apiOperatorNames]);

  const inOperatorRatingList = useCallback(
    async (
      operatorId: string,
      pageSize: number,
      pageNo: number,
      searchText: string
    ) => {
      const btnLoading = UtilsJQuery.Ladda(".company-user-from-submit-btn");
      btnLoading.start?.();

      try {
        const res = await axios.get(
          PlatformApi.Operator.GetOperatorRatings(
            operatorId,
            pageSize,
            pageNo,
            searchText
          )
        );

        if (res?.status === AppConstants.Api.StatusOK) {
          return Promise.resolve(res?.data);
        }
      } catch (e) {
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onDeleteOperatorRating = React.useCallback(
    async (ratingId: string, operatorId: string) => {
      let pagedOperatorRatingUrl: string;
      pagedOperatorRatingUrl = PlatformApi.Operator.GetOperatorRatings(
        operatorId,
        PaginationSettings.initialPageSize,
        PaginationSettings.initialPageIndex,
        ""
      );
      const btnLoading = UtilsJQuery.Ladda(".rating-delete");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.delete(
          PlatformApi.Operator.DeleteOperatorRating(ratingId ?? "")
        );

        if (response.status === AppConstants.Api.StatusOK) {
          mutate(pagedOperatorRatingUrl);
          toast.success("Rating Deleted Successful");
        }
        return Promise.resolve(response);
      } catch (e) {
        toast.error("Rating Deletion Failed");
      }
      btnLoading.stop?.();
    },
    []
  );

  return {
    filteredOperatorList,
    inCallOperatorList,
    onAddCompanyOperator,
    onChangeStatus,
    onForceOffline,
    onUpdatePassword,
    onDeleteUser,
    onUpdateTin,
    userTinData,
    onCreateOperatorRating,
    onUpdateOperatorRating,
    inOperatorRatingList,
    optionMappedOperatorNames,
    onDeleteOperatorRating,
  };
}
export function useFunctionalityOperatorNames() {
  const onOperatorNames = useCallback(async () => {
    const operatorNamesUrl = PlatformApi.Operator.GetOperatorNames();
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(operatorNamesUrl);

      if (res.status === AppConstants.Api.StatusOK) {
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onOperatorNames,
  };
}

export function useFunctionalityOperatorNamesByCallCenterId() {
  const onOperatorNamesByCallCenterId = useCallback(
    async (callCenterId: string) => {
      const operatorNamesByCallCenterIdUrl =
        PlatformApi.Operator.GetOperatorNamesByCallCenterId(callCenterId);
      try {
        const res = await AxiosAuth.get<ApiResponse<SelectModel[]>>(
          operatorNamesByCallCenterIdUrl
        );

        if (res.status === AppConstants.Api.StatusOK) {
        }
        return Promise.resolve(res.data);
      } catch (e) {
        return Promise.reject(null);
      }
    },
    []
  );

  return {
    onOperatorNamesByCallCenterId,
  };
}
