import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { AppConstants, topLoaderProgress } from "../../configs";
import { ActionAuth, AxiosAuth } from "../../core";
import { UtilsJQuery } from "../../utils";
import { AppRouteUi, PlatformApi } from "../../configs/appRoute";
import { useAppSelector } from "../../core/Redux/ReduxStore";
import { ApiResponse, ApiSchemaMenuSettingsData } from "../../models";
import useSWR from "swr";
import { useDispatch } from "react-redux";

export function useFunctionalityUserProfile() {
  const dispatch = useDispatch();
  const onUploadPhoto = useCallback(async (userId: string, form: FormData) => {
    const profilePhotoUrl = PlatformApi.Users.UploadProfilePhoto(userId ?? "");
    const btnLoading = UtilsJQuery.Ladda(".upload-button");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.post<FormData>(profilePhotoUrl, form);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(urlToMutate);
        toast.success("Uploaded successfully");
      }
    } catch (e) {
      toast.error("Upload failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);
  const getMenusettings = useCallback(async () => {
    const menuSettingsUrl = PlatformApi.Users.GetUserMenuSettings();
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(menuSettingsUrl);
      if (res.status === AppConstants.Api.StatusOK) {
        dispatch(ActionAuth.SetMenuSettingsData(res.data.data.menuSettings));
        return res.data.data.menuSettings;
      }
    } catch (e) {}
    return Promise.resolve(null);
  }, []);

  return {
    onUploadPhoto,
    getMenusettings,
  };
}
export function useFunctionalityMenuSettings(props: {
  setLoaderProgress: any;
}) {
  const dispatch = useDispatch();
  var { setLoaderProgress } = props;
  let isDataFetched = false;
  const menuSettingsUrl = PlatformApi.Users.GetUserMenuSettings();
  const { data: menuSettingsData } = useSWR<ApiSchemaMenuSettingsData>(
    menuSettingsUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<any>>(menuSettingsUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          dispatch(ActionAuth.SetMenuSettingsData(r.data.data.menuSettings));
          return r.data.data.menuSettings;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const menuSettings = useMemo(() => {
    const data = menuSettingsData != undefined ? menuSettingsData : null;
    return data;
  }, [menuSettingsData]);
 
  return {
    menuSettings,
  };
}
