export function searchInCurrentURL(searchText: string): boolean {
  let result = false;
  const path = new URL(window.location.href).pathname;

  if (path.search(searchText) !== -1) {
    result = true;
  }

  return result;
}
