import React from "react";
import { getState } from '../../utils/zipCodeChecker'
import { DefaultCountry } from "../../configs";

type Props = {
    selectedCountry: string;
    selectedState: string;
    selectedZipcode: string;
};
export function useFunctionalityZipcodeValidation(props: Props) {
    const { selectedCountry, selectedState, selectedZipcode } = props;
    const [zipCodeError, setZipCodeError] = React.useState<string>('');
    const handleZipCode = () => {
        if (selectedZipcode && selectedCountry === DefaultCountry.USA) {
            const stateName = getState(selectedZipcode)

            if ((selectedState && selectedState !== stateName) || !stateName) {
                setZipCodeError("The specified US ZIP Code is invalid")
                return
            }
            else {
                setZipCodeError('')
            }
        }
        else {
            setZipCodeError('')
        }
    }
    return {
        zipCodeError,
        handleZipCode
    }
}