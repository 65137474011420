import { useCallback } from "react";
import { toast } from "react-toastify";
import { AppConstants } from "../../configs/appConstants";
import { VriApi } from "../../configs/appRoute";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaConsumerConference } from "../../models";
import { UtilsJQuery } from "../../utils";

export function useFunctionalityConsumer() {

  const onGetConferenceList = useCallback( 
    (consumerId: string, pageSize: number, pageNo: number, searchText: string, setList?: any,list?:any,setConfCount?:any, setLoading?: any) => {
      const conferenceUrl = VriApi.Conference.GetConferencesByConsumer.Root(consumerId, pageNo, pageSize, searchText);
      const btnLoading = UtilsJQuery.Ladda(".load-more-btn");
      btnLoading.start?.();
      AxiosAuth.get<ApiResponse<ApiSchemaConsumerConference>>(conferenceUrl)
        .then((res) => {
          if (res?.status === AppConstants.Api.StatusOK) {
            setLoading(false)
            if (pageNo == 1){
              setList(res.data.data?.data)
              setConfCount(res.data.data?.count)
            }
             
            else
            concateDate(list,setList,res.data.data?.data)
            btnLoading.stop?.();
            
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error(e);
          console.log(e);
          setLoading(false)
        });
    },
    []
  );

  const concateDate = (list:any, setList:any, newdata:any) => {
    let data:any = [];
    data = list.slice()
    newdata.forEach((item:any)=>{
      data.push(item)
    })
    setList(data)
  }

  return {
    onGetConferenceList,

  };
}
