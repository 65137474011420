import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select, { createFilter } from "react-select";
import OptionsType from "react-select";
import { FormErrorMessage } from "../../components";
import { InputFieldValidationErrorMessage, PlatformType, reactSelectFilterConfig } from "../../configs";
import { useAppSelector } from "../../core/Redux/ReduxStore";
import { ApiSchemaCreateLanguagePack, ApiSchemaLanguagePackData, Option, TypeLanguageList, } from "../../models";
import { useFunctionalityLanguage, useFunctionalityLanguagePack, } from "../../pages/hooks";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { ModalFooter } from "../Common";
import { toast } from "react-toastify";
import { AssetSvg } from "../../assets";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  dataToEdit: ApiSchemaLanguagePackData;
  companyId: string;
  onUpdateSuccess?: boolean;
  setOnUpdateSuccess: any;
  selectedPlatformType?: number;
  isCopyLanguagePack?: boolean;
  onCopyLanguagePackHandler?: any;
};
const languageRateMap = new Map();

export function ModalLanguagePack(props: Props): JSX.Element {
  const { show, handleShow, dataToEdit, companyId, selectedPlatformType, isCopyLanguagePack, onCopyLanguagePackHandler, onUpdateSuccess, setOnUpdateSuccess, } = props;
  const { register, handleSubmit, formState: { errors } } = useForm<ApiSchemaCreateLanguagePack>();
  const vriCompanyId = useAppSelector((state) => state.auth.profile?.companyId) ?? "";
  const [zeroRateState, setZeroRateState] = useState<any>([]);
  const [languageFlatRateError, setLanguageFlatRateError] = useState<string>("");
  const [disableProp, setDisableProp] = useState<boolean>(false);
  const [flatRateToggle, setFlatRateToggle] = useState<boolean>(true);

  let { languageList } = useFunctionalityLanguage({
    plaformType: PlatformType.All,
    shouldLanguageGetAllInvoke: show,
  });

  if (selectedPlatformType === PlatformType.OPI) { languageList = languageList.filter((language) => language.label !== "ASL"); }
  const languageListForSelect = [
    { label: "Select All", value: "all" },
    ...languageList,
  ];
  const { onLanguagePackNameIsExists, onSubmit } = useFunctionalityLanguagePack({ companyId: companyId, });
  let tempOption: Option[] = [];
  let tempLanguageList: TypeLanguageList[] = [];
  const [listOfLanguages, setListOfLanguages] = useState<TypeLanguageList[]>(tempLanguageList);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(tempOption);

  const {
    numberFieldValidation,
    handleCopyPasteInvalidValue,
    handleLimitDecimalPlaces,
    useFunctionlityInputFieldValidation
  } = useFunctionalityInputChecker();

  useEffect(() => {
    languageRateMap.clear();
    if (dataToEdit?.languageRates) {
      tempOption = dataToEdit?.languageRates.map((t) => {
        return {
          value: t.languageId,
          label: t.languageName,
        };
      });
      tempLanguageList = dataToEdit?.languageRates.map((option) => {
        return {
          rate: option?.rate ? option.rate : 0.00,
          languageId: option.languageId,
          languageName: option.languageName,
        };
      });

      let initialRates: any = [];
      dataToEdit?.languageRates?.forEach((option, index) => {
        languageRateMap.set(option.languageId, option?.rate ? option.rate : 0.00);
        initialRates = [
          ...initialRates,
          {
            name: `languageRates[${index}].rate`,
            value: option?.rate,
          },
        ];
      });
      setZeroRateState(initialRates);
    }

    dataToEdit && dataToEdit.flatRate > 0
      ? setFlatRateToggle(true)
      : setFlatRateToggle(false);

    setSelectedOptions(tempOption);
    setListOfLanguages(tempLanguageList);
  }, [dataToEdit]);

  const doCopyUpdateOrAddLanguagePack = (form: ApiSchemaCreateLanguagePack) => {
    const isEmptyObject = Object.keys(dataToEdit).length === 0 && dataToEdit.constructor === Object;
    if (isEmptyObject) {
      onLanguagePackNameIsExists(vriCompanyId, form?.name)?.then((res) => {
        if (res) {
          toast.error("Language pack already exists");
          handleShow(false);
          return;
        }
        onSubmit(form).then((res) => {
          setOnUpdateSuccess?.(!onUpdateSuccess);
          handleShow(false);
        });
      });
    } else {
      if (isCopyLanguagePack) {
        onLanguagePackNameIsExists(vriCompanyId, form?.name)?.then((res) => {
          if (res) {
            toast.error("Language pack already exists");
            handleShow(false);
            return;
          }
          onSubmit(form).then((res) => {
            setOnUpdateSuccess?.(!onUpdateSuccess);
            handleShow(false);
            onCopyLanguagePackHandler?.(res?.id ?? "", dataToEdit?.id);
          });
        });
      } else {
        if (form?.name !== dataToEdit?.name) {
          onLanguagePackNameIsExists(vriCompanyId, form?.name)?.then((res) => {
            if (res) {
              toast.error("Language pack already exists");
              handleShow(false);
              return;
            }
            onSubmit(form, dataToEdit.id).then(() => {
              handleShow(false);
              setOnUpdateSuccess?.(!onUpdateSuccess);
            });
          });
        } else {
          onSubmit(form, dataToEdit.id).then(() => {
            handleShow(false);
            setOnUpdateSuccess?.(!onUpdateSuccess);
          });
        }
      }
    }
  };

  const onSubmitHandler = (form: ApiSchemaCreateLanguagePack) => {
    const languageRate: TypeLanguageList[] = form.languageRates ? form.languageRates : listOfLanguages;
    form = {
      ...form,
      flatRate: form.flatRate && parseFloat(form.flatRate.toString()),
      name: form.name.trim(),
      languageRates: languageRate.map((t) => {
        return {
          languageId: t.languageId,
          languageName: t.languageName,
          rate: parseFloat(t.rate?.toString() ?? "0"),
        };
      }),
      vendorCompanyId: isCopyLanguagePack ? vriCompanyId : companyId,
    };
    doCopyUpdateOrAddLanguagePack(form);
  };

  const handleFlatRateButtonToggle = (previousToggle: boolean) => {
    setFlatRateToggle(!flatRateToggle);
    if (!previousToggle && dataToEdit?.flatRate <= 0 || dataToEdit?.flatRate === undefined) {
      setDisableProp(true);
      setLanguageFlatRateError("flat rate must be greater than zero");
    } else {
      setDisableProp(false);
      setLanguageFlatRateError("");
    }
  };

  const handleFlatRate = (event: any) => {
    const value = event.target.value;
    if (value.length == 0) {
      setLanguageFlatRateError("")
    }
    if (Number(value) <= 0) {
      setDisableProp(true);
      setLanguageFlatRateError("flat rate must be greater than zero");
    } else {
      setDisableProp(false);
      setLanguageFlatRateError("");
    }
  };

  //@ts-ignore
  const handleAddLanguage = (selected: OptionsType<Option>) => {
    const selectedOptions: any =
      selected.length && selected.find((option:any) => option.value === "all")
        ? languageListForSelect.slice(1)
        : selected;
    setSelectedOptions(selectedOptions);
    const list: TypeLanguageList[] =
      selected.length && selected.find((option:any) => option.value === "all")
        ? languageListForSelect.slice(1).map((option) => {
          return {
            rate: 0,
            languageId: option.value,
            languageName: option.label,
          };
        })
        : selected.map((option:any) => {
          const languageAlreadyExists = listOfLanguages?.find(
            (language) => language?.languageId === option?.value
          );
          return {
            rate: languageAlreadyExists ? languageAlreadyExists?.rate : 0,
            languageId: option.value,
            languageName: option.label,
          };
        });

    setListOfLanguages(list);
    let selectAllRateState: any = [];
    list?.forEach((option, index) => {
      const previousRate = languageRateMap?.has(option?.languageId)
        ? languageRateMap?.get(option?.languageId)
        : option?.rate;
      languageRateMap?.set(option?.languageId, previousRate);
      selectAllRateState = [
        ...selectAllRateState,
        {
          name: `languageRates[${index}].rate`,
          value: previousRate ? previousRate : option?.rate,
        },
      ];
    });

    setZeroRateState(selectAllRateState);
  };

  const handleRateChange = (event: any, languageId: string) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    const newState = [
      ...zeroRateState?.filter((zeroRate: any) => zeroRate?.name !== name),
      {
        name: name,
        value: value,
      },
    ];
    setZeroRateState(newState);
    languageRateMap.set(languageId, value ? value : 0.00);
  };
  console.log("dataToEditselectedOptions",listOfLanguages,selectedOptions)
  const onRemoveItem = (id: string) => {
    languageRateMap?.delete(id);
    setSelectedOptions((selectedOptions) =>
      selectedOptions.filter((t) => {
        return t.value !== id;
      })
    );

    const listAfterRemove = listOfLanguages?.filter(
      (language) => language?.languageId !== id
    );
    setListOfLanguages(listAfterRemove);

    let rateStateAfterRemoveLanguage: any = [];
    listAfterRemove?.forEach((option, index) => {
      rateStateAfterRemoveLanguage = [
        ...rateStateAfterRemoveLanguage,
        {
          name: `languageRates[${index}].rate`,
          value: option?.rate,
        },
      ];
    });

    setZeroRateState(rateStateAfterRemoveLanguage);
  };

  const doOperation = (dataToEdit: any, isCopyLanguagePack?: boolean) => {
    if (dataToEdit && Object.keys(dataToEdit).length !== 0) {
      return isCopyLanguagePack ? "Copy" : "Update";
    } else {
      return "Add";
    }
  };

  useEffect(() => {
    const hasZeroRate = zeroRateState?.find((zero: any) => zero?.value < 0.01);
    if (hasZeroRate && zeroRateState?.length > 0 && !flatRateToggle) {
      setDisableProp(true);
    } else if (flatRateToggle && languageFlatRateError) {
      setDisableProp(true);
    } else {
      setDisableProp(false);
    }
  }, [zeroRateState, flatRateToggle]);

  return (
    <Modal show={show} onHide={() => handleShow(false)} centered>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>
            {doOperation(dataToEdit, isCopyLanguagePack)} Language Pack{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required font-sz-14">
                  Package Name
                </label>
                <input
                  type="text"
                  {...register('name', {
                    required: true,
                    validate: useFunctionlityInputFieldValidation,
                  })}
                  className="form-control"
                  placeholder="Package name"
                  defaultValue={dataToEdit?.name} />
                {errors.name && errors.name.type == "validate" && (
                  <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />
                )}
                {errors.name?.type === "required" && <FormErrorMessage message="required" />}
              </div>
              <div className="form-inline mb-3 flex-wrap align-items-sm-center">
                <div className="form-group col-12 pl-0 pr-0 ">
                  <Select
                    placeholder="Select Language"
                    className="w-100"
                    options={languageListForSelect}
                    value={selectedOptions}
                    isMulti
                    isDisabled={languageList.length === 0 ? true : false}
                    closeMenuOnSelect={false}
                    filterOption={createFilter(reactSelectFilterConfig)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(selected) => handleAddLanguage(selected)}
                  />
                  {/* {
                    errors.languageRates && (
                      <FormErrorMessage message="required" />
                    )
                  } */}
                </div>
              </div>
              <div className="component-card mb-3 shadow-none">
                <div className="component-card-header border-top border-right border-left">
                  <div className="row align-items-start">
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                      <div>Language</div>
                      {listOfLanguages && listOfLanguages.length === 0 ? (
                        <div className="text-danger font-sz-12 ">
                          Contains no language
                        </div>
                      ) : (
                        <div className="text-muted font-sz-12 ">
                          Total {listOfLanguages.length} Selected Languages
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <div className="row  flex-wrap  align-items-start mx-0">
                        <button
                          type="button"
                          className={`btn btn-toggle language-toggle-btn mt-1 ${dataToEdit.flatRate != undefined &&
                            dataToEdit.flatRate > 0 ? "active" : ""}`}
                          data-toggle="button"
                          aria-pressed="false"
                          onClick={() =>
                            handleFlatRateButtonToggle(flatRateToggle)
                          }
                        >
                          <div className="handle"></div>
                        </button>
                        <span className="toggle-button-label  text-muted ">
                          Flat Language Rate
                        </span>
                        {flatRateToggle ? (
                          <>
                            <div className="input-group col pr-0">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="flat-rate"> $ </span>
                              </div>
                              <input
                                type="float"
                                {...register('flatRate', {
                                  // required: true,
                                  validate: handleCopyPasteInvalidValue,
                                })}
                                className={`form-control ${languageFlatRateError ? "field-required" : ""
                                  }`}
                                defaultValue={dataToEdit.flatRate ?? 0}
                                aria-describedby="flatRate"
                                style={{ border: errors.flatRate || languageFlatRateError ? '1px solid red' : '' }}
                                onKeyDown={(evt) =>
                                  numberFieldValidation(evt)
                                }
                                onKeyPress={(event) => {
                                  if (!/^\d+(\.\d{1,2})?$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(event) => { handleFlatRate(event) }}
                                onInput={(event) =>
                                  handleLimitDecimalPlaces(event, 2)
                                } />
                              {/* {
                                errors.flatRate?.type === "required" && (
                                  <FormErrorMessage message="required" />
                                )} */}
                              {errors.flatRate &&
                                errors.flatRate.type === "validate" && (
                                  <FormErrorMessage message="Please enter a valid value" />
                                )}
                              {languageFlatRateError && (
                                <div className="font-sz-10 text-danger">
                                  {languageFlatRateError}
                                </div>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="component-card-body border">
                  <ul className="list-group">
                    {listOfLanguages.map(
                      (language: TypeLanguageList, index) => (
                        <li className="list-group-item" key={language.languageId}>
                          <div className="row">
                            <div className="col-12 col-sm-6 col-md-6">
                              <p className="word-wrap mb-sm-0 mb-2 pt-2">
                                {language.languageName}
                              </p>
                            </div>
                            <div className="col-12 col-sm-5 col-md-5 pr-sm-0 mb-2 mb-sm-0">
                              {flatRateToggle ? null : (
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text" id="flat-rate">$</span>
                                  </div>
                                  <input
                                    type="hidden"
                                    className="form-control"
                                    //@ts-ignore
                                    {...register(`languageRates[${index}].languageName`, {
                                      required: flatRateToggle ? true : false,
                                    })}
                                    aria-describedby="languageName"
                                    defaultValue={language.languageName} />
                                  <input
                                    type="hidden"
                                    className="form-control"
                                     //@ts-ignore
                                    {...register(`languageRates[${index}].languageId`, {
                                      required: flatRateToggle ? true : false,
                                    })}
                                    aria-describedby="languageId"
                                    defaultValue={language.languageId} />
                                  <input
                                    key={Date.now() + ""}
                                    type="float"
                                    //@ts-ignore
                                    {...register(`languageRates[${index}].rate`, {
                                      required:
                                        flatRateToggle ||
                                          languageRateMap.get(
                                            language?.languageId
                                          ) === 0
                                          ? true
                                          : false,
                                      validate: handleCopyPasteInvalidValue,
                                    })}
                                    className={`form-control ${languageRateMap.get(
                                      language?.languageId
                                    ) === 0
                                      ? "field-required"
                                      : ""
                                      }`}
                                    defaultValue={languageRateMap.get(
                                      language?.languageId
                                    ) !== 0.00 || languageRateMap.get(
                                      language?.languageId
                                    ) !== null ? languageRateMap.get(
                                      language?.languageId
                                    ) : 0}
                                    aria-describedby="rate"
                                    style={{
                                      border: languageRateMap.get(language?.languageId) ===
                                        '0.00' ? '1px solid red' : ''
                                    }}
                                    onKeyDown={(evt) =>
                                      numberFieldValidation(evt)
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]|\./.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onBlur={(event) => {
                                      handleRateChange(
                                        event,
                                        language?.languageId
                                      );
                                    }}
                                    onInput={(event) =>
                                      handleLimitDecimalPlaces(event, 2)
                                    } />
                                  {languageRateMap.get(language?.languageId) ===
                                    0.00 && (
                                      <span className="font-sz-14 text-danger">
                                        must be greater than 0
                                      </span>
                                    )}

                                    {errors.languageRates?.[index]?.rate ? (
                                      (errors.languageRates?.[index]?.rate
                                      ?.type === "validate") ?
                                      <FormErrorMessage message="Please enter a valid value" /> : null
                                    ) : null}
             
                                  {/* {errors.languageRates?.[index]?.rate ||
                                    (errors.languageRates?.[index]?.rate
                                      ?.type === "validate" && (
                                        <FormErrorMessage message="Please enter a valid value" />
                                      ))} */}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-sm-1 col-md-1 px-sm-2 text-left text-sm-center ">
                              <button
                                type="button"
                                className="btn btn-link px-0 text-site"
                                onClick={() =>
                                  onRemoveItem(language.languageId)
                                }
                              >
                                <span >
                                  <AssetSvg.Delete />
                                </span>
                              </button>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ModalFooter
            handleShow={handleShow}
            operationName={doOperation(dataToEdit, isCopyLanguagePack)}
            zeroRateLanguagePack={disableProp}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
}
