import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Modals } from "../../configs";
import { ConsumerConferenceData } from "../../models/api/conference";
import { dateFormatterInYMD, timeFormatterInAMPM } from "../../utils";

export function ModalCalendarUpcomingSchedule(props: {
  scheduleInfo: ConsumerConferenceData;
  show: boolean;
  handleShow: (value: boolean) => void;
}): JSX.Element {
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";

  return (
    <Modal
      show={props.show}
      onHide={() => props.handleShow(false)}
      centered
      className="schedule-modal"
    >
      {/*@ts-ignore */}
      <ModalHeader closeButton>Schedule Detail</ModalHeader>
      <ModalBody>
        <form>
          {props?.scheduleInfo?.assignedUserName && (
            <div className="form-row">
              <div className="form-group  col-12 col-sm-12 col-md-4">
                <label htmlFor="" className="">
                  User:
                </label>
              </div>
              <div className="form-group col-12 col-sm-12 col-md-8">
                <label htmlFor="" className="text-muted">
                  {props?.scheduleInfo?.assignedUserName}
                </label>
              </div>
            </div>
          )}

          {props?.scheduleInfo?.assignedUserName && (
            <div className="form-row">
              <div className="form-group  col-12 col-sm-12 col-md-4">
                <label htmlFor="" className="">
                  Company:
                </label>
              </div>
              <div className="form-group col-12 col-sm-12 col-md-8">
                <label htmlFor="" className="text-muted">
                  {props?.scheduleInfo?.assignedUserCompanyName}
                </label>
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="form-group col-12 col-sm-12 col-md-4">
              <label htmlFor="" className="">
                Time:
              </label>
            </div>
            <div className="form-group col-12 col-sm-12 col-md-8">
              <label htmlFor="" className=" text-muted ">
                {props?.scheduleInfo?.start
                  ? dateFormatterInYMD(props?.scheduleInfo?.start)
                  : ""}
                ,{" "}
                {props?.scheduleInfo?.start
                  ? timeFormatterInAMPM(props?.scheduleInfo?.start)
                  : ""}{" "}
                -{" "}
                {props?.scheduleInfo?.end
                  ? timeFormatterInAMPM(props?.scheduleInfo?.end)
                  : ""}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-12 col-sm-12 col-md-4">
              <label htmlFor="" className="">
                Conference Link:
              </label>
            </div>
            <div className="form-group col-12 col-sm-12 col-md-8">
              <a
                className="schedule-link font-sz-12"
                href={
                  props?.scheduleInfo?.roomId
                    ? `${rootUrl}/lobby/${props?.scheduleInfo?.roomId}`
                    : ""
                }
                target="_blank"
                rel="noreferrer"
              >
                {props?.scheduleInfo?.roomId
                  ? `${rootUrl}/lobby/${props?.scheduleInfo?.roomId}`
                  : ""}
              </a>
            </div>
          </div>

          {/* <div
                className="form-row 
              justify-content-lg-end justify-content-xl-end justify-content-md-end justify-content-sm-center justify-content-center 
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              mx-0"
              >
                <button
                  className="  btn btn-outline-dark border-grey 
                    d-flex d-sm-flex d-md-inline-block d-lg-inline-block d-xl-inline-block 
                    flex-grow-1 flex-sm-grow-1 flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 
                    justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 "
                  onClick={() => {
                    BootstrapUtils.ModalHideById(
                      Modals.CalendarUpcomingScheduleModal
                    );
                  }}
                >
                  Close
                </button>
              </div> */}
        </form>
      </ModalBody>
    </Modal>
  );
}
