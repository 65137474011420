import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, WithPagination } from "../../models";
import {
  ApiSchemaReasonData,
  ApiSchemaReasonRequestModel,
} from "../../models/api/CompanyCancellationReason";
import { UtilsJQuery } from "../../utils";

type Props = {
  companyId: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
};

let pagedResonUrl: string;

export function useFunctionalityPagedReason(props: Props) {
  const { companyId, pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedReasonListCount, setPagedReasonListCount] = useState<number>(0);

  pagedResonUrl = PlatformApi.CompanyCancellationReason.GetAll(
    companyId,
    pageSize,
    pageNo,
    searchText
  );
  const { data: apiPagedReasonList } = useSWR<ApiSchemaReasonData[]>(
    pagedResonUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaReasonData[]>>>(
        pagedResonUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedReasonListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  const pagedReasonList = React.useMemo(() => {
    const data = Array.isArray(apiPagedReasonList) ? apiPagedReasonList : [];
    return data;
  }, [apiPagedReasonList]);
  return {
    pagedReasonList,
    pagedReasonListCount,
  };
}

type ReasonProps = {
  companyId: string;
};

export function useFunctionalityReason(props: ReasonProps) {
  const { companyId } = props;

  // const { data: apiReasonData } = useSWR<ApiSchemaReasonData>
  //   (PlatformApi.CompanyCancellationReason.GetById(companyId),
  //     () => AxiosAuth.get<ApiResponse<ApiSchemaReasonData>>
  //       (PlatformApi.CompanyCancellationReason.GetById(companyId))
  //       .then((response) => {
  //         return response.data.data;
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //         return e;
  //       })
  //   );

  // const reasonData = React.useMemo(() => {
  //   const data = typeof apiReasonData === "object" ? apiReasonData : {};
  //   return data;
  // }, [apiReasonData]);

  const onCreateCancellationReason = React.useCallback(
    async (form: ApiSchemaReasonRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".skill-form-submit-btn");
      btnLoading.start?.();

      try {
        const response = await AxiosAuth.post(
          PlatformApi.CompanyCancellationReason.Create(companyId),
          form
        );

        if (response.status === AppConstants.Api.StatusOK) {
          toast.success("Cancellation reason added successfully");
          mutate(pagedResonUrl);
        }
      } catch (e) {
        toast.error("Failed to add cancellation reason.");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  const onUpdateCancellationReason = React.useCallback(
    (id: string, editForm: ApiSchemaReasonRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".skill-from-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(
        PlatformApi.CompanyCancellationReason.Update(id),
        editForm
      )
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            toast.success("Cancellation reason edited successfully");
            mutate(pagedResonUrl);
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    },
    []
  );

  const onDeleteCancellationReason = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        PlatformApi.CompanyCancellationReason.Delete(id)
      );
      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Cancellation reason deleted successfully");
        mutate(pagedResonUrl);
      }
    } catch (e) {
      toast.error("Failed to delete cancellation reason.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    // reasonData, -- no use
    onCreateCancellationReason,
    onUpdateCancellationReason,
    onDeleteCancellationReason,
  };
}
