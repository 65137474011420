import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaDepartmentData, WithPagination } from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  companyId: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
};

let pagedDepartmentUrl: string;

export function useFunctionalityPagedDepartment(props: Props) {

  const { companyId, pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedDepartmentListCount, setPagedDepartmentListCount] = useState<number>(0);

  pagedDepartmentUrl = PlatformApi.Department.PagedGetAll(companyId, pageSize, pageNo, searchText);

  const { data: apiPagedDepartmentList } = useSWR<ApiSchemaDepartmentData[]>(
    pagedDepartmentUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaDepartmentData[]>>>(
        pagedDepartmentUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedDepartmentListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedDepartmentList = React.useMemo(() => {
    const data = Array.isArray(apiPagedDepartmentList) ? apiPagedDepartmentList : [];
    return data;
  }, [apiPagedDepartmentList]);
  return {
    pagedDepartmentList,
    pagedDepartmentListCount,
  };
}
type UserProps = {
  companyId: string;
  shouldDepartmentGetAllInvoke?: boolean;
};
export function useFunctionalityDepartment(props: UserProps) {
  const { companyId, shouldDepartmentGetAllInvoke = false } = props;
  // 
  const locationUrl = PlatformApi.Department.GetAll(companyId);
  const locationCreateUrl = PlatformApi.Department.Create();
  //const locationDeleteUrl = PlatformApi.Department.Delete("");

  const { data: apiDepartmentList } = useSWR<ApiSchemaDepartmentData[]>(shouldDepartmentGetAllInvoke ? locationUrl : null, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaDepartmentData[]>>>(locationUrl)
      .then((r) => r.data.data?.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const optionMappedDepartmentList = React.useMemo(() => {
    const data = Array.isArray(apiDepartmentList)
      ? OptionMapper.mapperFunc(apiDepartmentList)
      : [];
    return data;
  }, [apiDepartmentList]);


  const onAddDepartment = React.useCallback(async (form: ApiSchemaDepartmentData) => {
    const btnLoading = UtilsJQuery.Ladda(".department-form-submit-btn");
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.post(locationCreateUrl, (form = { ...form, companyId: companyId || "" }));

      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Department added successfully");
        mutate(locationUrl);
        mutate(pagedDepartmentUrl);
      }
    } catch (e) {
      toast.error("Failed to add skill.");
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const onEditDepartment = React.useCallback(
    (editForm: ApiSchemaDepartmentData, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(".department-form-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(PlatformApi.Department.Update(id), editForm)
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            toast.success("Department edited successfully");
            mutate(locationUrl);
            mutate(pagedDepartmentUrl);
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    }, []);

  const onDeleteDepartment = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        PlatformApi.Department.Delete(id || "")
      );


      if (response.status === AppConstants.Api.StatusOK) {
        toast.success("Department deleted successfully");
        mutate(pagedDepartmentUrl);
      }
    } catch (e) {
      toast.error("Failed to delete Department.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    optionMappedDepartmentList,
    onAddDepartment,
    onEditDepartment,
    onDeleteDepartment,
  };
}
