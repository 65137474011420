import { platform, type } from "os";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import {
  ApiResponse,
  ApiSchemaCompanyLanguagePackData,
  ApiSchemaCreateLanguagePack,
  ApiSchemaInflowSettings,
  CallCenterInflowLanguagePackRequestModel,
  CallCenterInflowRequestModel,
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";

type InflowProps = {
  callCenterId: string;
  setLoaderProgress?: any;
  callInflowLoadCallBack?: any;
};
export function useFunctionalityCallCenterInflow(props: InflowProps) {
  const { callCenterId, setLoaderProgress, callInflowLoadCallBack } = props;
  const callInflowSettingsURL =
    PlatformApi.CallCenter.Inflow.GetInflowSettings(callCenterId);
  const callInflowUpdate = PlatformApi.CallCenter.Inflow.UpdateCallInflow();
  const CreateInflowLanguagePackUlr =
    PlatformApi.CallCenter.Inflow.CreateInflowLanguagePack();

  const onEditCallInflowStatus = React.useCallback((isEnabled: boolean) => {
    const reqeustModel: CallCenterInflowRequestModel = {
      callCenterId: callCenterId,
      isEnabled: isEnabled,
    };

    setLoaderProgress(topLoaderProgress.start);
    return AxiosAuth.put<ApiResponse<ApiSchemaCompanyLanguagePackData[]>>(
      PlatformApi.CallCenter.Inflow.UpdateCallInflow(),
      reqeustModel
    )
      .then((r) => {
        setLoaderProgress(topLoaderProgress.complete);
        toast.success("Updated successfully");
        //editCallInflowStatusCallBack(r.data?.data);
        //mutate(callInflowSettingsURL);
      })
      .catch((e) => {
        setLoaderProgress(topLoaderProgress.complete);
        console.error(e);
        return e;
      });
  }, []);
  // const onCreateCallInflowPack = React.useCallback(
  //   (form: CallCenterInflowLanguagePackRequestModel) => {

  //     setLoaderProgress(topLoaderProgress.start);
  //     const response =  AxiosAuth.post<ApiResponse<boolean>>(CreateInflowLanguagePackUlr,form)
  //       .then((r) => {
  //         setLoaderProgress(topLoaderProgress.complete);
  //         //editCallInflowStatusCallBack(r.data?.data);
  //         toast.success('Task completed successfully');
  //         mutate(callInflowSettingsURL);
  //       })
  //       .catch((e) => {
  //         setLoaderProgress(topLoaderProgress.complete);
  //         console.error(e);
  //         return e;
  //       })

  //   }, []);
  const onCreateCallInflowPack = React.useCallback(
    async (form: CallCenterInflowLanguagePackRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.post(
          CreateInflowLanguagePackUlr,
          form
        );
        if (response.status === AppConstants.Api.StatusOK) {
          mutate(callInflowSettingsURL);
          toast.success("Task completed successfully");
          btnLoading.stop?.();
        }
      } catch (e) {
        toast.error("Task failed");
        btnLoading.stop?.();
      }
      return Promise.resolve(null);
    },
    []
  );
  const onSubmit = React.useCallback(
    async (form: ApiSchemaCreateLanguagePack, id?: string) => {
      const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
      btnLoading?.start?.();

      try {
        const response = id
          ? await AxiosAuth.put(PlatformApi.LanguagePack.Edit(id), form)
          : await AxiosAuth.post(PlatformApi.LanguagePack.Create(), form);
        if (response.status === AppConstants.Api.StatusOK) {
          toast.success("Package updated successfully");
          btnLoading?.stop?.();
        }
      } catch (e) {
        toast.error("Task failed");
        btnLoading?.stop?.();
      }

      return Promise.resolve(null);
    },
    []
  );

  const LoadData = async() => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      var res = await AxiosAuth.get<ApiResponse<ApiSchemaInflowSettings>>(
        callInflowSettingsURL
      );
      if (res.status === AppConstants.Api.StatusOK) {
        
      return Promise.resolve(res.data);
      }
    } catch (e) {
      return Promise.resolve(null);
    }
    
  };
  // const { data: apiInflowSetting } = useSWR<ApiSchemaInflowSettings>(
  //   callInflowSettingsURL,
  //   () => {
  //     setLoaderProgress(topLoaderProgress.start);
  //     return AxiosAuth.get<ApiResponse<ApiSchemaInflowSettings>>(
  //       callInflowSettingsURL
  //     )
  //       .then((r) => {
  //         setLoaderProgress(topLoaderProgress.complete);

  //         if (callInflowLoadCallBack)
  //           callInflowLoadCallBack(r.data.data.enabled);
  //         return r.data?.data;
  //       })
  //       .catch((e) => {
  //         setLoaderProgress(topLoaderProgress.complete);
  //         console.error(e);
  //         return e;
  //       });
  //   }
  // );
  // const inflowSettings = React.useMemo(() => {
  //   const emptySettings: ApiSchemaInflowSettings = {
  //     enabled: false,
  //     opiPack: undefined,
  //     vriPack: undefined,
  //   };
  //   const data =
  //     typeof apiInflowSetting === "object" ? apiInflowSetting : emptySettings;
  //   return data;
  // }, [apiInflowSetting]);
  return {
    LoadData,
    onEditCallInflowStatus,
    onCreateCallInflowPack,
    onSubmit,
  };
}
type InflowPackProps = {
  inflowPackModel: CallCenterInflowRequestModel;
  setLoaderProgress?: any;
};
