import React, { useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { JobNotes } from "../";
import { Note } from "../../components";
import { ModalChooseInterpreter, ModalEditJob, ModalEditRate } from "../modals";
import { AppConstants, BookingStatusColor, BookingStatusType, GenderType, } from "../../configs";
import { AppointedOperator, PlatformType } from "../../models";
import LoadingBar from "react-top-loading-bar";
import { useAppSelector } from "../../core";
import { timeZones } from "../modals/jobFormData";
import { AssetSvg } from "../../assets";

type Props = {
  setBookingId?: any;
  jobData: any
  setSweetChangeProp: any
};

export function JobEditInfo(props: Props): JSX.Element {
  const { jobData, setSweetChangeProp } = props
  const data = useAppSelector((state) => ({
    userRole: state.auth.profile?.userRole,
    timeZone: state.auth.profile?.timeZone,
  }));

  const [show, setShow] = React.useState(false);
  const [showEditRateModal, setShowEditRateModal] = React.useState(false);
  const [showChooseInterpreterModal, setShowChooseInterpreterModal] = React.useState(false);
  const [loader, setLoader] = useState(jobData?.loaderProgress);

  const getAppointedOperators = (appointedOperators: AppointedOperator[]) => {
    const operatorNames = appointedOperators?.map(
      (appointedOperator) => appointedOperator.name
    );
    return operatorNames;
  };

  const getAppointedOperatorIds = (appointedOperators: AppointedOperator[]) => {
    const operatorIds = appointedOperators?.map(
      (appointedOperator) => appointedOperator.id
    );
    return operatorIds;
  };
  const jobStartDateTime = new Date(jobData?.date + ' ' + jobData?.startTime)
  const currentDate = new Date()
  const currentDateWithUserTimeZone = currentDate.toLocaleString('en-US', { timeZone: data.timeZone })
  const dateFormatOfCurrentDateWithUserTimeZone = new Date(currentDateWithUserTimeZone)

  return (
    <>
      <LoadingBar progress={loader} onLoaderFinished={() => setLoader(0)} />

      <div className="component-card mb-4">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
          <div>
            Booking :{" "}
            <span>
              <strong>#{jobData?.serialNo}</strong>
            </span>
          </div>
          <div className="ml-auto"></div>
          {(data.userRole === AppConstants.UserRoles.Admin ||
            data.userRole === AppConstants.UserRoles.Manager ||
            data.userRole === AppConstants.UserRoles.ResellerAdmin ||
            data.userRole === AppConstants.UserRoles.ResellerManager) && (!(jobData.jobData?.status === BookingStatusType.Closed || jobData.jobData?.status === BookingStatusType.Billed || jobData.jobData?.status === BookingStatusType.Paid)) && (
              <div className="ml-auto">
                <button
                  className="btn btn-link text-site font-weight-light pl-0"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  EDIT JOB
                </button>
              </div>
            )}
        </div>
        <div className="component-card-body border">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Company Name: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.companyName ?? "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Request By: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.requestedBy ?? "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Language: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.languageName ?? "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong> Skill: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.requestType ?? "Any"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Gender: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.genderType === 0 ||
                      jobData?.genderType === 1 ||
                      jobData?.genderType === 2
                      ? GenderType[jobData?.genderType]
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Service Type: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.serviceType
                      ? PlatformType[jobData?.serviceType]
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>No. of Interpreter: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.noOfOperator
                      ? jobData?.noOfOperator
                      : "N/A"}
                  </p>
                </div>
              </div>
              {jobData?.serviceType === PlatformType.OnSite ? (
                <div className="row">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Site Contact: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <p className="word-wrap">
                      {jobData?.siteContact
                        ? jobData?.siteContact
                        : "N/A"}
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Date: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.date
                      ? moment(jobData?.date).format("ll")
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Time: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.startTime
                      ? jobData?.startTime
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Duration: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.durationHours ||
                      jobData?.durationMinutes
                      ? `${jobData?.durationHours}h ${jobData?.durationMinutes}m`
                      : "N/A"}
                  </p>
                </div>
              </div>
              {jobData?.location && (
                <div className="row">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Branch: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <p className="word-wrap">
                      {jobData?.location ? (
                        <span>{jobData?.location}</span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>
              )}
              {jobData?.address &&
                jobData?.serviceType === PlatformType.OnSite ? (
                <div className="row">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Address: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <p className="word-wrap">
                      {jobData?.address &&
                        jobData?.address.countryName ? (
                        <span>
                          {jobData?.address.description},{" "}
                          {jobData?.address.city},{" "}
                          {jobData?.address.stateName},{" "}
                          {jobData?.address.zipCode},{" "}
                          {jobData?.address.countryName}.
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}
                    </p>
                  </div>
                </div>
              ) : null}

              {jobData?.distance &&
                jobData?.serviceType === PlatformType.OnSite ? (
                <div className="row">
                  <div className="col-fixed-width px-3">
                    <p>
                      <strong>Distance: </strong>
                    </p>
                  </div>
                  <div className="col">
                    <p className="word-wrap">
                      {jobData?.distance} {" Miles "}
                    </p>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Time Zone: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    <span>{jobData?.timeZone ? timeZones.find((x) => x.value === jobData?.timeZone)?.label : "N/A"}</span>
                  </p>
                </div>
              </div>
              {jobData?.bookingCustomFields &&
                jobData?.bookingCustomFields?.map(
                  (customFields: any) => (
                    <div className="row">
                      <div className="col-fixed-width px-3">
                        <p>
                          <strong>{customFields.name}: </strong>
                        </p>
                      </div>
                      <div className="col">
                        <p className="word-wrap">
                          {customFields.value ?? "N/A"}
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className="col-12 d-block d-sm-block d-md-none d-lg-none d-xl-none">
              <hr className="mt-2 mb-4" />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 word-wrap">
              <h4 className="font-weight-light">
                Status :
                <span
                  className={`badge p-1 rounded-0 text-uppercase text-white ml-2`}
                  style={{
                    backgroundColor:
                      jobData?.status === BookingStatusType.Open
                        ? BookingStatusColor.Open
                        : jobData?.status === BookingStatusType.Offered
                          ? BookingStatusColor.Offered
                          : jobData?.status ===
                            BookingStatusType.Confirmed
                            ? BookingStatusColor.Confirmed
                            : jobData?.status === BookingStatusType.Closed
                              ? BookingStatusColor.Closed
                              : jobData?.status ===
                                BookingStatusType.Cancelled
                                ? BookingStatusColor.Cancelled
                                : jobData?.status ===
                                  BookingStatusType.Unfulfilled
                                  ? BookingStatusColor.Unfulfilled
                                  : jobData?.status === BookingStatusType.NoShow
                                    ? BookingStatusColor.NoShow
                                    : jobData?.status === BookingStatusType.Billed
                                      ? BookingStatusColor.Billed
                                      : jobData?.status === BookingStatusType.Paid
                                        ? BookingStatusColor.Paid
                                        : "",
                  }}
                >
                  {BookingStatusType[jobData?.status ?? 0]}
                </span>
              </h4>
              <div className="d-flex align-items-center mb-2">
                <div className="">Custom Rate:</div>
                <div className="booking-rate-value pl-1">
                  {jobData?.rate ? `$${jobData?.rate}` : "N/A"}
                </div>
                {(data.userRole === AppConstants.UserRoles.Admin ||
                  data.userRole === AppConstants.UserRoles.Finance ||
                  data.userRole === AppConstants.UserRoles.ResellerAdmin ||
                  data.userRole === AppConstants.UserRoles.ResellerFinance) && (!(jobData.jobData?.status === BookingStatusType.Closed || jobData.jobData?.status === BookingStatusType.Billed || jobData.jobData?.status === BookingStatusType.Paid)) && (

                    <button
                      className="btn btn-link text-site text-decoration-none"
                      onClick={() => {
                        setShowEditRateModal(true);
                      }}
                    >
                      <div className="btn svg-color" title="bookingRateEdit">
                        <span >
                          <AssetSvg.Edit />
                        </span>
                      </div>
                    </button>
                  )}
              </div>
              <Note type="finance" value={jobData?.noteForFinance} />
              <h4 className="font-weight-light">Interpreter</h4>
              <div className="py-2">
                <span>
                  Preferred Interpreter :{" "}
                  {jobData?.preferredInterpreterName ?? "N/A"}
                </span>
              </div>
              <div className="py-2">
                <span>
                  <strong>Appointed to : </strong>
                  <span>
                    {jobData?.appointedOperators.length !== 0 && jobData?.appointedOperators
                      ? getAppointedOperators(
                        jobData?.appointedOperators
                      ).join(", ")
                      : "N/A"}
                  </span>
                </span>
              </div>
              {(data.userRole === AppConstants.UserRoles.Admin ||
                data.userRole === AppConstants.UserRoles.Finance ||
                data.userRole === AppConstants.UserRoles.ResellerAdmin ||
                data.userRole === AppConstants.UserRoles.ResellerFinance) && (jobData.jobData?.status !== BookingStatusType.Billed) && (dateFormatOfCurrentDateWithUserTimeZone < jobStartDateTime) && (
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-link text-site font-weight-light pl-0"
                      onClick={() => {
                        setShowChooseInterpreterModal(true);
                      }}
                    >
                      <div className="btn mr-2" title="bookingInterpreterEdit">
                        <span >
                          <AssetSvg.Edit />
                        </span>
                      </div>
                      Choose Interpreter
                    </button>
                  </div>
                )}
            </div>
          </div>

          <hr className="mt-2 mb-4" />
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Modality: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.modality ? jobData?.modality : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Appointment Type: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.appointmentCategory ? jobData?.appointmentCategory : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Appointment Description: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.appointmentDescription ? jobData?.appointmentDescription : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Consumer Type: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.consumerType ? jobData?.consumerType : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Consumer: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.consumer ? jobData?.consumer : "N/A"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-fixed-width px-3">
                  <p>
                    <strong>Extra Information: </strong>
                  </p>
                </div>
                <div className="col">
                  <p className="word-wrap">
                    {jobData?.extraInformation ? jobData?.extraInformation : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <JobNotes jobData={jobData} setSweetChangeProp={setSweetChangeProp} />
        </div>
      </div>

      <ModalEditJob
        data={jobData}
        show={show}
        handleShow={setShow}
        selectedJob={jobData}
        setSweetChangeProp={setSweetChangeProp}
      />

      <ModalEditRate
        show={showEditRateModal}
        handleShow={setShowEditRateModal}
        jobId={jobData?.id}
        rate={jobData?.rate ? jobData?.rate : null}
        noteForFinance={jobData?.noteForFinance}
        setSweetChangeProp={setSweetChangeProp}
      />
      {
        showChooseInterpreterModal && <ModalChooseInterpreter
          show={showChooseInterpreterModal}
          handleShow={setShowChooseInterpreterModal}
          jobId={jobData?.id}
          selectedOperatorIds={getAppointedOperatorIds(
            jobData?.appointedOperators
          )}
          chooseMaxNoOfOperator={jobData?.noOfOperator}
          setSweetChangeProp={setSweetChangeProp}
        />
      }
    </>
  );
}
