import { Modal } from "react-bootstrap";
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
  modalHeader: string;
  setPhoneNumber?: any;
  onSaveHandler: any;
};

export function ModalPhoneNumber(props: Props): JSX.Element {
  const { modalHeader, setPhoneNumber, onSaveHandler } = props;
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const [onClickEditTin, setOnClickEditTin] = useState(false);

  const tinCallBack = (cb: any) => {
    return cb();
  }
  const onModalClose = () => {
    props.handleShow(false);
    setOnClickEditTin(false);
  };

  const validateTeNumber = (inputValue: any) => {
    inputValue = inputValue.replace('-', '');
    inputValue = inputValue.replace('.', '');
    inputValue = inputValue.replace(' ', '');
    if (inputValue == "") return true;
    if ((/^[\+]?[(]?[0-9]{3,4}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(inputValue)) || (/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/im.test(inputValue))) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <Modal show={props.show} onHide={() => onModalClose()} centered backdrop='static'>
      {/* <form> */}
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>{modalHeader}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSaveHandler)} id="companyCreateForm">
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Phone Number
                </label>
                <input
                  type="number"
                  {...register('phone', {
                    required: true, validate: validateTeNumber
                  })}
                  className="form-control"
                  placeholder="Phone number"
                  onChange={(e) => { setPhoneNumber(e.target.value) }} />
                {errors.phone && errors.phone.type === "required" && <FormErrorMessage message="required" />}
                {
                  errors.phone && errors.phone.type == "validate" && (
                    <FormErrorMessage message="Please enter a valid contact number" />
                  )
                }
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className=" btn btn-primary text-uppercase" onClick={() => onModalClose()}>Close</button>
          <button type="submit" className="btn btn-primary text-uppercase btn-phone-add">Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
