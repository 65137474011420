import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas'

type Props = {
    show: boolean;
    handleShow: (value: boolean) => void;
    header: string;
    setImgUrl?: any;
};

export function SignatureModal(props: Props) {
    const [sigCanvas, setSigCanvas] = useState<SignatureCanvas | null>(null)
    const [trimmedImgUrl, setTrimmedImgUrl] = useState<any>("")
    return (
        <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
            <form >
                        {/*@ts-ignore */}
        <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sig-container">
                        <div className="sigContainer">
                            <SignatureCanvas penColor='black' ref={(ref) => setSigCanvas(ref)}
                                canvasProps={{ width: 400, height: 200, className: 'sigPad sigCanvas' }} />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-link text-site text-uppercase "
                                onClick={() => {
                                    sigCanvas?.clear()
                                }}

                            >Clear
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-link text-site text-uppercase "
                        onClick={() => {
                            props.handleShow(false)
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary text-uppercase login-from-submit-btn"
                        onClick={() => {
                            // setTrimmedImgUrl(sigCanvas?.getTrimmedCanvas().toDataURL())
                            props.setImgUrl(sigCanvas?.getTrimmedCanvas().toDataURL())
                            props.handleShow(false)
                        }}
                    >
                        Save
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
