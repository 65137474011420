import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import { FormErrorMessage } from "../../../components";
import {
  useFunctionalityCountry,
  useFunctionalityLocation,
  useFunctionalityLocationDetails,
  useFunctionalityState,
  useFunctionalityZipcodeValidation
} from "../../../pages/hooks";
import { AppConstants, AppRouteUi, DefaultCountry, PlatformType, reactSelectFilterConfig } from "../../../configs";
import { useFunctionalityAddressFieldValidation } from "../../../utils";
import { unstable_batchedUpdates } from "react-dom";

type Props = {
  register: any,
  setValue: any,
  errors: any,
  companyId?: any,
  selectedServiceType?: any,
  trigger: any,
  selectedLocation?: string,
  setSelectedLocation?: any,
  setShowLocationModal: Function,
  showJobCreateModal: boolean,
}

export function FaceToFaceForm(props: Props): JSX.Element {
  const { selectedServiceType, setShowLocationModal, showJobCreateModal, selectedLocation, setSelectedLocation } = props;
  const { countryList } = useFunctionalityCountry(showJobCreateModal);
  const [selectedCountry, setSelectedCountry] = React.useState<string>(DefaultCountry.USA);
  const { stateList } = useFunctionalityState(selectedCountry, showJobCreateModal);
  const { optionMappedLocationList: locationList } = useFunctionalityLocation({
    companyId: props.companyId || '', shouldLocationGetAllInvoke: showJobCreateModal
  });

  const { locationDetails } = useFunctionalityLocationDetails({
    locationId: selectedLocation || ''
  });

  const [requiredField, setRequiredField] = useState(false);
  const [selectedZipcode, setSelectZipcode] = React.useState<string>('');
  const [selectedAddress, setSelectAddress] = React.useState<string>('');
  const [selectedCity, setSelectCity] = React.useState<string>('');
  const [selectedState, setSelectedState] = React.useState<string>('');

  const updatedLocationList = [
    {
      value: "",
      label: 'Choose a location'
    },
    ...locationList,
  ];


  useEffect(() => {
    if (selectedServiceType === PlatformType.OnSite) {
      setRequiredField(true);
    } else {
      setRequiredField(false);
    }
  }, [selectedServiceType])

  const handleState = (event: any) => {
    setSelectedState(event.value);
  };

  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value)
  }
  const { handleAddressLength, addressLengthError } = useFunctionalityAddressFieldValidation()
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry,
    selectedState: stateList?.find(x => x.value === selectedState)?.label!,
    selectedZipcode
  });
  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode])

  const getSelectedCountry = () => {
    return countryList ? countryList?.find(x => x.value === selectedCountry) : null;
  }

  const getSelectedState = () => {
    return stateList ? stateList?.find(x => x.value === selectedState) : null;
  }

  const getDefaultLocation = () => {
    return selectedLocation ? locationList?.find(l => l?.value === selectedLocation) : updatedLocationList[0];
  }

  useEffect(() => {
    if(locationDetails?.address){
      unstable_batchedUpdates(() => {
        setSelectedCountry(locationDetails.address.countryId!)
        setSelectedState(locationDetails.address.stateId!)
        setSelectAddress(locationDetails.address.description!)
        setSelectCity(locationDetails.address.city!)
        setSelectZipcode(locationDetails.address.zipCode!)
      })
    }
    setTimeout(() => {
      if (selectedLocation) {
        props.trigger(["address.description", "address.city", "address.zipCode", "address.stateId"]);
      }
    }, 3000);
  }, [locationDetails])

  return (
    <>
      <div
        className="component-card-body rounded-bottom  border-left border-right border-bottom"
      >
        <div className="form-group row">
          <label
            htmlFor=""
            className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label "
          >
            Site Contact
          </label>
          <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <input
              name="siteContact"
              type="text"
              className="form-control"
              ref={props.register({
                // required: requiredField,
              })}
            />
            {(requiredField && props.errors.siteContact) && (
              <FormErrorMessage message="required" />
            )}
          </div>
        </div>

        <div className="form-group row">
          <label
            htmlFor=""
            className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label"
          >
            Location
          </label>
          <div className="col-8 col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <div className="row align-items-center">
              <div className="col">
                <input
                  className="d-none"
                  name="location"
                />
                <Select
                  key={new Date() + ''}
                  placeholder="Choose a location"
                  isDisabled={(locationList.length === 0) ? true : false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={updatedLocationList}
                  filterOption={createFilter(reactSelectFilterConfig)}
                  defaultValue={getDefaultLocation()}
                  onChange={(value) => {
                    props.setValue("location", value?.value);
                    setSelectedLocation(value ? value.value : "");
                  }}
                />
              </div>
              {
                (AppConstants.UserRoles.Admin && AppConstants.UserRoles.ResellerAdmin) && <div className="pr-3">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowLocationModal(true);
                    }}
                  >
                    <svg viewBox="0 0 448 512">
                      <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z" />
                    </svg>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label
            htmlFor=""
            className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 col-form-label required"
          >
            Address
          </label>
          <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <input
              name="address.description"
              type="text"
              className="form-control"
              value={selectedAddress}
              ref={props.register({
                required: requiredField, minLength: 2, maxLength: 128
              })}
              style={{ border: props.errors.address?.description || addressLengthError ? '1px solid red' : '' }}
              onChange={(e) => {
                handleAddressLength(e)
                setSelectAddress(e.target.value)
              }}
            />
            {(requiredField && props.errors.address?.description) && (
              <FormErrorMessage message="required" />
            )}
            {
              addressLengthError && <FormErrorMessage message={addressLengthError} />
            }
          </div>
        </div>

        <div className="form-group row">
          <div className="col-6">
            <label htmlFor="" className="col-12 p-0 col-form-label required">
              City
            </label>
            <input
              name="address.city"
              type="text"
              className="form-control"
              value={selectedCity}
              ref={props.register({
                required: requiredField,
              })}
              style={{ border: props.errors.address?.city ? '1px solid red' : '' }}
              onChange={(e) => setSelectCity(e.target.value)}
            />
            {(requiredField && props.errors.address?.city) && (
              <FormErrorMessage message="required" />
            )}
          </div>
          <div className="col-6">
            <label htmlFor="" className="col-12 p-0 col-form-label required">
              State
            </label>
            <input
              className="d-none"
              name="address.stateId"
              ref={props.register({ required: requiredField })}
              defaultValue={selectedState}
            />
            <Select
              // className={`${props.errors.address?.stateId?.type == "required" ? 'field-required' : ''} `}
              className={` ${props.errors.address?.stateId?.type == "required" ? 'field-required' : ''} `}
              key={new Date() + ''}
              placeholder="Select state"
              isDisabled={(stateList.length === 0) ? true : false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={stateList}
              filterOption={createFilter(reactSelectFilterConfig)}
              value={getSelectedState()}
              onChange={(value) => {
                props.setValue("address.stateId", value?.value, {
                  shouldValidate: true,
                });
                handleState(value);
              }}
            />
            {(requiredField && props.errors.address?.stateId) && (
              <FormErrorMessage message="required" />
            )}
          </div>
        </div>

        <div className="form-group row">

          <div className="col-6">
            <label htmlFor="" className="col-12 p-0 col-form-label required">
              Zip Code
            </label>
            <input
              name="address.zipCode"
              type="text"
              className="form-control"
              value={selectedZipcode}
              ref={props.register({
                required: requiredField,
              })}
              onChange={(event) => { 
                handleZip(event) 
              }}
              style={{ border: props.errors.address?.zipCode ? '1px solid red' : '' }}
            />
            {
              zipCodeError && (
                <FormErrorMessage message={zipCodeError} />
              )
            }
            {(requiredField && props.errors.address?.zipCode) && (
              <FormErrorMessage message="required" />
            )}
          </div>
          <div className="col-6">
            <label htmlFor="" className="col-12 p-0 col-form-label required">
              Country
            </label>
            <input
              className="d-none"
              name="address.countryId"
              ref={props.register({ required: requiredField })}
              defaultValue={selectedCountry}
            />
            <Select
              className={` ${props.errors.address?.countryId?.type == "required" ? 'field-required' : ''} `}
              key={new Date() + ''}
              placeholder="Select country"
              isDisabled={(countryList.length === 0) ? true : false}
              components={{
                IndicatorSeparator: () => null,
              }}
              options={countryList}
              filterOption={createFilter(reactSelectFilterConfig)}
              value={getSelectedCountry()}
              onChange={(value) => {
                props.setValue("address.countryId", value?.value, {
                  shouldValidate: true,
                });
                setSelectedCountry(value?.value);

                props.setValue("address.stateId", "", {
                  shouldValidate: true,
                });
              }}
            />
            {(requiredField && props.errors.address?.countryId) && (
              <FormErrorMessage message="required" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}