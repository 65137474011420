import { Searchbar } from "../../features";
import { AllScheduleList } from "../../features/Jobs/allScheduleList";
import { useFunctionalityVideoScheduler } from "../hooks/functionalityVideoScheduler";

export function PageVideoSchedules(): JSX.Element {
  return (
    <>
      <h4 className="my-3 font-weight-light">Video Conferences</h4>
      <div className="call-log-page ">
        <div className="row">
          <div className="col-12">
            <Searchbar showExportOptions={true} />
          </div>

          <div className="col-12 mt-4">
            <AllScheduleList />
          </div>
        </div>
      </div>
    </>
  );
}
