import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modals } from "../../configs";
import { useAppSelector } from "../../core";
import { ConsumerConferenceData } from "../../models";
import { useFunctionalityConsumer } from "../../pages/hooks/functionalityConsumer";
import {
  BootstrapUtils,
  dateFormatterInYMD,
  timeFormatterInAMPM,
} from "../../utils";
type Props = {
  onClickUpcomingSchedule?: any,
  dictionary?: any
};


export default function UpcomingSchedule(props: Props) {
  const { onClickUpcomingSchedule } = props;
  const [confCount, setConfCount] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const { onGetConferenceList } = useFunctionalityConsumer();
  const [consumerConferenceList, setConsumerConferenceList] = useState<ConsumerConferenceData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const data = useAppSelector(state => ({ userId: state.auth.profile?.id }));

  useEffect(() => {
    onGetConferenceList(data.userId ?? "", 10, 1, "", setConsumerConferenceList, consumerConferenceList, setConfCount, setLoading)
  }, [])

  useEffect(() => {
    if (pageNo > 1)
      onGetConferenceList(data.userId ?? "", pageSize, pageNo, "", setConsumerConferenceList, consumerConferenceList, setConfCount, setLoading)
  }, [pageNo])

  const loadMoreHandler = () => {
    setPageNo(prev => prev + 1)
  }

  useEffect(() => { }, [consumerConferenceList, confCount])

  useEffect(() => { }, [consumerConferenceList])

  return (
    <>
      <div className="upcoming-schedule-list">
        {/* {consumerConferenceList.map(function (name, index) {
					return (
						<ul className=" list-group  rounded-0 upcoming-schedule-list">
							<li className="list-group-item" key={index}
							data-toggle="modal"
							aria-pressed="false"
							onClick={() => onClickUpcomingSchedule(name)}
							data-target={BootstrapUtils.GetSelectorById(
								Modals.CalendarUpcomingScheduleModal
							)}>
							{name.start ? dateFormatterInYMD(name.start) : ""},{" "}
							{name.start ? timeFormatterInAMPM(name.start) : ""} -{" "}
							{name.end ? timeFormatterInAMPM(name.end) : ""}
							</li>
						</ul>
					);
				})} */}
        {loading ? <div className="text-center pt-3">Loading...</div> : consumerConferenceList?.length === 0 ?
          <div className="text-center pt-3">{props?.dictionary ? props?.dictionary["No Upcoming Schedule"] : "No Upcoming Schedule"}</div>
          : null}

        {consumerConferenceList && consumerConferenceList.map((sweetItem: ConsumerConferenceData) => {
          return (
            <ul className=" list-group">
              <li className="list-group-item"
                data-toggle="modal"
                aria-pressed="false"
                onClick={() => onClickUpcomingSchedule(sweetItem)}
                data-target={BootstrapUtils.GetSelectorById(
                  Modals.CalendarUpcomingScheduleModal
                )}>
                <div className="row justify-content-between flex-wrap align-items-center">
                  <div className="col-12 col-sm-6 text-center my-2 my-sm-2">
                    <div>
                      {moment(sweetItem.start).format('ll')}
                    </div>
                    <p className="font-sz-12  bg-gradient text-white p-1 width-content mx-auto mb-1">
                      {/* 5:06 PM - 7:10 PM */}
                      {moment(sweetItem.start).format('HH:mm A')} {" - "}
                      {moment(sweetItem.end).format('HH:mm A')}
                    </p>
                  </div>

                  <div className="col-12 col-sm-6  text-center text-truncate mb-2 font-600 font-sz-16">
                    {sweetItem.assignedUserName}
                  </div>
                </div>
              </li>
              {/* <li
              className="list-group-item"
              data-toggle="modal"
              aria-pressed="false"
            >
              <div className="row justify-content-between flex-wrap align-items-center">
                <div className="col-12 col-sm-6 text-center my-2 my-sm-2 ">
                  <div>
                    March 3, 2022
                  </div>
                  <p className="font-sz-12  bg-gradient text-white p-1 width-content mx-auto mb-1">
                    5:06 PM - 7:10 PM
                  </p>
                </div>

                <div className="col-12 col-sm-6  text-center text-truncate mb-2 font-600 font-sz-16">
                  Arman
                </div>
              </div>
            </li> */}
            </ul>


          )
        })}
        {/* <div className="upcoming-schedule-list">
        <ul className=" list-group">
          <li
            className="list-group-item"
            data-toggle="modal"
            aria-pressed="false"
          >
            <div className="row justify-content-between flex-wrap align-items-center">
              <div className="col-12 col-sm-6 text-center my-2 my-sm-2">
                <div>
                March 3, 2022
                </div> 
                  <p className="font-sz-12  bg-gradient text-white p-1 width-content mx-auto mb-1">
                  5:06 PM - 7:10 PM
                </p>
              </div>
            
              <div className="col-12 col-sm-6  text-center text-truncate mb-2 font-600 font-sz-16">
            Arman
              </div>
            </div>
          </li>
          <li
            className="list-group-item"
            data-toggle="modal"
            aria-pressed="false"
          >
            <div className="row justify-content-between flex-wrap align-items-center">
              <div className="col-12 col-sm-6 text-center my-2 my-sm-2 ">
                <div>
                March 3, 2022
                </div> 
                  <p className="font-sz-12  bg-gradient text-white p-1 width-content mx-auto mb-1">
                  5:06 PM - 7:10 PM
                </p>
              </div>
            
              <div className="col-12 col-sm-6  text-center text-truncate mb-2 font-600 font-sz-16">
                Arman
              </div>
            </div>
          </li>
        </ul>
      </div> */}
      </div>
      {consumerConferenceList && (consumerConferenceList.length < confCount) &&

        <div className="btn-load-more d-flex ml-auto">
          <button type="button" className="btn load-more-btn btn-link" onClick={loadMoreHandler} disabled={consumerConferenceList === null}>
            {/* {consumerConferenceList === null ? <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span> : null} */}
            Load More
          </button>
        </div>

      }
    </>
  );
}
