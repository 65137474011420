import React, {useState} from "react";
import {PlatformApi, topLoaderProgress} from "../../configs";
import useSWR from "swr";
import {ApiResponse, PlatformType, WithPagination} from "../../models";
import {AxiosAuth} from "../../core";
import {OperatorSearchCriteriaResponseModel} from "../../models/api/Call";

type Props = {
    userId: string;
    platform:PlatformType
};

let pagedOperatorCriteriaListUrl: string;

export function useFunctionalityOperatorCriteriaList(props: Props) {
    const { userId,platform } = props;
    const [totalRequiredFieldCount, setTotalRequiredFieldCount] = useState(0);

    pagedOperatorCriteriaListUrl = PlatformApi.Operator.GetOperatorSearchCriteriaList(userId,platform);

    const { data: apiPagedOperatorCriteriaList } = useSWR<OperatorSearchCriteriaResponseModel[]>(
        pagedOperatorCriteriaListUrl,
        () => {
            return AxiosAuth.get<ApiResponse<WithPagination<OperatorSearchCriteriaResponseModel[]>>>(
                pagedOperatorCriteriaListUrl
            )
                .then((r) => {
                    return r.data?.data;
                })
                .catch((e) => {
                    console.error(e);
                    return e;
                });
        }
    );

    const pagedOperatorCriteriaList = React.useMemo(() => {
        const data = Array.isArray(apiPagedOperatorCriteriaList) ? apiPagedOperatorCriteriaList : [];

        const count  = data?.filter((criteria) => {
            return criteria.isMandatory;
        }).length;

        setTotalRequiredFieldCount(count);
        return data;
    }, [apiPagedOperatorCriteriaList]);

    return {
        pagedOperatorCriteriaList,
        totalRequiredFieldCount
    };
}
