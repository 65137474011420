import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosAuth } from "../..";
import { PlatformApi } from "../../../configs";
import { ApiResponse } from "../../../models/api/common";

export type TSliceNotification = {
    unreadNotificationCount?: number;
    pageSize?: number;
    pageNo?: number;
    searchText?: string;
    notificationShown?: boolean;
    bookingModalShow?: boolean;
    bookingIdForModalData?: string;
};

function InitSliceNotification(): TSliceNotification {
  return {
    unreadNotificationCount: 0,
    pageSize: 10,
    pageNo: 1,
    notificationShown: true,
    bookingModalShow: false,
  };
}

export const incrementAsync = createAsyncThunk(
  'notification/fetchUnreadCount',
  async () => {
    try {
      const countUnReadNotificationUrl = PlatformApi.Notification.CountUnread.Root();
      const response = await AxiosAuth.get<ApiResponse<number>>(countUnReadNotificationUrl)

      // The value we return becomes the `fulfilled` action payload
      return response.data.data;
    } catch (error) {
      console.error(error)
    }
    
  }
);

const sliceNotification = createSlice({
  name: "sliceNotification",
  initialState: InitSliceNotification(),
  reducers: {
    SetUnreadNotificationCount: (
      state,
      action: PayloadAction<number>
    ): TSliceNotification => ({
      ...state,
      unreadNotificationCount: action.payload,
    }),
    SetPageSize: (
      state,
      action: PayloadAction<number>
    ): TSliceNotification => ({
      ...state,
      pageSize: action.payload,
    }),
    SetPageNo: (
      state,
      action: PayloadAction<number>
    ): TSliceNotification => ({
      ...state,
      pageNo: action.payload,
    }),
    IncrementPageNo: (
      state
    ): TSliceNotification => ({
      ...state,
      pageNo: state.pageNo! + 1,
    }),
    SetNotificationShown: (
      state,
      action: PayloadAction<boolean>
    ): TSliceNotification => ({
      ...state,
      notificationShown: action.payload,
    }),
    SetBookingNotificationShow: (
      state,
      action: PayloadAction<boolean>
    ): TSliceNotification => ({
      ...state,
      bookingModalShow: action.payload,
    }),
    SetBookingIdForModalData: (
      state,
      action: PayloadAction<string>
    ): TSliceNotification => ({
      ...state,
      bookingIdForModalData: action.payload,
    }),
    resetData: ()=> {
      return InitSliceNotification();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.fulfilled, (state, action) => { 
        return {
          ...state,
          unreadNotificationCount: action.payload
        }
    })
  },
})

export const ReducerNotification = sliceNotification.reducer;
export const ActionNotification = sliceNotification.actions;
