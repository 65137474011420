import { useEffect, useRef, useState } from 'react';
import { ActionCommon, useAppDispatch, useAppSelector } from '../../core';
import { AppConfig, WebSocketApi } from '../../configs';
import { UserAgentUtils, logger } from '../../utils';
import { useDetectNetwork } from './useDetectNetwork';

export function useWebSocket() {
  const dispatch = useAppDispatch();
  const { haveNetwork } = useDetectNetwork();
  const { shouldConnectWS, isLoggedIn, sessionId, profileId, userRole } =
    useAppSelector((state) => ({
      shouldConnectWS: state.common.shouldConnectWS,
      isLoggedIn: state.auth.isLoggedIn,
      sessionId: state.auth.sessionId,
      profileId: state.auth.profile?.id,
      userRole: state.auth.profile?.userRole,
    }));
  const [webSocketConnection, setWebSocketConnection] =
    useState<WebSocket | null>(null);
  const connectionStatusRef = useRef({
    pending: false,
    connected: false,
  });
  const timeoutIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (
      (AppConfig.tabQueue && !shouldConnectWS) ||
      !isLoggedIn ||
      !sessionId ||
      !profileId ||
      !userRole ||
      !haveNetwork ||
      connectionStatusRef.current.pending ||
      connectionStatusRef.current.connected ||
      shouldConnectWS === null
    ) {
      return;
    }

    let connection: WebSocket;

    const clearTimer = () => {
      if (!timeoutIdRef.current) {
        return;
      }
      logger({ message: 'AWS WebSocket connection re-connect timer cleared' });
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    };
    const startTimer = () => {
      if (!navigator.onLine) {
        return;
      }
      clearTimer();
      timeoutIdRef.current = window.setTimeout(() => connect(), 1000);
      logger({ message: 'AWS WebSocket connection re-connect timer started' });
    };

    const connect = () => {
      clearTimer();
      connectionStatusRef.current.pending = true;
      const browserName = UserAgentUtils.getBrowserName(navigator.userAgent);
      connection = new WebSocket(
        `${WebSocketApi.Root}?userid=${profileId}&sessionId=${sessionId}&devicetype=${browserName}&role=${userRole}`
      );

      connection.onopen = () => {
        logger({ message: 'AWS WebSocket connection established' });
        connectionStatusRef.current.connected = true;
        connectionStatusRef.current.pending = false;
        dispatch(ActionCommon.setWebSocketStatus('online'));
      };

      connection.onclose = () => {
        logger({ message: 'AWS WebSocket connection disconnected' });
        dispatch(ActionCommon.setWebSocketStatus('offline'));
        startTimer();
      };

      connection.onerror = (error) => {
        logger({ message: 'AWS WebSocket connection error', data: error });
        connection.close();
      };

      setWebSocketConnection(connection);
      if (!navigator.onLine) {
        clearTimer();
      }
    };

    connect();

    return () => {
      connection.close();
      connectionStatusRef.current.pending = false;
      connectionStatusRef.current.connected = false;
    };
  }, [
    isLoggedIn,
    shouldConnectWS,
    sessionId,
    profileId,
    userRole,
    dispatch,
    haveNetwork,
  ]);

  useEffect(() => {
    if (
      !AppConfig.tabQueue ||
      UserAgentUtils.isMobileBrowser() ||
      UserAgentUtils.isWebView()
    ) {
      dispatch(ActionCommon.setShouldConnectWS(true));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    };
  },[]);

  return { webSocketConnection };
}
