import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth } from "../../core";
import { ApiResponse, ApiSchemaTinData, WithPagination } from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";

type Props = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
};

let pagedTINUrl: string;

export function useFunctionalityPagedTin(props: Props) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedTinListCount, setPagedTinListCount] = useState<number>(0);

  pagedTINUrl = PlatformApi.Tin.PagedGetAll(pageSize, pageNo, searchText);

  const { data: apiPagedTinList } = useSWR<ApiSchemaTinData[]>(
    pagedTINUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaTinData[]>>>(
        pagedTINUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedTinListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedTinList = React.useMemo(() => {
    const data = Array.isArray(apiPagedTinList) ? apiPagedTinList : [];
    return data;
  }, [apiPagedTinList]);
  return {
    pagedTinList,
    pagedTinListCount,
  };
}

export function useFunctionalityTin() {
  const skillUrl = PlatformApi.Tin.GetAll();

  const { data: apiTinList } = useSWR<ApiSchemaTinData[]>(skillUrl, () =>
    AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaTinData[]>>>(skillUrl)
      .then((r) => r.data.data?.data)
      .catch((e) => {
        console.error(e);
        return e;
      })
  );

  const optionMappedTinList = React.useMemo(() => {
    const data = Array.isArray(apiTinList)
      ? OptionMapper.mapperFunc(apiTinList)
      : [];
    return data;
  }, [apiTinList]);

  return {
    optionMappedTinList,
  };
}
