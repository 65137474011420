import React from "react";
import { Login } from "../../features/Login";
// import { Login } from "../../features/Login";



export function PageLogin(): JSX.Element {
  return (
    <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
      <Login />
    </div>
  );
}
