import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import App from "./App";
import { VideoProvider } from "./components/VideoProvider";
import { VRIAppStore } from "./core";
import reportWebVitals from "./reportWebVitals";
import "./scss/style.scss";
import AppStateProvider, { useAppState } from "./state";
import useConnectionOptions from "./utils/video/useConnectionOptions/useConnectionOptions";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

const VriApp = () => {
  const { setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <Provider store={VRIAppStore}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <App />
      </VideoProvider>
    </Provider>
  );
};

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppStateProvider>
      <SWRConfig value={swrOptions}>
        <VriApp />
      </SWRConfig>
    </AppStateProvider>
  </React.StrictMode>
);
reportWebVitals();