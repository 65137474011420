import Select from "react-select";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { ApiSchemaFormFilter, ApiSchemaColumn, ApiSchemaFilter, ApiSchemaOperatorFormFilter, } from "../../models/api/filter";
import { useFunctionalityLanguage, useFunctionalitySkill } from "../hooks";
import { operatorAccountStatus, operatorCallPlatform, operatorPresence, operatorRating, } from "../../configs/AppJson/operatorfilterField";

type Props = {
  setFilterData: any;
  setLoaderProgress: any;
  setFilterDataChange?: any;
  setPageIndex: Function;
};
export function OperatorFilter(props: Props): JSX.Element {
  const { setFilterData, setFilterDataChange, setPageIndex } = props;
  const [languageNameList, setLanguageNameList] = useState<any>([]);
  const { languageList } = useFunctionalityLanguage({ shouldLanguageGetAllInvoke: true });
  const { optionMappedSkillList } = useFunctionalitySkill({ shouldSkillGetAllInvoke: true });
  const { register, handleSubmit, setValue } = useForm<ApiSchemaOperatorFormFilter>();

  const [selectedPresenceStatus, setSelectedPresenceStatus] = useState<any>([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState<any>([]);
  const [selectedRatingScore, setSelectedRatingScore] = useState<any>([]);
  const [selectedCallPlatform, setSelectedCallPlatform] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>([]);
  const [selectedSkill, setSelectedSkill] = useState<any>([]);

  const optionSkillUpdatedList = [
    {
      value: "",
      label: "Any Skill",
    },
    ...optionMappedSkillList,
  ];

  React.useEffect(() => {
    if (languageList)
      setLanguageNameList([{ value: "", label: "Any" }, ...languageList]);
  }, [languageList]);

  const onFilterHandler = (data: ApiSchemaFormFilter) => {
    let columns: ApiSchemaColumn[] = [];
    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (value === "" || value === undefined || value === '0') continue;
        let column: ApiSchemaColumn = {
          columnName: key,
          value: value.toString(),
        };
        columns.push(column);
      }
    }
    let finalFilter: ApiSchemaFilter = { filterQueries: columns };
    ReactDOM.unstable_batchedUpdates(() => {
      setFilterData(finalFilter);
      setFilterDataChange(Math.floor(Math.random() * 100) + 1);
      setPageIndex(1);
    });
  };

  return (
    <div className="col-12 collapse mt-3" id="jobFilterPanel">
      <div className="card card-body">
        {/*@ts-ignore */}
        <form onSubmit={handleSubmit(onFilterHandler)}>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Presence</label>
              <input className="d-none" {...register('presence', {})} />
              <Select
                options={operatorPresence}
                value={selectedPresenceStatus}
                defaultValue={operatorPresence[0].value}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a presence"
                onChange={(value) => {
                  setValue("presence", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedPresenceStatus([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Account</label>
              <input className="d-none" {...register('status', {})} />
              <Select
                options={operatorAccountStatus}
                value={selectedAccountStatus}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a status"
                onChange={(value) => {
                  setValue("status", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedAccountStatus([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Language</label>
              <input className="d-none" {...register('languageId', {})} />
              <Select
                options={languageNameList}
                value={selectedLanguage}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a language"
                onChange={(value) => {
                  setValue("languageId", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedLanguage([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Skill</label>
              <input className="d-none" {...register('skillId', {})} />
              <Select
                options={optionSkillUpdatedList}
                value={selectedSkill}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a skill"
                onChange={(value) => {
                  setValue("skillId", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedSkill([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Rating</label>
              <input className="d-none" {...register('ratingScore', {})} />
              <Select
                options={operatorRating}
                value={selectedRatingScore}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a rating"
                onChange={(value) => {
                  setValue("ratingScore", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedRatingScore([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6 form-group">
              <label htmlFor="">Call Platform</label>
              <input className="d-none" {...register('platforms', {})} />
              <Select
                options={operatorCallPlatform}
                value={selectedCallPlatform}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select a call platform"
                onChange={(value) => {
                  setValue("platforms", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedCallPlatform([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-link text-uppercase filter-reset-btn"
                onClick={() => {
                  setFilterData({ filterQueries: [] });
                  setFilterDataChange(Math.floor(Math.random() * 100) + 1);
                  setValue("presence", "");
                  setValue("status", undefined); //undefined instead of ""
                  setValue("ratingScore", "");
                  setValue("platforms", undefined); //undefined instead of ""
                  setValue("languageId", "");
                  setValue("skillId", "");
                  setSelectedPresenceStatus([]);
                  setSelectedAccountStatus([]);
                  setSelectedRatingScore([]);
                  setSelectedLanguage([]);
                  setSelectedSkill([]);
                  setSelectedCallPlatform([]);
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary text-uppercase mx-3"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
