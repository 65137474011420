import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISliceCommon {
  shouldConnectWS: boolean | null;
  twilioDeviceStatus: 'online' | 'offline';
  websocketStatus: 'online' | 'offline';
  isBackdatedVersion: boolean;
}

const initialState: ISliceCommon = {
  shouldConnectWS: null,
  twilioDeviceStatus: 'offline',
  websocketStatus: 'offline',
  isBackdatedVersion: false,
};

const sliceCommon = createSlice({
  name: 'sliceCommon',
  initialState,
  reducers: {
    setShouldConnectWS: (
      state,
      action: PayloadAction<ISliceCommon['shouldConnectWS']>
    ) => {
      state.shouldConnectWS = action.payload;
    },
    setTwilioDeviceStatus: (
      state,
      action: PayloadAction<ISliceCommon['twilioDeviceStatus']>
    ) => {
      state.twilioDeviceStatus = action.payload;
    },
    setWebSocketStatus: (
      state,
      action: PayloadAction<ISliceCommon['websocketStatus']>
    ) => {
      state.websocketStatus = action.payload;
    },
    setIsBackdatedVersion: (
      state,
      action: PayloadAction<ISliceCommon['isBackdatedVersion']>
    ) => {
      state.isBackdatedVersion = action.payload;
    },
    resetData: () => {
      return initialState;
    },
  },
});

export const ReducerCommon = sliceCommon.reducer;
export const ActionCommon = sliceCommon.actions;
export type TypeActionCommon = typeof ActionCommon;
