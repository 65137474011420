import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { CallCenterPhoneNumberModel } from "../../models";
import { useFunctionalityReseller } from "../../pages/hooks/functionalityReseller";
import { NoDataComponent } from "../admin/noDataComponent";
import { ModalConfirmDelete } from "../modals/common";
import { ModalPhoneNumber } from "../modals/phoneNumberModal";
import LoadingBar from "react-top-loading-bar";

type Prop = {
  companyId: string
}
export function PhoneNumber(prop: Prop): JSX.Element {
  const { companyId } = prop;
  const [loaderProgress, setLoaderProgress] = useState<any>(0);
  const [phoneNumbers, setPhoneNumbers] = useState<CallCenterPhoneNumberModel[]>([]);
  const { addPhoneNumber, removePhoneNumber, getPhoneNumberList } = useFunctionalityReseller({ setLoaderProgress });
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberChange, setPhoneNumberChange] = useState<boolean>(false);
  const [id, setId] = useState("");

  const onDeleteHandler = () => {
    removePhoneNumber(id).then(() => {
      setDeleteModalShow(false);
      setPhoneNumberChange(!phoneNumberChange)
    });
  };
  const onSaveHandler = () => {
    addPhoneNumber(companyId, phoneNumber).then((res) => {
      setCreateModalShow(false)
      setPhoneNumberChange(!phoneNumberChange)
    });
  };

  useEffect(() => {
    if (companyId) {
      getPhoneNumberList(companyId)
        .then((res) => {
          setPhoneNumbers(res?.data)
        })
    }
  }, [companyId, phoneNumberChange])

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <div className="component-card mb-3">
        <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right">
          <div> Phone number</div>
          <button className="btn btn-link px-0 py-0 text-site btn-sm text-uppercase" title="Add Number" onClick={() => {
            setCreateModalShow(true);
          }}>
            <span className="mr-2">
              <FontAwesomeIcon icon={faPlusCircle} />
            </span>
            <span className="font-sz-11"> Add Number</span>
          </button>
        </div>
        <div className="component-card-body border">
          <div className="row flex-wrap px-3">
            {phoneNumbers?.length > 0 ? phoneNumbers?.map((phoneNumber, index) => (
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 col-min-width">
                <span className="toggle-button-label">
                  {phoneNumber?.phoneNumber}
                </span>
                <button
                  className="btn btn-link py-0 text-site btn-sm text-uppercase mb-1 font-sz-12 svg-color" title="Delete number"
                  onClick={() => {
                    setId(phoneNumber.id);
                    setDeleteModalShow(true);
                  }}
                >
                  <span>
                    <svg viewBox="0 0 64 64" aria-labelledby="title"
                      aria-describedby="desc" role="img" >


                      <path data-name="layer2"
                        fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="3" d="M6 10h52m-36 0V5.9A3.9 3.9 0 0 1 25.9 2h12.2A3.9 3.9 0 0 1 42 5.9V10m10.5 0l-2.9 47.1a5 5 0 0 1-4.9 4.9H19.3a5 5 0 0 1-4.9-4.9L11.5 10"
                        stroke-linejoin="round" stroke-linecap="round"></path>
                      <path data-name="layer1" fill="none" stroke="currentColor" stroke-miterlimit="10"
                        stroke-width="2" d="M32 18v36M22 18l2 36m18-36l-2 36" stroke-linejoin="round"
                        stroke-linecap="round"></path>
                    </svg>
                  </span>
                </button>
              </div>
            )) : <div className="col-12 text-center">
              <NoDataComponent title={"No data found"} />
            </div>
            }
          </div>
        </div>
      </div>
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={onDeleteHandler}
      />
      <ModalPhoneNumber
        show={createModalShow}
        handleShow={setCreateModalShow}
        setPhoneNumber={setPhoneNumber}
        onSaveHandler={onSaveHandler}
        modalHeader={"Add Phone Number"}
      />
    </>
  );
}
