import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { AdminPageTemplate, ColumnToolTip, ModalPrintInvoice } from "../../..";
import { AssetSvg } from "../../../../assets";
import { InvoiceType, PaginationSettings } from "../../../../configs";
import {
  RptInvoiceViewModel,
  TransactionResponseModel,
} from "../../../../models";
import { useFunctionalityInvoice } from "../../../../pages/hooks/functionalityInvoice";
import { useFunctionalityPagedTransaction } from "../../../../pages/hooks/functionalityTransaction";
import { handleNumberToTwoDecimalPoint } from "../../../../utils";

type Props = {
  invoiceType?: InvoiceType;
  id?: string;
  invoiceList?: TransactionResponseModel[];
  invoiceListCount?: number;
  loaderProgress?: number;
  setLoaderProgress?: any;
};

export function CommontTransaction(props: Props) {
  const { invoiceType, id } = props;
  const [tableData, setTableData] = useState<
    TransactionResponseModel[] | undefined
  >();
  const [tableDataCount, setTableDataCount] = useState(0);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(
    PaginationSettings.initialPageIndex
  );
  const [invoiceDetails, setInvoiceDetails] = useState<RptInvoiceViewModel>();
  const [printInvoiceModalShow, setPrintInvoiceModalShow] =
    useState<boolean>(false);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const { getInvoiceDetails } = useFunctionalityInvoice({
    setLoaderProgress: setLoaderProgress,
  });

  const { pagedTransactionList, pagedTransactionListCount } =
    useFunctionalityPagedTransaction({
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
      id: id,
      invoiceType: invoiceType,
    });
  useEffect(() => {
    if (pagedTransactionList) {
      setTableData(pagedTransactionList);
      setTableDataCount(pagedTransactionListCount);
    }
  }, [pagedTransactionList, pagedTransactionListCount]);

  const ViewInvoice = (invoiceId: string, invoiceType: InvoiceType) => {
    getInvoiceDetails(invoiceId, invoiceType).then((data) => {
      setInvoiceDetails(data?.data);
      setPrintInvoiceModalShow(true);
    });
  };

  useEffect(() => {
    if (pagedTransactionListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedTransactionList]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      ReactDOM.unstable_batchedUpdates(() => {
        setSearchText(e.target.value);
        setPageIndex(1);
      });
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const TableColumns = [
    {
      name: <ColumnToolTip data="DATE" />,
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={row.createdAt} />;
      },
    },
    {
      name: <ColumnToolTip data="TYPE" />,
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={row.type} />;
      },
    },
    {
      name: <ColumnToolTip data="CHECKNUMBER" />,
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={row.checkNumber} />;
      },
    },
    {
      name: <ColumnToolTip data="PAYMENT CONFIRMED BY" />,
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={row.paidBy} />;
      },
    },

    {
      name: <ColumnToolTip data="INVOICE" />,
      allowOverflow: true,
      selector: (row: TransactionResponseModel) => {
        return (
          <>
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                ViewInvoice(row.invoiceId, row.invoiceType);
              }}
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </div>
          </>
        );
      },
    },
    {
      name: "NOTES",
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={row.details} />;
      },
    },
    {
      name: "AMOUNT",
      selector: (row: TransactionResponseModel) => {
        return <ColumnToolTip data={handleNumberToTwoDecimalPoint(row.amount)} />;
      },
      conditionalCellStyles: [
        {
          when: (row: TransactionResponseModel) => row.amount < 0,
          style: {
            color: "red",
          },
        },
      ],
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress ?? 0}
        tableHeaders={TableColumns}
        tableData={tableData || []}
        setCreateModalShow={setModalShow}
        selectableRows={true}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={tableDataCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
      <ModalPrintInvoice
        show={printInvoiceModalShow}
        handleShow={setPrintInvoiceModalShow}
        invoice={invoiceDetails}
      />
    </>
  );
}