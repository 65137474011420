import { logger } from './logger';

function checkPageRefresh() {
  const entries = performance?.getEntriesByType(
    'navigation'
  ) as PerformanceNavigationTiming[];
  entries.forEach((entry) => {
    if (entry.type === 'reload') {
      return true;
    }
  });
  return false;
}
function checkDeprecatedPageRefresh() {
  if (performance?.navigation.type === 1) {
    return true;
  }
  return false;
}

export function isPageRefreshed() {
  try {
    if (checkPageRefresh() || checkDeprecatedPageRefresh()) {
      logger({ message: 'Page refreshed' });
      return true;
    }
  } catch (error) {
    logger({ message: 'Detecting page refresh error: ', data: error });
  }
  return false;
}

export const NavigationUtils = {
  isPageRefreshed,
};
