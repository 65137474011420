import React, { useState } from "react";
import useTimer from 'easytimer-react-hook';

type prop = {
  participantState: any;
  //setParticipantState:(state:any)=>void
};

export function CallDurationCounter(props: prop): JSX.Element {
  const { participantState } = props;
  // const [hours, setHours] = useState(0);
  // const [minutes, setMinutes] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  // React.useEffect(() => {
  //   let myInterval: any = null;
  //   if (participantState.current > 0 && seconds == 0) {
  //     console.log("Call Start Time" + new Date());
  //     setSeconds(seconds + 1);
  //   }
  // }, [participantState]);

  // React.useEffect(() => {
  //   let myInterval: any = null;
  //   if (participantState.current > 0) {
  //     myInterval = setInterval(() => {
  //       if (seconds <= 59) {
  //         setSeconds(seconds + 1);
  //         if (seconds === 59) {
  //           setMinutes(minutes + 1);
  //           setSeconds(0);
  //           if (minutes === 59) {
  //             setHours(hours + 1);
  //             setMinutes(0);
  //             setSeconds(0);
  //           }
  //         }
  //       }
  //     }, 1000);
  //     return () => {
  //       clearInterval(myInterval);
  //     };
  //   }
  // }, [seconds, minutes, hours, participantState]);
  const [timer] = useTimer({});

  React.useEffect(() => {
    if(participantState.current > 0){
      timer.start({});
    }
  }, [participantState])
  return (
    <>
      {
        <>
        {timer.getTimeValues().toString()}
          {/* {" "}
          {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds} */}
        </>
      }
    </>
  );
}
