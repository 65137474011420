import {
  AppConstants,
  AppRouteUi,
  CallLogType,
  CallStatusType,
  topLoaderProgress,
} from '../../configs';
import {
  CallLogResponseModel,
  CallSummaryResponseModel,
} from '../../models/api/Call';
import { AssetSvg } from '../../assets/svg/index';
import { useFunctionalityCallSummary } from '.';
import { ColumnSelectionsModel } from '../../models';
import { ColumnToolTip } from '../../features';
import { handleNumberToTwoDecimalPoint } from '../../utils';
import { useAppSelector } from '../../core';
import { Link } from 'react-router-dom';

type Prop = {
  userRole: string | '';
  setLoaderProgress?: (value: number) => void;
  setCallSummaryData?: (value: CallSummaryResponseModel) => void;
  setShow: (value: boolean) => void;
  callLogType: CallLogType;
  columnSelection?: ColumnSelectionsModel[];
};
export function useFunctionalityCallLogColumns(prop: Prop) {
  const {
    userRole,
    setCallSummaryData,
    setLoaderProgress,
    setShow,
    callLogType,
    columnSelection,
  } = prop;

  const { LoadById } = useFunctionalityCallSummary();

  const callLogIcon = (row: CallLogResponseModel) => {
    if (
      row.callStatusType === CallStatusType.Missed ||
      row.callStatusType === CallStatusType.Rejected ||
      row.callStatusType === CallStatusType.Canceled
    ) {
      return <AssetSvg.MissedCall />;
    } else {
      return <AssetSvg.AcceptCallSuccess />;
    }
  };
  const companyName = useAppSelector(
    (state) => state.auth.profile?.companyName
  );

  const rolewiseColumn = [
    {
      name: ' ',
      width: '45px',
      selector: (row: CallLogResponseModel) => {
        return callLogIcon(row);
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CallStatusType'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Id'
            )?.name
          }
        />
      ),
      width: '100px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.id ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Id'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Date'
            )?.name
          }
        />
      ),
      // width: "100px",
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.date ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Date'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CallerName'
            )?.name
          }
        />
      ),
      //  width: "120px",
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.callerName ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CallerName'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CompanyName'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.companyName ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CompanyName'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'ViaResellerName'
            )?.name
          }
        />
      ),
      minWidth: '130px',
      selector: (row: CallLogResponseModel) => {
        return (
          <>
            {row.viaResellerName !== companyName &&
            userRole === AppConstants.UserRoles.Admin ? (
              <Link
                to={AppRouteUi.Reseller.Profile.Load(row.viaResellerId ?? '')}
              >
                <ColumnToolTip data={row.viaResellerName ?? '-'} />
              </Link>
            ) : (
              <ColumnToolTip data={row.viaResellerName ?? '-'} />
            )}
          </>
        );
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'ViaResellerName'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'ToResellerName'
            )?.name
          }
        />
      ),
      width: '120px',
      selector: (row: CallLogResponseModel) => {
        return (
          <>
            {row.toResellerName !== companyName &&
            userRole === AppConstants.UserRoles.Admin ? (
              <Link
                to={AppRouteUi.Reseller.Profile.Load(row.toResellerId ?? '')}
              >
                <ColumnToolTip data={row.toResellerName ?? '-'} />
              </Link>
            ) : (
              <ColumnToolTip data={row.toResellerName ?? '-'} />
            )}
          </>
        );
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'ToResellerName'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Location'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.location ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Location'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Department'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.department ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Department'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CustomField1'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.customField1 ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CustomField1'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CustomField2'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.customField2 ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CustomField2'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CustomField3'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.customField3 ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CustomField3'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'CustomField4'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.customField4 ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'CustomField4'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Language'
            )?.name
          }
        />
      ),
      width: '100px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.language ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Language'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Skill'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.skill ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Skill'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Gender'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.gender ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Gender'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'StartTime'
            )?.name
          }
        />
      ),
      width: '100px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.startTime ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'StartTime'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'EndTime'
            )?.name
          }
        />
      ),
      width: '100px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.endTime ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'EndTime'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Duration'
            )?.name
          }
        />
      ),
      width: '100px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.duration ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Duration'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Responded'
            )?.name
          }
        />
      ),
      width: '110px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.responded ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Responded'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Device'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.device ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Device'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Platform'
            )?.name
          }
        />
      ),
      width: '90px',
      selector: (row: CallLogResponseModel) => {
        return <ColumnToolTip data={row.platform ?? '-'} />;
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Platform'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'ConnectionFee'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.connectionFee!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.connectionFee! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'ConnectionFee'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'TechnologyFee'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.technologyFee!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.technologyFee! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'TechnologyFee'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Revenue'
            )?.name
          }
        />
      ),
      width: '90px',
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.revenue!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.revenue! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Revenue'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Profit'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.profit!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.profit! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Profit'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'ViaResellerProfit'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.viaResellerProfit!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.viaResellerProfit! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'ViaResellerProfit'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'ToResellerProfit'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.toResellerProfit!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.toResellerProfit! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'ToResellerProfit'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'TotalBill'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.totalBill!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.totalBill! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'TotalBill'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'Operators'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={row.operators?.length ? row.operators?.join(', ') : '-'}
          />
        );
      },
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'Operators'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'OperatorsEarning'
            )?.name
          }
        />
      ),
      // width: "100px",
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.operatorsEarning!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.operatorsEarning! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'OperatorsEarning'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'VriCharges'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.vriCharges!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.vriCharges! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'VriCharges'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'VriEarnings'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => {
        return (
          <ColumnToolTip
            data={handleNumberToTwoDecimalPoint(row.vriEarnings!) ?? '-'}
          />
        );
      },
      conditionalCellStyles: [
        {
          when: (row: CallLogResponseModel) => row.vriEarnings! < 0,
          style: {
            color: 'red',
          },
        },
      ],
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'VriEarnings'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'OutboundCaller'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.outboundCaller ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'OutboundCaller'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'OutboundDuration'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.outboundDuration ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'OutboundDuration'
      )?.isEnabled
        ? false
        : true,
    },
    {
      name: (
        <ColumnToolTip
          data={
            columnSelection?.find(
              (eachColumn) => eachColumn.propertyName === 'OutboundBill'
            )?.name
          }
        />
      ),
      selector: (row: CallLogResponseModel) => row.outboundBill ?? '-',
      omit: columnSelection?.find(
        (eachColumn) => eachColumn.propertyName === 'OutboundBill'
      )?.isEnabled
        ? false
        : true,
    },
    // {
    //   name: columnSelection?.find(eachColumn => eachColumn.propertyName === "Start")?.name,
    //   selector: (row: CallLogResponseModel) => row.start ? moment(row.start).format('HH:mm A') : '-',
    //   omit: columnSelection?.find(eachColumn => eachColumn.propertyName === "Start")?.isEnabled ? false : true
    // },
    {
      name: <ColumnToolTip data={'Actions'} />,
      allowOverflow: true,
      selector: (row: CallLogResponseModel) => {
        return (
          <>
            <div
              className="btn p-0 m-0 svg-color"
              title="View"
              onClick={() => {
                if (setLoaderProgress)
                  setLoaderProgress(topLoaderProgress.start);
                LoadById(row.id).then((data) => {
                  if (setLoaderProgress)
                    setLoaderProgress(topLoaderProgress.complete);
                  if (setCallSummaryData && data) setCallSummaryData(data);
                  setShow(true);
                });
              }}
            >
              <span>
                <AssetSvg.ViewProfile />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  return {
    callLogColumn: rolewiseColumn,
  };
}
