import moment from "moment";
import { ApiSchemaOperatorLanguageRate } from "../models";

export enum certificate {
  EXPIRED = "Expired",
  DAYSLEFT = 'days left'
}

export const dateFormatterInYMD = (isoString: string) => {
  let date = new Date(isoString);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  return `${year}-${month >= 10 ? month : "0" + month}-${day >= 10 ? day : "0" + day
    }`;
};

export const time24hrFormatter = (isoString: string) => {
  let time = new Date(isoString).toLocaleTimeString("en-US", { hour12: false });
  let validatedTime =
    time?.split(":")[0] === "24" ? time.replaceAll("24", "00") : time;
  return validatedTime;
};

export const timeSpanCalculator = (isoString: string, comparedTimeZone?: string) => {
  let current;
  if (comparedTimeZone) {
    current = dateWithCurrentUserTimeZone(comparedTimeZone)
  } else {
    current = new Date();
  }
  let time = new Date(isoString);
  let diff = Math.abs(Math.floor((current.getTime() - time.getTime()) / 60000));
  if (diff >= 10080) {
    diff = Math.abs(Math.floor(diff / 10080));
    return `${diff} ${diff < 2 ? "week" : "weeks"}`;
  }
  else if (diff >= 1440 && diff < 10080) {
    diff = Math.abs(Math.floor(diff / 1440));
    return `${diff} ${diff < 2 ? "day" : "days"}`;
  }
  else if (diff >= 60 && diff < 1440) {
    diff = Math.abs(Math.floor(diff / 60));
    return `${diff} ${diff < 2 ? "hour" : "hours"}`;
  }
  diff = Math.abs(Math.floor(diff));
  return `${diff} ${diff < 2 ? "minute" : "minutes"}`;
}

export const durationConverter = (duration: number) => {
  let day = Math.floor(duration / (24 * 3600));
  let hour = Math.floor((duration % (24 * 3600)) / 3600);
  let minute = Math.floor((duration % 3600) / 60);
  let second = Math.floor((duration % 3600) % 60);

  let showDay = day > 0 ? day + "d " : "";
  let showHour = hour > 0 ? hour + "h " : "";
  let showMinute = minute > 0 ? minute + "m " : "";
  let showSecond = second > 0 ? second + "s " : "";

  return showDay + showHour + showMinute + showSecond;
};

export const timeFormatterInAMPM = (isoString: string) => {
  let date = new Date(isoString);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let showMinutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + showMinutes + " " + ampm;
  return strTime;
};

export const timeDuration = (start: string, end: string) => {
  const startTime = moment(start);
  const timeEnd = moment(end);
  const diff = timeEnd.diff(startTime);
  const diffDuration = moment.duration(diff);
  if (!startTime || !timeEnd) return "";

  return (
    diffDuration.hours() +
    " : " +
    diffDuration.minutes() +
    " : " +
    diffDuration.seconds()
  );
};

export const dateWithCurrentUserTimeZone = (timeZone: string) => {
  const currentDate = new Date()
  const currentDateWithUserTimeZone = currentDate.toLocaleString('en-US', { timeZone: timeZone })
  return new Date(currentDateWithUserTimeZone)
}


export const dateFormatInUSA = (date: string) => {
  return moment(new Date(date)).format('ll')
}

export const certificateExpirationInDays = (certificateEndDate: any) => {
  const remainingDays = moment(certificateEndDate).diff(moment(new Date()), "days");
  return remainingDays <= 0 ? 'Expired' : remainingDays.toString().concat(" days left");
}

export const certificateIsExpired = (operatorCertificates: any[]) => {
  const certificateLength = operatorCertificates?.length ?? 0;
  var numeroPerguntas = certificateLength;
  var expirationTrackingArr = new Array(numeroPerguntas).fill(false);

  for (let i = 0; i < certificateLength; i++) {
    const cert = operatorCertificates && operatorCertificates[i];
    const isExpired: boolean = certificateExpirationInDays(cert?.endDate)?.includes(certificate.EXPIRED);
    const isDayLeft: boolean = certificateExpirationInDays(cert?.endDate)?.includes(certificate.DAYSLEFT);
    const numberOfDaysLeft = certificateExpirationInDays(cert?.endDate).match(/(\d+)/);

    if (isExpired) {
      expirationTrackingArr[i] = isExpired
     
    } else if (isDayLeft) {
      if (numberOfDaysLeft && Number(numberOfDaysLeft[0]) <= 30) {
        return "certificate-being-expired";
      }
      return "";
    }

  var allTrue = !expirationTrackingArr.some(x => x === false);
  if(allTrue)  return "certificate-expired";

  }
  return "";
}